import * as React from 'react';

interface IErrorMessageProps {
  message: string;
}

export const ErrorMessage: React.FC<IErrorMessageProps> = ({ message }) => {
  const displayMessage = message.replace('GraphQL error: ', '');
  return (
    <aside
      style={{
        padding: '1.5em',
        fontSize: 14,
        color: '#fff',
        backgroundColor: 'red',
        textAlign: 'center',
      }}
    >
      {displayMessage}
    </aside>
  );
};

import * as React from 'react';

import cn from 'classnames';

import s from './ProgressBar.module.css';

type ProgressBarProps = {
  progress: number;
  className?: string;
};
export const ProgressBar: React.FC<ProgressBarProps> = ({ progress, className }) => {
  return (
    <div className={cn(s.progressBar, className)} style={{ '--progress': `${progress}%` } as React.CSSProperties} />
  );
};

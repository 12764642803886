import * as React from 'react';

type DRAWER_CONTENT = 'CART';

type Action =
  | {
      type: 'OPEN_DRAWER';
      title?: string | null;
      content: DRAWER_CONTENT | React.ReactNode | null;
      position: 'top' | 'left' | 'right' | 'bottom';
      bg: 'light' | 'dark';
    }
  | {
      type: 'CLOSE_DRAWER';
    }
  | {
      type: 'OPEN_VIDEO_OVERLAY';
    }
  | {
      type: 'CLOSE_VIDEO_OVERLAY';
    }
  | {
      type: 'OPEN_MODAL';
      content: React.ReactNode | string | null;
      onClose?: () => void;
      customProps?: any;
    }
  | {
      type: 'CLOSE_MODAL';
    };

type State = {
  displayModal: boolean;
  displayDrawer: boolean;
  displayVideoOverlay: boolean;
  modalContent: React.ReactNode | null;
  modalOnClose?: () => void;
  drawerTitle?: string | null;
  drawerContent: DRAWER_CONTENT | React.ReactNode | null;
  drawerPosition: 'top' | 'left' | 'right' | 'bottom';
  drawerBg: 'light' | 'dark';
  customProps?: any | null;
};

const reducer: React.Reducer<State, Action> = (state, action) => {
  switch (action.type) {
    case 'OPEN_DRAWER':
      return {
        ...state,
        displayDrawer: true,
        drawerTitle: action.title,
        drawerContent: action.content,
        drawerPosition: action.position,
        drawerBg: action.bg,
      };
    case 'CLOSE_DRAWER':
      return { ...state, displayDrawer: false, drawerContent: null };
    case 'OPEN_VIDEO_OVERLAY':
      return { ...state, displayVideoOverlay: true };
    case 'CLOSE_VIDEO_OVERLAY':
      return { ...state, displayVideoOverlay: false };
    case 'OPEN_MODAL':
      return {
        ...state,
        displayModal: true,
        modalContent: action.content,
        customProps: action?.customProps || null,
        modalOnClose: action.onClose,
      };
    case 'CLOSE_MODAL':
      return { ...state, displayModal: false, modalContent: null };
    default:
      return state;
  }
};

const initialState: State = {
  customProps: null,
  displayDrawer: false,
  displayVideoOverlay: false,
  drawerPosition: 'right',
  displayModal: false,
  drawerContent: null,
  drawerTitle: null,
  drawerBg: 'light',
  modalContent: null,
};

export const UIStateContext = React.createContext<State | undefined>(undefined);
export const UIDispatchContext = React.createContext<React.Dispatch<Action> | undefined>(undefined);

export const useUI = () => {
  const stateContext = React.useContext(UIStateContext);

  if (!stateContext) {
    throw new Error('state context was not provided for `useUI`');
  }

  return stateContext;
};

export const useUIDispatch = () => {
  const dispatchContext = React.useContext(UIDispatchContext);

  if (!dispatchContext) {
    throw new Error('dispatch context was not provided for `useUIDispatch`');
  }

  return dispatchContext;
};

export const UIProvider: React.FC = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  return (
    <UIStateContext.Provider value={state}>
      <UIDispatchContext.Provider value={dispatch}>{children}</UIDispatchContext.Provider>
    </UIStateContext.Provider>
  );
};

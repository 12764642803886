/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';

import NextLink from 'next/link';

import s from './LiveEpisodeToast.module.css';

interface ILiveEpisodeToast {
  title: string;
  showName: string;
  slug: string;
}

export const LiveEpisodeToast: React.FC<ILiveEpisodeToast> = ({ title, showName, slug }) => {
  return (
    <NextLink href={`/episode/${slug}`} passHref>
      <a>
        <div>
          <div className={s.root}>
            <div className={s.liveIndicator} /> <div className={s.label}> Live</div>
            <div className={s.text} title={`${showName}: ${title}`}>
              {showName}: {title}
            </div>
          </div>
        </div>
      </a>
    </NextLink>
  );
};

import * as React from 'react';

import * as SwitchPrimitive from '@radix-ui/react-switch';
import cn from 'classnames';

import s from './ToggleSwitch.module.css';

export const ToggleSwitch = React.forwardRef<HTMLButtonElement, SwitchPrimitive.SwitchProps>(
  ({ className, ...rest }, ref) => {
    return (
      <SwitchPrimitive.Root className={cn(s.root, className)} ref={ref} {...rest}>
        <SwitchPrimitive.Thumb className={s.thumb} />
      </SwitchPrimitive.Root>
    );
  },
);

ToggleSwitch.displayName = 'ToggleSwitch';

import styled from '@emotion/styled';

import { mainBackgroundAttachment, mainBackgroundSize, mainBackgroundString } from '@/components/shared/Main';

type Props = {
  as?: React.ElementType;
  theme: any;
  variant?: 'light' | 'dark' | 'null';
};

export const Jumbotron = styled('header')(({ theme, variant = 'dark' }: Props) => ({
  background:
    variant === 'null'
      ? ''
      : variant === 'light'
      ? ' linear-gradient(to top, #F2F2F2 0%, rgba(242, 242, 242, 0) 25%);'
      : mainBackgroundString,
  backgroundSize: variant === 'light' ? undefined : mainBackgroundSize,
  backgroundAttachment: variant === 'light' ? undefined : mainBackgroundAttachment,
  color: variant === 'light' ? theme.colors.gray92 : theme.colors.white,
  paddingTop: '40px',
  paddingBottom: '40px',

  [theme.mq.tablet]: {
    paddingTop: '48px',
    paddingBottom: '48px',
  },
}));

/* eslint-disable import/no-unresolved */
/* eslint-disable import/order */
import { XPayload } from '@/types/xPayload';

export async function theFormerlyKnownAsTwitterAPI(payload: XPayload) {
  if (!process.env.NEXT_PUBLIC_X_CAPI_URL) {
    console.error('Missing NEXT_PUBLIC_X_CAPI_URL ENV');
    return;
  }

  const req = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  };

  await fetch(process.env.NEXT_PUBLIC_X_CAPI_URL, req);
}

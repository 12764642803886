/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from '@emotion/styled';

export const Superimposed = styled<any>('span')({
  position: 'absolute',
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.8)',
  padding: '8px 16px',
  fontWeight: 400,
  whiteSpace: 'nowrap',
  overflowX: 'auto',
  maxWidth: '100%',
  left: 0,
});

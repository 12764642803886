import * as React from 'react';

import cn from 'classnames';

import s from './Superimposed.module.css';

export interface ISuperimposed {
  className?: string;
  variant?: 'pill';
  position: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';
  spacing?: 'none' | 'default';
}

export const Superimposed: React.FC<ISuperimposed> = ({
  className,
  variant,
  position,
  spacing = 'default',
  children,
}) => {
  return (
    <div
      className={cn(
        s.root,
        {
          [s.pill]: variant === 'pill',
          [s.topLeft]: position === 'top-left',
          [s.topRight]: position === 'top-right',
          [s.bottomLeft]: position === 'bottom-left',
          [s.bottomRight]: position === 'bottom-right',
          [s.spaced]: spacing === 'default',
        },
        className,
      )}
    >
      {children}
    </div>
  );
};

/* eslint-disable import/no-unresolved */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';

import Router from 'next/router';

import { useAuth0 } from '@/context/auth';
import { getABTestAnalyticData } from '@/lib/abTesting';
import { getUtmCookieData } from '@/lib/utm_cookie';

type ImgUrls = {
  desktop: string;
  mobile: string;
};

function getImageUrls(effectivePlan = ''): ImgUrls {
  let desktop = '';
  let mobile = '';

  const isLastChance = new Date().getTime() > new Date('2024-12-07T00:00:00').getTime();

  switch (effectivePlan.trim().toLowerCase()) {
    case 'reader':
    case 'insider':
      if (isLastChance) {
        desktop = 'https://dailywireplus-v2.imgix.net/uploads/cyber_week_2024_upgrade_desktop_last_chance.png';
        mobile = 'https://dailywireplus-v2.imgix.net/uploads/cyber_week_2024_upgrade_mobile_last_chance.png';
      } else {
        desktop = 'https://dailywireplus-v2.imgix.net/uploads/cyber_week_2024_upgrade_desktop.png';
        mobile = 'https://dailywireplus-v2.imgix.net/uploads/cyber_week_2024_upgrade_mobile.png';
      }
      break;
    case 'insider_plus':
    case 'all_access':
      if (isLastChance) {
        desktop = 'https://dailywireplus-v2.imgix.net/uploads/cyber_week_2024_gift_desktop_last_chance.png';
        mobile = 'https://dailywireplus-v2.imgix.net/uploads/cyber_week_2024_gift_mobile_last_chance.png';
      } else {
        desktop = 'https://dailywireplus-v2.imgix.net/uploads/cyber_week_2024_gift_desktop.png';
        mobile = 'https://dailywireplus-v2.imgix.net/uploads/cyber_week_2024_gift_mobile.png';
      }
      break;
    default:
      if (isLastChance) {
        desktop = 'https://dailywireplus-v2.imgix.net/uploads/cyber_week_2024_annual_desktop_last_chance.png';
        mobile = 'https://dailywireplus-v2.imgix.net/uploads/cyber_week_2024_annual_mobile_last_chance.png';
      } else {
        desktop = 'https://dailywireplus-v2.imgix.net/uploads/cyber_week_2024_annual_desktop.png';
        mobile = 'https://dailywireplus-v2.imgix.net/uploads/cyber_week_2024_annual_mobile.png';
      }
      break;
  }

  return { desktop, mobile };
}

export default function GiftBanner() {
  const abTestData = getABTestAnalyticData();
  const utmCookieData = getUtmCookieData();
  const [imgSrcDesktop, setImgSrcDesktop] = React.useState<string>(
    'https://dailywireplus-v2.imgix.net/uploads/cyber_week_2024_annual_desktop.png',
  );
  const [imgSrcMobile, setImgSrcMobile] = React.useState<string>(
    'https://dailywireplus-v2.imgix.net/uploads/cyber_week_2024_annual_mobile.png',
  );
  const { subscriber } = useAuth0();

  React.useEffect(() => {
    const imgUrls = getImageUrls(subscriber?.effectivePlan);
    setImgSrcMobile(imgUrls.mobile);
    setImgSrcDesktop(imgUrls.desktop);
  }, [subscriber?.effectivePlan]);

  const handleClick = () => {
    window.__DW_Next_Bridge?.Analytics.logEvent('CTA Click', {
      ...abTestData,
      ...utmCookieData,
      label: 'Gift Now',
      location: 'All Page Banner',
      destination: 'https://get.dailywire.com/gift/?utm_campaign=xmas24&utm_medium=banner&utm_source=dailywire',
    });
    window.open('https://get.dailywire.com/gift/?utm_campaign=xmas24&utm_medium=banner&utm_source=dailywire', '_blank');
    return;
    switch (subscriber?.effectivePlan.trim().toLowerCase()) {
      case 'reader':
      case 'insider':
        window.__DW_Next_Bridge?.Analytics.logEvent('CTA Click', {
          ...abTestData,
          ...utmCookieData,
          label: 'Upgrade Now',
          location: 'All Page Banner',
          destination: '/account/subscription',
        });
        Router.push('/account/subscription');
        return;
      case 'insider_plus':
      case 'all_access':
        window.__DW_Next_Bridge?.Analytics.logEvent('CTA Click', {
          ...abTestData,
          ...utmCookieData,
          label: 'Gift Now',
          location: 'All Page Banner',
          destination: 'https://get.dailywire.com/gift/?utm_campaign=cw24&utm_medium=banner&utm_source=dailywire',
        });
        window.open(
          'https://get.dailywire.com/gift/?utm_campaign=cw24&utm_medium=banner&utm_source=dailywire',
          '_blank',
        );
        return;
      default:
        window.__DW_Next_Bridge?.Analytics.logEvent('CTA Click', {
          ...abTestData,
          ...utmCookieData,
          label: 'Join Now',
          location: 'All Page Banner',
          destination:
            'https://get.dailywire.com/subscribe/plus?utm_campaign=cw24&utm_medium=banner&utm_source=dailywire',
        });
        window.open(
          'https://get.dailywire.com/subscribe/plus?utm_campaign=cw24&utm_medium=banner&utm_source=dailywire',
          '_blank',
        );
        return;
    }
  };

  return (
    <div
      className='gift-banner'
      style={{
        width: '100%',
      }}
    >
      <img
        alt='gift banner'
        id='mobile'
        onClick={handleClick}
        src='https://dailywireplus-v2.imgix.net/images/dailywire.com/DW%2B_40Off_Gifts_Banner_Mobile.png'
      />
      <img
        alt='gift banner'
        id='desktop'
        onClick={handleClick}
        src='https://dailywireplus-v2.imgix.net/images/dailywire.com/dw_gift_desktop.png'
      />
    </div>
  );
}

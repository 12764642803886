import { AccessLevel, Subscriber } from 'types/subscriber';

import { subscriberHasPaidPlan } from './subscriberHasPaidPlan';

export const subscriberCanAccess = (subscriber?: Subscriber, accessLevels: string[] = []) => {
  if (!subscriber) return false;
  if (!subscriberHasPaidPlan(subscriber)) return false;

  const effectivePlan = subscriber.effectivePlan as AccessLevel;

  return accessLevels.includes(effectivePlan);
};

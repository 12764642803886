import styled from '@emotion/styled';

type Props = {
  as?: React.ElementType;
  theme: any;
  variant?: string;
};

export const Paragraph = styled('p')(({ theme, variant = '200' }: Props) => ({
  ...theme.paragraph[variant],
  lineHeight: 1.75,
  margin: 0,
  fontWeight: 400,
}));

import styled from '@emotion/styled';

import { ThemeType } from '../../theme';

export const Checklist = styled('ol')(() => ({
  position: 'relative',
  margin: '0',
  padding: '0',
  listStyle: 'none',
}));

interface ChecklistItemProps {
  theme: ThemeType;
  strikethrough?: boolean;
  iconSpacing?: number | string;
  subdued?: boolean;
}

export const ChecklistItem = styled.li<ChecklistItemProps>(({ theme, strikethrough, iconSpacing, subdued }) => ({
  display: 'flex',
  fontSize: '1rem',
  lineHeight: 1.5,
  alignItems: 'flex-start',
  textDecoration: strikethrough ? 'line-through' : 'none',
  color: strikethrough ? theme.colors.gray40 : 'inherit',
  opacity: subdued ? 0.5 : 1,

  '& + li': {
    marginTop: 16,
  },

  '::before': {
    content: strikethrough ? "url('/svg/close.svg')" : "url('/svg/check.svg')",
    width: '1rem',
    flexShrink: 0,
    marginRight: iconSpacing ?? '.5rem',
  },
}));

import cookie from 'js-cookie';

export const clearUserData = () => {
  window.analytics?.reset();
  //set window.shouldShowAds to true for ad injection (video unit specific)
  (window as any).showAds = true;
  cookie.remove('accessToken');
  localStorage.removeItem('access_token');
  localStorage.removeItem('ajs_user_traits');
  localStorage.removeItem('ajs_user_id');
  localStorage.removeItem('dw_user');
  localStorage.removeItem('dw_subscriber');
  localStorage.removeItem('dw_subscription');
};

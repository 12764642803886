import styled from '@emotion/styled';

type Props = {
  theme: any;
  as?: React.ElementType;
  variant?: string;
};

export const Text = styled('span')(({ theme, variant = '300' }: Props) => ({
  ...theme.text[variant],
  lineHeight: 1.25,
}));

import { RecurlySubscription } from '@/graphql/chat';

export const getActiveSubscription = (subscriptions: RecurlySubscription[]) => {
  const nonExpiredSubscriptions = subscriptions.filter((subscription) => subscription.state !== 'expired');

  // @TODO: nonExpiredSubscriptions.length > 1
  if (nonExpiredSubscriptions.length) {
    return nonExpiredSubscriptions[0];
  }

  return null;
};

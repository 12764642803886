import * as React from 'react';

import { useTheme } from 'emotion-theming';

import { Heading } from '@/components/design-system';
import { svgComponentToDataUrl } from '@/utils/helpers';

type AngleLinesProps = {
  fill: string;
};

function AngleLines({ fill }: AngleLinesProps) {
  return (
    <svg fill='none' height='8' viewBox='0 0 8 8' width='8' xmlns='http://www.w3.org/2000/svg'>
      <rect fill={fill} height='10' transform='rotate(45 7.14844 0)' width='1' x='7.14844' />
    </svg>
  );
}

type DividerProps = {
  title: string;
  variant?: 'light' | 'dark';
};

export const Divider = ({ title, variant = 'light' }: DividerProps) => {
  const theme: any = useTheme();

  const variantColor = {
    light: theme.colors.gray20,
    dark: theme.colors.white,
  };

  return (
    <div
      css={{
        backgroundImage: `
          url("${svgComponentToDataUrl(<AngleLines fill={variantColor[variant]} />)}")
        `,
        backgroundRepeat: 'repeat-x',
        marginTop: 56,
        marginBottom: 24,
      }}
    >
      <Heading
        css={(theme) => ({
          borderTop: `8px solid ${theme.colors.red}`,
          borderRight: '8px solid transparent',
          display: 'inline-block',
          paddingTop: 24,
          paddingRight: 8,
        })}
        variant='600'
      >
        {title}
      </Heading>
    </div>
  );
};

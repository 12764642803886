export function getUtmCookieData() {
  if (typeof document === 'undefined') return {};
  const utmCookie = getExisitngCookie();
  if (!utmCookie && !checkForUTMS()) return {};
  const cookieString = createCookieString(utmCookie);
  const utms = cookieString
    .replace('dw_utm=', '')
    .split('&')
    .reduce((acc, curr) => {
      const [key, value] = curr.split('=');
      acc['ck_' + key.trim()] = value;
      return acc;
    }, {});

  return utms;
}

const getExisitngCookie = (): string => {
  const utmCookie = document.cookie.split(';').find((cookie) => cookie.includes('dw_utm'));
  return utmCookie;
};

const checkForUTMS = (): boolean => {
  const url = new URL(window.location.href);
  if (
    url.searchParams.get('utm_source') ||
    url.searchParams.get('utm_medium') ||
    url.searchParams.get('utm_campaign') ||
    url.searchParams.get('utm_content')
  ) {
    return true;
  }
  return false;
};

const createCookieString = (existingCookie: string): string => {
  const url = new URL(window.location.href);
  if (checkForUTMS()) {
    const utmData = {
      utm_campaign: url.searchParams.get('utm_campaign'),
      utm_medium: url.searchParams.get('utm_medium'),
      utm_source: url.searchParams.get('utm_source'),
      utm_content: url.searchParams.get('utm_content'),
    };
    // Convert utmData to query parameter string
    const utmParams = new URLSearchParams(utmData).toString();
    if (existingCookie?.includes(utmParams)) {
      return existingCookie;
    } else {
      deleteCookie('dw_utm');
      setCookie('dw_utm', utmParams, 30);
      return `dw_utm=${utmParams}`;
    }
  } else {
    return existingCookie;
  }
};

const setCookie = (name: string, value: string, days: number) => {
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  const expires = 'expires=' + date.toUTCString();
  const cookie = `${name}=${value};${expires};path=/`;
  document.cookie = cookie;
};

const deleteCookie = (name: string) => {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
};

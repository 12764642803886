/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';

// Paragraphs
const paragraphs = (theme: any, variant: string) => ({
  p: {
    ...theme.paragraph[variant],
    marginTop: 24,
    wordBreak: 'break-word',
  },
});

// Headers
const headers = (theme: any) => ({
  '>': {
    h1: {
      ...theme.headers['800'],
      marginTop: 32,
      marginBottom: 0,
    },
    h2: {
      ...theme.headers['700'],
      marginTop: 32,
      marginBottom: 0,
    },

    h3: {
      ...theme.headers['600'],
      marginTop: 24,
      marginBottom: 0,
    },

    h4: {
      ...theme.headers['500'],
      marginTop: 16,
      marginBottom: 0,
    },
    h5: {
      ...theme.headers['400'],
      marginTop: 16,
      marginBottom: 0,
    },
  },
});

// Links
const links = (theme: any) => ({
  a: {
    color: theme.colors.gray95,
    textDecoration: 'underline',
    transition: 'color 250ms ease',
  },

  'a:hover': {
    color: theme.colors.red,
  },
});

// Lists
const lists = (theme: any) => ({
  'ul, ol': {
    listStyle: 'none',
    marginTop: 32,
    marginBottom: 32,
  },
  li: {
    position: 'relative',
    marginTop: 16,
    marginBottom: 16,
    paddingLeft: 24,
    fontWeight: 400,
    ...theme.paragraph['300'],
  },
  'li::before': {
    display: 'inline-block',
    marginRight: '16px',
  },
  'ul li::before': {
    content: '""',
    height: 6,
    width: 6,
    backgroundColor: theme.colors.red,
    borderRadius: '50%',
  },
  ol: {
    counterReset: 'item-number',
  },
  'ol li::before': {
    counterIncrement: 'item-number',
    content: 'counter(item-number) "."',
    color: theme.colors.red,
    fontSize: 16,
    fontWeight: 700,
  },
});

// Blockquote
const blockquote = (theme: any) => ({
  blockquote: {
    marginTop: 32,
    marginBottom: 32,
    paddingLeft: 40,
    borderLeft: `4px solid ${theme.colors.red}`,
  },
});

// Horizontal Rule
const horizontalRule = (theme: any) => ({
  hr: {
    margin: '40px 24px',
    border: 'none',
    height: 1,
    backgroundColor: theme.colors.gray20,
    maxWidth: 680,

    [theme.mq.desktop]: {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
});

// Embeds
const socialEmbeds = () => ({
  '.wp-embedded-content': {
    maxWidth: '100%',
  },
  '.twitter-tweet': {
    width: '100% !important',
    maxWidth: '680px !important',
    margin: '32px auto !important',
  },

  '.instagram-media': {
    width: 'calc(100% - 48px) !important',
    minWidth: 'auto !important',
    maxWidth: '680px !important',
    margin: '32px auto !important',
    padding: 0,
  },
  iframe: {
    maxWidth: '100%',
  },
});

// Media
const media = (theme: any) => ({
  '.wp-caption-text': {
    fontSize: theme.fontSizes[1],
    color: theme.colors.gray60,
    maxWidth: 728,
    fontStyle: 'italic',
    margin: '8px auto 0',
    padding: '0 24px',
    textAlign: 'left',
  },
});

// Text styles

const textStyleElements = () => ({
  strong: {
    fontWeight: 700,
  },
});

type Props = {
  variant?: 'sm' | 'default';
  expanded?: boolean;
};

const bodyTextCss = (theme: any, variant: string) => {
  const paragraphVariant = variant === 'sm' ? '200' : '300';

  return [
    paragraphs(theme, paragraphVariant),
    headers(theme),
    links(theme),
    lists(theme),
    blockquote(theme),
    horizontalRule(theme),
    media(theme),
    socialEmbeds(),
    textStyleElements(),
  ];
};

export const BodyText: React.FC<Props> = ({ variant = 'default', ...props }) => {
  return (
    <div
      className='article-body'
      css={(theme) => bodyTextCss(theme, variant) as any}
      style={{ position: 'relative' }}
      {...props}
    />
  );
};

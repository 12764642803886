import styled from '@emotion/styled';

export const Badge = styled('span')<any>(({ theme, ...props }) => ({
  padding: '4px 8px',
  borderRadius: '2px',
  fontSize: '12px',
  fontWeight: 700,
  color: props.color,
  border: '1px solid',
  marginRight: '16px',
  fontFamily: 'Libre Franklin',
}));

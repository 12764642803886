import { css } from '@emotion/core';

import { color } from './color';
import { font } from './font';

const inputTextareaSelectStyles = css`
  border-radius: 6px;
  ${font.lato};
  font-size: 16px;
  line-height: 1.75;
  padding: 10px 16px;
  appearance: none;
  border: 0;
  color: #fff;
  background-color: ${color.gray80};
  width: 100%;
  &:focus {
    outline: 0;
  }
`;

// Form Label

const labelDefaults = css`
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 700;
  margin: 0 0 16px;
  line-height: 1.25;
`;

const inlineLabelDefaults = css`
  margin: 0;
  display: block;
  font-size: 15px;
  line-height: 1.25;
`;

export const label = {
  default: {
    light: css`
      ${labelDefaults};
      color: ${color.gray60};
    `,
    dark: css`
      ${labelDefaults};
      color: ${color.gray40};
    `,
  },

  inline: {
    light: css`
      ${inlineLabelDefaults}
      color: ${color.white};
    `,
    dark: css`
      ${inlineLabelDefaults}
      color: ${color.gray95};
    `,
  },
};

export const input = {
  default: css`
    ${inputTextareaSelectStyles};
    cursor: text;

    &::placeholder {
      color: ${color.gray40};
    }
  `,
};

export const textarea = {
  default: css`
    ${inputTextareaSelectStyles};
    min-height: 160px;
    max-width: 100%;
    min-width: 100%;
    cursor: text;

    &::placeholder {
      color: ${color.gray40};
    }
  `,
};

export const selectBlack = {
  default: css`
    ${inputTextareaSelectStyles};
    background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%3E%3Ctitle%3Edown-arrow%3C%2Ftitle%3E%3Cg%20fill%3D%22%23ffffff%22%3E%3Cpath%20d%3D%22M10.293%2C3.293%2C6%2C7.586%2C1.707%2C3.293A1%2C1%2C0%2C0%2C0%2C.293%2C4.707l5%2C5a1%2C1%2C0%2C0%2C0%2C1.414%2C0l5-5a1%2C1%2C0%2C1%2C0-1.414-1.414Z%22%20fill%3D%22%23000000%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E');
    background-size: 0.8em;
    background-position: calc(100% - 0.8em) center;
    background-repeat: no-repeat;
    padding-right: 2rem;
    cursor: pointer;

    &::-ms-expand {
      display: none;
    }
  `,
};
export const select = {
  default: css`
    ${inputTextareaSelectStyles};
    background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%3E%3Ctitle%3Edown-arrow%3C%2Ftitle%3E%3Cg%20fill%3D%22%23ffffff%22%3E%3Cpath%20d%3D%22M10.293%2C3.293%2C6%2C7.586%2C1.707%2C3.293A1%2C1%2C0%2C0%2C0%2C.293%2C4.707l5%2C5a1%2C1%2C0%2C0%2C0%2C1.414%2C0l5-5a1%2C1%2C0%2C1%2C0-1.414-1.414Z%22%20fill%3D%22%23ffffff%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E');
    background-size: 0.8em;
    background-position: calc(100% - 0.8em) center;
    background-repeat: no-repeat;
    padding-right: 2.5rem;
    cursor: pointer;

    &::-ms-expand {
      display: none;
    }
  `,
};

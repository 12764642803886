import { css } from '@emotion/core';

export const shadow = {
  small: css`
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.5);
  `,
  default: css`
    box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.15);
  `,
  large: css`
    box-shadow: 0px 16px 48px rgba(0, 0, 0, 0.6);
  `,
};

/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */
import React from 'react';

import { Loader } from '@/components/design-system';

import styles from './index.module.css';

export const LoadingComponent = () => {
  return (
    <div className={styles.loadingComponent}>
      <Loader />
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, { useState, forwardRef } from 'react';

import styles from './index.module.css';

type CustomInputProps = {
  type: string;
  name: string;
  id: string;
  initialValue: string | null;
  placeholder: string;
  dataRecurly: string;
  inputEventHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  errors: string[];
  checkFormValidity: () => void;
  handleInputError: (name: string, error: boolean) => void;
  errorMessage?: string;
  required?: boolean;
};

export const CustomInput = forwardRef<HTMLInputElement, CustomInputProps>(function CustomInput(
  {
    type,
    name,
    id,
    initialValue,
    placeholder,
    dataRecurly,
    inputEventHandler,
    errors,
    checkFormValidity,
    handleInputError,
    errorMessage = null,
    required = false,
  },
  inputRef,
) {
  const [value, setValue] = useState(initialValue);
  const [placeholderClassList, setPlaceholderClassList] = useState(styles.customInputPlaceholder);

  const errorsObject = {};
  if (errors && errors.includes(name)) {
    console.log(errors);
    errorsObject.name = errorMessage ? errorMessage : `${placeholder} required`;
  }

  function handleInput() {
    if (typeof checkFormValidity === 'function') {
      checkFormValidity();
    }
    if (inputRef && inputRef.current) {
      setValue(inputRef.current.value);
    }

    setPlaceholderClassList(styles.customInputPlaceholderIsActive);
  }

  function handleFocus() {
    setPlaceholderClassList(styles.customInputPlaceholderIsActive);
  }
  function handleBlur() {
    if (inputRef && 'current' in inputRef && inputRef.current) {
      inputRef.current.value === ''
        ? setPlaceholderClassList(styles.customInputPlaceholder)
        : setPlaceholderClassList(styles.customInputPlaceholderIsActive);
      if (typeof handleInputError === 'function') {
        handleInputError(name, inputRef.current.value === '');
      }
    }
  }
  return (
    <div className={styles.container}>
      <div className={!!errorsObject.name == true ? styles.customInputContainerError : styles.customInputContainer}>
        <p className={value && value != '' ? styles.customInputPlaceholderIsActive : placeholderClassList}>
          {placeholder}
        </p>
        <input
          className={styles.customInput}
          data-recurly={dataRecurly ?? ''}
          id={id ?? ''}
          name={name ?? ''}
          onBlur={handleBlur ?? undefined}
          onChange={inputEventHandler ?? undefined}
          onFocus={handleFocus ?? undefined}
          onInput={handleInput ?? undefined}
          ref={inputRef}
          required={required}
          type={type}
          value={value ?? ''}
        />
      </div>
      {!!errorsObject.name == true ? <p className={styles.errorText}>{errorsObject.name}</p> : ''}
    </div>
  );
});

import React, { ButtonHTMLAttributes, FC, forwardRef, JSXElementConstructor, useRef } from 'react';

import cn from 'classnames';
import mergeRefs from 'react-merge-refs';

import s from './Button.module.css';

export type ButtonVariant = 'primary' | 'secondary' | 'minimal';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  size?: 'lg' | 'sm';
  variant?: ButtonVariant;
  Component?: string | JSXElementConstructor<any>;
}

const Button: FC<ButtonProps> = forwardRef((props, buttonRef) => {
  const {
    children,
    className,
    disabled = false,
    style = {},
    variant = 'primary',
    Component = 'button',
    size,
    ...rest
  } = props;

  const ref = useRef<typeof Component>(null);

  return (
    <Component
      className={cn(
        s.btn,
        {
          [s.disabled]: disabled,
          [s.minimal]: variant === 'minimal',
          [s.primary]: variant === 'primary',
          [s.secondary]: variant === 'secondary',
          [s.lg]: size === 'lg',
          [s.sm]: size === 'sm',
        },
        className,
      )}
      disabled={disabled}
      ref={mergeRefs([ref, buttonRef])}
      style={style}
      {...rest}
    >
      {children}
    </Component>
  );
});

Button.displayName = 'Button';

export default Button;

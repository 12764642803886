import useSWR from 'swr';

import { appApi } from '@/graphql/app';

import { EpisodeQueryKeys } from '../types';

export const useEpisodesLive = () => {
  return useSWR([EpisodeQueryKeys.LiveEpisodes], () => appApi.getLiveEpisodes(), {
    revalidateOnFocus: true,
    refreshInterval: 300000,
  });
};

import React, { FC } from 'react';

import Link, { LinkProps } from 'next/link';

import { TabRoot, Text, Underline } from './Tab.styles';

export interface TabProps extends LinkProps {
  active?: boolean;
}

export const Tab: FC<TabProps> = ({ active, children, ...linkProps }) => {
  return (
    <Link {...linkProps} passHref={true}>
      <TabRoot active={active}>
        <Text active={active}>{children}</Text>
        <Underline active={active} />
      </TabRoot>
    </Link>
  );
};

import * as React from 'react';

import cn from 'classnames';
import NextLink, { LinkProps as NextLinkProps } from 'next/link';

export type LinkProps = NextLinkProps & {
  href: string;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  style?: React.CSSProperties;
};

const Link: React.FC<LinkProps> = ({ href, onClick, children, className = '', ...rest }) => {
  const isInternal = /^\/(?!\/)/.test(href);
  const isAnchor = /^#/.test(href);

  if (isInternal) {
    return (
      <NextLink href={href}>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
        <a {...rest} className={className} onClick={onClick}>
          {children}
        </a>
      </NextLink>
    );
  }

  if (isAnchor) {
    return (
      <a className={className} href={href} onClick={onClick} {...rest}>
        {children}
      </a>
    );
  }

  return (
    <a
      className={cn(className, 'external')}
      href={href}
      onClick={onClick}
      rel='noopener noreferrer'
      target='_blank'
      {...rest}
    >
      {children}
    </a>
  );
};

export default Link;

type TokenData = {
  aud: string[];
  azp: string;
  exp: number;
  iat: number;
  iss: string;
  permissions: string[];
  scope: string;
  sub: string;
  'https://users.bentkey.services/email': string;
  'https://users.bentkey.services/profile_img': string;
  'https://users.bentkey.services/username': UserTraits;
};

type UserTraits = {
  badge_image_url: string;
  person_id: string;
  recurly_account_code: string;
  shopify_customer_id: number;
  username: string;
};

export function parseJwt(token: string): TokenData {
  if (token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(''),
    );
    let val = null;
    try {
      val = JSON.parse(jsonPayload);
    } catch (err) {
      console.error('[Authentication] Could not parse authentication token.');
      return null;
    }
    return val;
  } else {
    return null;
  }
}

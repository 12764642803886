import * as React from 'react';

import { Heading, Icon, Paragraph } from '@/components/design-system';
import { visuallyHidden } from '@/utils/styles';

type RadioCardProps = {
  label: string;
  name: string;
  description: string;
  value: string;
  selectedValue: string;
  buttonText: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: () => void;
};

export const RadioCard: React.FC<RadioCardProps> = ({
  label,
  name,
  description,
  value,
  selectedValue,
  buttonText,
  onChange,
  onClick,
}) => {
  const isActive = selectedValue && selectedValue === value;
  return (
    <div
      css={(theme) => ({
        borderRadius: '8px',
        backgroundColor: theme.colors.gray85,
        color: theme.colors.white,
        overflow: 'hidden',
        display: 'block',
        padding: '32px 24px 32px',
      })}
    >
      <Heading as='div' css={(theme) => ({ color: theme.colors.white })} variant='200'>
        {label}
      </Heading>
      <Paragraph css={(theme) => ({ color: theme.colors.gray40 })}>{description}</Paragraph>
      <label
        css={(theme) => ({
          ...theme.buttons[isActive ? 'secondary' : 'primary'],
          ...theme.buttonSizes['small'],

          textTransform: 'uppercase',
          borderRadius: theme.radii.default,
          fontFamily: 'Libre Franklin',
          cursor: 'pointer',
          display: 'inline-block',
          marginTop: 16,
        })}
      >
        <input
          aria-label={name}
          css={visuallyHidden}
          name={name}
          onChange={onChange}
          onClick={onClick}
          type='radio'
          value={value}
        />
        {isActive ? (
          <>
            <Icon name='CHECK' size={16} />
            &nbsp; Enabled
          </>
        ) : (
          buttonText
        )}
      </label>
    </div>
  );
};

import { SubscriptionPlan } from '@/components/account/subscription/types';
import shopDiscountCodes from '@/data/shop-discount-codes.json';
import { SITE_URL } from '@/lib/constants';

const basePerks = [
  'Read exclusive op-eds and commentary',
  'Enjoy a limited-ad experience across devices',
  'Explore in-depth analysis and investigative reporting op-eds',
];

const today = new Date();
const todaysShopCodes = shopDiscountCodes[today.getFullYear()][today.getMonth()];

const insiderDescription =
  "Tune in to the web's fastest-growing conservative commentary. Featuring Ben Shapiro, Andrew Klavan, Michael Knowles, Matt Walsh, Jeremy Boreing, and more. Watch live, on-demand, and on-the-go. ";

const reader: SubscriptionPlan = {
  planCode: 'reader',
  title: 'Reader',
  price: 4,
  total: 0.99,
  description:
    "Experience all of The Daily Wire's editorial content. That's truthful, accurate and ethical reporting, plus satire, opinion and analysis. 24 hours a day. 365 days a year. An incredible value",
  billingBreakdown: '$4/mo. billed annually after 2 week trial.',
  billingCycle: 'annually',
  billingText: '$48/year',
  perks: [
    ...basePerks,
    [
      'Shop with up to 5% off savings',
      'Click here to save 5% on your DailyWire+ Shop purchases',
      `https://store.dailywire.com/discount/${todaysShopCodes.reader}`,
    ],
    ['Complete access to Bentkey ($99 value)', 'Complete access to Bentkey ($99 value)', 'https://www.bentkey.com/'],
  ],
  uniquePerks: basePerks,
  notIncludedPerks: [],
  buttonText: 'Get 2 weeks for $0.99',
  badge: `${SITE_URL}/svg/badges/readers-pass.png`,
  image: `${SITE_URL}/images/subscribe/read.png`,
};

const reader_new: SubscriptionPlan = {
  planCode: 'reader',
  title: 'Reader',
  price: 4,
  total: 0.99,
  description:
    "Experience all of The Daily Wire's editorial content. That's truthful, accurate and ethical reporting, plus satire, opinion and analysis. 24 hours a day. 365 days a year. An incredible value",
  billingBreakdown: '$4/mo. billed annually after 2 week trial.',
  billingCycle: 'annually',
  billingText: '$48/year',
  perks: [...reader.perks],
  uniquePerks: ['Read exclusive op-eds and commentary', 'Shop with up to 5% off in savings'],
  notIncludedPerks: [],
  buttonText: 'Select Plan',
  // badge: `${SITE_URL}/svg/badges/readers-pass.png`,
  image: `${SITE_URL}/images/subscribe/read.png`,
};

const insider: SubscriptionPlan = {
  planCode: 'insider',
  title: 'Insider Monthly',
  price: 14.99,
  total: 14.99,
  description: insiderDescription,
  billingBreakdown: '$14.99/mo.',
  billingCycle: 'monthly',
  billingText: '$14.99/month',
  perks: [
    ...basePerks,
    'Watch all videos live, on-demand, and on the go',
    'Unlock exclusive Apple TV and Roku apps',
    [
      'Shop with up to 5% off savings',
      'Click here to save 5% on your DailyWire+ Shop purchases',
      `https://store.dailywire.com/discount/${todaysShopCodes.insider}`,
    ],
  ],
  uniquePerks: ['Watch all videos live, on-demand, and on the go', 'Unlock exclusive Apple TV and Roku apps'],
  notIncludedPerks: [],
  buttonText: 'Select Plan',
  badge: `${SITE_URL}/svg/badges/insider.png`,
  image: `${SITE_URL}/images/subscribe/read-watch.png`,
};

const insider_new: SubscriptionPlan = {
  planCode: 'insider',
  title: 'Insider Monthly',
  price: 14.99,
  total: 14.99,
  description: insiderDescription,
  billingBreakdown: '$14.99/mo.',
  billingCycle: 'monthly',
  billingText: '$14.99/month',
  perks: [] as string[],
  uniquePerks: [
    'Watch feature films, shows, and documentaries',
    'Read exclusive op-eds and commentary',
    'Unlock Apple TV, Roku, and mobile apps',
  ],
  notIncludedPerks: ['Shop with up to 10% off savings'],
  buttonText: 'Select Plan',
  image: `${SITE_URL}/images/subscribe/read-watch.png`,
};

const insider_plus: SubscriptionPlan = {
  planCode: 'insider_plus',
  title: 'Insider Annual',
  price: 12.42,
  total: 149,
  description: insiderDescription,
  billingBreakdown: '$12.42/mo.',
  billingCycle: 'annually',
  billingText: '$149/year',
  perks: [
    ...basePerks,
    'Watch all videos live, on-demand, and on the go',
    'Unlock exclusive Apple TV and Roku apps',
    [
      'Shop with up to 10% off savings',
      'Click here to save 10% on your DailyWire+ Shop purchases',
      `https://store.dailywire.com/discount/${todaysShopCodes.insiderPlus}`,
    ],
    ['Complete access to Bentkey ($99 value)', 'Complete access to Bentkey ($99 value)', 'https://www.bentkey.com/'],
  ],
  uniquePerks: [
    'Watch all videos live, on-demand, and on the go',
    'Unlock exclusive Apple TV and Roku apps',
    'Shop with up to 10% off savings',
  ],
  notIncludedPerks: [],
  buttonText: 'Select Plan',
  badge: `${SITE_URL}/svg/badges/insider.png`,
  image: ``,
};

const insider_plus_new: SubscriptionPlan = {
  planCode: 'insider_plus',
  title: 'Insider Annual',
  price: 12.42,
  total: 149,
  description: insiderDescription,
  billingBreakdown: '$12.42/mo.',
  billingCycle: 'annually',
  billingText: '$149/year',
  perks: [],
  uniquePerks: [
    'Watch feature films, shows, and documentaries',
    'Read exclusive op-eds and commentary',
    'Unlock Apple TV, Roku, and mobile apps',
    'Shop with up to 10% off savings',
  ],
  notIncludedPerks: [],
  newPrice: '$9',
  buttonText: 'Select Plan',
  disclaimer: 'with code WOMAN',
  // badge: `${SITE_URL}/svg/badges/insider.png`,
  image: ``,
};

const all_access: SubscriptionPlan = {
  planCode: 'all_access',
  title: 'All Access',
  price: 20.75,
  total: 249,
  description:
    "Experience everything The Daily Wire has to offer with zero locked content and behind the scenes access to your favorite hosts and writers. It doesn't get better than this.",
  billingBreakdown: '$20.75/mo. billed annually',
  billingCycle: 'annually',
  billingText: '$249/year',
  perks: [
    ...basePerks,
    'Watch all videos live, on-demand, and on the go',
    'Unlock exclusive Apple TV and Roku apps',
    'Hang out with your favorite hosts on video live streams',
    'Join live chat discussions with our writers',
    [
      'Shop with up to 20% off savings',
      'Click here to save 20% on your DailyWire+ Shop purchases',
      `https://store.dailywire.com/discount/${todaysShopCodes.allAccess}`,
    ],
    ['Complete access to Bentkey ($99 value)', 'Complete access to Bentkey ($99 value)', 'https://www.bentkey.com/'],
  ],
  uniquePerks: [
    'Hang out with your favorite hosts on video live streams',
    'Join live chat discussions with our writers',
    'Shop with up to 20% off savings',
  ],
  notIncludedPerks: [],
  buttonText: 'Select Plan',
  badge: `${SITE_URL}/svg/badges/all-access.png`,
  image: `${SITE_URL}/images/subscribe/read-watch-discuss.png`,
};

const all_access_new: SubscriptionPlan = {
  planCode: 'all_access',
  title: 'All Access',
  price: 20.75,
  total: 249,
  description:
    "Experience everything The Daily Wire has to offer with zero locked content and behind the scenes access to your favorite hosts and writers. It doesn't get better than this.",
  billingBreakdown: '$20/mo. billed annually',
  billingCycle: 'annually',
  billingText: '$249/year',
  perks: [] as string[],
  uniquePerks: [
    'Hang out with your favorite hosts on live streams',
    'Join live chat discussions with our writers',
    'Shop with up to 20% off savings',
  ],
  notIncludedPerks: [],
  newPrice: '$15',
  buttonText: 'Select Plan',
  // badge: `${SITE_URL}/svg/badges/all-access.png`,
  image: `${SITE_URL}/images/subscribe/read-watch-discuss.png`,
};

const plans = { reader, reader_new, insider, insider_new, insider_plus, insider_plus_new, all_access, all_access_new };

export const getPlanDetails = (planCode: string) => {
  switch (planCode) {
    case 'reader':
      return plans.reader;

    case 'reader_new':
      return plans.reader_new;

    case 'insider_plus':
      return plans.insider_plus;

    case 'insider_plus_new':
      return plans.insider_plus_new;

    case 'insider':
      return plans.insider;

    case 'insider_new':
      return plans.insider_new;

    case 'all_access':
      return plans.all_access;

    case 'all_access_new':
      return plans.all_access_new;

    default:
      return undefined;
  }
};

import { GraphQLClient } from 'graphql-request';
import { print } from 'graphql';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  Json: any;
  Long: any;
};

export type ActivityLog = {
  __typename?: 'ActivityLog';
  id: Scalars['ID'];
  referenceId?: Maybe<Scalars['String']>;
  referenceType?: Maybe<ReferenceType>;
  action?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  clientDetails?: Maybe<Scalars['Json']>;
  updatedBy?: Maybe<User>;
  beforeObj?: Maybe<Scalars['Json']>;
  afterObj?: Maybe<Scalars['Json']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type ActivityLogConnection = {
  __typename?: 'ActivityLogConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<ActivityLogEdge>>;
  aggregate: AggregateActivityLog;
};

export type ActivityLogCreateInput = {
  id?: Maybe<Scalars['ID']>;
  referenceId?: Maybe<Scalars['String']>;
  referenceType?: Maybe<ReferenceType>;
  action?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  clientDetails?: Maybe<Scalars['Json']>;
  updatedBy?: Maybe<UserCreateOneInput>;
  beforeObj?: Maybe<Scalars['Json']>;
  afterObj?: Maybe<Scalars['Json']>;
};

export type ActivityLogEdge = {
  __typename?: 'ActivityLogEdge';
  node: ActivityLog;
  cursor: Scalars['String'];
};

export enum ActivityLogOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  ReferenceIdAsc = 'referenceId_ASC',
  ReferenceIdDesc = 'referenceId_DESC',
  ReferenceTypeAsc = 'referenceType_ASC',
  ReferenceTypeDesc = 'referenceType_DESC',
  ActionAsc = 'action_ASC',
  ActionDesc = 'action_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  ContentAsc = 'content_ASC',
  ContentDesc = 'content_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  ClientDetailsAsc = 'clientDetails_ASC',
  ClientDetailsDesc = 'clientDetails_DESC',
  BeforeObjAsc = 'beforeObj_ASC',
  BeforeObjDesc = 'beforeObj_DESC',
  AfterObjAsc = 'afterObj_ASC',
  AfterObjDesc = 'afterObj_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type ActivityLogPreviousValues = {
  __typename?: 'ActivityLogPreviousValues';
  id: Scalars['ID'];
  referenceId?: Maybe<Scalars['String']>;
  referenceType?: Maybe<ReferenceType>;
  action?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  clientDetails?: Maybe<Scalars['Json']>;
  beforeObj?: Maybe<Scalars['Json']>;
  afterObj?: Maybe<Scalars['Json']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type ActivityLogSubscriptionPayload = {
  __typename?: 'ActivityLogSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<ActivityLog>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<ActivityLogPreviousValues>;
};

export type ActivityLogSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<ActivityLogWhereInput>;
  AND?: Maybe<Array<ActivityLogSubscriptionWhereInput>>;
  OR?: Maybe<Array<ActivityLogSubscriptionWhereInput>>;
  NOT?: Maybe<Array<ActivityLogSubscriptionWhereInput>>;
};

export type ActivityLogUpdateInput = {
  referenceId?: Maybe<Scalars['String']>;
  referenceType?: Maybe<ReferenceType>;
  action?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  clientDetails?: Maybe<Scalars['Json']>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  beforeObj?: Maybe<Scalars['Json']>;
  afterObj?: Maybe<Scalars['Json']>;
};

export type ActivityLogUpdateManyMutationInput = {
  referenceId?: Maybe<Scalars['String']>;
  referenceType?: Maybe<ReferenceType>;
  action?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  clientDetails?: Maybe<Scalars['Json']>;
  beforeObj?: Maybe<Scalars['Json']>;
  afterObj?: Maybe<Scalars['Json']>;
};

export type ActivityLogWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  referenceId?: Maybe<Scalars['String']>;
  referenceId_not?: Maybe<Scalars['String']>;
  referenceId_in?: Maybe<Array<Scalars['String']>>;
  referenceId_not_in?: Maybe<Array<Scalars['String']>>;
  referenceId_lt?: Maybe<Scalars['String']>;
  referenceId_lte?: Maybe<Scalars['String']>;
  referenceId_gt?: Maybe<Scalars['String']>;
  referenceId_gte?: Maybe<Scalars['String']>;
  referenceId_contains?: Maybe<Scalars['String']>;
  referenceId_not_contains?: Maybe<Scalars['String']>;
  referenceId_starts_with?: Maybe<Scalars['String']>;
  referenceId_not_starts_with?: Maybe<Scalars['String']>;
  referenceId_ends_with?: Maybe<Scalars['String']>;
  referenceId_not_ends_with?: Maybe<Scalars['String']>;
  referenceType?: Maybe<ReferenceType>;
  referenceType_not?: Maybe<ReferenceType>;
  referenceType_in?: Maybe<Array<ReferenceType>>;
  referenceType_not_in?: Maybe<Array<ReferenceType>>;
  action?: Maybe<Scalars['String']>;
  action_not?: Maybe<Scalars['String']>;
  action_in?: Maybe<Array<Scalars['String']>>;
  action_not_in?: Maybe<Array<Scalars['String']>>;
  action_lt?: Maybe<Scalars['String']>;
  action_lte?: Maybe<Scalars['String']>;
  action_gt?: Maybe<Scalars['String']>;
  action_gte?: Maybe<Scalars['String']>;
  action_contains?: Maybe<Scalars['String']>;
  action_not_contains?: Maybe<Scalars['String']>;
  action_starts_with?: Maybe<Scalars['String']>;
  action_not_starts_with?: Maybe<Scalars['String']>;
  action_ends_with?: Maybe<Scalars['String']>;
  action_not_ends_with?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Scalars['String']>>;
  title_not_in?: Maybe<Array<Scalars['String']>>;
  title_lt?: Maybe<Scalars['String']>;
  title_lte?: Maybe<Scalars['String']>;
  title_gt?: Maybe<Scalars['String']>;
  title_gte?: Maybe<Scalars['String']>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  title_starts_with?: Maybe<Scalars['String']>;
  title_not_starts_with?: Maybe<Scalars['String']>;
  title_ends_with?: Maybe<Scalars['String']>;
  title_not_ends_with?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  content_not?: Maybe<Scalars['String']>;
  content_in?: Maybe<Array<Scalars['String']>>;
  content_not_in?: Maybe<Array<Scalars['String']>>;
  content_lt?: Maybe<Scalars['String']>;
  content_lte?: Maybe<Scalars['String']>;
  content_gt?: Maybe<Scalars['String']>;
  content_gte?: Maybe<Scalars['String']>;
  content_contains?: Maybe<Scalars['String']>;
  content_not_contains?: Maybe<Scalars['String']>;
  content_starts_with?: Maybe<Scalars['String']>;
  content_not_starts_with?: Maybe<Scalars['String']>;
  content_ends_with?: Maybe<Scalars['String']>;
  content_not_ends_with?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  type_not?: Maybe<Scalars['String']>;
  type_in?: Maybe<Array<Scalars['String']>>;
  type_not_in?: Maybe<Array<Scalars['String']>>;
  type_lt?: Maybe<Scalars['String']>;
  type_lte?: Maybe<Scalars['String']>;
  type_gt?: Maybe<Scalars['String']>;
  type_gte?: Maybe<Scalars['String']>;
  type_contains?: Maybe<Scalars['String']>;
  type_not_contains?: Maybe<Scalars['String']>;
  type_starts_with?: Maybe<Scalars['String']>;
  type_not_starts_with?: Maybe<Scalars['String']>;
  type_ends_with?: Maybe<Scalars['String']>;
  type_not_ends_with?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<UserWhereInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<ActivityLogWhereInput>>;
  OR?: Maybe<Array<ActivityLogWhereInput>>;
  NOT?: Maybe<Array<ActivityLogWhereInput>>;
};

export type ActivityLogWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type AggregateActivityLog = {
  __typename?: 'AggregateActivityLog';
  count: Scalars['Int'];
};

export type AggregateCaption = {
  __typename?: 'AggregateCaption';
  count: Scalars['Int'];
};

export type AggregateCarouselModule = {
  __typename?: 'AggregateCarouselModule';
  count: Scalars['Int'];
};

export type AggregateClip = {
  __typename?: 'AggregateClip';
  count: Scalars['Int'];
};

export type AggregateClipCarouselModuleClips = {
  __typename?: 'AggregateClipCarouselModuleClips';
  count: Scalars['Int'];
};

export type AggregateContentfulWebhook = {
  __typename?: 'AggregateContentfulWebhook';
  count: Scalars['Int'];
};

export type AggregateContinueListeningModule = {
  __typename?: 'AggregateContinueListeningModule';
  count: Scalars['Int'];
};

export type AggregateContinueWatchingModule = {
  __typename?: 'AggregateContinueWatchingModule';
  count: Scalars['Int'];
};

export type AggregateEpisode = {
  __typename?: 'AggregateEpisode';
  count: Scalars['Int'];
};

export type AggregateEpisodeCarouselModuleEpisodes = {
  __typename?: 'AggregateEpisodeCarouselModuleEpisodes';
  count: Scalars['Int'];
};

export type AggregateEpisodeSegment = {
  __typename?: 'AggregateEpisodeSegment';
  count: Scalars['Int'];
};

export type AggregateFreeformCarouselModuleFreeforms = {
  __typename?: 'AggregateFreeformCarouselModuleFreeforms';
  count: Scalars['Int'];
};

export type AggregateFreeformHighlightModule = {
  __typename?: 'AggregateFreeformHighlightModule';
  count: Scalars['Int'];
};

export type AggregateModule = {
  __typename?: 'AggregateModule';
  count: Scalars['Int'];
};

export type AggregateModulePage = {
  __typename?: 'AggregateModulePage';
  count: Scalars['Int'];
};

export type AggregatePodcast = {
  __typename?: 'AggregatePodcast';
  count: Scalars['Int'];
};

export type AggregatePodcastCarouselModulePodcasts = {
  __typename?: 'AggregatePodcastCarouselModulePodcasts';
  count: Scalars['Int'];
};

export type AggregatePodcastEpisode = {
  __typename?: 'AggregatePodcastEpisode';
  count: Scalars['Int'];
};

export type AggregatePodcastEpisodeCarouselModulePodcastEpisodes = {
  __typename?: 'AggregatePodcastEpisodeCarouselModulePodcastEpisodes';
  count: Scalars['Int'];
};

export type AggregatePodcastMigrationEpisode = {
  __typename?: 'AggregatePodcastMigrationEpisode';
  count: Scalars['Int'];
};

export type AggregatePodcastMigrationSeason = {
  __typename?: 'AggregatePodcastMigrationSeason';
  count: Scalars['Int'];
};

export type AggregatePodcastMigrationShow = {
  __typename?: 'AggregatePodcastMigrationShow';
  count: Scalars['Int'];
};

export type AggregatePremiereModule = {
  __typename?: 'AggregatePremiereModule';
  count: Scalars['Int'];
};

export type AggregateSeason = {
  __typename?: 'AggregateSeason';
  count: Scalars['Int'];
};

export type AggregateSeasonEpisode = {
  __typename?: 'AggregateSeasonEpisode';
  count: Scalars['Int'];
};

export type AggregateSegmentAudioStatus = {
  __typename?: 'AggregateSegmentAudioStatus';
  count: Scalars['Int'];
};

export type AggregateShow = {
  __typename?: 'AggregateShow';
  count: Scalars['Int'];
};

export type AggregateShowCarouselModuleShows = {
  __typename?: 'AggregateShowCarouselModuleShows';
  count: Scalars['Int'];
};

export type AggregateUser = {
  __typename?: 'AggregateUser';
  count: Scalars['Int'];
};

export type AggregateVideo = {
  __typename?: 'AggregateVideo';
  count: Scalars['Int'];
};

export type AggregateVideoCarouselModuleVideos = {
  __typename?: 'AggregateVideoCarouselModuleVideos';
  count: Scalars['Int'];
};

export type AssetIdInput = {
  assetId: Scalars['String'];
};

export type BatchPayload = {
  __typename?: 'BatchPayload';
  count: Scalars['Long'];
};

export enum Caption_Types {
  EpisodeSegment = 'EPISODE_SEGMENT',
  Video = 'VIDEO',
  Clip = 'CLIP'
}

export type Caption = {
  __typename?: 'Caption';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  muxTrackId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  message?: Maybe<Scalars['String']>;
};

export type CaptionConnection = {
  __typename?: 'CaptionConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<CaptionEdge>>;
  aggregate: AggregateCaption;
};

export type CaptionCreateInput = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  muxTrackId?: Maybe<Scalars['String']>;
};

export type CaptionCreateManyInput = {
  create?: Maybe<Array<CaptionCreateInput>>;
  connect?: Maybe<Array<CaptionWhereUniqueInput>>;
};

export type CaptionEdge = {
  __typename?: 'CaptionEdge';
  node: Caption;
  cursor: Scalars['String'];
};

export enum CaptionOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC',
  LanguageAsc = 'language_ASC',
  LanguageDesc = 'language_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  MuxTrackIdAsc = 'muxTrackId_ASC',
  MuxTrackIdDesc = 'muxTrackId_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type CaptionPreviousValues = {
  __typename?: 'CaptionPreviousValues';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  muxTrackId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type CaptionScalarWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Scalars['String']>>;
  name_not_in?: Maybe<Array<Scalars['String']>>;
  name_lt?: Maybe<Scalars['String']>;
  name_lte?: Maybe<Scalars['String']>;
  name_gt?: Maybe<Scalars['String']>;
  name_gte?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  url_not?: Maybe<Scalars['String']>;
  url_in?: Maybe<Array<Scalars['String']>>;
  url_not_in?: Maybe<Array<Scalars['String']>>;
  url_lt?: Maybe<Scalars['String']>;
  url_lte?: Maybe<Scalars['String']>;
  url_gt?: Maybe<Scalars['String']>;
  url_gte?: Maybe<Scalars['String']>;
  url_contains?: Maybe<Scalars['String']>;
  url_not_contains?: Maybe<Scalars['String']>;
  url_starts_with?: Maybe<Scalars['String']>;
  url_not_starts_with?: Maybe<Scalars['String']>;
  url_ends_with?: Maybe<Scalars['String']>;
  url_not_ends_with?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  language_not?: Maybe<Scalars['String']>;
  language_in?: Maybe<Array<Scalars['String']>>;
  language_not_in?: Maybe<Array<Scalars['String']>>;
  language_lt?: Maybe<Scalars['String']>;
  language_lte?: Maybe<Scalars['String']>;
  language_gt?: Maybe<Scalars['String']>;
  language_gte?: Maybe<Scalars['String']>;
  language_contains?: Maybe<Scalars['String']>;
  language_not_contains?: Maybe<Scalars['String']>;
  language_starts_with?: Maybe<Scalars['String']>;
  language_not_starts_with?: Maybe<Scalars['String']>;
  language_ends_with?: Maybe<Scalars['String']>;
  language_not_ends_with?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  status_not?: Maybe<Scalars['String']>;
  status_in?: Maybe<Array<Scalars['String']>>;
  status_not_in?: Maybe<Array<Scalars['String']>>;
  status_lt?: Maybe<Scalars['String']>;
  status_lte?: Maybe<Scalars['String']>;
  status_gt?: Maybe<Scalars['String']>;
  status_gte?: Maybe<Scalars['String']>;
  status_contains?: Maybe<Scalars['String']>;
  status_not_contains?: Maybe<Scalars['String']>;
  status_starts_with?: Maybe<Scalars['String']>;
  status_not_starts_with?: Maybe<Scalars['String']>;
  status_ends_with?: Maybe<Scalars['String']>;
  status_not_ends_with?: Maybe<Scalars['String']>;
  muxTrackId?: Maybe<Scalars['String']>;
  muxTrackId_not?: Maybe<Scalars['String']>;
  muxTrackId_in?: Maybe<Array<Scalars['String']>>;
  muxTrackId_not_in?: Maybe<Array<Scalars['String']>>;
  muxTrackId_lt?: Maybe<Scalars['String']>;
  muxTrackId_lte?: Maybe<Scalars['String']>;
  muxTrackId_gt?: Maybe<Scalars['String']>;
  muxTrackId_gte?: Maybe<Scalars['String']>;
  muxTrackId_contains?: Maybe<Scalars['String']>;
  muxTrackId_not_contains?: Maybe<Scalars['String']>;
  muxTrackId_starts_with?: Maybe<Scalars['String']>;
  muxTrackId_not_starts_with?: Maybe<Scalars['String']>;
  muxTrackId_ends_with?: Maybe<Scalars['String']>;
  muxTrackId_not_ends_with?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<CaptionScalarWhereInput>>;
  OR?: Maybe<Array<CaptionScalarWhereInput>>;
  NOT?: Maybe<Array<CaptionScalarWhereInput>>;
};

export type CaptionSubscriptionPayload = {
  __typename?: 'CaptionSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<Caption>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<CaptionPreviousValues>;
};

export type CaptionSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<CaptionWhereInput>;
  AND?: Maybe<Array<CaptionSubscriptionWhereInput>>;
  OR?: Maybe<Array<CaptionSubscriptionWhereInput>>;
  NOT?: Maybe<Array<CaptionSubscriptionWhereInput>>;
};

export type CaptionUpdateDataInput = {
  name?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  muxTrackId?: Maybe<Scalars['String']>;
};

export type CaptionUpdateInput = {
  name?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  muxTrackId?: Maybe<Scalars['String']>;
};

export type CaptionUpdateManyDataInput = {
  name?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  muxTrackId?: Maybe<Scalars['String']>;
};

export type CaptionUpdateManyInput = {
  create?: Maybe<Array<CaptionCreateInput>>;
  update?: Maybe<Array<CaptionUpdateWithWhereUniqueNestedInput>>;
  upsert?: Maybe<Array<CaptionUpsertWithWhereUniqueNestedInput>>;
  delete?: Maybe<Array<CaptionWhereUniqueInput>>;
  connect?: Maybe<Array<CaptionWhereUniqueInput>>;
  set?: Maybe<Array<CaptionWhereUniqueInput>>;
  disconnect?: Maybe<Array<CaptionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CaptionScalarWhereInput>>;
  updateMany?: Maybe<Array<CaptionUpdateManyWithWhereNestedInput>>;
};

export type CaptionUpdateManyMutationInput = {
  name?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  muxTrackId?: Maybe<Scalars['String']>;
};

export type CaptionUpdateManyWithWhereNestedInput = {
  where: CaptionScalarWhereInput;
  data: CaptionUpdateManyDataInput;
};

export type CaptionUpdateWithWhereUniqueNestedInput = {
  where: CaptionWhereUniqueInput;
  data: CaptionUpdateDataInput;
};

export type CaptionUpsertWithWhereUniqueNestedInput = {
  where: CaptionWhereUniqueInput;
  update: CaptionUpdateDataInput;
  create: CaptionCreateInput;
};

export type CaptionWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Scalars['String']>>;
  name_not_in?: Maybe<Array<Scalars['String']>>;
  name_lt?: Maybe<Scalars['String']>;
  name_lte?: Maybe<Scalars['String']>;
  name_gt?: Maybe<Scalars['String']>;
  name_gte?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  url_not?: Maybe<Scalars['String']>;
  url_in?: Maybe<Array<Scalars['String']>>;
  url_not_in?: Maybe<Array<Scalars['String']>>;
  url_lt?: Maybe<Scalars['String']>;
  url_lte?: Maybe<Scalars['String']>;
  url_gt?: Maybe<Scalars['String']>;
  url_gte?: Maybe<Scalars['String']>;
  url_contains?: Maybe<Scalars['String']>;
  url_not_contains?: Maybe<Scalars['String']>;
  url_starts_with?: Maybe<Scalars['String']>;
  url_not_starts_with?: Maybe<Scalars['String']>;
  url_ends_with?: Maybe<Scalars['String']>;
  url_not_ends_with?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  language_not?: Maybe<Scalars['String']>;
  language_in?: Maybe<Array<Scalars['String']>>;
  language_not_in?: Maybe<Array<Scalars['String']>>;
  language_lt?: Maybe<Scalars['String']>;
  language_lte?: Maybe<Scalars['String']>;
  language_gt?: Maybe<Scalars['String']>;
  language_gte?: Maybe<Scalars['String']>;
  language_contains?: Maybe<Scalars['String']>;
  language_not_contains?: Maybe<Scalars['String']>;
  language_starts_with?: Maybe<Scalars['String']>;
  language_not_starts_with?: Maybe<Scalars['String']>;
  language_ends_with?: Maybe<Scalars['String']>;
  language_not_ends_with?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  status_not?: Maybe<Scalars['String']>;
  status_in?: Maybe<Array<Scalars['String']>>;
  status_not_in?: Maybe<Array<Scalars['String']>>;
  status_lt?: Maybe<Scalars['String']>;
  status_lte?: Maybe<Scalars['String']>;
  status_gt?: Maybe<Scalars['String']>;
  status_gte?: Maybe<Scalars['String']>;
  status_contains?: Maybe<Scalars['String']>;
  status_not_contains?: Maybe<Scalars['String']>;
  status_starts_with?: Maybe<Scalars['String']>;
  status_not_starts_with?: Maybe<Scalars['String']>;
  status_ends_with?: Maybe<Scalars['String']>;
  status_not_ends_with?: Maybe<Scalars['String']>;
  muxTrackId?: Maybe<Scalars['String']>;
  muxTrackId_not?: Maybe<Scalars['String']>;
  muxTrackId_in?: Maybe<Array<Scalars['String']>>;
  muxTrackId_not_in?: Maybe<Array<Scalars['String']>>;
  muxTrackId_lt?: Maybe<Scalars['String']>;
  muxTrackId_lte?: Maybe<Scalars['String']>;
  muxTrackId_gt?: Maybe<Scalars['String']>;
  muxTrackId_gte?: Maybe<Scalars['String']>;
  muxTrackId_contains?: Maybe<Scalars['String']>;
  muxTrackId_not_contains?: Maybe<Scalars['String']>;
  muxTrackId_starts_with?: Maybe<Scalars['String']>;
  muxTrackId_not_starts_with?: Maybe<Scalars['String']>;
  muxTrackId_ends_with?: Maybe<Scalars['String']>;
  muxTrackId_not_ends_with?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<CaptionWhereInput>>;
  OR?: Maybe<Array<CaptionWhereInput>>;
  NOT?: Maybe<Array<CaptionWhereInput>>;
};

export type CaptionWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type CarouselModule = {
  __typename?: 'CarouselModule';
  id: Scalars['ID'];
  title: Scalars['String'];
  type: CarouselModuleType;
  carouselEpisodes?: Maybe<Array<EpisodeCarouselModuleEpisodes>>;
  carouselPodcastEpisodes?: Maybe<Array<PodcastEpisodeCarouselModulePodcastEpisodes>>;
  carouselVideos?: Maybe<Array<VideoCarouselModuleVideos>>;
  carouselClips?: Maybe<Array<ClipCarouselModuleClips>>;
  carouselShows?: Maybe<Array<ShowCarouselModuleShows>>;
  carouselPodcasts?: Maybe<Array<PodcastCarouselModulePodcasts>>;
  carouselFreeforms?: Maybe<Array<FreeformCarouselModuleFreeforms>>;
  orientation?: Maybe<ShowCarouselImageOrientation>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};


export type CarouselModuleCarouselEpisodesArgs = {
  where?: Maybe<EpisodeCarouselModuleEpisodesWhereInput>;
  orderBy?: Maybe<EpisodeCarouselModuleEpisodesOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type CarouselModuleCarouselPodcastEpisodesArgs = {
  where?: Maybe<PodcastEpisodeCarouselModulePodcastEpisodesWhereInput>;
  orderBy?: Maybe<PodcastEpisodeCarouselModulePodcastEpisodesOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type CarouselModuleCarouselVideosArgs = {
  where?: Maybe<VideoCarouselModuleVideosWhereInput>;
  orderBy?: Maybe<VideoCarouselModuleVideosOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type CarouselModuleCarouselClipsArgs = {
  where?: Maybe<ClipCarouselModuleClipsWhereInput>;
  orderBy?: Maybe<ClipCarouselModuleClipsOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type CarouselModuleCarouselShowsArgs = {
  where?: Maybe<ShowCarouselModuleShowsWhereInput>;
  orderBy?: Maybe<ShowCarouselModuleShowsOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type CarouselModuleCarouselPodcastsArgs = {
  where?: Maybe<PodcastCarouselModulePodcastsWhereInput>;
  orderBy?: Maybe<PodcastCarouselModulePodcastsOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type CarouselModuleCarouselFreeformsArgs = {
  where?: Maybe<FreeformCarouselModuleFreeformsWhereInput>;
  orderBy?: Maybe<FreeformCarouselModuleFreeformsOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CarouselModuleConnection = {
  __typename?: 'CarouselModuleConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<CarouselModuleEdge>>;
  aggregate: AggregateCarouselModule;
};

export type CarouselModuleCreateInput = {
  id?: Maybe<Scalars['ID']>;
  title: Scalars['String'];
  type: CarouselModuleType;
  carouselEpisodes?: Maybe<EpisodeCarouselModuleEpisodesCreateManyWithoutEpisodeCarouselInput>;
  carouselPodcastEpisodes?: Maybe<PodcastEpisodeCarouselModulePodcastEpisodesCreateManyWithoutPodcastEpisodeCarouselInput>;
  carouselVideos?: Maybe<VideoCarouselModuleVideosCreateManyWithoutVideoCarouselInput>;
  carouselClips?: Maybe<ClipCarouselModuleClipsCreateManyWithoutClipCarouselInput>;
  carouselShows?: Maybe<ShowCarouselModuleShowsCreateManyWithoutShowCarouselInput>;
  carouselPodcasts?: Maybe<PodcastCarouselModulePodcastsCreateManyWithoutPodcastCarouselInput>;
  carouselFreeforms?: Maybe<FreeformCarouselModuleFreeformsCreateManyWithoutFreeformCarouselInput>;
  orientation?: Maybe<ShowCarouselImageOrientation>;
};

export type CarouselModuleCreateOneWithoutCarouselClipsInput = {
  create?: Maybe<CarouselModuleCreateWithoutCarouselClipsInput>;
  connect?: Maybe<CarouselModuleWhereUniqueInput>;
};

export type CarouselModuleCreateOneWithoutCarouselEpisodesInput = {
  create?: Maybe<CarouselModuleCreateWithoutCarouselEpisodesInput>;
  connect?: Maybe<CarouselModuleWhereUniqueInput>;
};

export type CarouselModuleCreateOneWithoutCarouselFreeformsInput = {
  create?: Maybe<CarouselModuleCreateWithoutCarouselFreeformsInput>;
  connect?: Maybe<CarouselModuleWhereUniqueInput>;
};

export type CarouselModuleCreateOneWithoutCarouselPodcastEpisodesInput = {
  create?: Maybe<CarouselModuleCreateWithoutCarouselPodcastEpisodesInput>;
  connect?: Maybe<CarouselModuleWhereUniqueInput>;
};

export type CarouselModuleCreateOneWithoutCarouselPodcastsInput = {
  create?: Maybe<CarouselModuleCreateWithoutCarouselPodcastsInput>;
  connect?: Maybe<CarouselModuleWhereUniqueInput>;
};

export type CarouselModuleCreateOneWithoutCarouselShowsInput = {
  create?: Maybe<CarouselModuleCreateWithoutCarouselShowsInput>;
  connect?: Maybe<CarouselModuleWhereUniqueInput>;
};

export type CarouselModuleCreateOneWithoutCarouselVideosInput = {
  create?: Maybe<CarouselModuleCreateWithoutCarouselVideosInput>;
  connect?: Maybe<CarouselModuleWhereUniqueInput>;
};

export type CarouselModuleCreateWithoutCarouselClipsInput = {
  id?: Maybe<Scalars['ID']>;
  title: Scalars['String'];
  type: CarouselModuleType;
  carouselEpisodes?: Maybe<EpisodeCarouselModuleEpisodesCreateManyWithoutEpisodeCarouselInput>;
  carouselPodcastEpisodes?: Maybe<PodcastEpisodeCarouselModulePodcastEpisodesCreateManyWithoutPodcastEpisodeCarouselInput>;
  carouselVideos?: Maybe<VideoCarouselModuleVideosCreateManyWithoutVideoCarouselInput>;
  carouselShows?: Maybe<ShowCarouselModuleShowsCreateManyWithoutShowCarouselInput>;
  carouselPodcasts?: Maybe<PodcastCarouselModulePodcastsCreateManyWithoutPodcastCarouselInput>;
  carouselFreeforms?: Maybe<FreeformCarouselModuleFreeformsCreateManyWithoutFreeformCarouselInput>;
  orientation?: Maybe<ShowCarouselImageOrientation>;
};

export type CarouselModuleCreateWithoutCarouselEpisodesInput = {
  id?: Maybe<Scalars['ID']>;
  title: Scalars['String'];
  type: CarouselModuleType;
  carouselPodcastEpisodes?: Maybe<PodcastEpisodeCarouselModulePodcastEpisodesCreateManyWithoutPodcastEpisodeCarouselInput>;
  carouselVideos?: Maybe<VideoCarouselModuleVideosCreateManyWithoutVideoCarouselInput>;
  carouselClips?: Maybe<ClipCarouselModuleClipsCreateManyWithoutClipCarouselInput>;
  carouselShows?: Maybe<ShowCarouselModuleShowsCreateManyWithoutShowCarouselInput>;
  carouselPodcasts?: Maybe<PodcastCarouselModulePodcastsCreateManyWithoutPodcastCarouselInput>;
  carouselFreeforms?: Maybe<FreeformCarouselModuleFreeformsCreateManyWithoutFreeformCarouselInput>;
  orientation?: Maybe<ShowCarouselImageOrientation>;
};

export type CarouselModuleCreateWithoutCarouselFreeformsInput = {
  id?: Maybe<Scalars['ID']>;
  title: Scalars['String'];
  type: CarouselModuleType;
  carouselEpisodes?: Maybe<EpisodeCarouselModuleEpisodesCreateManyWithoutEpisodeCarouselInput>;
  carouselPodcastEpisodes?: Maybe<PodcastEpisodeCarouselModulePodcastEpisodesCreateManyWithoutPodcastEpisodeCarouselInput>;
  carouselVideos?: Maybe<VideoCarouselModuleVideosCreateManyWithoutVideoCarouselInput>;
  carouselClips?: Maybe<ClipCarouselModuleClipsCreateManyWithoutClipCarouselInput>;
  carouselShows?: Maybe<ShowCarouselModuleShowsCreateManyWithoutShowCarouselInput>;
  carouselPodcasts?: Maybe<PodcastCarouselModulePodcastsCreateManyWithoutPodcastCarouselInput>;
  orientation?: Maybe<ShowCarouselImageOrientation>;
};

export type CarouselModuleCreateWithoutCarouselPodcastEpisodesInput = {
  id?: Maybe<Scalars['ID']>;
  title: Scalars['String'];
  type: CarouselModuleType;
  carouselEpisodes?: Maybe<EpisodeCarouselModuleEpisodesCreateManyWithoutEpisodeCarouselInput>;
  carouselVideos?: Maybe<VideoCarouselModuleVideosCreateManyWithoutVideoCarouselInput>;
  carouselClips?: Maybe<ClipCarouselModuleClipsCreateManyWithoutClipCarouselInput>;
  carouselShows?: Maybe<ShowCarouselModuleShowsCreateManyWithoutShowCarouselInput>;
  carouselPodcasts?: Maybe<PodcastCarouselModulePodcastsCreateManyWithoutPodcastCarouselInput>;
  carouselFreeforms?: Maybe<FreeformCarouselModuleFreeformsCreateManyWithoutFreeformCarouselInput>;
  orientation?: Maybe<ShowCarouselImageOrientation>;
};

export type CarouselModuleCreateWithoutCarouselPodcastsInput = {
  id?: Maybe<Scalars['ID']>;
  title: Scalars['String'];
  type: CarouselModuleType;
  carouselEpisodes?: Maybe<EpisodeCarouselModuleEpisodesCreateManyWithoutEpisodeCarouselInput>;
  carouselPodcastEpisodes?: Maybe<PodcastEpisodeCarouselModulePodcastEpisodesCreateManyWithoutPodcastEpisodeCarouselInput>;
  carouselVideos?: Maybe<VideoCarouselModuleVideosCreateManyWithoutVideoCarouselInput>;
  carouselClips?: Maybe<ClipCarouselModuleClipsCreateManyWithoutClipCarouselInput>;
  carouselShows?: Maybe<ShowCarouselModuleShowsCreateManyWithoutShowCarouselInput>;
  carouselFreeforms?: Maybe<FreeformCarouselModuleFreeformsCreateManyWithoutFreeformCarouselInput>;
  orientation?: Maybe<ShowCarouselImageOrientation>;
};

export type CarouselModuleCreateWithoutCarouselShowsInput = {
  id?: Maybe<Scalars['ID']>;
  title: Scalars['String'];
  type: CarouselModuleType;
  carouselEpisodes?: Maybe<EpisodeCarouselModuleEpisodesCreateManyWithoutEpisodeCarouselInput>;
  carouselPodcastEpisodes?: Maybe<PodcastEpisodeCarouselModulePodcastEpisodesCreateManyWithoutPodcastEpisodeCarouselInput>;
  carouselVideos?: Maybe<VideoCarouselModuleVideosCreateManyWithoutVideoCarouselInput>;
  carouselClips?: Maybe<ClipCarouselModuleClipsCreateManyWithoutClipCarouselInput>;
  carouselPodcasts?: Maybe<PodcastCarouselModulePodcastsCreateManyWithoutPodcastCarouselInput>;
  carouselFreeforms?: Maybe<FreeformCarouselModuleFreeformsCreateManyWithoutFreeformCarouselInput>;
  orientation?: Maybe<ShowCarouselImageOrientation>;
};

export type CarouselModuleCreateWithoutCarouselVideosInput = {
  id?: Maybe<Scalars['ID']>;
  title: Scalars['String'];
  type: CarouselModuleType;
  carouselEpisodes?: Maybe<EpisodeCarouselModuleEpisodesCreateManyWithoutEpisodeCarouselInput>;
  carouselPodcastEpisodes?: Maybe<PodcastEpisodeCarouselModulePodcastEpisodesCreateManyWithoutPodcastEpisodeCarouselInput>;
  carouselClips?: Maybe<ClipCarouselModuleClipsCreateManyWithoutClipCarouselInput>;
  carouselShows?: Maybe<ShowCarouselModuleShowsCreateManyWithoutShowCarouselInput>;
  carouselPodcasts?: Maybe<PodcastCarouselModulePodcastsCreateManyWithoutPodcastCarouselInput>;
  carouselFreeforms?: Maybe<FreeformCarouselModuleFreeformsCreateManyWithoutFreeformCarouselInput>;
  orientation?: Maybe<ShowCarouselImageOrientation>;
};

export type CarouselModuleEdge = {
  __typename?: 'CarouselModuleEdge';
  node: CarouselModule;
  cursor: Scalars['String'];
};

export enum CarouselModuleOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  OrientationAsc = 'orientation_ASC',
  OrientationDesc = 'orientation_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type CarouselModulePreviousValues = {
  __typename?: 'CarouselModulePreviousValues';
  id: Scalars['ID'];
  title: Scalars['String'];
  type: CarouselModuleType;
  orientation?: Maybe<ShowCarouselImageOrientation>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type CarouselModuleSubscriptionPayload = {
  __typename?: 'CarouselModuleSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<CarouselModule>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<CarouselModulePreviousValues>;
};

export type CarouselModuleSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<CarouselModuleWhereInput>;
  AND?: Maybe<Array<CarouselModuleSubscriptionWhereInput>>;
  OR?: Maybe<Array<CarouselModuleSubscriptionWhereInput>>;
  NOT?: Maybe<Array<CarouselModuleSubscriptionWhereInput>>;
};

export enum CarouselModuleType {
  EpisodeCarousel = 'EPISODE_CAROUSEL',
  PodcastEpisodeCarousel = 'PODCAST_EPISODE_CAROUSEL',
  VideoCarousel = 'VIDEO_CAROUSEL',
  ShowCarousel = 'SHOW_CAROUSEL',
  FreeformCarousel = 'FREEFORM_CAROUSEL',
  PodcastCarousel = 'PODCAST_CAROUSEL',
  ClipCarousel = 'CLIP_CAROUSEL'
}

export type CarouselModuleUpdateInput = {
  title?: Maybe<Scalars['String']>;
  type?: Maybe<CarouselModuleType>;
  carouselEpisodes?: Maybe<EpisodeCarouselModuleEpisodesUpdateManyWithoutEpisodeCarouselInput>;
  carouselPodcastEpisodes?: Maybe<PodcastEpisodeCarouselModulePodcastEpisodesUpdateManyWithoutPodcastEpisodeCarouselInput>;
  carouselVideos?: Maybe<VideoCarouselModuleVideosUpdateManyWithoutVideoCarouselInput>;
  carouselClips?: Maybe<ClipCarouselModuleClipsUpdateManyWithoutClipCarouselInput>;
  carouselShows?: Maybe<ShowCarouselModuleShowsUpdateManyWithoutShowCarouselInput>;
  carouselPodcasts?: Maybe<PodcastCarouselModulePodcastsUpdateManyWithoutPodcastCarouselInput>;
  carouselFreeforms?: Maybe<FreeformCarouselModuleFreeformsUpdateManyWithoutFreeformCarouselInput>;
  orientation?: Maybe<ShowCarouselImageOrientation>;
};

export type CarouselModuleUpdateManyMutationInput = {
  title?: Maybe<Scalars['String']>;
  type?: Maybe<CarouselModuleType>;
  orientation?: Maybe<ShowCarouselImageOrientation>;
};

export type CarouselModuleUpdateOneWithoutCarouselClipsInput = {
  create?: Maybe<CarouselModuleCreateWithoutCarouselClipsInput>;
  update?: Maybe<CarouselModuleUpdateWithoutCarouselClipsDataInput>;
  upsert?: Maybe<CarouselModuleUpsertWithoutCarouselClipsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<CarouselModuleWhereUniqueInput>;
};

export type CarouselModuleUpdateOneWithoutCarouselEpisodesInput = {
  create?: Maybe<CarouselModuleCreateWithoutCarouselEpisodesInput>;
  update?: Maybe<CarouselModuleUpdateWithoutCarouselEpisodesDataInput>;
  upsert?: Maybe<CarouselModuleUpsertWithoutCarouselEpisodesInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<CarouselModuleWhereUniqueInput>;
};

export type CarouselModuleUpdateOneWithoutCarouselFreeformsInput = {
  create?: Maybe<CarouselModuleCreateWithoutCarouselFreeformsInput>;
  update?: Maybe<CarouselModuleUpdateWithoutCarouselFreeformsDataInput>;
  upsert?: Maybe<CarouselModuleUpsertWithoutCarouselFreeformsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<CarouselModuleWhereUniqueInput>;
};

export type CarouselModuleUpdateOneWithoutCarouselPodcastEpisodesInput = {
  create?: Maybe<CarouselModuleCreateWithoutCarouselPodcastEpisodesInput>;
  update?: Maybe<CarouselModuleUpdateWithoutCarouselPodcastEpisodesDataInput>;
  upsert?: Maybe<CarouselModuleUpsertWithoutCarouselPodcastEpisodesInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<CarouselModuleWhereUniqueInput>;
};

export type CarouselModuleUpdateOneWithoutCarouselPodcastsInput = {
  create?: Maybe<CarouselModuleCreateWithoutCarouselPodcastsInput>;
  update?: Maybe<CarouselModuleUpdateWithoutCarouselPodcastsDataInput>;
  upsert?: Maybe<CarouselModuleUpsertWithoutCarouselPodcastsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<CarouselModuleWhereUniqueInput>;
};

export type CarouselModuleUpdateOneWithoutCarouselShowsInput = {
  create?: Maybe<CarouselModuleCreateWithoutCarouselShowsInput>;
  update?: Maybe<CarouselModuleUpdateWithoutCarouselShowsDataInput>;
  upsert?: Maybe<CarouselModuleUpsertWithoutCarouselShowsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<CarouselModuleWhereUniqueInput>;
};

export type CarouselModuleUpdateOneWithoutCarouselVideosInput = {
  create?: Maybe<CarouselModuleCreateWithoutCarouselVideosInput>;
  update?: Maybe<CarouselModuleUpdateWithoutCarouselVideosDataInput>;
  upsert?: Maybe<CarouselModuleUpsertWithoutCarouselVideosInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<CarouselModuleWhereUniqueInput>;
};

export type CarouselModuleUpdateWithoutCarouselClipsDataInput = {
  title?: Maybe<Scalars['String']>;
  type?: Maybe<CarouselModuleType>;
  carouselEpisodes?: Maybe<EpisodeCarouselModuleEpisodesUpdateManyWithoutEpisodeCarouselInput>;
  carouselPodcastEpisodes?: Maybe<PodcastEpisodeCarouselModulePodcastEpisodesUpdateManyWithoutPodcastEpisodeCarouselInput>;
  carouselVideos?: Maybe<VideoCarouselModuleVideosUpdateManyWithoutVideoCarouselInput>;
  carouselShows?: Maybe<ShowCarouselModuleShowsUpdateManyWithoutShowCarouselInput>;
  carouselPodcasts?: Maybe<PodcastCarouselModulePodcastsUpdateManyWithoutPodcastCarouselInput>;
  carouselFreeforms?: Maybe<FreeformCarouselModuleFreeformsUpdateManyWithoutFreeformCarouselInput>;
  orientation?: Maybe<ShowCarouselImageOrientation>;
};

export type CarouselModuleUpdateWithoutCarouselEpisodesDataInput = {
  title?: Maybe<Scalars['String']>;
  type?: Maybe<CarouselModuleType>;
  carouselPodcastEpisodes?: Maybe<PodcastEpisodeCarouselModulePodcastEpisodesUpdateManyWithoutPodcastEpisodeCarouselInput>;
  carouselVideos?: Maybe<VideoCarouselModuleVideosUpdateManyWithoutVideoCarouselInput>;
  carouselClips?: Maybe<ClipCarouselModuleClipsUpdateManyWithoutClipCarouselInput>;
  carouselShows?: Maybe<ShowCarouselModuleShowsUpdateManyWithoutShowCarouselInput>;
  carouselPodcasts?: Maybe<PodcastCarouselModulePodcastsUpdateManyWithoutPodcastCarouselInput>;
  carouselFreeforms?: Maybe<FreeformCarouselModuleFreeformsUpdateManyWithoutFreeformCarouselInput>;
  orientation?: Maybe<ShowCarouselImageOrientation>;
};

export type CarouselModuleUpdateWithoutCarouselFreeformsDataInput = {
  title?: Maybe<Scalars['String']>;
  type?: Maybe<CarouselModuleType>;
  carouselEpisodes?: Maybe<EpisodeCarouselModuleEpisodesUpdateManyWithoutEpisodeCarouselInput>;
  carouselPodcastEpisodes?: Maybe<PodcastEpisodeCarouselModulePodcastEpisodesUpdateManyWithoutPodcastEpisodeCarouselInput>;
  carouselVideos?: Maybe<VideoCarouselModuleVideosUpdateManyWithoutVideoCarouselInput>;
  carouselClips?: Maybe<ClipCarouselModuleClipsUpdateManyWithoutClipCarouselInput>;
  carouselShows?: Maybe<ShowCarouselModuleShowsUpdateManyWithoutShowCarouselInput>;
  carouselPodcasts?: Maybe<PodcastCarouselModulePodcastsUpdateManyWithoutPodcastCarouselInput>;
  orientation?: Maybe<ShowCarouselImageOrientation>;
};

export type CarouselModuleUpdateWithoutCarouselPodcastEpisodesDataInput = {
  title?: Maybe<Scalars['String']>;
  type?: Maybe<CarouselModuleType>;
  carouselEpisodes?: Maybe<EpisodeCarouselModuleEpisodesUpdateManyWithoutEpisodeCarouselInput>;
  carouselVideos?: Maybe<VideoCarouselModuleVideosUpdateManyWithoutVideoCarouselInput>;
  carouselClips?: Maybe<ClipCarouselModuleClipsUpdateManyWithoutClipCarouselInput>;
  carouselShows?: Maybe<ShowCarouselModuleShowsUpdateManyWithoutShowCarouselInput>;
  carouselPodcasts?: Maybe<PodcastCarouselModulePodcastsUpdateManyWithoutPodcastCarouselInput>;
  carouselFreeforms?: Maybe<FreeformCarouselModuleFreeformsUpdateManyWithoutFreeformCarouselInput>;
  orientation?: Maybe<ShowCarouselImageOrientation>;
};

export type CarouselModuleUpdateWithoutCarouselPodcastsDataInput = {
  title?: Maybe<Scalars['String']>;
  type?: Maybe<CarouselModuleType>;
  carouselEpisodes?: Maybe<EpisodeCarouselModuleEpisodesUpdateManyWithoutEpisodeCarouselInput>;
  carouselPodcastEpisodes?: Maybe<PodcastEpisodeCarouselModulePodcastEpisodesUpdateManyWithoutPodcastEpisodeCarouselInput>;
  carouselVideos?: Maybe<VideoCarouselModuleVideosUpdateManyWithoutVideoCarouselInput>;
  carouselClips?: Maybe<ClipCarouselModuleClipsUpdateManyWithoutClipCarouselInput>;
  carouselShows?: Maybe<ShowCarouselModuleShowsUpdateManyWithoutShowCarouselInput>;
  carouselFreeforms?: Maybe<FreeformCarouselModuleFreeformsUpdateManyWithoutFreeformCarouselInput>;
  orientation?: Maybe<ShowCarouselImageOrientation>;
};

export type CarouselModuleUpdateWithoutCarouselShowsDataInput = {
  title?: Maybe<Scalars['String']>;
  type?: Maybe<CarouselModuleType>;
  carouselEpisodes?: Maybe<EpisodeCarouselModuleEpisodesUpdateManyWithoutEpisodeCarouselInput>;
  carouselPodcastEpisodes?: Maybe<PodcastEpisodeCarouselModulePodcastEpisodesUpdateManyWithoutPodcastEpisodeCarouselInput>;
  carouselVideos?: Maybe<VideoCarouselModuleVideosUpdateManyWithoutVideoCarouselInput>;
  carouselClips?: Maybe<ClipCarouselModuleClipsUpdateManyWithoutClipCarouselInput>;
  carouselPodcasts?: Maybe<PodcastCarouselModulePodcastsUpdateManyWithoutPodcastCarouselInput>;
  carouselFreeforms?: Maybe<FreeformCarouselModuleFreeformsUpdateManyWithoutFreeformCarouselInput>;
  orientation?: Maybe<ShowCarouselImageOrientation>;
};

export type CarouselModuleUpdateWithoutCarouselVideosDataInput = {
  title?: Maybe<Scalars['String']>;
  type?: Maybe<CarouselModuleType>;
  carouselEpisodes?: Maybe<EpisodeCarouselModuleEpisodesUpdateManyWithoutEpisodeCarouselInput>;
  carouselPodcastEpisodes?: Maybe<PodcastEpisodeCarouselModulePodcastEpisodesUpdateManyWithoutPodcastEpisodeCarouselInput>;
  carouselClips?: Maybe<ClipCarouselModuleClipsUpdateManyWithoutClipCarouselInput>;
  carouselShows?: Maybe<ShowCarouselModuleShowsUpdateManyWithoutShowCarouselInput>;
  carouselPodcasts?: Maybe<PodcastCarouselModulePodcastsUpdateManyWithoutPodcastCarouselInput>;
  carouselFreeforms?: Maybe<FreeformCarouselModuleFreeformsUpdateManyWithoutFreeformCarouselInput>;
  orientation?: Maybe<ShowCarouselImageOrientation>;
};

export type CarouselModuleUpsertWithoutCarouselClipsInput = {
  update: CarouselModuleUpdateWithoutCarouselClipsDataInput;
  create: CarouselModuleCreateWithoutCarouselClipsInput;
};

export type CarouselModuleUpsertWithoutCarouselEpisodesInput = {
  update: CarouselModuleUpdateWithoutCarouselEpisodesDataInput;
  create: CarouselModuleCreateWithoutCarouselEpisodesInput;
};

export type CarouselModuleUpsertWithoutCarouselFreeformsInput = {
  update: CarouselModuleUpdateWithoutCarouselFreeformsDataInput;
  create: CarouselModuleCreateWithoutCarouselFreeformsInput;
};

export type CarouselModuleUpsertWithoutCarouselPodcastEpisodesInput = {
  update: CarouselModuleUpdateWithoutCarouselPodcastEpisodesDataInput;
  create: CarouselModuleCreateWithoutCarouselPodcastEpisodesInput;
};

export type CarouselModuleUpsertWithoutCarouselPodcastsInput = {
  update: CarouselModuleUpdateWithoutCarouselPodcastsDataInput;
  create: CarouselModuleCreateWithoutCarouselPodcastsInput;
};

export type CarouselModuleUpsertWithoutCarouselShowsInput = {
  update: CarouselModuleUpdateWithoutCarouselShowsDataInput;
  create: CarouselModuleCreateWithoutCarouselShowsInput;
};

export type CarouselModuleUpsertWithoutCarouselVideosInput = {
  update: CarouselModuleUpdateWithoutCarouselVideosDataInput;
  create: CarouselModuleCreateWithoutCarouselVideosInput;
};

export type CarouselModuleWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Scalars['String']>>;
  title_not_in?: Maybe<Array<Scalars['String']>>;
  title_lt?: Maybe<Scalars['String']>;
  title_lte?: Maybe<Scalars['String']>;
  title_gt?: Maybe<Scalars['String']>;
  title_gte?: Maybe<Scalars['String']>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  title_starts_with?: Maybe<Scalars['String']>;
  title_not_starts_with?: Maybe<Scalars['String']>;
  title_ends_with?: Maybe<Scalars['String']>;
  title_not_ends_with?: Maybe<Scalars['String']>;
  type?: Maybe<CarouselModuleType>;
  type_not?: Maybe<CarouselModuleType>;
  type_in?: Maybe<Array<CarouselModuleType>>;
  type_not_in?: Maybe<Array<CarouselModuleType>>;
  carouselEpisodes_every?: Maybe<EpisodeCarouselModuleEpisodesWhereInput>;
  carouselEpisodes_some?: Maybe<EpisodeCarouselModuleEpisodesWhereInput>;
  carouselEpisodes_none?: Maybe<EpisodeCarouselModuleEpisodesWhereInput>;
  carouselPodcastEpisodes_every?: Maybe<PodcastEpisodeCarouselModulePodcastEpisodesWhereInput>;
  carouselPodcastEpisodes_some?: Maybe<PodcastEpisodeCarouselModulePodcastEpisodesWhereInput>;
  carouselPodcastEpisodes_none?: Maybe<PodcastEpisodeCarouselModulePodcastEpisodesWhereInput>;
  carouselVideos_every?: Maybe<VideoCarouselModuleVideosWhereInput>;
  carouselVideos_some?: Maybe<VideoCarouselModuleVideosWhereInput>;
  carouselVideos_none?: Maybe<VideoCarouselModuleVideosWhereInput>;
  carouselClips_every?: Maybe<ClipCarouselModuleClipsWhereInput>;
  carouselClips_some?: Maybe<ClipCarouselModuleClipsWhereInput>;
  carouselClips_none?: Maybe<ClipCarouselModuleClipsWhereInput>;
  carouselShows_every?: Maybe<ShowCarouselModuleShowsWhereInput>;
  carouselShows_some?: Maybe<ShowCarouselModuleShowsWhereInput>;
  carouselShows_none?: Maybe<ShowCarouselModuleShowsWhereInput>;
  carouselPodcasts_every?: Maybe<PodcastCarouselModulePodcastsWhereInput>;
  carouselPodcasts_some?: Maybe<PodcastCarouselModulePodcastsWhereInput>;
  carouselPodcasts_none?: Maybe<PodcastCarouselModulePodcastsWhereInput>;
  carouselFreeforms_every?: Maybe<FreeformCarouselModuleFreeformsWhereInput>;
  carouselFreeforms_some?: Maybe<FreeformCarouselModuleFreeformsWhereInput>;
  carouselFreeforms_none?: Maybe<FreeformCarouselModuleFreeformsWhereInput>;
  orientation?: Maybe<ShowCarouselImageOrientation>;
  orientation_not?: Maybe<ShowCarouselImageOrientation>;
  orientation_in?: Maybe<Array<ShowCarouselImageOrientation>>;
  orientation_not_in?: Maybe<Array<ShowCarouselImageOrientation>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<CarouselModuleWhereInput>>;
  OR?: Maybe<Array<CarouselModuleWhereInput>>;
  NOT?: Maybe<Array<CarouselModuleWhereInput>>;
};

export type CarouselModuleWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type Clip = {
  __typename?: 'Clip';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  clipNumber?: Maybe<Scalars['String']>;
  status?: Maybe<ClipStatus>;
  show?: Maybe<Show>;
  video?: Maybe<Video>;
  captions?: Maybe<Array<Caption>>;
  videoState?: Maybe<Scalars['String']>;
  muxAssetId?: Maybe<Scalars['String']>;
  uploadId?: Maybe<Scalars['String']>;
  muxPlaybackId?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  playbackPolicy?: Maybe<Scalars['String']>;
  videoURL?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
  clipAccess: Array<Scalars['String']>;
  createdBy?: Maybe<User>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  message?: Maybe<Scalars['String']>;
  watchTime?: Maybe<Scalars['Float']>;
  muxStoryBoardURL?: Maybe<Scalars['String']>;
  carouselClipOrder?: Maybe<Scalars['Int']>;
  carouselClipId?: Maybe<Scalars['ID']>;
};


export type ClipCaptionsArgs = {
  where?: Maybe<CaptionWhereInput>;
  orderBy?: Maybe<CaptionOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type ClipCarousel = {
  __typename?: 'ClipCarousel';
  id?: Maybe<Scalars['ID']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  nextOrder?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  clips?: Maybe<Array<Maybe<Clip>>>;
  layout?: Maybe<Scalars['String']>;
};

export type ClipCarouselCustomInput = {
  clipId: Scalars['ID'];
  order?: Maybe<Scalars['Int']>;
};

export type ClipCarouselModuleClips = {
  __typename?: 'ClipCarouselModuleClips';
  id: Scalars['ID'];
  order?: Maybe<Scalars['Int']>;
  clip?: Maybe<Clip>;
  clipCarousel?: Maybe<CarouselModule>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type ClipCarouselModuleClipsConnection = {
  __typename?: 'ClipCarouselModuleClipsConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<ClipCarouselModuleClipsEdge>>;
  aggregate: AggregateClipCarouselModuleClips;
};

export type ClipCarouselModuleClipsCreateInput = {
  id?: Maybe<Scalars['ID']>;
  order?: Maybe<Scalars['Int']>;
  clip?: Maybe<ClipCreateOneInput>;
  clipCarousel?: Maybe<CarouselModuleCreateOneWithoutCarouselClipsInput>;
};

export type ClipCarouselModuleClipsCreateManyWithoutClipCarouselInput = {
  create?: Maybe<Array<ClipCarouselModuleClipsCreateWithoutClipCarouselInput>>;
  connect?: Maybe<Array<ClipCarouselModuleClipsWhereUniqueInput>>;
};

export type ClipCarouselModuleClipsCreateWithoutClipCarouselInput = {
  id?: Maybe<Scalars['ID']>;
  order?: Maybe<Scalars['Int']>;
  clip?: Maybe<ClipCreateOneInput>;
};

export type ClipCarouselModuleClipsEdge = {
  __typename?: 'ClipCarouselModuleClipsEdge';
  node: ClipCarouselModuleClips;
  cursor: Scalars['String'];
};

export enum ClipCarouselModuleClipsOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  OrderAsc = 'order_ASC',
  OrderDesc = 'order_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type ClipCarouselModuleClipsPreviousValues = {
  __typename?: 'ClipCarouselModuleClipsPreviousValues';
  id: Scalars['ID'];
  order?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type ClipCarouselModuleClipsScalarWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  order?: Maybe<Scalars['Int']>;
  order_not?: Maybe<Scalars['Int']>;
  order_in?: Maybe<Array<Scalars['Int']>>;
  order_not_in?: Maybe<Array<Scalars['Int']>>;
  order_lt?: Maybe<Scalars['Int']>;
  order_lte?: Maybe<Scalars['Int']>;
  order_gt?: Maybe<Scalars['Int']>;
  order_gte?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<ClipCarouselModuleClipsScalarWhereInput>>;
  OR?: Maybe<Array<ClipCarouselModuleClipsScalarWhereInput>>;
  NOT?: Maybe<Array<ClipCarouselModuleClipsScalarWhereInput>>;
};

export type ClipCarouselModuleClipsSubscriptionPayload = {
  __typename?: 'ClipCarouselModuleClipsSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<ClipCarouselModuleClips>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<ClipCarouselModuleClipsPreviousValues>;
};

export type ClipCarouselModuleClipsSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<ClipCarouselModuleClipsWhereInput>;
  AND?: Maybe<Array<ClipCarouselModuleClipsSubscriptionWhereInput>>;
  OR?: Maybe<Array<ClipCarouselModuleClipsSubscriptionWhereInput>>;
  NOT?: Maybe<Array<ClipCarouselModuleClipsSubscriptionWhereInput>>;
};

export type ClipCarouselModuleClipsUpdateInput = {
  order?: Maybe<Scalars['Int']>;
  clip?: Maybe<ClipUpdateOneInput>;
  clipCarousel?: Maybe<CarouselModuleUpdateOneWithoutCarouselClipsInput>;
};

export type ClipCarouselModuleClipsUpdateManyDataInput = {
  order?: Maybe<Scalars['Int']>;
};

export type ClipCarouselModuleClipsUpdateManyMutationInput = {
  order?: Maybe<Scalars['Int']>;
};

export type ClipCarouselModuleClipsUpdateManyWithWhereNestedInput = {
  where: ClipCarouselModuleClipsScalarWhereInput;
  data: ClipCarouselModuleClipsUpdateManyDataInput;
};

export type ClipCarouselModuleClipsUpdateManyWithoutClipCarouselInput = {
  create?: Maybe<Array<ClipCarouselModuleClipsCreateWithoutClipCarouselInput>>;
  delete?: Maybe<Array<ClipCarouselModuleClipsWhereUniqueInput>>;
  connect?: Maybe<Array<ClipCarouselModuleClipsWhereUniqueInput>>;
  set?: Maybe<Array<ClipCarouselModuleClipsWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClipCarouselModuleClipsWhereUniqueInput>>;
  update?: Maybe<Array<ClipCarouselModuleClipsUpdateWithWhereUniqueWithoutClipCarouselInput>>;
  upsert?: Maybe<Array<ClipCarouselModuleClipsUpsertWithWhereUniqueWithoutClipCarouselInput>>;
  deleteMany?: Maybe<Array<ClipCarouselModuleClipsScalarWhereInput>>;
  updateMany?: Maybe<Array<ClipCarouselModuleClipsUpdateManyWithWhereNestedInput>>;
};

export type ClipCarouselModuleClipsUpdateWithWhereUniqueWithoutClipCarouselInput = {
  where: ClipCarouselModuleClipsWhereUniqueInput;
  data: ClipCarouselModuleClipsUpdateWithoutClipCarouselDataInput;
};

export type ClipCarouselModuleClipsUpdateWithoutClipCarouselDataInput = {
  order?: Maybe<Scalars['Int']>;
  clip?: Maybe<ClipUpdateOneInput>;
};

export type ClipCarouselModuleClipsUpsertWithWhereUniqueWithoutClipCarouselInput = {
  where: ClipCarouselModuleClipsWhereUniqueInput;
  update: ClipCarouselModuleClipsUpdateWithoutClipCarouselDataInput;
  create: ClipCarouselModuleClipsCreateWithoutClipCarouselInput;
};

export type ClipCarouselModuleClipsWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  order?: Maybe<Scalars['Int']>;
  order_not?: Maybe<Scalars['Int']>;
  order_in?: Maybe<Array<Scalars['Int']>>;
  order_not_in?: Maybe<Array<Scalars['Int']>>;
  order_lt?: Maybe<Scalars['Int']>;
  order_lte?: Maybe<Scalars['Int']>;
  order_gt?: Maybe<Scalars['Int']>;
  order_gte?: Maybe<Scalars['Int']>;
  clip?: Maybe<ClipWhereInput>;
  clipCarousel?: Maybe<CarouselModuleWhereInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<ClipCarouselModuleClipsWhereInput>>;
  OR?: Maybe<Array<ClipCarouselModuleClipsWhereInput>>;
  NOT?: Maybe<Array<ClipCarouselModuleClipsWhereInput>>;
};

export type ClipCarouselModuleClipsWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type ClipCarouselModuleCustomInput = {
  title?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  carouselClips?: Maybe<Array<Maybe<ClipCarouselCustomInput>>>;
};

export type ClipConnection = {
  __typename?: 'ClipConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<ClipEdge>>;
  aggregate: AggregateClip;
};

export type ClipCreateInput = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  clipNumber?: Maybe<Scalars['String']>;
  status?: Maybe<ClipStatus>;
  show?: Maybe<ShowCreateOneWithoutClipsInput>;
  video?: Maybe<VideoCreateOneWithoutClipsInput>;
  captions?: Maybe<CaptionCreateManyInput>;
  videoState?: Maybe<Scalars['String']>;
  muxAssetId?: Maybe<Scalars['String']>;
  uploadId?: Maybe<Scalars['String']>;
  muxPlaybackId?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  playbackPolicy?: Maybe<Scalars['String']>;
  videoURL?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
  clipAccess?: Maybe<ClipCreateclipAccessInput>;
  createdBy?: Maybe<UserCreateOneInput>;
};

export type ClipCreateManyWithoutShowInput = {
  create?: Maybe<Array<ClipCreateWithoutShowInput>>;
  connect?: Maybe<Array<ClipWhereUniqueInput>>;
};

export type ClipCreateManyWithoutVideoInput = {
  create?: Maybe<Array<ClipCreateWithoutVideoInput>>;
  connect?: Maybe<Array<ClipWhereUniqueInput>>;
};

export type ClipCreateOneInput = {
  create?: Maybe<ClipCreateInput>;
  connect?: Maybe<ClipWhereUniqueInput>;
};

export type ClipCreateWithoutShowInput = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  clipNumber?: Maybe<Scalars['String']>;
  status?: Maybe<ClipStatus>;
  video?: Maybe<VideoCreateOneWithoutClipsInput>;
  captions?: Maybe<CaptionCreateManyInput>;
  videoState?: Maybe<Scalars['String']>;
  muxAssetId?: Maybe<Scalars['String']>;
  uploadId?: Maybe<Scalars['String']>;
  muxPlaybackId?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  playbackPolicy?: Maybe<Scalars['String']>;
  videoURL?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
  clipAccess?: Maybe<ClipCreateclipAccessInput>;
  createdBy?: Maybe<UserCreateOneInput>;
};

export type ClipCreateWithoutVideoInput = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  clipNumber?: Maybe<Scalars['String']>;
  status?: Maybe<ClipStatus>;
  show?: Maybe<ShowCreateOneWithoutClipsInput>;
  captions?: Maybe<CaptionCreateManyInput>;
  videoState?: Maybe<Scalars['String']>;
  muxAssetId?: Maybe<Scalars['String']>;
  uploadId?: Maybe<Scalars['String']>;
  muxPlaybackId?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  playbackPolicy?: Maybe<Scalars['String']>;
  videoURL?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
  clipAccess?: Maybe<ClipCreateclipAccessInput>;
  createdBy?: Maybe<UserCreateOneInput>;
};

export type ClipCreateclipAccessInput = {
  set?: Maybe<Array<Scalars['String']>>;
};

export type ClipEdge = {
  __typename?: 'ClipEdge';
  node: Clip;
  cursor: Scalars['String'];
};

export enum ClipOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  ImageAsc = 'image_ASC',
  ImageDesc = 'image_DESC',
  ClipNumberAsc = 'clipNumber_ASC',
  ClipNumberDesc = 'clipNumber_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  VideoStateAsc = 'videoState_ASC',
  VideoStateDesc = 'videoState_DESC',
  MuxAssetIdAsc = 'muxAssetId_ASC',
  MuxAssetIdDesc = 'muxAssetId_DESC',
  UploadIdAsc = 'uploadId_ASC',
  UploadIdDesc = 'uploadId_DESC',
  MuxPlaybackIdAsc = 'muxPlaybackId_ASC',
  MuxPlaybackIdDesc = 'muxPlaybackId_DESC',
  ThumbnailAsc = 'thumbnail_ASC',
  ThumbnailDesc = 'thumbnail_DESC',
  PlaybackPolicyAsc = 'playbackPolicy_ASC',
  PlaybackPolicyDesc = 'playbackPolicy_DESC',
  VideoUrlAsc = 'videoURL_ASC',
  VideoUrlDesc = 'videoURL_DESC',
  DurationAsc = 'duration_ASC',
  DurationDesc = 'duration_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type ClipPreviousValues = {
  __typename?: 'ClipPreviousValues';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  clipNumber?: Maybe<Scalars['String']>;
  status?: Maybe<ClipStatus>;
  videoState?: Maybe<Scalars['String']>;
  muxAssetId?: Maybe<Scalars['String']>;
  uploadId?: Maybe<Scalars['String']>;
  muxPlaybackId?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  playbackPolicy?: Maybe<Scalars['String']>;
  videoURL?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
  clipAccess: Array<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type ClipScalarWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Scalars['String']>>;
  name_not_in?: Maybe<Array<Scalars['String']>>;
  name_lt?: Maybe<Scalars['String']>;
  name_lte?: Maybe<Scalars['String']>;
  name_gt?: Maybe<Scalars['String']>;
  name_gte?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  slug_not?: Maybe<Scalars['String']>;
  slug_in?: Maybe<Array<Scalars['String']>>;
  slug_not_in?: Maybe<Array<Scalars['String']>>;
  slug_lt?: Maybe<Scalars['String']>;
  slug_lte?: Maybe<Scalars['String']>;
  slug_gt?: Maybe<Scalars['String']>;
  slug_gte?: Maybe<Scalars['String']>;
  slug_contains?: Maybe<Scalars['String']>;
  slug_not_contains?: Maybe<Scalars['String']>;
  slug_starts_with?: Maybe<Scalars['String']>;
  slug_not_starts_with?: Maybe<Scalars['String']>;
  slug_ends_with?: Maybe<Scalars['String']>;
  slug_not_ends_with?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  description_not?: Maybe<Scalars['String']>;
  description_in?: Maybe<Array<Scalars['String']>>;
  description_not_in?: Maybe<Array<Scalars['String']>>;
  description_lt?: Maybe<Scalars['String']>;
  description_lte?: Maybe<Scalars['String']>;
  description_gt?: Maybe<Scalars['String']>;
  description_gte?: Maybe<Scalars['String']>;
  description_contains?: Maybe<Scalars['String']>;
  description_not_contains?: Maybe<Scalars['String']>;
  description_starts_with?: Maybe<Scalars['String']>;
  description_not_starts_with?: Maybe<Scalars['String']>;
  description_ends_with?: Maybe<Scalars['String']>;
  description_not_ends_with?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  image_not?: Maybe<Scalars['String']>;
  image_in?: Maybe<Array<Scalars['String']>>;
  image_not_in?: Maybe<Array<Scalars['String']>>;
  image_lt?: Maybe<Scalars['String']>;
  image_lte?: Maybe<Scalars['String']>;
  image_gt?: Maybe<Scalars['String']>;
  image_gte?: Maybe<Scalars['String']>;
  image_contains?: Maybe<Scalars['String']>;
  image_not_contains?: Maybe<Scalars['String']>;
  image_starts_with?: Maybe<Scalars['String']>;
  image_not_starts_with?: Maybe<Scalars['String']>;
  image_ends_with?: Maybe<Scalars['String']>;
  image_not_ends_with?: Maybe<Scalars['String']>;
  clipNumber?: Maybe<Scalars['String']>;
  clipNumber_not?: Maybe<Scalars['String']>;
  clipNumber_in?: Maybe<Array<Scalars['String']>>;
  clipNumber_not_in?: Maybe<Array<Scalars['String']>>;
  clipNumber_lt?: Maybe<Scalars['String']>;
  clipNumber_lte?: Maybe<Scalars['String']>;
  clipNumber_gt?: Maybe<Scalars['String']>;
  clipNumber_gte?: Maybe<Scalars['String']>;
  clipNumber_contains?: Maybe<Scalars['String']>;
  clipNumber_not_contains?: Maybe<Scalars['String']>;
  clipNumber_starts_with?: Maybe<Scalars['String']>;
  clipNumber_not_starts_with?: Maybe<Scalars['String']>;
  clipNumber_ends_with?: Maybe<Scalars['String']>;
  clipNumber_not_ends_with?: Maybe<Scalars['String']>;
  status?: Maybe<ClipStatus>;
  status_not?: Maybe<ClipStatus>;
  status_in?: Maybe<Array<ClipStatus>>;
  status_not_in?: Maybe<Array<ClipStatus>>;
  videoState?: Maybe<Scalars['String']>;
  videoState_not?: Maybe<Scalars['String']>;
  videoState_in?: Maybe<Array<Scalars['String']>>;
  videoState_not_in?: Maybe<Array<Scalars['String']>>;
  videoState_lt?: Maybe<Scalars['String']>;
  videoState_lte?: Maybe<Scalars['String']>;
  videoState_gt?: Maybe<Scalars['String']>;
  videoState_gte?: Maybe<Scalars['String']>;
  videoState_contains?: Maybe<Scalars['String']>;
  videoState_not_contains?: Maybe<Scalars['String']>;
  videoState_starts_with?: Maybe<Scalars['String']>;
  videoState_not_starts_with?: Maybe<Scalars['String']>;
  videoState_ends_with?: Maybe<Scalars['String']>;
  videoState_not_ends_with?: Maybe<Scalars['String']>;
  muxAssetId?: Maybe<Scalars['String']>;
  muxAssetId_not?: Maybe<Scalars['String']>;
  muxAssetId_in?: Maybe<Array<Scalars['String']>>;
  muxAssetId_not_in?: Maybe<Array<Scalars['String']>>;
  muxAssetId_lt?: Maybe<Scalars['String']>;
  muxAssetId_lte?: Maybe<Scalars['String']>;
  muxAssetId_gt?: Maybe<Scalars['String']>;
  muxAssetId_gte?: Maybe<Scalars['String']>;
  muxAssetId_contains?: Maybe<Scalars['String']>;
  muxAssetId_not_contains?: Maybe<Scalars['String']>;
  muxAssetId_starts_with?: Maybe<Scalars['String']>;
  muxAssetId_not_starts_with?: Maybe<Scalars['String']>;
  muxAssetId_ends_with?: Maybe<Scalars['String']>;
  muxAssetId_not_ends_with?: Maybe<Scalars['String']>;
  uploadId?: Maybe<Scalars['String']>;
  uploadId_not?: Maybe<Scalars['String']>;
  uploadId_in?: Maybe<Array<Scalars['String']>>;
  uploadId_not_in?: Maybe<Array<Scalars['String']>>;
  uploadId_lt?: Maybe<Scalars['String']>;
  uploadId_lte?: Maybe<Scalars['String']>;
  uploadId_gt?: Maybe<Scalars['String']>;
  uploadId_gte?: Maybe<Scalars['String']>;
  uploadId_contains?: Maybe<Scalars['String']>;
  uploadId_not_contains?: Maybe<Scalars['String']>;
  uploadId_starts_with?: Maybe<Scalars['String']>;
  uploadId_not_starts_with?: Maybe<Scalars['String']>;
  uploadId_ends_with?: Maybe<Scalars['String']>;
  uploadId_not_ends_with?: Maybe<Scalars['String']>;
  muxPlaybackId?: Maybe<Scalars['String']>;
  muxPlaybackId_not?: Maybe<Scalars['String']>;
  muxPlaybackId_in?: Maybe<Array<Scalars['String']>>;
  muxPlaybackId_not_in?: Maybe<Array<Scalars['String']>>;
  muxPlaybackId_lt?: Maybe<Scalars['String']>;
  muxPlaybackId_lte?: Maybe<Scalars['String']>;
  muxPlaybackId_gt?: Maybe<Scalars['String']>;
  muxPlaybackId_gte?: Maybe<Scalars['String']>;
  muxPlaybackId_contains?: Maybe<Scalars['String']>;
  muxPlaybackId_not_contains?: Maybe<Scalars['String']>;
  muxPlaybackId_starts_with?: Maybe<Scalars['String']>;
  muxPlaybackId_not_starts_with?: Maybe<Scalars['String']>;
  muxPlaybackId_ends_with?: Maybe<Scalars['String']>;
  muxPlaybackId_not_ends_with?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  thumbnail_not?: Maybe<Scalars['String']>;
  thumbnail_in?: Maybe<Array<Scalars['String']>>;
  thumbnail_not_in?: Maybe<Array<Scalars['String']>>;
  thumbnail_lt?: Maybe<Scalars['String']>;
  thumbnail_lte?: Maybe<Scalars['String']>;
  thumbnail_gt?: Maybe<Scalars['String']>;
  thumbnail_gte?: Maybe<Scalars['String']>;
  thumbnail_contains?: Maybe<Scalars['String']>;
  thumbnail_not_contains?: Maybe<Scalars['String']>;
  thumbnail_starts_with?: Maybe<Scalars['String']>;
  thumbnail_not_starts_with?: Maybe<Scalars['String']>;
  thumbnail_ends_with?: Maybe<Scalars['String']>;
  thumbnail_not_ends_with?: Maybe<Scalars['String']>;
  playbackPolicy?: Maybe<Scalars['String']>;
  playbackPolicy_not?: Maybe<Scalars['String']>;
  playbackPolicy_in?: Maybe<Array<Scalars['String']>>;
  playbackPolicy_not_in?: Maybe<Array<Scalars['String']>>;
  playbackPolicy_lt?: Maybe<Scalars['String']>;
  playbackPolicy_lte?: Maybe<Scalars['String']>;
  playbackPolicy_gt?: Maybe<Scalars['String']>;
  playbackPolicy_gte?: Maybe<Scalars['String']>;
  playbackPolicy_contains?: Maybe<Scalars['String']>;
  playbackPolicy_not_contains?: Maybe<Scalars['String']>;
  playbackPolicy_starts_with?: Maybe<Scalars['String']>;
  playbackPolicy_not_starts_with?: Maybe<Scalars['String']>;
  playbackPolicy_ends_with?: Maybe<Scalars['String']>;
  playbackPolicy_not_ends_with?: Maybe<Scalars['String']>;
  videoURL?: Maybe<Scalars['String']>;
  videoURL_not?: Maybe<Scalars['String']>;
  videoURL_in?: Maybe<Array<Scalars['String']>>;
  videoURL_not_in?: Maybe<Array<Scalars['String']>>;
  videoURL_lt?: Maybe<Scalars['String']>;
  videoURL_lte?: Maybe<Scalars['String']>;
  videoURL_gt?: Maybe<Scalars['String']>;
  videoURL_gte?: Maybe<Scalars['String']>;
  videoURL_contains?: Maybe<Scalars['String']>;
  videoURL_not_contains?: Maybe<Scalars['String']>;
  videoURL_starts_with?: Maybe<Scalars['String']>;
  videoURL_not_starts_with?: Maybe<Scalars['String']>;
  videoURL_ends_with?: Maybe<Scalars['String']>;
  videoURL_not_ends_with?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
  duration_not?: Maybe<Scalars['Float']>;
  duration_in?: Maybe<Array<Scalars['Float']>>;
  duration_not_in?: Maybe<Array<Scalars['Float']>>;
  duration_lt?: Maybe<Scalars['Float']>;
  duration_lte?: Maybe<Scalars['Float']>;
  duration_gt?: Maybe<Scalars['Float']>;
  duration_gte?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<ClipScalarWhereInput>>;
  OR?: Maybe<Array<ClipScalarWhereInput>>;
  NOT?: Maybe<Array<ClipScalarWhereInput>>;
};

export type ClipSignedUrlDataInput = {
  clipId: Scalars['String'];
  fileName: Scalars['String'];
  contentType: Scalars['String'];
};

export enum ClipStatus {
  Published = 'PUBLISHED',
  Unpublished = 'UNPUBLISHED'
}

export type ClipSubscriptionPayload = {
  __typename?: 'ClipSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<Clip>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<ClipPreviousValues>;
};

export type ClipSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<ClipWhereInput>;
  AND?: Maybe<Array<ClipSubscriptionWhereInput>>;
  OR?: Maybe<Array<ClipSubscriptionWhereInput>>;
  NOT?: Maybe<Array<ClipSubscriptionWhereInput>>;
};

export type ClipUpdateDataInput = {
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  clipNumber?: Maybe<Scalars['String']>;
  status?: Maybe<ClipStatus>;
  show?: Maybe<ShowUpdateOneWithoutClipsInput>;
  video?: Maybe<VideoUpdateOneWithoutClipsInput>;
  captions?: Maybe<CaptionUpdateManyInput>;
  videoState?: Maybe<Scalars['String']>;
  muxAssetId?: Maybe<Scalars['String']>;
  uploadId?: Maybe<Scalars['String']>;
  muxPlaybackId?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  playbackPolicy?: Maybe<Scalars['String']>;
  videoURL?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
  clipAccess?: Maybe<ClipUpdateclipAccessInput>;
  createdBy?: Maybe<UserUpdateOneInput>;
};

export type ClipUpdateInput = {
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  clipNumber?: Maybe<Scalars['String']>;
  status?: Maybe<ClipStatus>;
  show?: Maybe<ShowUpdateOneWithoutClipsInput>;
  video?: Maybe<VideoUpdateOneWithoutClipsInput>;
  captions?: Maybe<CaptionUpdateManyInput>;
  videoState?: Maybe<Scalars['String']>;
  muxAssetId?: Maybe<Scalars['String']>;
  uploadId?: Maybe<Scalars['String']>;
  muxPlaybackId?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  playbackPolicy?: Maybe<Scalars['String']>;
  videoURL?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
  clipAccess?: Maybe<ClipUpdateclipAccessInput>;
  createdBy?: Maybe<UserUpdateOneInput>;
};

export type ClipUpdateManyDataInput = {
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  clipNumber?: Maybe<Scalars['String']>;
  status?: Maybe<ClipStatus>;
  videoState?: Maybe<Scalars['String']>;
  muxAssetId?: Maybe<Scalars['String']>;
  uploadId?: Maybe<Scalars['String']>;
  muxPlaybackId?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  playbackPolicy?: Maybe<Scalars['String']>;
  videoURL?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
  clipAccess?: Maybe<ClipUpdateclipAccessInput>;
};

export type ClipUpdateManyMutationInput = {
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  clipNumber?: Maybe<Scalars['String']>;
  status?: Maybe<ClipStatus>;
  videoState?: Maybe<Scalars['String']>;
  muxAssetId?: Maybe<Scalars['String']>;
  uploadId?: Maybe<Scalars['String']>;
  muxPlaybackId?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  playbackPolicy?: Maybe<Scalars['String']>;
  videoURL?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
  clipAccess?: Maybe<ClipUpdateclipAccessInput>;
};

export type ClipUpdateManyWithWhereNestedInput = {
  where: ClipScalarWhereInput;
  data: ClipUpdateManyDataInput;
};

export type ClipUpdateManyWithoutShowInput = {
  create?: Maybe<Array<ClipCreateWithoutShowInput>>;
  delete?: Maybe<Array<ClipWhereUniqueInput>>;
  connect?: Maybe<Array<ClipWhereUniqueInput>>;
  set?: Maybe<Array<ClipWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClipWhereUniqueInput>>;
  update?: Maybe<Array<ClipUpdateWithWhereUniqueWithoutShowInput>>;
  upsert?: Maybe<Array<ClipUpsertWithWhereUniqueWithoutShowInput>>;
  deleteMany?: Maybe<Array<ClipScalarWhereInput>>;
  updateMany?: Maybe<Array<ClipUpdateManyWithWhereNestedInput>>;
};

export type ClipUpdateManyWithoutVideoInput = {
  create?: Maybe<Array<ClipCreateWithoutVideoInput>>;
  delete?: Maybe<Array<ClipWhereUniqueInput>>;
  connect?: Maybe<Array<ClipWhereUniqueInput>>;
  set?: Maybe<Array<ClipWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClipWhereUniqueInput>>;
  update?: Maybe<Array<ClipUpdateWithWhereUniqueWithoutVideoInput>>;
  upsert?: Maybe<Array<ClipUpsertWithWhereUniqueWithoutVideoInput>>;
  deleteMany?: Maybe<Array<ClipScalarWhereInput>>;
  updateMany?: Maybe<Array<ClipUpdateManyWithWhereNestedInput>>;
};

export type ClipUpdateOneInput = {
  create?: Maybe<ClipCreateInput>;
  update?: Maybe<ClipUpdateDataInput>;
  upsert?: Maybe<ClipUpsertNestedInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClipWhereUniqueInput>;
};

export type ClipUpdateWithWhereUniqueWithoutShowInput = {
  where: ClipWhereUniqueInput;
  data: ClipUpdateWithoutShowDataInput;
};

export type ClipUpdateWithWhereUniqueWithoutVideoInput = {
  where: ClipWhereUniqueInput;
  data: ClipUpdateWithoutVideoDataInput;
};

export type ClipUpdateWithoutShowDataInput = {
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  clipNumber?: Maybe<Scalars['String']>;
  status?: Maybe<ClipStatus>;
  video?: Maybe<VideoUpdateOneWithoutClipsInput>;
  captions?: Maybe<CaptionUpdateManyInput>;
  videoState?: Maybe<Scalars['String']>;
  muxAssetId?: Maybe<Scalars['String']>;
  uploadId?: Maybe<Scalars['String']>;
  muxPlaybackId?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  playbackPolicy?: Maybe<Scalars['String']>;
  videoURL?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
  clipAccess?: Maybe<ClipUpdateclipAccessInput>;
  createdBy?: Maybe<UserUpdateOneInput>;
};

export type ClipUpdateWithoutVideoDataInput = {
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  clipNumber?: Maybe<Scalars['String']>;
  status?: Maybe<ClipStatus>;
  show?: Maybe<ShowUpdateOneWithoutClipsInput>;
  captions?: Maybe<CaptionUpdateManyInput>;
  videoState?: Maybe<Scalars['String']>;
  muxAssetId?: Maybe<Scalars['String']>;
  uploadId?: Maybe<Scalars['String']>;
  muxPlaybackId?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  playbackPolicy?: Maybe<Scalars['String']>;
  videoURL?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
  clipAccess?: Maybe<ClipUpdateclipAccessInput>;
  createdBy?: Maybe<UserUpdateOneInput>;
};

export type ClipUpdateclipAccessInput = {
  set?: Maybe<Array<Scalars['String']>>;
};

export type ClipUpsertNestedInput = {
  update: ClipUpdateDataInput;
  create: ClipCreateInput;
};

export type ClipUpsertWithWhereUniqueWithoutShowInput = {
  where: ClipWhereUniqueInput;
  update: ClipUpdateWithoutShowDataInput;
  create: ClipCreateWithoutShowInput;
};

export type ClipUpsertWithWhereUniqueWithoutVideoInput = {
  where: ClipWhereUniqueInput;
  update: ClipUpdateWithoutVideoDataInput;
  create: ClipCreateWithoutVideoInput;
};

export type ClipWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Scalars['String']>>;
  name_not_in?: Maybe<Array<Scalars['String']>>;
  name_lt?: Maybe<Scalars['String']>;
  name_lte?: Maybe<Scalars['String']>;
  name_gt?: Maybe<Scalars['String']>;
  name_gte?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  slug_not?: Maybe<Scalars['String']>;
  slug_in?: Maybe<Array<Scalars['String']>>;
  slug_not_in?: Maybe<Array<Scalars['String']>>;
  slug_lt?: Maybe<Scalars['String']>;
  slug_lte?: Maybe<Scalars['String']>;
  slug_gt?: Maybe<Scalars['String']>;
  slug_gte?: Maybe<Scalars['String']>;
  slug_contains?: Maybe<Scalars['String']>;
  slug_not_contains?: Maybe<Scalars['String']>;
  slug_starts_with?: Maybe<Scalars['String']>;
  slug_not_starts_with?: Maybe<Scalars['String']>;
  slug_ends_with?: Maybe<Scalars['String']>;
  slug_not_ends_with?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  description_not?: Maybe<Scalars['String']>;
  description_in?: Maybe<Array<Scalars['String']>>;
  description_not_in?: Maybe<Array<Scalars['String']>>;
  description_lt?: Maybe<Scalars['String']>;
  description_lte?: Maybe<Scalars['String']>;
  description_gt?: Maybe<Scalars['String']>;
  description_gte?: Maybe<Scalars['String']>;
  description_contains?: Maybe<Scalars['String']>;
  description_not_contains?: Maybe<Scalars['String']>;
  description_starts_with?: Maybe<Scalars['String']>;
  description_not_starts_with?: Maybe<Scalars['String']>;
  description_ends_with?: Maybe<Scalars['String']>;
  description_not_ends_with?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  image_not?: Maybe<Scalars['String']>;
  image_in?: Maybe<Array<Scalars['String']>>;
  image_not_in?: Maybe<Array<Scalars['String']>>;
  image_lt?: Maybe<Scalars['String']>;
  image_lte?: Maybe<Scalars['String']>;
  image_gt?: Maybe<Scalars['String']>;
  image_gte?: Maybe<Scalars['String']>;
  image_contains?: Maybe<Scalars['String']>;
  image_not_contains?: Maybe<Scalars['String']>;
  image_starts_with?: Maybe<Scalars['String']>;
  image_not_starts_with?: Maybe<Scalars['String']>;
  image_ends_with?: Maybe<Scalars['String']>;
  image_not_ends_with?: Maybe<Scalars['String']>;
  clipNumber?: Maybe<Scalars['String']>;
  clipNumber_not?: Maybe<Scalars['String']>;
  clipNumber_in?: Maybe<Array<Scalars['String']>>;
  clipNumber_not_in?: Maybe<Array<Scalars['String']>>;
  clipNumber_lt?: Maybe<Scalars['String']>;
  clipNumber_lte?: Maybe<Scalars['String']>;
  clipNumber_gt?: Maybe<Scalars['String']>;
  clipNumber_gte?: Maybe<Scalars['String']>;
  clipNumber_contains?: Maybe<Scalars['String']>;
  clipNumber_not_contains?: Maybe<Scalars['String']>;
  clipNumber_starts_with?: Maybe<Scalars['String']>;
  clipNumber_not_starts_with?: Maybe<Scalars['String']>;
  clipNumber_ends_with?: Maybe<Scalars['String']>;
  clipNumber_not_ends_with?: Maybe<Scalars['String']>;
  status?: Maybe<ClipStatus>;
  status_not?: Maybe<ClipStatus>;
  status_in?: Maybe<Array<ClipStatus>>;
  status_not_in?: Maybe<Array<ClipStatus>>;
  show?: Maybe<ShowWhereInput>;
  video?: Maybe<VideoWhereInput>;
  captions_every?: Maybe<CaptionWhereInput>;
  captions_some?: Maybe<CaptionWhereInput>;
  captions_none?: Maybe<CaptionWhereInput>;
  videoState?: Maybe<Scalars['String']>;
  videoState_not?: Maybe<Scalars['String']>;
  videoState_in?: Maybe<Array<Scalars['String']>>;
  videoState_not_in?: Maybe<Array<Scalars['String']>>;
  videoState_lt?: Maybe<Scalars['String']>;
  videoState_lte?: Maybe<Scalars['String']>;
  videoState_gt?: Maybe<Scalars['String']>;
  videoState_gte?: Maybe<Scalars['String']>;
  videoState_contains?: Maybe<Scalars['String']>;
  videoState_not_contains?: Maybe<Scalars['String']>;
  videoState_starts_with?: Maybe<Scalars['String']>;
  videoState_not_starts_with?: Maybe<Scalars['String']>;
  videoState_ends_with?: Maybe<Scalars['String']>;
  videoState_not_ends_with?: Maybe<Scalars['String']>;
  muxAssetId?: Maybe<Scalars['String']>;
  muxAssetId_not?: Maybe<Scalars['String']>;
  muxAssetId_in?: Maybe<Array<Scalars['String']>>;
  muxAssetId_not_in?: Maybe<Array<Scalars['String']>>;
  muxAssetId_lt?: Maybe<Scalars['String']>;
  muxAssetId_lte?: Maybe<Scalars['String']>;
  muxAssetId_gt?: Maybe<Scalars['String']>;
  muxAssetId_gte?: Maybe<Scalars['String']>;
  muxAssetId_contains?: Maybe<Scalars['String']>;
  muxAssetId_not_contains?: Maybe<Scalars['String']>;
  muxAssetId_starts_with?: Maybe<Scalars['String']>;
  muxAssetId_not_starts_with?: Maybe<Scalars['String']>;
  muxAssetId_ends_with?: Maybe<Scalars['String']>;
  muxAssetId_not_ends_with?: Maybe<Scalars['String']>;
  uploadId?: Maybe<Scalars['String']>;
  uploadId_not?: Maybe<Scalars['String']>;
  uploadId_in?: Maybe<Array<Scalars['String']>>;
  uploadId_not_in?: Maybe<Array<Scalars['String']>>;
  uploadId_lt?: Maybe<Scalars['String']>;
  uploadId_lte?: Maybe<Scalars['String']>;
  uploadId_gt?: Maybe<Scalars['String']>;
  uploadId_gte?: Maybe<Scalars['String']>;
  uploadId_contains?: Maybe<Scalars['String']>;
  uploadId_not_contains?: Maybe<Scalars['String']>;
  uploadId_starts_with?: Maybe<Scalars['String']>;
  uploadId_not_starts_with?: Maybe<Scalars['String']>;
  uploadId_ends_with?: Maybe<Scalars['String']>;
  uploadId_not_ends_with?: Maybe<Scalars['String']>;
  muxPlaybackId?: Maybe<Scalars['String']>;
  muxPlaybackId_not?: Maybe<Scalars['String']>;
  muxPlaybackId_in?: Maybe<Array<Scalars['String']>>;
  muxPlaybackId_not_in?: Maybe<Array<Scalars['String']>>;
  muxPlaybackId_lt?: Maybe<Scalars['String']>;
  muxPlaybackId_lte?: Maybe<Scalars['String']>;
  muxPlaybackId_gt?: Maybe<Scalars['String']>;
  muxPlaybackId_gte?: Maybe<Scalars['String']>;
  muxPlaybackId_contains?: Maybe<Scalars['String']>;
  muxPlaybackId_not_contains?: Maybe<Scalars['String']>;
  muxPlaybackId_starts_with?: Maybe<Scalars['String']>;
  muxPlaybackId_not_starts_with?: Maybe<Scalars['String']>;
  muxPlaybackId_ends_with?: Maybe<Scalars['String']>;
  muxPlaybackId_not_ends_with?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  thumbnail_not?: Maybe<Scalars['String']>;
  thumbnail_in?: Maybe<Array<Scalars['String']>>;
  thumbnail_not_in?: Maybe<Array<Scalars['String']>>;
  thumbnail_lt?: Maybe<Scalars['String']>;
  thumbnail_lte?: Maybe<Scalars['String']>;
  thumbnail_gt?: Maybe<Scalars['String']>;
  thumbnail_gte?: Maybe<Scalars['String']>;
  thumbnail_contains?: Maybe<Scalars['String']>;
  thumbnail_not_contains?: Maybe<Scalars['String']>;
  thumbnail_starts_with?: Maybe<Scalars['String']>;
  thumbnail_not_starts_with?: Maybe<Scalars['String']>;
  thumbnail_ends_with?: Maybe<Scalars['String']>;
  thumbnail_not_ends_with?: Maybe<Scalars['String']>;
  playbackPolicy?: Maybe<Scalars['String']>;
  playbackPolicy_not?: Maybe<Scalars['String']>;
  playbackPolicy_in?: Maybe<Array<Scalars['String']>>;
  playbackPolicy_not_in?: Maybe<Array<Scalars['String']>>;
  playbackPolicy_lt?: Maybe<Scalars['String']>;
  playbackPolicy_lte?: Maybe<Scalars['String']>;
  playbackPolicy_gt?: Maybe<Scalars['String']>;
  playbackPolicy_gte?: Maybe<Scalars['String']>;
  playbackPolicy_contains?: Maybe<Scalars['String']>;
  playbackPolicy_not_contains?: Maybe<Scalars['String']>;
  playbackPolicy_starts_with?: Maybe<Scalars['String']>;
  playbackPolicy_not_starts_with?: Maybe<Scalars['String']>;
  playbackPolicy_ends_with?: Maybe<Scalars['String']>;
  playbackPolicy_not_ends_with?: Maybe<Scalars['String']>;
  videoURL?: Maybe<Scalars['String']>;
  videoURL_not?: Maybe<Scalars['String']>;
  videoURL_in?: Maybe<Array<Scalars['String']>>;
  videoURL_not_in?: Maybe<Array<Scalars['String']>>;
  videoURL_lt?: Maybe<Scalars['String']>;
  videoURL_lte?: Maybe<Scalars['String']>;
  videoURL_gt?: Maybe<Scalars['String']>;
  videoURL_gte?: Maybe<Scalars['String']>;
  videoURL_contains?: Maybe<Scalars['String']>;
  videoURL_not_contains?: Maybe<Scalars['String']>;
  videoURL_starts_with?: Maybe<Scalars['String']>;
  videoURL_not_starts_with?: Maybe<Scalars['String']>;
  videoURL_ends_with?: Maybe<Scalars['String']>;
  videoURL_not_ends_with?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
  duration_not?: Maybe<Scalars['Float']>;
  duration_in?: Maybe<Array<Scalars['Float']>>;
  duration_not_in?: Maybe<Array<Scalars['Float']>>;
  duration_lt?: Maybe<Scalars['Float']>;
  duration_lte?: Maybe<Scalars['Float']>;
  duration_gt?: Maybe<Scalars['Float']>;
  duration_gte?: Maybe<Scalars['Float']>;
  createdBy?: Maybe<UserWhereInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<ClipWhereInput>>;
  OR?: Maybe<Array<ClipWhereInput>>;
  NOT?: Maybe<Array<ClipWhereInput>>;
};

export type ClipWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
  slug?: Maybe<Scalars['String']>;
};

export type ContentfulWebhook = {
  __typename?: 'ContentfulWebhook';
  id: Scalars['ID'];
  topic?: Maybe<Scalars['String']>;
  payload?: Maybe<Scalars['Json']>;
  error?: Maybe<Scalars['Json']>;
  entityId?: Maybe<Scalars['String']>;
  showId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type ContentfulWebhookConnection = {
  __typename?: 'ContentfulWebhookConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<ContentfulWebhookEdge>>;
  aggregate: AggregateContentfulWebhook;
};

export type ContentfulWebhookCreateInput = {
  id?: Maybe<Scalars['ID']>;
  topic?: Maybe<Scalars['String']>;
  payload?: Maybe<Scalars['Json']>;
  error?: Maybe<Scalars['Json']>;
  entityId?: Maybe<Scalars['String']>;
  showId?: Maybe<Scalars['String']>;
};

export type ContentfulWebhookEdge = {
  __typename?: 'ContentfulWebhookEdge';
  node: ContentfulWebhook;
  cursor: Scalars['String'];
};

export enum ContentfulWebhookOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TopicAsc = 'topic_ASC',
  TopicDesc = 'topic_DESC',
  PayloadAsc = 'payload_ASC',
  PayloadDesc = 'payload_DESC',
  ErrorAsc = 'error_ASC',
  ErrorDesc = 'error_DESC',
  EntityIdAsc = 'entityId_ASC',
  EntityIdDesc = 'entityId_DESC',
  ShowIdAsc = 'showId_ASC',
  ShowIdDesc = 'showId_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type ContentfulWebhookPreviousValues = {
  __typename?: 'ContentfulWebhookPreviousValues';
  id: Scalars['ID'];
  topic?: Maybe<Scalars['String']>;
  payload?: Maybe<Scalars['Json']>;
  error?: Maybe<Scalars['Json']>;
  entityId?: Maybe<Scalars['String']>;
  showId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type ContentfulWebhookSubscriptionPayload = {
  __typename?: 'ContentfulWebhookSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<ContentfulWebhook>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<ContentfulWebhookPreviousValues>;
};

export type ContentfulWebhookSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<ContentfulWebhookWhereInput>;
  AND?: Maybe<Array<ContentfulWebhookSubscriptionWhereInput>>;
  OR?: Maybe<Array<ContentfulWebhookSubscriptionWhereInput>>;
  NOT?: Maybe<Array<ContentfulWebhookSubscriptionWhereInput>>;
};

export type ContentfulWebhookUpdateInput = {
  topic?: Maybe<Scalars['String']>;
  payload?: Maybe<Scalars['Json']>;
  error?: Maybe<Scalars['Json']>;
  entityId?: Maybe<Scalars['String']>;
  showId?: Maybe<Scalars['String']>;
};

export type ContentfulWebhookUpdateManyMutationInput = {
  topic?: Maybe<Scalars['String']>;
  payload?: Maybe<Scalars['Json']>;
  error?: Maybe<Scalars['Json']>;
  entityId?: Maybe<Scalars['String']>;
  showId?: Maybe<Scalars['String']>;
};

export type ContentfulWebhookWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  topic?: Maybe<Scalars['String']>;
  topic_not?: Maybe<Scalars['String']>;
  topic_in?: Maybe<Array<Scalars['String']>>;
  topic_not_in?: Maybe<Array<Scalars['String']>>;
  topic_lt?: Maybe<Scalars['String']>;
  topic_lte?: Maybe<Scalars['String']>;
  topic_gt?: Maybe<Scalars['String']>;
  topic_gte?: Maybe<Scalars['String']>;
  topic_contains?: Maybe<Scalars['String']>;
  topic_not_contains?: Maybe<Scalars['String']>;
  topic_starts_with?: Maybe<Scalars['String']>;
  topic_not_starts_with?: Maybe<Scalars['String']>;
  topic_ends_with?: Maybe<Scalars['String']>;
  topic_not_ends_with?: Maybe<Scalars['String']>;
  entityId?: Maybe<Scalars['String']>;
  entityId_not?: Maybe<Scalars['String']>;
  entityId_in?: Maybe<Array<Scalars['String']>>;
  entityId_not_in?: Maybe<Array<Scalars['String']>>;
  entityId_lt?: Maybe<Scalars['String']>;
  entityId_lte?: Maybe<Scalars['String']>;
  entityId_gt?: Maybe<Scalars['String']>;
  entityId_gte?: Maybe<Scalars['String']>;
  entityId_contains?: Maybe<Scalars['String']>;
  entityId_not_contains?: Maybe<Scalars['String']>;
  entityId_starts_with?: Maybe<Scalars['String']>;
  entityId_not_starts_with?: Maybe<Scalars['String']>;
  entityId_ends_with?: Maybe<Scalars['String']>;
  entityId_not_ends_with?: Maybe<Scalars['String']>;
  showId?: Maybe<Scalars['String']>;
  showId_not?: Maybe<Scalars['String']>;
  showId_in?: Maybe<Array<Scalars['String']>>;
  showId_not_in?: Maybe<Array<Scalars['String']>>;
  showId_lt?: Maybe<Scalars['String']>;
  showId_lte?: Maybe<Scalars['String']>;
  showId_gt?: Maybe<Scalars['String']>;
  showId_gte?: Maybe<Scalars['String']>;
  showId_contains?: Maybe<Scalars['String']>;
  showId_not_contains?: Maybe<Scalars['String']>;
  showId_starts_with?: Maybe<Scalars['String']>;
  showId_not_starts_with?: Maybe<Scalars['String']>;
  showId_ends_with?: Maybe<Scalars['String']>;
  showId_not_ends_with?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<ContentfulWebhookWhereInput>>;
  OR?: Maybe<Array<ContentfulWebhookWhereInput>>;
  NOT?: Maybe<Array<ContentfulWebhookWhereInput>>;
};

export type ContentfulWebhookWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type ContinentForSelection = {
  __typename?: 'ContinentForSelection';
  continentCode?: Maybe<Scalars['String']>;
  continentName?: Maybe<Scalars['String']>;
  countries?: Maybe<Array<Maybe<CountryForSelection>>>;
};

export type ContinueListening = {
  __typename?: 'ContinueListening';
  id?: Maybe<Scalars['ID']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  items?: Maybe<Array<Maybe<ContinueListeningItem>>>;
};

export type ContinueListeningItem = ContinueListeningPodcastEpisode;

export type ContinueListeningModule = {
  __typename?: 'ContinueListeningModule';
  id: Scalars['ID'];
  title: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type ContinueListeningModuleConnection = {
  __typename?: 'ContinueListeningModuleConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<ContinueListeningModuleEdge>>;
  aggregate: AggregateContinueListeningModule;
};

export type ContinueListeningModuleCreateInput = {
  id?: Maybe<Scalars['ID']>;
  title: Scalars['String'];
};

export type ContinueListeningModuleCustomInput = {
  title: Scalars['String'];
  order: Scalars['Int'];
  isPublished?: Maybe<Scalars['Boolean']>;
};

export type ContinueListeningModuleEdge = {
  __typename?: 'ContinueListeningModuleEdge';
  node: ContinueListeningModule;
  cursor: Scalars['String'];
};

export enum ContinueListeningModuleOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type ContinueListeningModulePreviousValues = {
  __typename?: 'ContinueListeningModulePreviousValues';
  id: Scalars['ID'];
  title: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type ContinueListeningModuleSubscriptionPayload = {
  __typename?: 'ContinueListeningModuleSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<ContinueListeningModule>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<ContinueListeningModulePreviousValues>;
};

export type ContinueListeningModuleSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<ContinueListeningModuleWhereInput>;
  AND?: Maybe<Array<ContinueListeningModuleSubscriptionWhereInput>>;
  OR?: Maybe<Array<ContinueListeningModuleSubscriptionWhereInput>>;
  NOT?: Maybe<Array<ContinueListeningModuleSubscriptionWhereInput>>;
};

export type ContinueListeningModuleUpdateInput = {
  title?: Maybe<Scalars['String']>;
};

export type ContinueListeningModuleUpdateManyMutationInput = {
  title?: Maybe<Scalars['String']>;
};

export type ContinueListeningModuleWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Scalars['String']>>;
  title_not_in?: Maybe<Array<Scalars['String']>>;
  title_lt?: Maybe<Scalars['String']>;
  title_lte?: Maybe<Scalars['String']>;
  title_gt?: Maybe<Scalars['String']>;
  title_gte?: Maybe<Scalars['String']>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  title_starts_with?: Maybe<Scalars['String']>;
  title_not_starts_with?: Maybe<Scalars['String']>;
  title_ends_with?: Maybe<Scalars['String']>;
  title_not_ends_with?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<ContinueListeningModuleWhereInput>>;
  OR?: Maybe<Array<ContinueListeningModuleWhereInput>>;
  NOT?: Maybe<Array<ContinueListeningModuleWhereInput>>;
};

export type ContinueListeningModuleWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type ContinueListeningPodcastEpisode = {
  __typename?: 'ContinueListeningPodcastEpisode';
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  episodeNumber?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  podcast?: Maybe<Podcast>;
  publishDate?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  listenTime?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  audio?: Maybe<Scalars['String']>;
  audioAccess?: Maybe<Array<Maybe<Scalars['String']>>>;
  allowedContinents?: Maybe<Array<Maybe<Scalars['String']>>>;
  allowedCountries?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Scalars['String']>;
};

export type ContinueWatching = {
  __typename?: 'ContinueWatching';
  id?: Maybe<Scalars['ID']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  items?: Maybe<Array<Maybe<ContinueWatchingItem>>>;
};

export type ContinueWatchingEpisode = {
  __typename?: 'ContinueWatchingEpisode';
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  episodeNumber?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  show?: Maybe<Show>;
  createdAt?: Maybe<Scalars['DateTime']>;
  watchTime?: Maybe<Scalars['Float']>;
  segment?: Maybe<SegmentUnique>;
  duration?: Maybe<Scalars['Float']>;
};

export type ContinueWatchingItem = ContinueWatchingEpisode | Video | Clip;

export type ContinueWatchingModule = {
  __typename?: 'ContinueWatchingModule';
  id: Scalars['ID'];
  title: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type ContinueWatchingModuleConnection = {
  __typename?: 'ContinueWatchingModuleConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<ContinueWatchingModuleEdge>>;
  aggregate: AggregateContinueWatchingModule;
};

export type ContinueWatchingModuleCreateInput = {
  id?: Maybe<Scalars['ID']>;
  title: Scalars['String'];
};

export type ContinueWatchingModuleCustomInput = {
  title: Scalars['String'];
  order: Scalars['Int'];
  isPublished?: Maybe<Scalars['Boolean']>;
};

export type ContinueWatchingModuleEdge = {
  __typename?: 'ContinueWatchingModuleEdge';
  node: ContinueWatchingModule;
  cursor: Scalars['String'];
};

export enum ContinueWatchingModuleOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type ContinueWatchingModulePreviousValues = {
  __typename?: 'ContinueWatchingModulePreviousValues';
  id: Scalars['ID'];
  title: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type ContinueWatchingModuleSubscriptionPayload = {
  __typename?: 'ContinueWatchingModuleSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<ContinueWatchingModule>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<ContinueWatchingModulePreviousValues>;
};

export type ContinueWatchingModuleSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<ContinueWatchingModuleWhereInput>;
  AND?: Maybe<Array<ContinueWatchingModuleSubscriptionWhereInput>>;
  OR?: Maybe<Array<ContinueWatchingModuleSubscriptionWhereInput>>;
  NOT?: Maybe<Array<ContinueWatchingModuleSubscriptionWhereInput>>;
};

export type ContinueWatchingModuleUpdateInput = {
  title?: Maybe<Scalars['String']>;
};

export type ContinueWatchingModuleUpdateManyMutationInput = {
  title?: Maybe<Scalars['String']>;
};

export type ContinueWatchingModuleWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Scalars['String']>>;
  title_not_in?: Maybe<Array<Scalars['String']>>;
  title_lt?: Maybe<Scalars['String']>;
  title_lte?: Maybe<Scalars['String']>;
  title_gt?: Maybe<Scalars['String']>;
  title_gte?: Maybe<Scalars['String']>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  title_starts_with?: Maybe<Scalars['String']>;
  title_not_starts_with?: Maybe<Scalars['String']>;
  title_ends_with?: Maybe<Scalars['String']>;
  title_not_ends_with?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<ContinueWatchingModuleWhereInput>>;
  OR?: Maybe<Array<ContinueWatchingModuleWhereInput>>;
  NOT?: Maybe<Array<ContinueWatchingModuleWhereInput>>;
};

export type ContinueWatchingModuleWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type CountryForSelection = {
  __typename?: 'CountryForSelection';
  countryName?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
};

export type CreateCaptionInput = {
  fileName: Scalars['String'];
  muxAssetId: Scalars['ID'];
  language: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  entityId: Scalars['ID'];
  entityType: Caption_Types;
};

export type CustomModule = EpisodeCarousel | PodcastEpisodeCarousel | ShowCarousel | PodcastCarousel | VideoCarousel | ClipCarousel | FreeformCarousel | EpisodePremiere | PodcastEpisodePremiere | ShowPremiere | PodcastPremiere | VideoPremiere | Headliner | Highlight | ContinueWatching | ContinueListening;


export type DeleteCaptionCustomInput = {
  id: Scalars['ID'];
  muxAssetId: Scalars['ID'];
};

export type DeleteCaptionRes = {
  __typename?: 'DeleteCaptionRes';
  message?: Maybe<Scalars['String']>;
};

export type Episode = {
  __typename?: 'Episode';
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  episodeNumber?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  show: Show;
  seasonEpisode?: Maybe<Array<SeasonEpisode>>;
  isLive?: Maybe<Scalars['Boolean']>;
  thirdPartyId?: Maybe<Scalars['String']>;
  isFeatured?: Maybe<Scalars['Boolean']>;
  featuredBannerImage?: Maybe<Scalars['String']>;
  featureBannerWeight?: Maybe<Scalars['Int']>;
  status: EpisodeStatus;
  algoliaId?: Maybe<Scalars['String']>;
  discussionId?: Maybe<Scalars['String']>;
  segments?: Maybe<Array<EpisodeSegment>>;
  rating?: Maybe<Scalars['String']>;
  allowedContinents: Array<Scalars['String']>;
  allowedCountries?: Maybe<Scalars['Json']>;
  createdBy: User;
  scheduleAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  allowedCountryNames?: Maybe<Array<Maybe<Scalars['String']>>>;
  carouselEpisodeOrder?: Maybe<Scalars['Int']>;
  carouselEpisodeId?: Maybe<Scalars['ID']>;
};


export type EpisodeSeasonEpisodeArgs = {
  where?: Maybe<SeasonEpisodeWhereInput>;
  orderBy?: Maybe<SeasonEpisodeOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type EpisodeSegmentsArgs = {
  where?: Maybe<EpisodeSegmentWhereInput>;
  orderBy?: Maybe<EpisodeSegmentOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type EpisodeCarousel = {
  __typename?: 'EpisodeCarousel';
  id?: Maybe<Scalars['ID']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  nextOrder?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  episodes?: Maybe<Array<Maybe<Episode>>>;
  layout?: Maybe<Scalars['String']>;
};

export type EpisodeCarouselCustomInput = {
  episodeId: Scalars['ID'];
  order?: Maybe<Scalars['Int']>;
};

export type EpisodeCarouselModuleCustomInput = {
  title: Scalars['String'];
  order: Scalars['Int'];
  isPublished?: Maybe<Scalars['Boolean']>;
  carouselEpisodes?: Maybe<Array<Maybe<EpisodeCarouselCustomInput>>>;
};

export type EpisodeCarouselModuleEpisodes = {
  __typename?: 'EpisodeCarouselModuleEpisodes';
  id: Scalars['ID'];
  order?: Maybe<Scalars['Int']>;
  episode?: Maybe<Episode>;
  episodeCarousel?: Maybe<CarouselModule>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type EpisodeCarouselModuleEpisodesConnection = {
  __typename?: 'EpisodeCarouselModuleEpisodesConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<EpisodeCarouselModuleEpisodesEdge>>;
  aggregate: AggregateEpisodeCarouselModuleEpisodes;
};

export type EpisodeCarouselModuleEpisodesCreateInput = {
  id?: Maybe<Scalars['ID']>;
  order?: Maybe<Scalars['Int']>;
  episode?: Maybe<EpisodeCreateOneInput>;
  episodeCarousel?: Maybe<CarouselModuleCreateOneWithoutCarouselEpisodesInput>;
};

export type EpisodeCarouselModuleEpisodesCreateManyWithoutEpisodeCarouselInput = {
  create?: Maybe<Array<EpisodeCarouselModuleEpisodesCreateWithoutEpisodeCarouselInput>>;
  connect?: Maybe<Array<EpisodeCarouselModuleEpisodesWhereUniqueInput>>;
};

export type EpisodeCarouselModuleEpisodesCreateWithoutEpisodeCarouselInput = {
  id?: Maybe<Scalars['ID']>;
  order?: Maybe<Scalars['Int']>;
  episode?: Maybe<EpisodeCreateOneInput>;
};

export type EpisodeCarouselModuleEpisodesEdge = {
  __typename?: 'EpisodeCarouselModuleEpisodesEdge';
  node: EpisodeCarouselModuleEpisodes;
  cursor: Scalars['String'];
};

export enum EpisodeCarouselModuleEpisodesOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  OrderAsc = 'order_ASC',
  OrderDesc = 'order_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type EpisodeCarouselModuleEpisodesPreviousValues = {
  __typename?: 'EpisodeCarouselModuleEpisodesPreviousValues';
  id: Scalars['ID'];
  order?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type EpisodeCarouselModuleEpisodesScalarWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  order?: Maybe<Scalars['Int']>;
  order_not?: Maybe<Scalars['Int']>;
  order_in?: Maybe<Array<Scalars['Int']>>;
  order_not_in?: Maybe<Array<Scalars['Int']>>;
  order_lt?: Maybe<Scalars['Int']>;
  order_lte?: Maybe<Scalars['Int']>;
  order_gt?: Maybe<Scalars['Int']>;
  order_gte?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<EpisodeCarouselModuleEpisodesScalarWhereInput>>;
  OR?: Maybe<Array<EpisodeCarouselModuleEpisodesScalarWhereInput>>;
  NOT?: Maybe<Array<EpisodeCarouselModuleEpisodesScalarWhereInput>>;
};

export type EpisodeCarouselModuleEpisodesSubscriptionPayload = {
  __typename?: 'EpisodeCarouselModuleEpisodesSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<EpisodeCarouselModuleEpisodes>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<EpisodeCarouselModuleEpisodesPreviousValues>;
};

export type EpisodeCarouselModuleEpisodesSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<EpisodeCarouselModuleEpisodesWhereInput>;
  AND?: Maybe<Array<EpisodeCarouselModuleEpisodesSubscriptionWhereInput>>;
  OR?: Maybe<Array<EpisodeCarouselModuleEpisodesSubscriptionWhereInput>>;
  NOT?: Maybe<Array<EpisodeCarouselModuleEpisodesSubscriptionWhereInput>>;
};

export type EpisodeCarouselModuleEpisodesUpdateInput = {
  order?: Maybe<Scalars['Int']>;
  episode?: Maybe<EpisodeUpdateOneInput>;
  episodeCarousel?: Maybe<CarouselModuleUpdateOneWithoutCarouselEpisodesInput>;
};

export type EpisodeCarouselModuleEpisodesUpdateManyDataInput = {
  order?: Maybe<Scalars['Int']>;
};

export type EpisodeCarouselModuleEpisodesUpdateManyMutationInput = {
  order?: Maybe<Scalars['Int']>;
};

export type EpisodeCarouselModuleEpisodesUpdateManyWithWhereNestedInput = {
  where: EpisodeCarouselModuleEpisodesScalarWhereInput;
  data: EpisodeCarouselModuleEpisodesUpdateManyDataInput;
};

export type EpisodeCarouselModuleEpisodesUpdateManyWithoutEpisodeCarouselInput = {
  create?: Maybe<Array<EpisodeCarouselModuleEpisodesCreateWithoutEpisodeCarouselInput>>;
  delete?: Maybe<Array<EpisodeCarouselModuleEpisodesWhereUniqueInput>>;
  connect?: Maybe<Array<EpisodeCarouselModuleEpisodesWhereUniqueInput>>;
  set?: Maybe<Array<EpisodeCarouselModuleEpisodesWhereUniqueInput>>;
  disconnect?: Maybe<Array<EpisodeCarouselModuleEpisodesWhereUniqueInput>>;
  update?: Maybe<Array<EpisodeCarouselModuleEpisodesUpdateWithWhereUniqueWithoutEpisodeCarouselInput>>;
  upsert?: Maybe<Array<EpisodeCarouselModuleEpisodesUpsertWithWhereUniqueWithoutEpisodeCarouselInput>>;
  deleteMany?: Maybe<Array<EpisodeCarouselModuleEpisodesScalarWhereInput>>;
  updateMany?: Maybe<Array<EpisodeCarouselModuleEpisodesUpdateManyWithWhereNestedInput>>;
};

export type EpisodeCarouselModuleEpisodesUpdateWithWhereUniqueWithoutEpisodeCarouselInput = {
  where: EpisodeCarouselModuleEpisodesWhereUniqueInput;
  data: EpisodeCarouselModuleEpisodesUpdateWithoutEpisodeCarouselDataInput;
};

export type EpisodeCarouselModuleEpisodesUpdateWithoutEpisodeCarouselDataInput = {
  order?: Maybe<Scalars['Int']>;
  episode?: Maybe<EpisodeUpdateOneInput>;
};

export type EpisodeCarouselModuleEpisodesUpsertWithWhereUniqueWithoutEpisodeCarouselInput = {
  where: EpisodeCarouselModuleEpisodesWhereUniqueInput;
  update: EpisodeCarouselModuleEpisodesUpdateWithoutEpisodeCarouselDataInput;
  create: EpisodeCarouselModuleEpisodesCreateWithoutEpisodeCarouselInput;
};

export type EpisodeCarouselModuleEpisodesWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  order?: Maybe<Scalars['Int']>;
  order_not?: Maybe<Scalars['Int']>;
  order_in?: Maybe<Array<Scalars['Int']>>;
  order_not_in?: Maybe<Array<Scalars['Int']>>;
  order_lt?: Maybe<Scalars['Int']>;
  order_lte?: Maybe<Scalars['Int']>;
  order_gt?: Maybe<Scalars['Int']>;
  order_gte?: Maybe<Scalars['Int']>;
  episode?: Maybe<EpisodeWhereInput>;
  episodeCarousel?: Maybe<CarouselModuleWhereInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<EpisodeCarouselModuleEpisodesWhereInput>>;
  OR?: Maybe<Array<EpisodeCarouselModuleEpisodesWhereInput>>;
  NOT?: Maybe<Array<EpisodeCarouselModuleEpisodesWhereInput>>;
};

export type EpisodeCarouselModuleEpisodesWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type EpisodeConnection = {
  __typename?: 'EpisodeConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<EpisodeEdge>>;
  aggregate: AggregateEpisode;
};

export type EpisodeCreateInput = {
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  episodeNumber?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  show: ShowCreateOneWithoutEpisodesInput;
  seasonEpisode?: Maybe<SeasonEpisodeCreateManyWithoutEpisodeInput>;
  isLive?: Maybe<Scalars['Boolean']>;
  thirdPartyId?: Maybe<Scalars['String']>;
  isFeatured?: Maybe<Scalars['Boolean']>;
  featuredBannerImage?: Maybe<Scalars['String']>;
  featureBannerWeight?: Maybe<Scalars['Int']>;
  status?: Maybe<EpisodeStatus>;
  algoliaId?: Maybe<Scalars['String']>;
  discussionId?: Maybe<Scalars['String']>;
  segments?: Maybe<EpisodeSegmentCreateManyWithoutEpisodeInput>;
  rating?: Maybe<Scalars['String']>;
  allowedContinents?: Maybe<EpisodeCreateallowedContinentsInput>;
  allowedCountries?: Maybe<Scalars['Json']>;
  createdBy: UserCreateOneInput;
  scheduleAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EpisodeCreateManyWithoutShowInput = {
  create?: Maybe<Array<EpisodeCreateWithoutShowInput>>;
  connect?: Maybe<Array<EpisodeWhereUniqueInput>>;
};

export type EpisodeCreateOneInput = {
  create?: Maybe<EpisodeCreateInput>;
  connect?: Maybe<EpisodeWhereUniqueInput>;
};

export type EpisodeCreateOneWithoutSeasonEpisodeInput = {
  create?: Maybe<EpisodeCreateWithoutSeasonEpisodeInput>;
  connect?: Maybe<EpisodeWhereUniqueInput>;
};

export type EpisodeCreateOneWithoutSegmentsInput = {
  create?: Maybe<EpisodeCreateWithoutSegmentsInput>;
  connect?: Maybe<EpisodeWhereUniqueInput>;
};

export type EpisodeCreateWithoutSeasonEpisodeInput = {
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  episodeNumber?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  show: ShowCreateOneWithoutEpisodesInput;
  isLive?: Maybe<Scalars['Boolean']>;
  thirdPartyId?: Maybe<Scalars['String']>;
  isFeatured?: Maybe<Scalars['Boolean']>;
  featuredBannerImage?: Maybe<Scalars['String']>;
  featureBannerWeight?: Maybe<Scalars['Int']>;
  status?: Maybe<EpisodeStatus>;
  algoliaId?: Maybe<Scalars['String']>;
  discussionId?: Maybe<Scalars['String']>;
  segments?: Maybe<EpisodeSegmentCreateManyWithoutEpisodeInput>;
  rating?: Maybe<Scalars['String']>;
  allowedContinents?: Maybe<EpisodeCreateallowedContinentsInput>;
  allowedCountries?: Maybe<Scalars['Json']>;
  createdBy: UserCreateOneInput;
  scheduleAt?: Maybe<Scalars['DateTime']>;
};

export type EpisodeCreateWithoutSegmentsInput = {
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  episodeNumber?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  show: ShowCreateOneWithoutEpisodesInput;
  seasonEpisode?: Maybe<SeasonEpisodeCreateManyWithoutEpisodeInput>;
  isLive?: Maybe<Scalars['Boolean']>;
  thirdPartyId?: Maybe<Scalars['String']>;
  isFeatured?: Maybe<Scalars['Boolean']>;
  featuredBannerImage?: Maybe<Scalars['String']>;
  featureBannerWeight?: Maybe<Scalars['Int']>;
  status?: Maybe<EpisodeStatus>;
  algoliaId?: Maybe<Scalars['String']>;
  discussionId?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['String']>;
  allowedContinents?: Maybe<EpisodeCreateallowedContinentsInput>;
  allowedCountries?: Maybe<Scalars['Json']>;
  createdBy: UserCreateOneInput;
  scheduleAt?: Maybe<Scalars['DateTime']>;
};

export type EpisodeCreateWithoutShowInput = {
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  episodeNumber?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  seasonEpisode?: Maybe<SeasonEpisodeCreateManyWithoutEpisodeInput>;
  isLive?: Maybe<Scalars['Boolean']>;
  thirdPartyId?: Maybe<Scalars['String']>;
  isFeatured?: Maybe<Scalars['Boolean']>;
  featuredBannerImage?: Maybe<Scalars['String']>;
  featureBannerWeight?: Maybe<Scalars['Int']>;
  status?: Maybe<EpisodeStatus>;
  algoliaId?: Maybe<Scalars['String']>;
  discussionId?: Maybe<Scalars['String']>;
  segments?: Maybe<EpisodeSegmentCreateManyWithoutEpisodeInput>;
  rating?: Maybe<Scalars['String']>;
  allowedContinents?: Maybe<EpisodeCreateallowedContinentsInput>;
  allowedCountries?: Maybe<Scalars['Json']>;
  createdBy: UserCreateOneInput;
  scheduleAt?: Maybe<Scalars['DateTime']>;
};

export type EpisodeCreateallowedContinentsInput = {
  set?: Maybe<Array<Scalars['String']>>;
};

export type EpisodeEdge = {
  __typename?: 'EpisodeEdge';
  node: Episode;
  cursor: Scalars['String'];
};

export enum EpisodeOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  EpisodeNumberAsc = 'episodeNumber_ASC',
  EpisodeNumberDesc = 'episodeNumber_DESC',
  ImageAsc = 'image_ASC',
  ImageDesc = 'image_DESC',
  ThumbnailAsc = 'thumbnail_ASC',
  ThumbnailDesc = 'thumbnail_DESC',
  IsLiveAsc = 'isLive_ASC',
  IsLiveDesc = 'isLive_DESC',
  ThirdPartyIdAsc = 'thirdPartyId_ASC',
  ThirdPartyIdDesc = 'thirdPartyId_DESC',
  IsFeaturedAsc = 'isFeatured_ASC',
  IsFeaturedDesc = 'isFeatured_DESC',
  FeaturedBannerImageAsc = 'featuredBannerImage_ASC',
  FeaturedBannerImageDesc = 'featuredBannerImage_DESC',
  FeatureBannerWeightAsc = 'featureBannerWeight_ASC',
  FeatureBannerWeightDesc = 'featureBannerWeight_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  AlgoliaIdAsc = 'algoliaId_ASC',
  AlgoliaIdDesc = 'algoliaId_DESC',
  DiscussionIdAsc = 'discussionId_ASC',
  DiscussionIdDesc = 'discussionId_DESC',
  RatingAsc = 'rating_ASC',
  RatingDesc = 'rating_DESC',
  AllowedCountriesAsc = 'allowedCountries_ASC',
  AllowedCountriesDesc = 'allowedCountries_DESC',
  ScheduleAtAsc = 'scheduleAt_ASC',
  ScheduleAtDesc = 'scheduleAt_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type EpisodePremiere = {
  __typename?: 'EpisodePremiere';
  id?: Maybe<Scalars['ID']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  premiereImage?: Maybe<Scalars['String']>;
  episode?: Maybe<Episode>;
};

export type EpisodePreviousValues = {
  __typename?: 'EpisodePreviousValues';
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  episodeNumber?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  isLive?: Maybe<Scalars['Boolean']>;
  thirdPartyId?: Maybe<Scalars['String']>;
  isFeatured?: Maybe<Scalars['Boolean']>;
  featuredBannerImage?: Maybe<Scalars['String']>;
  featureBannerWeight?: Maybe<Scalars['Int']>;
  status: EpisodeStatus;
  algoliaId?: Maybe<Scalars['String']>;
  discussionId?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['String']>;
  allowedContinents: Array<Scalars['String']>;
  allowedCountries?: Maybe<Scalars['Json']>;
  scheduleAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type EpisodeScalarWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Scalars['String']>>;
  title_not_in?: Maybe<Array<Scalars['String']>>;
  title_lt?: Maybe<Scalars['String']>;
  title_lte?: Maybe<Scalars['String']>;
  title_gt?: Maybe<Scalars['String']>;
  title_gte?: Maybe<Scalars['String']>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  title_starts_with?: Maybe<Scalars['String']>;
  title_not_starts_with?: Maybe<Scalars['String']>;
  title_ends_with?: Maybe<Scalars['String']>;
  title_not_ends_with?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  description_not?: Maybe<Scalars['String']>;
  description_in?: Maybe<Array<Scalars['String']>>;
  description_not_in?: Maybe<Array<Scalars['String']>>;
  description_lt?: Maybe<Scalars['String']>;
  description_lte?: Maybe<Scalars['String']>;
  description_gt?: Maybe<Scalars['String']>;
  description_gte?: Maybe<Scalars['String']>;
  description_contains?: Maybe<Scalars['String']>;
  description_not_contains?: Maybe<Scalars['String']>;
  description_starts_with?: Maybe<Scalars['String']>;
  description_not_starts_with?: Maybe<Scalars['String']>;
  description_ends_with?: Maybe<Scalars['String']>;
  description_not_ends_with?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  slug_not?: Maybe<Scalars['String']>;
  slug_in?: Maybe<Array<Scalars['String']>>;
  slug_not_in?: Maybe<Array<Scalars['String']>>;
  slug_lt?: Maybe<Scalars['String']>;
  slug_lte?: Maybe<Scalars['String']>;
  slug_gt?: Maybe<Scalars['String']>;
  slug_gte?: Maybe<Scalars['String']>;
  slug_contains?: Maybe<Scalars['String']>;
  slug_not_contains?: Maybe<Scalars['String']>;
  slug_starts_with?: Maybe<Scalars['String']>;
  slug_not_starts_with?: Maybe<Scalars['String']>;
  slug_ends_with?: Maybe<Scalars['String']>;
  slug_not_ends_with?: Maybe<Scalars['String']>;
  episodeNumber?: Maybe<Scalars['String']>;
  episodeNumber_not?: Maybe<Scalars['String']>;
  episodeNumber_in?: Maybe<Array<Scalars['String']>>;
  episodeNumber_not_in?: Maybe<Array<Scalars['String']>>;
  episodeNumber_lt?: Maybe<Scalars['String']>;
  episodeNumber_lte?: Maybe<Scalars['String']>;
  episodeNumber_gt?: Maybe<Scalars['String']>;
  episodeNumber_gte?: Maybe<Scalars['String']>;
  episodeNumber_contains?: Maybe<Scalars['String']>;
  episodeNumber_not_contains?: Maybe<Scalars['String']>;
  episodeNumber_starts_with?: Maybe<Scalars['String']>;
  episodeNumber_not_starts_with?: Maybe<Scalars['String']>;
  episodeNumber_ends_with?: Maybe<Scalars['String']>;
  episodeNumber_not_ends_with?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  image_not?: Maybe<Scalars['String']>;
  image_in?: Maybe<Array<Scalars['String']>>;
  image_not_in?: Maybe<Array<Scalars['String']>>;
  image_lt?: Maybe<Scalars['String']>;
  image_lte?: Maybe<Scalars['String']>;
  image_gt?: Maybe<Scalars['String']>;
  image_gte?: Maybe<Scalars['String']>;
  image_contains?: Maybe<Scalars['String']>;
  image_not_contains?: Maybe<Scalars['String']>;
  image_starts_with?: Maybe<Scalars['String']>;
  image_not_starts_with?: Maybe<Scalars['String']>;
  image_ends_with?: Maybe<Scalars['String']>;
  image_not_ends_with?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  thumbnail_not?: Maybe<Scalars['String']>;
  thumbnail_in?: Maybe<Array<Scalars['String']>>;
  thumbnail_not_in?: Maybe<Array<Scalars['String']>>;
  thumbnail_lt?: Maybe<Scalars['String']>;
  thumbnail_lte?: Maybe<Scalars['String']>;
  thumbnail_gt?: Maybe<Scalars['String']>;
  thumbnail_gte?: Maybe<Scalars['String']>;
  thumbnail_contains?: Maybe<Scalars['String']>;
  thumbnail_not_contains?: Maybe<Scalars['String']>;
  thumbnail_starts_with?: Maybe<Scalars['String']>;
  thumbnail_not_starts_with?: Maybe<Scalars['String']>;
  thumbnail_ends_with?: Maybe<Scalars['String']>;
  thumbnail_not_ends_with?: Maybe<Scalars['String']>;
  isLive?: Maybe<Scalars['Boolean']>;
  isLive_not?: Maybe<Scalars['Boolean']>;
  thirdPartyId?: Maybe<Scalars['String']>;
  thirdPartyId_not?: Maybe<Scalars['String']>;
  thirdPartyId_in?: Maybe<Array<Scalars['String']>>;
  thirdPartyId_not_in?: Maybe<Array<Scalars['String']>>;
  thirdPartyId_lt?: Maybe<Scalars['String']>;
  thirdPartyId_lte?: Maybe<Scalars['String']>;
  thirdPartyId_gt?: Maybe<Scalars['String']>;
  thirdPartyId_gte?: Maybe<Scalars['String']>;
  thirdPartyId_contains?: Maybe<Scalars['String']>;
  thirdPartyId_not_contains?: Maybe<Scalars['String']>;
  thirdPartyId_starts_with?: Maybe<Scalars['String']>;
  thirdPartyId_not_starts_with?: Maybe<Scalars['String']>;
  thirdPartyId_ends_with?: Maybe<Scalars['String']>;
  thirdPartyId_not_ends_with?: Maybe<Scalars['String']>;
  isFeatured?: Maybe<Scalars['Boolean']>;
  isFeatured_not?: Maybe<Scalars['Boolean']>;
  featuredBannerImage?: Maybe<Scalars['String']>;
  featuredBannerImage_not?: Maybe<Scalars['String']>;
  featuredBannerImage_in?: Maybe<Array<Scalars['String']>>;
  featuredBannerImage_not_in?: Maybe<Array<Scalars['String']>>;
  featuredBannerImage_lt?: Maybe<Scalars['String']>;
  featuredBannerImage_lte?: Maybe<Scalars['String']>;
  featuredBannerImage_gt?: Maybe<Scalars['String']>;
  featuredBannerImage_gte?: Maybe<Scalars['String']>;
  featuredBannerImage_contains?: Maybe<Scalars['String']>;
  featuredBannerImage_not_contains?: Maybe<Scalars['String']>;
  featuredBannerImage_starts_with?: Maybe<Scalars['String']>;
  featuredBannerImage_not_starts_with?: Maybe<Scalars['String']>;
  featuredBannerImage_ends_with?: Maybe<Scalars['String']>;
  featuredBannerImage_not_ends_with?: Maybe<Scalars['String']>;
  featureBannerWeight?: Maybe<Scalars['Int']>;
  featureBannerWeight_not?: Maybe<Scalars['Int']>;
  featureBannerWeight_in?: Maybe<Array<Scalars['Int']>>;
  featureBannerWeight_not_in?: Maybe<Array<Scalars['Int']>>;
  featureBannerWeight_lt?: Maybe<Scalars['Int']>;
  featureBannerWeight_lte?: Maybe<Scalars['Int']>;
  featureBannerWeight_gt?: Maybe<Scalars['Int']>;
  featureBannerWeight_gte?: Maybe<Scalars['Int']>;
  status?: Maybe<EpisodeStatus>;
  status_not?: Maybe<EpisodeStatus>;
  status_in?: Maybe<Array<EpisodeStatus>>;
  status_not_in?: Maybe<Array<EpisodeStatus>>;
  algoliaId?: Maybe<Scalars['String']>;
  algoliaId_not?: Maybe<Scalars['String']>;
  algoliaId_in?: Maybe<Array<Scalars['String']>>;
  algoliaId_not_in?: Maybe<Array<Scalars['String']>>;
  algoliaId_lt?: Maybe<Scalars['String']>;
  algoliaId_lte?: Maybe<Scalars['String']>;
  algoliaId_gt?: Maybe<Scalars['String']>;
  algoliaId_gte?: Maybe<Scalars['String']>;
  algoliaId_contains?: Maybe<Scalars['String']>;
  algoliaId_not_contains?: Maybe<Scalars['String']>;
  algoliaId_starts_with?: Maybe<Scalars['String']>;
  algoliaId_not_starts_with?: Maybe<Scalars['String']>;
  algoliaId_ends_with?: Maybe<Scalars['String']>;
  algoliaId_not_ends_with?: Maybe<Scalars['String']>;
  discussionId?: Maybe<Scalars['String']>;
  discussionId_not?: Maybe<Scalars['String']>;
  discussionId_in?: Maybe<Array<Scalars['String']>>;
  discussionId_not_in?: Maybe<Array<Scalars['String']>>;
  discussionId_lt?: Maybe<Scalars['String']>;
  discussionId_lte?: Maybe<Scalars['String']>;
  discussionId_gt?: Maybe<Scalars['String']>;
  discussionId_gte?: Maybe<Scalars['String']>;
  discussionId_contains?: Maybe<Scalars['String']>;
  discussionId_not_contains?: Maybe<Scalars['String']>;
  discussionId_starts_with?: Maybe<Scalars['String']>;
  discussionId_not_starts_with?: Maybe<Scalars['String']>;
  discussionId_ends_with?: Maybe<Scalars['String']>;
  discussionId_not_ends_with?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['String']>;
  rating_not?: Maybe<Scalars['String']>;
  rating_in?: Maybe<Array<Scalars['String']>>;
  rating_not_in?: Maybe<Array<Scalars['String']>>;
  rating_lt?: Maybe<Scalars['String']>;
  rating_lte?: Maybe<Scalars['String']>;
  rating_gt?: Maybe<Scalars['String']>;
  rating_gte?: Maybe<Scalars['String']>;
  rating_contains?: Maybe<Scalars['String']>;
  rating_not_contains?: Maybe<Scalars['String']>;
  rating_starts_with?: Maybe<Scalars['String']>;
  rating_not_starts_with?: Maybe<Scalars['String']>;
  rating_ends_with?: Maybe<Scalars['String']>;
  rating_not_ends_with?: Maybe<Scalars['String']>;
  scheduleAt?: Maybe<Scalars['DateTime']>;
  scheduleAt_not?: Maybe<Scalars['DateTime']>;
  scheduleAt_in?: Maybe<Array<Scalars['DateTime']>>;
  scheduleAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  scheduleAt_lt?: Maybe<Scalars['DateTime']>;
  scheduleAt_lte?: Maybe<Scalars['DateTime']>;
  scheduleAt_gt?: Maybe<Scalars['DateTime']>;
  scheduleAt_gte?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<EpisodeScalarWhereInput>>;
  OR?: Maybe<Array<EpisodeScalarWhereInput>>;
  NOT?: Maybe<Array<EpisodeScalarWhereInput>>;
};

export type EpisodeSegment = {
  __typename?: 'EpisodeSegment';
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  episode: Episode;
  order: Scalars['Int'];
  simulateLive?: Maybe<Scalars['Boolean']>;
  liveVideoURL?: Maybe<Scalars['String']>;
  videoState?: Maybe<Scalars['String']>;
  audioState?: Maybe<Scalars['String']>;
  muxAssetId?: Maybe<Scalars['String']>;
  streamKey?: Maybe<Scalars['String']>;
  captions?: Maybe<Array<Caption>>;
  liveChatAccess: Array<Scalars['String']>;
  videoAccess: Array<Scalars['String']>;
  audioAccess: Array<Scalars['String']>;
  accessLevelScheduleAt?: Maybe<Scalars['DateTime']>;
  scheduleVideoAccess: Array<Scalars['String']>;
  scheduleAudioAccess: Array<Scalars['String']>;
  uploadId?: Maybe<Scalars['String']>;
  muxPlaybackId?: Maybe<Scalars['String']>;
  playbackPolicy?: Maybe<Scalars['String']>;
  audio?: Maybe<Scalars['String']>;
  video?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  muxStoryBoardURL?: Maybe<Scalars['String']>;
  watchTime?: Maybe<Scalars['Float']>;
  listenTime?: Maybe<Scalars['Float']>;
};


export type EpisodeSegmentCaptionsArgs = {
  where?: Maybe<CaptionWhereInput>;
  orderBy?: Maybe<CaptionOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type EpisodeSegmentConnection = {
  __typename?: 'EpisodeSegmentConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<EpisodeSegmentEdge>>;
  aggregate: AggregateEpisodeSegment;
};

export type EpisodeSegmentCreateInput = {
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  episode: EpisodeCreateOneWithoutSegmentsInput;
  order: Scalars['Int'];
  simulateLive?: Maybe<Scalars['Boolean']>;
  liveVideoURL?: Maybe<Scalars['String']>;
  videoState?: Maybe<Scalars['String']>;
  audioState?: Maybe<Scalars['String']>;
  muxAssetId?: Maybe<Scalars['String']>;
  streamKey?: Maybe<Scalars['String']>;
  captions?: Maybe<CaptionCreateManyInput>;
  liveChatAccess?: Maybe<EpisodeSegmentCreateliveChatAccessInput>;
  videoAccess?: Maybe<EpisodeSegmentCreatevideoAccessInput>;
  audioAccess?: Maybe<EpisodeSegmentCreateaudioAccessInput>;
  accessLevelScheduleAt?: Maybe<Scalars['DateTime']>;
  scheduleVideoAccess?: Maybe<EpisodeSegmentCreatescheduleVideoAccessInput>;
  scheduleAudioAccess?: Maybe<EpisodeSegmentCreatescheduleAudioAccessInput>;
  uploadId?: Maybe<Scalars['String']>;
  muxPlaybackId?: Maybe<Scalars['String']>;
  playbackPolicy?: Maybe<Scalars['String']>;
  audio?: Maybe<Scalars['String']>;
  video?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
};

export type EpisodeSegmentCreateManyWithoutEpisodeInput = {
  create?: Maybe<Array<EpisodeSegmentCreateWithoutEpisodeInput>>;
  connect?: Maybe<Array<EpisodeSegmentWhereUniqueInput>>;
};

export type EpisodeSegmentCreateOneInput = {
  create?: Maybe<EpisodeSegmentCreateInput>;
  connect?: Maybe<EpisodeSegmentWhereUniqueInput>;
};

export type EpisodeSegmentCreateWithoutEpisodeInput = {
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  order: Scalars['Int'];
  simulateLive?: Maybe<Scalars['Boolean']>;
  liveVideoURL?: Maybe<Scalars['String']>;
  videoState?: Maybe<Scalars['String']>;
  audioState?: Maybe<Scalars['String']>;
  muxAssetId?: Maybe<Scalars['String']>;
  streamKey?: Maybe<Scalars['String']>;
  captions?: Maybe<CaptionCreateManyInput>;
  liveChatAccess?: Maybe<EpisodeSegmentCreateliveChatAccessInput>;
  videoAccess?: Maybe<EpisodeSegmentCreatevideoAccessInput>;
  audioAccess?: Maybe<EpisodeSegmentCreateaudioAccessInput>;
  accessLevelScheduleAt?: Maybe<Scalars['DateTime']>;
  scheduleVideoAccess?: Maybe<EpisodeSegmentCreatescheduleVideoAccessInput>;
  scheduleAudioAccess?: Maybe<EpisodeSegmentCreatescheduleAudioAccessInput>;
  uploadId?: Maybe<Scalars['String']>;
  muxPlaybackId?: Maybe<Scalars['String']>;
  playbackPolicy?: Maybe<Scalars['String']>;
  audio?: Maybe<Scalars['String']>;
  video?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
};

export type EpisodeSegmentCreateaudioAccessInput = {
  set?: Maybe<Array<Scalars['String']>>;
};

export type EpisodeSegmentCreateliveChatAccessInput = {
  set?: Maybe<Array<Scalars['String']>>;
};

export type EpisodeSegmentCreatescheduleAudioAccessInput = {
  set?: Maybe<Array<Scalars['String']>>;
};

export type EpisodeSegmentCreatescheduleVideoAccessInput = {
  set?: Maybe<Array<Scalars['String']>>;
};

export type EpisodeSegmentCreatevideoAccessInput = {
  set?: Maybe<Array<Scalars['String']>>;
};

export type EpisodeSegmentEdge = {
  __typename?: 'EpisodeSegmentEdge';
  node: EpisodeSegment;
  cursor: Scalars['String'];
};

export enum EpisodeSegmentOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  ImageAsc = 'image_ASC',
  ImageDesc = 'image_DESC',
  ThumbnailAsc = 'thumbnail_ASC',
  ThumbnailDesc = 'thumbnail_DESC',
  OrderAsc = 'order_ASC',
  OrderDesc = 'order_DESC',
  SimulateLiveAsc = 'simulateLive_ASC',
  SimulateLiveDesc = 'simulateLive_DESC',
  LiveVideoUrlAsc = 'liveVideoURL_ASC',
  LiveVideoUrlDesc = 'liveVideoURL_DESC',
  VideoStateAsc = 'videoState_ASC',
  VideoStateDesc = 'videoState_DESC',
  AudioStateAsc = 'audioState_ASC',
  AudioStateDesc = 'audioState_DESC',
  MuxAssetIdAsc = 'muxAssetId_ASC',
  MuxAssetIdDesc = 'muxAssetId_DESC',
  StreamKeyAsc = 'streamKey_ASC',
  StreamKeyDesc = 'streamKey_DESC',
  AccessLevelScheduleAtAsc = 'accessLevelScheduleAt_ASC',
  AccessLevelScheduleAtDesc = 'accessLevelScheduleAt_DESC',
  UploadIdAsc = 'uploadId_ASC',
  UploadIdDesc = 'uploadId_DESC',
  MuxPlaybackIdAsc = 'muxPlaybackId_ASC',
  MuxPlaybackIdDesc = 'muxPlaybackId_DESC',
  PlaybackPolicyAsc = 'playbackPolicy_ASC',
  PlaybackPolicyDesc = 'playbackPolicy_DESC',
  AudioAsc = 'audio_ASC',
  AudioDesc = 'audio_DESC',
  VideoAsc = 'video_ASC',
  VideoDesc = 'video_DESC',
  DurationAsc = 'duration_ASC',
  DurationDesc = 'duration_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type EpisodeSegmentPreviousValues = {
  __typename?: 'EpisodeSegmentPreviousValues';
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  order: Scalars['Int'];
  simulateLive?: Maybe<Scalars['Boolean']>;
  liveVideoURL?: Maybe<Scalars['String']>;
  videoState?: Maybe<Scalars['String']>;
  audioState?: Maybe<Scalars['String']>;
  muxAssetId?: Maybe<Scalars['String']>;
  streamKey?: Maybe<Scalars['String']>;
  liveChatAccess: Array<Scalars['String']>;
  videoAccess: Array<Scalars['String']>;
  audioAccess: Array<Scalars['String']>;
  accessLevelScheduleAt?: Maybe<Scalars['DateTime']>;
  scheduleVideoAccess: Array<Scalars['String']>;
  scheduleAudioAccess: Array<Scalars['String']>;
  uploadId?: Maybe<Scalars['String']>;
  muxPlaybackId?: Maybe<Scalars['String']>;
  playbackPolicy?: Maybe<Scalars['String']>;
  audio?: Maybe<Scalars['String']>;
  video?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type EpisodeSegmentScalarWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Scalars['String']>>;
  title_not_in?: Maybe<Array<Scalars['String']>>;
  title_lt?: Maybe<Scalars['String']>;
  title_lte?: Maybe<Scalars['String']>;
  title_gt?: Maybe<Scalars['String']>;
  title_gte?: Maybe<Scalars['String']>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  title_starts_with?: Maybe<Scalars['String']>;
  title_not_starts_with?: Maybe<Scalars['String']>;
  title_ends_with?: Maybe<Scalars['String']>;
  title_not_ends_with?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  subtitle_not?: Maybe<Scalars['String']>;
  subtitle_in?: Maybe<Array<Scalars['String']>>;
  subtitle_not_in?: Maybe<Array<Scalars['String']>>;
  subtitle_lt?: Maybe<Scalars['String']>;
  subtitle_lte?: Maybe<Scalars['String']>;
  subtitle_gt?: Maybe<Scalars['String']>;
  subtitle_gte?: Maybe<Scalars['String']>;
  subtitle_contains?: Maybe<Scalars['String']>;
  subtitle_not_contains?: Maybe<Scalars['String']>;
  subtitle_starts_with?: Maybe<Scalars['String']>;
  subtitle_not_starts_with?: Maybe<Scalars['String']>;
  subtitle_ends_with?: Maybe<Scalars['String']>;
  subtitle_not_ends_with?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  description_not?: Maybe<Scalars['String']>;
  description_in?: Maybe<Array<Scalars['String']>>;
  description_not_in?: Maybe<Array<Scalars['String']>>;
  description_lt?: Maybe<Scalars['String']>;
  description_lte?: Maybe<Scalars['String']>;
  description_gt?: Maybe<Scalars['String']>;
  description_gte?: Maybe<Scalars['String']>;
  description_contains?: Maybe<Scalars['String']>;
  description_not_contains?: Maybe<Scalars['String']>;
  description_starts_with?: Maybe<Scalars['String']>;
  description_not_starts_with?: Maybe<Scalars['String']>;
  description_ends_with?: Maybe<Scalars['String']>;
  description_not_ends_with?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  image_not?: Maybe<Scalars['String']>;
  image_in?: Maybe<Array<Scalars['String']>>;
  image_not_in?: Maybe<Array<Scalars['String']>>;
  image_lt?: Maybe<Scalars['String']>;
  image_lte?: Maybe<Scalars['String']>;
  image_gt?: Maybe<Scalars['String']>;
  image_gte?: Maybe<Scalars['String']>;
  image_contains?: Maybe<Scalars['String']>;
  image_not_contains?: Maybe<Scalars['String']>;
  image_starts_with?: Maybe<Scalars['String']>;
  image_not_starts_with?: Maybe<Scalars['String']>;
  image_ends_with?: Maybe<Scalars['String']>;
  image_not_ends_with?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  thumbnail_not?: Maybe<Scalars['String']>;
  thumbnail_in?: Maybe<Array<Scalars['String']>>;
  thumbnail_not_in?: Maybe<Array<Scalars['String']>>;
  thumbnail_lt?: Maybe<Scalars['String']>;
  thumbnail_lte?: Maybe<Scalars['String']>;
  thumbnail_gt?: Maybe<Scalars['String']>;
  thumbnail_gte?: Maybe<Scalars['String']>;
  thumbnail_contains?: Maybe<Scalars['String']>;
  thumbnail_not_contains?: Maybe<Scalars['String']>;
  thumbnail_starts_with?: Maybe<Scalars['String']>;
  thumbnail_not_starts_with?: Maybe<Scalars['String']>;
  thumbnail_ends_with?: Maybe<Scalars['String']>;
  thumbnail_not_ends_with?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  order_not?: Maybe<Scalars['Int']>;
  order_in?: Maybe<Array<Scalars['Int']>>;
  order_not_in?: Maybe<Array<Scalars['Int']>>;
  order_lt?: Maybe<Scalars['Int']>;
  order_lte?: Maybe<Scalars['Int']>;
  order_gt?: Maybe<Scalars['Int']>;
  order_gte?: Maybe<Scalars['Int']>;
  simulateLive?: Maybe<Scalars['Boolean']>;
  simulateLive_not?: Maybe<Scalars['Boolean']>;
  liveVideoURL?: Maybe<Scalars['String']>;
  liveVideoURL_not?: Maybe<Scalars['String']>;
  liveVideoURL_in?: Maybe<Array<Scalars['String']>>;
  liveVideoURL_not_in?: Maybe<Array<Scalars['String']>>;
  liveVideoURL_lt?: Maybe<Scalars['String']>;
  liveVideoURL_lte?: Maybe<Scalars['String']>;
  liveVideoURL_gt?: Maybe<Scalars['String']>;
  liveVideoURL_gte?: Maybe<Scalars['String']>;
  liveVideoURL_contains?: Maybe<Scalars['String']>;
  liveVideoURL_not_contains?: Maybe<Scalars['String']>;
  liveVideoURL_starts_with?: Maybe<Scalars['String']>;
  liveVideoURL_not_starts_with?: Maybe<Scalars['String']>;
  liveVideoURL_ends_with?: Maybe<Scalars['String']>;
  liveVideoURL_not_ends_with?: Maybe<Scalars['String']>;
  videoState?: Maybe<Scalars['String']>;
  videoState_not?: Maybe<Scalars['String']>;
  videoState_in?: Maybe<Array<Scalars['String']>>;
  videoState_not_in?: Maybe<Array<Scalars['String']>>;
  videoState_lt?: Maybe<Scalars['String']>;
  videoState_lte?: Maybe<Scalars['String']>;
  videoState_gt?: Maybe<Scalars['String']>;
  videoState_gte?: Maybe<Scalars['String']>;
  videoState_contains?: Maybe<Scalars['String']>;
  videoState_not_contains?: Maybe<Scalars['String']>;
  videoState_starts_with?: Maybe<Scalars['String']>;
  videoState_not_starts_with?: Maybe<Scalars['String']>;
  videoState_ends_with?: Maybe<Scalars['String']>;
  videoState_not_ends_with?: Maybe<Scalars['String']>;
  audioState?: Maybe<Scalars['String']>;
  audioState_not?: Maybe<Scalars['String']>;
  audioState_in?: Maybe<Array<Scalars['String']>>;
  audioState_not_in?: Maybe<Array<Scalars['String']>>;
  audioState_lt?: Maybe<Scalars['String']>;
  audioState_lte?: Maybe<Scalars['String']>;
  audioState_gt?: Maybe<Scalars['String']>;
  audioState_gte?: Maybe<Scalars['String']>;
  audioState_contains?: Maybe<Scalars['String']>;
  audioState_not_contains?: Maybe<Scalars['String']>;
  audioState_starts_with?: Maybe<Scalars['String']>;
  audioState_not_starts_with?: Maybe<Scalars['String']>;
  audioState_ends_with?: Maybe<Scalars['String']>;
  audioState_not_ends_with?: Maybe<Scalars['String']>;
  muxAssetId?: Maybe<Scalars['String']>;
  muxAssetId_not?: Maybe<Scalars['String']>;
  muxAssetId_in?: Maybe<Array<Scalars['String']>>;
  muxAssetId_not_in?: Maybe<Array<Scalars['String']>>;
  muxAssetId_lt?: Maybe<Scalars['String']>;
  muxAssetId_lte?: Maybe<Scalars['String']>;
  muxAssetId_gt?: Maybe<Scalars['String']>;
  muxAssetId_gte?: Maybe<Scalars['String']>;
  muxAssetId_contains?: Maybe<Scalars['String']>;
  muxAssetId_not_contains?: Maybe<Scalars['String']>;
  muxAssetId_starts_with?: Maybe<Scalars['String']>;
  muxAssetId_not_starts_with?: Maybe<Scalars['String']>;
  muxAssetId_ends_with?: Maybe<Scalars['String']>;
  muxAssetId_not_ends_with?: Maybe<Scalars['String']>;
  streamKey?: Maybe<Scalars['String']>;
  streamKey_not?: Maybe<Scalars['String']>;
  streamKey_in?: Maybe<Array<Scalars['String']>>;
  streamKey_not_in?: Maybe<Array<Scalars['String']>>;
  streamKey_lt?: Maybe<Scalars['String']>;
  streamKey_lte?: Maybe<Scalars['String']>;
  streamKey_gt?: Maybe<Scalars['String']>;
  streamKey_gte?: Maybe<Scalars['String']>;
  streamKey_contains?: Maybe<Scalars['String']>;
  streamKey_not_contains?: Maybe<Scalars['String']>;
  streamKey_starts_with?: Maybe<Scalars['String']>;
  streamKey_not_starts_with?: Maybe<Scalars['String']>;
  streamKey_ends_with?: Maybe<Scalars['String']>;
  streamKey_not_ends_with?: Maybe<Scalars['String']>;
  accessLevelScheduleAt?: Maybe<Scalars['DateTime']>;
  accessLevelScheduleAt_not?: Maybe<Scalars['DateTime']>;
  accessLevelScheduleAt_in?: Maybe<Array<Scalars['DateTime']>>;
  accessLevelScheduleAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  accessLevelScheduleAt_lt?: Maybe<Scalars['DateTime']>;
  accessLevelScheduleAt_lte?: Maybe<Scalars['DateTime']>;
  accessLevelScheduleAt_gt?: Maybe<Scalars['DateTime']>;
  accessLevelScheduleAt_gte?: Maybe<Scalars['DateTime']>;
  uploadId?: Maybe<Scalars['String']>;
  uploadId_not?: Maybe<Scalars['String']>;
  uploadId_in?: Maybe<Array<Scalars['String']>>;
  uploadId_not_in?: Maybe<Array<Scalars['String']>>;
  uploadId_lt?: Maybe<Scalars['String']>;
  uploadId_lte?: Maybe<Scalars['String']>;
  uploadId_gt?: Maybe<Scalars['String']>;
  uploadId_gte?: Maybe<Scalars['String']>;
  uploadId_contains?: Maybe<Scalars['String']>;
  uploadId_not_contains?: Maybe<Scalars['String']>;
  uploadId_starts_with?: Maybe<Scalars['String']>;
  uploadId_not_starts_with?: Maybe<Scalars['String']>;
  uploadId_ends_with?: Maybe<Scalars['String']>;
  uploadId_not_ends_with?: Maybe<Scalars['String']>;
  muxPlaybackId?: Maybe<Scalars['String']>;
  muxPlaybackId_not?: Maybe<Scalars['String']>;
  muxPlaybackId_in?: Maybe<Array<Scalars['String']>>;
  muxPlaybackId_not_in?: Maybe<Array<Scalars['String']>>;
  muxPlaybackId_lt?: Maybe<Scalars['String']>;
  muxPlaybackId_lte?: Maybe<Scalars['String']>;
  muxPlaybackId_gt?: Maybe<Scalars['String']>;
  muxPlaybackId_gte?: Maybe<Scalars['String']>;
  muxPlaybackId_contains?: Maybe<Scalars['String']>;
  muxPlaybackId_not_contains?: Maybe<Scalars['String']>;
  muxPlaybackId_starts_with?: Maybe<Scalars['String']>;
  muxPlaybackId_not_starts_with?: Maybe<Scalars['String']>;
  muxPlaybackId_ends_with?: Maybe<Scalars['String']>;
  muxPlaybackId_not_ends_with?: Maybe<Scalars['String']>;
  playbackPolicy?: Maybe<Scalars['String']>;
  playbackPolicy_not?: Maybe<Scalars['String']>;
  playbackPolicy_in?: Maybe<Array<Scalars['String']>>;
  playbackPolicy_not_in?: Maybe<Array<Scalars['String']>>;
  playbackPolicy_lt?: Maybe<Scalars['String']>;
  playbackPolicy_lte?: Maybe<Scalars['String']>;
  playbackPolicy_gt?: Maybe<Scalars['String']>;
  playbackPolicy_gte?: Maybe<Scalars['String']>;
  playbackPolicy_contains?: Maybe<Scalars['String']>;
  playbackPolicy_not_contains?: Maybe<Scalars['String']>;
  playbackPolicy_starts_with?: Maybe<Scalars['String']>;
  playbackPolicy_not_starts_with?: Maybe<Scalars['String']>;
  playbackPolicy_ends_with?: Maybe<Scalars['String']>;
  playbackPolicy_not_ends_with?: Maybe<Scalars['String']>;
  audio?: Maybe<Scalars['String']>;
  audio_not?: Maybe<Scalars['String']>;
  audio_in?: Maybe<Array<Scalars['String']>>;
  audio_not_in?: Maybe<Array<Scalars['String']>>;
  audio_lt?: Maybe<Scalars['String']>;
  audio_lte?: Maybe<Scalars['String']>;
  audio_gt?: Maybe<Scalars['String']>;
  audio_gte?: Maybe<Scalars['String']>;
  audio_contains?: Maybe<Scalars['String']>;
  audio_not_contains?: Maybe<Scalars['String']>;
  audio_starts_with?: Maybe<Scalars['String']>;
  audio_not_starts_with?: Maybe<Scalars['String']>;
  audio_ends_with?: Maybe<Scalars['String']>;
  audio_not_ends_with?: Maybe<Scalars['String']>;
  video?: Maybe<Scalars['String']>;
  video_not?: Maybe<Scalars['String']>;
  video_in?: Maybe<Array<Scalars['String']>>;
  video_not_in?: Maybe<Array<Scalars['String']>>;
  video_lt?: Maybe<Scalars['String']>;
  video_lte?: Maybe<Scalars['String']>;
  video_gt?: Maybe<Scalars['String']>;
  video_gte?: Maybe<Scalars['String']>;
  video_contains?: Maybe<Scalars['String']>;
  video_not_contains?: Maybe<Scalars['String']>;
  video_starts_with?: Maybe<Scalars['String']>;
  video_not_starts_with?: Maybe<Scalars['String']>;
  video_ends_with?: Maybe<Scalars['String']>;
  video_not_ends_with?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
  duration_not?: Maybe<Scalars['Float']>;
  duration_in?: Maybe<Array<Scalars['Float']>>;
  duration_not_in?: Maybe<Array<Scalars['Float']>>;
  duration_lt?: Maybe<Scalars['Float']>;
  duration_lte?: Maybe<Scalars['Float']>;
  duration_gt?: Maybe<Scalars['Float']>;
  duration_gte?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<EpisodeSegmentScalarWhereInput>>;
  OR?: Maybe<Array<EpisodeSegmentScalarWhereInput>>;
  NOT?: Maybe<Array<EpisodeSegmentScalarWhereInput>>;
};

export type EpisodeSegmentSubscriptionPayload = {
  __typename?: 'EpisodeSegmentSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<EpisodeSegment>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<EpisodeSegmentPreviousValues>;
};

export type EpisodeSegmentSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<EpisodeSegmentWhereInput>;
  AND?: Maybe<Array<EpisodeSegmentSubscriptionWhereInput>>;
  OR?: Maybe<Array<EpisodeSegmentSubscriptionWhereInput>>;
  NOT?: Maybe<Array<EpisodeSegmentSubscriptionWhereInput>>;
};

export type EpisodeSegmentUpdateDataInput = {
  title?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  episode?: Maybe<EpisodeUpdateOneRequiredWithoutSegmentsInput>;
  order?: Maybe<Scalars['Int']>;
  simulateLive?: Maybe<Scalars['Boolean']>;
  liveVideoURL?: Maybe<Scalars['String']>;
  videoState?: Maybe<Scalars['String']>;
  audioState?: Maybe<Scalars['String']>;
  muxAssetId?: Maybe<Scalars['String']>;
  streamKey?: Maybe<Scalars['String']>;
  captions?: Maybe<CaptionUpdateManyInput>;
  liveChatAccess?: Maybe<EpisodeSegmentUpdateliveChatAccessInput>;
  videoAccess?: Maybe<EpisodeSegmentUpdatevideoAccessInput>;
  audioAccess?: Maybe<EpisodeSegmentUpdateaudioAccessInput>;
  accessLevelScheduleAt?: Maybe<Scalars['DateTime']>;
  scheduleVideoAccess?: Maybe<EpisodeSegmentUpdatescheduleVideoAccessInput>;
  scheduleAudioAccess?: Maybe<EpisodeSegmentUpdatescheduleAudioAccessInput>;
  uploadId?: Maybe<Scalars['String']>;
  muxPlaybackId?: Maybe<Scalars['String']>;
  playbackPolicy?: Maybe<Scalars['String']>;
  audio?: Maybe<Scalars['String']>;
  video?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
};

export type EpisodeSegmentUpdateInput = {
  title?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  episode?: Maybe<EpisodeUpdateOneRequiredWithoutSegmentsInput>;
  order?: Maybe<Scalars['Int']>;
  simulateLive?: Maybe<Scalars['Boolean']>;
  liveVideoURL?: Maybe<Scalars['String']>;
  videoState?: Maybe<Scalars['String']>;
  audioState?: Maybe<Scalars['String']>;
  muxAssetId?: Maybe<Scalars['String']>;
  streamKey?: Maybe<Scalars['String']>;
  captions?: Maybe<CaptionUpdateManyInput>;
  liveChatAccess?: Maybe<EpisodeSegmentUpdateliveChatAccessInput>;
  videoAccess?: Maybe<EpisodeSegmentUpdatevideoAccessInput>;
  audioAccess?: Maybe<EpisodeSegmentUpdateaudioAccessInput>;
  accessLevelScheduleAt?: Maybe<Scalars['DateTime']>;
  scheduleVideoAccess?: Maybe<EpisodeSegmentUpdatescheduleVideoAccessInput>;
  scheduleAudioAccess?: Maybe<EpisodeSegmentUpdatescheduleAudioAccessInput>;
  uploadId?: Maybe<Scalars['String']>;
  muxPlaybackId?: Maybe<Scalars['String']>;
  playbackPolicy?: Maybe<Scalars['String']>;
  audio?: Maybe<Scalars['String']>;
  video?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
};

export type EpisodeSegmentUpdateManyDataInput = {
  title?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  simulateLive?: Maybe<Scalars['Boolean']>;
  liveVideoURL?: Maybe<Scalars['String']>;
  videoState?: Maybe<Scalars['String']>;
  audioState?: Maybe<Scalars['String']>;
  muxAssetId?: Maybe<Scalars['String']>;
  streamKey?: Maybe<Scalars['String']>;
  liveChatAccess?: Maybe<EpisodeSegmentUpdateliveChatAccessInput>;
  videoAccess?: Maybe<EpisodeSegmentUpdatevideoAccessInput>;
  audioAccess?: Maybe<EpisodeSegmentUpdateaudioAccessInput>;
  accessLevelScheduleAt?: Maybe<Scalars['DateTime']>;
  scheduleVideoAccess?: Maybe<EpisodeSegmentUpdatescheduleVideoAccessInput>;
  scheduleAudioAccess?: Maybe<EpisodeSegmentUpdatescheduleAudioAccessInput>;
  uploadId?: Maybe<Scalars['String']>;
  muxPlaybackId?: Maybe<Scalars['String']>;
  playbackPolicy?: Maybe<Scalars['String']>;
  audio?: Maybe<Scalars['String']>;
  video?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
};

export type EpisodeSegmentUpdateManyMutationInput = {
  title?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  simulateLive?: Maybe<Scalars['Boolean']>;
  liveVideoURL?: Maybe<Scalars['String']>;
  videoState?: Maybe<Scalars['String']>;
  audioState?: Maybe<Scalars['String']>;
  muxAssetId?: Maybe<Scalars['String']>;
  streamKey?: Maybe<Scalars['String']>;
  liveChatAccess?: Maybe<EpisodeSegmentUpdateliveChatAccessInput>;
  videoAccess?: Maybe<EpisodeSegmentUpdatevideoAccessInput>;
  audioAccess?: Maybe<EpisodeSegmentUpdateaudioAccessInput>;
  accessLevelScheduleAt?: Maybe<Scalars['DateTime']>;
  scheduleVideoAccess?: Maybe<EpisodeSegmentUpdatescheduleVideoAccessInput>;
  scheduleAudioAccess?: Maybe<EpisodeSegmentUpdatescheduleAudioAccessInput>;
  uploadId?: Maybe<Scalars['String']>;
  muxPlaybackId?: Maybe<Scalars['String']>;
  playbackPolicy?: Maybe<Scalars['String']>;
  audio?: Maybe<Scalars['String']>;
  video?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
};

export type EpisodeSegmentUpdateManyWithWhereNestedInput = {
  where: EpisodeSegmentScalarWhereInput;
  data: EpisodeSegmentUpdateManyDataInput;
};

export type EpisodeSegmentUpdateManyWithoutEpisodeInput = {
  create?: Maybe<Array<EpisodeSegmentCreateWithoutEpisodeInput>>;
  delete?: Maybe<Array<EpisodeSegmentWhereUniqueInput>>;
  connect?: Maybe<Array<EpisodeSegmentWhereUniqueInput>>;
  set?: Maybe<Array<EpisodeSegmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<EpisodeSegmentWhereUniqueInput>>;
  update?: Maybe<Array<EpisodeSegmentUpdateWithWhereUniqueWithoutEpisodeInput>>;
  upsert?: Maybe<Array<EpisodeSegmentUpsertWithWhereUniqueWithoutEpisodeInput>>;
  deleteMany?: Maybe<Array<EpisodeSegmentScalarWhereInput>>;
  updateMany?: Maybe<Array<EpisodeSegmentUpdateManyWithWhereNestedInput>>;
};

export type EpisodeSegmentUpdateOneRequiredInput = {
  create?: Maybe<EpisodeSegmentCreateInput>;
  update?: Maybe<EpisodeSegmentUpdateDataInput>;
  upsert?: Maybe<EpisodeSegmentUpsertNestedInput>;
  connect?: Maybe<EpisodeSegmentWhereUniqueInput>;
};

export type EpisodeSegmentUpdateWithWhereUniqueWithoutEpisodeInput = {
  where: EpisodeSegmentWhereUniqueInput;
  data: EpisodeSegmentUpdateWithoutEpisodeDataInput;
};

export type EpisodeSegmentUpdateWithoutEpisodeDataInput = {
  title?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  simulateLive?: Maybe<Scalars['Boolean']>;
  liveVideoURL?: Maybe<Scalars['String']>;
  videoState?: Maybe<Scalars['String']>;
  audioState?: Maybe<Scalars['String']>;
  muxAssetId?: Maybe<Scalars['String']>;
  streamKey?: Maybe<Scalars['String']>;
  captions?: Maybe<CaptionUpdateManyInput>;
  liveChatAccess?: Maybe<EpisodeSegmentUpdateliveChatAccessInput>;
  videoAccess?: Maybe<EpisodeSegmentUpdatevideoAccessInput>;
  audioAccess?: Maybe<EpisodeSegmentUpdateaudioAccessInput>;
  accessLevelScheduleAt?: Maybe<Scalars['DateTime']>;
  scheduleVideoAccess?: Maybe<EpisodeSegmentUpdatescheduleVideoAccessInput>;
  scheduleAudioAccess?: Maybe<EpisodeSegmentUpdatescheduleAudioAccessInput>;
  uploadId?: Maybe<Scalars['String']>;
  muxPlaybackId?: Maybe<Scalars['String']>;
  playbackPolicy?: Maybe<Scalars['String']>;
  audio?: Maybe<Scalars['String']>;
  video?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
};

export type EpisodeSegmentUpdateaudioAccessInput = {
  set?: Maybe<Array<Scalars['String']>>;
};

export type EpisodeSegmentUpdateliveChatAccessInput = {
  set?: Maybe<Array<Scalars['String']>>;
};

export type EpisodeSegmentUpdatescheduleAudioAccessInput = {
  set?: Maybe<Array<Scalars['String']>>;
};

export type EpisodeSegmentUpdatescheduleVideoAccessInput = {
  set?: Maybe<Array<Scalars['String']>>;
};

export type EpisodeSegmentUpdatevideoAccessInput = {
  set?: Maybe<Array<Scalars['String']>>;
};

export type EpisodeSegmentUpsertNestedInput = {
  update: EpisodeSegmentUpdateDataInput;
  create: EpisodeSegmentCreateInput;
};

export type EpisodeSegmentUpsertWithWhereUniqueWithoutEpisodeInput = {
  where: EpisodeSegmentWhereUniqueInput;
  update: EpisodeSegmentUpdateWithoutEpisodeDataInput;
  create: EpisodeSegmentCreateWithoutEpisodeInput;
};

export type EpisodeSegmentWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Scalars['String']>>;
  title_not_in?: Maybe<Array<Scalars['String']>>;
  title_lt?: Maybe<Scalars['String']>;
  title_lte?: Maybe<Scalars['String']>;
  title_gt?: Maybe<Scalars['String']>;
  title_gte?: Maybe<Scalars['String']>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  title_starts_with?: Maybe<Scalars['String']>;
  title_not_starts_with?: Maybe<Scalars['String']>;
  title_ends_with?: Maybe<Scalars['String']>;
  title_not_ends_with?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  subtitle_not?: Maybe<Scalars['String']>;
  subtitle_in?: Maybe<Array<Scalars['String']>>;
  subtitle_not_in?: Maybe<Array<Scalars['String']>>;
  subtitle_lt?: Maybe<Scalars['String']>;
  subtitle_lte?: Maybe<Scalars['String']>;
  subtitle_gt?: Maybe<Scalars['String']>;
  subtitle_gte?: Maybe<Scalars['String']>;
  subtitle_contains?: Maybe<Scalars['String']>;
  subtitle_not_contains?: Maybe<Scalars['String']>;
  subtitle_starts_with?: Maybe<Scalars['String']>;
  subtitle_not_starts_with?: Maybe<Scalars['String']>;
  subtitle_ends_with?: Maybe<Scalars['String']>;
  subtitle_not_ends_with?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  description_not?: Maybe<Scalars['String']>;
  description_in?: Maybe<Array<Scalars['String']>>;
  description_not_in?: Maybe<Array<Scalars['String']>>;
  description_lt?: Maybe<Scalars['String']>;
  description_lte?: Maybe<Scalars['String']>;
  description_gt?: Maybe<Scalars['String']>;
  description_gte?: Maybe<Scalars['String']>;
  description_contains?: Maybe<Scalars['String']>;
  description_not_contains?: Maybe<Scalars['String']>;
  description_starts_with?: Maybe<Scalars['String']>;
  description_not_starts_with?: Maybe<Scalars['String']>;
  description_ends_with?: Maybe<Scalars['String']>;
  description_not_ends_with?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  image_not?: Maybe<Scalars['String']>;
  image_in?: Maybe<Array<Scalars['String']>>;
  image_not_in?: Maybe<Array<Scalars['String']>>;
  image_lt?: Maybe<Scalars['String']>;
  image_lte?: Maybe<Scalars['String']>;
  image_gt?: Maybe<Scalars['String']>;
  image_gte?: Maybe<Scalars['String']>;
  image_contains?: Maybe<Scalars['String']>;
  image_not_contains?: Maybe<Scalars['String']>;
  image_starts_with?: Maybe<Scalars['String']>;
  image_not_starts_with?: Maybe<Scalars['String']>;
  image_ends_with?: Maybe<Scalars['String']>;
  image_not_ends_with?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  thumbnail_not?: Maybe<Scalars['String']>;
  thumbnail_in?: Maybe<Array<Scalars['String']>>;
  thumbnail_not_in?: Maybe<Array<Scalars['String']>>;
  thumbnail_lt?: Maybe<Scalars['String']>;
  thumbnail_lte?: Maybe<Scalars['String']>;
  thumbnail_gt?: Maybe<Scalars['String']>;
  thumbnail_gte?: Maybe<Scalars['String']>;
  thumbnail_contains?: Maybe<Scalars['String']>;
  thumbnail_not_contains?: Maybe<Scalars['String']>;
  thumbnail_starts_with?: Maybe<Scalars['String']>;
  thumbnail_not_starts_with?: Maybe<Scalars['String']>;
  thumbnail_ends_with?: Maybe<Scalars['String']>;
  thumbnail_not_ends_with?: Maybe<Scalars['String']>;
  episode?: Maybe<EpisodeWhereInput>;
  order?: Maybe<Scalars['Int']>;
  order_not?: Maybe<Scalars['Int']>;
  order_in?: Maybe<Array<Scalars['Int']>>;
  order_not_in?: Maybe<Array<Scalars['Int']>>;
  order_lt?: Maybe<Scalars['Int']>;
  order_lte?: Maybe<Scalars['Int']>;
  order_gt?: Maybe<Scalars['Int']>;
  order_gte?: Maybe<Scalars['Int']>;
  simulateLive?: Maybe<Scalars['Boolean']>;
  simulateLive_not?: Maybe<Scalars['Boolean']>;
  liveVideoURL?: Maybe<Scalars['String']>;
  liveVideoURL_not?: Maybe<Scalars['String']>;
  liveVideoURL_in?: Maybe<Array<Scalars['String']>>;
  liveVideoURL_not_in?: Maybe<Array<Scalars['String']>>;
  liveVideoURL_lt?: Maybe<Scalars['String']>;
  liveVideoURL_lte?: Maybe<Scalars['String']>;
  liveVideoURL_gt?: Maybe<Scalars['String']>;
  liveVideoURL_gte?: Maybe<Scalars['String']>;
  liveVideoURL_contains?: Maybe<Scalars['String']>;
  liveVideoURL_not_contains?: Maybe<Scalars['String']>;
  liveVideoURL_starts_with?: Maybe<Scalars['String']>;
  liveVideoURL_not_starts_with?: Maybe<Scalars['String']>;
  liveVideoURL_ends_with?: Maybe<Scalars['String']>;
  liveVideoURL_not_ends_with?: Maybe<Scalars['String']>;
  videoState?: Maybe<Scalars['String']>;
  videoState_not?: Maybe<Scalars['String']>;
  videoState_in?: Maybe<Array<Scalars['String']>>;
  videoState_not_in?: Maybe<Array<Scalars['String']>>;
  videoState_lt?: Maybe<Scalars['String']>;
  videoState_lte?: Maybe<Scalars['String']>;
  videoState_gt?: Maybe<Scalars['String']>;
  videoState_gte?: Maybe<Scalars['String']>;
  videoState_contains?: Maybe<Scalars['String']>;
  videoState_not_contains?: Maybe<Scalars['String']>;
  videoState_starts_with?: Maybe<Scalars['String']>;
  videoState_not_starts_with?: Maybe<Scalars['String']>;
  videoState_ends_with?: Maybe<Scalars['String']>;
  videoState_not_ends_with?: Maybe<Scalars['String']>;
  audioState?: Maybe<Scalars['String']>;
  audioState_not?: Maybe<Scalars['String']>;
  audioState_in?: Maybe<Array<Scalars['String']>>;
  audioState_not_in?: Maybe<Array<Scalars['String']>>;
  audioState_lt?: Maybe<Scalars['String']>;
  audioState_lte?: Maybe<Scalars['String']>;
  audioState_gt?: Maybe<Scalars['String']>;
  audioState_gte?: Maybe<Scalars['String']>;
  audioState_contains?: Maybe<Scalars['String']>;
  audioState_not_contains?: Maybe<Scalars['String']>;
  audioState_starts_with?: Maybe<Scalars['String']>;
  audioState_not_starts_with?: Maybe<Scalars['String']>;
  audioState_ends_with?: Maybe<Scalars['String']>;
  audioState_not_ends_with?: Maybe<Scalars['String']>;
  muxAssetId?: Maybe<Scalars['String']>;
  muxAssetId_not?: Maybe<Scalars['String']>;
  muxAssetId_in?: Maybe<Array<Scalars['String']>>;
  muxAssetId_not_in?: Maybe<Array<Scalars['String']>>;
  muxAssetId_lt?: Maybe<Scalars['String']>;
  muxAssetId_lte?: Maybe<Scalars['String']>;
  muxAssetId_gt?: Maybe<Scalars['String']>;
  muxAssetId_gte?: Maybe<Scalars['String']>;
  muxAssetId_contains?: Maybe<Scalars['String']>;
  muxAssetId_not_contains?: Maybe<Scalars['String']>;
  muxAssetId_starts_with?: Maybe<Scalars['String']>;
  muxAssetId_not_starts_with?: Maybe<Scalars['String']>;
  muxAssetId_ends_with?: Maybe<Scalars['String']>;
  muxAssetId_not_ends_with?: Maybe<Scalars['String']>;
  streamKey?: Maybe<Scalars['String']>;
  streamKey_not?: Maybe<Scalars['String']>;
  streamKey_in?: Maybe<Array<Scalars['String']>>;
  streamKey_not_in?: Maybe<Array<Scalars['String']>>;
  streamKey_lt?: Maybe<Scalars['String']>;
  streamKey_lte?: Maybe<Scalars['String']>;
  streamKey_gt?: Maybe<Scalars['String']>;
  streamKey_gte?: Maybe<Scalars['String']>;
  streamKey_contains?: Maybe<Scalars['String']>;
  streamKey_not_contains?: Maybe<Scalars['String']>;
  streamKey_starts_with?: Maybe<Scalars['String']>;
  streamKey_not_starts_with?: Maybe<Scalars['String']>;
  streamKey_ends_with?: Maybe<Scalars['String']>;
  streamKey_not_ends_with?: Maybe<Scalars['String']>;
  captions_every?: Maybe<CaptionWhereInput>;
  captions_some?: Maybe<CaptionWhereInput>;
  captions_none?: Maybe<CaptionWhereInput>;
  accessLevelScheduleAt?: Maybe<Scalars['DateTime']>;
  accessLevelScheduleAt_not?: Maybe<Scalars['DateTime']>;
  accessLevelScheduleAt_in?: Maybe<Array<Scalars['DateTime']>>;
  accessLevelScheduleAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  accessLevelScheduleAt_lt?: Maybe<Scalars['DateTime']>;
  accessLevelScheduleAt_lte?: Maybe<Scalars['DateTime']>;
  accessLevelScheduleAt_gt?: Maybe<Scalars['DateTime']>;
  accessLevelScheduleAt_gte?: Maybe<Scalars['DateTime']>;
  uploadId?: Maybe<Scalars['String']>;
  uploadId_not?: Maybe<Scalars['String']>;
  uploadId_in?: Maybe<Array<Scalars['String']>>;
  uploadId_not_in?: Maybe<Array<Scalars['String']>>;
  uploadId_lt?: Maybe<Scalars['String']>;
  uploadId_lte?: Maybe<Scalars['String']>;
  uploadId_gt?: Maybe<Scalars['String']>;
  uploadId_gte?: Maybe<Scalars['String']>;
  uploadId_contains?: Maybe<Scalars['String']>;
  uploadId_not_contains?: Maybe<Scalars['String']>;
  uploadId_starts_with?: Maybe<Scalars['String']>;
  uploadId_not_starts_with?: Maybe<Scalars['String']>;
  uploadId_ends_with?: Maybe<Scalars['String']>;
  uploadId_not_ends_with?: Maybe<Scalars['String']>;
  muxPlaybackId?: Maybe<Scalars['String']>;
  muxPlaybackId_not?: Maybe<Scalars['String']>;
  muxPlaybackId_in?: Maybe<Array<Scalars['String']>>;
  muxPlaybackId_not_in?: Maybe<Array<Scalars['String']>>;
  muxPlaybackId_lt?: Maybe<Scalars['String']>;
  muxPlaybackId_lte?: Maybe<Scalars['String']>;
  muxPlaybackId_gt?: Maybe<Scalars['String']>;
  muxPlaybackId_gte?: Maybe<Scalars['String']>;
  muxPlaybackId_contains?: Maybe<Scalars['String']>;
  muxPlaybackId_not_contains?: Maybe<Scalars['String']>;
  muxPlaybackId_starts_with?: Maybe<Scalars['String']>;
  muxPlaybackId_not_starts_with?: Maybe<Scalars['String']>;
  muxPlaybackId_ends_with?: Maybe<Scalars['String']>;
  muxPlaybackId_not_ends_with?: Maybe<Scalars['String']>;
  playbackPolicy?: Maybe<Scalars['String']>;
  playbackPolicy_not?: Maybe<Scalars['String']>;
  playbackPolicy_in?: Maybe<Array<Scalars['String']>>;
  playbackPolicy_not_in?: Maybe<Array<Scalars['String']>>;
  playbackPolicy_lt?: Maybe<Scalars['String']>;
  playbackPolicy_lte?: Maybe<Scalars['String']>;
  playbackPolicy_gt?: Maybe<Scalars['String']>;
  playbackPolicy_gte?: Maybe<Scalars['String']>;
  playbackPolicy_contains?: Maybe<Scalars['String']>;
  playbackPolicy_not_contains?: Maybe<Scalars['String']>;
  playbackPolicy_starts_with?: Maybe<Scalars['String']>;
  playbackPolicy_not_starts_with?: Maybe<Scalars['String']>;
  playbackPolicy_ends_with?: Maybe<Scalars['String']>;
  playbackPolicy_not_ends_with?: Maybe<Scalars['String']>;
  audio?: Maybe<Scalars['String']>;
  audio_not?: Maybe<Scalars['String']>;
  audio_in?: Maybe<Array<Scalars['String']>>;
  audio_not_in?: Maybe<Array<Scalars['String']>>;
  audio_lt?: Maybe<Scalars['String']>;
  audio_lte?: Maybe<Scalars['String']>;
  audio_gt?: Maybe<Scalars['String']>;
  audio_gte?: Maybe<Scalars['String']>;
  audio_contains?: Maybe<Scalars['String']>;
  audio_not_contains?: Maybe<Scalars['String']>;
  audio_starts_with?: Maybe<Scalars['String']>;
  audio_not_starts_with?: Maybe<Scalars['String']>;
  audio_ends_with?: Maybe<Scalars['String']>;
  audio_not_ends_with?: Maybe<Scalars['String']>;
  video?: Maybe<Scalars['String']>;
  video_not?: Maybe<Scalars['String']>;
  video_in?: Maybe<Array<Scalars['String']>>;
  video_not_in?: Maybe<Array<Scalars['String']>>;
  video_lt?: Maybe<Scalars['String']>;
  video_lte?: Maybe<Scalars['String']>;
  video_gt?: Maybe<Scalars['String']>;
  video_gte?: Maybe<Scalars['String']>;
  video_contains?: Maybe<Scalars['String']>;
  video_not_contains?: Maybe<Scalars['String']>;
  video_starts_with?: Maybe<Scalars['String']>;
  video_not_starts_with?: Maybe<Scalars['String']>;
  video_ends_with?: Maybe<Scalars['String']>;
  video_not_ends_with?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
  duration_not?: Maybe<Scalars['Float']>;
  duration_in?: Maybe<Array<Scalars['Float']>>;
  duration_not_in?: Maybe<Array<Scalars['Float']>>;
  duration_lt?: Maybe<Scalars['Float']>;
  duration_lte?: Maybe<Scalars['Float']>;
  duration_gt?: Maybe<Scalars['Float']>;
  duration_gte?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<EpisodeSegmentWhereInput>>;
  OR?: Maybe<Array<EpisodeSegmentWhereInput>>;
  NOT?: Maybe<Array<EpisodeSegmentWhereInput>>;
};

export type EpisodeSegmentWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type EpisodeSignedUrlDataInput = {
  episodeId: Scalars['String'];
  fileName: Scalars['String'];
  contentType: Scalars['String'];
};

export enum EpisodeStatus {
  Draft = 'DRAFT',
  Scheduled = 'SCHEDULED',
  Live = 'LIVE',
  LiveFinished = 'LIVE_FINISHED',
  Published = 'PUBLISHED',
  Unpublished = 'UNPUBLISHED'
}

export type EpisodeSubscriptionPayload = {
  __typename?: 'EpisodeSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<Episode>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<EpisodePreviousValues>;
};

export type EpisodeSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<EpisodeWhereInput>;
  AND?: Maybe<Array<EpisodeSubscriptionWhereInput>>;
  OR?: Maybe<Array<EpisodeSubscriptionWhereInput>>;
  NOT?: Maybe<Array<EpisodeSubscriptionWhereInput>>;
};

export type EpisodeUpdateDataInput = {
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  episodeNumber?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  show?: Maybe<ShowUpdateOneRequiredWithoutEpisodesInput>;
  seasonEpisode?: Maybe<SeasonEpisodeUpdateManyWithoutEpisodeInput>;
  isLive?: Maybe<Scalars['Boolean']>;
  thirdPartyId?: Maybe<Scalars['String']>;
  isFeatured?: Maybe<Scalars['Boolean']>;
  featuredBannerImage?: Maybe<Scalars['String']>;
  featureBannerWeight?: Maybe<Scalars['Int']>;
  status?: Maybe<EpisodeStatus>;
  algoliaId?: Maybe<Scalars['String']>;
  discussionId?: Maybe<Scalars['String']>;
  segments?: Maybe<EpisodeSegmentUpdateManyWithoutEpisodeInput>;
  rating?: Maybe<Scalars['String']>;
  allowedContinents?: Maybe<EpisodeUpdateallowedContinentsInput>;
  allowedCountries?: Maybe<Scalars['Json']>;
  createdBy?: Maybe<UserUpdateOneRequiredInput>;
  scheduleAt?: Maybe<Scalars['DateTime']>;
};

export type EpisodeUpdateInput = {
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  episodeNumber?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  show?: Maybe<ShowUpdateOneRequiredWithoutEpisodesInput>;
  seasonEpisode?: Maybe<SeasonEpisodeUpdateManyWithoutEpisodeInput>;
  isLive?: Maybe<Scalars['Boolean']>;
  thirdPartyId?: Maybe<Scalars['String']>;
  isFeatured?: Maybe<Scalars['Boolean']>;
  featuredBannerImage?: Maybe<Scalars['String']>;
  featureBannerWeight?: Maybe<Scalars['Int']>;
  status?: Maybe<EpisodeStatus>;
  algoliaId?: Maybe<Scalars['String']>;
  discussionId?: Maybe<Scalars['String']>;
  segments?: Maybe<EpisodeSegmentUpdateManyWithoutEpisodeInput>;
  rating?: Maybe<Scalars['String']>;
  allowedContinents?: Maybe<EpisodeUpdateallowedContinentsInput>;
  allowedCountries?: Maybe<Scalars['Json']>;
  createdBy?: Maybe<UserUpdateOneRequiredInput>;
  scheduleAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type EpisodeUpdateManyDataInput = {
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  episodeNumber?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  isLive?: Maybe<Scalars['Boolean']>;
  thirdPartyId?: Maybe<Scalars['String']>;
  isFeatured?: Maybe<Scalars['Boolean']>;
  featuredBannerImage?: Maybe<Scalars['String']>;
  featureBannerWeight?: Maybe<Scalars['Int']>;
  status?: Maybe<EpisodeStatus>;
  algoliaId?: Maybe<Scalars['String']>;
  discussionId?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['String']>;
  allowedContinents?: Maybe<EpisodeUpdateallowedContinentsInput>;
  allowedCountries?: Maybe<Scalars['Json']>;
  scheduleAt?: Maybe<Scalars['DateTime']>;
};

export type EpisodeUpdateManyMutationInput = {
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  episodeNumber?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  isLive?: Maybe<Scalars['Boolean']>;
  thirdPartyId?: Maybe<Scalars['String']>;
  isFeatured?: Maybe<Scalars['Boolean']>;
  featuredBannerImage?: Maybe<Scalars['String']>;
  featureBannerWeight?: Maybe<Scalars['Int']>;
  status?: Maybe<EpisodeStatus>;
  algoliaId?: Maybe<Scalars['String']>;
  discussionId?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['String']>;
  allowedContinents?: Maybe<EpisodeUpdateallowedContinentsInput>;
  allowedCountries?: Maybe<Scalars['Json']>;
  scheduleAt?: Maybe<Scalars['DateTime']>;
};

export type EpisodeUpdateManyWithWhereNestedInput = {
  where: EpisodeScalarWhereInput;
  data: EpisodeUpdateManyDataInput;
};

export type EpisodeUpdateManyWithoutShowInput = {
  create?: Maybe<Array<EpisodeCreateWithoutShowInput>>;
  delete?: Maybe<Array<EpisodeWhereUniqueInput>>;
  connect?: Maybe<Array<EpisodeWhereUniqueInput>>;
  set?: Maybe<Array<EpisodeWhereUniqueInput>>;
  disconnect?: Maybe<Array<EpisodeWhereUniqueInput>>;
  update?: Maybe<Array<EpisodeUpdateWithWhereUniqueWithoutShowInput>>;
  upsert?: Maybe<Array<EpisodeUpsertWithWhereUniqueWithoutShowInput>>;
  deleteMany?: Maybe<Array<EpisodeScalarWhereInput>>;
  updateMany?: Maybe<Array<EpisodeUpdateManyWithWhereNestedInput>>;
};

export type EpisodeUpdateOneInput = {
  create?: Maybe<EpisodeCreateInput>;
  update?: Maybe<EpisodeUpdateDataInput>;
  upsert?: Maybe<EpisodeUpsertNestedInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<EpisodeWhereUniqueInput>;
};

export type EpisodeUpdateOneRequiredWithoutSeasonEpisodeInput = {
  create?: Maybe<EpisodeCreateWithoutSeasonEpisodeInput>;
  update?: Maybe<EpisodeUpdateWithoutSeasonEpisodeDataInput>;
  upsert?: Maybe<EpisodeUpsertWithoutSeasonEpisodeInput>;
  connect?: Maybe<EpisodeWhereUniqueInput>;
};

export type EpisodeUpdateOneRequiredWithoutSegmentsInput = {
  create?: Maybe<EpisodeCreateWithoutSegmentsInput>;
  update?: Maybe<EpisodeUpdateWithoutSegmentsDataInput>;
  upsert?: Maybe<EpisodeUpsertWithoutSegmentsInput>;
  connect?: Maybe<EpisodeWhereUniqueInput>;
};

export type EpisodeUpdateWithWhereUniqueWithoutShowInput = {
  where: EpisodeWhereUniqueInput;
  data: EpisodeUpdateWithoutShowDataInput;
};

export type EpisodeUpdateWithoutSeasonEpisodeDataInput = {
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  episodeNumber?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  show?: Maybe<ShowUpdateOneRequiredWithoutEpisodesInput>;
  isLive?: Maybe<Scalars['Boolean']>;
  thirdPartyId?: Maybe<Scalars['String']>;
  isFeatured?: Maybe<Scalars['Boolean']>;
  featuredBannerImage?: Maybe<Scalars['String']>;
  featureBannerWeight?: Maybe<Scalars['Int']>;
  status?: Maybe<EpisodeStatus>;
  algoliaId?: Maybe<Scalars['String']>;
  discussionId?: Maybe<Scalars['String']>;
  segments?: Maybe<EpisodeSegmentUpdateManyWithoutEpisodeInput>;
  rating?: Maybe<Scalars['String']>;
  allowedContinents?: Maybe<EpisodeUpdateallowedContinentsInput>;
  allowedCountries?: Maybe<Scalars['Json']>;
  createdBy?: Maybe<UserUpdateOneRequiredInput>;
  scheduleAt?: Maybe<Scalars['DateTime']>;
};

export type EpisodeUpdateWithoutSegmentsDataInput = {
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  episodeNumber?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  show?: Maybe<ShowUpdateOneRequiredWithoutEpisodesInput>;
  seasonEpisode?: Maybe<SeasonEpisodeUpdateManyWithoutEpisodeInput>;
  isLive?: Maybe<Scalars['Boolean']>;
  thirdPartyId?: Maybe<Scalars['String']>;
  isFeatured?: Maybe<Scalars['Boolean']>;
  featuredBannerImage?: Maybe<Scalars['String']>;
  featureBannerWeight?: Maybe<Scalars['Int']>;
  status?: Maybe<EpisodeStatus>;
  algoliaId?: Maybe<Scalars['String']>;
  discussionId?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['String']>;
  allowedContinents?: Maybe<EpisodeUpdateallowedContinentsInput>;
  allowedCountries?: Maybe<Scalars['Json']>;
  createdBy?: Maybe<UserUpdateOneRequiredInput>;
  scheduleAt?: Maybe<Scalars['DateTime']>;
};

export type EpisodeUpdateWithoutShowDataInput = {
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  episodeNumber?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  seasonEpisode?: Maybe<SeasonEpisodeUpdateManyWithoutEpisodeInput>;
  isLive?: Maybe<Scalars['Boolean']>;
  thirdPartyId?: Maybe<Scalars['String']>;
  isFeatured?: Maybe<Scalars['Boolean']>;
  featuredBannerImage?: Maybe<Scalars['String']>;
  featureBannerWeight?: Maybe<Scalars['Int']>;
  status?: Maybe<EpisodeStatus>;
  algoliaId?: Maybe<Scalars['String']>;
  discussionId?: Maybe<Scalars['String']>;
  segments?: Maybe<EpisodeSegmentUpdateManyWithoutEpisodeInput>;
  rating?: Maybe<Scalars['String']>;
  allowedContinents?: Maybe<EpisodeUpdateallowedContinentsInput>;
  allowedCountries?: Maybe<Scalars['Json']>;
  createdBy?: Maybe<UserUpdateOneRequiredInput>;
  scheduleAt?: Maybe<Scalars['DateTime']>;
};

export type EpisodeUpdateallowedContinentsInput = {
  set?: Maybe<Array<Scalars['String']>>;
};

export type EpisodeUpsertNestedInput = {
  update: EpisodeUpdateDataInput;
  create: EpisodeCreateInput;
};

export type EpisodeUpsertWithWhereUniqueWithoutShowInput = {
  where: EpisodeWhereUniqueInput;
  update: EpisodeUpdateWithoutShowDataInput;
  create: EpisodeCreateWithoutShowInput;
};

export type EpisodeUpsertWithoutSeasonEpisodeInput = {
  update: EpisodeUpdateWithoutSeasonEpisodeDataInput;
  create: EpisodeCreateWithoutSeasonEpisodeInput;
};

export type EpisodeUpsertWithoutSegmentsInput = {
  update: EpisodeUpdateWithoutSegmentsDataInput;
  create: EpisodeCreateWithoutSegmentsInput;
};

export type EpisodeWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Scalars['String']>>;
  title_not_in?: Maybe<Array<Scalars['String']>>;
  title_lt?: Maybe<Scalars['String']>;
  title_lte?: Maybe<Scalars['String']>;
  title_gt?: Maybe<Scalars['String']>;
  title_gte?: Maybe<Scalars['String']>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  title_starts_with?: Maybe<Scalars['String']>;
  title_not_starts_with?: Maybe<Scalars['String']>;
  title_ends_with?: Maybe<Scalars['String']>;
  title_not_ends_with?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  description_not?: Maybe<Scalars['String']>;
  description_in?: Maybe<Array<Scalars['String']>>;
  description_not_in?: Maybe<Array<Scalars['String']>>;
  description_lt?: Maybe<Scalars['String']>;
  description_lte?: Maybe<Scalars['String']>;
  description_gt?: Maybe<Scalars['String']>;
  description_gte?: Maybe<Scalars['String']>;
  description_contains?: Maybe<Scalars['String']>;
  description_not_contains?: Maybe<Scalars['String']>;
  description_starts_with?: Maybe<Scalars['String']>;
  description_not_starts_with?: Maybe<Scalars['String']>;
  description_ends_with?: Maybe<Scalars['String']>;
  description_not_ends_with?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  slug_not?: Maybe<Scalars['String']>;
  slug_in?: Maybe<Array<Scalars['String']>>;
  slug_not_in?: Maybe<Array<Scalars['String']>>;
  slug_lt?: Maybe<Scalars['String']>;
  slug_lte?: Maybe<Scalars['String']>;
  slug_gt?: Maybe<Scalars['String']>;
  slug_gte?: Maybe<Scalars['String']>;
  slug_contains?: Maybe<Scalars['String']>;
  slug_not_contains?: Maybe<Scalars['String']>;
  slug_starts_with?: Maybe<Scalars['String']>;
  slug_not_starts_with?: Maybe<Scalars['String']>;
  slug_ends_with?: Maybe<Scalars['String']>;
  slug_not_ends_with?: Maybe<Scalars['String']>;
  episodeNumber?: Maybe<Scalars['String']>;
  episodeNumber_not?: Maybe<Scalars['String']>;
  episodeNumber_in?: Maybe<Array<Scalars['String']>>;
  episodeNumber_not_in?: Maybe<Array<Scalars['String']>>;
  episodeNumber_lt?: Maybe<Scalars['String']>;
  episodeNumber_lte?: Maybe<Scalars['String']>;
  episodeNumber_gt?: Maybe<Scalars['String']>;
  episodeNumber_gte?: Maybe<Scalars['String']>;
  episodeNumber_contains?: Maybe<Scalars['String']>;
  episodeNumber_not_contains?: Maybe<Scalars['String']>;
  episodeNumber_starts_with?: Maybe<Scalars['String']>;
  episodeNumber_not_starts_with?: Maybe<Scalars['String']>;
  episodeNumber_ends_with?: Maybe<Scalars['String']>;
  episodeNumber_not_ends_with?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  image_not?: Maybe<Scalars['String']>;
  image_in?: Maybe<Array<Scalars['String']>>;
  image_not_in?: Maybe<Array<Scalars['String']>>;
  image_lt?: Maybe<Scalars['String']>;
  image_lte?: Maybe<Scalars['String']>;
  image_gt?: Maybe<Scalars['String']>;
  image_gte?: Maybe<Scalars['String']>;
  image_contains?: Maybe<Scalars['String']>;
  image_not_contains?: Maybe<Scalars['String']>;
  image_starts_with?: Maybe<Scalars['String']>;
  image_not_starts_with?: Maybe<Scalars['String']>;
  image_ends_with?: Maybe<Scalars['String']>;
  image_not_ends_with?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  thumbnail_not?: Maybe<Scalars['String']>;
  thumbnail_in?: Maybe<Array<Scalars['String']>>;
  thumbnail_not_in?: Maybe<Array<Scalars['String']>>;
  thumbnail_lt?: Maybe<Scalars['String']>;
  thumbnail_lte?: Maybe<Scalars['String']>;
  thumbnail_gt?: Maybe<Scalars['String']>;
  thumbnail_gte?: Maybe<Scalars['String']>;
  thumbnail_contains?: Maybe<Scalars['String']>;
  thumbnail_not_contains?: Maybe<Scalars['String']>;
  thumbnail_starts_with?: Maybe<Scalars['String']>;
  thumbnail_not_starts_with?: Maybe<Scalars['String']>;
  thumbnail_ends_with?: Maybe<Scalars['String']>;
  thumbnail_not_ends_with?: Maybe<Scalars['String']>;
  show?: Maybe<ShowWhereInput>;
  seasonEpisode_every?: Maybe<SeasonEpisodeWhereInput>;
  seasonEpisode_some?: Maybe<SeasonEpisodeWhereInput>;
  seasonEpisode_none?: Maybe<SeasonEpisodeWhereInput>;
  isLive?: Maybe<Scalars['Boolean']>;
  isLive_not?: Maybe<Scalars['Boolean']>;
  thirdPartyId?: Maybe<Scalars['String']>;
  thirdPartyId_not?: Maybe<Scalars['String']>;
  thirdPartyId_in?: Maybe<Array<Scalars['String']>>;
  thirdPartyId_not_in?: Maybe<Array<Scalars['String']>>;
  thirdPartyId_lt?: Maybe<Scalars['String']>;
  thirdPartyId_lte?: Maybe<Scalars['String']>;
  thirdPartyId_gt?: Maybe<Scalars['String']>;
  thirdPartyId_gte?: Maybe<Scalars['String']>;
  thirdPartyId_contains?: Maybe<Scalars['String']>;
  thirdPartyId_not_contains?: Maybe<Scalars['String']>;
  thirdPartyId_starts_with?: Maybe<Scalars['String']>;
  thirdPartyId_not_starts_with?: Maybe<Scalars['String']>;
  thirdPartyId_ends_with?: Maybe<Scalars['String']>;
  thirdPartyId_not_ends_with?: Maybe<Scalars['String']>;
  isFeatured?: Maybe<Scalars['Boolean']>;
  isFeatured_not?: Maybe<Scalars['Boolean']>;
  featuredBannerImage?: Maybe<Scalars['String']>;
  featuredBannerImage_not?: Maybe<Scalars['String']>;
  featuredBannerImage_in?: Maybe<Array<Scalars['String']>>;
  featuredBannerImage_not_in?: Maybe<Array<Scalars['String']>>;
  featuredBannerImage_lt?: Maybe<Scalars['String']>;
  featuredBannerImage_lte?: Maybe<Scalars['String']>;
  featuredBannerImage_gt?: Maybe<Scalars['String']>;
  featuredBannerImage_gte?: Maybe<Scalars['String']>;
  featuredBannerImage_contains?: Maybe<Scalars['String']>;
  featuredBannerImage_not_contains?: Maybe<Scalars['String']>;
  featuredBannerImage_starts_with?: Maybe<Scalars['String']>;
  featuredBannerImage_not_starts_with?: Maybe<Scalars['String']>;
  featuredBannerImage_ends_with?: Maybe<Scalars['String']>;
  featuredBannerImage_not_ends_with?: Maybe<Scalars['String']>;
  featureBannerWeight?: Maybe<Scalars['Int']>;
  featureBannerWeight_not?: Maybe<Scalars['Int']>;
  featureBannerWeight_in?: Maybe<Array<Scalars['Int']>>;
  featureBannerWeight_not_in?: Maybe<Array<Scalars['Int']>>;
  featureBannerWeight_lt?: Maybe<Scalars['Int']>;
  featureBannerWeight_lte?: Maybe<Scalars['Int']>;
  featureBannerWeight_gt?: Maybe<Scalars['Int']>;
  featureBannerWeight_gte?: Maybe<Scalars['Int']>;
  status?: Maybe<EpisodeStatus>;
  status_not?: Maybe<EpisodeStatus>;
  status_in?: Maybe<Array<EpisodeStatus>>;
  status_not_in?: Maybe<Array<EpisodeStatus>>;
  algoliaId?: Maybe<Scalars['String']>;
  algoliaId_not?: Maybe<Scalars['String']>;
  algoliaId_in?: Maybe<Array<Scalars['String']>>;
  algoliaId_not_in?: Maybe<Array<Scalars['String']>>;
  algoliaId_lt?: Maybe<Scalars['String']>;
  algoliaId_lte?: Maybe<Scalars['String']>;
  algoliaId_gt?: Maybe<Scalars['String']>;
  algoliaId_gte?: Maybe<Scalars['String']>;
  algoliaId_contains?: Maybe<Scalars['String']>;
  algoliaId_not_contains?: Maybe<Scalars['String']>;
  algoliaId_starts_with?: Maybe<Scalars['String']>;
  algoliaId_not_starts_with?: Maybe<Scalars['String']>;
  algoliaId_ends_with?: Maybe<Scalars['String']>;
  algoliaId_not_ends_with?: Maybe<Scalars['String']>;
  discussionId?: Maybe<Scalars['String']>;
  discussionId_not?: Maybe<Scalars['String']>;
  discussionId_in?: Maybe<Array<Scalars['String']>>;
  discussionId_not_in?: Maybe<Array<Scalars['String']>>;
  discussionId_lt?: Maybe<Scalars['String']>;
  discussionId_lte?: Maybe<Scalars['String']>;
  discussionId_gt?: Maybe<Scalars['String']>;
  discussionId_gte?: Maybe<Scalars['String']>;
  discussionId_contains?: Maybe<Scalars['String']>;
  discussionId_not_contains?: Maybe<Scalars['String']>;
  discussionId_starts_with?: Maybe<Scalars['String']>;
  discussionId_not_starts_with?: Maybe<Scalars['String']>;
  discussionId_ends_with?: Maybe<Scalars['String']>;
  discussionId_not_ends_with?: Maybe<Scalars['String']>;
  segments_every?: Maybe<EpisodeSegmentWhereInput>;
  segments_some?: Maybe<EpisodeSegmentWhereInput>;
  segments_none?: Maybe<EpisodeSegmentWhereInput>;
  rating?: Maybe<Scalars['String']>;
  rating_not?: Maybe<Scalars['String']>;
  rating_in?: Maybe<Array<Scalars['String']>>;
  rating_not_in?: Maybe<Array<Scalars['String']>>;
  rating_lt?: Maybe<Scalars['String']>;
  rating_lte?: Maybe<Scalars['String']>;
  rating_gt?: Maybe<Scalars['String']>;
  rating_gte?: Maybe<Scalars['String']>;
  rating_contains?: Maybe<Scalars['String']>;
  rating_not_contains?: Maybe<Scalars['String']>;
  rating_starts_with?: Maybe<Scalars['String']>;
  rating_not_starts_with?: Maybe<Scalars['String']>;
  rating_ends_with?: Maybe<Scalars['String']>;
  rating_not_ends_with?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserWhereInput>;
  scheduleAt?: Maybe<Scalars['DateTime']>;
  scheduleAt_not?: Maybe<Scalars['DateTime']>;
  scheduleAt_in?: Maybe<Array<Scalars['DateTime']>>;
  scheduleAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  scheduleAt_lt?: Maybe<Scalars['DateTime']>;
  scheduleAt_lte?: Maybe<Scalars['DateTime']>;
  scheduleAt_gt?: Maybe<Scalars['DateTime']>;
  scheduleAt_gte?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<EpisodeWhereInput>>;
  OR?: Maybe<Array<EpisodeWhereInput>>;
  NOT?: Maybe<Array<EpisodeWhereInput>>;
  featuredFirst?: Maybe<Scalars['Boolean']>;
};

export type EpisodeWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
  slug?: Maybe<Scalars['String']>;
};

export type FreeformCarousel = {
  __typename?: 'FreeformCarousel';
  id?: Maybe<Scalars['ID']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  nextOrder?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  freeforms?: Maybe<Array<Maybe<FreeformHighlightModule>>>;
  layout?: Maybe<Scalars['String']>;
};

export type FreeformCarouselCustomInput = {
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  logoImage?: Maybe<Scalars['String']>;
  CTAText?: Maybe<Scalars['String']>;
  routing?: Maybe<FreeformHighlightModuleRouting>;
  link?: Maybe<Scalars['String']>;
  referenceId?: Maybe<Scalars['String']>;
  referenceType?: Maybe<FreeformReferenceType>;
  order?: Maybe<Scalars['Int']>;
};

export type FreeformCarouselModuleCustomInput = {
  title?: Maybe<Scalars['String']>;
  order: Scalars['Int'];
  isPublished?: Maybe<Scalars['Boolean']>;
  carouselFreeforms?: Maybe<Array<Maybe<FreeformCarouselCustomInput>>>;
};

export type FreeformCarouselModuleFreeforms = {
  __typename?: 'FreeformCarouselModuleFreeforms';
  id: Scalars['ID'];
  order?: Maybe<Scalars['Int']>;
  freeform?: Maybe<FreeformHighlightModule>;
  freeformCarousel?: Maybe<CarouselModule>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type FreeformCarouselModuleFreeformsConnection = {
  __typename?: 'FreeformCarouselModuleFreeformsConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<FreeformCarouselModuleFreeformsEdge>>;
  aggregate: AggregateFreeformCarouselModuleFreeforms;
};

export type FreeformCarouselModuleFreeformsCreateInput = {
  id?: Maybe<Scalars['ID']>;
  order?: Maybe<Scalars['Int']>;
  freeform?: Maybe<FreeformHighlightModuleCreateOneInput>;
  freeformCarousel?: Maybe<CarouselModuleCreateOneWithoutCarouselFreeformsInput>;
};

export type FreeformCarouselModuleFreeformsCreateManyWithoutFreeformCarouselInput = {
  create?: Maybe<Array<FreeformCarouselModuleFreeformsCreateWithoutFreeformCarouselInput>>;
  connect?: Maybe<Array<FreeformCarouselModuleFreeformsWhereUniqueInput>>;
};

export type FreeformCarouselModuleFreeformsCreateWithoutFreeformCarouselInput = {
  id?: Maybe<Scalars['ID']>;
  order?: Maybe<Scalars['Int']>;
  freeform?: Maybe<FreeformHighlightModuleCreateOneInput>;
};

export type FreeformCarouselModuleFreeformsEdge = {
  __typename?: 'FreeformCarouselModuleFreeformsEdge';
  node: FreeformCarouselModuleFreeforms;
  cursor: Scalars['String'];
};

export enum FreeformCarouselModuleFreeformsOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  OrderAsc = 'order_ASC',
  OrderDesc = 'order_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type FreeformCarouselModuleFreeformsPreviousValues = {
  __typename?: 'FreeformCarouselModuleFreeformsPreviousValues';
  id: Scalars['ID'];
  order?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type FreeformCarouselModuleFreeformsScalarWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  order?: Maybe<Scalars['Int']>;
  order_not?: Maybe<Scalars['Int']>;
  order_in?: Maybe<Array<Scalars['Int']>>;
  order_not_in?: Maybe<Array<Scalars['Int']>>;
  order_lt?: Maybe<Scalars['Int']>;
  order_lte?: Maybe<Scalars['Int']>;
  order_gt?: Maybe<Scalars['Int']>;
  order_gte?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<FreeformCarouselModuleFreeformsScalarWhereInput>>;
  OR?: Maybe<Array<FreeformCarouselModuleFreeformsScalarWhereInput>>;
  NOT?: Maybe<Array<FreeformCarouselModuleFreeformsScalarWhereInput>>;
};

export type FreeformCarouselModuleFreeformsSubscriptionPayload = {
  __typename?: 'FreeformCarouselModuleFreeformsSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<FreeformCarouselModuleFreeforms>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<FreeformCarouselModuleFreeformsPreviousValues>;
};

export type FreeformCarouselModuleFreeformsSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<FreeformCarouselModuleFreeformsWhereInput>;
  AND?: Maybe<Array<FreeformCarouselModuleFreeformsSubscriptionWhereInput>>;
  OR?: Maybe<Array<FreeformCarouselModuleFreeformsSubscriptionWhereInput>>;
  NOT?: Maybe<Array<FreeformCarouselModuleFreeformsSubscriptionWhereInput>>;
};

export type FreeformCarouselModuleFreeformsUpdateInput = {
  order?: Maybe<Scalars['Int']>;
  freeform?: Maybe<FreeformHighlightModuleUpdateOneInput>;
  freeformCarousel?: Maybe<CarouselModuleUpdateOneWithoutCarouselFreeformsInput>;
};

export type FreeformCarouselModuleFreeformsUpdateManyDataInput = {
  order?: Maybe<Scalars['Int']>;
};

export type FreeformCarouselModuleFreeformsUpdateManyMutationInput = {
  order?: Maybe<Scalars['Int']>;
};

export type FreeformCarouselModuleFreeformsUpdateManyWithWhereNestedInput = {
  where: FreeformCarouselModuleFreeformsScalarWhereInput;
  data: FreeformCarouselModuleFreeformsUpdateManyDataInput;
};

export type FreeformCarouselModuleFreeformsUpdateManyWithoutFreeformCarouselInput = {
  create?: Maybe<Array<FreeformCarouselModuleFreeformsCreateWithoutFreeformCarouselInput>>;
  delete?: Maybe<Array<FreeformCarouselModuleFreeformsWhereUniqueInput>>;
  connect?: Maybe<Array<FreeformCarouselModuleFreeformsWhereUniqueInput>>;
  set?: Maybe<Array<FreeformCarouselModuleFreeformsWhereUniqueInput>>;
  disconnect?: Maybe<Array<FreeformCarouselModuleFreeformsWhereUniqueInput>>;
  update?: Maybe<Array<FreeformCarouselModuleFreeformsUpdateWithWhereUniqueWithoutFreeformCarouselInput>>;
  upsert?: Maybe<Array<FreeformCarouselModuleFreeformsUpsertWithWhereUniqueWithoutFreeformCarouselInput>>;
  deleteMany?: Maybe<Array<FreeformCarouselModuleFreeformsScalarWhereInput>>;
  updateMany?: Maybe<Array<FreeformCarouselModuleFreeformsUpdateManyWithWhereNestedInput>>;
};

export type FreeformCarouselModuleFreeformsUpdateWithWhereUniqueWithoutFreeformCarouselInput = {
  where: FreeformCarouselModuleFreeformsWhereUniqueInput;
  data: FreeformCarouselModuleFreeformsUpdateWithoutFreeformCarouselDataInput;
};

export type FreeformCarouselModuleFreeformsUpdateWithoutFreeformCarouselDataInput = {
  order?: Maybe<Scalars['Int']>;
  freeform?: Maybe<FreeformHighlightModuleUpdateOneInput>;
};

export type FreeformCarouselModuleFreeformsUpsertWithWhereUniqueWithoutFreeformCarouselInput = {
  where: FreeformCarouselModuleFreeformsWhereUniqueInput;
  update: FreeformCarouselModuleFreeformsUpdateWithoutFreeformCarouselDataInput;
  create: FreeformCarouselModuleFreeformsCreateWithoutFreeformCarouselInput;
};

export type FreeformCarouselModuleFreeformsWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  order?: Maybe<Scalars['Int']>;
  order_not?: Maybe<Scalars['Int']>;
  order_in?: Maybe<Array<Scalars['Int']>>;
  order_not_in?: Maybe<Array<Scalars['Int']>>;
  order_lt?: Maybe<Scalars['Int']>;
  order_lte?: Maybe<Scalars['Int']>;
  order_gt?: Maybe<Scalars['Int']>;
  order_gte?: Maybe<Scalars['Int']>;
  freeform?: Maybe<FreeformHighlightModuleWhereInput>;
  freeformCarousel?: Maybe<CarouselModuleWhereInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<FreeformCarouselModuleFreeformsWhereInput>>;
  OR?: Maybe<Array<FreeformCarouselModuleFreeformsWhereInput>>;
  NOT?: Maybe<Array<FreeformCarouselModuleFreeformsWhereInput>>;
};

export type FreeformCarouselModuleFreeformsWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type FreeformHeadlinerModuleCustomInput = {
  title: Scalars['String'];
  isPublished?: Maybe<Scalars['Boolean']>;
  order: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  CTAText?: Maybe<Scalars['String']>;
  routing?: Maybe<FreeformHighlightModuleRouting>;
  link?: Maybe<Scalars['String']>;
  referenceId?: Maybe<Scalars['String']>;
  referenceType?: Maybe<FreeformReferenceType>;
};

export type FreeformHighlightModule = {
  __typename?: 'FreeformHighlightModule';
  id: Scalars['ID'];
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  logoImage?: Maybe<Scalars['String']>;
  CTAText?: Maybe<Scalars['String']>;
  routing?: Maybe<FreeformHighlightModuleRouting>;
  link?: Maybe<Scalars['String']>;
  referenceId?: Maybe<Scalars['String']>;
  referenceType?: Maybe<FreeformReferenceType>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  carouselFreeformOrder?: Maybe<Scalars['Int']>;
  carouselFreeformId?: Maybe<Scalars['ID']>;
  referenceSlug?: Maybe<Scalars['String']>;
  item?: Maybe<FreeformItem>;
};

export type FreeformHighlightModuleConnection = {
  __typename?: 'FreeformHighlightModuleConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<FreeformHighlightModuleEdge>>;
  aggregate: AggregateFreeformHighlightModule;
};

export type FreeformHighlightModuleCreateInput = {
  id?: Maybe<Scalars['ID']>;
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  logoImage?: Maybe<Scalars['String']>;
  CTAText?: Maybe<Scalars['String']>;
  routing?: Maybe<FreeformHighlightModuleRouting>;
  link?: Maybe<Scalars['String']>;
  referenceId?: Maybe<Scalars['String']>;
  referenceType?: Maybe<FreeformReferenceType>;
};

export type FreeformHighlightModuleCreateOneInput = {
  create?: Maybe<FreeformHighlightModuleCreateInput>;
  connect?: Maybe<FreeformHighlightModuleWhereUniqueInput>;
};

export type FreeformHighlightModuleCustomInput = {
  title: Scalars['String'];
  isPublished?: Maybe<Scalars['Boolean']>;
  order: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  CTAText?: Maybe<Scalars['String']>;
  routing?: Maybe<FreeformHighlightModuleRouting>;
  link?: Maybe<Scalars['String']>;
  referenceId?: Maybe<Scalars['String']>;
  referenceType?: Maybe<FreeformReferenceType>;
};

export type FreeformHighlightModuleEdge = {
  __typename?: 'FreeformHighlightModuleEdge';
  node: FreeformHighlightModule;
  cursor: Scalars['String'];
};

export enum FreeformHighlightModuleOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  ImageAsc = 'image_ASC',
  ImageDesc = 'image_DESC',
  LogoImageAsc = 'logoImage_ASC',
  LogoImageDesc = 'logoImage_DESC',
  CtaTextAsc = 'CTAText_ASC',
  CtaTextDesc = 'CTAText_DESC',
  RoutingAsc = 'routing_ASC',
  RoutingDesc = 'routing_DESC',
  LinkAsc = 'link_ASC',
  LinkDesc = 'link_DESC',
  ReferenceIdAsc = 'referenceId_ASC',
  ReferenceIdDesc = 'referenceId_DESC',
  ReferenceTypeAsc = 'referenceType_ASC',
  ReferenceTypeDesc = 'referenceType_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type FreeformHighlightModulePreviousValues = {
  __typename?: 'FreeformHighlightModulePreviousValues';
  id: Scalars['ID'];
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  logoImage?: Maybe<Scalars['String']>;
  CTAText?: Maybe<Scalars['String']>;
  routing?: Maybe<FreeformHighlightModuleRouting>;
  link?: Maybe<Scalars['String']>;
  referenceId?: Maybe<Scalars['String']>;
  referenceType?: Maybe<FreeformReferenceType>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export enum FreeformHighlightModuleRouting {
  Internal = 'INTERNAL',
  Reference = 'REFERENCE',
  External = 'EXTERNAL'
}

export type FreeformHighlightModuleSubscriptionPayload = {
  __typename?: 'FreeformHighlightModuleSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<FreeformHighlightModule>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<FreeformHighlightModulePreviousValues>;
};

export type FreeformHighlightModuleSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<FreeformHighlightModuleWhereInput>;
  AND?: Maybe<Array<FreeformHighlightModuleSubscriptionWhereInput>>;
  OR?: Maybe<Array<FreeformHighlightModuleSubscriptionWhereInput>>;
  NOT?: Maybe<Array<FreeformHighlightModuleSubscriptionWhereInput>>;
};

export type FreeformHighlightModuleUpdateDataInput = {
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  logoImage?: Maybe<Scalars['String']>;
  CTAText?: Maybe<Scalars['String']>;
  routing?: Maybe<FreeformHighlightModuleRouting>;
  link?: Maybe<Scalars['String']>;
  referenceId?: Maybe<Scalars['String']>;
  referenceType?: Maybe<FreeformReferenceType>;
};

export type FreeformHighlightModuleUpdateInput = {
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  logoImage?: Maybe<Scalars['String']>;
  CTAText?: Maybe<Scalars['String']>;
  routing?: Maybe<FreeformHighlightModuleRouting>;
  link?: Maybe<Scalars['String']>;
  referenceId?: Maybe<Scalars['String']>;
  referenceType?: Maybe<FreeformReferenceType>;
};

export type FreeformHighlightModuleUpdateManyMutationInput = {
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  logoImage?: Maybe<Scalars['String']>;
  CTAText?: Maybe<Scalars['String']>;
  routing?: Maybe<FreeformHighlightModuleRouting>;
  link?: Maybe<Scalars['String']>;
  referenceId?: Maybe<Scalars['String']>;
  referenceType?: Maybe<FreeformReferenceType>;
};

export type FreeformHighlightModuleUpdateOneInput = {
  create?: Maybe<FreeformHighlightModuleCreateInput>;
  update?: Maybe<FreeformHighlightModuleUpdateDataInput>;
  upsert?: Maybe<FreeformHighlightModuleUpsertNestedInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<FreeformHighlightModuleWhereUniqueInput>;
};

export type FreeformHighlightModuleUpsertNestedInput = {
  update: FreeformHighlightModuleUpdateDataInput;
  create: FreeformHighlightModuleCreateInput;
};

export type FreeformHighlightModuleWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Scalars['String']>>;
  title_not_in?: Maybe<Array<Scalars['String']>>;
  title_lt?: Maybe<Scalars['String']>;
  title_lte?: Maybe<Scalars['String']>;
  title_gt?: Maybe<Scalars['String']>;
  title_gte?: Maybe<Scalars['String']>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  title_starts_with?: Maybe<Scalars['String']>;
  title_not_starts_with?: Maybe<Scalars['String']>;
  title_ends_with?: Maybe<Scalars['String']>;
  title_not_ends_with?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  description_not?: Maybe<Scalars['String']>;
  description_in?: Maybe<Array<Scalars['String']>>;
  description_not_in?: Maybe<Array<Scalars['String']>>;
  description_lt?: Maybe<Scalars['String']>;
  description_lte?: Maybe<Scalars['String']>;
  description_gt?: Maybe<Scalars['String']>;
  description_gte?: Maybe<Scalars['String']>;
  description_contains?: Maybe<Scalars['String']>;
  description_not_contains?: Maybe<Scalars['String']>;
  description_starts_with?: Maybe<Scalars['String']>;
  description_not_starts_with?: Maybe<Scalars['String']>;
  description_ends_with?: Maybe<Scalars['String']>;
  description_not_ends_with?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  image_not?: Maybe<Scalars['String']>;
  image_in?: Maybe<Array<Scalars['String']>>;
  image_not_in?: Maybe<Array<Scalars['String']>>;
  image_lt?: Maybe<Scalars['String']>;
  image_lte?: Maybe<Scalars['String']>;
  image_gt?: Maybe<Scalars['String']>;
  image_gte?: Maybe<Scalars['String']>;
  image_contains?: Maybe<Scalars['String']>;
  image_not_contains?: Maybe<Scalars['String']>;
  image_starts_with?: Maybe<Scalars['String']>;
  image_not_starts_with?: Maybe<Scalars['String']>;
  image_ends_with?: Maybe<Scalars['String']>;
  image_not_ends_with?: Maybe<Scalars['String']>;
  logoImage?: Maybe<Scalars['String']>;
  logoImage_not?: Maybe<Scalars['String']>;
  logoImage_in?: Maybe<Array<Scalars['String']>>;
  logoImage_not_in?: Maybe<Array<Scalars['String']>>;
  logoImage_lt?: Maybe<Scalars['String']>;
  logoImage_lte?: Maybe<Scalars['String']>;
  logoImage_gt?: Maybe<Scalars['String']>;
  logoImage_gte?: Maybe<Scalars['String']>;
  logoImage_contains?: Maybe<Scalars['String']>;
  logoImage_not_contains?: Maybe<Scalars['String']>;
  logoImage_starts_with?: Maybe<Scalars['String']>;
  logoImage_not_starts_with?: Maybe<Scalars['String']>;
  logoImage_ends_with?: Maybe<Scalars['String']>;
  logoImage_not_ends_with?: Maybe<Scalars['String']>;
  CTAText?: Maybe<Scalars['String']>;
  CTAText_not?: Maybe<Scalars['String']>;
  CTAText_in?: Maybe<Array<Scalars['String']>>;
  CTAText_not_in?: Maybe<Array<Scalars['String']>>;
  CTAText_lt?: Maybe<Scalars['String']>;
  CTAText_lte?: Maybe<Scalars['String']>;
  CTAText_gt?: Maybe<Scalars['String']>;
  CTAText_gte?: Maybe<Scalars['String']>;
  CTAText_contains?: Maybe<Scalars['String']>;
  CTAText_not_contains?: Maybe<Scalars['String']>;
  CTAText_starts_with?: Maybe<Scalars['String']>;
  CTAText_not_starts_with?: Maybe<Scalars['String']>;
  CTAText_ends_with?: Maybe<Scalars['String']>;
  CTAText_not_ends_with?: Maybe<Scalars['String']>;
  routing?: Maybe<FreeformHighlightModuleRouting>;
  routing_not?: Maybe<FreeformHighlightModuleRouting>;
  routing_in?: Maybe<Array<FreeformHighlightModuleRouting>>;
  routing_not_in?: Maybe<Array<FreeformHighlightModuleRouting>>;
  link?: Maybe<Scalars['String']>;
  link_not?: Maybe<Scalars['String']>;
  link_in?: Maybe<Array<Scalars['String']>>;
  link_not_in?: Maybe<Array<Scalars['String']>>;
  link_lt?: Maybe<Scalars['String']>;
  link_lte?: Maybe<Scalars['String']>;
  link_gt?: Maybe<Scalars['String']>;
  link_gte?: Maybe<Scalars['String']>;
  link_contains?: Maybe<Scalars['String']>;
  link_not_contains?: Maybe<Scalars['String']>;
  link_starts_with?: Maybe<Scalars['String']>;
  link_not_starts_with?: Maybe<Scalars['String']>;
  link_ends_with?: Maybe<Scalars['String']>;
  link_not_ends_with?: Maybe<Scalars['String']>;
  referenceId?: Maybe<Scalars['String']>;
  referenceId_not?: Maybe<Scalars['String']>;
  referenceId_in?: Maybe<Array<Scalars['String']>>;
  referenceId_not_in?: Maybe<Array<Scalars['String']>>;
  referenceId_lt?: Maybe<Scalars['String']>;
  referenceId_lte?: Maybe<Scalars['String']>;
  referenceId_gt?: Maybe<Scalars['String']>;
  referenceId_gte?: Maybe<Scalars['String']>;
  referenceId_contains?: Maybe<Scalars['String']>;
  referenceId_not_contains?: Maybe<Scalars['String']>;
  referenceId_starts_with?: Maybe<Scalars['String']>;
  referenceId_not_starts_with?: Maybe<Scalars['String']>;
  referenceId_ends_with?: Maybe<Scalars['String']>;
  referenceId_not_ends_with?: Maybe<Scalars['String']>;
  referenceType?: Maybe<FreeformReferenceType>;
  referenceType_not?: Maybe<FreeformReferenceType>;
  referenceType_in?: Maybe<Array<FreeformReferenceType>>;
  referenceType_not_in?: Maybe<Array<FreeformReferenceType>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<FreeformHighlightModuleWhereInput>>;
  OR?: Maybe<Array<FreeformHighlightModuleWhereInput>>;
  NOT?: Maybe<Array<FreeformHighlightModuleWhereInput>>;
};

export type FreeformHighlightModuleWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type FreeformItem = Clip | Show | Podcast;

export enum FreeformReferenceType {
  Clip = 'CLIP',
  Video = 'VIDEO',
  Episode = 'EPISODE',
  PodcastEpisode = 'PODCAST_EPISODE',
  Show = 'SHOW',
  Podcast = 'PODCAST',
  ModulePage = 'MODULE_PAGE',
  Season = 'SEASON'
}

export type Headliner = {
  __typename?: 'Headliner';
  id?: Maybe<Scalars['ID']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  CTAText?: Maybe<Scalars['String']>;
  routing?: Maybe<FreeformHighlightModuleRouting>;
  link?: Maybe<Scalars['String']>;
  referenceId?: Maybe<Scalars['String']>;
  referenceType?: Maybe<FreeformReferenceType>;
  referenceSlug?: Maybe<Scalars['String']>;
  item?: Maybe<FreeformItem>;
};

export type Highlight = {
  __typename?: 'Highlight';
  id?: Maybe<Scalars['ID']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  CTAText?: Maybe<Scalars['String']>;
  routing?: Maybe<FreeformHighlightModuleRouting>;
  link?: Maybe<Scalars['String']>;
  referenceId?: Maybe<Scalars['String']>;
  referenceType?: Maybe<FreeformReferenceType>;
  referenceSlug?: Maybe<Scalars['String']>;
  item?: Maybe<FreeformItem>;
};



export type LiveEpisodeWhereInput = {
  orderBy?: Maybe<EpisodeOrderByInput>;
};


export type ModularPageCustomRes = {
  __typename?: 'ModularPageCustomRes';
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  nextOrder?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<ModulePageCreatedByUser>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  modules?: Maybe<Scalars['Json']>;
};

export type ModularPageRes = {
  __typename?: 'ModularPageRes';
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  isProminent?: Maybe<Scalars['Boolean']>;
  createdBy?: Maybe<User>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  slug?: Maybe<Scalars['String']>;
  nextOrder?: Maybe<Scalars['Int']>;
  modules?: Maybe<Array<Maybe<CustomModule>>>;
};

export type Module = {
  __typename?: 'Module';
  id: Scalars['ID'];
  moduleReferenceId?: Maybe<Scalars['String']>;
  moduleType?: Maybe<ModuleType>;
  order?: Maybe<Scalars['Int']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  modulePage: ModulePage;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type ModuleConnection = {
  __typename?: 'ModuleConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<ModuleEdge>>;
  aggregate: AggregateModule;
};

export type ModuleCreateInput = {
  id?: Maybe<Scalars['ID']>;
  moduleReferenceId?: Maybe<Scalars['String']>;
  moduleType?: Maybe<ModuleType>;
  order?: Maybe<Scalars['Int']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  modulePage: ModulePageCreateOneWithoutModulesInput;
};

export type ModuleCreateManyWithoutModulePageInput = {
  create?: Maybe<Array<ModuleCreateWithoutModulePageInput>>;
  connect?: Maybe<Array<ModuleWhereUniqueInput>>;
};

export type ModuleCreateWithoutModulePageInput = {
  id?: Maybe<Scalars['ID']>;
  moduleReferenceId?: Maybe<Scalars['String']>;
  moduleType?: Maybe<ModuleType>;
  order?: Maybe<Scalars['Int']>;
  isPublished?: Maybe<Scalars['Boolean']>;
};

export type ModuleEdge = {
  __typename?: 'ModuleEdge';
  node: Module;
  cursor: Scalars['String'];
};

export enum ModuleOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  ModuleReferenceIdAsc = 'moduleReferenceId_ASC',
  ModuleReferenceIdDesc = 'moduleReferenceId_DESC',
  ModuleTypeAsc = 'moduleType_ASC',
  ModuleTypeDesc = 'moduleType_DESC',
  OrderAsc = 'order_ASC',
  OrderDesc = 'order_DESC',
  IsPublishedAsc = 'isPublished_ASC',
  IsPublishedDesc = 'isPublished_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type ModulePage = {
  __typename?: 'ModulePage';
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  isProminent?: Maybe<Scalars['Boolean']>;
  modules?: Maybe<Array<Module>>;
  createdBy?: Maybe<User>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};


export type ModulePageModulesArgs = {
  where?: Maybe<ModuleWhereInput>;
  orderBy?: Maybe<ModuleOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type ModulePageConnection = {
  __typename?: 'ModulePageConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<ModulePageEdge>>;
  aggregate: AggregateModulePage;
};

export type ModulePageCreateInput = {
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  isProminent?: Maybe<Scalars['Boolean']>;
  modules?: Maybe<ModuleCreateManyWithoutModulePageInput>;
  createdBy?: Maybe<UserCreateOneInput>;
};

export type ModulePageCreateOneWithoutModulesInput = {
  create?: Maybe<ModulePageCreateWithoutModulesInput>;
  connect?: Maybe<ModulePageWhereUniqueInput>;
};

export type ModulePageCreateWithoutModulesInput = {
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  isProminent?: Maybe<Scalars['Boolean']>;
  createdBy?: Maybe<UserCreateOneInput>;
};

export type ModulePageCreatedByUser = {
  __typename?: 'ModulePageCreatedByUser';
  id?: Maybe<Scalars['ID']>;
  role?: Maybe<Role>;
};

export type ModulePageEdge = {
  __typename?: 'ModulePageEdge';
  node: ModulePage;
  cursor: Scalars['String'];
};

export enum ModulePageOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  IsPublishedAsc = 'isPublished_ASC',
  IsPublishedDesc = 'isPublished_DESC',
  IsProminentAsc = 'isProminent_ASC',
  IsProminentDesc = 'isProminent_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type ModulePagePreviousValues = {
  __typename?: 'ModulePagePreviousValues';
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  isProminent?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type ModulePageSubscriptionPayload = {
  __typename?: 'ModulePageSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<ModulePage>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<ModulePagePreviousValues>;
};

export type ModulePageSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<ModulePageWhereInput>;
  AND?: Maybe<Array<ModulePageSubscriptionWhereInput>>;
  OR?: Maybe<Array<ModulePageSubscriptionWhereInput>>;
  NOT?: Maybe<Array<ModulePageSubscriptionWhereInput>>;
};

export type ModulePageUpdateInput = {
  title?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  isProminent?: Maybe<Scalars['Boolean']>;
  modules?: Maybe<ModuleUpdateManyWithoutModulePageInput>;
  createdBy?: Maybe<UserUpdateOneInput>;
};

export type ModulePageUpdateManyMutationInput = {
  title?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  isProminent?: Maybe<Scalars['Boolean']>;
};

export type ModulePageUpdateOneRequiredWithoutModulesInput = {
  create?: Maybe<ModulePageCreateWithoutModulesInput>;
  update?: Maybe<ModulePageUpdateWithoutModulesDataInput>;
  upsert?: Maybe<ModulePageUpsertWithoutModulesInput>;
  connect?: Maybe<ModulePageWhereUniqueInput>;
};

export type ModulePageUpdateWithoutModulesDataInput = {
  title?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  isProminent?: Maybe<Scalars['Boolean']>;
  createdBy?: Maybe<UserUpdateOneInput>;
};

export type ModulePageUpsertWithoutModulesInput = {
  update: ModulePageUpdateWithoutModulesDataInput;
  create: ModulePageCreateWithoutModulesInput;
};

export type ModulePageWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Scalars['String']>>;
  title_not_in?: Maybe<Array<Scalars['String']>>;
  title_lt?: Maybe<Scalars['String']>;
  title_lte?: Maybe<Scalars['String']>;
  title_gt?: Maybe<Scalars['String']>;
  title_gte?: Maybe<Scalars['String']>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  title_starts_with?: Maybe<Scalars['String']>;
  title_not_starts_with?: Maybe<Scalars['String']>;
  title_ends_with?: Maybe<Scalars['String']>;
  title_not_ends_with?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  slug_not?: Maybe<Scalars['String']>;
  slug_in?: Maybe<Array<Scalars['String']>>;
  slug_not_in?: Maybe<Array<Scalars['String']>>;
  slug_lt?: Maybe<Scalars['String']>;
  slug_lte?: Maybe<Scalars['String']>;
  slug_gt?: Maybe<Scalars['String']>;
  slug_gte?: Maybe<Scalars['String']>;
  slug_contains?: Maybe<Scalars['String']>;
  slug_not_contains?: Maybe<Scalars['String']>;
  slug_starts_with?: Maybe<Scalars['String']>;
  slug_not_starts_with?: Maybe<Scalars['String']>;
  slug_ends_with?: Maybe<Scalars['String']>;
  slug_not_ends_with?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  isPublished_not?: Maybe<Scalars['Boolean']>;
  isProminent?: Maybe<Scalars['Boolean']>;
  isProminent_not?: Maybe<Scalars['Boolean']>;
  modules_every?: Maybe<ModuleWhereInput>;
  modules_some?: Maybe<ModuleWhereInput>;
  modules_none?: Maybe<ModuleWhereInput>;
  createdBy?: Maybe<UserWhereInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<ModulePageWhereInput>>;
  OR?: Maybe<Array<ModulePageWhereInput>>;
  NOT?: Maybe<Array<ModulePageWhereInput>>;
};

export type ModulePageWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
  slug?: Maybe<Scalars['String']>;
};

export type ModulePreviousValues = {
  __typename?: 'ModulePreviousValues';
  id: Scalars['ID'];
  moduleReferenceId?: Maybe<Scalars['String']>;
  moduleType?: Maybe<ModuleType>;
  order?: Maybe<Scalars['Int']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type ModuleScalarWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  moduleReferenceId?: Maybe<Scalars['String']>;
  moduleReferenceId_not?: Maybe<Scalars['String']>;
  moduleReferenceId_in?: Maybe<Array<Scalars['String']>>;
  moduleReferenceId_not_in?: Maybe<Array<Scalars['String']>>;
  moduleReferenceId_lt?: Maybe<Scalars['String']>;
  moduleReferenceId_lte?: Maybe<Scalars['String']>;
  moduleReferenceId_gt?: Maybe<Scalars['String']>;
  moduleReferenceId_gte?: Maybe<Scalars['String']>;
  moduleReferenceId_contains?: Maybe<Scalars['String']>;
  moduleReferenceId_not_contains?: Maybe<Scalars['String']>;
  moduleReferenceId_starts_with?: Maybe<Scalars['String']>;
  moduleReferenceId_not_starts_with?: Maybe<Scalars['String']>;
  moduleReferenceId_ends_with?: Maybe<Scalars['String']>;
  moduleReferenceId_not_ends_with?: Maybe<Scalars['String']>;
  moduleType?: Maybe<ModuleType>;
  moduleType_not?: Maybe<ModuleType>;
  moduleType_in?: Maybe<Array<ModuleType>>;
  moduleType_not_in?: Maybe<Array<ModuleType>>;
  order?: Maybe<Scalars['Int']>;
  order_not?: Maybe<Scalars['Int']>;
  order_in?: Maybe<Array<Scalars['Int']>>;
  order_not_in?: Maybe<Array<Scalars['Int']>>;
  order_lt?: Maybe<Scalars['Int']>;
  order_lte?: Maybe<Scalars['Int']>;
  order_gt?: Maybe<Scalars['Int']>;
  order_gte?: Maybe<Scalars['Int']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  isPublished_not?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<ModuleScalarWhereInput>>;
  OR?: Maybe<Array<ModuleScalarWhereInput>>;
  NOT?: Maybe<Array<ModuleScalarWhereInput>>;
};

export type ModuleSubscriptionPayload = {
  __typename?: 'ModuleSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<Module>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<ModulePreviousValues>;
};

export type ModuleSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<ModuleWhereInput>;
  AND?: Maybe<Array<ModuleSubscriptionWhereInput>>;
  OR?: Maybe<Array<ModuleSubscriptionWhereInput>>;
  NOT?: Maybe<Array<ModuleSubscriptionWhereInput>>;
};

export enum ModuleType {
  EpisodeCarousel = 'EPISODE_CAROUSEL',
  EpisodePremiere = 'EPISODE_PREMIERE',
  PodcastEpisodeCarousel = 'PODCAST_EPISODE_CAROUSEL',
  PodcastEpisodePremiere = 'PODCAST_EPISODE_PREMIERE',
  VideoCarousel = 'VIDEO_CAROUSEL',
  VideoPremiere = 'VIDEO_PREMIERE',
  ShowCarousel = 'SHOW_CAROUSEL',
  FreeformCarousel = 'FREEFORM_CAROUSEL',
  ShowPremiere = 'SHOW_PREMIERE',
  PodcastCarousel = 'PODCAST_CAROUSEL',
  PodcastPremiere = 'PODCAST_PREMIERE',
  ClipCarousel = 'CLIP_CAROUSEL',
  FreeformHighlight = 'FREEFORM_HIGHLIGHT',
  FreeformHeadliner = 'FREEFORM_HEADLINER',
  ContinueWatching = 'CONTINUE_WATCHING',
  ContinueListening = 'CONTINUE_LISTENING'
}

export type ModuleUpdateInput = {
  moduleReferenceId?: Maybe<Scalars['String']>;
  moduleType?: Maybe<ModuleType>;
  order?: Maybe<Scalars['Int']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  modulePage?: Maybe<ModulePageUpdateOneRequiredWithoutModulesInput>;
};

export type ModuleUpdateManyDataInput = {
  moduleReferenceId?: Maybe<Scalars['String']>;
  moduleType?: Maybe<ModuleType>;
  order?: Maybe<Scalars['Int']>;
  isPublished?: Maybe<Scalars['Boolean']>;
};

export type ModuleUpdateManyMutationInput = {
  moduleReferenceId?: Maybe<Scalars['String']>;
  moduleType?: Maybe<ModuleType>;
  order?: Maybe<Scalars['Int']>;
  isPublished?: Maybe<Scalars['Boolean']>;
};

export type ModuleUpdateManyWithWhereNestedInput = {
  where: ModuleScalarWhereInput;
  data: ModuleUpdateManyDataInput;
};

export type ModuleUpdateManyWithoutModulePageInput = {
  create?: Maybe<Array<ModuleCreateWithoutModulePageInput>>;
  delete?: Maybe<Array<ModuleWhereUniqueInput>>;
  connect?: Maybe<Array<ModuleWhereUniqueInput>>;
  set?: Maybe<Array<ModuleWhereUniqueInput>>;
  disconnect?: Maybe<Array<ModuleWhereUniqueInput>>;
  update?: Maybe<Array<ModuleUpdateWithWhereUniqueWithoutModulePageInput>>;
  upsert?: Maybe<Array<ModuleUpsertWithWhereUniqueWithoutModulePageInput>>;
  deleteMany?: Maybe<Array<ModuleScalarWhereInput>>;
  updateMany?: Maybe<Array<ModuleUpdateManyWithWhereNestedInput>>;
};

export type ModuleUpdateWithWhereUniqueWithoutModulePageInput = {
  where: ModuleWhereUniqueInput;
  data: ModuleUpdateWithoutModulePageDataInput;
};

export type ModuleUpdateWithoutModulePageDataInput = {
  moduleReferenceId?: Maybe<Scalars['String']>;
  moduleType?: Maybe<ModuleType>;
  order?: Maybe<Scalars['Int']>;
  isPublished?: Maybe<Scalars['Boolean']>;
};

export type ModuleUpsertWithWhereUniqueWithoutModulePageInput = {
  where: ModuleWhereUniqueInput;
  update: ModuleUpdateWithoutModulePageDataInput;
  create: ModuleCreateWithoutModulePageInput;
};

export type ModuleWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  moduleReferenceId?: Maybe<Scalars['String']>;
  moduleReferenceId_not?: Maybe<Scalars['String']>;
  moduleReferenceId_in?: Maybe<Array<Scalars['String']>>;
  moduleReferenceId_not_in?: Maybe<Array<Scalars['String']>>;
  moduleReferenceId_lt?: Maybe<Scalars['String']>;
  moduleReferenceId_lte?: Maybe<Scalars['String']>;
  moduleReferenceId_gt?: Maybe<Scalars['String']>;
  moduleReferenceId_gte?: Maybe<Scalars['String']>;
  moduleReferenceId_contains?: Maybe<Scalars['String']>;
  moduleReferenceId_not_contains?: Maybe<Scalars['String']>;
  moduleReferenceId_starts_with?: Maybe<Scalars['String']>;
  moduleReferenceId_not_starts_with?: Maybe<Scalars['String']>;
  moduleReferenceId_ends_with?: Maybe<Scalars['String']>;
  moduleReferenceId_not_ends_with?: Maybe<Scalars['String']>;
  moduleType?: Maybe<ModuleType>;
  moduleType_not?: Maybe<ModuleType>;
  moduleType_in?: Maybe<Array<ModuleType>>;
  moduleType_not_in?: Maybe<Array<ModuleType>>;
  order?: Maybe<Scalars['Int']>;
  order_not?: Maybe<Scalars['Int']>;
  order_in?: Maybe<Array<Scalars['Int']>>;
  order_not_in?: Maybe<Array<Scalars['Int']>>;
  order_lt?: Maybe<Scalars['Int']>;
  order_lte?: Maybe<Scalars['Int']>;
  order_gt?: Maybe<Scalars['Int']>;
  order_gte?: Maybe<Scalars['Int']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  isPublished_not?: Maybe<Scalars['Boolean']>;
  modulePage?: Maybe<ModulePageWhereInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<ModuleWhereInput>>;
  OR?: Maybe<Array<ModuleWhereInput>>;
  NOT?: Maybe<Array<ModuleWhereInput>>;
};

export type ModuleWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createActivityLog: ActivityLog;
  updateActivityLog?: Maybe<ActivityLog>;
  updateManyActivityLogs: BatchPayload;
  upsertActivityLog: ActivityLog;
  deleteActivityLog?: Maybe<ActivityLog>;
  deleteManyActivityLogs: BatchPayload;
  createCaption: Caption;
  updateCaption?: Maybe<Caption>;
  updateManyCaptions: BatchPayload;
  upsertCaption: Caption;
  deleteCaption?: Maybe<Caption>;
  deleteManyCaptions: BatchPayload;
  createCarouselModule: CarouselModule;
  updateCarouselModule?: Maybe<CarouselModule>;
  updateManyCarouselModules: BatchPayload;
  upsertCarouselModule: CarouselModule;
  deleteCarouselModule?: Maybe<CarouselModule>;
  deleteManyCarouselModules: BatchPayload;
  createClip: Clip;
  updateClip?: Maybe<Clip>;
  updateManyClips: BatchPayload;
  upsertClip: Clip;
  deleteClip?: Maybe<Clip>;
  deleteManyClips: BatchPayload;
  createClipCarouselModuleClips: ClipCarouselModuleClips;
  updateClipCarouselModuleClips?: Maybe<ClipCarouselModuleClips>;
  updateManyClipCarouselModuleClipses: BatchPayload;
  upsertClipCarouselModuleClips: ClipCarouselModuleClips;
  deleteClipCarouselModuleClips?: Maybe<ClipCarouselModuleClips>;
  deleteManyClipCarouselModuleClipses: BatchPayload;
  createContentfulWebhook: ContentfulWebhook;
  updateContentfulWebhook?: Maybe<ContentfulWebhook>;
  updateManyContentfulWebhooks: BatchPayload;
  upsertContentfulWebhook: ContentfulWebhook;
  deleteContentfulWebhook?: Maybe<ContentfulWebhook>;
  deleteManyContentfulWebhooks: BatchPayload;
  createContinueListeningModule: ContinueListeningModule;
  updateContinueListeningModule?: Maybe<ContinueListeningModule>;
  updateManyContinueListeningModules: BatchPayload;
  upsertContinueListeningModule: ContinueListeningModule;
  deleteContinueListeningModule?: Maybe<ContinueListeningModule>;
  deleteManyContinueListeningModules: BatchPayload;
  createContinueWatchingModule: ContinueWatchingModule;
  updateContinueWatchingModule?: Maybe<ContinueWatchingModule>;
  updateManyContinueWatchingModules: BatchPayload;
  upsertContinueWatchingModule: ContinueWatchingModule;
  deleteContinueWatchingModule?: Maybe<ContinueWatchingModule>;
  deleteManyContinueWatchingModules: BatchPayload;
  createEpisode: Episode;
  updateEpisode?: Maybe<Episode>;
  updateManyEpisodes: BatchPayload;
  upsertEpisode: Episode;
  deleteEpisode?: Maybe<Episode>;
  deleteManyEpisodes: BatchPayload;
  createEpisodeCarouselModuleEpisodes: EpisodeCarouselModuleEpisodes;
  updateEpisodeCarouselModuleEpisodes?: Maybe<EpisodeCarouselModuleEpisodes>;
  updateManyEpisodeCarouselModuleEpisodeses: BatchPayload;
  upsertEpisodeCarouselModuleEpisodes: EpisodeCarouselModuleEpisodes;
  deleteEpisodeCarouselModuleEpisodes?: Maybe<EpisodeCarouselModuleEpisodes>;
  deleteManyEpisodeCarouselModuleEpisodeses: BatchPayload;
  createEpisodeSegment: EpisodeSegment;
  updateEpisodeSegment?: Maybe<EpisodeSegment>;
  updateManyEpisodeSegments: BatchPayload;
  upsertEpisodeSegment: EpisodeSegment;
  deleteEpisodeSegment?: Maybe<EpisodeSegment>;
  deleteManyEpisodeSegments: BatchPayload;
  createFreeformCarouselModuleFreeforms: FreeformCarouselModuleFreeforms;
  updateFreeformCarouselModuleFreeforms?: Maybe<FreeformCarouselModuleFreeforms>;
  updateManyFreeformCarouselModuleFreeformses: BatchPayload;
  upsertFreeformCarouselModuleFreeforms: FreeformCarouselModuleFreeforms;
  deleteFreeformCarouselModuleFreeforms?: Maybe<FreeformCarouselModuleFreeforms>;
  deleteManyFreeformCarouselModuleFreeformses: BatchPayload;
  createFreeformHighlightModule: FreeformHighlightModule;
  updateFreeformHighlightModule?: Maybe<FreeformHighlightModule>;
  updateManyFreeformHighlightModules: BatchPayload;
  upsertFreeformHighlightModule: FreeformHighlightModule;
  deleteFreeformHighlightModule?: Maybe<FreeformHighlightModule>;
  deleteManyFreeformHighlightModules: BatchPayload;
  createModule: Module;
  updateModule?: Maybe<Module>;
  updateManyModules: BatchPayload;
  upsertModule: Module;
  deleteModule?: Maybe<Module>;
  deleteManyModules: BatchPayload;
  createModulePage: ModulePage;
  updateModulePage?: Maybe<ModulePage>;
  updateManyModulePages: BatchPayload;
  upsertModulePage: ModulePage;
  deleteModulePage?: Maybe<ModulePage>;
  deleteManyModulePages: BatchPayload;
  createPodcast: Podcast;
  updatePodcast?: Maybe<Podcast>;
  updateManyPodcasts: BatchPayload;
  upsertPodcast: Podcast;
  deletePodcast?: Maybe<Podcast>;
  deleteManyPodcasts: BatchPayload;
  createPodcastCarouselModulePodcasts: PodcastCarouselModulePodcasts;
  updatePodcastCarouselModulePodcasts?: Maybe<PodcastCarouselModulePodcasts>;
  updateManyPodcastCarouselModulePodcastses: BatchPayload;
  upsertPodcastCarouselModulePodcasts: PodcastCarouselModulePodcasts;
  deletePodcastCarouselModulePodcasts?: Maybe<PodcastCarouselModulePodcasts>;
  deleteManyPodcastCarouselModulePodcastses: BatchPayload;
  createPodcastEpisode: PodcastEpisode;
  updatePodcastEpisode?: Maybe<PodcastEpisode>;
  updateManyPodcastEpisodes: BatchPayload;
  upsertPodcastEpisode: PodcastEpisode;
  deletePodcastEpisode?: Maybe<PodcastEpisode>;
  deleteManyPodcastEpisodes: BatchPayload;
  createPodcastEpisodeCarouselModulePodcastEpisodes: PodcastEpisodeCarouselModulePodcastEpisodes;
  updatePodcastEpisodeCarouselModulePodcastEpisodes?: Maybe<PodcastEpisodeCarouselModulePodcastEpisodes>;
  updateManyPodcastEpisodeCarouselModulePodcastEpisodeses: BatchPayload;
  upsertPodcastEpisodeCarouselModulePodcastEpisodes: PodcastEpisodeCarouselModulePodcastEpisodes;
  deletePodcastEpisodeCarouselModulePodcastEpisodes?: Maybe<PodcastEpisodeCarouselModulePodcastEpisodes>;
  deleteManyPodcastEpisodeCarouselModulePodcastEpisodeses: BatchPayload;
  createPodcastMigrationEpisode: PodcastMigrationEpisode;
  updatePodcastMigrationEpisode?: Maybe<PodcastMigrationEpisode>;
  updateManyPodcastMigrationEpisodes: BatchPayload;
  upsertPodcastMigrationEpisode: PodcastMigrationEpisode;
  deletePodcastMigrationEpisode?: Maybe<PodcastMigrationEpisode>;
  deleteManyPodcastMigrationEpisodes: BatchPayload;
  createPodcastMigrationSeason: PodcastMigrationSeason;
  updatePodcastMigrationSeason?: Maybe<PodcastMigrationSeason>;
  updateManyPodcastMigrationSeasons: BatchPayload;
  upsertPodcastMigrationSeason: PodcastMigrationSeason;
  deletePodcastMigrationSeason?: Maybe<PodcastMigrationSeason>;
  deleteManyPodcastMigrationSeasons: BatchPayload;
  createPodcastMigrationShow: PodcastMigrationShow;
  updatePodcastMigrationShow?: Maybe<PodcastMigrationShow>;
  updateManyPodcastMigrationShows: BatchPayload;
  upsertPodcastMigrationShow: PodcastMigrationShow;
  deletePodcastMigrationShow?: Maybe<PodcastMigrationShow>;
  deleteManyPodcastMigrationShows: BatchPayload;
  createPremiereModule: PremiereModule;
  updatePremiereModule?: Maybe<PremiereModule>;
  updateManyPremiereModules: BatchPayload;
  upsertPremiereModule: PremiereModule;
  deletePremiereModule?: Maybe<PremiereModule>;
  deleteManyPremiereModules: BatchPayload;
  createSeason: Season;
  updateSeason?: Maybe<Season>;
  updateManySeasons: BatchPayload;
  upsertSeason: Season;
  deleteSeason?: Maybe<Season>;
  deleteManySeasons: BatchPayload;
  createSeasonEpisode: SeasonEpisode;
  updateSeasonEpisode?: Maybe<SeasonEpisode>;
  updateManySeasonEpisodes: BatchPayload;
  upsertSeasonEpisode: SeasonEpisode;
  deleteSeasonEpisode?: Maybe<SeasonEpisode>;
  deleteManySeasonEpisodes: BatchPayload;
  createSegmentAudioStatus: SegmentAudioStatus;
  updateSegmentAudioStatus?: Maybe<SegmentAudioStatus>;
  updateManySegmentAudioStatuses: BatchPayload;
  upsertSegmentAudioStatus: SegmentAudioStatus;
  deleteSegmentAudioStatus?: Maybe<SegmentAudioStatus>;
  deleteManySegmentAudioStatuses: BatchPayload;
  createShow: Show;
  updateShow?: Maybe<Show>;
  updateManyShows: BatchPayload;
  upsertShow: Show;
  deleteShow?: Maybe<Show>;
  deleteManyShows: BatchPayload;
  createShowCarouselModuleShows: ShowCarouselModuleShows;
  updateShowCarouselModuleShows?: Maybe<ShowCarouselModuleShows>;
  updateManyShowCarouselModuleShowses: BatchPayload;
  upsertShowCarouselModuleShows: ShowCarouselModuleShows;
  deleteShowCarouselModuleShows?: Maybe<ShowCarouselModuleShows>;
  deleteManyShowCarouselModuleShowses: BatchPayload;
  createUser: User;
  updateUser?: Maybe<User>;
  updateManyUsers: BatchPayload;
  upsertUser: User;
  deleteUser?: Maybe<User>;
  deleteManyUsers: BatchPayload;
  createVideo: Video;
  updateVideo?: Maybe<Video>;
  updateManyVideos: BatchPayload;
  upsertVideo: Video;
  deleteVideo?: Maybe<Video>;
  deleteManyVideos: BatchPayload;
  createVideoCarouselModuleVideos: VideoCarouselModuleVideos;
  updateVideoCarouselModuleVideos?: Maybe<VideoCarouselModuleVideos>;
  updateManyVideoCarouselModuleVideoses: BatchPayload;
  upsertVideoCarouselModuleVideos: VideoCarouselModuleVideos;
  deleteVideoCarouselModuleVideos?: Maybe<VideoCarouselModuleVideos>;
  deleteManyVideoCarouselModuleVideoses: BatchPayload;
  deleteCaptionCustom?: Maybe<DeleteCaptionRes>;
  createCaptionCustom?: Maybe<Caption>;
  syncEpisodeDiscussion?: Maybe<SyncEpisodeDiscussionRes>;
  createModularPageCustom?: Maybe<CreateModularPageRes>;
  updateModularPageCustom?: Maybe<CreateModularPageRes>;
  deleteModuleCustom?: Maybe<DeleteRes>;
  deleteCarouselClip?: Maybe<DeleteRes>;
  deleteCarouselVideo?: Maybe<DeleteRes>;
  deleteCarouselEpisode?: Maybe<DeleteRes>;
  deleteCarouselFreeform?: Maybe<DeleteRes>;
  deleteCarouselPodcastEpisode?: Maybe<DeleteRes>;
  deleteCarouselShow?: Maybe<DeleteRes>;
  deleteCarouselPodcast?: Maybe<DeleteRes>;
  deleteModularPageCustom?: Maybe<DeleteRes>;
  requestMasterAccess?: Maybe<Scalars['Boolean']>;
  createPodcastCustom?: Maybe<GetPodcastRes>;
  updatePodcastCustom?: Maybe<GetPodcastRes>;
  deletePodcastCustom?: Maybe<Scalars['Boolean']>;
  removePodcastEpisodeFromSeason?: Maybe<GetPodcastEpisodeRes>;
  createPodcastEpisodeCustom?: Maybe<GetPodcastEpisodeRes>;
  updatePodcastEpisodeCustom?: Maybe<GetPodcastEpisodeRes>;
  deletePodcastEpisodeCustom?: Maybe<Scalars['Boolean']>;
  changePassword?: Maybe<UserPasswordData>;
};


export type MutationCreateActivityLogArgs = {
  data: ActivityLogCreateInput;
};


export type MutationUpdateActivityLogArgs = {
  data: ActivityLogUpdateInput;
  where: ActivityLogWhereUniqueInput;
};


export type MutationUpdateManyActivityLogsArgs = {
  data: ActivityLogUpdateManyMutationInput;
  where?: Maybe<ActivityLogWhereInput>;
};


export type MutationUpsertActivityLogArgs = {
  where: ActivityLogWhereUniqueInput;
  create: ActivityLogCreateInput;
  update: ActivityLogUpdateInput;
};


export type MutationDeleteActivityLogArgs = {
  where: ActivityLogWhereUniqueInput;
};


export type MutationDeleteManyActivityLogsArgs = {
  where?: Maybe<ActivityLogWhereInput>;
};


export type MutationCreateCaptionArgs = {
  data: CaptionCreateInput;
};


export type MutationUpdateCaptionArgs = {
  data: CaptionUpdateInput;
  where: CaptionWhereUniqueInput;
};


export type MutationUpdateManyCaptionsArgs = {
  data: CaptionUpdateManyMutationInput;
  where?: Maybe<CaptionWhereInput>;
};


export type MutationUpsertCaptionArgs = {
  where: CaptionWhereUniqueInput;
  create: CaptionCreateInput;
  update: CaptionUpdateInput;
};


export type MutationDeleteCaptionArgs = {
  where: CaptionWhereUniqueInput;
};


export type MutationDeleteManyCaptionsArgs = {
  where?: Maybe<CaptionWhereInput>;
};


export type MutationCreateCarouselModuleArgs = {
  data: CarouselModuleCreateInput;
};


export type MutationUpdateCarouselModuleArgs = {
  data: CarouselModuleUpdateInput;
  where: CarouselModuleWhereUniqueInput;
};


export type MutationUpdateManyCarouselModulesArgs = {
  data: CarouselModuleUpdateManyMutationInput;
  where?: Maybe<CarouselModuleWhereInput>;
};


export type MutationUpsertCarouselModuleArgs = {
  where: CarouselModuleWhereUniqueInput;
  create: CarouselModuleCreateInput;
  update: CarouselModuleUpdateInput;
};


export type MutationDeleteCarouselModuleArgs = {
  where: CarouselModuleWhereUniqueInput;
};


export type MutationDeleteManyCarouselModulesArgs = {
  where?: Maybe<CarouselModuleWhereInput>;
};


export type MutationCreateClipArgs = {
  data: ClipCreateInput;
};


export type MutationUpdateClipArgs = {
  data: ClipUpdateInput;
  where: ClipWhereUniqueInput;
};


export type MutationUpdateManyClipsArgs = {
  data: ClipUpdateManyMutationInput;
  where?: Maybe<ClipWhereInput>;
};


export type MutationUpsertClipArgs = {
  where: ClipWhereUniqueInput;
  create: ClipCreateInput;
  update: ClipUpdateInput;
};


export type MutationDeleteClipArgs = {
  where: ClipWhereUniqueInput;
};


export type MutationDeleteManyClipsArgs = {
  where?: Maybe<ClipWhereInput>;
};


export type MutationCreateClipCarouselModuleClipsArgs = {
  data: ClipCarouselModuleClipsCreateInput;
};


export type MutationUpdateClipCarouselModuleClipsArgs = {
  data: ClipCarouselModuleClipsUpdateInput;
  where: ClipCarouselModuleClipsWhereUniqueInput;
};


export type MutationUpdateManyClipCarouselModuleClipsesArgs = {
  data: ClipCarouselModuleClipsUpdateManyMutationInput;
  where?: Maybe<ClipCarouselModuleClipsWhereInput>;
};


export type MutationUpsertClipCarouselModuleClipsArgs = {
  where: ClipCarouselModuleClipsWhereUniqueInput;
  create: ClipCarouselModuleClipsCreateInput;
  update: ClipCarouselModuleClipsUpdateInput;
};


export type MutationDeleteClipCarouselModuleClipsArgs = {
  where: ClipCarouselModuleClipsWhereUniqueInput;
};


export type MutationDeleteManyClipCarouselModuleClipsesArgs = {
  where?: Maybe<ClipCarouselModuleClipsWhereInput>;
};


export type MutationCreateContentfulWebhookArgs = {
  data: ContentfulWebhookCreateInput;
};


export type MutationUpdateContentfulWebhookArgs = {
  data: ContentfulWebhookUpdateInput;
  where: ContentfulWebhookWhereUniqueInput;
};


export type MutationUpdateManyContentfulWebhooksArgs = {
  data: ContentfulWebhookUpdateManyMutationInput;
  where?: Maybe<ContentfulWebhookWhereInput>;
};


export type MutationUpsertContentfulWebhookArgs = {
  where: ContentfulWebhookWhereUniqueInput;
  create: ContentfulWebhookCreateInput;
  update: ContentfulWebhookUpdateInput;
};


export type MutationDeleteContentfulWebhookArgs = {
  where: ContentfulWebhookWhereUniqueInput;
};


export type MutationDeleteManyContentfulWebhooksArgs = {
  where?: Maybe<ContentfulWebhookWhereInput>;
};


export type MutationCreateContinueListeningModuleArgs = {
  data: ContinueListeningModuleCreateInput;
};


export type MutationUpdateContinueListeningModuleArgs = {
  data: ContinueListeningModuleUpdateInput;
  where: ContinueListeningModuleWhereUniqueInput;
};


export type MutationUpdateManyContinueListeningModulesArgs = {
  data: ContinueListeningModuleUpdateManyMutationInput;
  where?: Maybe<ContinueListeningModuleWhereInput>;
};


export type MutationUpsertContinueListeningModuleArgs = {
  where: ContinueListeningModuleWhereUniqueInput;
  create: ContinueListeningModuleCreateInput;
  update: ContinueListeningModuleUpdateInput;
};


export type MutationDeleteContinueListeningModuleArgs = {
  where: ContinueListeningModuleWhereUniqueInput;
};


export type MutationDeleteManyContinueListeningModulesArgs = {
  where?: Maybe<ContinueListeningModuleWhereInput>;
};


export type MutationCreateContinueWatchingModuleArgs = {
  data: ContinueWatchingModuleCreateInput;
};


export type MutationUpdateContinueWatchingModuleArgs = {
  data: ContinueWatchingModuleUpdateInput;
  where: ContinueWatchingModuleWhereUniqueInput;
};


export type MutationUpdateManyContinueWatchingModulesArgs = {
  data: ContinueWatchingModuleUpdateManyMutationInput;
  where?: Maybe<ContinueWatchingModuleWhereInput>;
};


export type MutationUpsertContinueWatchingModuleArgs = {
  where: ContinueWatchingModuleWhereUniqueInput;
  create: ContinueWatchingModuleCreateInput;
  update: ContinueWatchingModuleUpdateInput;
};


export type MutationDeleteContinueWatchingModuleArgs = {
  where: ContinueWatchingModuleWhereUniqueInput;
};


export type MutationDeleteManyContinueWatchingModulesArgs = {
  where?: Maybe<ContinueWatchingModuleWhereInput>;
};


export type MutationCreateEpisodeArgs = {
  data: EpisodeCreateInput;
};


export type MutationUpdateEpisodeArgs = {
  data: EpisodeUpdateInput;
  where: EpisodeWhereUniqueInput;
};


export type MutationUpdateManyEpisodesArgs = {
  data: EpisodeUpdateManyMutationInput;
  where?: Maybe<EpisodeWhereInput>;
};


export type MutationUpsertEpisodeArgs = {
  where: EpisodeWhereUniqueInput;
  create: EpisodeCreateInput;
  update: EpisodeUpdateInput;
};


export type MutationDeleteEpisodeArgs = {
  where: EpisodeWhereUniqueInput;
};


export type MutationDeleteManyEpisodesArgs = {
  where?: Maybe<EpisodeWhereInput>;
};


export type MutationCreateEpisodeCarouselModuleEpisodesArgs = {
  data: EpisodeCarouselModuleEpisodesCreateInput;
};


export type MutationUpdateEpisodeCarouselModuleEpisodesArgs = {
  data: EpisodeCarouselModuleEpisodesUpdateInput;
  where: EpisodeCarouselModuleEpisodesWhereUniqueInput;
};


export type MutationUpdateManyEpisodeCarouselModuleEpisodesesArgs = {
  data: EpisodeCarouselModuleEpisodesUpdateManyMutationInput;
  where?: Maybe<EpisodeCarouselModuleEpisodesWhereInput>;
};


export type MutationUpsertEpisodeCarouselModuleEpisodesArgs = {
  where: EpisodeCarouselModuleEpisodesWhereUniqueInput;
  create: EpisodeCarouselModuleEpisodesCreateInput;
  update: EpisodeCarouselModuleEpisodesUpdateInput;
};


export type MutationDeleteEpisodeCarouselModuleEpisodesArgs = {
  where: EpisodeCarouselModuleEpisodesWhereUniqueInput;
};


export type MutationDeleteManyEpisodeCarouselModuleEpisodesesArgs = {
  where?: Maybe<EpisodeCarouselModuleEpisodesWhereInput>;
};


export type MutationCreateEpisodeSegmentArgs = {
  data: EpisodeSegmentCreateInput;
};


export type MutationUpdateEpisodeSegmentArgs = {
  data: EpisodeSegmentUpdateInput;
  where: EpisodeSegmentWhereUniqueInput;
};


export type MutationUpdateManyEpisodeSegmentsArgs = {
  data: EpisodeSegmentUpdateManyMutationInput;
  where?: Maybe<EpisodeSegmentWhereInput>;
};


export type MutationUpsertEpisodeSegmentArgs = {
  where: EpisodeSegmentWhereUniqueInput;
  create: EpisodeSegmentCreateInput;
  update: EpisodeSegmentUpdateInput;
};


export type MutationDeleteEpisodeSegmentArgs = {
  where: EpisodeSegmentWhereUniqueInput;
};


export type MutationDeleteManyEpisodeSegmentsArgs = {
  where?: Maybe<EpisodeSegmentWhereInput>;
};


export type MutationCreateFreeformCarouselModuleFreeformsArgs = {
  data: FreeformCarouselModuleFreeformsCreateInput;
};


export type MutationUpdateFreeformCarouselModuleFreeformsArgs = {
  data: FreeformCarouselModuleFreeformsUpdateInput;
  where: FreeformCarouselModuleFreeformsWhereUniqueInput;
};


export type MutationUpdateManyFreeformCarouselModuleFreeformsesArgs = {
  data: FreeformCarouselModuleFreeformsUpdateManyMutationInput;
  where?: Maybe<FreeformCarouselModuleFreeformsWhereInput>;
};


export type MutationUpsertFreeformCarouselModuleFreeformsArgs = {
  where: FreeformCarouselModuleFreeformsWhereUniqueInput;
  create: FreeformCarouselModuleFreeformsCreateInput;
  update: FreeformCarouselModuleFreeformsUpdateInput;
};


export type MutationDeleteFreeformCarouselModuleFreeformsArgs = {
  where: FreeformCarouselModuleFreeformsWhereUniqueInput;
};


export type MutationDeleteManyFreeformCarouselModuleFreeformsesArgs = {
  where?: Maybe<FreeformCarouselModuleFreeformsWhereInput>;
};


export type MutationCreateFreeformHighlightModuleArgs = {
  data: FreeformHighlightModuleCreateInput;
};


export type MutationUpdateFreeformHighlightModuleArgs = {
  data: FreeformHighlightModuleUpdateInput;
  where: FreeformHighlightModuleWhereUniqueInput;
};


export type MutationUpdateManyFreeformHighlightModulesArgs = {
  data: FreeformHighlightModuleUpdateManyMutationInput;
  where?: Maybe<FreeformHighlightModuleWhereInput>;
};


export type MutationUpsertFreeformHighlightModuleArgs = {
  where: FreeformHighlightModuleWhereUniqueInput;
  create: FreeformHighlightModuleCreateInput;
  update: FreeformHighlightModuleUpdateInput;
};


export type MutationDeleteFreeformHighlightModuleArgs = {
  where: FreeformHighlightModuleWhereUniqueInput;
};


export type MutationDeleteManyFreeformHighlightModulesArgs = {
  where?: Maybe<FreeformHighlightModuleWhereInput>;
};


export type MutationCreateModuleArgs = {
  data: ModuleCreateInput;
};


export type MutationUpdateModuleArgs = {
  data: ModuleUpdateInput;
  where: ModuleWhereUniqueInput;
};


export type MutationUpdateManyModulesArgs = {
  data: ModuleUpdateManyMutationInput;
  where?: Maybe<ModuleWhereInput>;
};


export type MutationUpsertModuleArgs = {
  where: ModuleWhereUniqueInput;
  create: ModuleCreateInput;
  update: ModuleUpdateInput;
};


export type MutationDeleteModuleArgs = {
  where: ModuleWhereUniqueInput;
};


export type MutationDeleteManyModulesArgs = {
  where?: Maybe<ModuleWhereInput>;
};


export type MutationCreateModulePageArgs = {
  data: ModulePageCreateInput;
};


export type MutationUpdateModulePageArgs = {
  data: ModulePageUpdateInput;
  where: ModulePageWhereUniqueInput;
};


export type MutationUpdateManyModulePagesArgs = {
  data: ModulePageUpdateManyMutationInput;
  where?: Maybe<ModulePageWhereInput>;
};


export type MutationUpsertModulePageArgs = {
  where: ModulePageWhereUniqueInput;
  create: ModulePageCreateInput;
  update: ModulePageUpdateInput;
};


export type MutationDeleteModulePageArgs = {
  where: ModulePageWhereUniqueInput;
};


export type MutationDeleteManyModulePagesArgs = {
  where?: Maybe<ModulePageWhereInput>;
};


export type MutationCreatePodcastArgs = {
  data: PodcastCreateInput;
};


export type MutationUpdatePodcastArgs = {
  data: PodcastUpdateInput;
  where: PodcastWhereUniqueInput;
};


export type MutationUpdateManyPodcastsArgs = {
  data: PodcastUpdateManyMutationInput;
  where?: Maybe<PodcastWhereInput>;
};


export type MutationUpsertPodcastArgs = {
  where: PodcastWhereUniqueInput;
  create: PodcastCreateInput;
  update: PodcastUpdateInput;
};


export type MutationDeletePodcastArgs = {
  where: PodcastWhereUniqueInput;
};


export type MutationDeleteManyPodcastsArgs = {
  where?: Maybe<PodcastWhereInput>;
};


export type MutationCreatePodcastCarouselModulePodcastsArgs = {
  data: PodcastCarouselModulePodcastsCreateInput;
};


export type MutationUpdatePodcastCarouselModulePodcastsArgs = {
  data: PodcastCarouselModulePodcastsUpdateInput;
  where: PodcastCarouselModulePodcastsWhereUniqueInput;
};


export type MutationUpdateManyPodcastCarouselModulePodcastsesArgs = {
  data: PodcastCarouselModulePodcastsUpdateManyMutationInput;
  where?: Maybe<PodcastCarouselModulePodcastsWhereInput>;
};


export type MutationUpsertPodcastCarouselModulePodcastsArgs = {
  where: PodcastCarouselModulePodcastsWhereUniqueInput;
  create: PodcastCarouselModulePodcastsCreateInput;
  update: PodcastCarouselModulePodcastsUpdateInput;
};


export type MutationDeletePodcastCarouselModulePodcastsArgs = {
  where: PodcastCarouselModulePodcastsWhereUniqueInput;
};


export type MutationDeleteManyPodcastCarouselModulePodcastsesArgs = {
  where?: Maybe<PodcastCarouselModulePodcastsWhereInput>;
};


export type MutationCreatePodcastEpisodeArgs = {
  data: PodcastEpisodeCreateInput;
};


export type MutationUpdatePodcastEpisodeArgs = {
  data: PodcastEpisodeUpdateInput;
  where: PodcastEpisodeWhereUniqueInput;
};


export type MutationUpdateManyPodcastEpisodesArgs = {
  data: PodcastEpisodeUpdateManyMutationInput;
  where?: Maybe<PodcastEpisodeWhereInput>;
};


export type MutationUpsertPodcastEpisodeArgs = {
  where: PodcastEpisodeWhereUniqueInput;
  create: PodcastEpisodeCreateInput;
  update: PodcastEpisodeUpdateInput;
};


export type MutationDeletePodcastEpisodeArgs = {
  where: PodcastEpisodeWhereUniqueInput;
};


export type MutationDeleteManyPodcastEpisodesArgs = {
  where?: Maybe<PodcastEpisodeWhereInput>;
};


export type MutationCreatePodcastEpisodeCarouselModulePodcastEpisodesArgs = {
  data: PodcastEpisodeCarouselModulePodcastEpisodesCreateInput;
};


export type MutationUpdatePodcastEpisodeCarouselModulePodcastEpisodesArgs = {
  data: PodcastEpisodeCarouselModulePodcastEpisodesUpdateInput;
  where: PodcastEpisodeCarouselModulePodcastEpisodesWhereUniqueInput;
};


export type MutationUpdateManyPodcastEpisodeCarouselModulePodcastEpisodesesArgs = {
  data: PodcastEpisodeCarouselModulePodcastEpisodesUpdateManyMutationInput;
  where?: Maybe<PodcastEpisodeCarouselModulePodcastEpisodesWhereInput>;
};


export type MutationUpsertPodcastEpisodeCarouselModulePodcastEpisodesArgs = {
  where: PodcastEpisodeCarouselModulePodcastEpisodesWhereUniqueInput;
  create: PodcastEpisodeCarouselModulePodcastEpisodesCreateInput;
  update: PodcastEpisodeCarouselModulePodcastEpisodesUpdateInput;
};


export type MutationDeletePodcastEpisodeCarouselModulePodcastEpisodesArgs = {
  where: PodcastEpisodeCarouselModulePodcastEpisodesWhereUniqueInput;
};


export type MutationDeleteManyPodcastEpisodeCarouselModulePodcastEpisodesesArgs = {
  where?: Maybe<PodcastEpisodeCarouselModulePodcastEpisodesWhereInput>;
};


export type MutationCreatePodcastMigrationEpisodeArgs = {
  data: PodcastMigrationEpisodeCreateInput;
};


export type MutationUpdatePodcastMigrationEpisodeArgs = {
  data: PodcastMigrationEpisodeUpdateInput;
  where: PodcastMigrationEpisodeWhereUniqueInput;
};


export type MutationUpdateManyPodcastMigrationEpisodesArgs = {
  data: PodcastMigrationEpisodeUpdateManyMutationInput;
  where?: Maybe<PodcastMigrationEpisodeWhereInput>;
};


export type MutationUpsertPodcastMigrationEpisodeArgs = {
  where: PodcastMigrationEpisodeWhereUniqueInput;
  create: PodcastMigrationEpisodeCreateInput;
  update: PodcastMigrationEpisodeUpdateInput;
};


export type MutationDeletePodcastMigrationEpisodeArgs = {
  where: PodcastMigrationEpisodeWhereUniqueInput;
};


export type MutationDeleteManyPodcastMigrationEpisodesArgs = {
  where?: Maybe<PodcastMigrationEpisodeWhereInput>;
};


export type MutationCreatePodcastMigrationSeasonArgs = {
  data: PodcastMigrationSeasonCreateInput;
};


export type MutationUpdatePodcastMigrationSeasonArgs = {
  data: PodcastMigrationSeasonUpdateInput;
  where: PodcastMigrationSeasonWhereUniqueInput;
};


export type MutationUpdateManyPodcastMigrationSeasonsArgs = {
  data: PodcastMigrationSeasonUpdateManyMutationInput;
  where?: Maybe<PodcastMigrationSeasonWhereInput>;
};


export type MutationUpsertPodcastMigrationSeasonArgs = {
  where: PodcastMigrationSeasonWhereUniqueInput;
  create: PodcastMigrationSeasonCreateInput;
  update: PodcastMigrationSeasonUpdateInput;
};


export type MutationDeletePodcastMigrationSeasonArgs = {
  where: PodcastMigrationSeasonWhereUniqueInput;
};


export type MutationDeleteManyPodcastMigrationSeasonsArgs = {
  where?: Maybe<PodcastMigrationSeasonWhereInput>;
};


export type MutationCreatePodcastMigrationShowArgs = {
  data: PodcastMigrationShowCreateInput;
};


export type MutationUpdatePodcastMigrationShowArgs = {
  data: PodcastMigrationShowUpdateInput;
  where: PodcastMigrationShowWhereUniqueInput;
};


export type MutationUpdateManyPodcastMigrationShowsArgs = {
  data: PodcastMigrationShowUpdateManyMutationInput;
  where?: Maybe<PodcastMigrationShowWhereInput>;
};


export type MutationUpsertPodcastMigrationShowArgs = {
  where: PodcastMigrationShowWhereUniqueInput;
  create: PodcastMigrationShowCreateInput;
  update: PodcastMigrationShowUpdateInput;
};


export type MutationDeletePodcastMigrationShowArgs = {
  where: PodcastMigrationShowWhereUniqueInput;
};


export type MutationDeleteManyPodcastMigrationShowsArgs = {
  where?: Maybe<PodcastMigrationShowWhereInput>;
};


export type MutationCreatePremiereModuleArgs = {
  data: PremiereModuleCreateInput;
};


export type MutationUpdatePremiereModuleArgs = {
  data: PremiereModuleUpdateInput;
  where: PremiereModuleWhereUniqueInput;
};


export type MutationUpdateManyPremiereModulesArgs = {
  data: PremiereModuleUpdateManyMutationInput;
  where?: Maybe<PremiereModuleWhereInput>;
};


export type MutationUpsertPremiereModuleArgs = {
  where: PremiereModuleWhereUniqueInput;
  create: PremiereModuleCreateInput;
  update: PremiereModuleUpdateInput;
};


export type MutationDeletePremiereModuleArgs = {
  where: PremiereModuleWhereUniqueInput;
};


export type MutationDeleteManyPremiereModulesArgs = {
  where?: Maybe<PremiereModuleWhereInput>;
};


export type MutationCreateSeasonArgs = {
  data: SeasonCreateInput;
};


export type MutationUpdateSeasonArgs = {
  data: SeasonUpdateInput;
  where: SeasonWhereUniqueInput;
};


export type MutationUpdateManySeasonsArgs = {
  data: SeasonUpdateManyMutationInput;
  where?: Maybe<SeasonWhereInput>;
};


export type MutationUpsertSeasonArgs = {
  where: SeasonWhereUniqueInput;
  create: SeasonCreateInput;
  update: SeasonUpdateInput;
};


export type MutationDeleteSeasonArgs = {
  where: SeasonWhereUniqueInput;
};


export type MutationDeleteManySeasonsArgs = {
  where?: Maybe<SeasonWhereInput>;
};


export type MutationCreateSeasonEpisodeArgs = {
  data: SeasonEpisodeCreateInput;
};


export type MutationUpdateSeasonEpisodeArgs = {
  data: SeasonEpisodeUpdateInput;
  where: SeasonEpisodeWhereUniqueInput;
};


export type MutationUpdateManySeasonEpisodesArgs = {
  data: SeasonEpisodeUpdateManyMutationInput;
  where?: Maybe<SeasonEpisodeWhereInput>;
};


export type MutationUpsertSeasonEpisodeArgs = {
  where: SeasonEpisodeWhereUniqueInput;
  create: SeasonEpisodeCreateInput;
  update: SeasonEpisodeUpdateInput;
};


export type MutationDeleteSeasonEpisodeArgs = {
  where: SeasonEpisodeWhereUniqueInput;
};


export type MutationDeleteManySeasonEpisodesArgs = {
  where?: Maybe<SeasonEpisodeWhereInput>;
};


export type MutationCreateSegmentAudioStatusArgs = {
  data: SegmentAudioStatusCreateInput;
};


export type MutationUpdateSegmentAudioStatusArgs = {
  data: SegmentAudioStatusUpdateInput;
  where: SegmentAudioStatusWhereUniqueInput;
};


export type MutationUpdateManySegmentAudioStatusesArgs = {
  data: SegmentAudioStatusUpdateManyMutationInput;
  where?: Maybe<SegmentAudioStatusWhereInput>;
};


export type MutationUpsertSegmentAudioStatusArgs = {
  where: SegmentAudioStatusWhereUniqueInput;
  create: SegmentAudioStatusCreateInput;
  update: SegmentAudioStatusUpdateInput;
};


export type MutationDeleteSegmentAudioStatusArgs = {
  where: SegmentAudioStatusWhereUniqueInput;
};


export type MutationDeleteManySegmentAudioStatusesArgs = {
  where?: Maybe<SegmentAudioStatusWhereInput>;
};


export type MutationCreateShowArgs = {
  data: ShowCreateInput;
};


export type MutationUpdateShowArgs = {
  data: ShowUpdateInput;
  where: ShowWhereUniqueInput;
};


export type MutationUpdateManyShowsArgs = {
  data: ShowUpdateManyMutationInput;
  where?: Maybe<ShowWhereInput>;
};


export type MutationUpsertShowArgs = {
  where: ShowWhereUniqueInput;
  create: ShowCreateInput;
  update: ShowUpdateInput;
};


export type MutationDeleteShowArgs = {
  where: ShowWhereUniqueInput;
};


export type MutationDeleteManyShowsArgs = {
  where?: Maybe<ShowWhereInput>;
};


export type MutationCreateShowCarouselModuleShowsArgs = {
  data: ShowCarouselModuleShowsCreateInput;
};


export type MutationUpdateShowCarouselModuleShowsArgs = {
  data: ShowCarouselModuleShowsUpdateInput;
  where: ShowCarouselModuleShowsWhereUniqueInput;
};


export type MutationUpdateManyShowCarouselModuleShowsesArgs = {
  data: ShowCarouselModuleShowsUpdateManyMutationInput;
  where?: Maybe<ShowCarouselModuleShowsWhereInput>;
};


export type MutationUpsertShowCarouselModuleShowsArgs = {
  where: ShowCarouselModuleShowsWhereUniqueInput;
  create: ShowCarouselModuleShowsCreateInput;
  update: ShowCarouselModuleShowsUpdateInput;
};


export type MutationDeleteShowCarouselModuleShowsArgs = {
  where: ShowCarouselModuleShowsWhereUniqueInput;
};


export type MutationDeleteManyShowCarouselModuleShowsesArgs = {
  where?: Maybe<ShowCarouselModuleShowsWhereInput>;
};


export type MutationCreateUserArgs = {
  data: UserCreateInput;
};


export type MutationUpdateUserArgs = {
  data: UserUpdateInput;
  where: UserWhereUniqueInput;
};


export type MutationUpdateManyUsersArgs = {
  data: UserUpdateManyMutationInput;
  where?: Maybe<UserWhereInput>;
};


export type MutationUpsertUserArgs = {
  where: UserWhereUniqueInput;
  create: UserCreateInput;
  update: UserUpdateInput;
};


export type MutationDeleteUserArgs = {
  where: UserWhereUniqueInput;
};


export type MutationDeleteManyUsersArgs = {
  where?: Maybe<UserWhereInput>;
};


export type MutationCreateVideoArgs = {
  data: VideoCreateInput;
};


export type MutationUpdateVideoArgs = {
  data: VideoUpdateInput;
  where: VideoWhereUniqueInput;
};


export type MutationUpdateManyVideosArgs = {
  data: VideoUpdateManyMutationInput;
  where?: Maybe<VideoWhereInput>;
};


export type MutationUpsertVideoArgs = {
  where: VideoWhereUniqueInput;
  create: VideoCreateInput;
  update: VideoUpdateInput;
};


export type MutationDeleteVideoArgs = {
  where: VideoWhereUniqueInput;
};


export type MutationDeleteManyVideosArgs = {
  where?: Maybe<VideoWhereInput>;
};


export type MutationCreateVideoCarouselModuleVideosArgs = {
  data: VideoCarouselModuleVideosCreateInput;
};


export type MutationUpdateVideoCarouselModuleVideosArgs = {
  data: VideoCarouselModuleVideosUpdateInput;
  where: VideoCarouselModuleVideosWhereUniqueInput;
};


export type MutationUpdateManyVideoCarouselModuleVideosesArgs = {
  data: VideoCarouselModuleVideosUpdateManyMutationInput;
  where?: Maybe<VideoCarouselModuleVideosWhereInput>;
};


export type MutationUpsertVideoCarouselModuleVideosArgs = {
  where: VideoCarouselModuleVideosWhereUniqueInput;
  create: VideoCarouselModuleVideosCreateInput;
  update: VideoCarouselModuleVideosUpdateInput;
};


export type MutationDeleteVideoCarouselModuleVideosArgs = {
  where: VideoCarouselModuleVideosWhereUniqueInput;
};


export type MutationDeleteManyVideoCarouselModuleVideosesArgs = {
  where?: Maybe<VideoCarouselModuleVideosWhereInput>;
};


export type MutationDeleteCaptionCustomArgs = {
  where?: Maybe<DeleteCaptionCustomInput>;
};


export type MutationCreateCaptionCustomArgs = {
  data: Array<CreateCaptionInput>;
};


export type MutationSyncEpisodeDiscussionArgs = {
  where?: Maybe<SyncEpisodeDiscussionInput>;
};


export type MutationCreateModularPageCustomArgs = {
  data: CreateModularPageInput;
};


export type MutationUpdateModularPageCustomArgs = {
  data: UpdateModularPageInput;
  where: UpdateModularPageWhereInput;
};


export type MutationDeleteModuleCustomArgs = {
  where: DeleteModuleWhereInput;
};


export type MutationDeleteCarouselClipArgs = {
  where: DeleteWhereInput;
};


export type MutationDeleteCarouselVideoArgs = {
  where: DeleteWhereInput;
};


export type MutationDeleteCarouselEpisodeArgs = {
  where: DeleteWhereInput;
};


export type MutationDeleteCarouselFreeformArgs = {
  where: DeleteWhereInput;
};


export type MutationDeleteCarouselPodcastEpisodeArgs = {
  where: DeleteWhereInput;
};


export type MutationDeleteCarouselShowArgs = {
  where: DeleteWhereInput;
};


export type MutationDeleteCarouselPodcastArgs = {
  where: DeleteWhereInput;
};


export type MutationDeleteModularPageCustomArgs = {
  where: DeleteWhereInput;
};


export type MutationRequestMasterAccessArgs = {
  where: AssetIdInput;
};


export type MutationCreatePodcastCustomArgs = {
  data: PodcastCreateInputCustom;
};


export type MutationUpdatePodcastCustomArgs = {
  data: PodcastUpdateInputCustom;
  where: PodcastWhereUniqueInput;
};


export type MutationDeletePodcastCustomArgs = {
  where: PodcastWhereUniqueInput;
};


export type MutationRemovePodcastEpisodeFromSeasonArgs = {
  where?: Maybe<PodcastEpisodeWhereUniqueInput>;
};


export type MutationCreatePodcastEpisodeCustomArgs = {
  data: PodcastEpisodeCreateInputCustom;
};


export type MutationUpdatePodcastEpisodeCustomArgs = {
  where: PodcastEpisodeWhereUniqueInput;
  data: PodcastEpisodeUpdateInputCustom;
};


export type MutationDeletePodcastEpisodeCustomArgs = {
  where: PodcastEpisodeWhereUniqueInput;
};


export type MutationChangePasswordArgs = {
  data?: Maybe<UserPasswordDataInput>;
};

export enum MutationType {
  Created = 'CREATED',
  Updated = 'UPDATED',
  Deleted = 'DELETED'
}

export type Node = {
  id: Scalars['ID'];
};

export type PageInfo = {
  __typename?: 'PageInfo';
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
  endCursor?: Maybe<Scalars['String']>;
};

export type ParselyAuthor = {
  __typename?: 'ParselyAuthor';
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

export type ParselyAuthorInput = {
  limit?: Maybe<Scalars['Int']>;
};

export type ParselyPost = {
  __typename?: 'ParselyPost';
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  author?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
};

export type ParselyPostInput = {
  section: Scalars['String'];
  author?: Maybe<Scalars['String']>;
  tag?: Maybe<Scalars['String']>;
  sort?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
};

export type ParselyTopic = {
  __typename?: 'ParselyTopic';
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

export type ParselyTopicInput = {
  limit?: Maybe<Scalars['Int']>;
};

export type PermissionCustom = {
  __typename?: 'PermissionCustom';
  id?: Maybe<Scalars['ID']>;
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  canRead?: Maybe<Scalars['Boolean']>;
  canMutate?: Maybe<Scalars['Boolean']>;
};

export type Podcast = {
  __typename?: 'Podcast';
  id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  status: PodcastStatus;
  weight?: Maybe<Scalars['Int']>;
  belongsTo?: Maybe<Scalars['String']>;
  thirdPartyShowId?: Maybe<Scalars['String']>;
  coverImage?: Maybe<Scalars['String']>;
  logoImage?: Maybe<Scalars['String']>;
  backgroundImage?: Maybe<Scalars['String']>;
  seasons?: Maybe<Array<Season>>;
  podcastEpisodes?: Maybe<Array<PodcastEpisode>>;
  author: User;
  createdBy: User;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  carouselPodcastOrder?: Maybe<Scalars['Int']>;
  carouselPodcastId?: Maybe<Scalars['ID']>;
};


export type PodcastSeasonsArgs = {
  where?: Maybe<SeasonWhereInput>;
  orderBy?: Maybe<SeasonOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type PodcastPodcastEpisodesArgs = {
  where?: Maybe<PodcastEpisodeWhereInput>;
  orderBy?: Maybe<PodcastEpisodeOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type PodcastCarousel = {
  __typename?: 'PodcastCarousel';
  id?: Maybe<Scalars['ID']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  nextOrder?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  orientation?: Maybe<ShowCarouselImageOrientation>;
  podcasts?: Maybe<Array<Maybe<Podcast>>>;
  layout?: Maybe<Scalars['String']>;
};

export type PodcastCarouselCustomInput = {
  podcastId: Scalars['ID'];
  order?: Maybe<Scalars['Int']>;
};

export type PodcastCarouselModuleCustomInput = {
  title: Scalars['String'];
  order: Scalars['Int'];
  isPublished?: Maybe<Scalars['Boolean']>;
  orientation?: Maybe<ShowCarouselImageOrientation>;
  carouselPodcasts?: Maybe<Array<Maybe<PodcastCarouselCustomInput>>>;
};

export type PodcastCarouselModulePodcasts = {
  __typename?: 'PodcastCarouselModulePodcasts';
  id: Scalars['ID'];
  order?: Maybe<Scalars['Int']>;
  podcast?: Maybe<Podcast>;
  podcastCarousel?: Maybe<CarouselModule>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type PodcastCarouselModulePodcastsConnection = {
  __typename?: 'PodcastCarouselModulePodcastsConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<PodcastCarouselModulePodcastsEdge>>;
  aggregate: AggregatePodcastCarouselModulePodcasts;
};

export type PodcastCarouselModulePodcastsCreateInput = {
  id?: Maybe<Scalars['ID']>;
  order?: Maybe<Scalars['Int']>;
  podcast?: Maybe<PodcastCreateOneInput>;
  podcastCarousel?: Maybe<CarouselModuleCreateOneWithoutCarouselPodcastsInput>;
};

export type PodcastCarouselModulePodcastsCreateManyWithoutPodcastCarouselInput = {
  create?: Maybe<Array<PodcastCarouselModulePodcastsCreateWithoutPodcastCarouselInput>>;
  connect?: Maybe<Array<PodcastCarouselModulePodcastsWhereUniqueInput>>;
};

export type PodcastCarouselModulePodcastsCreateWithoutPodcastCarouselInput = {
  id?: Maybe<Scalars['ID']>;
  order?: Maybe<Scalars['Int']>;
  podcast?: Maybe<PodcastCreateOneInput>;
};

export type PodcastCarouselModulePodcastsEdge = {
  __typename?: 'PodcastCarouselModulePodcastsEdge';
  node: PodcastCarouselModulePodcasts;
  cursor: Scalars['String'];
};

export enum PodcastCarouselModulePodcastsOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  OrderAsc = 'order_ASC',
  OrderDesc = 'order_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type PodcastCarouselModulePodcastsPreviousValues = {
  __typename?: 'PodcastCarouselModulePodcastsPreviousValues';
  id: Scalars['ID'];
  order?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type PodcastCarouselModulePodcastsScalarWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  order?: Maybe<Scalars['Int']>;
  order_not?: Maybe<Scalars['Int']>;
  order_in?: Maybe<Array<Scalars['Int']>>;
  order_not_in?: Maybe<Array<Scalars['Int']>>;
  order_lt?: Maybe<Scalars['Int']>;
  order_lte?: Maybe<Scalars['Int']>;
  order_gt?: Maybe<Scalars['Int']>;
  order_gte?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<PodcastCarouselModulePodcastsScalarWhereInput>>;
  OR?: Maybe<Array<PodcastCarouselModulePodcastsScalarWhereInput>>;
  NOT?: Maybe<Array<PodcastCarouselModulePodcastsScalarWhereInput>>;
};

export type PodcastCarouselModulePodcastsSubscriptionPayload = {
  __typename?: 'PodcastCarouselModulePodcastsSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<PodcastCarouselModulePodcasts>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<PodcastCarouselModulePodcastsPreviousValues>;
};

export type PodcastCarouselModulePodcastsSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<PodcastCarouselModulePodcastsWhereInput>;
  AND?: Maybe<Array<PodcastCarouselModulePodcastsSubscriptionWhereInput>>;
  OR?: Maybe<Array<PodcastCarouselModulePodcastsSubscriptionWhereInput>>;
  NOT?: Maybe<Array<PodcastCarouselModulePodcastsSubscriptionWhereInput>>;
};

export type PodcastCarouselModulePodcastsUpdateInput = {
  order?: Maybe<Scalars['Int']>;
  podcast?: Maybe<PodcastUpdateOneInput>;
  podcastCarousel?: Maybe<CarouselModuleUpdateOneWithoutCarouselPodcastsInput>;
};

export type PodcastCarouselModulePodcastsUpdateManyDataInput = {
  order?: Maybe<Scalars['Int']>;
};

export type PodcastCarouselModulePodcastsUpdateManyMutationInput = {
  order?: Maybe<Scalars['Int']>;
};

export type PodcastCarouselModulePodcastsUpdateManyWithWhereNestedInput = {
  where: PodcastCarouselModulePodcastsScalarWhereInput;
  data: PodcastCarouselModulePodcastsUpdateManyDataInput;
};

export type PodcastCarouselModulePodcastsUpdateManyWithoutPodcastCarouselInput = {
  create?: Maybe<Array<PodcastCarouselModulePodcastsCreateWithoutPodcastCarouselInput>>;
  delete?: Maybe<Array<PodcastCarouselModulePodcastsWhereUniqueInput>>;
  connect?: Maybe<Array<PodcastCarouselModulePodcastsWhereUniqueInput>>;
  set?: Maybe<Array<PodcastCarouselModulePodcastsWhereUniqueInput>>;
  disconnect?: Maybe<Array<PodcastCarouselModulePodcastsWhereUniqueInput>>;
  update?: Maybe<Array<PodcastCarouselModulePodcastsUpdateWithWhereUniqueWithoutPodcastCarouselInput>>;
  upsert?: Maybe<Array<PodcastCarouselModulePodcastsUpsertWithWhereUniqueWithoutPodcastCarouselInput>>;
  deleteMany?: Maybe<Array<PodcastCarouselModulePodcastsScalarWhereInput>>;
  updateMany?: Maybe<Array<PodcastCarouselModulePodcastsUpdateManyWithWhereNestedInput>>;
};

export type PodcastCarouselModulePodcastsUpdateWithWhereUniqueWithoutPodcastCarouselInput = {
  where: PodcastCarouselModulePodcastsWhereUniqueInput;
  data: PodcastCarouselModulePodcastsUpdateWithoutPodcastCarouselDataInput;
};

export type PodcastCarouselModulePodcastsUpdateWithoutPodcastCarouselDataInput = {
  order?: Maybe<Scalars['Int']>;
  podcast?: Maybe<PodcastUpdateOneInput>;
};

export type PodcastCarouselModulePodcastsUpsertWithWhereUniqueWithoutPodcastCarouselInput = {
  where: PodcastCarouselModulePodcastsWhereUniqueInput;
  update: PodcastCarouselModulePodcastsUpdateWithoutPodcastCarouselDataInput;
  create: PodcastCarouselModulePodcastsCreateWithoutPodcastCarouselInput;
};

export type PodcastCarouselModulePodcastsWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  order?: Maybe<Scalars['Int']>;
  order_not?: Maybe<Scalars['Int']>;
  order_in?: Maybe<Array<Scalars['Int']>>;
  order_not_in?: Maybe<Array<Scalars['Int']>>;
  order_lt?: Maybe<Scalars['Int']>;
  order_lte?: Maybe<Scalars['Int']>;
  order_gt?: Maybe<Scalars['Int']>;
  order_gte?: Maybe<Scalars['Int']>;
  podcast?: Maybe<PodcastWhereInput>;
  podcastCarousel?: Maybe<CarouselModuleWhereInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<PodcastCarouselModulePodcastsWhereInput>>;
  OR?: Maybe<Array<PodcastCarouselModulePodcastsWhereInput>>;
  NOT?: Maybe<Array<PodcastCarouselModulePodcastsWhereInput>>;
};

export type PodcastCarouselModulePodcastsWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type PodcastConnection = {
  __typename?: 'PodcastConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<PodcastEdge>>;
  aggregate: AggregatePodcast;
};

export type PodcastCreateInput = {
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<PodcastStatus>;
  weight?: Maybe<Scalars['Int']>;
  belongsTo?: Maybe<Scalars['String']>;
  thirdPartyShowId?: Maybe<Scalars['String']>;
  coverImage?: Maybe<Scalars['String']>;
  logoImage?: Maybe<Scalars['String']>;
  backgroundImage?: Maybe<Scalars['String']>;
  seasons?: Maybe<SeasonCreateManyWithoutPodcastInput>;
  podcastEpisodes?: Maybe<PodcastEpisodeCreateManyWithoutPodcastInput>;
  author: UserCreateOneInput;
  createdBy: UserCreateOneInput;
};

export type PodcastCreateInputCustom = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<PodcastStatus>;
  weight?: Maybe<Scalars['Int']>;
  belongsTo?: Maybe<Scalars['String']>;
  thirdPartyShowId?: Maybe<Scalars['String']>;
  coverImage?: Maybe<Scalars['String']>;
  logoImage?: Maybe<Scalars['String']>;
  backgroundImage?: Maybe<Scalars['String']>;
  authorId: Scalars['ID'];
};

export type PodcastCreateOneInput = {
  create?: Maybe<PodcastCreateInput>;
  connect?: Maybe<PodcastWhereUniqueInput>;
};

export type PodcastCreateOneWithoutPodcastEpisodesInput = {
  create?: Maybe<PodcastCreateWithoutPodcastEpisodesInput>;
  connect?: Maybe<PodcastWhereUniqueInput>;
};

export type PodcastCreateOneWithoutSeasonsInput = {
  create?: Maybe<PodcastCreateWithoutSeasonsInput>;
  connect?: Maybe<PodcastWhereUniqueInput>;
};

export type PodcastCreateWithoutPodcastEpisodesInput = {
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<PodcastStatus>;
  weight?: Maybe<Scalars['Int']>;
  belongsTo?: Maybe<Scalars['String']>;
  thirdPartyShowId?: Maybe<Scalars['String']>;
  coverImage?: Maybe<Scalars['String']>;
  logoImage?: Maybe<Scalars['String']>;
  backgroundImage?: Maybe<Scalars['String']>;
  seasons?: Maybe<SeasonCreateManyWithoutPodcastInput>;
  author: UserCreateOneInput;
  createdBy: UserCreateOneInput;
};

export type PodcastCreateWithoutSeasonsInput = {
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<PodcastStatus>;
  weight?: Maybe<Scalars['Int']>;
  belongsTo?: Maybe<Scalars['String']>;
  thirdPartyShowId?: Maybe<Scalars['String']>;
  coverImage?: Maybe<Scalars['String']>;
  logoImage?: Maybe<Scalars['String']>;
  backgroundImage?: Maybe<Scalars['String']>;
  podcastEpisodes?: Maybe<PodcastEpisodeCreateManyWithoutPodcastInput>;
  author: UserCreateOneInput;
  createdBy: UserCreateOneInput;
};

export type PodcastEdge = {
  __typename?: 'PodcastEdge';
  node: Podcast;
  cursor: Scalars['String'];
};

export type PodcastEpisode = {
  __typename?: 'PodcastEpisode';
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  episodeNumber?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Int']>;
  thumbnail?: Maybe<Scalars['String']>;
  podcast: Podcast;
  thirdPartyId?: Maybe<Scalars['String']>;
  season?: Maybe<Season>;
  status: PodcastEpisodeStatus;
  algoliaId?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
  audio?: Maybe<Scalars['String']>;
  audioState?: Maybe<Scalars['String']>;
  audioMuxAssetId?: Maybe<Scalars['String']>;
  audioMuxPlaybackId?: Maybe<Scalars['String']>;
  audioUploadId?: Maybe<Scalars['String']>;
  audioPlaybackPolicy?: Maybe<Scalars['String']>;
  durationWithAds?: Maybe<Scalars['Float']>;
  audioWithAds?: Maybe<Scalars['String']>;
  audioWithAdsState?: Maybe<Scalars['String']>;
  audioWithAdsMuxAssetId?: Maybe<Scalars['String']>;
  audioWithAdsMuxPlaybackId?: Maybe<Scalars['String']>;
  audioWithAdsUploadId?: Maybe<Scalars['String']>;
  audioWithAdsPlaybackPolicy?: Maybe<Scalars['String']>;
  audioAccess: Array<Scalars['String']>;
  audioWithAdsAccess: Array<Scalars['String']>;
  allowedContinents: Array<Scalars['String']>;
  allowedCountries?: Maybe<Scalars['Json']>;
  scheduleAt?: Maybe<Scalars['DateTime']>;
  publishDate?: Maybe<Scalars['DateTime']>;
  createdBy: User;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  carouselPodcastEpisodeOrder?: Maybe<Scalars['Int']>;
  carouselPodcastEpisodeId?: Maybe<Scalars['ID']>;
  listenTime?: Maybe<Scalars['Float']>;
};

export type PodcastEpisodeCarousel = {
  __typename?: 'PodcastEpisodeCarousel';
  id?: Maybe<Scalars['ID']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  nextOrder?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  podcastEpisodes?: Maybe<Array<Maybe<PodcastEpisode>>>;
  layout?: Maybe<Scalars['String']>;
};

export type PodcastEpisodeCarouselCustomInput = {
  podcastEpisodeId: Scalars['ID'];
  order?: Maybe<Scalars['Int']>;
};

export type PodcastEpisodeCarouselModuleCustomInput = {
  title: Scalars['String'];
  order: Scalars['Int'];
  isPublished?: Maybe<Scalars['Boolean']>;
  carouselPodcastEpisodes?: Maybe<Array<Maybe<PodcastEpisodeCarouselCustomInput>>>;
};

export type PodcastEpisodeCarouselModulePodcastEpisodes = {
  __typename?: 'PodcastEpisodeCarouselModulePodcastEpisodes';
  id: Scalars['ID'];
  order?: Maybe<Scalars['Int']>;
  podcastEpisode?: Maybe<PodcastEpisode>;
  podcastEpisodeCarousel?: Maybe<CarouselModule>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type PodcastEpisodeCarouselModulePodcastEpisodesConnection = {
  __typename?: 'PodcastEpisodeCarouselModulePodcastEpisodesConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<PodcastEpisodeCarouselModulePodcastEpisodesEdge>>;
  aggregate: AggregatePodcastEpisodeCarouselModulePodcastEpisodes;
};

export type PodcastEpisodeCarouselModulePodcastEpisodesCreateInput = {
  id?: Maybe<Scalars['ID']>;
  order?: Maybe<Scalars['Int']>;
  podcastEpisode?: Maybe<PodcastEpisodeCreateOneInput>;
  podcastEpisodeCarousel?: Maybe<CarouselModuleCreateOneWithoutCarouselPodcastEpisodesInput>;
};

export type PodcastEpisodeCarouselModulePodcastEpisodesCreateManyWithoutPodcastEpisodeCarouselInput = {
  create?: Maybe<Array<PodcastEpisodeCarouselModulePodcastEpisodesCreateWithoutPodcastEpisodeCarouselInput>>;
  connect?: Maybe<Array<PodcastEpisodeCarouselModulePodcastEpisodesWhereUniqueInput>>;
};

export type PodcastEpisodeCarouselModulePodcastEpisodesCreateWithoutPodcastEpisodeCarouselInput = {
  id?: Maybe<Scalars['ID']>;
  order?: Maybe<Scalars['Int']>;
  podcastEpisode?: Maybe<PodcastEpisodeCreateOneInput>;
};

export type PodcastEpisodeCarouselModulePodcastEpisodesEdge = {
  __typename?: 'PodcastEpisodeCarouselModulePodcastEpisodesEdge';
  node: PodcastEpisodeCarouselModulePodcastEpisodes;
  cursor: Scalars['String'];
};

export enum PodcastEpisodeCarouselModulePodcastEpisodesOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  OrderAsc = 'order_ASC',
  OrderDesc = 'order_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type PodcastEpisodeCarouselModulePodcastEpisodesPreviousValues = {
  __typename?: 'PodcastEpisodeCarouselModulePodcastEpisodesPreviousValues';
  id: Scalars['ID'];
  order?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type PodcastEpisodeCarouselModulePodcastEpisodesScalarWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  order?: Maybe<Scalars['Int']>;
  order_not?: Maybe<Scalars['Int']>;
  order_in?: Maybe<Array<Scalars['Int']>>;
  order_not_in?: Maybe<Array<Scalars['Int']>>;
  order_lt?: Maybe<Scalars['Int']>;
  order_lte?: Maybe<Scalars['Int']>;
  order_gt?: Maybe<Scalars['Int']>;
  order_gte?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<PodcastEpisodeCarouselModulePodcastEpisodesScalarWhereInput>>;
  OR?: Maybe<Array<PodcastEpisodeCarouselModulePodcastEpisodesScalarWhereInput>>;
  NOT?: Maybe<Array<PodcastEpisodeCarouselModulePodcastEpisodesScalarWhereInput>>;
};

export type PodcastEpisodeCarouselModulePodcastEpisodesSubscriptionPayload = {
  __typename?: 'PodcastEpisodeCarouselModulePodcastEpisodesSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<PodcastEpisodeCarouselModulePodcastEpisodes>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<PodcastEpisodeCarouselModulePodcastEpisodesPreviousValues>;
};

export type PodcastEpisodeCarouselModulePodcastEpisodesSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<PodcastEpisodeCarouselModulePodcastEpisodesWhereInput>;
  AND?: Maybe<Array<PodcastEpisodeCarouselModulePodcastEpisodesSubscriptionWhereInput>>;
  OR?: Maybe<Array<PodcastEpisodeCarouselModulePodcastEpisodesSubscriptionWhereInput>>;
  NOT?: Maybe<Array<PodcastEpisodeCarouselModulePodcastEpisodesSubscriptionWhereInput>>;
};

export type PodcastEpisodeCarouselModulePodcastEpisodesUpdateInput = {
  order?: Maybe<Scalars['Int']>;
  podcastEpisode?: Maybe<PodcastEpisodeUpdateOneInput>;
  podcastEpisodeCarousel?: Maybe<CarouselModuleUpdateOneWithoutCarouselPodcastEpisodesInput>;
};

export type PodcastEpisodeCarouselModulePodcastEpisodesUpdateManyDataInput = {
  order?: Maybe<Scalars['Int']>;
};

export type PodcastEpisodeCarouselModulePodcastEpisodesUpdateManyMutationInput = {
  order?: Maybe<Scalars['Int']>;
};

export type PodcastEpisodeCarouselModulePodcastEpisodesUpdateManyWithWhereNestedInput = {
  where: PodcastEpisodeCarouselModulePodcastEpisodesScalarWhereInput;
  data: PodcastEpisodeCarouselModulePodcastEpisodesUpdateManyDataInput;
};

export type PodcastEpisodeCarouselModulePodcastEpisodesUpdateManyWithoutPodcastEpisodeCarouselInput = {
  create?: Maybe<Array<PodcastEpisodeCarouselModulePodcastEpisodesCreateWithoutPodcastEpisodeCarouselInput>>;
  delete?: Maybe<Array<PodcastEpisodeCarouselModulePodcastEpisodesWhereUniqueInput>>;
  connect?: Maybe<Array<PodcastEpisodeCarouselModulePodcastEpisodesWhereUniqueInput>>;
  set?: Maybe<Array<PodcastEpisodeCarouselModulePodcastEpisodesWhereUniqueInput>>;
  disconnect?: Maybe<Array<PodcastEpisodeCarouselModulePodcastEpisodesWhereUniqueInput>>;
  update?: Maybe<Array<PodcastEpisodeCarouselModulePodcastEpisodesUpdateWithWhereUniqueWithoutPodcastEpisodeCarouselInput>>;
  upsert?: Maybe<Array<PodcastEpisodeCarouselModulePodcastEpisodesUpsertWithWhereUniqueWithoutPodcastEpisodeCarouselInput>>;
  deleteMany?: Maybe<Array<PodcastEpisodeCarouselModulePodcastEpisodesScalarWhereInput>>;
  updateMany?: Maybe<Array<PodcastEpisodeCarouselModulePodcastEpisodesUpdateManyWithWhereNestedInput>>;
};

export type PodcastEpisodeCarouselModulePodcastEpisodesUpdateWithWhereUniqueWithoutPodcastEpisodeCarouselInput = {
  where: PodcastEpisodeCarouselModulePodcastEpisodesWhereUniqueInput;
  data: PodcastEpisodeCarouselModulePodcastEpisodesUpdateWithoutPodcastEpisodeCarouselDataInput;
};

export type PodcastEpisodeCarouselModulePodcastEpisodesUpdateWithoutPodcastEpisodeCarouselDataInput = {
  order?: Maybe<Scalars['Int']>;
  podcastEpisode?: Maybe<PodcastEpisodeUpdateOneInput>;
};

export type PodcastEpisodeCarouselModulePodcastEpisodesUpsertWithWhereUniqueWithoutPodcastEpisodeCarouselInput = {
  where: PodcastEpisodeCarouselModulePodcastEpisodesWhereUniqueInput;
  update: PodcastEpisodeCarouselModulePodcastEpisodesUpdateWithoutPodcastEpisodeCarouselDataInput;
  create: PodcastEpisodeCarouselModulePodcastEpisodesCreateWithoutPodcastEpisodeCarouselInput;
};

export type PodcastEpisodeCarouselModulePodcastEpisodesWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  order?: Maybe<Scalars['Int']>;
  order_not?: Maybe<Scalars['Int']>;
  order_in?: Maybe<Array<Scalars['Int']>>;
  order_not_in?: Maybe<Array<Scalars['Int']>>;
  order_lt?: Maybe<Scalars['Int']>;
  order_lte?: Maybe<Scalars['Int']>;
  order_gt?: Maybe<Scalars['Int']>;
  order_gte?: Maybe<Scalars['Int']>;
  podcastEpisode?: Maybe<PodcastEpisodeWhereInput>;
  podcastEpisodeCarousel?: Maybe<CarouselModuleWhereInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<PodcastEpisodeCarouselModulePodcastEpisodesWhereInput>>;
  OR?: Maybe<Array<PodcastEpisodeCarouselModulePodcastEpisodesWhereInput>>;
  NOT?: Maybe<Array<PodcastEpisodeCarouselModulePodcastEpisodesWhereInput>>;
};

export type PodcastEpisodeCarouselModulePodcastEpisodesWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type PodcastEpisodeConnection = {
  __typename?: 'PodcastEpisodeConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<PodcastEpisodeEdge>>;
  aggregate: AggregatePodcastEpisode;
};

export type PodcastEpisodeCreateInput = {
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  episodeNumber?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Int']>;
  thumbnail?: Maybe<Scalars['String']>;
  podcast: PodcastCreateOneWithoutPodcastEpisodesInput;
  thirdPartyId?: Maybe<Scalars['String']>;
  season?: Maybe<SeasonCreateOneWithoutPodcastEpisodesInput>;
  status?: Maybe<PodcastEpisodeStatus>;
  algoliaId?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
  audio?: Maybe<Scalars['String']>;
  audioState?: Maybe<Scalars['String']>;
  audioMuxAssetId?: Maybe<Scalars['String']>;
  audioMuxPlaybackId?: Maybe<Scalars['String']>;
  audioUploadId?: Maybe<Scalars['String']>;
  audioPlaybackPolicy?: Maybe<Scalars['String']>;
  durationWithAds?: Maybe<Scalars['Float']>;
  audioWithAds?: Maybe<Scalars['String']>;
  audioWithAdsState?: Maybe<Scalars['String']>;
  audioWithAdsMuxAssetId?: Maybe<Scalars['String']>;
  audioWithAdsMuxPlaybackId?: Maybe<Scalars['String']>;
  audioWithAdsUploadId?: Maybe<Scalars['String']>;
  audioWithAdsPlaybackPolicy?: Maybe<Scalars['String']>;
  audioAccess?: Maybe<PodcastEpisodeCreateaudioAccessInput>;
  audioWithAdsAccess?: Maybe<PodcastEpisodeCreateaudioWithAdsAccessInput>;
  allowedContinents?: Maybe<PodcastEpisodeCreateallowedContinentsInput>;
  allowedCountries?: Maybe<Scalars['Json']>;
  scheduleAt?: Maybe<Scalars['DateTime']>;
  publishDate?: Maybe<Scalars['DateTime']>;
  createdBy: UserCreateOneInput;
};

export type PodcastEpisodeCreateInputCustom = {
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  episodeNumber?: Maybe<Scalars['String']>;
  publishDate?: Maybe<Scalars['DateTime']>;
  rating?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Int']>;
  thumbnail?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['ID']>;
  podcastId?: Maybe<Scalars['ID']>;
  audioWithAdsAccess?: Maybe<PodcastEpisodeCreateaudioWithAdsAccessInput>;
  audioAccess?: Maybe<PodcastEpisodeCreateaudioAccessInput>;
  allowedContinents?: Maybe<PodcastEpisodeCreateallowedContinentsInput>;
  allowedCountries?: Maybe<Array<Maybe<Scalars['String']>>>;
  scheduleAt?: Maybe<Scalars['DateTime']>;
};

export type PodcastEpisodeCreateManyWithoutPodcastInput = {
  create?: Maybe<Array<PodcastEpisodeCreateWithoutPodcastInput>>;
  connect?: Maybe<Array<PodcastEpisodeWhereUniqueInput>>;
};

export type PodcastEpisodeCreateManyWithoutSeasonInput = {
  create?: Maybe<Array<PodcastEpisodeCreateWithoutSeasonInput>>;
  connect?: Maybe<Array<PodcastEpisodeWhereUniqueInput>>;
};

export type PodcastEpisodeCreateOneInput = {
  create?: Maybe<PodcastEpisodeCreateInput>;
  connect?: Maybe<PodcastEpisodeWhereUniqueInput>;
};

export type PodcastEpisodeCreateWithoutPodcastInput = {
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  episodeNumber?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Int']>;
  thumbnail?: Maybe<Scalars['String']>;
  thirdPartyId?: Maybe<Scalars['String']>;
  season?: Maybe<SeasonCreateOneWithoutPodcastEpisodesInput>;
  status?: Maybe<PodcastEpisodeStatus>;
  algoliaId?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
  audio?: Maybe<Scalars['String']>;
  audioState?: Maybe<Scalars['String']>;
  audioMuxAssetId?: Maybe<Scalars['String']>;
  audioMuxPlaybackId?: Maybe<Scalars['String']>;
  audioUploadId?: Maybe<Scalars['String']>;
  audioPlaybackPolicy?: Maybe<Scalars['String']>;
  durationWithAds?: Maybe<Scalars['Float']>;
  audioWithAds?: Maybe<Scalars['String']>;
  audioWithAdsState?: Maybe<Scalars['String']>;
  audioWithAdsMuxAssetId?: Maybe<Scalars['String']>;
  audioWithAdsMuxPlaybackId?: Maybe<Scalars['String']>;
  audioWithAdsUploadId?: Maybe<Scalars['String']>;
  audioWithAdsPlaybackPolicy?: Maybe<Scalars['String']>;
  audioAccess?: Maybe<PodcastEpisodeCreateaudioAccessInput>;
  audioWithAdsAccess?: Maybe<PodcastEpisodeCreateaudioWithAdsAccessInput>;
  allowedContinents?: Maybe<PodcastEpisodeCreateallowedContinentsInput>;
  allowedCountries?: Maybe<Scalars['Json']>;
  scheduleAt?: Maybe<Scalars['DateTime']>;
  publishDate?: Maybe<Scalars['DateTime']>;
  createdBy: UserCreateOneInput;
};

export type PodcastEpisodeCreateWithoutSeasonInput = {
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  episodeNumber?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Int']>;
  thumbnail?: Maybe<Scalars['String']>;
  podcast: PodcastCreateOneWithoutPodcastEpisodesInput;
  thirdPartyId?: Maybe<Scalars['String']>;
  status?: Maybe<PodcastEpisodeStatus>;
  algoliaId?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
  audio?: Maybe<Scalars['String']>;
  audioState?: Maybe<Scalars['String']>;
  audioMuxAssetId?: Maybe<Scalars['String']>;
  audioMuxPlaybackId?: Maybe<Scalars['String']>;
  audioUploadId?: Maybe<Scalars['String']>;
  audioPlaybackPolicy?: Maybe<Scalars['String']>;
  durationWithAds?: Maybe<Scalars['Float']>;
  audioWithAds?: Maybe<Scalars['String']>;
  audioWithAdsState?: Maybe<Scalars['String']>;
  audioWithAdsMuxAssetId?: Maybe<Scalars['String']>;
  audioWithAdsMuxPlaybackId?: Maybe<Scalars['String']>;
  audioWithAdsUploadId?: Maybe<Scalars['String']>;
  audioWithAdsPlaybackPolicy?: Maybe<Scalars['String']>;
  audioAccess?: Maybe<PodcastEpisodeCreateaudioAccessInput>;
  audioWithAdsAccess?: Maybe<PodcastEpisodeCreateaudioWithAdsAccessInput>;
  allowedContinents?: Maybe<PodcastEpisodeCreateallowedContinentsInput>;
  allowedCountries?: Maybe<Scalars['Json']>;
  scheduleAt?: Maybe<Scalars['DateTime']>;
  publishDate?: Maybe<Scalars['DateTime']>;
  createdBy: UserCreateOneInput;
};

export type PodcastEpisodeCreateallowedContinentsInput = {
  set?: Maybe<Array<Scalars['String']>>;
};

export type PodcastEpisodeCreateaudioAccessInput = {
  set?: Maybe<Array<Scalars['String']>>;
};

export type PodcastEpisodeCreateaudioWithAdsAccessInput = {
  set?: Maybe<Array<Scalars['String']>>;
};

export type PodcastEpisodeDataInput = {
  id: Scalars['String'];
};

export type PodcastEpisodeEdge = {
  __typename?: 'PodcastEpisodeEdge';
  node: PodcastEpisode;
  cursor: Scalars['String'];
};

export enum PodcastEpisodeOrderBy {
  CreatedAtDesc = 'createdAt_DESC',
  PublishDateDesc = 'publishDate_DESC'
}

export enum PodcastEpisodeOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  EpisodeNumberAsc = 'episodeNumber_ASC',
  EpisodeNumberDesc = 'episodeNumber_DESC',
  WeightAsc = 'weight_ASC',
  WeightDesc = 'weight_DESC',
  ThumbnailAsc = 'thumbnail_ASC',
  ThumbnailDesc = 'thumbnail_DESC',
  ThirdPartyIdAsc = 'thirdPartyId_ASC',
  ThirdPartyIdDesc = 'thirdPartyId_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  AlgoliaIdAsc = 'algoliaId_ASC',
  AlgoliaIdDesc = 'algoliaId_DESC',
  RatingAsc = 'rating_ASC',
  RatingDesc = 'rating_DESC',
  DurationAsc = 'duration_ASC',
  DurationDesc = 'duration_DESC',
  AudioAsc = 'audio_ASC',
  AudioDesc = 'audio_DESC',
  AudioStateAsc = 'audioState_ASC',
  AudioStateDesc = 'audioState_DESC',
  AudioMuxAssetIdAsc = 'audioMuxAssetId_ASC',
  AudioMuxAssetIdDesc = 'audioMuxAssetId_DESC',
  AudioMuxPlaybackIdAsc = 'audioMuxPlaybackId_ASC',
  AudioMuxPlaybackIdDesc = 'audioMuxPlaybackId_DESC',
  AudioUploadIdAsc = 'audioUploadId_ASC',
  AudioUploadIdDesc = 'audioUploadId_DESC',
  AudioPlaybackPolicyAsc = 'audioPlaybackPolicy_ASC',
  AudioPlaybackPolicyDesc = 'audioPlaybackPolicy_DESC',
  DurationWithAdsAsc = 'durationWithAds_ASC',
  DurationWithAdsDesc = 'durationWithAds_DESC',
  AudioWithAdsAsc = 'audioWithAds_ASC',
  AudioWithAdsDesc = 'audioWithAds_DESC',
  AudioWithAdsStateAsc = 'audioWithAdsState_ASC',
  AudioWithAdsStateDesc = 'audioWithAdsState_DESC',
  AudioWithAdsMuxAssetIdAsc = 'audioWithAdsMuxAssetId_ASC',
  AudioWithAdsMuxAssetIdDesc = 'audioWithAdsMuxAssetId_DESC',
  AudioWithAdsMuxPlaybackIdAsc = 'audioWithAdsMuxPlaybackId_ASC',
  AudioWithAdsMuxPlaybackIdDesc = 'audioWithAdsMuxPlaybackId_DESC',
  AudioWithAdsUploadIdAsc = 'audioWithAdsUploadId_ASC',
  AudioWithAdsUploadIdDesc = 'audioWithAdsUploadId_DESC',
  AudioWithAdsPlaybackPolicyAsc = 'audioWithAdsPlaybackPolicy_ASC',
  AudioWithAdsPlaybackPolicyDesc = 'audioWithAdsPlaybackPolicy_DESC',
  AllowedCountriesAsc = 'allowedCountries_ASC',
  AllowedCountriesDesc = 'allowedCountries_DESC',
  ScheduleAtAsc = 'scheduleAt_ASC',
  ScheduleAtDesc = 'scheduleAt_DESC',
  PublishDateAsc = 'publishDate_ASC',
  PublishDateDesc = 'publishDate_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type PodcastEpisodePremiere = {
  __typename?: 'PodcastEpisodePremiere';
  id?: Maybe<Scalars['ID']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  premiereImage?: Maybe<Scalars['String']>;
  podcastEpisode?: Maybe<PodcastEpisode>;
};

export type PodcastEpisodePreviousValues = {
  __typename?: 'PodcastEpisodePreviousValues';
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  episodeNumber?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Int']>;
  thumbnail?: Maybe<Scalars['String']>;
  thirdPartyId?: Maybe<Scalars['String']>;
  status: PodcastEpisodeStatus;
  algoliaId?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
  audio?: Maybe<Scalars['String']>;
  audioState?: Maybe<Scalars['String']>;
  audioMuxAssetId?: Maybe<Scalars['String']>;
  audioMuxPlaybackId?: Maybe<Scalars['String']>;
  audioUploadId?: Maybe<Scalars['String']>;
  audioPlaybackPolicy?: Maybe<Scalars['String']>;
  durationWithAds?: Maybe<Scalars['Float']>;
  audioWithAds?: Maybe<Scalars['String']>;
  audioWithAdsState?: Maybe<Scalars['String']>;
  audioWithAdsMuxAssetId?: Maybe<Scalars['String']>;
  audioWithAdsMuxPlaybackId?: Maybe<Scalars['String']>;
  audioWithAdsUploadId?: Maybe<Scalars['String']>;
  audioWithAdsPlaybackPolicy?: Maybe<Scalars['String']>;
  audioAccess: Array<Scalars['String']>;
  audioWithAdsAccess: Array<Scalars['String']>;
  allowedContinents: Array<Scalars['String']>;
  allowedCountries?: Maybe<Scalars['Json']>;
  scheduleAt?: Maybe<Scalars['DateTime']>;
  publishDate?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type PodcastEpisodeScalarWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Scalars['String']>>;
  title_not_in?: Maybe<Array<Scalars['String']>>;
  title_lt?: Maybe<Scalars['String']>;
  title_lte?: Maybe<Scalars['String']>;
  title_gt?: Maybe<Scalars['String']>;
  title_gte?: Maybe<Scalars['String']>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  title_starts_with?: Maybe<Scalars['String']>;
  title_not_starts_with?: Maybe<Scalars['String']>;
  title_ends_with?: Maybe<Scalars['String']>;
  title_not_ends_with?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  description_not?: Maybe<Scalars['String']>;
  description_in?: Maybe<Array<Scalars['String']>>;
  description_not_in?: Maybe<Array<Scalars['String']>>;
  description_lt?: Maybe<Scalars['String']>;
  description_lte?: Maybe<Scalars['String']>;
  description_gt?: Maybe<Scalars['String']>;
  description_gte?: Maybe<Scalars['String']>;
  description_contains?: Maybe<Scalars['String']>;
  description_not_contains?: Maybe<Scalars['String']>;
  description_starts_with?: Maybe<Scalars['String']>;
  description_not_starts_with?: Maybe<Scalars['String']>;
  description_ends_with?: Maybe<Scalars['String']>;
  description_not_ends_with?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  slug_not?: Maybe<Scalars['String']>;
  slug_in?: Maybe<Array<Scalars['String']>>;
  slug_not_in?: Maybe<Array<Scalars['String']>>;
  slug_lt?: Maybe<Scalars['String']>;
  slug_lte?: Maybe<Scalars['String']>;
  slug_gt?: Maybe<Scalars['String']>;
  slug_gte?: Maybe<Scalars['String']>;
  slug_contains?: Maybe<Scalars['String']>;
  slug_not_contains?: Maybe<Scalars['String']>;
  slug_starts_with?: Maybe<Scalars['String']>;
  slug_not_starts_with?: Maybe<Scalars['String']>;
  slug_ends_with?: Maybe<Scalars['String']>;
  slug_not_ends_with?: Maybe<Scalars['String']>;
  episodeNumber?: Maybe<Scalars['String']>;
  episodeNumber_not?: Maybe<Scalars['String']>;
  episodeNumber_in?: Maybe<Array<Scalars['String']>>;
  episodeNumber_not_in?: Maybe<Array<Scalars['String']>>;
  episodeNumber_lt?: Maybe<Scalars['String']>;
  episodeNumber_lte?: Maybe<Scalars['String']>;
  episodeNumber_gt?: Maybe<Scalars['String']>;
  episodeNumber_gte?: Maybe<Scalars['String']>;
  episodeNumber_contains?: Maybe<Scalars['String']>;
  episodeNumber_not_contains?: Maybe<Scalars['String']>;
  episodeNumber_starts_with?: Maybe<Scalars['String']>;
  episodeNumber_not_starts_with?: Maybe<Scalars['String']>;
  episodeNumber_ends_with?: Maybe<Scalars['String']>;
  episodeNumber_not_ends_with?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Int']>;
  weight_not?: Maybe<Scalars['Int']>;
  weight_in?: Maybe<Array<Scalars['Int']>>;
  weight_not_in?: Maybe<Array<Scalars['Int']>>;
  weight_lt?: Maybe<Scalars['Int']>;
  weight_lte?: Maybe<Scalars['Int']>;
  weight_gt?: Maybe<Scalars['Int']>;
  weight_gte?: Maybe<Scalars['Int']>;
  thumbnail?: Maybe<Scalars['String']>;
  thumbnail_not?: Maybe<Scalars['String']>;
  thumbnail_in?: Maybe<Array<Scalars['String']>>;
  thumbnail_not_in?: Maybe<Array<Scalars['String']>>;
  thumbnail_lt?: Maybe<Scalars['String']>;
  thumbnail_lte?: Maybe<Scalars['String']>;
  thumbnail_gt?: Maybe<Scalars['String']>;
  thumbnail_gte?: Maybe<Scalars['String']>;
  thumbnail_contains?: Maybe<Scalars['String']>;
  thumbnail_not_contains?: Maybe<Scalars['String']>;
  thumbnail_starts_with?: Maybe<Scalars['String']>;
  thumbnail_not_starts_with?: Maybe<Scalars['String']>;
  thumbnail_ends_with?: Maybe<Scalars['String']>;
  thumbnail_not_ends_with?: Maybe<Scalars['String']>;
  thirdPartyId?: Maybe<Scalars['String']>;
  thirdPartyId_not?: Maybe<Scalars['String']>;
  thirdPartyId_in?: Maybe<Array<Scalars['String']>>;
  thirdPartyId_not_in?: Maybe<Array<Scalars['String']>>;
  thirdPartyId_lt?: Maybe<Scalars['String']>;
  thirdPartyId_lte?: Maybe<Scalars['String']>;
  thirdPartyId_gt?: Maybe<Scalars['String']>;
  thirdPartyId_gte?: Maybe<Scalars['String']>;
  thirdPartyId_contains?: Maybe<Scalars['String']>;
  thirdPartyId_not_contains?: Maybe<Scalars['String']>;
  thirdPartyId_starts_with?: Maybe<Scalars['String']>;
  thirdPartyId_not_starts_with?: Maybe<Scalars['String']>;
  thirdPartyId_ends_with?: Maybe<Scalars['String']>;
  thirdPartyId_not_ends_with?: Maybe<Scalars['String']>;
  status?: Maybe<PodcastEpisodeStatus>;
  status_not?: Maybe<PodcastEpisodeStatus>;
  status_in?: Maybe<Array<PodcastEpisodeStatus>>;
  status_not_in?: Maybe<Array<PodcastEpisodeStatus>>;
  algoliaId?: Maybe<Scalars['String']>;
  algoliaId_not?: Maybe<Scalars['String']>;
  algoliaId_in?: Maybe<Array<Scalars['String']>>;
  algoliaId_not_in?: Maybe<Array<Scalars['String']>>;
  algoliaId_lt?: Maybe<Scalars['String']>;
  algoliaId_lte?: Maybe<Scalars['String']>;
  algoliaId_gt?: Maybe<Scalars['String']>;
  algoliaId_gte?: Maybe<Scalars['String']>;
  algoliaId_contains?: Maybe<Scalars['String']>;
  algoliaId_not_contains?: Maybe<Scalars['String']>;
  algoliaId_starts_with?: Maybe<Scalars['String']>;
  algoliaId_not_starts_with?: Maybe<Scalars['String']>;
  algoliaId_ends_with?: Maybe<Scalars['String']>;
  algoliaId_not_ends_with?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['String']>;
  rating_not?: Maybe<Scalars['String']>;
  rating_in?: Maybe<Array<Scalars['String']>>;
  rating_not_in?: Maybe<Array<Scalars['String']>>;
  rating_lt?: Maybe<Scalars['String']>;
  rating_lte?: Maybe<Scalars['String']>;
  rating_gt?: Maybe<Scalars['String']>;
  rating_gte?: Maybe<Scalars['String']>;
  rating_contains?: Maybe<Scalars['String']>;
  rating_not_contains?: Maybe<Scalars['String']>;
  rating_starts_with?: Maybe<Scalars['String']>;
  rating_not_starts_with?: Maybe<Scalars['String']>;
  rating_ends_with?: Maybe<Scalars['String']>;
  rating_not_ends_with?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
  duration_not?: Maybe<Scalars['Float']>;
  duration_in?: Maybe<Array<Scalars['Float']>>;
  duration_not_in?: Maybe<Array<Scalars['Float']>>;
  duration_lt?: Maybe<Scalars['Float']>;
  duration_lte?: Maybe<Scalars['Float']>;
  duration_gt?: Maybe<Scalars['Float']>;
  duration_gte?: Maybe<Scalars['Float']>;
  audio?: Maybe<Scalars['String']>;
  audio_not?: Maybe<Scalars['String']>;
  audio_in?: Maybe<Array<Scalars['String']>>;
  audio_not_in?: Maybe<Array<Scalars['String']>>;
  audio_lt?: Maybe<Scalars['String']>;
  audio_lte?: Maybe<Scalars['String']>;
  audio_gt?: Maybe<Scalars['String']>;
  audio_gte?: Maybe<Scalars['String']>;
  audio_contains?: Maybe<Scalars['String']>;
  audio_not_contains?: Maybe<Scalars['String']>;
  audio_starts_with?: Maybe<Scalars['String']>;
  audio_not_starts_with?: Maybe<Scalars['String']>;
  audio_ends_with?: Maybe<Scalars['String']>;
  audio_not_ends_with?: Maybe<Scalars['String']>;
  audioState?: Maybe<Scalars['String']>;
  audioState_not?: Maybe<Scalars['String']>;
  audioState_in?: Maybe<Array<Scalars['String']>>;
  audioState_not_in?: Maybe<Array<Scalars['String']>>;
  audioState_lt?: Maybe<Scalars['String']>;
  audioState_lte?: Maybe<Scalars['String']>;
  audioState_gt?: Maybe<Scalars['String']>;
  audioState_gte?: Maybe<Scalars['String']>;
  audioState_contains?: Maybe<Scalars['String']>;
  audioState_not_contains?: Maybe<Scalars['String']>;
  audioState_starts_with?: Maybe<Scalars['String']>;
  audioState_not_starts_with?: Maybe<Scalars['String']>;
  audioState_ends_with?: Maybe<Scalars['String']>;
  audioState_not_ends_with?: Maybe<Scalars['String']>;
  audioMuxAssetId?: Maybe<Scalars['String']>;
  audioMuxAssetId_not?: Maybe<Scalars['String']>;
  audioMuxAssetId_in?: Maybe<Array<Scalars['String']>>;
  audioMuxAssetId_not_in?: Maybe<Array<Scalars['String']>>;
  audioMuxAssetId_lt?: Maybe<Scalars['String']>;
  audioMuxAssetId_lte?: Maybe<Scalars['String']>;
  audioMuxAssetId_gt?: Maybe<Scalars['String']>;
  audioMuxAssetId_gte?: Maybe<Scalars['String']>;
  audioMuxAssetId_contains?: Maybe<Scalars['String']>;
  audioMuxAssetId_not_contains?: Maybe<Scalars['String']>;
  audioMuxAssetId_starts_with?: Maybe<Scalars['String']>;
  audioMuxAssetId_not_starts_with?: Maybe<Scalars['String']>;
  audioMuxAssetId_ends_with?: Maybe<Scalars['String']>;
  audioMuxAssetId_not_ends_with?: Maybe<Scalars['String']>;
  audioMuxPlaybackId?: Maybe<Scalars['String']>;
  audioMuxPlaybackId_not?: Maybe<Scalars['String']>;
  audioMuxPlaybackId_in?: Maybe<Array<Scalars['String']>>;
  audioMuxPlaybackId_not_in?: Maybe<Array<Scalars['String']>>;
  audioMuxPlaybackId_lt?: Maybe<Scalars['String']>;
  audioMuxPlaybackId_lte?: Maybe<Scalars['String']>;
  audioMuxPlaybackId_gt?: Maybe<Scalars['String']>;
  audioMuxPlaybackId_gte?: Maybe<Scalars['String']>;
  audioMuxPlaybackId_contains?: Maybe<Scalars['String']>;
  audioMuxPlaybackId_not_contains?: Maybe<Scalars['String']>;
  audioMuxPlaybackId_starts_with?: Maybe<Scalars['String']>;
  audioMuxPlaybackId_not_starts_with?: Maybe<Scalars['String']>;
  audioMuxPlaybackId_ends_with?: Maybe<Scalars['String']>;
  audioMuxPlaybackId_not_ends_with?: Maybe<Scalars['String']>;
  audioUploadId?: Maybe<Scalars['String']>;
  audioUploadId_not?: Maybe<Scalars['String']>;
  audioUploadId_in?: Maybe<Array<Scalars['String']>>;
  audioUploadId_not_in?: Maybe<Array<Scalars['String']>>;
  audioUploadId_lt?: Maybe<Scalars['String']>;
  audioUploadId_lte?: Maybe<Scalars['String']>;
  audioUploadId_gt?: Maybe<Scalars['String']>;
  audioUploadId_gte?: Maybe<Scalars['String']>;
  audioUploadId_contains?: Maybe<Scalars['String']>;
  audioUploadId_not_contains?: Maybe<Scalars['String']>;
  audioUploadId_starts_with?: Maybe<Scalars['String']>;
  audioUploadId_not_starts_with?: Maybe<Scalars['String']>;
  audioUploadId_ends_with?: Maybe<Scalars['String']>;
  audioUploadId_not_ends_with?: Maybe<Scalars['String']>;
  audioPlaybackPolicy?: Maybe<Scalars['String']>;
  audioPlaybackPolicy_not?: Maybe<Scalars['String']>;
  audioPlaybackPolicy_in?: Maybe<Array<Scalars['String']>>;
  audioPlaybackPolicy_not_in?: Maybe<Array<Scalars['String']>>;
  audioPlaybackPolicy_lt?: Maybe<Scalars['String']>;
  audioPlaybackPolicy_lte?: Maybe<Scalars['String']>;
  audioPlaybackPolicy_gt?: Maybe<Scalars['String']>;
  audioPlaybackPolicy_gte?: Maybe<Scalars['String']>;
  audioPlaybackPolicy_contains?: Maybe<Scalars['String']>;
  audioPlaybackPolicy_not_contains?: Maybe<Scalars['String']>;
  audioPlaybackPolicy_starts_with?: Maybe<Scalars['String']>;
  audioPlaybackPolicy_not_starts_with?: Maybe<Scalars['String']>;
  audioPlaybackPolicy_ends_with?: Maybe<Scalars['String']>;
  audioPlaybackPolicy_not_ends_with?: Maybe<Scalars['String']>;
  durationWithAds?: Maybe<Scalars['Float']>;
  durationWithAds_not?: Maybe<Scalars['Float']>;
  durationWithAds_in?: Maybe<Array<Scalars['Float']>>;
  durationWithAds_not_in?: Maybe<Array<Scalars['Float']>>;
  durationWithAds_lt?: Maybe<Scalars['Float']>;
  durationWithAds_lte?: Maybe<Scalars['Float']>;
  durationWithAds_gt?: Maybe<Scalars['Float']>;
  durationWithAds_gte?: Maybe<Scalars['Float']>;
  audioWithAds?: Maybe<Scalars['String']>;
  audioWithAds_not?: Maybe<Scalars['String']>;
  audioWithAds_in?: Maybe<Array<Scalars['String']>>;
  audioWithAds_not_in?: Maybe<Array<Scalars['String']>>;
  audioWithAds_lt?: Maybe<Scalars['String']>;
  audioWithAds_lte?: Maybe<Scalars['String']>;
  audioWithAds_gt?: Maybe<Scalars['String']>;
  audioWithAds_gte?: Maybe<Scalars['String']>;
  audioWithAds_contains?: Maybe<Scalars['String']>;
  audioWithAds_not_contains?: Maybe<Scalars['String']>;
  audioWithAds_starts_with?: Maybe<Scalars['String']>;
  audioWithAds_not_starts_with?: Maybe<Scalars['String']>;
  audioWithAds_ends_with?: Maybe<Scalars['String']>;
  audioWithAds_not_ends_with?: Maybe<Scalars['String']>;
  audioWithAdsState?: Maybe<Scalars['String']>;
  audioWithAdsState_not?: Maybe<Scalars['String']>;
  audioWithAdsState_in?: Maybe<Array<Scalars['String']>>;
  audioWithAdsState_not_in?: Maybe<Array<Scalars['String']>>;
  audioWithAdsState_lt?: Maybe<Scalars['String']>;
  audioWithAdsState_lte?: Maybe<Scalars['String']>;
  audioWithAdsState_gt?: Maybe<Scalars['String']>;
  audioWithAdsState_gte?: Maybe<Scalars['String']>;
  audioWithAdsState_contains?: Maybe<Scalars['String']>;
  audioWithAdsState_not_contains?: Maybe<Scalars['String']>;
  audioWithAdsState_starts_with?: Maybe<Scalars['String']>;
  audioWithAdsState_not_starts_with?: Maybe<Scalars['String']>;
  audioWithAdsState_ends_with?: Maybe<Scalars['String']>;
  audioWithAdsState_not_ends_with?: Maybe<Scalars['String']>;
  audioWithAdsMuxAssetId?: Maybe<Scalars['String']>;
  audioWithAdsMuxAssetId_not?: Maybe<Scalars['String']>;
  audioWithAdsMuxAssetId_in?: Maybe<Array<Scalars['String']>>;
  audioWithAdsMuxAssetId_not_in?: Maybe<Array<Scalars['String']>>;
  audioWithAdsMuxAssetId_lt?: Maybe<Scalars['String']>;
  audioWithAdsMuxAssetId_lte?: Maybe<Scalars['String']>;
  audioWithAdsMuxAssetId_gt?: Maybe<Scalars['String']>;
  audioWithAdsMuxAssetId_gte?: Maybe<Scalars['String']>;
  audioWithAdsMuxAssetId_contains?: Maybe<Scalars['String']>;
  audioWithAdsMuxAssetId_not_contains?: Maybe<Scalars['String']>;
  audioWithAdsMuxAssetId_starts_with?: Maybe<Scalars['String']>;
  audioWithAdsMuxAssetId_not_starts_with?: Maybe<Scalars['String']>;
  audioWithAdsMuxAssetId_ends_with?: Maybe<Scalars['String']>;
  audioWithAdsMuxAssetId_not_ends_with?: Maybe<Scalars['String']>;
  audioWithAdsMuxPlaybackId?: Maybe<Scalars['String']>;
  audioWithAdsMuxPlaybackId_not?: Maybe<Scalars['String']>;
  audioWithAdsMuxPlaybackId_in?: Maybe<Array<Scalars['String']>>;
  audioWithAdsMuxPlaybackId_not_in?: Maybe<Array<Scalars['String']>>;
  audioWithAdsMuxPlaybackId_lt?: Maybe<Scalars['String']>;
  audioWithAdsMuxPlaybackId_lte?: Maybe<Scalars['String']>;
  audioWithAdsMuxPlaybackId_gt?: Maybe<Scalars['String']>;
  audioWithAdsMuxPlaybackId_gte?: Maybe<Scalars['String']>;
  audioWithAdsMuxPlaybackId_contains?: Maybe<Scalars['String']>;
  audioWithAdsMuxPlaybackId_not_contains?: Maybe<Scalars['String']>;
  audioWithAdsMuxPlaybackId_starts_with?: Maybe<Scalars['String']>;
  audioWithAdsMuxPlaybackId_not_starts_with?: Maybe<Scalars['String']>;
  audioWithAdsMuxPlaybackId_ends_with?: Maybe<Scalars['String']>;
  audioWithAdsMuxPlaybackId_not_ends_with?: Maybe<Scalars['String']>;
  audioWithAdsUploadId?: Maybe<Scalars['String']>;
  audioWithAdsUploadId_not?: Maybe<Scalars['String']>;
  audioWithAdsUploadId_in?: Maybe<Array<Scalars['String']>>;
  audioWithAdsUploadId_not_in?: Maybe<Array<Scalars['String']>>;
  audioWithAdsUploadId_lt?: Maybe<Scalars['String']>;
  audioWithAdsUploadId_lte?: Maybe<Scalars['String']>;
  audioWithAdsUploadId_gt?: Maybe<Scalars['String']>;
  audioWithAdsUploadId_gte?: Maybe<Scalars['String']>;
  audioWithAdsUploadId_contains?: Maybe<Scalars['String']>;
  audioWithAdsUploadId_not_contains?: Maybe<Scalars['String']>;
  audioWithAdsUploadId_starts_with?: Maybe<Scalars['String']>;
  audioWithAdsUploadId_not_starts_with?: Maybe<Scalars['String']>;
  audioWithAdsUploadId_ends_with?: Maybe<Scalars['String']>;
  audioWithAdsUploadId_not_ends_with?: Maybe<Scalars['String']>;
  audioWithAdsPlaybackPolicy?: Maybe<Scalars['String']>;
  audioWithAdsPlaybackPolicy_not?: Maybe<Scalars['String']>;
  audioWithAdsPlaybackPolicy_in?: Maybe<Array<Scalars['String']>>;
  audioWithAdsPlaybackPolicy_not_in?: Maybe<Array<Scalars['String']>>;
  audioWithAdsPlaybackPolicy_lt?: Maybe<Scalars['String']>;
  audioWithAdsPlaybackPolicy_lte?: Maybe<Scalars['String']>;
  audioWithAdsPlaybackPolicy_gt?: Maybe<Scalars['String']>;
  audioWithAdsPlaybackPolicy_gte?: Maybe<Scalars['String']>;
  audioWithAdsPlaybackPolicy_contains?: Maybe<Scalars['String']>;
  audioWithAdsPlaybackPolicy_not_contains?: Maybe<Scalars['String']>;
  audioWithAdsPlaybackPolicy_starts_with?: Maybe<Scalars['String']>;
  audioWithAdsPlaybackPolicy_not_starts_with?: Maybe<Scalars['String']>;
  audioWithAdsPlaybackPolicy_ends_with?: Maybe<Scalars['String']>;
  audioWithAdsPlaybackPolicy_not_ends_with?: Maybe<Scalars['String']>;
  scheduleAt?: Maybe<Scalars['DateTime']>;
  scheduleAt_not?: Maybe<Scalars['DateTime']>;
  scheduleAt_in?: Maybe<Array<Scalars['DateTime']>>;
  scheduleAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  scheduleAt_lt?: Maybe<Scalars['DateTime']>;
  scheduleAt_lte?: Maybe<Scalars['DateTime']>;
  scheduleAt_gt?: Maybe<Scalars['DateTime']>;
  scheduleAt_gte?: Maybe<Scalars['DateTime']>;
  publishDate?: Maybe<Scalars['DateTime']>;
  publishDate_not?: Maybe<Scalars['DateTime']>;
  publishDate_in?: Maybe<Array<Scalars['DateTime']>>;
  publishDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  publishDate_lt?: Maybe<Scalars['DateTime']>;
  publishDate_lte?: Maybe<Scalars['DateTime']>;
  publishDate_gt?: Maybe<Scalars['DateTime']>;
  publishDate_gte?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<PodcastEpisodeScalarWhereInput>>;
  OR?: Maybe<Array<PodcastEpisodeScalarWhereInput>>;
  NOT?: Maybe<Array<PodcastEpisodeScalarWhereInput>>;
};

export type PodcastEpisodeSignedUrlDataInput = {
  podcastEpisodeId: Scalars['String'];
  fileName: Scalars['String'];
  contentType: Scalars['String'];
};

export enum PodcastEpisodeStatus {
  Draft = 'DRAFT',
  Scheduled = 'SCHEDULED',
  Published = 'PUBLISHED',
  Unpublished = 'UNPUBLISHED'
}

export type PodcastEpisodeSubscriptionPayload = {
  __typename?: 'PodcastEpisodeSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<PodcastEpisode>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<PodcastEpisodePreviousValues>;
};

export type PodcastEpisodeSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<PodcastEpisodeWhereInput>;
  AND?: Maybe<Array<PodcastEpisodeSubscriptionWhereInput>>;
  OR?: Maybe<Array<PodcastEpisodeSubscriptionWhereInput>>;
  NOT?: Maybe<Array<PodcastEpisodeSubscriptionWhereInput>>;
};

export type PodcastEpisodeUpdateDataInput = {
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  episodeNumber?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Int']>;
  thumbnail?: Maybe<Scalars['String']>;
  podcast?: Maybe<PodcastUpdateOneRequiredWithoutPodcastEpisodesInput>;
  thirdPartyId?: Maybe<Scalars['String']>;
  season?: Maybe<SeasonUpdateOneWithoutPodcastEpisodesInput>;
  status?: Maybe<PodcastEpisodeStatus>;
  algoliaId?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
  audio?: Maybe<Scalars['String']>;
  audioState?: Maybe<Scalars['String']>;
  audioMuxAssetId?: Maybe<Scalars['String']>;
  audioMuxPlaybackId?: Maybe<Scalars['String']>;
  audioUploadId?: Maybe<Scalars['String']>;
  audioPlaybackPolicy?: Maybe<Scalars['String']>;
  durationWithAds?: Maybe<Scalars['Float']>;
  audioWithAds?: Maybe<Scalars['String']>;
  audioWithAdsState?: Maybe<Scalars['String']>;
  audioWithAdsMuxAssetId?: Maybe<Scalars['String']>;
  audioWithAdsMuxPlaybackId?: Maybe<Scalars['String']>;
  audioWithAdsUploadId?: Maybe<Scalars['String']>;
  audioWithAdsPlaybackPolicy?: Maybe<Scalars['String']>;
  audioAccess?: Maybe<PodcastEpisodeUpdateaudioAccessInput>;
  audioWithAdsAccess?: Maybe<PodcastEpisodeUpdateaudioWithAdsAccessInput>;
  allowedContinents?: Maybe<PodcastEpisodeUpdateallowedContinentsInput>;
  allowedCountries?: Maybe<Scalars['Json']>;
  scheduleAt?: Maybe<Scalars['DateTime']>;
  publishDate?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserUpdateOneRequiredInput>;
};

export type PodcastEpisodeUpdateInput = {
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  episodeNumber?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Int']>;
  thumbnail?: Maybe<Scalars['String']>;
  podcast?: Maybe<PodcastUpdateOneRequiredWithoutPodcastEpisodesInput>;
  thirdPartyId?: Maybe<Scalars['String']>;
  season?: Maybe<SeasonUpdateOneWithoutPodcastEpisodesInput>;
  status?: Maybe<PodcastEpisodeStatus>;
  algoliaId?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
  audio?: Maybe<Scalars['String']>;
  audioState?: Maybe<Scalars['String']>;
  audioMuxAssetId?: Maybe<Scalars['String']>;
  audioMuxPlaybackId?: Maybe<Scalars['String']>;
  audioUploadId?: Maybe<Scalars['String']>;
  audioPlaybackPolicy?: Maybe<Scalars['String']>;
  durationWithAds?: Maybe<Scalars['Float']>;
  audioWithAds?: Maybe<Scalars['String']>;
  audioWithAdsState?: Maybe<Scalars['String']>;
  audioWithAdsMuxAssetId?: Maybe<Scalars['String']>;
  audioWithAdsMuxPlaybackId?: Maybe<Scalars['String']>;
  audioWithAdsUploadId?: Maybe<Scalars['String']>;
  audioWithAdsPlaybackPolicy?: Maybe<Scalars['String']>;
  audioAccess?: Maybe<PodcastEpisodeUpdateaudioAccessInput>;
  audioWithAdsAccess?: Maybe<PodcastEpisodeUpdateaudioWithAdsAccessInput>;
  allowedContinents?: Maybe<PodcastEpisodeUpdateallowedContinentsInput>;
  allowedCountries?: Maybe<Scalars['Json']>;
  scheduleAt?: Maybe<Scalars['DateTime']>;
  publishDate?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserUpdateOneRequiredInput>;
};

export type PodcastEpisodeUpdateInputCustom = {
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  episodeNumber?: Maybe<Scalars['String']>;
  publishDate?: Maybe<Scalars['DateTime']>;
  rating?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Int']>;
  thumbnail?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['ID']>;
  podcastId?: Maybe<Scalars['ID']>;
  audioWithAdsAccess?: Maybe<PodcastEpisodeCreateaudioWithAdsAccessInput>;
  audioAccess?: Maybe<PodcastEpisodeCreateaudioAccessInput>;
  allowedContinents?: Maybe<PodcastEpisodeCreateallowedContinentsInput>;
  allowedCountries?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<PodcastStatus>;
  audio?: Maybe<Scalars['String']>;
  audioState?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
  audioWithAds?: Maybe<Scalars['String']>;
  audioWithAdsState?: Maybe<Scalars['String']>;
  durationWithAds?: Maybe<Scalars['Float']>;
  scheduleAt?: Maybe<Scalars['DateTime']>;
};

export type PodcastEpisodeUpdateManyDataInput = {
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  episodeNumber?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Int']>;
  thumbnail?: Maybe<Scalars['String']>;
  thirdPartyId?: Maybe<Scalars['String']>;
  status?: Maybe<PodcastEpisodeStatus>;
  algoliaId?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
  audio?: Maybe<Scalars['String']>;
  audioState?: Maybe<Scalars['String']>;
  audioMuxAssetId?: Maybe<Scalars['String']>;
  audioMuxPlaybackId?: Maybe<Scalars['String']>;
  audioUploadId?: Maybe<Scalars['String']>;
  audioPlaybackPolicy?: Maybe<Scalars['String']>;
  durationWithAds?: Maybe<Scalars['Float']>;
  audioWithAds?: Maybe<Scalars['String']>;
  audioWithAdsState?: Maybe<Scalars['String']>;
  audioWithAdsMuxAssetId?: Maybe<Scalars['String']>;
  audioWithAdsMuxPlaybackId?: Maybe<Scalars['String']>;
  audioWithAdsUploadId?: Maybe<Scalars['String']>;
  audioWithAdsPlaybackPolicy?: Maybe<Scalars['String']>;
  audioAccess?: Maybe<PodcastEpisodeUpdateaudioAccessInput>;
  audioWithAdsAccess?: Maybe<PodcastEpisodeUpdateaudioWithAdsAccessInput>;
  allowedContinents?: Maybe<PodcastEpisodeUpdateallowedContinentsInput>;
  allowedCountries?: Maybe<Scalars['Json']>;
  scheduleAt?: Maybe<Scalars['DateTime']>;
  publishDate?: Maybe<Scalars['DateTime']>;
};

export type PodcastEpisodeUpdateManyMutationInput = {
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  episodeNumber?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Int']>;
  thumbnail?: Maybe<Scalars['String']>;
  thirdPartyId?: Maybe<Scalars['String']>;
  status?: Maybe<PodcastEpisodeStatus>;
  algoliaId?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
  audio?: Maybe<Scalars['String']>;
  audioState?: Maybe<Scalars['String']>;
  audioMuxAssetId?: Maybe<Scalars['String']>;
  audioMuxPlaybackId?: Maybe<Scalars['String']>;
  audioUploadId?: Maybe<Scalars['String']>;
  audioPlaybackPolicy?: Maybe<Scalars['String']>;
  durationWithAds?: Maybe<Scalars['Float']>;
  audioWithAds?: Maybe<Scalars['String']>;
  audioWithAdsState?: Maybe<Scalars['String']>;
  audioWithAdsMuxAssetId?: Maybe<Scalars['String']>;
  audioWithAdsMuxPlaybackId?: Maybe<Scalars['String']>;
  audioWithAdsUploadId?: Maybe<Scalars['String']>;
  audioWithAdsPlaybackPolicy?: Maybe<Scalars['String']>;
  audioAccess?: Maybe<PodcastEpisodeUpdateaudioAccessInput>;
  audioWithAdsAccess?: Maybe<PodcastEpisodeUpdateaudioWithAdsAccessInput>;
  allowedContinents?: Maybe<PodcastEpisodeUpdateallowedContinentsInput>;
  allowedCountries?: Maybe<Scalars['Json']>;
  scheduleAt?: Maybe<Scalars['DateTime']>;
  publishDate?: Maybe<Scalars['DateTime']>;
};

export type PodcastEpisodeUpdateManyWithWhereNestedInput = {
  where: PodcastEpisodeScalarWhereInput;
  data: PodcastEpisodeUpdateManyDataInput;
};

export type PodcastEpisodeUpdateManyWithoutPodcastInput = {
  create?: Maybe<Array<PodcastEpisodeCreateWithoutPodcastInput>>;
  delete?: Maybe<Array<PodcastEpisodeWhereUniqueInput>>;
  connect?: Maybe<Array<PodcastEpisodeWhereUniqueInput>>;
  set?: Maybe<Array<PodcastEpisodeWhereUniqueInput>>;
  disconnect?: Maybe<Array<PodcastEpisodeWhereUniqueInput>>;
  update?: Maybe<Array<PodcastEpisodeUpdateWithWhereUniqueWithoutPodcastInput>>;
  upsert?: Maybe<Array<PodcastEpisodeUpsertWithWhereUniqueWithoutPodcastInput>>;
  deleteMany?: Maybe<Array<PodcastEpisodeScalarWhereInput>>;
  updateMany?: Maybe<Array<PodcastEpisodeUpdateManyWithWhereNestedInput>>;
};

export type PodcastEpisodeUpdateManyWithoutSeasonInput = {
  create?: Maybe<Array<PodcastEpisodeCreateWithoutSeasonInput>>;
  delete?: Maybe<Array<PodcastEpisodeWhereUniqueInput>>;
  connect?: Maybe<Array<PodcastEpisodeWhereUniqueInput>>;
  set?: Maybe<Array<PodcastEpisodeWhereUniqueInput>>;
  disconnect?: Maybe<Array<PodcastEpisodeWhereUniqueInput>>;
  update?: Maybe<Array<PodcastEpisodeUpdateWithWhereUniqueWithoutSeasonInput>>;
  upsert?: Maybe<Array<PodcastEpisodeUpsertWithWhereUniqueWithoutSeasonInput>>;
  deleteMany?: Maybe<Array<PodcastEpisodeScalarWhereInput>>;
  updateMany?: Maybe<Array<PodcastEpisodeUpdateManyWithWhereNestedInput>>;
};

export type PodcastEpisodeUpdateOneInput = {
  create?: Maybe<PodcastEpisodeCreateInput>;
  update?: Maybe<PodcastEpisodeUpdateDataInput>;
  upsert?: Maybe<PodcastEpisodeUpsertNestedInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<PodcastEpisodeWhereUniqueInput>;
};

export type PodcastEpisodeUpdateWithWhereUniqueWithoutPodcastInput = {
  where: PodcastEpisodeWhereUniqueInput;
  data: PodcastEpisodeUpdateWithoutPodcastDataInput;
};

export type PodcastEpisodeUpdateWithWhereUniqueWithoutSeasonInput = {
  where: PodcastEpisodeWhereUniqueInput;
  data: PodcastEpisodeUpdateWithoutSeasonDataInput;
};

export type PodcastEpisodeUpdateWithoutPodcastDataInput = {
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  episodeNumber?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Int']>;
  thumbnail?: Maybe<Scalars['String']>;
  thirdPartyId?: Maybe<Scalars['String']>;
  season?: Maybe<SeasonUpdateOneWithoutPodcastEpisodesInput>;
  status?: Maybe<PodcastEpisodeStatus>;
  algoliaId?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
  audio?: Maybe<Scalars['String']>;
  audioState?: Maybe<Scalars['String']>;
  audioMuxAssetId?: Maybe<Scalars['String']>;
  audioMuxPlaybackId?: Maybe<Scalars['String']>;
  audioUploadId?: Maybe<Scalars['String']>;
  audioPlaybackPolicy?: Maybe<Scalars['String']>;
  durationWithAds?: Maybe<Scalars['Float']>;
  audioWithAds?: Maybe<Scalars['String']>;
  audioWithAdsState?: Maybe<Scalars['String']>;
  audioWithAdsMuxAssetId?: Maybe<Scalars['String']>;
  audioWithAdsMuxPlaybackId?: Maybe<Scalars['String']>;
  audioWithAdsUploadId?: Maybe<Scalars['String']>;
  audioWithAdsPlaybackPolicy?: Maybe<Scalars['String']>;
  audioAccess?: Maybe<PodcastEpisodeUpdateaudioAccessInput>;
  audioWithAdsAccess?: Maybe<PodcastEpisodeUpdateaudioWithAdsAccessInput>;
  allowedContinents?: Maybe<PodcastEpisodeUpdateallowedContinentsInput>;
  allowedCountries?: Maybe<Scalars['Json']>;
  scheduleAt?: Maybe<Scalars['DateTime']>;
  publishDate?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserUpdateOneRequiredInput>;
};

export type PodcastEpisodeUpdateWithoutSeasonDataInput = {
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  episodeNumber?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Int']>;
  thumbnail?: Maybe<Scalars['String']>;
  podcast?: Maybe<PodcastUpdateOneRequiredWithoutPodcastEpisodesInput>;
  thirdPartyId?: Maybe<Scalars['String']>;
  status?: Maybe<PodcastEpisodeStatus>;
  algoliaId?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
  audio?: Maybe<Scalars['String']>;
  audioState?: Maybe<Scalars['String']>;
  audioMuxAssetId?: Maybe<Scalars['String']>;
  audioMuxPlaybackId?: Maybe<Scalars['String']>;
  audioUploadId?: Maybe<Scalars['String']>;
  audioPlaybackPolicy?: Maybe<Scalars['String']>;
  durationWithAds?: Maybe<Scalars['Float']>;
  audioWithAds?: Maybe<Scalars['String']>;
  audioWithAdsState?: Maybe<Scalars['String']>;
  audioWithAdsMuxAssetId?: Maybe<Scalars['String']>;
  audioWithAdsMuxPlaybackId?: Maybe<Scalars['String']>;
  audioWithAdsUploadId?: Maybe<Scalars['String']>;
  audioWithAdsPlaybackPolicy?: Maybe<Scalars['String']>;
  audioAccess?: Maybe<PodcastEpisodeUpdateaudioAccessInput>;
  audioWithAdsAccess?: Maybe<PodcastEpisodeUpdateaudioWithAdsAccessInput>;
  allowedContinents?: Maybe<PodcastEpisodeUpdateallowedContinentsInput>;
  allowedCountries?: Maybe<Scalars['Json']>;
  scheduleAt?: Maybe<Scalars['DateTime']>;
  publishDate?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserUpdateOneRequiredInput>;
};

export type PodcastEpisodeUpdateallowedContinentsInput = {
  set?: Maybe<Array<Scalars['String']>>;
};

export type PodcastEpisodeUpdateaudioAccessInput = {
  set?: Maybe<Array<Scalars['String']>>;
};

export type PodcastEpisodeUpdateaudioWithAdsAccessInput = {
  set?: Maybe<Array<Scalars['String']>>;
};

export type PodcastEpisodeUpsertNestedInput = {
  update: PodcastEpisodeUpdateDataInput;
  create: PodcastEpisodeCreateInput;
};

export type PodcastEpisodeUpsertWithWhereUniqueWithoutPodcastInput = {
  where: PodcastEpisodeWhereUniqueInput;
  update: PodcastEpisodeUpdateWithoutPodcastDataInput;
  create: PodcastEpisodeCreateWithoutPodcastInput;
};

export type PodcastEpisodeUpsertWithWhereUniqueWithoutSeasonInput = {
  where: PodcastEpisodeWhereUniqueInput;
  update: PodcastEpisodeUpdateWithoutSeasonDataInput;
  create: PodcastEpisodeCreateWithoutSeasonInput;
};

export type PodcastEpisodeWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Scalars['String']>>;
  title_not_in?: Maybe<Array<Scalars['String']>>;
  title_lt?: Maybe<Scalars['String']>;
  title_lte?: Maybe<Scalars['String']>;
  title_gt?: Maybe<Scalars['String']>;
  title_gte?: Maybe<Scalars['String']>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  title_starts_with?: Maybe<Scalars['String']>;
  title_not_starts_with?: Maybe<Scalars['String']>;
  title_ends_with?: Maybe<Scalars['String']>;
  title_not_ends_with?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  description_not?: Maybe<Scalars['String']>;
  description_in?: Maybe<Array<Scalars['String']>>;
  description_not_in?: Maybe<Array<Scalars['String']>>;
  description_lt?: Maybe<Scalars['String']>;
  description_lte?: Maybe<Scalars['String']>;
  description_gt?: Maybe<Scalars['String']>;
  description_gte?: Maybe<Scalars['String']>;
  description_contains?: Maybe<Scalars['String']>;
  description_not_contains?: Maybe<Scalars['String']>;
  description_starts_with?: Maybe<Scalars['String']>;
  description_not_starts_with?: Maybe<Scalars['String']>;
  description_ends_with?: Maybe<Scalars['String']>;
  description_not_ends_with?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  slug_not?: Maybe<Scalars['String']>;
  slug_in?: Maybe<Array<Scalars['String']>>;
  slug_not_in?: Maybe<Array<Scalars['String']>>;
  slug_lt?: Maybe<Scalars['String']>;
  slug_lte?: Maybe<Scalars['String']>;
  slug_gt?: Maybe<Scalars['String']>;
  slug_gte?: Maybe<Scalars['String']>;
  slug_contains?: Maybe<Scalars['String']>;
  slug_not_contains?: Maybe<Scalars['String']>;
  slug_starts_with?: Maybe<Scalars['String']>;
  slug_not_starts_with?: Maybe<Scalars['String']>;
  slug_ends_with?: Maybe<Scalars['String']>;
  slug_not_ends_with?: Maybe<Scalars['String']>;
  episodeNumber?: Maybe<Scalars['String']>;
  episodeNumber_not?: Maybe<Scalars['String']>;
  episodeNumber_in?: Maybe<Array<Scalars['String']>>;
  episodeNumber_not_in?: Maybe<Array<Scalars['String']>>;
  episodeNumber_lt?: Maybe<Scalars['String']>;
  episodeNumber_lte?: Maybe<Scalars['String']>;
  episodeNumber_gt?: Maybe<Scalars['String']>;
  episodeNumber_gte?: Maybe<Scalars['String']>;
  episodeNumber_contains?: Maybe<Scalars['String']>;
  episodeNumber_not_contains?: Maybe<Scalars['String']>;
  episodeNumber_starts_with?: Maybe<Scalars['String']>;
  episodeNumber_not_starts_with?: Maybe<Scalars['String']>;
  episodeNumber_ends_with?: Maybe<Scalars['String']>;
  episodeNumber_not_ends_with?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Int']>;
  weight_not?: Maybe<Scalars['Int']>;
  weight_in?: Maybe<Array<Scalars['Int']>>;
  weight_not_in?: Maybe<Array<Scalars['Int']>>;
  weight_lt?: Maybe<Scalars['Int']>;
  weight_lte?: Maybe<Scalars['Int']>;
  weight_gt?: Maybe<Scalars['Int']>;
  weight_gte?: Maybe<Scalars['Int']>;
  thumbnail?: Maybe<Scalars['String']>;
  thumbnail_not?: Maybe<Scalars['String']>;
  thumbnail_in?: Maybe<Array<Scalars['String']>>;
  thumbnail_not_in?: Maybe<Array<Scalars['String']>>;
  thumbnail_lt?: Maybe<Scalars['String']>;
  thumbnail_lte?: Maybe<Scalars['String']>;
  thumbnail_gt?: Maybe<Scalars['String']>;
  thumbnail_gte?: Maybe<Scalars['String']>;
  thumbnail_contains?: Maybe<Scalars['String']>;
  thumbnail_not_contains?: Maybe<Scalars['String']>;
  thumbnail_starts_with?: Maybe<Scalars['String']>;
  thumbnail_not_starts_with?: Maybe<Scalars['String']>;
  thumbnail_ends_with?: Maybe<Scalars['String']>;
  thumbnail_not_ends_with?: Maybe<Scalars['String']>;
  podcast?: Maybe<PodcastWhereInput>;
  thirdPartyId?: Maybe<Scalars['String']>;
  thirdPartyId_not?: Maybe<Scalars['String']>;
  thirdPartyId_in?: Maybe<Array<Scalars['String']>>;
  thirdPartyId_not_in?: Maybe<Array<Scalars['String']>>;
  thirdPartyId_lt?: Maybe<Scalars['String']>;
  thirdPartyId_lte?: Maybe<Scalars['String']>;
  thirdPartyId_gt?: Maybe<Scalars['String']>;
  thirdPartyId_gte?: Maybe<Scalars['String']>;
  thirdPartyId_contains?: Maybe<Scalars['String']>;
  thirdPartyId_not_contains?: Maybe<Scalars['String']>;
  thirdPartyId_starts_with?: Maybe<Scalars['String']>;
  thirdPartyId_not_starts_with?: Maybe<Scalars['String']>;
  thirdPartyId_ends_with?: Maybe<Scalars['String']>;
  thirdPartyId_not_ends_with?: Maybe<Scalars['String']>;
  season?: Maybe<SeasonWhereInput>;
  status?: Maybe<PodcastEpisodeStatus>;
  status_not?: Maybe<PodcastEpisodeStatus>;
  status_in?: Maybe<Array<PodcastEpisodeStatus>>;
  status_not_in?: Maybe<Array<PodcastEpisodeStatus>>;
  algoliaId?: Maybe<Scalars['String']>;
  algoliaId_not?: Maybe<Scalars['String']>;
  algoliaId_in?: Maybe<Array<Scalars['String']>>;
  algoliaId_not_in?: Maybe<Array<Scalars['String']>>;
  algoliaId_lt?: Maybe<Scalars['String']>;
  algoliaId_lte?: Maybe<Scalars['String']>;
  algoliaId_gt?: Maybe<Scalars['String']>;
  algoliaId_gte?: Maybe<Scalars['String']>;
  algoliaId_contains?: Maybe<Scalars['String']>;
  algoliaId_not_contains?: Maybe<Scalars['String']>;
  algoliaId_starts_with?: Maybe<Scalars['String']>;
  algoliaId_not_starts_with?: Maybe<Scalars['String']>;
  algoliaId_ends_with?: Maybe<Scalars['String']>;
  algoliaId_not_ends_with?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['String']>;
  rating_not?: Maybe<Scalars['String']>;
  rating_in?: Maybe<Array<Scalars['String']>>;
  rating_not_in?: Maybe<Array<Scalars['String']>>;
  rating_lt?: Maybe<Scalars['String']>;
  rating_lte?: Maybe<Scalars['String']>;
  rating_gt?: Maybe<Scalars['String']>;
  rating_gte?: Maybe<Scalars['String']>;
  rating_contains?: Maybe<Scalars['String']>;
  rating_not_contains?: Maybe<Scalars['String']>;
  rating_starts_with?: Maybe<Scalars['String']>;
  rating_not_starts_with?: Maybe<Scalars['String']>;
  rating_ends_with?: Maybe<Scalars['String']>;
  rating_not_ends_with?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
  duration_not?: Maybe<Scalars['Float']>;
  duration_in?: Maybe<Array<Scalars['Float']>>;
  duration_not_in?: Maybe<Array<Scalars['Float']>>;
  duration_lt?: Maybe<Scalars['Float']>;
  duration_lte?: Maybe<Scalars['Float']>;
  duration_gt?: Maybe<Scalars['Float']>;
  duration_gte?: Maybe<Scalars['Float']>;
  audio?: Maybe<Scalars['String']>;
  audio_not?: Maybe<Scalars['String']>;
  audio_in?: Maybe<Array<Scalars['String']>>;
  audio_not_in?: Maybe<Array<Scalars['String']>>;
  audio_lt?: Maybe<Scalars['String']>;
  audio_lte?: Maybe<Scalars['String']>;
  audio_gt?: Maybe<Scalars['String']>;
  audio_gte?: Maybe<Scalars['String']>;
  audio_contains?: Maybe<Scalars['String']>;
  audio_not_contains?: Maybe<Scalars['String']>;
  audio_starts_with?: Maybe<Scalars['String']>;
  audio_not_starts_with?: Maybe<Scalars['String']>;
  audio_ends_with?: Maybe<Scalars['String']>;
  audio_not_ends_with?: Maybe<Scalars['String']>;
  audioState?: Maybe<Scalars['String']>;
  audioState_not?: Maybe<Scalars['String']>;
  audioState_in?: Maybe<Array<Scalars['String']>>;
  audioState_not_in?: Maybe<Array<Scalars['String']>>;
  audioState_lt?: Maybe<Scalars['String']>;
  audioState_lte?: Maybe<Scalars['String']>;
  audioState_gt?: Maybe<Scalars['String']>;
  audioState_gte?: Maybe<Scalars['String']>;
  audioState_contains?: Maybe<Scalars['String']>;
  audioState_not_contains?: Maybe<Scalars['String']>;
  audioState_starts_with?: Maybe<Scalars['String']>;
  audioState_not_starts_with?: Maybe<Scalars['String']>;
  audioState_ends_with?: Maybe<Scalars['String']>;
  audioState_not_ends_with?: Maybe<Scalars['String']>;
  audioMuxAssetId?: Maybe<Scalars['String']>;
  audioMuxAssetId_not?: Maybe<Scalars['String']>;
  audioMuxAssetId_in?: Maybe<Array<Scalars['String']>>;
  audioMuxAssetId_not_in?: Maybe<Array<Scalars['String']>>;
  audioMuxAssetId_lt?: Maybe<Scalars['String']>;
  audioMuxAssetId_lte?: Maybe<Scalars['String']>;
  audioMuxAssetId_gt?: Maybe<Scalars['String']>;
  audioMuxAssetId_gte?: Maybe<Scalars['String']>;
  audioMuxAssetId_contains?: Maybe<Scalars['String']>;
  audioMuxAssetId_not_contains?: Maybe<Scalars['String']>;
  audioMuxAssetId_starts_with?: Maybe<Scalars['String']>;
  audioMuxAssetId_not_starts_with?: Maybe<Scalars['String']>;
  audioMuxAssetId_ends_with?: Maybe<Scalars['String']>;
  audioMuxAssetId_not_ends_with?: Maybe<Scalars['String']>;
  audioMuxPlaybackId?: Maybe<Scalars['String']>;
  audioMuxPlaybackId_not?: Maybe<Scalars['String']>;
  audioMuxPlaybackId_in?: Maybe<Array<Scalars['String']>>;
  audioMuxPlaybackId_not_in?: Maybe<Array<Scalars['String']>>;
  audioMuxPlaybackId_lt?: Maybe<Scalars['String']>;
  audioMuxPlaybackId_lte?: Maybe<Scalars['String']>;
  audioMuxPlaybackId_gt?: Maybe<Scalars['String']>;
  audioMuxPlaybackId_gte?: Maybe<Scalars['String']>;
  audioMuxPlaybackId_contains?: Maybe<Scalars['String']>;
  audioMuxPlaybackId_not_contains?: Maybe<Scalars['String']>;
  audioMuxPlaybackId_starts_with?: Maybe<Scalars['String']>;
  audioMuxPlaybackId_not_starts_with?: Maybe<Scalars['String']>;
  audioMuxPlaybackId_ends_with?: Maybe<Scalars['String']>;
  audioMuxPlaybackId_not_ends_with?: Maybe<Scalars['String']>;
  audioUploadId?: Maybe<Scalars['String']>;
  audioUploadId_not?: Maybe<Scalars['String']>;
  audioUploadId_in?: Maybe<Array<Scalars['String']>>;
  audioUploadId_not_in?: Maybe<Array<Scalars['String']>>;
  audioUploadId_lt?: Maybe<Scalars['String']>;
  audioUploadId_lte?: Maybe<Scalars['String']>;
  audioUploadId_gt?: Maybe<Scalars['String']>;
  audioUploadId_gte?: Maybe<Scalars['String']>;
  audioUploadId_contains?: Maybe<Scalars['String']>;
  audioUploadId_not_contains?: Maybe<Scalars['String']>;
  audioUploadId_starts_with?: Maybe<Scalars['String']>;
  audioUploadId_not_starts_with?: Maybe<Scalars['String']>;
  audioUploadId_ends_with?: Maybe<Scalars['String']>;
  audioUploadId_not_ends_with?: Maybe<Scalars['String']>;
  audioPlaybackPolicy?: Maybe<Scalars['String']>;
  audioPlaybackPolicy_not?: Maybe<Scalars['String']>;
  audioPlaybackPolicy_in?: Maybe<Array<Scalars['String']>>;
  audioPlaybackPolicy_not_in?: Maybe<Array<Scalars['String']>>;
  audioPlaybackPolicy_lt?: Maybe<Scalars['String']>;
  audioPlaybackPolicy_lte?: Maybe<Scalars['String']>;
  audioPlaybackPolicy_gt?: Maybe<Scalars['String']>;
  audioPlaybackPolicy_gte?: Maybe<Scalars['String']>;
  audioPlaybackPolicy_contains?: Maybe<Scalars['String']>;
  audioPlaybackPolicy_not_contains?: Maybe<Scalars['String']>;
  audioPlaybackPolicy_starts_with?: Maybe<Scalars['String']>;
  audioPlaybackPolicy_not_starts_with?: Maybe<Scalars['String']>;
  audioPlaybackPolicy_ends_with?: Maybe<Scalars['String']>;
  audioPlaybackPolicy_not_ends_with?: Maybe<Scalars['String']>;
  durationWithAds?: Maybe<Scalars['Float']>;
  durationWithAds_not?: Maybe<Scalars['Float']>;
  durationWithAds_in?: Maybe<Array<Scalars['Float']>>;
  durationWithAds_not_in?: Maybe<Array<Scalars['Float']>>;
  durationWithAds_lt?: Maybe<Scalars['Float']>;
  durationWithAds_lte?: Maybe<Scalars['Float']>;
  durationWithAds_gt?: Maybe<Scalars['Float']>;
  durationWithAds_gte?: Maybe<Scalars['Float']>;
  audioWithAds?: Maybe<Scalars['String']>;
  audioWithAds_not?: Maybe<Scalars['String']>;
  audioWithAds_in?: Maybe<Array<Scalars['String']>>;
  audioWithAds_not_in?: Maybe<Array<Scalars['String']>>;
  audioWithAds_lt?: Maybe<Scalars['String']>;
  audioWithAds_lte?: Maybe<Scalars['String']>;
  audioWithAds_gt?: Maybe<Scalars['String']>;
  audioWithAds_gte?: Maybe<Scalars['String']>;
  audioWithAds_contains?: Maybe<Scalars['String']>;
  audioWithAds_not_contains?: Maybe<Scalars['String']>;
  audioWithAds_starts_with?: Maybe<Scalars['String']>;
  audioWithAds_not_starts_with?: Maybe<Scalars['String']>;
  audioWithAds_ends_with?: Maybe<Scalars['String']>;
  audioWithAds_not_ends_with?: Maybe<Scalars['String']>;
  audioWithAdsState?: Maybe<Scalars['String']>;
  audioWithAdsState_not?: Maybe<Scalars['String']>;
  audioWithAdsState_in?: Maybe<Array<Scalars['String']>>;
  audioWithAdsState_not_in?: Maybe<Array<Scalars['String']>>;
  audioWithAdsState_lt?: Maybe<Scalars['String']>;
  audioWithAdsState_lte?: Maybe<Scalars['String']>;
  audioWithAdsState_gt?: Maybe<Scalars['String']>;
  audioWithAdsState_gte?: Maybe<Scalars['String']>;
  audioWithAdsState_contains?: Maybe<Scalars['String']>;
  audioWithAdsState_not_contains?: Maybe<Scalars['String']>;
  audioWithAdsState_starts_with?: Maybe<Scalars['String']>;
  audioWithAdsState_not_starts_with?: Maybe<Scalars['String']>;
  audioWithAdsState_ends_with?: Maybe<Scalars['String']>;
  audioWithAdsState_not_ends_with?: Maybe<Scalars['String']>;
  audioWithAdsMuxAssetId?: Maybe<Scalars['String']>;
  audioWithAdsMuxAssetId_not?: Maybe<Scalars['String']>;
  audioWithAdsMuxAssetId_in?: Maybe<Array<Scalars['String']>>;
  audioWithAdsMuxAssetId_not_in?: Maybe<Array<Scalars['String']>>;
  audioWithAdsMuxAssetId_lt?: Maybe<Scalars['String']>;
  audioWithAdsMuxAssetId_lte?: Maybe<Scalars['String']>;
  audioWithAdsMuxAssetId_gt?: Maybe<Scalars['String']>;
  audioWithAdsMuxAssetId_gte?: Maybe<Scalars['String']>;
  audioWithAdsMuxAssetId_contains?: Maybe<Scalars['String']>;
  audioWithAdsMuxAssetId_not_contains?: Maybe<Scalars['String']>;
  audioWithAdsMuxAssetId_starts_with?: Maybe<Scalars['String']>;
  audioWithAdsMuxAssetId_not_starts_with?: Maybe<Scalars['String']>;
  audioWithAdsMuxAssetId_ends_with?: Maybe<Scalars['String']>;
  audioWithAdsMuxAssetId_not_ends_with?: Maybe<Scalars['String']>;
  audioWithAdsMuxPlaybackId?: Maybe<Scalars['String']>;
  audioWithAdsMuxPlaybackId_not?: Maybe<Scalars['String']>;
  audioWithAdsMuxPlaybackId_in?: Maybe<Array<Scalars['String']>>;
  audioWithAdsMuxPlaybackId_not_in?: Maybe<Array<Scalars['String']>>;
  audioWithAdsMuxPlaybackId_lt?: Maybe<Scalars['String']>;
  audioWithAdsMuxPlaybackId_lte?: Maybe<Scalars['String']>;
  audioWithAdsMuxPlaybackId_gt?: Maybe<Scalars['String']>;
  audioWithAdsMuxPlaybackId_gte?: Maybe<Scalars['String']>;
  audioWithAdsMuxPlaybackId_contains?: Maybe<Scalars['String']>;
  audioWithAdsMuxPlaybackId_not_contains?: Maybe<Scalars['String']>;
  audioWithAdsMuxPlaybackId_starts_with?: Maybe<Scalars['String']>;
  audioWithAdsMuxPlaybackId_not_starts_with?: Maybe<Scalars['String']>;
  audioWithAdsMuxPlaybackId_ends_with?: Maybe<Scalars['String']>;
  audioWithAdsMuxPlaybackId_not_ends_with?: Maybe<Scalars['String']>;
  audioWithAdsUploadId?: Maybe<Scalars['String']>;
  audioWithAdsUploadId_not?: Maybe<Scalars['String']>;
  audioWithAdsUploadId_in?: Maybe<Array<Scalars['String']>>;
  audioWithAdsUploadId_not_in?: Maybe<Array<Scalars['String']>>;
  audioWithAdsUploadId_lt?: Maybe<Scalars['String']>;
  audioWithAdsUploadId_lte?: Maybe<Scalars['String']>;
  audioWithAdsUploadId_gt?: Maybe<Scalars['String']>;
  audioWithAdsUploadId_gte?: Maybe<Scalars['String']>;
  audioWithAdsUploadId_contains?: Maybe<Scalars['String']>;
  audioWithAdsUploadId_not_contains?: Maybe<Scalars['String']>;
  audioWithAdsUploadId_starts_with?: Maybe<Scalars['String']>;
  audioWithAdsUploadId_not_starts_with?: Maybe<Scalars['String']>;
  audioWithAdsUploadId_ends_with?: Maybe<Scalars['String']>;
  audioWithAdsUploadId_not_ends_with?: Maybe<Scalars['String']>;
  audioWithAdsPlaybackPolicy?: Maybe<Scalars['String']>;
  audioWithAdsPlaybackPolicy_not?: Maybe<Scalars['String']>;
  audioWithAdsPlaybackPolicy_in?: Maybe<Array<Scalars['String']>>;
  audioWithAdsPlaybackPolicy_not_in?: Maybe<Array<Scalars['String']>>;
  audioWithAdsPlaybackPolicy_lt?: Maybe<Scalars['String']>;
  audioWithAdsPlaybackPolicy_lte?: Maybe<Scalars['String']>;
  audioWithAdsPlaybackPolicy_gt?: Maybe<Scalars['String']>;
  audioWithAdsPlaybackPolicy_gte?: Maybe<Scalars['String']>;
  audioWithAdsPlaybackPolicy_contains?: Maybe<Scalars['String']>;
  audioWithAdsPlaybackPolicy_not_contains?: Maybe<Scalars['String']>;
  audioWithAdsPlaybackPolicy_starts_with?: Maybe<Scalars['String']>;
  audioWithAdsPlaybackPolicy_not_starts_with?: Maybe<Scalars['String']>;
  audioWithAdsPlaybackPolicy_ends_with?: Maybe<Scalars['String']>;
  audioWithAdsPlaybackPolicy_not_ends_with?: Maybe<Scalars['String']>;
  scheduleAt?: Maybe<Scalars['DateTime']>;
  scheduleAt_not?: Maybe<Scalars['DateTime']>;
  scheduleAt_in?: Maybe<Array<Scalars['DateTime']>>;
  scheduleAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  scheduleAt_lt?: Maybe<Scalars['DateTime']>;
  scheduleAt_lte?: Maybe<Scalars['DateTime']>;
  scheduleAt_gt?: Maybe<Scalars['DateTime']>;
  scheduleAt_gte?: Maybe<Scalars['DateTime']>;
  publishDate?: Maybe<Scalars['DateTime']>;
  publishDate_not?: Maybe<Scalars['DateTime']>;
  publishDate_in?: Maybe<Array<Scalars['DateTime']>>;
  publishDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  publishDate_lt?: Maybe<Scalars['DateTime']>;
  publishDate_lte?: Maybe<Scalars['DateTime']>;
  publishDate_gt?: Maybe<Scalars['DateTime']>;
  publishDate_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<PodcastEpisodeWhereInput>>;
  OR?: Maybe<Array<PodcastEpisodeWhereInput>>;
  NOT?: Maybe<Array<PodcastEpisodeWhereInput>>;
};

export type PodcastEpisodeWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
  slug?: Maybe<Scalars['String']>;
};

export type PodcastMigrationEpisode = {
  __typename?: 'PodcastMigrationEpisode';
  id: Scalars['ID'];
  showId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  episodeId?: Maybe<Scalars['String']>;
  episodeSegmentId?: Maybe<Scalars['String']>;
  podcastEpisodeId?: Maybe<Scalars['String']>;
  isMigrated?: Maybe<Scalars['Boolean']>;
  error?: Maybe<Scalars['Json']>;
};

export type PodcastMigrationEpisodeConnection = {
  __typename?: 'PodcastMigrationEpisodeConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<PodcastMigrationEpisodeEdge>>;
  aggregate: AggregatePodcastMigrationEpisode;
};

export type PodcastMigrationEpisodeCreateInput = {
  id?: Maybe<Scalars['ID']>;
  showId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  episodeId?: Maybe<Scalars['String']>;
  episodeSegmentId?: Maybe<Scalars['String']>;
  podcastEpisodeId?: Maybe<Scalars['String']>;
  isMigrated?: Maybe<Scalars['Boolean']>;
  error?: Maybe<Scalars['Json']>;
};

export type PodcastMigrationEpisodeEdge = {
  __typename?: 'PodcastMigrationEpisodeEdge';
  node: PodcastMigrationEpisode;
  cursor: Scalars['String'];
};

export enum PodcastMigrationEpisodeOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  ShowIdAsc = 'showId_ASC',
  ShowIdDesc = 'showId_DESC',
  SeasonIdAsc = 'seasonId_ASC',
  SeasonIdDesc = 'seasonId_DESC',
  EpisodeIdAsc = 'episodeId_ASC',
  EpisodeIdDesc = 'episodeId_DESC',
  EpisodeSegmentIdAsc = 'episodeSegmentId_ASC',
  EpisodeSegmentIdDesc = 'episodeSegmentId_DESC',
  PodcastEpisodeIdAsc = 'podcastEpisodeId_ASC',
  PodcastEpisodeIdDesc = 'podcastEpisodeId_DESC',
  IsMigratedAsc = 'isMigrated_ASC',
  IsMigratedDesc = 'isMigrated_DESC',
  ErrorAsc = 'error_ASC',
  ErrorDesc = 'error_DESC'
}

export type PodcastMigrationEpisodePreviousValues = {
  __typename?: 'PodcastMigrationEpisodePreviousValues';
  id: Scalars['ID'];
  showId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  episodeId?: Maybe<Scalars['String']>;
  episodeSegmentId?: Maybe<Scalars['String']>;
  podcastEpisodeId?: Maybe<Scalars['String']>;
  isMigrated?: Maybe<Scalars['Boolean']>;
  error?: Maybe<Scalars['Json']>;
};

export type PodcastMigrationEpisodeSubscriptionPayload = {
  __typename?: 'PodcastMigrationEpisodeSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<PodcastMigrationEpisode>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<PodcastMigrationEpisodePreviousValues>;
};

export type PodcastMigrationEpisodeSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<PodcastMigrationEpisodeWhereInput>;
  AND?: Maybe<Array<PodcastMigrationEpisodeSubscriptionWhereInput>>;
  OR?: Maybe<Array<PodcastMigrationEpisodeSubscriptionWhereInput>>;
  NOT?: Maybe<Array<PodcastMigrationEpisodeSubscriptionWhereInput>>;
};

export type PodcastMigrationEpisodeUpdateInput = {
  showId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  episodeId?: Maybe<Scalars['String']>;
  episodeSegmentId?: Maybe<Scalars['String']>;
  podcastEpisodeId?: Maybe<Scalars['String']>;
  isMigrated?: Maybe<Scalars['Boolean']>;
  error?: Maybe<Scalars['Json']>;
};

export type PodcastMigrationEpisodeUpdateManyMutationInput = {
  showId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  episodeId?: Maybe<Scalars['String']>;
  episodeSegmentId?: Maybe<Scalars['String']>;
  podcastEpisodeId?: Maybe<Scalars['String']>;
  isMigrated?: Maybe<Scalars['Boolean']>;
  error?: Maybe<Scalars['Json']>;
};

export type PodcastMigrationEpisodeWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  showId?: Maybe<Scalars['String']>;
  showId_not?: Maybe<Scalars['String']>;
  showId_in?: Maybe<Array<Scalars['String']>>;
  showId_not_in?: Maybe<Array<Scalars['String']>>;
  showId_lt?: Maybe<Scalars['String']>;
  showId_lte?: Maybe<Scalars['String']>;
  showId_gt?: Maybe<Scalars['String']>;
  showId_gte?: Maybe<Scalars['String']>;
  showId_contains?: Maybe<Scalars['String']>;
  showId_not_contains?: Maybe<Scalars['String']>;
  showId_starts_with?: Maybe<Scalars['String']>;
  showId_not_starts_with?: Maybe<Scalars['String']>;
  showId_ends_with?: Maybe<Scalars['String']>;
  showId_not_ends_with?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  seasonId_not?: Maybe<Scalars['String']>;
  seasonId_in?: Maybe<Array<Scalars['String']>>;
  seasonId_not_in?: Maybe<Array<Scalars['String']>>;
  seasonId_lt?: Maybe<Scalars['String']>;
  seasonId_lte?: Maybe<Scalars['String']>;
  seasonId_gt?: Maybe<Scalars['String']>;
  seasonId_gte?: Maybe<Scalars['String']>;
  seasonId_contains?: Maybe<Scalars['String']>;
  seasonId_not_contains?: Maybe<Scalars['String']>;
  seasonId_starts_with?: Maybe<Scalars['String']>;
  seasonId_not_starts_with?: Maybe<Scalars['String']>;
  seasonId_ends_with?: Maybe<Scalars['String']>;
  seasonId_not_ends_with?: Maybe<Scalars['String']>;
  episodeId?: Maybe<Scalars['String']>;
  episodeId_not?: Maybe<Scalars['String']>;
  episodeId_in?: Maybe<Array<Scalars['String']>>;
  episodeId_not_in?: Maybe<Array<Scalars['String']>>;
  episodeId_lt?: Maybe<Scalars['String']>;
  episodeId_lte?: Maybe<Scalars['String']>;
  episodeId_gt?: Maybe<Scalars['String']>;
  episodeId_gte?: Maybe<Scalars['String']>;
  episodeId_contains?: Maybe<Scalars['String']>;
  episodeId_not_contains?: Maybe<Scalars['String']>;
  episodeId_starts_with?: Maybe<Scalars['String']>;
  episodeId_not_starts_with?: Maybe<Scalars['String']>;
  episodeId_ends_with?: Maybe<Scalars['String']>;
  episodeId_not_ends_with?: Maybe<Scalars['String']>;
  episodeSegmentId?: Maybe<Scalars['String']>;
  episodeSegmentId_not?: Maybe<Scalars['String']>;
  episodeSegmentId_in?: Maybe<Array<Scalars['String']>>;
  episodeSegmentId_not_in?: Maybe<Array<Scalars['String']>>;
  episodeSegmentId_lt?: Maybe<Scalars['String']>;
  episodeSegmentId_lte?: Maybe<Scalars['String']>;
  episodeSegmentId_gt?: Maybe<Scalars['String']>;
  episodeSegmentId_gte?: Maybe<Scalars['String']>;
  episodeSegmentId_contains?: Maybe<Scalars['String']>;
  episodeSegmentId_not_contains?: Maybe<Scalars['String']>;
  episodeSegmentId_starts_with?: Maybe<Scalars['String']>;
  episodeSegmentId_not_starts_with?: Maybe<Scalars['String']>;
  episodeSegmentId_ends_with?: Maybe<Scalars['String']>;
  episodeSegmentId_not_ends_with?: Maybe<Scalars['String']>;
  podcastEpisodeId?: Maybe<Scalars['String']>;
  podcastEpisodeId_not?: Maybe<Scalars['String']>;
  podcastEpisodeId_in?: Maybe<Array<Scalars['String']>>;
  podcastEpisodeId_not_in?: Maybe<Array<Scalars['String']>>;
  podcastEpisodeId_lt?: Maybe<Scalars['String']>;
  podcastEpisodeId_lte?: Maybe<Scalars['String']>;
  podcastEpisodeId_gt?: Maybe<Scalars['String']>;
  podcastEpisodeId_gte?: Maybe<Scalars['String']>;
  podcastEpisodeId_contains?: Maybe<Scalars['String']>;
  podcastEpisodeId_not_contains?: Maybe<Scalars['String']>;
  podcastEpisodeId_starts_with?: Maybe<Scalars['String']>;
  podcastEpisodeId_not_starts_with?: Maybe<Scalars['String']>;
  podcastEpisodeId_ends_with?: Maybe<Scalars['String']>;
  podcastEpisodeId_not_ends_with?: Maybe<Scalars['String']>;
  isMigrated?: Maybe<Scalars['Boolean']>;
  isMigrated_not?: Maybe<Scalars['Boolean']>;
  AND?: Maybe<Array<PodcastMigrationEpisodeWhereInput>>;
  OR?: Maybe<Array<PodcastMigrationEpisodeWhereInput>>;
  NOT?: Maybe<Array<PodcastMigrationEpisodeWhereInput>>;
};

export type PodcastMigrationEpisodeWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
  episodeSegmentId?: Maybe<Scalars['String']>;
};

export type PodcastMigrationSeason = {
  __typename?: 'PodcastMigrationSeason';
  id: Scalars['ID'];
  showId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  podcastSeasonId?: Maybe<Scalars['String']>;
  isMigrated?: Maybe<Scalars['Boolean']>;
  error?: Maybe<Scalars['Json']>;
};

export type PodcastMigrationSeasonConnection = {
  __typename?: 'PodcastMigrationSeasonConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<PodcastMigrationSeasonEdge>>;
  aggregate: AggregatePodcastMigrationSeason;
};

export type PodcastMigrationSeasonCreateInput = {
  id?: Maybe<Scalars['ID']>;
  showId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  podcastSeasonId?: Maybe<Scalars['String']>;
  isMigrated?: Maybe<Scalars['Boolean']>;
  error?: Maybe<Scalars['Json']>;
};

export type PodcastMigrationSeasonEdge = {
  __typename?: 'PodcastMigrationSeasonEdge';
  node: PodcastMigrationSeason;
  cursor: Scalars['String'];
};

export enum PodcastMigrationSeasonOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  ShowIdAsc = 'showId_ASC',
  ShowIdDesc = 'showId_DESC',
  SeasonIdAsc = 'seasonId_ASC',
  SeasonIdDesc = 'seasonId_DESC',
  PodcastSeasonIdAsc = 'podcastSeasonId_ASC',
  PodcastSeasonIdDesc = 'podcastSeasonId_DESC',
  IsMigratedAsc = 'isMigrated_ASC',
  IsMigratedDesc = 'isMigrated_DESC',
  ErrorAsc = 'error_ASC',
  ErrorDesc = 'error_DESC'
}

export type PodcastMigrationSeasonPreviousValues = {
  __typename?: 'PodcastMigrationSeasonPreviousValues';
  id: Scalars['ID'];
  showId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  podcastSeasonId?: Maybe<Scalars['String']>;
  isMigrated?: Maybe<Scalars['Boolean']>;
  error?: Maybe<Scalars['Json']>;
};

export type PodcastMigrationSeasonSubscriptionPayload = {
  __typename?: 'PodcastMigrationSeasonSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<PodcastMigrationSeason>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<PodcastMigrationSeasonPreviousValues>;
};

export type PodcastMigrationSeasonSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<PodcastMigrationSeasonWhereInput>;
  AND?: Maybe<Array<PodcastMigrationSeasonSubscriptionWhereInput>>;
  OR?: Maybe<Array<PodcastMigrationSeasonSubscriptionWhereInput>>;
  NOT?: Maybe<Array<PodcastMigrationSeasonSubscriptionWhereInput>>;
};

export type PodcastMigrationSeasonUpdateInput = {
  showId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  podcastSeasonId?: Maybe<Scalars['String']>;
  isMigrated?: Maybe<Scalars['Boolean']>;
  error?: Maybe<Scalars['Json']>;
};

export type PodcastMigrationSeasonUpdateManyMutationInput = {
  showId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  podcastSeasonId?: Maybe<Scalars['String']>;
  isMigrated?: Maybe<Scalars['Boolean']>;
  error?: Maybe<Scalars['Json']>;
};

export type PodcastMigrationSeasonWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  showId?: Maybe<Scalars['String']>;
  showId_not?: Maybe<Scalars['String']>;
  showId_in?: Maybe<Array<Scalars['String']>>;
  showId_not_in?: Maybe<Array<Scalars['String']>>;
  showId_lt?: Maybe<Scalars['String']>;
  showId_lte?: Maybe<Scalars['String']>;
  showId_gt?: Maybe<Scalars['String']>;
  showId_gte?: Maybe<Scalars['String']>;
  showId_contains?: Maybe<Scalars['String']>;
  showId_not_contains?: Maybe<Scalars['String']>;
  showId_starts_with?: Maybe<Scalars['String']>;
  showId_not_starts_with?: Maybe<Scalars['String']>;
  showId_ends_with?: Maybe<Scalars['String']>;
  showId_not_ends_with?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  seasonId_not?: Maybe<Scalars['String']>;
  seasonId_in?: Maybe<Array<Scalars['String']>>;
  seasonId_not_in?: Maybe<Array<Scalars['String']>>;
  seasonId_lt?: Maybe<Scalars['String']>;
  seasonId_lte?: Maybe<Scalars['String']>;
  seasonId_gt?: Maybe<Scalars['String']>;
  seasonId_gte?: Maybe<Scalars['String']>;
  seasonId_contains?: Maybe<Scalars['String']>;
  seasonId_not_contains?: Maybe<Scalars['String']>;
  seasonId_starts_with?: Maybe<Scalars['String']>;
  seasonId_not_starts_with?: Maybe<Scalars['String']>;
  seasonId_ends_with?: Maybe<Scalars['String']>;
  seasonId_not_ends_with?: Maybe<Scalars['String']>;
  podcastSeasonId?: Maybe<Scalars['String']>;
  podcastSeasonId_not?: Maybe<Scalars['String']>;
  podcastSeasonId_in?: Maybe<Array<Scalars['String']>>;
  podcastSeasonId_not_in?: Maybe<Array<Scalars['String']>>;
  podcastSeasonId_lt?: Maybe<Scalars['String']>;
  podcastSeasonId_lte?: Maybe<Scalars['String']>;
  podcastSeasonId_gt?: Maybe<Scalars['String']>;
  podcastSeasonId_gte?: Maybe<Scalars['String']>;
  podcastSeasonId_contains?: Maybe<Scalars['String']>;
  podcastSeasonId_not_contains?: Maybe<Scalars['String']>;
  podcastSeasonId_starts_with?: Maybe<Scalars['String']>;
  podcastSeasonId_not_starts_with?: Maybe<Scalars['String']>;
  podcastSeasonId_ends_with?: Maybe<Scalars['String']>;
  podcastSeasonId_not_ends_with?: Maybe<Scalars['String']>;
  isMigrated?: Maybe<Scalars['Boolean']>;
  isMigrated_not?: Maybe<Scalars['Boolean']>;
  AND?: Maybe<Array<PodcastMigrationSeasonWhereInput>>;
  OR?: Maybe<Array<PodcastMigrationSeasonWhereInput>>;
  NOT?: Maybe<Array<PodcastMigrationSeasonWhereInput>>;
};

export type PodcastMigrationSeasonWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
  seasonId?: Maybe<Scalars['String']>;
};

export type PodcastMigrationShow = {
  __typename?: 'PodcastMigrationShow';
  id: Scalars['ID'];
  showId?: Maybe<Scalars['String']>;
  podcastId?: Maybe<Scalars['String']>;
  isMigrated?: Maybe<Scalars['Boolean']>;
  error?: Maybe<Scalars['Json']>;
};

export type PodcastMigrationShowConnection = {
  __typename?: 'PodcastMigrationShowConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<PodcastMigrationShowEdge>>;
  aggregate: AggregatePodcastMigrationShow;
};

export type PodcastMigrationShowCreateInput = {
  id?: Maybe<Scalars['ID']>;
  showId?: Maybe<Scalars['String']>;
  podcastId?: Maybe<Scalars['String']>;
  isMigrated?: Maybe<Scalars['Boolean']>;
  error?: Maybe<Scalars['Json']>;
};

export type PodcastMigrationShowEdge = {
  __typename?: 'PodcastMigrationShowEdge';
  node: PodcastMigrationShow;
  cursor: Scalars['String'];
};

export enum PodcastMigrationShowOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  ShowIdAsc = 'showId_ASC',
  ShowIdDesc = 'showId_DESC',
  PodcastIdAsc = 'podcastId_ASC',
  PodcastIdDesc = 'podcastId_DESC',
  IsMigratedAsc = 'isMigrated_ASC',
  IsMigratedDesc = 'isMigrated_DESC',
  ErrorAsc = 'error_ASC',
  ErrorDesc = 'error_DESC'
}

export type PodcastMigrationShowPreviousValues = {
  __typename?: 'PodcastMigrationShowPreviousValues';
  id: Scalars['ID'];
  showId?: Maybe<Scalars['String']>;
  podcastId?: Maybe<Scalars['String']>;
  isMigrated?: Maybe<Scalars['Boolean']>;
  error?: Maybe<Scalars['Json']>;
};

export type PodcastMigrationShowSubscriptionPayload = {
  __typename?: 'PodcastMigrationShowSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<PodcastMigrationShow>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<PodcastMigrationShowPreviousValues>;
};

export type PodcastMigrationShowSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<PodcastMigrationShowWhereInput>;
  AND?: Maybe<Array<PodcastMigrationShowSubscriptionWhereInput>>;
  OR?: Maybe<Array<PodcastMigrationShowSubscriptionWhereInput>>;
  NOT?: Maybe<Array<PodcastMigrationShowSubscriptionWhereInput>>;
};

export type PodcastMigrationShowUpdateInput = {
  showId?: Maybe<Scalars['String']>;
  podcastId?: Maybe<Scalars['String']>;
  isMigrated?: Maybe<Scalars['Boolean']>;
  error?: Maybe<Scalars['Json']>;
};

export type PodcastMigrationShowUpdateManyMutationInput = {
  showId?: Maybe<Scalars['String']>;
  podcastId?: Maybe<Scalars['String']>;
  isMigrated?: Maybe<Scalars['Boolean']>;
  error?: Maybe<Scalars['Json']>;
};

export type PodcastMigrationShowWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  showId?: Maybe<Scalars['String']>;
  showId_not?: Maybe<Scalars['String']>;
  showId_in?: Maybe<Array<Scalars['String']>>;
  showId_not_in?: Maybe<Array<Scalars['String']>>;
  showId_lt?: Maybe<Scalars['String']>;
  showId_lte?: Maybe<Scalars['String']>;
  showId_gt?: Maybe<Scalars['String']>;
  showId_gte?: Maybe<Scalars['String']>;
  showId_contains?: Maybe<Scalars['String']>;
  showId_not_contains?: Maybe<Scalars['String']>;
  showId_starts_with?: Maybe<Scalars['String']>;
  showId_not_starts_with?: Maybe<Scalars['String']>;
  showId_ends_with?: Maybe<Scalars['String']>;
  showId_not_ends_with?: Maybe<Scalars['String']>;
  podcastId?: Maybe<Scalars['String']>;
  podcastId_not?: Maybe<Scalars['String']>;
  podcastId_in?: Maybe<Array<Scalars['String']>>;
  podcastId_not_in?: Maybe<Array<Scalars['String']>>;
  podcastId_lt?: Maybe<Scalars['String']>;
  podcastId_lte?: Maybe<Scalars['String']>;
  podcastId_gt?: Maybe<Scalars['String']>;
  podcastId_gte?: Maybe<Scalars['String']>;
  podcastId_contains?: Maybe<Scalars['String']>;
  podcastId_not_contains?: Maybe<Scalars['String']>;
  podcastId_starts_with?: Maybe<Scalars['String']>;
  podcastId_not_starts_with?: Maybe<Scalars['String']>;
  podcastId_ends_with?: Maybe<Scalars['String']>;
  podcastId_not_ends_with?: Maybe<Scalars['String']>;
  isMigrated?: Maybe<Scalars['Boolean']>;
  isMigrated_not?: Maybe<Scalars['Boolean']>;
  AND?: Maybe<Array<PodcastMigrationShowWhereInput>>;
  OR?: Maybe<Array<PodcastMigrationShowWhereInput>>;
  NOT?: Maybe<Array<PodcastMigrationShowWhereInput>>;
};

export type PodcastMigrationShowWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
  showId?: Maybe<Scalars['String']>;
};

export enum PodcastOrderBy {
  WeightDesc = 'weight_DESC'
}

export enum PodcastOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  WeightAsc = 'weight_ASC',
  WeightDesc = 'weight_DESC',
  BelongsToAsc = 'belongsTo_ASC',
  BelongsToDesc = 'belongsTo_DESC',
  ThirdPartyShowIdAsc = 'thirdPartyShowId_ASC',
  ThirdPartyShowIdDesc = 'thirdPartyShowId_DESC',
  CoverImageAsc = 'coverImage_ASC',
  CoverImageDesc = 'coverImage_DESC',
  LogoImageAsc = 'logoImage_ASC',
  LogoImageDesc = 'logoImage_DESC',
  BackgroundImageAsc = 'backgroundImage_ASC',
  BackgroundImageDesc = 'backgroundImage_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type PodcastPremiere = {
  __typename?: 'PodcastPremiere';
  id?: Maybe<Scalars['ID']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  premiereImage?: Maybe<Scalars['String']>;
  podcast?: Maybe<Podcast>;
};

export type PodcastPreviousValues = {
  __typename?: 'PodcastPreviousValues';
  id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  status: PodcastStatus;
  weight?: Maybe<Scalars['Int']>;
  belongsTo?: Maybe<Scalars['String']>;
  thirdPartyShowId?: Maybe<Scalars['String']>;
  coverImage?: Maybe<Scalars['String']>;
  logoImage?: Maybe<Scalars['String']>;
  backgroundImage?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export enum PodcastStatus {
  Unpublished = 'UNPUBLISHED',
  Published = 'PUBLISHED',
  Draft = 'DRAFT'
}

export type PodcastSubscriptionPayload = {
  __typename?: 'PodcastSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<Podcast>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<PodcastPreviousValues>;
};

export type PodcastSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<PodcastWhereInput>;
  AND?: Maybe<Array<PodcastSubscriptionWhereInput>>;
  OR?: Maybe<Array<PodcastSubscriptionWhereInput>>;
  NOT?: Maybe<Array<PodcastSubscriptionWhereInput>>;
};

export type PodcastUpdateDataInput = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<PodcastStatus>;
  weight?: Maybe<Scalars['Int']>;
  belongsTo?: Maybe<Scalars['String']>;
  thirdPartyShowId?: Maybe<Scalars['String']>;
  coverImage?: Maybe<Scalars['String']>;
  logoImage?: Maybe<Scalars['String']>;
  backgroundImage?: Maybe<Scalars['String']>;
  seasons?: Maybe<SeasonUpdateManyWithoutPodcastInput>;
  podcastEpisodes?: Maybe<PodcastEpisodeUpdateManyWithoutPodcastInput>;
  author?: Maybe<UserUpdateOneRequiredInput>;
  createdBy?: Maybe<UserUpdateOneRequiredInput>;
};

export type PodcastUpdateInput = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<PodcastStatus>;
  weight?: Maybe<Scalars['Int']>;
  belongsTo?: Maybe<Scalars['String']>;
  thirdPartyShowId?: Maybe<Scalars['String']>;
  coverImage?: Maybe<Scalars['String']>;
  logoImage?: Maybe<Scalars['String']>;
  backgroundImage?: Maybe<Scalars['String']>;
  seasons?: Maybe<SeasonUpdateManyWithoutPodcastInput>;
  podcastEpisodes?: Maybe<PodcastEpisodeUpdateManyWithoutPodcastInput>;
  author?: Maybe<UserUpdateOneRequiredInput>;
  createdBy?: Maybe<UserUpdateOneRequiredInput>;
};

export type PodcastUpdateInputCustom = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<PodcastStatus>;
  weight?: Maybe<Scalars['Int']>;
  belongsTo?: Maybe<Scalars['String']>;
  thirdPartyShowId?: Maybe<Scalars['String']>;
  coverImage?: Maybe<Scalars['String']>;
  logoImage?: Maybe<Scalars['String']>;
  backgroundImage?: Maybe<Scalars['String']>;
  authorId?: Maybe<Scalars['ID']>;
};

export type PodcastUpdateManyMutationInput = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<PodcastStatus>;
  weight?: Maybe<Scalars['Int']>;
  belongsTo?: Maybe<Scalars['String']>;
  thirdPartyShowId?: Maybe<Scalars['String']>;
  coverImage?: Maybe<Scalars['String']>;
  logoImage?: Maybe<Scalars['String']>;
  backgroundImage?: Maybe<Scalars['String']>;
};

export type PodcastUpdateOneInput = {
  create?: Maybe<PodcastCreateInput>;
  update?: Maybe<PodcastUpdateDataInput>;
  upsert?: Maybe<PodcastUpsertNestedInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<PodcastWhereUniqueInput>;
};

export type PodcastUpdateOneRequiredWithoutPodcastEpisodesInput = {
  create?: Maybe<PodcastCreateWithoutPodcastEpisodesInput>;
  update?: Maybe<PodcastUpdateWithoutPodcastEpisodesDataInput>;
  upsert?: Maybe<PodcastUpsertWithoutPodcastEpisodesInput>;
  connect?: Maybe<PodcastWhereUniqueInput>;
};

export type PodcastUpdateOneWithoutSeasonsInput = {
  create?: Maybe<PodcastCreateWithoutSeasonsInput>;
  update?: Maybe<PodcastUpdateWithoutSeasonsDataInput>;
  upsert?: Maybe<PodcastUpsertWithoutSeasonsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<PodcastWhereUniqueInput>;
};

export type PodcastUpdateWithoutPodcastEpisodesDataInput = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<PodcastStatus>;
  weight?: Maybe<Scalars['Int']>;
  belongsTo?: Maybe<Scalars['String']>;
  thirdPartyShowId?: Maybe<Scalars['String']>;
  coverImage?: Maybe<Scalars['String']>;
  logoImage?: Maybe<Scalars['String']>;
  backgroundImage?: Maybe<Scalars['String']>;
  seasons?: Maybe<SeasonUpdateManyWithoutPodcastInput>;
  author?: Maybe<UserUpdateOneRequiredInput>;
  createdBy?: Maybe<UserUpdateOneRequiredInput>;
};

export type PodcastUpdateWithoutSeasonsDataInput = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<PodcastStatus>;
  weight?: Maybe<Scalars['Int']>;
  belongsTo?: Maybe<Scalars['String']>;
  thirdPartyShowId?: Maybe<Scalars['String']>;
  coverImage?: Maybe<Scalars['String']>;
  logoImage?: Maybe<Scalars['String']>;
  backgroundImage?: Maybe<Scalars['String']>;
  podcastEpisodes?: Maybe<PodcastEpisodeUpdateManyWithoutPodcastInput>;
  author?: Maybe<UserUpdateOneRequiredInput>;
  createdBy?: Maybe<UserUpdateOneRequiredInput>;
};

export type PodcastUpsertNestedInput = {
  update: PodcastUpdateDataInput;
  create: PodcastCreateInput;
};

export type PodcastUpsertWithoutPodcastEpisodesInput = {
  update: PodcastUpdateWithoutPodcastEpisodesDataInput;
  create: PodcastCreateWithoutPodcastEpisodesInput;
};

export type PodcastUpsertWithoutSeasonsInput = {
  update: PodcastUpdateWithoutSeasonsDataInput;
  create: PodcastCreateWithoutSeasonsInput;
};

export type PodcastWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Scalars['String']>>;
  name_not_in?: Maybe<Array<Scalars['String']>>;
  name_lt?: Maybe<Scalars['String']>;
  name_lte?: Maybe<Scalars['String']>;
  name_gt?: Maybe<Scalars['String']>;
  name_gte?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  description_not?: Maybe<Scalars['String']>;
  description_in?: Maybe<Array<Scalars['String']>>;
  description_not_in?: Maybe<Array<Scalars['String']>>;
  description_lt?: Maybe<Scalars['String']>;
  description_lte?: Maybe<Scalars['String']>;
  description_gt?: Maybe<Scalars['String']>;
  description_gte?: Maybe<Scalars['String']>;
  description_contains?: Maybe<Scalars['String']>;
  description_not_contains?: Maybe<Scalars['String']>;
  description_starts_with?: Maybe<Scalars['String']>;
  description_not_starts_with?: Maybe<Scalars['String']>;
  description_ends_with?: Maybe<Scalars['String']>;
  description_not_ends_with?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  slug_not?: Maybe<Scalars['String']>;
  slug_in?: Maybe<Array<Scalars['String']>>;
  slug_not_in?: Maybe<Array<Scalars['String']>>;
  slug_lt?: Maybe<Scalars['String']>;
  slug_lte?: Maybe<Scalars['String']>;
  slug_gt?: Maybe<Scalars['String']>;
  slug_gte?: Maybe<Scalars['String']>;
  slug_contains?: Maybe<Scalars['String']>;
  slug_not_contains?: Maybe<Scalars['String']>;
  slug_starts_with?: Maybe<Scalars['String']>;
  slug_not_starts_with?: Maybe<Scalars['String']>;
  slug_ends_with?: Maybe<Scalars['String']>;
  slug_not_ends_with?: Maybe<Scalars['String']>;
  status?: Maybe<PodcastStatus>;
  status_not?: Maybe<PodcastStatus>;
  status_in?: Maybe<Array<PodcastStatus>>;
  status_not_in?: Maybe<Array<PodcastStatus>>;
  weight?: Maybe<Scalars['Int']>;
  weight_not?: Maybe<Scalars['Int']>;
  weight_in?: Maybe<Array<Scalars['Int']>>;
  weight_not_in?: Maybe<Array<Scalars['Int']>>;
  weight_lt?: Maybe<Scalars['Int']>;
  weight_lte?: Maybe<Scalars['Int']>;
  weight_gt?: Maybe<Scalars['Int']>;
  weight_gte?: Maybe<Scalars['Int']>;
  belongsTo?: Maybe<Scalars['String']>;
  belongsTo_not?: Maybe<Scalars['String']>;
  belongsTo_in?: Maybe<Array<Scalars['String']>>;
  belongsTo_not_in?: Maybe<Array<Scalars['String']>>;
  belongsTo_lt?: Maybe<Scalars['String']>;
  belongsTo_lte?: Maybe<Scalars['String']>;
  belongsTo_gt?: Maybe<Scalars['String']>;
  belongsTo_gte?: Maybe<Scalars['String']>;
  belongsTo_contains?: Maybe<Scalars['String']>;
  belongsTo_not_contains?: Maybe<Scalars['String']>;
  belongsTo_starts_with?: Maybe<Scalars['String']>;
  belongsTo_not_starts_with?: Maybe<Scalars['String']>;
  belongsTo_ends_with?: Maybe<Scalars['String']>;
  belongsTo_not_ends_with?: Maybe<Scalars['String']>;
  thirdPartyShowId?: Maybe<Scalars['String']>;
  thirdPartyShowId_not?: Maybe<Scalars['String']>;
  thirdPartyShowId_in?: Maybe<Array<Scalars['String']>>;
  thirdPartyShowId_not_in?: Maybe<Array<Scalars['String']>>;
  thirdPartyShowId_lt?: Maybe<Scalars['String']>;
  thirdPartyShowId_lte?: Maybe<Scalars['String']>;
  thirdPartyShowId_gt?: Maybe<Scalars['String']>;
  thirdPartyShowId_gte?: Maybe<Scalars['String']>;
  thirdPartyShowId_contains?: Maybe<Scalars['String']>;
  thirdPartyShowId_not_contains?: Maybe<Scalars['String']>;
  thirdPartyShowId_starts_with?: Maybe<Scalars['String']>;
  thirdPartyShowId_not_starts_with?: Maybe<Scalars['String']>;
  thirdPartyShowId_ends_with?: Maybe<Scalars['String']>;
  thirdPartyShowId_not_ends_with?: Maybe<Scalars['String']>;
  coverImage?: Maybe<Scalars['String']>;
  coverImage_not?: Maybe<Scalars['String']>;
  coverImage_in?: Maybe<Array<Scalars['String']>>;
  coverImage_not_in?: Maybe<Array<Scalars['String']>>;
  coverImage_lt?: Maybe<Scalars['String']>;
  coverImage_lte?: Maybe<Scalars['String']>;
  coverImage_gt?: Maybe<Scalars['String']>;
  coverImage_gte?: Maybe<Scalars['String']>;
  coverImage_contains?: Maybe<Scalars['String']>;
  coverImage_not_contains?: Maybe<Scalars['String']>;
  coverImage_starts_with?: Maybe<Scalars['String']>;
  coverImage_not_starts_with?: Maybe<Scalars['String']>;
  coverImage_ends_with?: Maybe<Scalars['String']>;
  coverImage_not_ends_with?: Maybe<Scalars['String']>;
  logoImage?: Maybe<Scalars['String']>;
  logoImage_not?: Maybe<Scalars['String']>;
  logoImage_in?: Maybe<Array<Scalars['String']>>;
  logoImage_not_in?: Maybe<Array<Scalars['String']>>;
  logoImage_lt?: Maybe<Scalars['String']>;
  logoImage_lte?: Maybe<Scalars['String']>;
  logoImage_gt?: Maybe<Scalars['String']>;
  logoImage_gte?: Maybe<Scalars['String']>;
  logoImage_contains?: Maybe<Scalars['String']>;
  logoImage_not_contains?: Maybe<Scalars['String']>;
  logoImage_starts_with?: Maybe<Scalars['String']>;
  logoImage_not_starts_with?: Maybe<Scalars['String']>;
  logoImage_ends_with?: Maybe<Scalars['String']>;
  logoImage_not_ends_with?: Maybe<Scalars['String']>;
  backgroundImage?: Maybe<Scalars['String']>;
  backgroundImage_not?: Maybe<Scalars['String']>;
  backgroundImage_in?: Maybe<Array<Scalars['String']>>;
  backgroundImage_not_in?: Maybe<Array<Scalars['String']>>;
  backgroundImage_lt?: Maybe<Scalars['String']>;
  backgroundImage_lte?: Maybe<Scalars['String']>;
  backgroundImage_gt?: Maybe<Scalars['String']>;
  backgroundImage_gte?: Maybe<Scalars['String']>;
  backgroundImage_contains?: Maybe<Scalars['String']>;
  backgroundImage_not_contains?: Maybe<Scalars['String']>;
  backgroundImage_starts_with?: Maybe<Scalars['String']>;
  backgroundImage_not_starts_with?: Maybe<Scalars['String']>;
  backgroundImage_ends_with?: Maybe<Scalars['String']>;
  backgroundImage_not_ends_with?: Maybe<Scalars['String']>;
  seasons_every?: Maybe<SeasonWhereInput>;
  seasons_some?: Maybe<SeasonWhereInput>;
  seasons_none?: Maybe<SeasonWhereInput>;
  podcastEpisodes_every?: Maybe<PodcastEpisodeWhereInput>;
  podcastEpisodes_some?: Maybe<PodcastEpisodeWhereInput>;
  podcastEpisodes_none?: Maybe<PodcastEpisodeWhereInput>;
  author?: Maybe<UserWhereInput>;
  createdBy?: Maybe<UserWhereInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<PodcastWhereInput>>;
  OR?: Maybe<Array<PodcastWhereInput>>;
  NOT?: Maybe<Array<PodcastWhereInput>>;
};

export type PodcastWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
  slug?: Maybe<Scalars['String']>;
};

export type PremiereImageSignedUrlDataInput = {
  fileName: Scalars['String'];
};

export type PremiereModule = {
  __typename?: 'PremiereModule';
  id: Scalars['ID'];
  referenceType: PremiereModuleType;
  referenceId?: Maybe<Scalars['String']>;
  premiereImage?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type PremiereModuleConnection = {
  __typename?: 'PremiereModuleConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<PremiereModuleEdge>>;
  aggregate: AggregatePremiereModule;
};

export type PremiereModuleCreateInput = {
  id?: Maybe<Scalars['ID']>;
  referenceType: PremiereModuleType;
  referenceId?: Maybe<Scalars['String']>;
  premiereImage?: Maybe<Scalars['String']>;
};

export type PremiereModuleCustomInput = {
  order?: Maybe<Scalars['Int']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  type: PremiereModuleType;
  referenceId: Scalars['String'];
  premiereImage?: Maybe<Scalars['String']>;
};

export type PremiereModuleEdge = {
  __typename?: 'PremiereModuleEdge';
  node: PremiereModule;
  cursor: Scalars['String'];
};

export enum PremiereModuleOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  ReferenceTypeAsc = 'referenceType_ASC',
  ReferenceTypeDesc = 'referenceType_DESC',
  ReferenceIdAsc = 'referenceId_ASC',
  ReferenceIdDesc = 'referenceId_DESC',
  PremiereImageAsc = 'premiereImage_ASC',
  PremiereImageDesc = 'premiereImage_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type PremiereModulePreviousValues = {
  __typename?: 'PremiereModulePreviousValues';
  id: Scalars['ID'];
  referenceType: PremiereModuleType;
  referenceId?: Maybe<Scalars['String']>;
  premiereImage?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type PremiereModuleSubscriptionPayload = {
  __typename?: 'PremiereModuleSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<PremiereModule>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<PremiereModulePreviousValues>;
};

export type PremiereModuleSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<PremiereModuleWhereInput>;
  AND?: Maybe<Array<PremiereModuleSubscriptionWhereInput>>;
  OR?: Maybe<Array<PremiereModuleSubscriptionWhereInput>>;
  NOT?: Maybe<Array<PremiereModuleSubscriptionWhereInput>>;
};

export enum PremiereModuleType {
  EpisodePremiere = 'EPISODE_PREMIERE',
  PodcastEpisodePremiere = 'PODCAST_EPISODE_PREMIERE',
  VideoPremiere = 'VIDEO_PREMIERE',
  ShowPremiere = 'SHOW_PREMIERE',
  PodcastPremiere = 'PODCAST_PREMIERE'
}

export type PremiereModuleUpdateInput = {
  referenceType?: Maybe<PremiereModuleType>;
  referenceId?: Maybe<Scalars['String']>;
  premiereImage?: Maybe<Scalars['String']>;
};

export type PremiereModuleUpdateManyMutationInput = {
  referenceType?: Maybe<PremiereModuleType>;
  referenceId?: Maybe<Scalars['String']>;
  premiereImage?: Maybe<Scalars['String']>;
};

export type PremiereModuleWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  referenceType?: Maybe<PremiereModuleType>;
  referenceType_not?: Maybe<PremiereModuleType>;
  referenceType_in?: Maybe<Array<PremiereModuleType>>;
  referenceType_not_in?: Maybe<Array<PremiereModuleType>>;
  referenceId?: Maybe<Scalars['String']>;
  referenceId_not?: Maybe<Scalars['String']>;
  referenceId_in?: Maybe<Array<Scalars['String']>>;
  referenceId_not_in?: Maybe<Array<Scalars['String']>>;
  referenceId_lt?: Maybe<Scalars['String']>;
  referenceId_lte?: Maybe<Scalars['String']>;
  referenceId_gt?: Maybe<Scalars['String']>;
  referenceId_gte?: Maybe<Scalars['String']>;
  referenceId_contains?: Maybe<Scalars['String']>;
  referenceId_not_contains?: Maybe<Scalars['String']>;
  referenceId_starts_with?: Maybe<Scalars['String']>;
  referenceId_not_starts_with?: Maybe<Scalars['String']>;
  referenceId_ends_with?: Maybe<Scalars['String']>;
  referenceId_not_ends_with?: Maybe<Scalars['String']>;
  premiereImage?: Maybe<Scalars['String']>;
  premiereImage_not?: Maybe<Scalars['String']>;
  premiereImage_in?: Maybe<Array<Scalars['String']>>;
  premiereImage_not_in?: Maybe<Array<Scalars['String']>>;
  premiereImage_lt?: Maybe<Scalars['String']>;
  premiereImage_lte?: Maybe<Scalars['String']>;
  premiereImage_gt?: Maybe<Scalars['String']>;
  premiereImage_gte?: Maybe<Scalars['String']>;
  premiereImage_contains?: Maybe<Scalars['String']>;
  premiereImage_not_contains?: Maybe<Scalars['String']>;
  premiereImage_starts_with?: Maybe<Scalars['String']>;
  premiereImage_not_starts_with?: Maybe<Scalars['String']>;
  premiereImage_ends_with?: Maybe<Scalars['String']>;
  premiereImage_not_ends_with?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<PremiereModuleWhereInput>>;
  OR?: Maybe<Array<PremiereModuleWhereInput>>;
  NOT?: Maybe<Array<PremiereModuleWhereInput>>;
};

export type PremiereModuleWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type Query = {
  __typename?: 'Query';
  activityLog?: Maybe<ActivityLog>;
  activityLogs: Array<Maybe<ActivityLog>>;
  activityLogsConnection: ActivityLogConnection;
  caption?: Maybe<Caption>;
  captions: Array<Maybe<Caption>>;
  captionsConnection: CaptionConnection;
  carouselModule?: Maybe<CarouselModule>;
  carouselModules: Array<Maybe<CarouselModule>>;
  carouselModulesConnection: CarouselModuleConnection;
  clip?: Maybe<Clip>;
  clips: Array<Maybe<Clip>>;
  clipsConnection: ClipConnection;
  clipCarouselModuleClips?: Maybe<ClipCarouselModuleClips>;
  clipCarouselModuleClipses: Array<Maybe<ClipCarouselModuleClips>>;
  clipCarouselModuleClipsesConnection: ClipCarouselModuleClipsConnection;
  contentfulWebhook?: Maybe<ContentfulWebhook>;
  contentfulWebhooks: Array<Maybe<ContentfulWebhook>>;
  contentfulWebhooksConnection: ContentfulWebhookConnection;
  continueListeningModule?: Maybe<ContinueListeningModule>;
  continueListeningModules: Array<Maybe<ContinueListeningModule>>;
  continueListeningModulesConnection: ContinueListeningModuleConnection;
  continueWatchingModule?: Maybe<ContinueWatchingModule>;
  continueWatchingModules: Array<Maybe<ContinueWatchingModule>>;
  continueWatchingModulesConnection: ContinueWatchingModuleConnection;
  episode?: Maybe<Episode>;
  episodes: Array<Maybe<Episode>>;
  episodesConnection: EpisodeConnection;
  episodeCarouselModuleEpisodes?: Maybe<EpisodeCarouselModuleEpisodes>;
  episodeCarouselModuleEpisodeses: Array<Maybe<EpisodeCarouselModuleEpisodes>>;
  episodeCarouselModuleEpisodesesConnection: EpisodeCarouselModuleEpisodesConnection;
  episodeSegment?: Maybe<EpisodeSegment>;
  episodeSegments: Array<Maybe<EpisodeSegment>>;
  episodeSegmentsConnection: EpisodeSegmentConnection;
  freeformCarouselModuleFreeforms?: Maybe<FreeformCarouselModuleFreeforms>;
  freeformCarouselModuleFreeformses: Array<Maybe<FreeformCarouselModuleFreeforms>>;
  freeformCarouselModuleFreeformsesConnection: FreeformCarouselModuleFreeformsConnection;
  freeformHighlightModule?: Maybe<FreeformHighlightModule>;
  freeformHighlightModules: Array<Maybe<FreeformHighlightModule>>;
  freeformHighlightModulesConnection: FreeformHighlightModuleConnection;
  module?: Maybe<Module>;
  modules: Array<Maybe<Module>>;
  modulesConnection: ModuleConnection;
  modulePage?: Maybe<ModulePage>;
  modulePages: Array<Maybe<ModulePage>>;
  modulePagesConnection: ModulePageConnection;
  podcast?: Maybe<Podcast>;
  podcasts: Array<Maybe<Podcast>>;
  podcastsConnection: PodcastConnection;
  podcastCarouselModulePodcasts?: Maybe<PodcastCarouselModulePodcasts>;
  podcastCarouselModulePodcastses: Array<Maybe<PodcastCarouselModulePodcasts>>;
  podcastCarouselModulePodcastsesConnection: PodcastCarouselModulePodcastsConnection;
  podcastEpisode?: Maybe<PodcastEpisode>;
  podcastEpisodes: Array<Maybe<PodcastEpisode>>;
  podcastEpisodesConnection: PodcastEpisodeConnection;
  podcastEpisodeCarouselModulePodcastEpisodes?: Maybe<PodcastEpisodeCarouselModulePodcastEpisodes>;
  podcastEpisodeCarouselModulePodcastEpisodeses: Array<Maybe<PodcastEpisodeCarouselModulePodcastEpisodes>>;
  podcastEpisodeCarouselModulePodcastEpisodesesConnection: PodcastEpisodeCarouselModulePodcastEpisodesConnection;
  podcastMigrationEpisode?: Maybe<PodcastMigrationEpisode>;
  podcastMigrationEpisodes: Array<Maybe<PodcastMigrationEpisode>>;
  podcastMigrationEpisodesConnection: PodcastMigrationEpisodeConnection;
  podcastMigrationSeason?: Maybe<PodcastMigrationSeason>;
  podcastMigrationSeasons: Array<Maybe<PodcastMigrationSeason>>;
  podcastMigrationSeasonsConnection: PodcastMigrationSeasonConnection;
  podcastMigrationShow?: Maybe<PodcastMigrationShow>;
  podcastMigrationShows: Array<Maybe<PodcastMigrationShow>>;
  podcastMigrationShowsConnection: PodcastMigrationShowConnection;
  premiereModule?: Maybe<PremiereModule>;
  premiereModules: Array<Maybe<PremiereModule>>;
  premiereModulesConnection: PremiereModuleConnection;
  season?: Maybe<Season>;
  seasons: Array<Maybe<Season>>;
  seasonsConnection: SeasonConnection;
  seasonEpisode?: Maybe<SeasonEpisode>;
  seasonEpisodes: Array<Maybe<SeasonEpisode>>;
  seasonEpisodesConnection: SeasonEpisodeConnection;
  segmentAudioStatus?: Maybe<SegmentAudioStatus>;
  segmentAudioStatuses: Array<Maybe<SegmentAudioStatus>>;
  segmentAudioStatusesConnection: SegmentAudioStatusConnection;
  show?: Maybe<Show>;
  shows: Array<Maybe<Show>>;
  showsConnection: ShowConnection;
  showCarouselModuleShows?: Maybe<ShowCarouselModuleShows>;
  showCarouselModuleShowses: Array<Maybe<ShowCarouselModuleShows>>;
  showCarouselModuleShowsesConnection: ShowCarouselModuleShowsConnection;
  user?: Maybe<User>;
  users: Array<Maybe<User>>;
  usersConnection: UserConnection;
  video?: Maybe<Video>;
  videos: Array<Maybe<Video>>;
  videosConnection: VideoConnection;
  videoCarouselModuleVideos?: Maybe<VideoCarouselModuleVideos>;
  videoCarouselModuleVideoses: Array<Maybe<VideoCarouselModuleVideos>>;
  videoCarouselModuleVideosesConnection: VideoCarouselModuleVideosConnection;
  node?: Maybe<Node>;
  captionSingedPutUrl?: Maybe<SignedUrlType>;
  getClipSignedVideoPutUrl: UploadUrlType;
  getClipSignedPutUrl: SignedUrlType;
  listShowForClip?: Maybe<Array<Maybe<ListShowForClipRes>>>;
  listVideoForClip?: Maybe<Array<Maybe<ListVideoForClipRes>>>;
  getCountrySelectionForRestriction?: Maybe<Array<Maybe<ContinentForSelection>>>;
  getSignedUrl?: Maybe<GetSignedUrlRes>;
  getLiveEpisodes: Array<Maybe<Episode>>;
  getEpisodeSignedPutUrl: SignedUrlType;
  getPremiereImageSignedPutUrl: SignedUrlType;
  getModularPage?: Maybe<ModularPageRes>;
  previewModularPage?: Maybe<ModularPageRes>;
  getContinueWatchingModule?: Maybe<ContinueWatching>;
  getContinueListeningModule?: Maybe<ContinueListening>;
  getMuxData?: Maybe<Scalars['JSON']>;
  getParselyTopPosts: Array<Maybe<ParselyPost>>;
  getParselyTopAuthors: Array<Maybe<ParselyAuthor>>;
  getParselyTopTopics: Array<Maybe<ParselyTopic>>;
  listPodcastForSeason?: Maybe<Array<Maybe<ListPodcastForSeasonRes>>>;
  getPodcastSignedPutUrl: SignedUrlType;
  getPodcastSignedPutUrls: SignedUrlsType;
  getPodcast?: Maybe<GetPodcastRes>;
  listPodcast?: Maybe<Array<Maybe<GetPodcastRes>>>;
  getPodcastDetails?: Maybe<GetPodcastRes>;
  listPodcastDetails?: Maybe<Array<Maybe<GetPodcastRes>>>;
  getPodcastEpisodeSignedPutUrl: SignedUrlType;
  listPodcastEpisodeForSeason?: Maybe<ListPodcastEpisodeForSeasonRes>;
  getPodcastEpisodeSignedAudioPutUrl: UploadUrlType;
  getPodcastEpisode?: Maybe<GetPodcastEpisodeRes>;
  listPodcastEpisode?: Maybe<Array<Maybe<GetPodcastEpisodeRes>>>;
  getPodcastEpisodeDetails?: Maybe<GetPodcastEpisodeRes>;
  listPodcastEpisodeDetails?: Maybe<Array<Maybe<GetPodcastEpisodeRes>>>;
  getSeasonSignedPutUrl: SignedUrlType;
  listEpisodeForSeason?: Maybe<ListEpisodeForSeasonRes>;
  getSeasonEpisodes?: Maybe<Array<Maybe<SeasonEpisode>>>;
  listShowForSeason?: Maybe<Array<Maybe<ListShowForSeasonRes>>>;
  listSeasonEpisodes?: Maybe<Season>;
  getSegmentSimulateLiveVideoPutUrl: SignedUrlType;
  getSegmentLiveVideoUrl: SegmentLiveVideoData;
  getSegmentSignedPutUrl: SignedUrlType;
  getSegmentSignedVideoPutUrl: UploadUrlType;
  getShowSignedPutUrl: SignedUrlType;
  getShowSignedPutUrls: SignedUrlsType;
  currentUser?: Maybe<User>;
  getUserProfileSignedPutUrl: SignedUrlType;
  getVideoSignedVideoPutUrl: UploadUrlType;
  getVideoLiveStreamUrl: VideoLiveStreamData;
  getVideoSignedPutUrl: SignedUrlType;
};


export type QueryActivityLogArgs = {
  where: ActivityLogWhereUniqueInput;
};


export type QueryActivityLogsArgs = {
  where?: Maybe<ActivityLogWhereInput>;
  orderBy?: Maybe<ActivityLogOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryActivityLogsConnectionArgs = {
  where?: Maybe<ActivityLogWhereInput>;
  orderBy?: Maybe<ActivityLogOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryCaptionArgs = {
  where: CaptionWhereUniqueInput;
};


export type QueryCaptionsArgs = {
  where?: Maybe<CaptionWhereInput>;
  orderBy?: Maybe<CaptionOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryCaptionsConnectionArgs = {
  where?: Maybe<CaptionWhereInput>;
  orderBy?: Maybe<CaptionOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryCarouselModuleArgs = {
  where: CarouselModuleWhereUniqueInput;
};


export type QueryCarouselModulesArgs = {
  where?: Maybe<CarouselModuleWhereInput>;
  orderBy?: Maybe<CarouselModuleOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryCarouselModulesConnectionArgs = {
  where?: Maybe<CarouselModuleWhereInput>;
  orderBy?: Maybe<CarouselModuleOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryClipArgs = {
  where: ClipWhereUniqueInput;
};


export type QueryClipsArgs = {
  where?: Maybe<ClipWhereInput>;
  orderBy?: Maybe<ClipOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryClipsConnectionArgs = {
  where?: Maybe<ClipWhereInput>;
  orderBy?: Maybe<ClipOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryClipCarouselModuleClipsArgs = {
  where: ClipCarouselModuleClipsWhereUniqueInput;
};


export type QueryClipCarouselModuleClipsesArgs = {
  where?: Maybe<ClipCarouselModuleClipsWhereInput>;
  orderBy?: Maybe<ClipCarouselModuleClipsOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryClipCarouselModuleClipsesConnectionArgs = {
  where?: Maybe<ClipCarouselModuleClipsWhereInput>;
  orderBy?: Maybe<ClipCarouselModuleClipsOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryContentfulWebhookArgs = {
  where: ContentfulWebhookWhereUniqueInput;
};


export type QueryContentfulWebhooksArgs = {
  where?: Maybe<ContentfulWebhookWhereInput>;
  orderBy?: Maybe<ContentfulWebhookOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryContentfulWebhooksConnectionArgs = {
  where?: Maybe<ContentfulWebhookWhereInput>;
  orderBy?: Maybe<ContentfulWebhookOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryContinueListeningModuleArgs = {
  where: ContinueListeningModuleWhereUniqueInput;
};


export type QueryContinueListeningModulesArgs = {
  where?: Maybe<ContinueListeningModuleWhereInput>;
  orderBy?: Maybe<ContinueListeningModuleOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryContinueListeningModulesConnectionArgs = {
  where?: Maybe<ContinueListeningModuleWhereInput>;
  orderBy?: Maybe<ContinueListeningModuleOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryContinueWatchingModuleArgs = {
  where: ContinueWatchingModuleWhereUniqueInput;
};


export type QueryContinueWatchingModulesArgs = {
  where?: Maybe<ContinueWatchingModuleWhereInput>;
  orderBy?: Maybe<ContinueWatchingModuleOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryContinueWatchingModulesConnectionArgs = {
  where?: Maybe<ContinueWatchingModuleWhereInput>;
  orderBy?: Maybe<ContinueWatchingModuleOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryEpisodeArgs = {
  where: EpisodeWhereUniqueInput;
};


export type QueryEpisodesArgs = {
  where?: Maybe<EpisodeWhereInput>;
  orderBy?: Maybe<EpisodeOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryEpisodesConnectionArgs = {
  where?: Maybe<EpisodeWhereInput>;
  orderBy?: Maybe<EpisodeOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryEpisodeCarouselModuleEpisodesArgs = {
  where: EpisodeCarouselModuleEpisodesWhereUniqueInput;
};


export type QueryEpisodeCarouselModuleEpisodesesArgs = {
  where?: Maybe<EpisodeCarouselModuleEpisodesWhereInput>;
  orderBy?: Maybe<EpisodeCarouselModuleEpisodesOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryEpisodeCarouselModuleEpisodesesConnectionArgs = {
  where?: Maybe<EpisodeCarouselModuleEpisodesWhereInput>;
  orderBy?: Maybe<EpisodeCarouselModuleEpisodesOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryEpisodeSegmentArgs = {
  where: EpisodeSegmentWhereUniqueInput;
};


export type QueryEpisodeSegmentsArgs = {
  where?: Maybe<EpisodeSegmentWhereInput>;
  orderBy?: Maybe<EpisodeSegmentOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryEpisodeSegmentsConnectionArgs = {
  where?: Maybe<EpisodeSegmentWhereInput>;
  orderBy?: Maybe<EpisodeSegmentOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryFreeformCarouselModuleFreeformsArgs = {
  where: FreeformCarouselModuleFreeformsWhereUniqueInput;
};


export type QueryFreeformCarouselModuleFreeformsesArgs = {
  where?: Maybe<FreeformCarouselModuleFreeformsWhereInput>;
  orderBy?: Maybe<FreeformCarouselModuleFreeformsOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryFreeformCarouselModuleFreeformsesConnectionArgs = {
  where?: Maybe<FreeformCarouselModuleFreeformsWhereInput>;
  orderBy?: Maybe<FreeformCarouselModuleFreeformsOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryFreeformHighlightModuleArgs = {
  where: FreeformHighlightModuleWhereUniqueInput;
};


export type QueryFreeformHighlightModulesArgs = {
  where?: Maybe<FreeformHighlightModuleWhereInput>;
  orderBy?: Maybe<FreeformHighlightModuleOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryFreeformHighlightModulesConnectionArgs = {
  where?: Maybe<FreeformHighlightModuleWhereInput>;
  orderBy?: Maybe<FreeformHighlightModuleOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryModuleArgs = {
  where: ModuleWhereUniqueInput;
};


export type QueryModulesArgs = {
  where?: Maybe<ModuleWhereInput>;
  orderBy?: Maybe<ModuleOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryModulesConnectionArgs = {
  where?: Maybe<ModuleWhereInput>;
  orderBy?: Maybe<ModuleOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryModulePageArgs = {
  where: ModulePageWhereUniqueInput;
};


export type QueryModulePagesArgs = {
  where?: Maybe<ModulePageWhereInput>;
  orderBy?: Maybe<ModulePageOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryModulePagesConnectionArgs = {
  where?: Maybe<ModulePageWhereInput>;
  orderBy?: Maybe<ModulePageOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryPodcastArgs = {
  where: PodcastWhereUniqueInput;
};


export type QueryPodcastsArgs = {
  where?: Maybe<PodcastWhereInput>;
  orderBy?: Maybe<PodcastOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryPodcastsConnectionArgs = {
  where?: Maybe<PodcastWhereInput>;
  orderBy?: Maybe<PodcastOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryPodcastCarouselModulePodcastsArgs = {
  where: PodcastCarouselModulePodcastsWhereUniqueInput;
};


export type QueryPodcastCarouselModulePodcastsesArgs = {
  where?: Maybe<PodcastCarouselModulePodcastsWhereInput>;
  orderBy?: Maybe<PodcastCarouselModulePodcastsOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryPodcastCarouselModulePodcastsesConnectionArgs = {
  where?: Maybe<PodcastCarouselModulePodcastsWhereInput>;
  orderBy?: Maybe<PodcastCarouselModulePodcastsOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryPodcastEpisodeArgs = {
  where: PodcastEpisodeWhereUniqueInput;
};


export type QueryPodcastEpisodesArgs = {
  where?: Maybe<PodcastEpisodeWhereInput>;
  orderBy?: Maybe<PodcastEpisodeOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryPodcastEpisodesConnectionArgs = {
  where?: Maybe<PodcastEpisodeWhereInput>;
  orderBy?: Maybe<PodcastEpisodeOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryPodcastEpisodeCarouselModulePodcastEpisodesArgs = {
  where: PodcastEpisodeCarouselModulePodcastEpisodesWhereUniqueInput;
};


export type QueryPodcastEpisodeCarouselModulePodcastEpisodesesArgs = {
  where?: Maybe<PodcastEpisodeCarouselModulePodcastEpisodesWhereInput>;
  orderBy?: Maybe<PodcastEpisodeCarouselModulePodcastEpisodesOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryPodcastEpisodeCarouselModulePodcastEpisodesesConnectionArgs = {
  where?: Maybe<PodcastEpisodeCarouselModulePodcastEpisodesWhereInput>;
  orderBy?: Maybe<PodcastEpisodeCarouselModulePodcastEpisodesOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryPodcastMigrationEpisodeArgs = {
  where: PodcastMigrationEpisodeWhereUniqueInput;
};


export type QueryPodcastMigrationEpisodesArgs = {
  where?: Maybe<PodcastMigrationEpisodeWhereInput>;
  orderBy?: Maybe<PodcastMigrationEpisodeOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryPodcastMigrationEpisodesConnectionArgs = {
  where?: Maybe<PodcastMigrationEpisodeWhereInput>;
  orderBy?: Maybe<PodcastMigrationEpisodeOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryPodcastMigrationSeasonArgs = {
  where: PodcastMigrationSeasonWhereUniqueInput;
};


export type QueryPodcastMigrationSeasonsArgs = {
  where?: Maybe<PodcastMigrationSeasonWhereInput>;
  orderBy?: Maybe<PodcastMigrationSeasonOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryPodcastMigrationSeasonsConnectionArgs = {
  where?: Maybe<PodcastMigrationSeasonWhereInput>;
  orderBy?: Maybe<PodcastMigrationSeasonOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryPodcastMigrationShowArgs = {
  where: PodcastMigrationShowWhereUniqueInput;
};


export type QueryPodcastMigrationShowsArgs = {
  where?: Maybe<PodcastMigrationShowWhereInput>;
  orderBy?: Maybe<PodcastMigrationShowOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryPodcastMigrationShowsConnectionArgs = {
  where?: Maybe<PodcastMigrationShowWhereInput>;
  orderBy?: Maybe<PodcastMigrationShowOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryPremiereModuleArgs = {
  where: PremiereModuleWhereUniqueInput;
};


export type QueryPremiereModulesArgs = {
  where?: Maybe<PremiereModuleWhereInput>;
  orderBy?: Maybe<PremiereModuleOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryPremiereModulesConnectionArgs = {
  where?: Maybe<PremiereModuleWhereInput>;
  orderBy?: Maybe<PremiereModuleOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QuerySeasonArgs = {
  where: SeasonWhereUniqueInput;
};


export type QuerySeasonsArgs = {
  where?: Maybe<SeasonWhereInput>;
  orderBy?: Maybe<SeasonOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QuerySeasonsConnectionArgs = {
  where?: Maybe<SeasonWhereInput>;
  orderBy?: Maybe<SeasonOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QuerySeasonEpisodeArgs = {
  where: SeasonEpisodeWhereUniqueInput;
};


export type QuerySeasonEpisodesArgs = {
  where?: Maybe<SeasonEpisodeWhereInput>;
  orderBy?: Maybe<SeasonEpisodeOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QuerySeasonEpisodesConnectionArgs = {
  where?: Maybe<SeasonEpisodeWhereInput>;
  orderBy?: Maybe<SeasonEpisodeOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QuerySegmentAudioStatusArgs = {
  where: SegmentAudioStatusWhereUniqueInput;
};


export type QuerySegmentAudioStatusesArgs = {
  where?: Maybe<SegmentAudioStatusWhereInput>;
  orderBy?: Maybe<SegmentAudioStatusOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QuerySegmentAudioStatusesConnectionArgs = {
  where?: Maybe<SegmentAudioStatusWhereInput>;
  orderBy?: Maybe<SegmentAudioStatusOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryShowArgs = {
  where: ShowWhereUniqueInput;
};


export type QueryShowsArgs = {
  where?: Maybe<ShowWhereInput>;
  orderBy?: Maybe<ShowOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryShowsConnectionArgs = {
  where?: Maybe<ShowWhereInput>;
  orderBy?: Maybe<ShowOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryShowCarouselModuleShowsArgs = {
  where: ShowCarouselModuleShowsWhereUniqueInput;
};


export type QueryShowCarouselModuleShowsesArgs = {
  where?: Maybe<ShowCarouselModuleShowsWhereInput>;
  orderBy?: Maybe<ShowCarouselModuleShowsOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryShowCarouselModuleShowsesConnectionArgs = {
  where?: Maybe<ShowCarouselModuleShowsWhereInput>;
  orderBy?: Maybe<ShowCarouselModuleShowsOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryUserArgs = {
  where: UserWhereUniqueInput;
};


export type QueryUsersArgs = {
  where?: Maybe<UserWhereInput>;
  orderBy?: Maybe<UserOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryUsersConnectionArgs = {
  where?: Maybe<UserWhereInput>;
  orderBy?: Maybe<UserOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryVideoArgs = {
  where: VideoWhereUniqueInput;
};


export type QueryVideosArgs = {
  where?: Maybe<VideoWhereInput>;
  orderBy?: Maybe<VideoOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryVideosConnectionArgs = {
  where?: Maybe<VideoWhereInput>;
  orderBy?: Maybe<VideoOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryVideoCarouselModuleVideosArgs = {
  where: VideoCarouselModuleVideosWhereUniqueInput;
};


export type QueryVideoCarouselModuleVideosesArgs = {
  where?: Maybe<VideoCarouselModuleVideosWhereInput>;
  orderBy?: Maybe<VideoCarouselModuleVideosOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryVideoCarouselModuleVideosesConnectionArgs = {
  where?: Maybe<VideoCarouselModuleVideosWhereInput>;
  orderBy?: Maybe<VideoCarouselModuleVideosOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryNodeArgs = {
  id: Scalars['ID'];
};


export type QueryCaptionSingedPutUrlArgs = {
  fileName: Scalars['String'];
};


export type QueryGetClipSignedVideoPutUrlArgs = {
  where: ClipWhereUniqueInput;
  corsOrigin?: Maybe<Scalars['String']>;
};


export type QueryGetClipSignedPutUrlArgs = {
  data: ClipSignedUrlDataInput;
};


export type QueryGetSignedUrlArgs = {
  url: Scalars['String'];
};


export type QueryGetLiveEpisodesArgs = {
  where?: Maybe<LiveEpisodeWhereInput>;
};


export type QueryGetEpisodeSignedPutUrlArgs = {
  data?: Maybe<EpisodeSignedUrlDataInput>;
};


export type QueryGetPremiereImageSignedPutUrlArgs = {
  data: PremiereImageSignedUrlDataInput;
};


export type QueryGetModularPageArgs = {
  where: ModulePageWhereUniqueInput;
};


export type QueryPreviewModularPageArgs = {
  where: ModulePageWhereUniqueInput;
};


export type QueryGetContinueWatchingModuleArgs = {
  where: ModuleWhereUniqueInput;
};


export type QueryGetContinueListeningModuleArgs = {
  where: ModuleWhereUniqueInput;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryGetMuxDataArgs = {
  where: AssetIdInput;
};


export type QueryGetParselyTopPostsArgs = {
  where: ParselyPostInput;
};


export type QueryGetParselyTopAuthorsArgs = {
  where: ParselyAuthorInput;
};


export type QueryGetParselyTopTopicsArgs = {
  where: ParselyTopicInput;
};


export type QueryGetPodcastSignedPutUrlArgs = {
  data?: Maybe<SignedUrlDataInput>;
};


export type QueryGetPodcastSignedPutUrlsArgs = {
  data?: Maybe<Array<Maybe<SignedUrlDataInput>>>;
};


export type QueryGetPodcastArgs = {
  where: PodcastWhereUniqueInput;
};


export type QueryListPodcastArgs = {
  where?: Maybe<PodcastWhereInput>;
  orderBy?: Maybe<PodcastOrderBy>;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryGetPodcastDetailsArgs = {
  where: PodcastWhereUniqueInput;
};


export type QueryListPodcastDetailsArgs = {
  where?: Maybe<PodcastWhereInput>;
  orderBy?: Maybe<PodcastOrderBy>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryGetPodcastEpisodeSignedPutUrlArgs = {
  data?: Maybe<PodcastEpisodeSignedUrlDataInput>;
};


export type QueryListPodcastEpisodeForSeasonArgs = {
  where: ListPodcastEpisodeForSeasonWhereInput;
  listSeasonEpisode?: Maybe<Scalars['Boolean']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<PodcastEpisodeOrderByInput>;
};


export type QueryGetPodcastEpisodeSignedAudioPutUrlArgs = {
  where?: Maybe<PodcastEpisodeDataInput>;
  corsOrigin?: Maybe<Scalars['String']>;
  withAds: Scalars['Boolean'];
};


export type QueryGetPodcastEpisodeArgs = {
  where: PodcastEpisodeWhereUniqueInput;
};


export type QueryListPodcastEpisodeArgs = {
  where?: Maybe<PodcastEpisodeWhereInput>;
  orderBy?: Maybe<PodcastEpisodeOrderBy>;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
};


export type QueryGetPodcastEpisodeDetailsArgs = {
  where: PodcastEpisodeWhereUniqueInput;
};


export type QueryListPodcastEpisodeDetailsArgs = {
  where?: Maybe<PodcastEpisodeWhereInput>;
  orderBy?: Maybe<PodcastEpisodeOrderBy>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


export type QueryGetSeasonSignedPutUrlArgs = {
  data: SeasonSignedUrlDataInput;
};


export type QueryListEpisodeForSeasonArgs = {
  where: ListEpisodeForSeasonWhereInput;
  listSeasonEpisode?: Maybe<Scalars['Boolean']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<EpisodeOrderByInput>;
};


export type QueryGetSeasonEpisodesArgs = {
  where: GetSeasonEpisodesInput;
  orderBy?: Maybe<SeasonEpisodeOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryListSeasonEpisodesArgs = {
  where: SeasonWhereUniqueInput;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
};


export type QueryGetSegmentSimulateLiveVideoPutUrlArgs = {
  data?: Maybe<GetSegmentSimulateLiveVideoPutUrlInput>;
};


export type QueryGetSegmentLiveVideoUrlArgs = {
  where?: Maybe<SegmentLiveVideoDataInput>;
};


export type QueryGetSegmentSignedPutUrlArgs = {
  data?: Maybe<SegmentSignedUrlDataInput>;
};


export type QueryGetSegmentSignedVideoPutUrlArgs = {
  where?: Maybe<SegmentDataInput>;
  corsOrigin?: Maybe<Scalars['String']>;
};


export type QueryGetShowSignedPutUrlArgs = {
  data?: Maybe<SignedUrlDataInput>;
};


export type QueryGetShowSignedPutUrlsArgs = {
  data?: Maybe<Array<Maybe<SignedUrlDataInput>>>;
};


export type QueryGetUserProfileSignedPutUrlArgs = {
  data?: Maybe<SignedUrlDataInput>;
};


export type QueryGetVideoSignedVideoPutUrlArgs = {
  where: VideoWhereUniqueInput;
  corsOrigin?: Maybe<Scalars['String']>;
};


export type QueryGetVideoLiveStreamUrlArgs = {
  where: VideoLiveStreamDataInput;
};


export type QueryGetVideoSignedPutUrlArgs = {
  data: VideoSignedUrlDataInput;
};

export enum ReferenceType {
  Show = 'SHOW',
  Podcast = 'PODCAST',
  Episode = 'EPISODE',
  PodcastEpisode = 'PODCAST_EPISODE',
  User = 'USER',
  Season = 'SEASON',
  SeasonEpisode = 'SEASON_EPISODE',
  EpisodeSegment = 'EPISODE_SEGMENT',
  Video = 'VIDEO',
  Clip = 'CLIP'
}

export enum Role {
  User = 'USER',
  Admin = 'ADMIN',
  Author = 'AUTHOR',
  GuestAuthor = 'GUEST_AUTHOR',
  Moderator = 'MODERATOR'
}

export type Season = {
  __typename?: 'Season';
  id: Scalars['ID'];
  name: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Int']>;
  orderBy: SeasonOrderBy;
  status?: Maybe<SeasonStatus>;
  type?: Maybe<SeasonType>;
  show?: Maybe<Show>;
  seasonEpisodes?: Maybe<Array<SeasonEpisode>>;
  podcast?: Maybe<Podcast>;
  podcastEpisodes?: Maybe<Array<PodcastEpisode>>;
  createdBy?: Maybe<User>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  message?: Maybe<Scalars['String']>;
};


export type SeasonSeasonEpisodesArgs = {
  where?: Maybe<SeasonEpisodeWhereInput>;
  orderBy?: Maybe<SeasonEpisodeOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type SeasonPodcastEpisodesArgs = {
  where?: Maybe<PodcastEpisodeWhereInput>;
  orderBy?: Maybe<PodcastEpisodeOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type SeasonConnection = {
  __typename?: 'SeasonConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<SeasonEdge>>;
  aggregate: AggregateSeason;
};

export type SeasonCreateInput = {
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Int']>;
  orderBy: SeasonOrderBy;
  status?: Maybe<SeasonStatus>;
  type?: Maybe<SeasonType>;
  show?: Maybe<ShowCreateOneWithoutSeasonsInput>;
  seasonEpisodes?: Maybe<SeasonEpisodeCreateManyWithoutSeasonInput>;
  podcast?: Maybe<PodcastCreateOneWithoutSeasonsInput>;
  podcastEpisodes?: Maybe<PodcastEpisodeCreateManyWithoutSeasonInput>;
  createdBy?: Maybe<UserCreateOneInput>;
};

export type SeasonCreateManyWithoutPodcastInput = {
  create?: Maybe<Array<SeasonCreateWithoutPodcastInput>>;
  connect?: Maybe<Array<SeasonWhereUniqueInput>>;
};

export type SeasonCreateManyWithoutShowInput = {
  create?: Maybe<Array<SeasonCreateWithoutShowInput>>;
  connect?: Maybe<Array<SeasonWhereUniqueInput>>;
};

export type SeasonCreateOneWithoutPodcastEpisodesInput = {
  create?: Maybe<SeasonCreateWithoutPodcastEpisodesInput>;
  connect?: Maybe<SeasonWhereUniqueInput>;
};

export type SeasonCreateOneWithoutSeasonEpisodesInput = {
  create?: Maybe<SeasonCreateWithoutSeasonEpisodesInput>;
  connect?: Maybe<SeasonWhereUniqueInput>;
};

export type SeasonCreateWithoutPodcastEpisodesInput = {
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Int']>;
  orderBy: SeasonOrderBy;
  status?: Maybe<SeasonStatus>;
  type?: Maybe<SeasonType>;
  show?: Maybe<ShowCreateOneWithoutSeasonsInput>;
  seasonEpisodes?: Maybe<SeasonEpisodeCreateManyWithoutSeasonInput>;
  podcast?: Maybe<PodcastCreateOneWithoutSeasonsInput>;
  createdBy?: Maybe<UserCreateOneInput>;
};

export type SeasonCreateWithoutPodcastInput = {
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Int']>;
  orderBy: SeasonOrderBy;
  status?: Maybe<SeasonStatus>;
  type?: Maybe<SeasonType>;
  show?: Maybe<ShowCreateOneWithoutSeasonsInput>;
  seasonEpisodes?: Maybe<SeasonEpisodeCreateManyWithoutSeasonInput>;
  podcastEpisodes?: Maybe<PodcastEpisodeCreateManyWithoutSeasonInput>;
  createdBy?: Maybe<UserCreateOneInput>;
};

export type SeasonCreateWithoutSeasonEpisodesInput = {
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Int']>;
  orderBy: SeasonOrderBy;
  status?: Maybe<SeasonStatus>;
  type?: Maybe<SeasonType>;
  show?: Maybe<ShowCreateOneWithoutSeasonsInput>;
  podcast?: Maybe<PodcastCreateOneWithoutSeasonsInput>;
  podcastEpisodes?: Maybe<PodcastEpisodeCreateManyWithoutSeasonInput>;
  createdBy?: Maybe<UserCreateOneInput>;
};

export type SeasonCreateWithoutShowInput = {
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Int']>;
  orderBy: SeasonOrderBy;
  status?: Maybe<SeasonStatus>;
  type?: Maybe<SeasonType>;
  seasonEpisodes?: Maybe<SeasonEpisodeCreateManyWithoutSeasonInput>;
  podcast?: Maybe<PodcastCreateOneWithoutSeasonsInput>;
  podcastEpisodes?: Maybe<PodcastEpisodeCreateManyWithoutSeasonInput>;
  createdBy?: Maybe<UserCreateOneInput>;
};

export type SeasonEdge = {
  __typename?: 'SeasonEdge';
  node: Season;
  cursor: Scalars['String'];
};

export type SeasonEpisode = {
  __typename?: 'SeasonEpisode';
  id: Scalars['ID'];
  season: Season;
  weight?: Maybe<Scalars['Int']>;
  publishDate?: Maybe<Scalars['DateTime']>;
  episode: Episode;
  createdBy?: Maybe<User>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  message?: Maybe<Scalars['String']>;
};

export type SeasonEpisodeConnection = {
  __typename?: 'SeasonEpisodeConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<SeasonEpisodeEdge>>;
  aggregate: AggregateSeasonEpisode;
};

export type SeasonEpisodeCreateInput = {
  id?: Maybe<Scalars['ID']>;
  season: SeasonCreateOneWithoutSeasonEpisodesInput;
  weight?: Maybe<Scalars['Int']>;
  publishDate?: Maybe<Scalars['DateTime']>;
  episode: EpisodeCreateOneWithoutSeasonEpisodeInput;
  createdBy?: Maybe<UserCreateOneInput>;
};

export type SeasonEpisodeCreateManyWithoutEpisodeInput = {
  create?: Maybe<Array<SeasonEpisodeCreateWithoutEpisodeInput>>;
  connect?: Maybe<Array<SeasonEpisodeWhereUniqueInput>>;
};

export type SeasonEpisodeCreateManyWithoutSeasonInput = {
  create?: Maybe<Array<SeasonEpisodeCreateWithoutSeasonInput>>;
  connect?: Maybe<Array<SeasonEpisodeWhereUniqueInput>>;
};

export type SeasonEpisodeCreateWithoutEpisodeInput = {
  id?: Maybe<Scalars['ID']>;
  season: SeasonCreateOneWithoutSeasonEpisodesInput;
  weight?: Maybe<Scalars['Int']>;
  publishDate?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserCreateOneInput>;
};

export type SeasonEpisodeCreateWithoutSeasonInput = {
  id?: Maybe<Scalars['ID']>;
  weight?: Maybe<Scalars['Int']>;
  publishDate?: Maybe<Scalars['DateTime']>;
  episode: EpisodeCreateOneWithoutSeasonEpisodeInput;
  createdBy?: Maybe<UserCreateOneInput>;
};

export type SeasonEpisodeEdge = {
  __typename?: 'SeasonEpisodeEdge';
  node: SeasonEpisode;
  cursor: Scalars['String'];
};

export enum SeasonEpisodeOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  WeightAsc = 'weight_ASC',
  WeightDesc = 'weight_DESC',
  PublishDateAsc = 'publishDate_ASC',
  PublishDateDesc = 'publishDate_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type SeasonEpisodePreviousValues = {
  __typename?: 'SeasonEpisodePreviousValues';
  id: Scalars['ID'];
  weight?: Maybe<Scalars['Int']>;
  publishDate?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type SeasonEpisodeScalarWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  weight?: Maybe<Scalars['Int']>;
  weight_not?: Maybe<Scalars['Int']>;
  weight_in?: Maybe<Array<Scalars['Int']>>;
  weight_not_in?: Maybe<Array<Scalars['Int']>>;
  weight_lt?: Maybe<Scalars['Int']>;
  weight_lte?: Maybe<Scalars['Int']>;
  weight_gt?: Maybe<Scalars['Int']>;
  weight_gte?: Maybe<Scalars['Int']>;
  publishDate?: Maybe<Scalars['DateTime']>;
  publishDate_not?: Maybe<Scalars['DateTime']>;
  publishDate_in?: Maybe<Array<Scalars['DateTime']>>;
  publishDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  publishDate_lt?: Maybe<Scalars['DateTime']>;
  publishDate_lte?: Maybe<Scalars['DateTime']>;
  publishDate_gt?: Maybe<Scalars['DateTime']>;
  publishDate_gte?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<SeasonEpisodeScalarWhereInput>>;
  OR?: Maybe<Array<SeasonEpisodeScalarWhereInput>>;
  NOT?: Maybe<Array<SeasonEpisodeScalarWhereInput>>;
};

export type SeasonEpisodeSubscriptionPayload = {
  __typename?: 'SeasonEpisodeSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<SeasonEpisode>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<SeasonEpisodePreviousValues>;
};

export type SeasonEpisodeSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<SeasonEpisodeWhereInput>;
  AND?: Maybe<Array<SeasonEpisodeSubscriptionWhereInput>>;
  OR?: Maybe<Array<SeasonEpisodeSubscriptionWhereInput>>;
  NOT?: Maybe<Array<SeasonEpisodeSubscriptionWhereInput>>;
};

export type SeasonEpisodeUpdateInput = {
  season?: Maybe<SeasonUpdateOneRequiredWithoutSeasonEpisodesInput>;
  weight?: Maybe<Scalars['Int']>;
  publishDate?: Maybe<Scalars['DateTime']>;
  episode?: Maybe<EpisodeUpdateOneRequiredWithoutSeasonEpisodeInput>;
  createdBy?: Maybe<UserUpdateOneInput>;
};

export type SeasonEpisodeUpdateManyDataInput = {
  weight?: Maybe<Scalars['Int']>;
  publishDate?: Maybe<Scalars['DateTime']>;
};

export type SeasonEpisodeUpdateManyMutationInput = {
  weight?: Maybe<Scalars['Int']>;
  publishDate?: Maybe<Scalars['DateTime']>;
};

export type SeasonEpisodeUpdateManyWithWhereNestedInput = {
  where: SeasonEpisodeScalarWhereInput;
  data: SeasonEpisodeUpdateManyDataInput;
};

export type SeasonEpisodeUpdateManyWithoutEpisodeInput = {
  create?: Maybe<Array<SeasonEpisodeCreateWithoutEpisodeInput>>;
  delete?: Maybe<Array<SeasonEpisodeWhereUniqueInput>>;
  connect?: Maybe<Array<SeasonEpisodeWhereUniqueInput>>;
  set?: Maybe<Array<SeasonEpisodeWhereUniqueInput>>;
  disconnect?: Maybe<Array<SeasonEpisodeWhereUniqueInput>>;
  update?: Maybe<Array<SeasonEpisodeUpdateWithWhereUniqueWithoutEpisodeInput>>;
  upsert?: Maybe<Array<SeasonEpisodeUpsertWithWhereUniqueWithoutEpisodeInput>>;
  deleteMany?: Maybe<Array<SeasonEpisodeScalarWhereInput>>;
  updateMany?: Maybe<Array<SeasonEpisodeUpdateManyWithWhereNestedInput>>;
};

export type SeasonEpisodeUpdateManyWithoutSeasonInput = {
  create?: Maybe<Array<SeasonEpisodeCreateWithoutSeasonInput>>;
  delete?: Maybe<Array<SeasonEpisodeWhereUniqueInput>>;
  connect?: Maybe<Array<SeasonEpisodeWhereUniqueInput>>;
  set?: Maybe<Array<SeasonEpisodeWhereUniqueInput>>;
  disconnect?: Maybe<Array<SeasonEpisodeWhereUniqueInput>>;
  update?: Maybe<Array<SeasonEpisodeUpdateWithWhereUniqueWithoutSeasonInput>>;
  upsert?: Maybe<Array<SeasonEpisodeUpsertWithWhereUniqueWithoutSeasonInput>>;
  deleteMany?: Maybe<Array<SeasonEpisodeScalarWhereInput>>;
  updateMany?: Maybe<Array<SeasonEpisodeUpdateManyWithWhereNestedInput>>;
};

export type SeasonEpisodeUpdateWithWhereUniqueWithoutEpisodeInput = {
  where: SeasonEpisodeWhereUniqueInput;
  data: SeasonEpisodeUpdateWithoutEpisodeDataInput;
};

export type SeasonEpisodeUpdateWithWhereUniqueWithoutSeasonInput = {
  where: SeasonEpisodeWhereUniqueInput;
  data: SeasonEpisodeUpdateWithoutSeasonDataInput;
};

export type SeasonEpisodeUpdateWithoutEpisodeDataInput = {
  season?: Maybe<SeasonUpdateOneRequiredWithoutSeasonEpisodesInput>;
  weight?: Maybe<Scalars['Int']>;
  publishDate?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserUpdateOneInput>;
};

export type SeasonEpisodeUpdateWithoutSeasonDataInput = {
  weight?: Maybe<Scalars['Int']>;
  publishDate?: Maybe<Scalars['DateTime']>;
  episode?: Maybe<EpisodeUpdateOneRequiredWithoutSeasonEpisodeInput>;
  createdBy?: Maybe<UserUpdateOneInput>;
};

export type SeasonEpisodeUpsertWithWhereUniqueWithoutEpisodeInput = {
  where: SeasonEpisodeWhereUniqueInput;
  update: SeasonEpisodeUpdateWithoutEpisodeDataInput;
  create: SeasonEpisodeCreateWithoutEpisodeInput;
};

export type SeasonEpisodeUpsertWithWhereUniqueWithoutSeasonInput = {
  where: SeasonEpisodeWhereUniqueInput;
  update: SeasonEpisodeUpdateWithoutSeasonDataInput;
  create: SeasonEpisodeCreateWithoutSeasonInput;
};

export type SeasonEpisodeWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  season?: Maybe<SeasonWhereInput>;
  weight?: Maybe<Scalars['Int']>;
  weight_not?: Maybe<Scalars['Int']>;
  weight_in?: Maybe<Array<Scalars['Int']>>;
  weight_not_in?: Maybe<Array<Scalars['Int']>>;
  weight_lt?: Maybe<Scalars['Int']>;
  weight_lte?: Maybe<Scalars['Int']>;
  weight_gt?: Maybe<Scalars['Int']>;
  weight_gte?: Maybe<Scalars['Int']>;
  publishDate?: Maybe<Scalars['DateTime']>;
  publishDate_not?: Maybe<Scalars['DateTime']>;
  publishDate_in?: Maybe<Array<Scalars['DateTime']>>;
  publishDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  publishDate_lt?: Maybe<Scalars['DateTime']>;
  publishDate_lte?: Maybe<Scalars['DateTime']>;
  publishDate_gt?: Maybe<Scalars['DateTime']>;
  publishDate_gte?: Maybe<Scalars['DateTime']>;
  episode?: Maybe<EpisodeWhereInput>;
  createdBy?: Maybe<UserWhereInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<SeasonEpisodeWhereInput>>;
  OR?: Maybe<Array<SeasonEpisodeWhereInput>>;
  NOT?: Maybe<Array<SeasonEpisodeWhereInput>>;
};

export type SeasonEpisodeWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export enum SeasonOrderBy {
  CreatedAtDesc = 'CreatedAt_DESC',
  CreatedAtAsc = 'CreatedAt_ASC',
  Manual = 'Manual'
}

export enum SeasonOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  ImageAsc = 'image_ASC',
  ImageDesc = 'image_DESC',
  WeightAsc = 'weight_ASC',
  WeightDesc = 'weight_DESC',
  OrderByAsc = 'orderBy_ASC',
  OrderByDesc = 'orderBy_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type SeasonPreviousValues = {
  __typename?: 'SeasonPreviousValues';
  id: Scalars['ID'];
  name: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Int']>;
  orderBy: SeasonOrderBy;
  status?: Maybe<SeasonStatus>;
  type?: Maybe<SeasonType>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type SeasonScalarWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Scalars['String']>>;
  name_not_in?: Maybe<Array<Scalars['String']>>;
  name_lt?: Maybe<Scalars['String']>;
  name_lte?: Maybe<Scalars['String']>;
  name_gt?: Maybe<Scalars['String']>;
  name_gte?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  slug_not?: Maybe<Scalars['String']>;
  slug_in?: Maybe<Array<Scalars['String']>>;
  slug_not_in?: Maybe<Array<Scalars['String']>>;
  slug_lt?: Maybe<Scalars['String']>;
  slug_lte?: Maybe<Scalars['String']>;
  slug_gt?: Maybe<Scalars['String']>;
  slug_gte?: Maybe<Scalars['String']>;
  slug_contains?: Maybe<Scalars['String']>;
  slug_not_contains?: Maybe<Scalars['String']>;
  slug_starts_with?: Maybe<Scalars['String']>;
  slug_not_starts_with?: Maybe<Scalars['String']>;
  slug_ends_with?: Maybe<Scalars['String']>;
  slug_not_ends_with?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  description_not?: Maybe<Scalars['String']>;
  description_in?: Maybe<Array<Scalars['String']>>;
  description_not_in?: Maybe<Array<Scalars['String']>>;
  description_lt?: Maybe<Scalars['String']>;
  description_lte?: Maybe<Scalars['String']>;
  description_gt?: Maybe<Scalars['String']>;
  description_gte?: Maybe<Scalars['String']>;
  description_contains?: Maybe<Scalars['String']>;
  description_not_contains?: Maybe<Scalars['String']>;
  description_starts_with?: Maybe<Scalars['String']>;
  description_not_starts_with?: Maybe<Scalars['String']>;
  description_ends_with?: Maybe<Scalars['String']>;
  description_not_ends_with?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  image_not?: Maybe<Scalars['String']>;
  image_in?: Maybe<Array<Scalars['String']>>;
  image_not_in?: Maybe<Array<Scalars['String']>>;
  image_lt?: Maybe<Scalars['String']>;
  image_lte?: Maybe<Scalars['String']>;
  image_gt?: Maybe<Scalars['String']>;
  image_gte?: Maybe<Scalars['String']>;
  image_contains?: Maybe<Scalars['String']>;
  image_not_contains?: Maybe<Scalars['String']>;
  image_starts_with?: Maybe<Scalars['String']>;
  image_not_starts_with?: Maybe<Scalars['String']>;
  image_ends_with?: Maybe<Scalars['String']>;
  image_not_ends_with?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Int']>;
  weight_not?: Maybe<Scalars['Int']>;
  weight_in?: Maybe<Array<Scalars['Int']>>;
  weight_not_in?: Maybe<Array<Scalars['Int']>>;
  weight_lt?: Maybe<Scalars['Int']>;
  weight_lte?: Maybe<Scalars['Int']>;
  weight_gt?: Maybe<Scalars['Int']>;
  weight_gte?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<SeasonOrderBy>;
  orderBy_not?: Maybe<SeasonOrderBy>;
  orderBy_in?: Maybe<Array<SeasonOrderBy>>;
  orderBy_not_in?: Maybe<Array<SeasonOrderBy>>;
  status?: Maybe<SeasonStatus>;
  status_not?: Maybe<SeasonStatus>;
  status_in?: Maybe<Array<SeasonStatus>>;
  status_not_in?: Maybe<Array<SeasonStatus>>;
  type?: Maybe<SeasonType>;
  type_not?: Maybe<SeasonType>;
  type_in?: Maybe<Array<SeasonType>>;
  type_not_in?: Maybe<Array<SeasonType>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<SeasonScalarWhereInput>>;
  OR?: Maybe<Array<SeasonScalarWhereInput>>;
  NOT?: Maybe<Array<SeasonScalarWhereInput>>;
};

export type SeasonSignedUrlDataInput = {
  seasonId: Scalars['String'];
  fileName: Scalars['String'];
  contentType: Scalars['String'];
};

export enum SeasonStatus {
  Unpublished = 'UNPUBLISHED',
  Published = 'PUBLISHED'
}

export type SeasonSubscriptionPayload = {
  __typename?: 'SeasonSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<Season>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<SeasonPreviousValues>;
};

export type SeasonSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<SeasonWhereInput>;
  AND?: Maybe<Array<SeasonSubscriptionWhereInput>>;
  OR?: Maybe<Array<SeasonSubscriptionWhereInput>>;
  NOT?: Maybe<Array<SeasonSubscriptionWhereInput>>;
};

export enum SeasonType {
  Show = 'SHOW',
  Podcast = 'PODCAST'
}

export type SeasonUpdateInput = {
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<SeasonOrderBy>;
  status?: Maybe<SeasonStatus>;
  type?: Maybe<SeasonType>;
  show?: Maybe<ShowUpdateOneWithoutSeasonsInput>;
  seasonEpisodes?: Maybe<SeasonEpisodeUpdateManyWithoutSeasonInput>;
  podcast?: Maybe<PodcastUpdateOneWithoutSeasonsInput>;
  podcastEpisodes?: Maybe<PodcastEpisodeUpdateManyWithoutSeasonInput>;
  createdBy?: Maybe<UserUpdateOneInput>;
};

export type SeasonUpdateManyDataInput = {
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<SeasonOrderBy>;
  status?: Maybe<SeasonStatus>;
  type?: Maybe<SeasonType>;
};

export type SeasonUpdateManyMutationInput = {
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<SeasonOrderBy>;
  status?: Maybe<SeasonStatus>;
  type?: Maybe<SeasonType>;
};

export type SeasonUpdateManyWithWhereNestedInput = {
  where: SeasonScalarWhereInput;
  data: SeasonUpdateManyDataInput;
};

export type SeasonUpdateManyWithoutPodcastInput = {
  create?: Maybe<Array<SeasonCreateWithoutPodcastInput>>;
  delete?: Maybe<Array<SeasonWhereUniqueInput>>;
  connect?: Maybe<Array<SeasonWhereUniqueInput>>;
  set?: Maybe<Array<SeasonWhereUniqueInput>>;
  disconnect?: Maybe<Array<SeasonWhereUniqueInput>>;
  update?: Maybe<Array<SeasonUpdateWithWhereUniqueWithoutPodcastInput>>;
  upsert?: Maybe<Array<SeasonUpsertWithWhereUniqueWithoutPodcastInput>>;
  deleteMany?: Maybe<Array<SeasonScalarWhereInput>>;
  updateMany?: Maybe<Array<SeasonUpdateManyWithWhereNestedInput>>;
};

export type SeasonUpdateManyWithoutShowInput = {
  create?: Maybe<Array<SeasonCreateWithoutShowInput>>;
  delete?: Maybe<Array<SeasonWhereUniqueInput>>;
  connect?: Maybe<Array<SeasonWhereUniqueInput>>;
  set?: Maybe<Array<SeasonWhereUniqueInput>>;
  disconnect?: Maybe<Array<SeasonWhereUniqueInput>>;
  update?: Maybe<Array<SeasonUpdateWithWhereUniqueWithoutShowInput>>;
  upsert?: Maybe<Array<SeasonUpsertWithWhereUniqueWithoutShowInput>>;
  deleteMany?: Maybe<Array<SeasonScalarWhereInput>>;
  updateMany?: Maybe<Array<SeasonUpdateManyWithWhereNestedInput>>;
};

export type SeasonUpdateOneRequiredWithoutSeasonEpisodesInput = {
  create?: Maybe<SeasonCreateWithoutSeasonEpisodesInput>;
  update?: Maybe<SeasonUpdateWithoutSeasonEpisodesDataInput>;
  upsert?: Maybe<SeasonUpsertWithoutSeasonEpisodesInput>;
  connect?: Maybe<SeasonWhereUniqueInput>;
};

export type SeasonUpdateOneWithoutPodcastEpisodesInput = {
  create?: Maybe<SeasonCreateWithoutPodcastEpisodesInput>;
  update?: Maybe<SeasonUpdateWithoutPodcastEpisodesDataInput>;
  upsert?: Maybe<SeasonUpsertWithoutPodcastEpisodesInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<SeasonWhereUniqueInput>;
};

export type SeasonUpdateWithWhereUniqueWithoutPodcastInput = {
  where: SeasonWhereUniqueInput;
  data: SeasonUpdateWithoutPodcastDataInput;
};

export type SeasonUpdateWithWhereUniqueWithoutShowInput = {
  where: SeasonWhereUniqueInput;
  data: SeasonUpdateWithoutShowDataInput;
};

export type SeasonUpdateWithoutPodcastDataInput = {
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<SeasonOrderBy>;
  status?: Maybe<SeasonStatus>;
  type?: Maybe<SeasonType>;
  show?: Maybe<ShowUpdateOneWithoutSeasonsInput>;
  seasonEpisodes?: Maybe<SeasonEpisodeUpdateManyWithoutSeasonInput>;
  podcastEpisodes?: Maybe<PodcastEpisodeUpdateManyWithoutSeasonInput>;
  createdBy?: Maybe<UserUpdateOneInput>;
};

export type SeasonUpdateWithoutPodcastEpisodesDataInput = {
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<SeasonOrderBy>;
  status?: Maybe<SeasonStatus>;
  type?: Maybe<SeasonType>;
  show?: Maybe<ShowUpdateOneWithoutSeasonsInput>;
  seasonEpisodes?: Maybe<SeasonEpisodeUpdateManyWithoutSeasonInput>;
  podcast?: Maybe<PodcastUpdateOneWithoutSeasonsInput>;
  createdBy?: Maybe<UserUpdateOneInput>;
};

export type SeasonUpdateWithoutSeasonEpisodesDataInput = {
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<SeasonOrderBy>;
  status?: Maybe<SeasonStatus>;
  type?: Maybe<SeasonType>;
  show?: Maybe<ShowUpdateOneWithoutSeasonsInput>;
  podcast?: Maybe<PodcastUpdateOneWithoutSeasonsInput>;
  podcastEpisodes?: Maybe<PodcastEpisodeUpdateManyWithoutSeasonInput>;
  createdBy?: Maybe<UserUpdateOneInput>;
};

export type SeasonUpdateWithoutShowDataInput = {
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<SeasonOrderBy>;
  status?: Maybe<SeasonStatus>;
  type?: Maybe<SeasonType>;
  seasonEpisodes?: Maybe<SeasonEpisodeUpdateManyWithoutSeasonInput>;
  podcast?: Maybe<PodcastUpdateOneWithoutSeasonsInput>;
  podcastEpisodes?: Maybe<PodcastEpisodeUpdateManyWithoutSeasonInput>;
  createdBy?: Maybe<UserUpdateOneInput>;
};

export type SeasonUpsertWithWhereUniqueWithoutPodcastInput = {
  where: SeasonWhereUniqueInput;
  update: SeasonUpdateWithoutPodcastDataInput;
  create: SeasonCreateWithoutPodcastInput;
};

export type SeasonUpsertWithWhereUniqueWithoutShowInput = {
  where: SeasonWhereUniqueInput;
  update: SeasonUpdateWithoutShowDataInput;
  create: SeasonCreateWithoutShowInput;
};

export type SeasonUpsertWithoutPodcastEpisodesInput = {
  update: SeasonUpdateWithoutPodcastEpisodesDataInput;
  create: SeasonCreateWithoutPodcastEpisodesInput;
};

export type SeasonUpsertWithoutSeasonEpisodesInput = {
  update: SeasonUpdateWithoutSeasonEpisodesDataInput;
  create: SeasonCreateWithoutSeasonEpisodesInput;
};

export type SeasonWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Scalars['String']>>;
  name_not_in?: Maybe<Array<Scalars['String']>>;
  name_lt?: Maybe<Scalars['String']>;
  name_lte?: Maybe<Scalars['String']>;
  name_gt?: Maybe<Scalars['String']>;
  name_gte?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  slug_not?: Maybe<Scalars['String']>;
  slug_in?: Maybe<Array<Scalars['String']>>;
  slug_not_in?: Maybe<Array<Scalars['String']>>;
  slug_lt?: Maybe<Scalars['String']>;
  slug_lte?: Maybe<Scalars['String']>;
  slug_gt?: Maybe<Scalars['String']>;
  slug_gte?: Maybe<Scalars['String']>;
  slug_contains?: Maybe<Scalars['String']>;
  slug_not_contains?: Maybe<Scalars['String']>;
  slug_starts_with?: Maybe<Scalars['String']>;
  slug_not_starts_with?: Maybe<Scalars['String']>;
  slug_ends_with?: Maybe<Scalars['String']>;
  slug_not_ends_with?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  description_not?: Maybe<Scalars['String']>;
  description_in?: Maybe<Array<Scalars['String']>>;
  description_not_in?: Maybe<Array<Scalars['String']>>;
  description_lt?: Maybe<Scalars['String']>;
  description_lte?: Maybe<Scalars['String']>;
  description_gt?: Maybe<Scalars['String']>;
  description_gte?: Maybe<Scalars['String']>;
  description_contains?: Maybe<Scalars['String']>;
  description_not_contains?: Maybe<Scalars['String']>;
  description_starts_with?: Maybe<Scalars['String']>;
  description_not_starts_with?: Maybe<Scalars['String']>;
  description_ends_with?: Maybe<Scalars['String']>;
  description_not_ends_with?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  image_not?: Maybe<Scalars['String']>;
  image_in?: Maybe<Array<Scalars['String']>>;
  image_not_in?: Maybe<Array<Scalars['String']>>;
  image_lt?: Maybe<Scalars['String']>;
  image_lte?: Maybe<Scalars['String']>;
  image_gt?: Maybe<Scalars['String']>;
  image_gte?: Maybe<Scalars['String']>;
  image_contains?: Maybe<Scalars['String']>;
  image_not_contains?: Maybe<Scalars['String']>;
  image_starts_with?: Maybe<Scalars['String']>;
  image_not_starts_with?: Maybe<Scalars['String']>;
  image_ends_with?: Maybe<Scalars['String']>;
  image_not_ends_with?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Int']>;
  weight_not?: Maybe<Scalars['Int']>;
  weight_in?: Maybe<Array<Scalars['Int']>>;
  weight_not_in?: Maybe<Array<Scalars['Int']>>;
  weight_lt?: Maybe<Scalars['Int']>;
  weight_lte?: Maybe<Scalars['Int']>;
  weight_gt?: Maybe<Scalars['Int']>;
  weight_gte?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<SeasonOrderBy>;
  orderBy_not?: Maybe<SeasonOrderBy>;
  orderBy_in?: Maybe<Array<SeasonOrderBy>>;
  orderBy_not_in?: Maybe<Array<SeasonOrderBy>>;
  status?: Maybe<SeasonStatus>;
  status_not?: Maybe<SeasonStatus>;
  status_in?: Maybe<Array<SeasonStatus>>;
  status_not_in?: Maybe<Array<SeasonStatus>>;
  type?: Maybe<SeasonType>;
  type_not?: Maybe<SeasonType>;
  type_in?: Maybe<Array<SeasonType>>;
  type_not_in?: Maybe<Array<SeasonType>>;
  show?: Maybe<ShowWhereInput>;
  seasonEpisodes_every?: Maybe<SeasonEpisodeWhereInput>;
  seasonEpisodes_some?: Maybe<SeasonEpisodeWhereInput>;
  seasonEpisodes_none?: Maybe<SeasonEpisodeWhereInput>;
  podcast?: Maybe<PodcastWhereInput>;
  podcastEpisodes_every?: Maybe<PodcastEpisodeWhereInput>;
  podcastEpisodes_some?: Maybe<PodcastEpisodeWhereInput>;
  podcastEpisodes_none?: Maybe<PodcastEpisodeWhereInput>;
  createdBy?: Maybe<UserWhereInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<SeasonWhereInput>>;
  OR?: Maybe<Array<SeasonWhereInput>>;
  NOT?: Maybe<Array<SeasonWhereInput>>;
};

export type SeasonWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
  slug?: Maybe<Scalars['String']>;
};

export type SegmentAudioStatus = {
  __typename?: 'SegmentAudioStatus';
  id: Scalars['ID'];
  jobId: Scalars['String'];
  episodeSegment: EpisodeSegment;
  status?: Maybe<Scalars['String']>;
  isCompleted?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type SegmentAudioStatusConnection = {
  __typename?: 'SegmentAudioStatusConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<SegmentAudioStatusEdge>>;
  aggregate: AggregateSegmentAudioStatus;
};

export type SegmentAudioStatusCreateInput = {
  id?: Maybe<Scalars['ID']>;
  jobId: Scalars['String'];
  episodeSegment: EpisodeSegmentCreateOneInput;
  status?: Maybe<Scalars['String']>;
  isCompleted?: Maybe<Scalars['Boolean']>;
};

export type SegmentAudioStatusEdge = {
  __typename?: 'SegmentAudioStatusEdge';
  node: SegmentAudioStatus;
  cursor: Scalars['String'];
};

export enum SegmentAudioStatusOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  JobIdAsc = 'jobId_ASC',
  JobIdDesc = 'jobId_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  IsCompletedAsc = 'isCompleted_ASC',
  IsCompletedDesc = 'isCompleted_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type SegmentAudioStatusPreviousValues = {
  __typename?: 'SegmentAudioStatusPreviousValues';
  id: Scalars['ID'];
  jobId: Scalars['String'];
  status?: Maybe<Scalars['String']>;
  isCompleted?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type SegmentAudioStatusSubscriptionPayload = {
  __typename?: 'SegmentAudioStatusSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<SegmentAudioStatus>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<SegmentAudioStatusPreviousValues>;
};

export type SegmentAudioStatusSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<SegmentAudioStatusWhereInput>;
  AND?: Maybe<Array<SegmentAudioStatusSubscriptionWhereInput>>;
  OR?: Maybe<Array<SegmentAudioStatusSubscriptionWhereInput>>;
  NOT?: Maybe<Array<SegmentAudioStatusSubscriptionWhereInput>>;
};

export type SegmentAudioStatusUpdateInput = {
  jobId?: Maybe<Scalars['String']>;
  episodeSegment?: Maybe<EpisodeSegmentUpdateOneRequiredInput>;
  status?: Maybe<Scalars['String']>;
  isCompleted?: Maybe<Scalars['Boolean']>;
};

export type SegmentAudioStatusUpdateManyMutationInput = {
  jobId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  isCompleted?: Maybe<Scalars['Boolean']>;
};

export type SegmentAudioStatusWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  jobId?: Maybe<Scalars['String']>;
  jobId_not?: Maybe<Scalars['String']>;
  jobId_in?: Maybe<Array<Scalars['String']>>;
  jobId_not_in?: Maybe<Array<Scalars['String']>>;
  jobId_lt?: Maybe<Scalars['String']>;
  jobId_lte?: Maybe<Scalars['String']>;
  jobId_gt?: Maybe<Scalars['String']>;
  jobId_gte?: Maybe<Scalars['String']>;
  jobId_contains?: Maybe<Scalars['String']>;
  jobId_not_contains?: Maybe<Scalars['String']>;
  jobId_starts_with?: Maybe<Scalars['String']>;
  jobId_not_starts_with?: Maybe<Scalars['String']>;
  jobId_ends_with?: Maybe<Scalars['String']>;
  jobId_not_ends_with?: Maybe<Scalars['String']>;
  episodeSegment?: Maybe<EpisodeSegmentWhereInput>;
  status?: Maybe<Scalars['String']>;
  status_not?: Maybe<Scalars['String']>;
  status_in?: Maybe<Array<Scalars['String']>>;
  status_not_in?: Maybe<Array<Scalars['String']>>;
  status_lt?: Maybe<Scalars['String']>;
  status_lte?: Maybe<Scalars['String']>;
  status_gt?: Maybe<Scalars['String']>;
  status_gte?: Maybe<Scalars['String']>;
  status_contains?: Maybe<Scalars['String']>;
  status_not_contains?: Maybe<Scalars['String']>;
  status_starts_with?: Maybe<Scalars['String']>;
  status_not_starts_with?: Maybe<Scalars['String']>;
  status_ends_with?: Maybe<Scalars['String']>;
  status_not_ends_with?: Maybe<Scalars['String']>;
  isCompleted?: Maybe<Scalars['Boolean']>;
  isCompleted_not?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<SegmentAudioStatusWhereInput>>;
  OR?: Maybe<Array<SegmentAudioStatusWhereInput>>;
  NOT?: Maybe<Array<SegmentAudioStatusWhereInput>>;
};

export type SegmentAudioStatusWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
  jobId?: Maybe<Scalars['String']>;
};

export type SegmentDataInput = {
  id: Scalars['String'];
};

export type SegmentLiveVideoData = {
  __typename?: 'SegmentLiveVideoData';
  video?: Maybe<Scalars['String']>;
  streamKey?: Maybe<Scalars['String']>;
};

export type SegmentLiveVideoDataInput = {
  id: Scalars['String'];
};

export type SegmentSignedUrlDataInput = {
  episodeId: Scalars['String'];
  segmentId?: Maybe<Scalars['String']>;
  fileName: Scalars['String'];
  contentType: Scalars['String'];
};

export type SegmentUnique = {
  __typename?: 'SegmentUnique';
  id?: Maybe<Scalars['ID']>;
};

export type Show = {
  __typename?: 'Show';
  id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Int']>;
  belongsTo?: Maybe<Scalars['String']>;
  thirdPartyShowId?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  logoImage?: Maybe<Scalars['String']>;
  clips?: Maybe<Array<Clip>>;
  seasons?: Maybe<Array<Season>>;
  backgroundImage?: Maybe<Scalars['String']>;
  showDetailImage?: Maybe<Scalars['String']>;
  episodeDetailImage?: Maybe<Scalars['String']>;
  portraitImage?: Maybe<Scalars['String']>;
  hostImage?: Maybe<Scalars['String']>;
  author: User;
  createdBy: User;
  staff?: Maybe<Array<User>>;
  episodes?: Maybe<Array<Episode>>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  carouselShowOrder?: Maybe<Scalars['Int']>;
  carouselShowId?: Maybe<Scalars['ID']>;
  message?: Maybe<Scalars['String']>;
};


export type ShowClipsArgs = {
  where?: Maybe<ClipWhereInput>;
  orderBy?: Maybe<ClipOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type ShowSeasonsArgs = {
  where?: Maybe<SeasonWhereInput>;
  orderBy?: Maybe<SeasonOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type ShowStaffArgs = {
  where?: Maybe<UserWhereInput>;
  orderBy?: Maybe<UserOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type ShowEpisodesArgs = {
  where?: Maybe<EpisodeWhereInput>;
  orderBy?: Maybe<EpisodeOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type ShowCarousel = {
  __typename?: 'ShowCarousel';
  id?: Maybe<Scalars['ID']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  nextOrder?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  orientation?: Maybe<ShowCarouselImageOrientation>;
  shows?: Maybe<Array<Maybe<Show>>>;
  layout?: Maybe<Scalars['String']>;
};

export type ShowCarouselCustomInput = {
  showId: Scalars['ID'];
  order?: Maybe<Scalars['Int']>;
};

export enum ShowCarouselImageOrientation {
  Portrait = 'PORTRAIT',
  Landscape = 'LANDSCAPE'
}

export type ShowCarouselModuleCustomInput = {
  title: Scalars['String'];
  order: Scalars['Int'];
  isPublished?: Maybe<Scalars['Boolean']>;
  orientation?: Maybe<ShowCarouselImageOrientation>;
  carouselShows?: Maybe<Array<Maybe<ShowCarouselCustomInput>>>;
};

export type ShowCarouselModuleShows = {
  __typename?: 'ShowCarouselModuleShows';
  id: Scalars['ID'];
  order?: Maybe<Scalars['Int']>;
  show?: Maybe<Show>;
  showCarousel?: Maybe<CarouselModule>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type ShowCarouselModuleShowsConnection = {
  __typename?: 'ShowCarouselModuleShowsConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<ShowCarouselModuleShowsEdge>>;
  aggregate: AggregateShowCarouselModuleShows;
};

export type ShowCarouselModuleShowsCreateInput = {
  id?: Maybe<Scalars['ID']>;
  order?: Maybe<Scalars['Int']>;
  show?: Maybe<ShowCreateOneInput>;
  showCarousel?: Maybe<CarouselModuleCreateOneWithoutCarouselShowsInput>;
};

export type ShowCarouselModuleShowsCreateManyWithoutShowCarouselInput = {
  create?: Maybe<Array<ShowCarouselModuleShowsCreateWithoutShowCarouselInput>>;
  connect?: Maybe<Array<ShowCarouselModuleShowsWhereUniqueInput>>;
};

export type ShowCarouselModuleShowsCreateWithoutShowCarouselInput = {
  id?: Maybe<Scalars['ID']>;
  order?: Maybe<Scalars['Int']>;
  show?: Maybe<ShowCreateOneInput>;
};

export type ShowCarouselModuleShowsEdge = {
  __typename?: 'ShowCarouselModuleShowsEdge';
  node: ShowCarouselModuleShows;
  cursor: Scalars['String'];
};

export enum ShowCarouselModuleShowsOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  OrderAsc = 'order_ASC',
  OrderDesc = 'order_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type ShowCarouselModuleShowsPreviousValues = {
  __typename?: 'ShowCarouselModuleShowsPreviousValues';
  id: Scalars['ID'];
  order?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type ShowCarouselModuleShowsScalarWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  order?: Maybe<Scalars['Int']>;
  order_not?: Maybe<Scalars['Int']>;
  order_in?: Maybe<Array<Scalars['Int']>>;
  order_not_in?: Maybe<Array<Scalars['Int']>>;
  order_lt?: Maybe<Scalars['Int']>;
  order_lte?: Maybe<Scalars['Int']>;
  order_gt?: Maybe<Scalars['Int']>;
  order_gte?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<ShowCarouselModuleShowsScalarWhereInput>>;
  OR?: Maybe<Array<ShowCarouselModuleShowsScalarWhereInput>>;
  NOT?: Maybe<Array<ShowCarouselModuleShowsScalarWhereInput>>;
};

export type ShowCarouselModuleShowsSubscriptionPayload = {
  __typename?: 'ShowCarouselModuleShowsSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<ShowCarouselModuleShows>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<ShowCarouselModuleShowsPreviousValues>;
};

export type ShowCarouselModuleShowsSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<ShowCarouselModuleShowsWhereInput>;
  AND?: Maybe<Array<ShowCarouselModuleShowsSubscriptionWhereInput>>;
  OR?: Maybe<Array<ShowCarouselModuleShowsSubscriptionWhereInput>>;
  NOT?: Maybe<Array<ShowCarouselModuleShowsSubscriptionWhereInput>>;
};

export type ShowCarouselModuleShowsUpdateInput = {
  order?: Maybe<Scalars['Int']>;
  show?: Maybe<ShowUpdateOneInput>;
  showCarousel?: Maybe<CarouselModuleUpdateOneWithoutCarouselShowsInput>;
};

export type ShowCarouselModuleShowsUpdateManyDataInput = {
  order?: Maybe<Scalars['Int']>;
};

export type ShowCarouselModuleShowsUpdateManyMutationInput = {
  order?: Maybe<Scalars['Int']>;
};

export type ShowCarouselModuleShowsUpdateManyWithWhereNestedInput = {
  where: ShowCarouselModuleShowsScalarWhereInput;
  data: ShowCarouselModuleShowsUpdateManyDataInput;
};

export type ShowCarouselModuleShowsUpdateManyWithoutShowCarouselInput = {
  create?: Maybe<Array<ShowCarouselModuleShowsCreateWithoutShowCarouselInput>>;
  delete?: Maybe<Array<ShowCarouselModuleShowsWhereUniqueInput>>;
  connect?: Maybe<Array<ShowCarouselModuleShowsWhereUniqueInput>>;
  set?: Maybe<Array<ShowCarouselModuleShowsWhereUniqueInput>>;
  disconnect?: Maybe<Array<ShowCarouselModuleShowsWhereUniqueInput>>;
  update?: Maybe<Array<ShowCarouselModuleShowsUpdateWithWhereUniqueWithoutShowCarouselInput>>;
  upsert?: Maybe<Array<ShowCarouselModuleShowsUpsertWithWhereUniqueWithoutShowCarouselInput>>;
  deleteMany?: Maybe<Array<ShowCarouselModuleShowsScalarWhereInput>>;
  updateMany?: Maybe<Array<ShowCarouselModuleShowsUpdateManyWithWhereNestedInput>>;
};

export type ShowCarouselModuleShowsUpdateWithWhereUniqueWithoutShowCarouselInput = {
  where: ShowCarouselModuleShowsWhereUniqueInput;
  data: ShowCarouselModuleShowsUpdateWithoutShowCarouselDataInput;
};

export type ShowCarouselModuleShowsUpdateWithoutShowCarouselDataInput = {
  order?: Maybe<Scalars['Int']>;
  show?: Maybe<ShowUpdateOneInput>;
};

export type ShowCarouselModuleShowsUpsertWithWhereUniqueWithoutShowCarouselInput = {
  where: ShowCarouselModuleShowsWhereUniqueInput;
  update: ShowCarouselModuleShowsUpdateWithoutShowCarouselDataInput;
  create: ShowCarouselModuleShowsCreateWithoutShowCarouselInput;
};

export type ShowCarouselModuleShowsWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  order?: Maybe<Scalars['Int']>;
  order_not?: Maybe<Scalars['Int']>;
  order_in?: Maybe<Array<Scalars['Int']>>;
  order_not_in?: Maybe<Array<Scalars['Int']>>;
  order_lt?: Maybe<Scalars['Int']>;
  order_lte?: Maybe<Scalars['Int']>;
  order_gt?: Maybe<Scalars['Int']>;
  order_gte?: Maybe<Scalars['Int']>;
  show?: Maybe<ShowWhereInput>;
  showCarousel?: Maybe<CarouselModuleWhereInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<ShowCarouselModuleShowsWhereInput>>;
  OR?: Maybe<Array<ShowCarouselModuleShowsWhereInput>>;
  NOT?: Maybe<Array<ShowCarouselModuleShowsWhereInput>>;
};

export type ShowCarouselModuleShowsWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type ShowConnection = {
  __typename?: 'ShowConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<ShowEdge>>;
  aggregate: AggregateShow;
};

export type ShowCreateInput = {
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Int']>;
  belongsTo?: Maybe<Scalars['String']>;
  thirdPartyShowId?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  logoImage?: Maybe<Scalars['String']>;
  clips?: Maybe<ClipCreateManyWithoutShowInput>;
  seasons?: Maybe<SeasonCreateManyWithoutShowInput>;
  backgroundImage?: Maybe<Scalars['String']>;
  showDetailImage?: Maybe<Scalars['String']>;
  episodeDetailImage?: Maybe<Scalars['String']>;
  portraitImage?: Maybe<Scalars['String']>;
  hostImage?: Maybe<Scalars['String']>;
  author: UserCreateOneInput;
  createdBy: UserCreateOneInput;
  staff?: Maybe<UserCreateManyInput>;
  episodes?: Maybe<EpisodeCreateManyWithoutShowInput>;
};

export type ShowCreateOneInput = {
  create?: Maybe<ShowCreateInput>;
  connect?: Maybe<ShowWhereUniqueInput>;
};

export type ShowCreateOneWithoutClipsInput = {
  create?: Maybe<ShowCreateWithoutClipsInput>;
  connect?: Maybe<ShowWhereUniqueInput>;
};

export type ShowCreateOneWithoutEpisodesInput = {
  create?: Maybe<ShowCreateWithoutEpisodesInput>;
  connect?: Maybe<ShowWhereUniqueInput>;
};

export type ShowCreateOneWithoutSeasonsInput = {
  create?: Maybe<ShowCreateWithoutSeasonsInput>;
  connect?: Maybe<ShowWhereUniqueInput>;
};

export type ShowCreateWithoutClipsInput = {
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Int']>;
  belongsTo?: Maybe<Scalars['String']>;
  thirdPartyShowId?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  logoImage?: Maybe<Scalars['String']>;
  seasons?: Maybe<SeasonCreateManyWithoutShowInput>;
  backgroundImage?: Maybe<Scalars['String']>;
  showDetailImage?: Maybe<Scalars['String']>;
  episodeDetailImage?: Maybe<Scalars['String']>;
  portraitImage?: Maybe<Scalars['String']>;
  hostImage?: Maybe<Scalars['String']>;
  author: UserCreateOneInput;
  createdBy: UserCreateOneInput;
  staff?: Maybe<UserCreateManyInput>;
  episodes?: Maybe<EpisodeCreateManyWithoutShowInput>;
};

export type ShowCreateWithoutEpisodesInput = {
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Int']>;
  belongsTo?: Maybe<Scalars['String']>;
  thirdPartyShowId?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  logoImage?: Maybe<Scalars['String']>;
  clips?: Maybe<ClipCreateManyWithoutShowInput>;
  seasons?: Maybe<SeasonCreateManyWithoutShowInput>;
  backgroundImage?: Maybe<Scalars['String']>;
  showDetailImage?: Maybe<Scalars['String']>;
  episodeDetailImage?: Maybe<Scalars['String']>;
  portraitImage?: Maybe<Scalars['String']>;
  hostImage?: Maybe<Scalars['String']>;
  author: UserCreateOneInput;
  createdBy: UserCreateOneInput;
  staff?: Maybe<UserCreateManyInput>;
};

export type ShowCreateWithoutSeasonsInput = {
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Int']>;
  belongsTo?: Maybe<Scalars['String']>;
  thirdPartyShowId?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  logoImage?: Maybe<Scalars['String']>;
  clips?: Maybe<ClipCreateManyWithoutShowInput>;
  backgroundImage?: Maybe<Scalars['String']>;
  showDetailImage?: Maybe<Scalars['String']>;
  episodeDetailImage?: Maybe<Scalars['String']>;
  portraitImage?: Maybe<Scalars['String']>;
  hostImage?: Maybe<Scalars['String']>;
  author: UserCreateOneInput;
  createdBy: UserCreateOneInput;
  staff?: Maybe<UserCreateManyInput>;
  episodes?: Maybe<EpisodeCreateManyWithoutShowInput>;
};

export type ShowEdge = {
  __typename?: 'ShowEdge';
  node: Show;
  cursor: Scalars['String'];
};

export enum ShowOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  WeightAsc = 'weight_ASC',
  WeightDesc = 'weight_DESC',
  BelongsToAsc = 'belongsTo_ASC',
  BelongsToDesc = 'belongsTo_DESC',
  ThirdPartyShowIdAsc = 'thirdPartyShowId_ASC',
  ThirdPartyShowIdDesc = 'thirdPartyShowId_DESC',
  ImageAsc = 'image_ASC',
  ImageDesc = 'image_DESC',
  ThumbnailAsc = 'thumbnail_ASC',
  ThumbnailDesc = 'thumbnail_DESC',
  LogoImageAsc = 'logoImage_ASC',
  LogoImageDesc = 'logoImage_DESC',
  BackgroundImageAsc = 'backgroundImage_ASC',
  BackgroundImageDesc = 'backgroundImage_DESC',
  ShowDetailImageAsc = 'showDetailImage_ASC',
  ShowDetailImageDesc = 'showDetailImage_DESC',
  EpisodeDetailImageAsc = 'episodeDetailImage_ASC',
  EpisodeDetailImageDesc = 'episodeDetailImage_DESC',
  PortraitImageAsc = 'portraitImage_ASC',
  PortraitImageDesc = 'portraitImage_DESC',
  HostImageAsc = 'hostImage_ASC',
  HostImageDesc = 'hostImage_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type ShowPremiere = {
  __typename?: 'ShowPremiere';
  id?: Maybe<Scalars['ID']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  premiereImage?: Maybe<Scalars['String']>;
  show?: Maybe<Show>;
};

export type ShowPreviousValues = {
  __typename?: 'ShowPreviousValues';
  id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Int']>;
  belongsTo?: Maybe<Scalars['String']>;
  thirdPartyShowId?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  logoImage?: Maybe<Scalars['String']>;
  backgroundImage?: Maybe<Scalars['String']>;
  showDetailImage?: Maybe<Scalars['String']>;
  episodeDetailImage?: Maybe<Scalars['String']>;
  portraitImage?: Maybe<Scalars['String']>;
  hostImage?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type ShowSubscriptionPayload = {
  __typename?: 'ShowSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<Show>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<ShowPreviousValues>;
};

export type ShowSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<ShowWhereInput>;
  AND?: Maybe<Array<ShowSubscriptionWhereInput>>;
  OR?: Maybe<Array<ShowSubscriptionWhereInput>>;
  NOT?: Maybe<Array<ShowSubscriptionWhereInput>>;
};

export type ShowUpdateDataInput = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Int']>;
  belongsTo?: Maybe<Scalars['String']>;
  thirdPartyShowId?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  logoImage?: Maybe<Scalars['String']>;
  clips?: Maybe<ClipUpdateManyWithoutShowInput>;
  seasons?: Maybe<SeasonUpdateManyWithoutShowInput>;
  backgroundImage?: Maybe<Scalars['String']>;
  showDetailImage?: Maybe<Scalars['String']>;
  episodeDetailImage?: Maybe<Scalars['String']>;
  portraitImage?: Maybe<Scalars['String']>;
  hostImage?: Maybe<Scalars['String']>;
  author?: Maybe<UserUpdateOneRequiredInput>;
  createdBy?: Maybe<UserUpdateOneRequiredInput>;
  staff?: Maybe<UserUpdateManyInput>;
  episodes?: Maybe<EpisodeUpdateManyWithoutShowInput>;
};

export type ShowUpdateInput = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Int']>;
  belongsTo?: Maybe<Scalars['String']>;
  thirdPartyShowId?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  logoImage?: Maybe<Scalars['String']>;
  clips?: Maybe<ClipUpdateManyWithoutShowInput>;
  seasons?: Maybe<SeasonUpdateManyWithoutShowInput>;
  backgroundImage?: Maybe<Scalars['String']>;
  showDetailImage?: Maybe<Scalars['String']>;
  episodeDetailImage?: Maybe<Scalars['String']>;
  portraitImage?: Maybe<Scalars['String']>;
  hostImage?: Maybe<Scalars['String']>;
  author?: Maybe<UserUpdateOneRequiredInput>;
  createdBy?: Maybe<UserUpdateOneRequiredInput>;
  staff?: Maybe<UserUpdateManyInput>;
  episodes?: Maybe<EpisodeUpdateManyWithoutShowInput>;
};

export type ShowUpdateManyMutationInput = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Int']>;
  belongsTo?: Maybe<Scalars['String']>;
  thirdPartyShowId?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  logoImage?: Maybe<Scalars['String']>;
  backgroundImage?: Maybe<Scalars['String']>;
  showDetailImage?: Maybe<Scalars['String']>;
  episodeDetailImage?: Maybe<Scalars['String']>;
  portraitImage?: Maybe<Scalars['String']>;
  hostImage?: Maybe<Scalars['String']>;
};

export type ShowUpdateOneInput = {
  create?: Maybe<ShowCreateInput>;
  update?: Maybe<ShowUpdateDataInput>;
  upsert?: Maybe<ShowUpsertNestedInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ShowWhereUniqueInput>;
};

export type ShowUpdateOneRequiredWithoutEpisodesInput = {
  create?: Maybe<ShowCreateWithoutEpisodesInput>;
  update?: Maybe<ShowUpdateWithoutEpisodesDataInput>;
  upsert?: Maybe<ShowUpsertWithoutEpisodesInput>;
  connect?: Maybe<ShowWhereUniqueInput>;
};

export type ShowUpdateOneWithoutClipsInput = {
  create?: Maybe<ShowCreateWithoutClipsInput>;
  update?: Maybe<ShowUpdateWithoutClipsDataInput>;
  upsert?: Maybe<ShowUpsertWithoutClipsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ShowWhereUniqueInput>;
};

export type ShowUpdateOneWithoutSeasonsInput = {
  create?: Maybe<ShowCreateWithoutSeasonsInput>;
  update?: Maybe<ShowUpdateWithoutSeasonsDataInput>;
  upsert?: Maybe<ShowUpsertWithoutSeasonsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ShowWhereUniqueInput>;
};

export type ShowUpdateWithoutClipsDataInput = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Int']>;
  belongsTo?: Maybe<Scalars['String']>;
  thirdPartyShowId?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  logoImage?: Maybe<Scalars['String']>;
  seasons?: Maybe<SeasonUpdateManyWithoutShowInput>;
  backgroundImage?: Maybe<Scalars['String']>;
  showDetailImage?: Maybe<Scalars['String']>;
  episodeDetailImage?: Maybe<Scalars['String']>;
  portraitImage?: Maybe<Scalars['String']>;
  hostImage?: Maybe<Scalars['String']>;
  author?: Maybe<UserUpdateOneRequiredInput>;
  createdBy?: Maybe<UserUpdateOneRequiredInput>;
  staff?: Maybe<UserUpdateManyInput>;
  episodes?: Maybe<EpisodeUpdateManyWithoutShowInput>;
};

export type ShowUpdateWithoutEpisodesDataInput = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Int']>;
  belongsTo?: Maybe<Scalars['String']>;
  thirdPartyShowId?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  logoImage?: Maybe<Scalars['String']>;
  clips?: Maybe<ClipUpdateManyWithoutShowInput>;
  seasons?: Maybe<SeasonUpdateManyWithoutShowInput>;
  backgroundImage?: Maybe<Scalars['String']>;
  showDetailImage?: Maybe<Scalars['String']>;
  episodeDetailImage?: Maybe<Scalars['String']>;
  portraitImage?: Maybe<Scalars['String']>;
  hostImage?: Maybe<Scalars['String']>;
  author?: Maybe<UserUpdateOneRequiredInput>;
  createdBy?: Maybe<UserUpdateOneRequiredInput>;
  staff?: Maybe<UserUpdateManyInput>;
};

export type ShowUpdateWithoutSeasonsDataInput = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Int']>;
  belongsTo?: Maybe<Scalars['String']>;
  thirdPartyShowId?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  logoImage?: Maybe<Scalars['String']>;
  clips?: Maybe<ClipUpdateManyWithoutShowInput>;
  backgroundImage?: Maybe<Scalars['String']>;
  showDetailImage?: Maybe<Scalars['String']>;
  episodeDetailImage?: Maybe<Scalars['String']>;
  portraitImage?: Maybe<Scalars['String']>;
  hostImage?: Maybe<Scalars['String']>;
  author?: Maybe<UserUpdateOneRequiredInput>;
  createdBy?: Maybe<UserUpdateOneRequiredInput>;
  staff?: Maybe<UserUpdateManyInput>;
  episodes?: Maybe<EpisodeUpdateManyWithoutShowInput>;
};

export type ShowUpsertNestedInput = {
  update: ShowUpdateDataInput;
  create: ShowCreateInput;
};

export type ShowUpsertWithoutClipsInput = {
  update: ShowUpdateWithoutClipsDataInput;
  create: ShowCreateWithoutClipsInput;
};

export type ShowUpsertWithoutEpisodesInput = {
  update: ShowUpdateWithoutEpisodesDataInput;
  create: ShowCreateWithoutEpisodesInput;
};

export type ShowUpsertWithoutSeasonsInput = {
  update: ShowUpdateWithoutSeasonsDataInput;
  create: ShowCreateWithoutSeasonsInput;
};

export type ShowWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Scalars['String']>>;
  name_not_in?: Maybe<Array<Scalars['String']>>;
  name_lt?: Maybe<Scalars['String']>;
  name_lte?: Maybe<Scalars['String']>;
  name_gt?: Maybe<Scalars['String']>;
  name_gte?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  description_not?: Maybe<Scalars['String']>;
  description_in?: Maybe<Array<Scalars['String']>>;
  description_not_in?: Maybe<Array<Scalars['String']>>;
  description_lt?: Maybe<Scalars['String']>;
  description_lte?: Maybe<Scalars['String']>;
  description_gt?: Maybe<Scalars['String']>;
  description_gte?: Maybe<Scalars['String']>;
  description_contains?: Maybe<Scalars['String']>;
  description_not_contains?: Maybe<Scalars['String']>;
  description_starts_with?: Maybe<Scalars['String']>;
  description_not_starts_with?: Maybe<Scalars['String']>;
  description_ends_with?: Maybe<Scalars['String']>;
  description_not_ends_with?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  slug_not?: Maybe<Scalars['String']>;
  slug_in?: Maybe<Array<Scalars['String']>>;
  slug_not_in?: Maybe<Array<Scalars['String']>>;
  slug_lt?: Maybe<Scalars['String']>;
  slug_lte?: Maybe<Scalars['String']>;
  slug_gt?: Maybe<Scalars['String']>;
  slug_gte?: Maybe<Scalars['String']>;
  slug_contains?: Maybe<Scalars['String']>;
  slug_not_contains?: Maybe<Scalars['String']>;
  slug_starts_with?: Maybe<Scalars['String']>;
  slug_not_starts_with?: Maybe<Scalars['String']>;
  slug_ends_with?: Maybe<Scalars['String']>;
  slug_not_ends_with?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Int']>;
  weight_not?: Maybe<Scalars['Int']>;
  weight_in?: Maybe<Array<Scalars['Int']>>;
  weight_not_in?: Maybe<Array<Scalars['Int']>>;
  weight_lt?: Maybe<Scalars['Int']>;
  weight_lte?: Maybe<Scalars['Int']>;
  weight_gt?: Maybe<Scalars['Int']>;
  weight_gte?: Maybe<Scalars['Int']>;
  belongsTo?: Maybe<Scalars['String']>;
  belongsTo_not?: Maybe<Scalars['String']>;
  belongsTo_in?: Maybe<Array<Scalars['String']>>;
  belongsTo_not_in?: Maybe<Array<Scalars['String']>>;
  belongsTo_lt?: Maybe<Scalars['String']>;
  belongsTo_lte?: Maybe<Scalars['String']>;
  belongsTo_gt?: Maybe<Scalars['String']>;
  belongsTo_gte?: Maybe<Scalars['String']>;
  belongsTo_contains?: Maybe<Scalars['String']>;
  belongsTo_not_contains?: Maybe<Scalars['String']>;
  belongsTo_starts_with?: Maybe<Scalars['String']>;
  belongsTo_not_starts_with?: Maybe<Scalars['String']>;
  belongsTo_ends_with?: Maybe<Scalars['String']>;
  belongsTo_not_ends_with?: Maybe<Scalars['String']>;
  thirdPartyShowId?: Maybe<Scalars['String']>;
  thirdPartyShowId_not?: Maybe<Scalars['String']>;
  thirdPartyShowId_in?: Maybe<Array<Scalars['String']>>;
  thirdPartyShowId_not_in?: Maybe<Array<Scalars['String']>>;
  thirdPartyShowId_lt?: Maybe<Scalars['String']>;
  thirdPartyShowId_lte?: Maybe<Scalars['String']>;
  thirdPartyShowId_gt?: Maybe<Scalars['String']>;
  thirdPartyShowId_gte?: Maybe<Scalars['String']>;
  thirdPartyShowId_contains?: Maybe<Scalars['String']>;
  thirdPartyShowId_not_contains?: Maybe<Scalars['String']>;
  thirdPartyShowId_starts_with?: Maybe<Scalars['String']>;
  thirdPartyShowId_not_starts_with?: Maybe<Scalars['String']>;
  thirdPartyShowId_ends_with?: Maybe<Scalars['String']>;
  thirdPartyShowId_not_ends_with?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  image_not?: Maybe<Scalars['String']>;
  image_in?: Maybe<Array<Scalars['String']>>;
  image_not_in?: Maybe<Array<Scalars['String']>>;
  image_lt?: Maybe<Scalars['String']>;
  image_lte?: Maybe<Scalars['String']>;
  image_gt?: Maybe<Scalars['String']>;
  image_gte?: Maybe<Scalars['String']>;
  image_contains?: Maybe<Scalars['String']>;
  image_not_contains?: Maybe<Scalars['String']>;
  image_starts_with?: Maybe<Scalars['String']>;
  image_not_starts_with?: Maybe<Scalars['String']>;
  image_ends_with?: Maybe<Scalars['String']>;
  image_not_ends_with?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  thumbnail_not?: Maybe<Scalars['String']>;
  thumbnail_in?: Maybe<Array<Scalars['String']>>;
  thumbnail_not_in?: Maybe<Array<Scalars['String']>>;
  thumbnail_lt?: Maybe<Scalars['String']>;
  thumbnail_lte?: Maybe<Scalars['String']>;
  thumbnail_gt?: Maybe<Scalars['String']>;
  thumbnail_gte?: Maybe<Scalars['String']>;
  thumbnail_contains?: Maybe<Scalars['String']>;
  thumbnail_not_contains?: Maybe<Scalars['String']>;
  thumbnail_starts_with?: Maybe<Scalars['String']>;
  thumbnail_not_starts_with?: Maybe<Scalars['String']>;
  thumbnail_ends_with?: Maybe<Scalars['String']>;
  thumbnail_not_ends_with?: Maybe<Scalars['String']>;
  logoImage?: Maybe<Scalars['String']>;
  logoImage_not?: Maybe<Scalars['String']>;
  logoImage_in?: Maybe<Array<Scalars['String']>>;
  logoImage_not_in?: Maybe<Array<Scalars['String']>>;
  logoImage_lt?: Maybe<Scalars['String']>;
  logoImage_lte?: Maybe<Scalars['String']>;
  logoImage_gt?: Maybe<Scalars['String']>;
  logoImage_gte?: Maybe<Scalars['String']>;
  logoImage_contains?: Maybe<Scalars['String']>;
  logoImage_not_contains?: Maybe<Scalars['String']>;
  logoImage_starts_with?: Maybe<Scalars['String']>;
  logoImage_not_starts_with?: Maybe<Scalars['String']>;
  logoImage_ends_with?: Maybe<Scalars['String']>;
  logoImage_not_ends_with?: Maybe<Scalars['String']>;
  clips_every?: Maybe<ClipWhereInput>;
  clips_some?: Maybe<ClipWhereInput>;
  clips_none?: Maybe<ClipWhereInput>;
  seasons_every?: Maybe<SeasonWhereInput>;
  seasons_some?: Maybe<SeasonWhereInput>;
  seasons_none?: Maybe<SeasonWhereInput>;
  backgroundImage?: Maybe<Scalars['String']>;
  backgroundImage_not?: Maybe<Scalars['String']>;
  backgroundImage_in?: Maybe<Array<Scalars['String']>>;
  backgroundImage_not_in?: Maybe<Array<Scalars['String']>>;
  backgroundImage_lt?: Maybe<Scalars['String']>;
  backgroundImage_lte?: Maybe<Scalars['String']>;
  backgroundImage_gt?: Maybe<Scalars['String']>;
  backgroundImage_gte?: Maybe<Scalars['String']>;
  backgroundImage_contains?: Maybe<Scalars['String']>;
  backgroundImage_not_contains?: Maybe<Scalars['String']>;
  backgroundImage_starts_with?: Maybe<Scalars['String']>;
  backgroundImage_not_starts_with?: Maybe<Scalars['String']>;
  backgroundImage_ends_with?: Maybe<Scalars['String']>;
  backgroundImage_not_ends_with?: Maybe<Scalars['String']>;
  showDetailImage?: Maybe<Scalars['String']>;
  showDetailImage_not?: Maybe<Scalars['String']>;
  showDetailImage_in?: Maybe<Array<Scalars['String']>>;
  showDetailImage_not_in?: Maybe<Array<Scalars['String']>>;
  showDetailImage_lt?: Maybe<Scalars['String']>;
  showDetailImage_lte?: Maybe<Scalars['String']>;
  showDetailImage_gt?: Maybe<Scalars['String']>;
  showDetailImage_gte?: Maybe<Scalars['String']>;
  showDetailImage_contains?: Maybe<Scalars['String']>;
  showDetailImage_not_contains?: Maybe<Scalars['String']>;
  showDetailImage_starts_with?: Maybe<Scalars['String']>;
  showDetailImage_not_starts_with?: Maybe<Scalars['String']>;
  showDetailImage_ends_with?: Maybe<Scalars['String']>;
  showDetailImage_not_ends_with?: Maybe<Scalars['String']>;
  episodeDetailImage?: Maybe<Scalars['String']>;
  episodeDetailImage_not?: Maybe<Scalars['String']>;
  episodeDetailImage_in?: Maybe<Array<Scalars['String']>>;
  episodeDetailImage_not_in?: Maybe<Array<Scalars['String']>>;
  episodeDetailImage_lt?: Maybe<Scalars['String']>;
  episodeDetailImage_lte?: Maybe<Scalars['String']>;
  episodeDetailImage_gt?: Maybe<Scalars['String']>;
  episodeDetailImage_gte?: Maybe<Scalars['String']>;
  episodeDetailImage_contains?: Maybe<Scalars['String']>;
  episodeDetailImage_not_contains?: Maybe<Scalars['String']>;
  episodeDetailImage_starts_with?: Maybe<Scalars['String']>;
  episodeDetailImage_not_starts_with?: Maybe<Scalars['String']>;
  episodeDetailImage_ends_with?: Maybe<Scalars['String']>;
  episodeDetailImage_not_ends_with?: Maybe<Scalars['String']>;
  portraitImage?: Maybe<Scalars['String']>;
  portraitImage_not?: Maybe<Scalars['String']>;
  portraitImage_in?: Maybe<Array<Scalars['String']>>;
  portraitImage_not_in?: Maybe<Array<Scalars['String']>>;
  portraitImage_lt?: Maybe<Scalars['String']>;
  portraitImage_lte?: Maybe<Scalars['String']>;
  portraitImage_gt?: Maybe<Scalars['String']>;
  portraitImage_gte?: Maybe<Scalars['String']>;
  portraitImage_contains?: Maybe<Scalars['String']>;
  portraitImage_not_contains?: Maybe<Scalars['String']>;
  portraitImage_starts_with?: Maybe<Scalars['String']>;
  portraitImage_not_starts_with?: Maybe<Scalars['String']>;
  portraitImage_ends_with?: Maybe<Scalars['String']>;
  portraitImage_not_ends_with?: Maybe<Scalars['String']>;
  hostImage?: Maybe<Scalars['String']>;
  hostImage_not?: Maybe<Scalars['String']>;
  hostImage_in?: Maybe<Array<Scalars['String']>>;
  hostImage_not_in?: Maybe<Array<Scalars['String']>>;
  hostImage_lt?: Maybe<Scalars['String']>;
  hostImage_lte?: Maybe<Scalars['String']>;
  hostImage_gt?: Maybe<Scalars['String']>;
  hostImage_gte?: Maybe<Scalars['String']>;
  hostImage_contains?: Maybe<Scalars['String']>;
  hostImage_not_contains?: Maybe<Scalars['String']>;
  hostImage_starts_with?: Maybe<Scalars['String']>;
  hostImage_not_starts_with?: Maybe<Scalars['String']>;
  hostImage_ends_with?: Maybe<Scalars['String']>;
  hostImage_not_ends_with?: Maybe<Scalars['String']>;
  author?: Maybe<UserWhereInput>;
  createdBy?: Maybe<UserWhereInput>;
  staff_every?: Maybe<UserWhereInput>;
  staff_some?: Maybe<UserWhereInput>;
  staff_none?: Maybe<UserWhereInput>;
  episodes_every?: Maybe<EpisodeWhereInput>;
  episodes_some?: Maybe<EpisodeWhereInput>;
  episodes_none?: Maybe<EpisodeWhereInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<ShowWhereInput>>;
  OR?: Maybe<Array<ShowWhereInput>>;
  NOT?: Maybe<Array<ShowWhereInput>>;
};

export type ShowWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
  slug?: Maybe<Scalars['String']>;
};

export type SignedUrlDataInput = {
  fileName: Scalars['String'];
  contentType: Scalars['String'];
};

export type SignedUrlType = {
  __typename?: 'SignedUrlType';
  fileName: Scalars['String'];
  signedUrl: Scalars['String'];
  getUrl: Scalars['String'];
  gets3Url?: Maybe<Scalars['String']>;
};

export type SignedUrlsType = {
  __typename?: 'SignedUrlsType';
  fileNames: Array<Maybe<Scalars['String']>>;
  signedUrls: Array<Maybe<Scalars['String']>>;
  getUrls: Array<Maybe<Scalars['String']>>;
};

export type Subscription = {
  __typename?: 'Subscription';
  activityLog?: Maybe<ActivityLogSubscriptionPayload>;
  caption?: Maybe<CaptionSubscriptionPayload>;
  carouselModule?: Maybe<CarouselModuleSubscriptionPayload>;
  clip?: Maybe<ClipSubscriptionPayload>;
  clipCarouselModuleClips?: Maybe<ClipCarouselModuleClipsSubscriptionPayload>;
  contentfulWebhook?: Maybe<ContentfulWebhookSubscriptionPayload>;
  continueListeningModule?: Maybe<ContinueListeningModuleSubscriptionPayload>;
  continueWatchingModule?: Maybe<ContinueWatchingModuleSubscriptionPayload>;
  episode?: Maybe<EpisodeSubscriptionPayload>;
  episodeCarouselModuleEpisodes?: Maybe<EpisodeCarouselModuleEpisodesSubscriptionPayload>;
  episodeSegment?: Maybe<EpisodeSegmentSubscriptionPayload>;
  freeformCarouselModuleFreeforms?: Maybe<FreeformCarouselModuleFreeformsSubscriptionPayload>;
  freeformHighlightModule?: Maybe<FreeformHighlightModuleSubscriptionPayload>;
  module?: Maybe<ModuleSubscriptionPayload>;
  modulePage?: Maybe<ModulePageSubscriptionPayload>;
  podcast?: Maybe<PodcastSubscriptionPayload>;
  podcastCarouselModulePodcasts?: Maybe<PodcastCarouselModulePodcastsSubscriptionPayload>;
  podcastEpisode?: Maybe<PodcastEpisodeSubscriptionPayload>;
  podcastEpisodeCarouselModulePodcastEpisodes?: Maybe<PodcastEpisodeCarouselModulePodcastEpisodesSubscriptionPayload>;
  podcastMigrationEpisode?: Maybe<PodcastMigrationEpisodeSubscriptionPayload>;
  podcastMigrationSeason?: Maybe<PodcastMigrationSeasonSubscriptionPayload>;
  podcastMigrationShow?: Maybe<PodcastMigrationShowSubscriptionPayload>;
  premiereModule?: Maybe<PremiereModuleSubscriptionPayload>;
  season?: Maybe<SeasonSubscriptionPayload>;
  seasonEpisode?: Maybe<SeasonEpisodeSubscriptionPayload>;
  segmentAudioStatus?: Maybe<SegmentAudioStatusSubscriptionPayload>;
  show?: Maybe<ShowSubscriptionPayload>;
  showCarouselModuleShows?: Maybe<ShowCarouselModuleShowsSubscriptionPayload>;
  user?: Maybe<UserSubscriptionPayload>;
  video?: Maybe<VideoSubscriptionPayload>;
  videoCarouselModuleVideos?: Maybe<VideoCarouselModuleVideosSubscriptionPayload>;
};


export type SubscriptionActivityLogArgs = {
  where?: Maybe<ActivityLogSubscriptionWhereInput>;
};


export type SubscriptionCaptionArgs = {
  where?: Maybe<CaptionSubscriptionWhereInput>;
};


export type SubscriptionCarouselModuleArgs = {
  where?: Maybe<CarouselModuleSubscriptionWhereInput>;
};


export type SubscriptionClipArgs = {
  where?: Maybe<ClipSubscriptionWhereInput>;
};


export type SubscriptionClipCarouselModuleClipsArgs = {
  where?: Maybe<ClipCarouselModuleClipsSubscriptionWhereInput>;
};


export type SubscriptionContentfulWebhookArgs = {
  where?: Maybe<ContentfulWebhookSubscriptionWhereInput>;
};


export type SubscriptionContinueListeningModuleArgs = {
  where?: Maybe<ContinueListeningModuleSubscriptionWhereInput>;
};


export type SubscriptionContinueWatchingModuleArgs = {
  where?: Maybe<ContinueWatchingModuleSubscriptionWhereInput>;
};


export type SubscriptionEpisodeArgs = {
  where?: Maybe<EpisodeSubscriptionWhereInput>;
};


export type SubscriptionEpisodeCarouselModuleEpisodesArgs = {
  where?: Maybe<EpisodeCarouselModuleEpisodesSubscriptionWhereInput>;
};


export type SubscriptionEpisodeSegmentArgs = {
  where?: Maybe<EpisodeSegmentSubscriptionWhereInput>;
};


export type SubscriptionFreeformCarouselModuleFreeformsArgs = {
  where?: Maybe<FreeformCarouselModuleFreeformsSubscriptionWhereInput>;
};


export type SubscriptionFreeformHighlightModuleArgs = {
  where?: Maybe<FreeformHighlightModuleSubscriptionWhereInput>;
};


export type SubscriptionModuleArgs = {
  where?: Maybe<ModuleSubscriptionWhereInput>;
};


export type SubscriptionModulePageArgs = {
  where?: Maybe<ModulePageSubscriptionWhereInput>;
};


export type SubscriptionPodcastArgs = {
  where?: Maybe<PodcastSubscriptionWhereInput>;
};


export type SubscriptionPodcastCarouselModulePodcastsArgs = {
  where?: Maybe<PodcastCarouselModulePodcastsSubscriptionWhereInput>;
};


export type SubscriptionPodcastEpisodeArgs = {
  where?: Maybe<PodcastEpisodeSubscriptionWhereInput>;
};


export type SubscriptionPodcastEpisodeCarouselModulePodcastEpisodesArgs = {
  where?: Maybe<PodcastEpisodeCarouselModulePodcastEpisodesSubscriptionWhereInput>;
};


export type SubscriptionPodcastMigrationEpisodeArgs = {
  where?: Maybe<PodcastMigrationEpisodeSubscriptionWhereInput>;
};


export type SubscriptionPodcastMigrationSeasonArgs = {
  where?: Maybe<PodcastMigrationSeasonSubscriptionWhereInput>;
};


export type SubscriptionPodcastMigrationShowArgs = {
  where?: Maybe<PodcastMigrationShowSubscriptionWhereInput>;
};


export type SubscriptionPremiereModuleArgs = {
  where?: Maybe<PremiereModuleSubscriptionWhereInput>;
};


export type SubscriptionSeasonArgs = {
  where?: Maybe<SeasonSubscriptionWhereInput>;
};


export type SubscriptionSeasonEpisodeArgs = {
  where?: Maybe<SeasonEpisodeSubscriptionWhereInput>;
};


export type SubscriptionSegmentAudioStatusArgs = {
  where?: Maybe<SegmentAudioStatusSubscriptionWhereInput>;
};


export type SubscriptionShowArgs = {
  where?: Maybe<ShowSubscriptionWhereInput>;
};


export type SubscriptionShowCarouselModuleShowsArgs = {
  where?: Maybe<ShowCarouselModuleShowsSubscriptionWhereInput>;
};


export type SubscriptionUserArgs = {
  where?: Maybe<UserSubscriptionWhereInput>;
};


export type SubscriptionVideoArgs = {
  where?: Maybe<VideoSubscriptionWhereInput>;
};


export type SubscriptionVideoCarouselModuleVideosArgs = {
  where?: Maybe<VideoCarouselModuleVideosSubscriptionWhereInput>;
};

export type SyncEpisodeDiscussionInput = {
  id: Scalars['ID'];
};

export type SyncEpisodeDiscussionRes = {
  __typename?: 'SyncEpisodeDiscussionRes';
  message?: Maybe<Scalars['String']>;
};

export type UpdateCarouselFreeformData = {
  order?: Maybe<Scalars['Int']>;
};

export type UpdateCarouselFreeformInput = {
  data: UpdateCarouselFreeformData;
  where: UpdateCarouselFreeformWhere;
};

export type UpdateCarouselFreeformWhere = {
  id?: Maybe<Scalars['ID']>;
};

export type UpdateFreeformCarouselModuleCustom = {
  where: ModuleUniqueInputWhere;
  data: UpdateFreeformCarouselModuleCustomData;
};

export type UpdateFreeformCarouselModuleCustomData = {
  title?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  carouselFreeforms?: Maybe<UpsertCarouselFreeformInput>;
};

export type UploadUrlType = {
  __typename?: 'UploadUrlType';
  signedUrl: Scalars['String'];
};

export type UpsertCarouselFreeformInput = {
  create?: Maybe<Array<Maybe<FreeformCarouselCustomInput>>>;
  update?: Maybe<Array<Maybe<UpdateCarouselFreeformInput>>>;
};

export type UpsertFreeformCarouselModuleCustomInput = {
  update?: Maybe<Array<Maybe<UpdateFreeformCarouselModuleCustom>>>;
  create?: Maybe<Array<Maybe<FreeformCarouselModuleCustomInput>>>;
  delete?: Maybe<Array<Scalars['ID']>>;
};

export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  authZeroID?: Maybe<Scalars['String']>;
  role: Role;
  profileImage?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  permission?: Maybe<Array<Maybe<PermissionCustom>>>;
  personId?: Maybe<Scalars['String']>;
};

export type UserConnection = {
  __typename?: 'UserConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<UserEdge>>;
  aggregate: AggregateUser;
};

export type UserCreateInput = {
  id?: Maybe<Scalars['ID']>;
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  authZeroID?: Maybe<Scalars['String']>;
  role?: Maybe<Role>;
  profileImage?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
};

export type UserCreateManyInput = {
  create?: Maybe<Array<UserCreateInput>>;
  connect?: Maybe<Array<UserWhereUniqueInput>>;
};

export type UserCreateOneInput = {
  create?: Maybe<UserCreateInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserEdge = {
  __typename?: 'UserEdge';
  node: User;
  cursor: Scalars['String'];
};

export enum UserOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
  FirstNameAsc = 'firstName_ASC',
  FirstNameDesc = 'firstName_DESC',
  LastNameAsc = 'lastName_ASC',
  LastNameDesc = 'lastName_DESC',
  AuthZeroIdAsc = 'authZeroID_ASC',
  AuthZeroIdDesc = 'authZeroID_DESC',
  RoleAsc = 'role_ASC',
  RoleDesc = 'role_DESC',
  ProfileImageAsc = 'profileImage_ASC',
  ProfileImageDesc = 'profileImage_DESC',
  IsActiveAsc = 'isActive_ASC',
  IsActiveDesc = 'isActive_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type UserPasswordData = {
  __typename?: 'UserPasswordData';
  message: Scalars['String'];
};

export type UserPasswordDataInput = {
  email: Scalars['String'];
};

export type UserPreviousValues = {
  __typename?: 'UserPreviousValues';
  id: Scalars['ID'];
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  authZeroID?: Maybe<Scalars['String']>;
  role: Role;
  profileImage?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type UserScalarWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  email?: Maybe<Scalars['String']>;
  email_not?: Maybe<Scalars['String']>;
  email_in?: Maybe<Array<Scalars['String']>>;
  email_not_in?: Maybe<Array<Scalars['String']>>;
  email_lt?: Maybe<Scalars['String']>;
  email_lte?: Maybe<Scalars['String']>;
  email_gt?: Maybe<Scalars['String']>;
  email_gte?: Maybe<Scalars['String']>;
  email_contains?: Maybe<Scalars['String']>;
  email_not_contains?: Maybe<Scalars['String']>;
  email_starts_with?: Maybe<Scalars['String']>;
  email_not_starts_with?: Maybe<Scalars['String']>;
  email_ends_with?: Maybe<Scalars['String']>;
  email_not_ends_with?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  firstName_not?: Maybe<Scalars['String']>;
  firstName_in?: Maybe<Array<Scalars['String']>>;
  firstName_not_in?: Maybe<Array<Scalars['String']>>;
  firstName_lt?: Maybe<Scalars['String']>;
  firstName_lte?: Maybe<Scalars['String']>;
  firstName_gt?: Maybe<Scalars['String']>;
  firstName_gte?: Maybe<Scalars['String']>;
  firstName_contains?: Maybe<Scalars['String']>;
  firstName_not_contains?: Maybe<Scalars['String']>;
  firstName_starts_with?: Maybe<Scalars['String']>;
  firstName_not_starts_with?: Maybe<Scalars['String']>;
  firstName_ends_with?: Maybe<Scalars['String']>;
  firstName_not_ends_with?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  lastName_not?: Maybe<Scalars['String']>;
  lastName_in?: Maybe<Array<Scalars['String']>>;
  lastName_not_in?: Maybe<Array<Scalars['String']>>;
  lastName_lt?: Maybe<Scalars['String']>;
  lastName_lte?: Maybe<Scalars['String']>;
  lastName_gt?: Maybe<Scalars['String']>;
  lastName_gte?: Maybe<Scalars['String']>;
  lastName_contains?: Maybe<Scalars['String']>;
  lastName_not_contains?: Maybe<Scalars['String']>;
  lastName_starts_with?: Maybe<Scalars['String']>;
  lastName_not_starts_with?: Maybe<Scalars['String']>;
  lastName_ends_with?: Maybe<Scalars['String']>;
  lastName_not_ends_with?: Maybe<Scalars['String']>;
  authZeroID?: Maybe<Scalars['String']>;
  authZeroID_not?: Maybe<Scalars['String']>;
  authZeroID_in?: Maybe<Array<Scalars['String']>>;
  authZeroID_not_in?: Maybe<Array<Scalars['String']>>;
  authZeroID_lt?: Maybe<Scalars['String']>;
  authZeroID_lte?: Maybe<Scalars['String']>;
  authZeroID_gt?: Maybe<Scalars['String']>;
  authZeroID_gte?: Maybe<Scalars['String']>;
  authZeroID_contains?: Maybe<Scalars['String']>;
  authZeroID_not_contains?: Maybe<Scalars['String']>;
  authZeroID_starts_with?: Maybe<Scalars['String']>;
  authZeroID_not_starts_with?: Maybe<Scalars['String']>;
  authZeroID_ends_with?: Maybe<Scalars['String']>;
  authZeroID_not_ends_with?: Maybe<Scalars['String']>;
  role?: Maybe<Role>;
  role_not?: Maybe<Role>;
  role_in?: Maybe<Array<Role>>;
  role_not_in?: Maybe<Array<Role>>;
  profileImage?: Maybe<Scalars['String']>;
  profileImage_not?: Maybe<Scalars['String']>;
  profileImage_in?: Maybe<Array<Scalars['String']>>;
  profileImage_not_in?: Maybe<Array<Scalars['String']>>;
  profileImage_lt?: Maybe<Scalars['String']>;
  profileImage_lte?: Maybe<Scalars['String']>;
  profileImage_gt?: Maybe<Scalars['String']>;
  profileImage_gte?: Maybe<Scalars['String']>;
  profileImage_contains?: Maybe<Scalars['String']>;
  profileImage_not_contains?: Maybe<Scalars['String']>;
  profileImage_starts_with?: Maybe<Scalars['String']>;
  profileImage_not_starts_with?: Maybe<Scalars['String']>;
  profileImage_ends_with?: Maybe<Scalars['String']>;
  profileImage_not_ends_with?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isActive_not?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<UserScalarWhereInput>>;
  OR?: Maybe<Array<UserScalarWhereInput>>;
  NOT?: Maybe<Array<UserScalarWhereInput>>;
};

export type UserSubscriptionPayload = {
  __typename?: 'UserSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<User>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<UserPreviousValues>;
};

export type UserSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<UserWhereInput>;
  AND?: Maybe<Array<UserSubscriptionWhereInput>>;
  OR?: Maybe<Array<UserSubscriptionWhereInput>>;
  NOT?: Maybe<Array<UserSubscriptionWhereInput>>;
};

export type UserUpdateDataInput = {
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  authZeroID?: Maybe<Scalars['String']>;
  role?: Maybe<Role>;
  profileImage?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
};

export type UserUpdateInput = {
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  authZeroID?: Maybe<Scalars['String']>;
  role?: Maybe<Role>;
  profileImage?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
};

export type UserUpdateManyDataInput = {
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  authZeroID?: Maybe<Scalars['String']>;
  role?: Maybe<Role>;
  profileImage?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
};

export type UserUpdateManyInput = {
  create?: Maybe<Array<UserCreateInput>>;
  update?: Maybe<Array<UserUpdateWithWhereUniqueNestedInput>>;
  upsert?: Maybe<Array<UserUpsertWithWhereUniqueNestedInput>>;
  delete?: Maybe<Array<UserWhereUniqueInput>>;
  connect?: Maybe<Array<UserWhereUniqueInput>>;
  set?: Maybe<Array<UserWhereUniqueInput>>;
  disconnect?: Maybe<Array<UserWhereUniqueInput>>;
  deleteMany?: Maybe<Array<UserScalarWhereInput>>;
  updateMany?: Maybe<Array<UserUpdateManyWithWhereNestedInput>>;
};

export type UserUpdateManyMutationInput = {
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  authZeroID?: Maybe<Scalars['String']>;
  role?: Maybe<Role>;
  profileImage?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
};

export type UserUpdateManyWithWhereNestedInput = {
  where: UserScalarWhereInput;
  data: UserUpdateManyDataInput;
};

export type UserUpdateOneInput = {
  create?: Maybe<UserCreateInput>;
  update?: Maybe<UserUpdateDataInput>;
  upsert?: Maybe<UserUpsertNestedInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserUpdateOneRequiredInput = {
  create?: Maybe<UserCreateInput>;
  update?: Maybe<UserUpdateDataInput>;
  upsert?: Maybe<UserUpsertNestedInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserUpdateWithWhereUniqueNestedInput = {
  where: UserWhereUniqueInput;
  data: UserUpdateDataInput;
};

export type UserUpsertNestedInput = {
  update: UserUpdateDataInput;
  create: UserCreateInput;
};

export type UserUpsertWithWhereUniqueNestedInput = {
  where: UserWhereUniqueInput;
  update: UserUpdateDataInput;
  create: UserCreateInput;
};

export type UserWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  email?: Maybe<Scalars['String']>;
  email_not?: Maybe<Scalars['String']>;
  email_in?: Maybe<Array<Scalars['String']>>;
  email_not_in?: Maybe<Array<Scalars['String']>>;
  email_lt?: Maybe<Scalars['String']>;
  email_lte?: Maybe<Scalars['String']>;
  email_gt?: Maybe<Scalars['String']>;
  email_gte?: Maybe<Scalars['String']>;
  email_contains?: Maybe<Scalars['String']>;
  email_not_contains?: Maybe<Scalars['String']>;
  email_starts_with?: Maybe<Scalars['String']>;
  email_not_starts_with?: Maybe<Scalars['String']>;
  email_ends_with?: Maybe<Scalars['String']>;
  email_not_ends_with?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  firstName_not?: Maybe<Scalars['String']>;
  firstName_in?: Maybe<Array<Scalars['String']>>;
  firstName_not_in?: Maybe<Array<Scalars['String']>>;
  firstName_lt?: Maybe<Scalars['String']>;
  firstName_lte?: Maybe<Scalars['String']>;
  firstName_gt?: Maybe<Scalars['String']>;
  firstName_gte?: Maybe<Scalars['String']>;
  firstName_contains?: Maybe<Scalars['String']>;
  firstName_not_contains?: Maybe<Scalars['String']>;
  firstName_starts_with?: Maybe<Scalars['String']>;
  firstName_not_starts_with?: Maybe<Scalars['String']>;
  firstName_ends_with?: Maybe<Scalars['String']>;
  firstName_not_ends_with?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  lastName_not?: Maybe<Scalars['String']>;
  lastName_in?: Maybe<Array<Scalars['String']>>;
  lastName_not_in?: Maybe<Array<Scalars['String']>>;
  lastName_lt?: Maybe<Scalars['String']>;
  lastName_lte?: Maybe<Scalars['String']>;
  lastName_gt?: Maybe<Scalars['String']>;
  lastName_gte?: Maybe<Scalars['String']>;
  lastName_contains?: Maybe<Scalars['String']>;
  lastName_not_contains?: Maybe<Scalars['String']>;
  lastName_starts_with?: Maybe<Scalars['String']>;
  lastName_not_starts_with?: Maybe<Scalars['String']>;
  lastName_ends_with?: Maybe<Scalars['String']>;
  lastName_not_ends_with?: Maybe<Scalars['String']>;
  authZeroID?: Maybe<Scalars['String']>;
  authZeroID_not?: Maybe<Scalars['String']>;
  authZeroID_in?: Maybe<Array<Scalars['String']>>;
  authZeroID_not_in?: Maybe<Array<Scalars['String']>>;
  authZeroID_lt?: Maybe<Scalars['String']>;
  authZeroID_lte?: Maybe<Scalars['String']>;
  authZeroID_gt?: Maybe<Scalars['String']>;
  authZeroID_gte?: Maybe<Scalars['String']>;
  authZeroID_contains?: Maybe<Scalars['String']>;
  authZeroID_not_contains?: Maybe<Scalars['String']>;
  authZeroID_starts_with?: Maybe<Scalars['String']>;
  authZeroID_not_starts_with?: Maybe<Scalars['String']>;
  authZeroID_ends_with?: Maybe<Scalars['String']>;
  authZeroID_not_ends_with?: Maybe<Scalars['String']>;
  role?: Maybe<Role>;
  role_not?: Maybe<Role>;
  role_in?: Maybe<Array<Role>>;
  role_not_in?: Maybe<Array<Role>>;
  profileImage?: Maybe<Scalars['String']>;
  profileImage_not?: Maybe<Scalars['String']>;
  profileImage_in?: Maybe<Array<Scalars['String']>>;
  profileImage_not_in?: Maybe<Array<Scalars['String']>>;
  profileImage_lt?: Maybe<Scalars['String']>;
  profileImage_lte?: Maybe<Scalars['String']>;
  profileImage_gt?: Maybe<Scalars['String']>;
  profileImage_gte?: Maybe<Scalars['String']>;
  profileImage_contains?: Maybe<Scalars['String']>;
  profileImage_not_contains?: Maybe<Scalars['String']>;
  profileImage_starts_with?: Maybe<Scalars['String']>;
  profileImage_not_starts_with?: Maybe<Scalars['String']>;
  profileImage_ends_with?: Maybe<Scalars['String']>;
  profileImage_not_ends_with?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isActive_not?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<UserWhereInput>>;
  OR?: Maybe<Array<UserWhereInput>>;
  NOT?: Maybe<Array<UserWhereInput>>;
};

export type UserWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
  email?: Maybe<Scalars['String']>;
};

export type Video = {
  __typename?: 'Video';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  videoNumber?: Maybe<Scalars['String']>;
  status?: Maybe<VideoStatus>;
  isLive?: Maybe<Scalars['Boolean']>;
  clips?: Maybe<Array<Clip>>;
  liveChatAccess: Array<Scalars['String']>;
  scheduleAt?: Maybe<Scalars['DateTime']>;
  discussionId?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['Json']>;
  rating?: Maybe<Scalars['String']>;
  captions?: Maybe<Array<Caption>>;
  allowedContinents: Array<Scalars['String']>;
  allowedCountries?: Maybe<Scalars['Json']>;
  logoImage?: Maybe<Scalars['String']>;
  videoState?: Maybe<Scalars['String']>;
  audioState?: Maybe<Scalars['String']>;
  muxAssetId?: Maybe<Scalars['String']>;
  streamKey?: Maybe<Scalars['String']>;
  uploadId?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  muxPlaybackId?: Maybe<Scalars['String']>;
  playbackPolicy?: Maybe<Scalars['String']>;
  videoURL?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
  videoAccess: Array<Scalars['String']>;
  createdBy?: Maybe<User>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  muxStoryBoardURL?: Maybe<Scalars['String']>;
  carouselVideoOrder?: Maybe<Scalars['Int']>;
  carouselVideoId?: Maybe<Scalars['ID']>;
  message?: Maybe<Scalars['String']>;
  watchTime?: Maybe<Scalars['Float']>;
  allowedCountryNames?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type VideoClipsArgs = {
  where?: Maybe<ClipWhereInput>;
  orderBy?: Maybe<ClipOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type VideoCaptionsArgs = {
  where?: Maybe<CaptionWhereInput>;
  orderBy?: Maybe<CaptionOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type VideoCarousel = {
  __typename?: 'VideoCarousel';
  id?: Maybe<Scalars['ID']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  nextOrder?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  videos?: Maybe<Array<Maybe<Video>>>;
  layout?: Maybe<Scalars['String']>;
};

export type VideoCarouselCustomInput = {
  videoId: Scalars['ID'];
  order?: Maybe<Scalars['Int']>;
};

export type VideoCarouselModuleCustomInput = {
  title: Scalars['String'];
  order: Scalars['Int'];
  isPublished?: Maybe<Scalars['Boolean']>;
  carouselVideos?: Maybe<Array<Maybe<VideoCarouselCustomInput>>>;
};

export type VideoCarouselModuleVideos = {
  __typename?: 'VideoCarouselModuleVideos';
  id: Scalars['ID'];
  order?: Maybe<Scalars['Int']>;
  video?: Maybe<Video>;
  videoCarousel?: Maybe<CarouselModule>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type VideoCarouselModuleVideosConnection = {
  __typename?: 'VideoCarouselModuleVideosConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<VideoCarouselModuleVideosEdge>>;
  aggregate: AggregateVideoCarouselModuleVideos;
};

export type VideoCarouselModuleVideosCreateInput = {
  id?: Maybe<Scalars['ID']>;
  order?: Maybe<Scalars['Int']>;
  video?: Maybe<VideoCreateOneInput>;
  videoCarousel?: Maybe<CarouselModuleCreateOneWithoutCarouselVideosInput>;
};

export type VideoCarouselModuleVideosCreateManyWithoutVideoCarouselInput = {
  create?: Maybe<Array<VideoCarouselModuleVideosCreateWithoutVideoCarouselInput>>;
  connect?: Maybe<Array<VideoCarouselModuleVideosWhereUniqueInput>>;
};

export type VideoCarouselModuleVideosCreateWithoutVideoCarouselInput = {
  id?: Maybe<Scalars['ID']>;
  order?: Maybe<Scalars['Int']>;
  video?: Maybe<VideoCreateOneInput>;
};

export type VideoCarouselModuleVideosEdge = {
  __typename?: 'VideoCarouselModuleVideosEdge';
  node: VideoCarouselModuleVideos;
  cursor: Scalars['String'];
};

export enum VideoCarouselModuleVideosOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  OrderAsc = 'order_ASC',
  OrderDesc = 'order_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type VideoCarouselModuleVideosPreviousValues = {
  __typename?: 'VideoCarouselModuleVideosPreviousValues';
  id: Scalars['ID'];
  order?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type VideoCarouselModuleVideosScalarWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  order?: Maybe<Scalars['Int']>;
  order_not?: Maybe<Scalars['Int']>;
  order_in?: Maybe<Array<Scalars['Int']>>;
  order_not_in?: Maybe<Array<Scalars['Int']>>;
  order_lt?: Maybe<Scalars['Int']>;
  order_lte?: Maybe<Scalars['Int']>;
  order_gt?: Maybe<Scalars['Int']>;
  order_gte?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<VideoCarouselModuleVideosScalarWhereInput>>;
  OR?: Maybe<Array<VideoCarouselModuleVideosScalarWhereInput>>;
  NOT?: Maybe<Array<VideoCarouselModuleVideosScalarWhereInput>>;
};

export type VideoCarouselModuleVideosSubscriptionPayload = {
  __typename?: 'VideoCarouselModuleVideosSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<VideoCarouselModuleVideos>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<VideoCarouselModuleVideosPreviousValues>;
};

export type VideoCarouselModuleVideosSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<VideoCarouselModuleVideosWhereInput>;
  AND?: Maybe<Array<VideoCarouselModuleVideosSubscriptionWhereInput>>;
  OR?: Maybe<Array<VideoCarouselModuleVideosSubscriptionWhereInput>>;
  NOT?: Maybe<Array<VideoCarouselModuleVideosSubscriptionWhereInput>>;
};

export type VideoCarouselModuleVideosUpdateInput = {
  order?: Maybe<Scalars['Int']>;
  video?: Maybe<VideoUpdateOneInput>;
  videoCarousel?: Maybe<CarouselModuleUpdateOneWithoutCarouselVideosInput>;
};

export type VideoCarouselModuleVideosUpdateManyDataInput = {
  order?: Maybe<Scalars['Int']>;
};

export type VideoCarouselModuleVideosUpdateManyMutationInput = {
  order?: Maybe<Scalars['Int']>;
};

export type VideoCarouselModuleVideosUpdateManyWithWhereNestedInput = {
  where: VideoCarouselModuleVideosScalarWhereInput;
  data: VideoCarouselModuleVideosUpdateManyDataInput;
};

export type VideoCarouselModuleVideosUpdateManyWithoutVideoCarouselInput = {
  create?: Maybe<Array<VideoCarouselModuleVideosCreateWithoutVideoCarouselInput>>;
  delete?: Maybe<Array<VideoCarouselModuleVideosWhereUniqueInput>>;
  connect?: Maybe<Array<VideoCarouselModuleVideosWhereUniqueInput>>;
  set?: Maybe<Array<VideoCarouselModuleVideosWhereUniqueInput>>;
  disconnect?: Maybe<Array<VideoCarouselModuleVideosWhereUniqueInput>>;
  update?: Maybe<Array<VideoCarouselModuleVideosUpdateWithWhereUniqueWithoutVideoCarouselInput>>;
  upsert?: Maybe<Array<VideoCarouselModuleVideosUpsertWithWhereUniqueWithoutVideoCarouselInput>>;
  deleteMany?: Maybe<Array<VideoCarouselModuleVideosScalarWhereInput>>;
  updateMany?: Maybe<Array<VideoCarouselModuleVideosUpdateManyWithWhereNestedInput>>;
};

export type VideoCarouselModuleVideosUpdateWithWhereUniqueWithoutVideoCarouselInput = {
  where: VideoCarouselModuleVideosWhereUniqueInput;
  data: VideoCarouselModuleVideosUpdateWithoutVideoCarouselDataInput;
};

export type VideoCarouselModuleVideosUpdateWithoutVideoCarouselDataInput = {
  order?: Maybe<Scalars['Int']>;
  video?: Maybe<VideoUpdateOneInput>;
};

export type VideoCarouselModuleVideosUpsertWithWhereUniqueWithoutVideoCarouselInput = {
  where: VideoCarouselModuleVideosWhereUniqueInput;
  update: VideoCarouselModuleVideosUpdateWithoutVideoCarouselDataInput;
  create: VideoCarouselModuleVideosCreateWithoutVideoCarouselInput;
};

export type VideoCarouselModuleVideosWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  order?: Maybe<Scalars['Int']>;
  order_not?: Maybe<Scalars['Int']>;
  order_in?: Maybe<Array<Scalars['Int']>>;
  order_not_in?: Maybe<Array<Scalars['Int']>>;
  order_lt?: Maybe<Scalars['Int']>;
  order_lte?: Maybe<Scalars['Int']>;
  order_gt?: Maybe<Scalars['Int']>;
  order_gte?: Maybe<Scalars['Int']>;
  video?: Maybe<VideoWhereInput>;
  videoCarousel?: Maybe<CarouselModuleWhereInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<VideoCarouselModuleVideosWhereInput>>;
  OR?: Maybe<Array<VideoCarouselModuleVideosWhereInput>>;
  NOT?: Maybe<Array<VideoCarouselModuleVideosWhereInput>>;
};

export type VideoCarouselModuleVideosWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type VideoConnection = {
  __typename?: 'VideoConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<VideoEdge>>;
  aggregate: AggregateVideo;
};

export type VideoCreateInput = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  videoNumber?: Maybe<Scalars['String']>;
  status?: Maybe<VideoStatus>;
  isLive?: Maybe<Scalars['Boolean']>;
  clips?: Maybe<ClipCreateManyWithoutVideoInput>;
  liveChatAccess?: Maybe<VideoCreateliveChatAccessInput>;
  scheduleAt?: Maybe<Scalars['DateTime']>;
  discussionId?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['Json']>;
  rating?: Maybe<Scalars['String']>;
  captions?: Maybe<CaptionCreateManyInput>;
  allowedContinents?: Maybe<VideoCreateallowedContinentsInput>;
  allowedCountries?: Maybe<Scalars['Json']>;
  logoImage?: Maybe<Scalars['String']>;
  videoState?: Maybe<Scalars['String']>;
  audioState?: Maybe<Scalars['String']>;
  muxAssetId?: Maybe<Scalars['String']>;
  streamKey?: Maybe<Scalars['String']>;
  uploadId?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  muxPlaybackId?: Maybe<Scalars['String']>;
  playbackPolicy?: Maybe<Scalars['String']>;
  videoURL?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
  videoAccess?: Maybe<VideoCreatevideoAccessInput>;
  createdBy?: Maybe<UserCreateOneInput>;
};

export type VideoCreateOneInput = {
  create?: Maybe<VideoCreateInput>;
  connect?: Maybe<VideoWhereUniqueInput>;
};

export type VideoCreateOneWithoutClipsInput = {
  create?: Maybe<VideoCreateWithoutClipsInput>;
  connect?: Maybe<VideoWhereUniqueInput>;
};

export type VideoCreateWithoutClipsInput = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  videoNumber?: Maybe<Scalars['String']>;
  status?: Maybe<VideoStatus>;
  isLive?: Maybe<Scalars['Boolean']>;
  liveChatAccess?: Maybe<VideoCreateliveChatAccessInput>;
  scheduleAt?: Maybe<Scalars['DateTime']>;
  discussionId?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['Json']>;
  rating?: Maybe<Scalars['String']>;
  captions?: Maybe<CaptionCreateManyInput>;
  allowedContinents?: Maybe<VideoCreateallowedContinentsInput>;
  allowedCountries?: Maybe<Scalars['Json']>;
  logoImage?: Maybe<Scalars['String']>;
  videoState?: Maybe<Scalars['String']>;
  audioState?: Maybe<Scalars['String']>;
  muxAssetId?: Maybe<Scalars['String']>;
  streamKey?: Maybe<Scalars['String']>;
  uploadId?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  muxPlaybackId?: Maybe<Scalars['String']>;
  playbackPolicy?: Maybe<Scalars['String']>;
  videoURL?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
  videoAccess?: Maybe<VideoCreatevideoAccessInput>;
  createdBy?: Maybe<UserCreateOneInput>;
};

export type VideoCreateallowedContinentsInput = {
  set?: Maybe<Array<Scalars['String']>>;
};

export type VideoCreateliveChatAccessInput = {
  set?: Maybe<Array<Scalars['String']>>;
};

export type VideoCreatevideoAccessInput = {
  set?: Maybe<Array<Scalars['String']>>;
};

export type VideoEdge = {
  __typename?: 'VideoEdge';
  node: Video;
  cursor: Scalars['String'];
};

export type VideoLiveStreamData = {
  __typename?: 'VideoLiveStreamData';
  videoURL?: Maybe<Scalars['String']>;
  streamKey?: Maybe<Scalars['String']>;
};

export type VideoLiveStreamDataInput = {
  id: Scalars['ID'];
};

export enum VideoOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  ImageAsc = 'image_ASC',
  ImageDesc = 'image_DESC',
  VideoNumberAsc = 'videoNumber_ASC',
  VideoNumberDesc = 'videoNumber_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  IsLiveAsc = 'isLive_ASC',
  IsLiveDesc = 'isLive_DESC',
  ScheduleAtAsc = 'scheduleAt_ASC',
  ScheduleAtDesc = 'scheduleAt_DESC',
  DiscussionIdAsc = 'discussionId_ASC',
  DiscussionIdDesc = 'discussionId_DESC',
  MetadataAsc = 'metadata_ASC',
  MetadataDesc = 'metadata_DESC',
  RatingAsc = 'rating_ASC',
  RatingDesc = 'rating_DESC',
  AllowedCountriesAsc = 'allowedCountries_ASC',
  AllowedCountriesDesc = 'allowedCountries_DESC',
  LogoImageAsc = 'logoImage_ASC',
  LogoImageDesc = 'logoImage_DESC',
  VideoStateAsc = 'videoState_ASC',
  VideoStateDesc = 'videoState_DESC',
  AudioStateAsc = 'audioState_ASC',
  AudioStateDesc = 'audioState_DESC',
  MuxAssetIdAsc = 'muxAssetId_ASC',
  MuxAssetIdDesc = 'muxAssetId_DESC',
  StreamKeyAsc = 'streamKey_ASC',
  StreamKeyDesc = 'streamKey_DESC',
  UploadIdAsc = 'uploadId_ASC',
  UploadIdDesc = 'uploadId_DESC',
  ThumbnailAsc = 'thumbnail_ASC',
  ThumbnailDesc = 'thumbnail_DESC',
  MuxPlaybackIdAsc = 'muxPlaybackId_ASC',
  MuxPlaybackIdDesc = 'muxPlaybackId_DESC',
  PlaybackPolicyAsc = 'playbackPolicy_ASC',
  PlaybackPolicyDesc = 'playbackPolicy_DESC',
  VideoUrlAsc = 'videoURL_ASC',
  VideoUrlDesc = 'videoURL_DESC',
  DurationAsc = 'duration_ASC',
  DurationDesc = 'duration_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type VideoPremiere = {
  __typename?: 'VideoPremiere';
  id?: Maybe<Scalars['ID']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  premiereImage?: Maybe<Scalars['String']>;
  video?: Maybe<Video>;
};

export type VideoPreviousValues = {
  __typename?: 'VideoPreviousValues';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  videoNumber?: Maybe<Scalars['String']>;
  status?: Maybe<VideoStatus>;
  isLive?: Maybe<Scalars['Boolean']>;
  liveChatAccess: Array<Scalars['String']>;
  scheduleAt?: Maybe<Scalars['DateTime']>;
  discussionId?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['Json']>;
  rating?: Maybe<Scalars['String']>;
  allowedContinents: Array<Scalars['String']>;
  allowedCountries?: Maybe<Scalars['Json']>;
  logoImage?: Maybe<Scalars['String']>;
  videoState?: Maybe<Scalars['String']>;
  audioState?: Maybe<Scalars['String']>;
  muxAssetId?: Maybe<Scalars['String']>;
  streamKey?: Maybe<Scalars['String']>;
  uploadId?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  muxPlaybackId?: Maybe<Scalars['String']>;
  playbackPolicy?: Maybe<Scalars['String']>;
  videoURL?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
  videoAccess: Array<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type VideoSignedUrlDataInput = {
  videoId: Scalars['String'];
  fileName: Scalars['String'];
  contentType: Scalars['String'];
};

export enum VideoStatus {
  Unpublished = 'UNPUBLISHED',
  Published = 'PUBLISHED',
  Live = 'LIVE',
  Draft = 'DRAFT',
  Scheduled = 'SCHEDULED',
  LiveFinished = 'LIVE_FINISHED'
}

export type VideoSubscriptionPayload = {
  __typename?: 'VideoSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<Video>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<VideoPreviousValues>;
};

export type VideoSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<VideoWhereInput>;
  AND?: Maybe<Array<VideoSubscriptionWhereInput>>;
  OR?: Maybe<Array<VideoSubscriptionWhereInput>>;
  NOT?: Maybe<Array<VideoSubscriptionWhereInput>>;
};

export type VideoUpdateDataInput = {
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  videoNumber?: Maybe<Scalars['String']>;
  status?: Maybe<VideoStatus>;
  isLive?: Maybe<Scalars['Boolean']>;
  clips?: Maybe<ClipUpdateManyWithoutVideoInput>;
  liveChatAccess?: Maybe<VideoUpdateliveChatAccessInput>;
  scheduleAt?: Maybe<Scalars['DateTime']>;
  discussionId?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['Json']>;
  rating?: Maybe<Scalars['String']>;
  captions?: Maybe<CaptionUpdateManyInput>;
  allowedContinents?: Maybe<VideoUpdateallowedContinentsInput>;
  allowedCountries?: Maybe<Scalars['Json']>;
  logoImage?: Maybe<Scalars['String']>;
  videoState?: Maybe<Scalars['String']>;
  audioState?: Maybe<Scalars['String']>;
  muxAssetId?: Maybe<Scalars['String']>;
  streamKey?: Maybe<Scalars['String']>;
  uploadId?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  muxPlaybackId?: Maybe<Scalars['String']>;
  playbackPolicy?: Maybe<Scalars['String']>;
  videoURL?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
  videoAccess?: Maybe<VideoUpdatevideoAccessInput>;
  createdBy?: Maybe<UserUpdateOneInput>;
};

export type VideoUpdateInput = {
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  videoNumber?: Maybe<Scalars['String']>;
  status?: Maybe<VideoStatus>;
  isLive?: Maybe<Scalars['Boolean']>;
  clips?: Maybe<ClipUpdateManyWithoutVideoInput>;
  liveChatAccess?: Maybe<VideoUpdateliveChatAccessInput>;
  scheduleAt?: Maybe<Scalars['DateTime']>;
  discussionId?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['Json']>;
  rating?: Maybe<Scalars['String']>;
  captions?: Maybe<CaptionUpdateManyInput>;
  allowedContinents?: Maybe<VideoUpdateallowedContinentsInput>;
  allowedCountries?: Maybe<Scalars['Json']>;
  logoImage?: Maybe<Scalars['String']>;
  videoState?: Maybe<Scalars['String']>;
  audioState?: Maybe<Scalars['String']>;
  muxAssetId?: Maybe<Scalars['String']>;
  streamKey?: Maybe<Scalars['String']>;
  uploadId?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  muxPlaybackId?: Maybe<Scalars['String']>;
  playbackPolicy?: Maybe<Scalars['String']>;
  videoURL?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
  videoAccess?: Maybe<VideoUpdatevideoAccessInput>;
  createdBy?: Maybe<UserUpdateOneInput>;
};

export type VideoUpdateManyMutationInput = {
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  videoNumber?: Maybe<Scalars['String']>;
  status?: Maybe<VideoStatus>;
  isLive?: Maybe<Scalars['Boolean']>;
  liveChatAccess?: Maybe<VideoUpdateliveChatAccessInput>;
  scheduleAt?: Maybe<Scalars['DateTime']>;
  discussionId?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['Json']>;
  rating?: Maybe<Scalars['String']>;
  allowedContinents?: Maybe<VideoUpdateallowedContinentsInput>;
  allowedCountries?: Maybe<Scalars['Json']>;
  logoImage?: Maybe<Scalars['String']>;
  videoState?: Maybe<Scalars['String']>;
  audioState?: Maybe<Scalars['String']>;
  muxAssetId?: Maybe<Scalars['String']>;
  streamKey?: Maybe<Scalars['String']>;
  uploadId?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  muxPlaybackId?: Maybe<Scalars['String']>;
  playbackPolicy?: Maybe<Scalars['String']>;
  videoURL?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
  videoAccess?: Maybe<VideoUpdatevideoAccessInput>;
};

export type VideoUpdateOneInput = {
  create?: Maybe<VideoCreateInput>;
  update?: Maybe<VideoUpdateDataInput>;
  upsert?: Maybe<VideoUpsertNestedInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<VideoWhereUniqueInput>;
};

export type VideoUpdateOneWithoutClipsInput = {
  create?: Maybe<VideoCreateWithoutClipsInput>;
  update?: Maybe<VideoUpdateWithoutClipsDataInput>;
  upsert?: Maybe<VideoUpsertWithoutClipsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<VideoWhereUniqueInput>;
};

export type VideoUpdateWithoutClipsDataInput = {
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  videoNumber?: Maybe<Scalars['String']>;
  status?: Maybe<VideoStatus>;
  isLive?: Maybe<Scalars['Boolean']>;
  liveChatAccess?: Maybe<VideoUpdateliveChatAccessInput>;
  scheduleAt?: Maybe<Scalars['DateTime']>;
  discussionId?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['Json']>;
  rating?: Maybe<Scalars['String']>;
  captions?: Maybe<CaptionUpdateManyInput>;
  allowedContinents?: Maybe<VideoUpdateallowedContinentsInput>;
  allowedCountries?: Maybe<Scalars['Json']>;
  logoImage?: Maybe<Scalars['String']>;
  videoState?: Maybe<Scalars['String']>;
  audioState?: Maybe<Scalars['String']>;
  muxAssetId?: Maybe<Scalars['String']>;
  streamKey?: Maybe<Scalars['String']>;
  uploadId?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  muxPlaybackId?: Maybe<Scalars['String']>;
  playbackPolicy?: Maybe<Scalars['String']>;
  videoURL?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
  videoAccess?: Maybe<VideoUpdatevideoAccessInput>;
  createdBy?: Maybe<UserUpdateOneInput>;
};

export type VideoUpdateallowedContinentsInput = {
  set?: Maybe<Array<Scalars['String']>>;
};

export type VideoUpdateliveChatAccessInput = {
  set?: Maybe<Array<Scalars['String']>>;
};

export type VideoUpdatevideoAccessInput = {
  set?: Maybe<Array<Scalars['String']>>;
};

export type VideoUpsertNestedInput = {
  update: VideoUpdateDataInput;
  create: VideoCreateInput;
};

export type VideoUpsertWithoutClipsInput = {
  update: VideoUpdateWithoutClipsDataInput;
  create: VideoCreateWithoutClipsInput;
};

export type VideoWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Scalars['String']>>;
  name_not_in?: Maybe<Array<Scalars['String']>>;
  name_lt?: Maybe<Scalars['String']>;
  name_lte?: Maybe<Scalars['String']>;
  name_gt?: Maybe<Scalars['String']>;
  name_gte?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  slug_not?: Maybe<Scalars['String']>;
  slug_in?: Maybe<Array<Scalars['String']>>;
  slug_not_in?: Maybe<Array<Scalars['String']>>;
  slug_lt?: Maybe<Scalars['String']>;
  slug_lte?: Maybe<Scalars['String']>;
  slug_gt?: Maybe<Scalars['String']>;
  slug_gte?: Maybe<Scalars['String']>;
  slug_contains?: Maybe<Scalars['String']>;
  slug_not_contains?: Maybe<Scalars['String']>;
  slug_starts_with?: Maybe<Scalars['String']>;
  slug_not_starts_with?: Maybe<Scalars['String']>;
  slug_ends_with?: Maybe<Scalars['String']>;
  slug_not_ends_with?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  description_not?: Maybe<Scalars['String']>;
  description_in?: Maybe<Array<Scalars['String']>>;
  description_not_in?: Maybe<Array<Scalars['String']>>;
  description_lt?: Maybe<Scalars['String']>;
  description_lte?: Maybe<Scalars['String']>;
  description_gt?: Maybe<Scalars['String']>;
  description_gte?: Maybe<Scalars['String']>;
  description_contains?: Maybe<Scalars['String']>;
  description_not_contains?: Maybe<Scalars['String']>;
  description_starts_with?: Maybe<Scalars['String']>;
  description_not_starts_with?: Maybe<Scalars['String']>;
  description_ends_with?: Maybe<Scalars['String']>;
  description_not_ends_with?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  image_not?: Maybe<Scalars['String']>;
  image_in?: Maybe<Array<Scalars['String']>>;
  image_not_in?: Maybe<Array<Scalars['String']>>;
  image_lt?: Maybe<Scalars['String']>;
  image_lte?: Maybe<Scalars['String']>;
  image_gt?: Maybe<Scalars['String']>;
  image_gte?: Maybe<Scalars['String']>;
  image_contains?: Maybe<Scalars['String']>;
  image_not_contains?: Maybe<Scalars['String']>;
  image_starts_with?: Maybe<Scalars['String']>;
  image_not_starts_with?: Maybe<Scalars['String']>;
  image_ends_with?: Maybe<Scalars['String']>;
  image_not_ends_with?: Maybe<Scalars['String']>;
  videoNumber?: Maybe<Scalars['String']>;
  videoNumber_not?: Maybe<Scalars['String']>;
  videoNumber_in?: Maybe<Array<Scalars['String']>>;
  videoNumber_not_in?: Maybe<Array<Scalars['String']>>;
  videoNumber_lt?: Maybe<Scalars['String']>;
  videoNumber_lte?: Maybe<Scalars['String']>;
  videoNumber_gt?: Maybe<Scalars['String']>;
  videoNumber_gte?: Maybe<Scalars['String']>;
  videoNumber_contains?: Maybe<Scalars['String']>;
  videoNumber_not_contains?: Maybe<Scalars['String']>;
  videoNumber_starts_with?: Maybe<Scalars['String']>;
  videoNumber_not_starts_with?: Maybe<Scalars['String']>;
  videoNumber_ends_with?: Maybe<Scalars['String']>;
  videoNumber_not_ends_with?: Maybe<Scalars['String']>;
  status?: Maybe<VideoStatus>;
  status_not?: Maybe<VideoStatus>;
  status_in?: Maybe<Array<VideoStatus>>;
  status_not_in?: Maybe<Array<VideoStatus>>;
  isLive?: Maybe<Scalars['Boolean']>;
  isLive_not?: Maybe<Scalars['Boolean']>;
  clips_every?: Maybe<ClipWhereInput>;
  clips_some?: Maybe<ClipWhereInput>;
  clips_none?: Maybe<ClipWhereInput>;
  scheduleAt?: Maybe<Scalars['DateTime']>;
  scheduleAt_not?: Maybe<Scalars['DateTime']>;
  scheduleAt_in?: Maybe<Array<Scalars['DateTime']>>;
  scheduleAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  scheduleAt_lt?: Maybe<Scalars['DateTime']>;
  scheduleAt_lte?: Maybe<Scalars['DateTime']>;
  scheduleAt_gt?: Maybe<Scalars['DateTime']>;
  scheduleAt_gte?: Maybe<Scalars['DateTime']>;
  discussionId?: Maybe<Scalars['String']>;
  discussionId_not?: Maybe<Scalars['String']>;
  discussionId_in?: Maybe<Array<Scalars['String']>>;
  discussionId_not_in?: Maybe<Array<Scalars['String']>>;
  discussionId_lt?: Maybe<Scalars['String']>;
  discussionId_lte?: Maybe<Scalars['String']>;
  discussionId_gt?: Maybe<Scalars['String']>;
  discussionId_gte?: Maybe<Scalars['String']>;
  discussionId_contains?: Maybe<Scalars['String']>;
  discussionId_not_contains?: Maybe<Scalars['String']>;
  discussionId_starts_with?: Maybe<Scalars['String']>;
  discussionId_not_starts_with?: Maybe<Scalars['String']>;
  discussionId_ends_with?: Maybe<Scalars['String']>;
  discussionId_not_ends_with?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['String']>;
  rating_not?: Maybe<Scalars['String']>;
  rating_in?: Maybe<Array<Scalars['String']>>;
  rating_not_in?: Maybe<Array<Scalars['String']>>;
  rating_lt?: Maybe<Scalars['String']>;
  rating_lte?: Maybe<Scalars['String']>;
  rating_gt?: Maybe<Scalars['String']>;
  rating_gte?: Maybe<Scalars['String']>;
  rating_contains?: Maybe<Scalars['String']>;
  rating_not_contains?: Maybe<Scalars['String']>;
  rating_starts_with?: Maybe<Scalars['String']>;
  rating_not_starts_with?: Maybe<Scalars['String']>;
  rating_ends_with?: Maybe<Scalars['String']>;
  rating_not_ends_with?: Maybe<Scalars['String']>;
  captions_every?: Maybe<CaptionWhereInput>;
  captions_some?: Maybe<CaptionWhereInput>;
  captions_none?: Maybe<CaptionWhereInput>;
  logoImage?: Maybe<Scalars['String']>;
  logoImage_not?: Maybe<Scalars['String']>;
  logoImage_in?: Maybe<Array<Scalars['String']>>;
  logoImage_not_in?: Maybe<Array<Scalars['String']>>;
  logoImage_lt?: Maybe<Scalars['String']>;
  logoImage_lte?: Maybe<Scalars['String']>;
  logoImage_gt?: Maybe<Scalars['String']>;
  logoImage_gte?: Maybe<Scalars['String']>;
  logoImage_contains?: Maybe<Scalars['String']>;
  logoImage_not_contains?: Maybe<Scalars['String']>;
  logoImage_starts_with?: Maybe<Scalars['String']>;
  logoImage_not_starts_with?: Maybe<Scalars['String']>;
  logoImage_ends_with?: Maybe<Scalars['String']>;
  logoImage_not_ends_with?: Maybe<Scalars['String']>;
  videoState?: Maybe<Scalars['String']>;
  videoState_not?: Maybe<Scalars['String']>;
  videoState_in?: Maybe<Array<Scalars['String']>>;
  videoState_not_in?: Maybe<Array<Scalars['String']>>;
  videoState_lt?: Maybe<Scalars['String']>;
  videoState_lte?: Maybe<Scalars['String']>;
  videoState_gt?: Maybe<Scalars['String']>;
  videoState_gte?: Maybe<Scalars['String']>;
  videoState_contains?: Maybe<Scalars['String']>;
  videoState_not_contains?: Maybe<Scalars['String']>;
  videoState_starts_with?: Maybe<Scalars['String']>;
  videoState_not_starts_with?: Maybe<Scalars['String']>;
  videoState_ends_with?: Maybe<Scalars['String']>;
  videoState_not_ends_with?: Maybe<Scalars['String']>;
  audioState?: Maybe<Scalars['String']>;
  audioState_not?: Maybe<Scalars['String']>;
  audioState_in?: Maybe<Array<Scalars['String']>>;
  audioState_not_in?: Maybe<Array<Scalars['String']>>;
  audioState_lt?: Maybe<Scalars['String']>;
  audioState_lte?: Maybe<Scalars['String']>;
  audioState_gt?: Maybe<Scalars['String']>;
  audioState_gte?: Maybe<Scalars['String']>;
  audioState_contains?: Maybe<Scalars['String']>;
  audioState_not_contains?: Maybe<Scalars['String']>;
  audioState_starts_with?: Maybe<Scalars['String']>;
  audioState_not_starts_with?: Maybe<Scalars['String']>;
  audioState_ends_with?: Maybe<Scalars['String']>;
  audioState_not_ends_with?: Maybe<Scalars['String']>;
  muxAssetId?: Maybe<Scalars['String']>;
  muxAssetId_not?: Maybe<Scalars['String']>;
  muxAssetId_in?: Maybe<Array<Scalars['String']>>;
  muxAssetId_not_in?: Maybe<Array<Scalars['String']>>;
  muxAssetId_lt?: Maybe<Scalars['String']>;
  muxAssetId_lte?: Maybe<Scalars['String']>;
  muxAssetId_gt?: Maybe<Scalars['String']>;
  muxAssetId_gte?: Maybe<Scalars['String']>;
  muxAssetId_contains?: Maybe<Scalars['String']>;
  muxAssetId_not_contains?: Maybe<Scalars['String']>;
  muxAssetId_starts_with?: Maybe<Scalars['String']>;
  muxAssetId_not_starts_with?: Maybe<Scalars['String']>;
  muxAssetId_ends_with?: Maybe<Scalars['String']>;
  muxAssetId_not_ends_with?: Maybe<Scalars['String']>;
  streamKey?: Maybe<Scalars['String']>;
  streamKey_not?: Maybe<Scalars['String']>;
  streamKey_in?: Maybe<Array<Scalars['String']>>;
  streamKey_not_in?: Maybe<Array<Scalars['String']>>;
  streamKey_lt?: Maybe<Scalars['String']>;
  streamKey_lte?: Maybe<Scalars['String']>;
  streamKey_gt?: Maybe<Scalars['String']>;
  streamKey_gte?: Maybe<Scalars['String']>;
  streamKey_contains?: Maybe<Scalars['String']>;
  streamKey_not_contains?: Maybe<Scalars['String']>;
  streamKey_starts_with?: Maybe<Scalars['String']>;
  streamKey_not_starts_with?: Maybe<Scalars['String']>;
  streamKey_ends_with?: Maybe<Scalars['String']>;
  streamKey_not_ends_with?: Maybe<Scalars['String']>;
  uploadId?: Maybe<Scalars['String']>;
  uploadId_not?: Maybe<Scalars['String']>;
  uploadId_in?: Maybe<Array<Scalars['String']>>;
  uploadId_not_in?: Maybe<Array<Scalars['String']>>;
  uploadId_lt?: Maybe<Scalars['String']>;
  uploadId_lte?: Maybe<Scalars['String']>;
  uploadId_gt?: Maybe<Scalars['String']>;
  uploadId_gte?: Maybe<Scalars['String']>;
  uploadId_contains?: Maybe<Scalars['String']>;
  uploadId_not_contains?: Maybe<Scalars['String']>;
  uploadId_starts_with?: Maybe<Scalars['String']>;
  uploadId_not_starts_with?: Maybe<Scalars['String']>;
  uploadId_ends_with?: Maybe<Scalars['String']>;
  uploadId_not_ends_with?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  thumbnail_not?: Maybe<Scalars['String']>;
  thumbnail_in?: Maybe<Array<Scalars['String']>>;
  thumbnail_not_in?: Maybe<Array<Scalars['String']>>;
  thumbnail_lt?: Maybe<Scalars['String']>;
  thumbnail_lte?: Maybe<Scalars['String']>;
  thumbnail_gt?: Maybe<Scalars['String']>;
  thumbnail_gte?: Maybe<Scalars['String']>;
  thumbnail_contains?: Maybe<Scalars['String']>;
  thumbnail_not_contains?: Maybe<Scalars['String']>;
  thumbnail_starts_with?: Maybe<Scalars['String']>;
  thumbnail_not_starts_with?: Maybe<Scalars['String']>;
  thumbnail_ends_with?: Maybe<Scalars['String']>;
  thumbnail_not_ends_with?: Maybe<Scalars['String']>;
  muxPlaybackId?: Maybe<Scalars['String']>;
  muxPlaybackId_not?: Maybe<Scalars['String']>;
  muxPlaybackId_in?: Maybe<Array<Scalars['String']>>;
  muxPlaybackId_not_in?: Maybe<Array<Scalars['String']>>;
  muxPlaybackId_lt?: Maybe<Scalars['String']>;
  muxPlaybackId_lte?: Maybe<Scalars['String']>;
  muxPlaybackId_gt?: Maybe<Scalars['String']>;
  muxPlaybackId_gte?: Maybe<Scalars['String']>;
  muxPlaybackId_contains?: Maybe<Scalars['String']>;
  muxPlaybackId_not_contains?: Maybe<Scalars['String']>;
  muxPlaybackId_starts_with?: Maybe<Scalars['String']>;
  muxPlaybackId_not_starts_with?: Maybe<Scalars['String']>;
  muxPlaybackId_ends_with?: Maybe<Scalars['String']>;
  muxPlaybackId_not_ends_with?: Maybe<Scalars['String']>;
  playbackPolicy?: Maybe<Scalars['String']>;
  playbackPolicy_not?: Maybe<Scalars['String']>;
  playbackPolicy_in?: Maybe<Array<Scalars['String']>>;
  playbackPolicy_not_in?: Maybe<Array<Scalars['String']>>;
  playbackPolicy_lt?: Maybe<Scalars['String']>;
  playbackPolicy_lte?: Maybe<Scalars['String']>;
  playbackPolicy_gt?: Maybe<Scalars['String']>;
  playbackPolicy_gte?: Maybe<Scalars['String']>;
  playbackPolicy_contains?: Maybe<Scalars['String']>;
  playbackPolicy_not_contains?: Maybe<Scalars['String']>;
  playbackPolicy_starts_with?: Maybe<Scalars['String']>;
  playbackPolicy_not_starts_with?: Maybe<Scalars['String']>;
  playbackPolicy_ends_with?: Maybe<Scalars['String']>;
  playbackPolicy_not_ends_with?: Maybe<Scalars['String']>;
  videoURL?: Maybe<Scalars['String']>;
  videoURL_not?: Maybe<Scalars['String']>;
  videoURL_in?: Maybe<Array<Scalars['String']>>;
  videoURL_not_in?: Maybe<Array<Scalars['String']>>;
  videoURL_lt?: Maybe<Scalars['String']>;
  videoURL_lte?: Maybe<Scalars['String']>;
  videoURL_gt?: Maybe<Scalars['String']>;
  videoURL_gte?: Maybe<Scalars['String']>;
  videoURL_contains?: Maybe<Scalars['String']>;
  videoURL_not_contains?: Maybe<Scalars['String']>;
  videoURL_starts_with?: Maybe<Scalars['String']>;
  videoURL_not_starts_with?: Maybe<Scalars['String']>;
  videoURL_ends_with?: Maybe<Scalars['String']>;
  videoURL_not_ends_with?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
  duration_not?: Maybe<Scalars['Float']>;
  duration_in?: Maybe<Array<Scalars['Float']>>;
  duration_not_in?: Maybe<Array<Scalars['Float']>>;
  duration_lt?: Maybe<Scalars['Float']>;
  duration_lte?: Maybe<Scalars['Float']>;
  duration_gt?: Maybe<Scalars['Float']>;
  duration_gte?: Maybe<Scalars['Float']>;
  createdBy?: Maybe<UserWhereInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<VideoWhereInput>>;
  OR?: Maybe<Array<VideoWhereInput>>;
  NOT?: Maybe<Array<VideoWhereInput>>;
};

export type VideoWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
  slug?: Maybe<Scalars['String']>;
};

export type CreateModularPageInput = {
  title: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  createdBy?: Maybe<UserCreateOneInput>;
  continueWatchingModules?: Maybe<Array<Maybe<ContinueWatchingModuleCustomInput>>>;
  continueListeningModules?: Maybe<Array<Maybe<ContinueListeningModuleCustomInput>>>;
  freeformHighlightModules?: Maybe<Array<Maybe<FreeformHighlightModuleCustomInput>>>;
  freeformHeadlinerModules?: Maybe<Array<Maybe<FreeformHeadlinerModuleCustomInput>>>;
  episodeCarouselModules?: Maybe<Array<Maybe<EpisodeCarouselModuleCustomInput>>>;
  podcastEpisodeCarouselModules?: Maybe<Array<Maybe<PodcastEpisodeCarouselModuleCustomInput>>>;
  showCarouselModules?: Maybe<Array<Maybe<ShowCarouselModuleCustomInput>>>;
  podcastCarouselModules?: Maybe<Array<Maybe<PodcastCarouselModuleCustomInput>>>;
  clipCarouselModules?: Maybe<Array<Maybe<ClipCarouselModuleCustomInput>>>;
  videoCarouselModules?: Maybe<Array<Maybe<VideoCarouselModuleCustomInput>>>;
  freeformCarouselModules?: Maybe<Array<Maybe<FreeformCarouselModuleCustomInput>>>;
  premiereModules?: Maybe<Array<Maybe<PremiereModuleCustomInput>>>;
};

export type CreateModularPageRes = {
  __typename?: 'createModularPageRes';
  message?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};

export type DeleteModuleWhereInput = {
  type: ModuleType;
  id: Scalars['ID'];
};

export type DeletePremiereModuleCustomInput = {
  type: PremiereModuleType;
  id: Scalars['ID'];
};

export type DeleteRes = {
  __typename?: 'deleteRes';
  message?: Maybe<Scalars['String']>;
};

export type DeleteWhereInput = {
  id: Scalars['ID'];
};

export type GetPodcastEpisodeRes = {
  __typename?: 'getPodcastEpisodeRes';
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  episodeNumber?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Int']>;
  thumbnail?: Maybe<Scalars['String']>;
  podcast?: Maybe<GetPodcastRes>;
  season?: Maybe<SeasonDetails>;
  status: PodcastEpisodeStatus;
  rating?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
  audio?: Maybe<Scalars['String']>;
  audioState?: Maybe<Scalars['String']>;
  audioMuxPlaybackId?: Maybe<Scalars['String']>;
  durationWithAds?: Maybe<Scalars['Float']>;
  audioWithAdsMuxPlaybackId?: Maybe<Scalars['String']>;
  audioAccess?: Maybe<Array<Scalars['String']>>;
  allowedContinents?: Maybe<Array<Scalars['String']>>;
  allowedCountries?: Maybe<Array<Maybe<Scalars['String']>>>;
  scheduleAt?: Maybe<Scalars['DateTime']>;
  publishDate?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  listenTime?: Maybe<Scalars['Float']>;
  allowedCountryNames?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** FOR CMS */
  audioMuxAssetId?: Maybe<Scalars['String']>;
  audioWithAds?: Maybe<Scalars['String']>;
  audioWithAdsState?: Maybe<Scalars['String']>;
  audioWithAdsMuxAssetId?: Maybe<Scalars['String']>;
  audioWithAdsAccess: Array<Scalars['String']>;
  createdBy: UserDetails;
};

export type GetPodcastRes = {
  __typename?: 'getPodcastRes';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<PodcastStatus>;
  weight?: Maybe<Scalars['Int']>;
  belongsTo?: Maybe<Scalars['String']>;
  thirdPartyShowId?: Maybe<Scalars['String']>;
  coverImage?: Maybe<Scalars['String']>;
  logoImage?: Maybe<Scalars['String']>;
  backgroundImage?: Maybe<Scalars['String']>;
  seasons?: Maybe<Array<Maybe<SeasonDetails>>>;
  author?: Maybe<UserDetails>;
  createdBy?: Maybe<UserDetails>;
  message?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GetSeasonEpisodesInput = {
  season: SeasonWhereUniqueInput;
};

export type GetSegmentSimulateLiveVideoPutUrlInput = {
  fileName: Scalars['String'];
};

export type GetSignedUrlRes = {
  __typename?: 'getSignedUrlRes';
  signedUrl?: Maybe<Scalars['String']>;
};

export type ListEpisodeForSeasonRes = {
  __typename?: 'listEpisodeForSeasonRes';
  show?: Maybe<Show>;
  episodes?: Maybe<Array<Maybe<Episode>>>;
  count?: Maybe<Scalars['Int']>;
  seasonEpisodes?: Maybe<Array<Maybe<SeasonEpisode>>>;
};

export type ListEpisodeForSeasonWhereInput = {
  seasonId: Scalars['ID'];
  status_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  search?: Maybe<Scalars['String']>;
};

export type ListPodcastEpisodeForSeasonRes = {
  __typename?: 'listPodcastEpisodeForSeasonRes';
  podcast?: Maybe<GetPodcastRes>;
  count?: Maybe<Scalars['Int']>;
  podcastEpisodes?: Maybe<Array<Maybe<GetPodcastEpisodeRes>>>;
  seasonEpisodes?: Maybe<Array<Maybe<GetPodcastEpisodeRes>>>;
};

export type ListPodcastEpisodeForSeasonWhereInput = {
  seasonId: Scalars['ID'];
  status_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  search?: Maybe<Scalars['String']>;
};

export type ListPodcastForSeasonRes = {
  __typename?: 'listPodcastForSeasonRes';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type ListShowForClipRes = {
  __typename?: 'listShowForClipRes';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type ListShowForSeasonRes = {
  __typename?: 'listShowForSeasonRes';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type ListVideoForClipRes = {
  __typename?: 'listVideoForClipRes';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type ModuleUniqueInputWhere = {
  id: Scalars['ID'];
};

export type SeasonDetails = {
  __typename?: 'seasonDetails';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  status?: Maybe<SeasonStatus>;
};

export type UpdateCarouselClipData = {
  order?: Maybe<Scalars['Int']>;
};

export type UpdateCarouselClipInput = {
  data: UpdateCarouselClipData;
  where: UpdateCarouselClipWhere;
};

export type UpdateCarouselClipWhere = {
  id?: Maybe<Scalars['ID']>;
};

export type UpdateCarouselEpisodeData = {
  order?: Maybe<Scalars['Int']>;
};

export type UpdateCarouselEpisodeInput = {
  data: UpdateCarouselEpisodeData;
  where: UpdateCarouselEpisodeWhere;
};

export type UpdateCarouselEpisodeWhere = {
  id?: Maybe<Scalars['ID']>;
};

export type UpdateCarouselPodcastData = {
  order?: Maybe<Scalars['Int']>;
};

export type UpdateCarouselPodcastEpisodeData = {
  order?: Maybe<Scalars['Int']>;
};

export type UpdateCarouselPodcastEpisodeInput = {
  data: UpdateCarouselPodcastEpisodeData;
  where: UpdateCarouselPodcastEpisodeWhere;
};

export type UpdateCarouselPodcastEpisodeWhere = {
  id?: Maybe<Scalars['ID']>;
};

export type UpdateCarouselPodcastInput = {
  data: UpdateCarouselPodcastData;
  where: UpdateCarouselPodcastWhere;
};

export type UpdateCarouselPodcastWhere = {
  id?: Maybe<Scalars['ID']>;
};

export type UpdateCarouselShowData = {
  order?: Maybe<Scalars['Int']>;
};

export type UpdateCarouselShowInput = {
  data: UpdateCarouselShowData;
  where: UpdateCarouselShowWhere;
};

export type UpdateCarouselShowWhere = {
  id?: Maybe<Scalars['ID']>;
};

export type UpdateCarouselVideoData = {
  order?: Maybe<Scalars['Int']>;
};

export type UpdateCarouselVideoInput = {
  data: UpdateCarouselVideoData;
  where: UpdateCarouselVideoWhere;
};

export type UpdateCarouselVideoWhere = {
  id?: Maybe<Scalars['ID']>;
};

export type UpdateClipCarouselModuleCustom = {
  where: ModuleUniqueInputWhere;
  data: UpdateClipCarouselModuleCustomData;
};

export type UpdateClipCarouselModuleCustomData = {
  title?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  carouselClips?: Maybe<UpsertCarouselClipInput>;
};

export type UpdateContinueListeningModuleCustom = {
  where: ModuleUniqueInputWhere;
  data: UpdateContinueListeningModuleCustomData;
};

export type UpdateContinueListeningModuleCustomData = {
  title?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  isPublished?: Maybe<Scalars['Boolean']>;
};

export type UpdateContinueWatchingModuleCustom = {
  where: ModuleUniqueInputWhere;
  data: UpdateContinueWatchingModuleCustomData;
};

export type UpdateContinueWatchingModuleCustomData = {
  title?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  isPublished?: Maybe<Scalars['Boolean']>;
};

export type UpdateEpisodeCarouselModuleCustom = {
  where: ModuleUniqueInputWhere;
  data: UpdateEpisodeCarouselModuleCustomData;
};

export type UpdateEpisodeCarouselModuleCustomData = {
  title?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  carouselEpisodes?: Maybe<UpsertCarouselEpisodeInput>;
};

export type UpdateFreeformHeadlinerModuleCustom = {
  where: ModuleUniqueInputWhere;
  data: UpdateFreeformHeadlinerModuleCustomData;
};

export type UpdateFreeformHeadlinerModuleCustomData = {
  title?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  CTAText?: Maybe<Scalars['String']>;
  routing?: Maybe<FreeformHighlightModuleRouting>;
  link?: Maybe<Scalars['String']>;
  referenceId?: Maybe<Scalars['String']>;
  referenceType?: Maybe<FreeformReferenceType>;
};

export type UpdateFreeformHighlightModuleCustom = {
  where: ModuleUniqueInputWhere;
  data: UpdateFreeformHighlightModuleCustomData;
};

export type UpdateFreeformHighlightModuleCustomData = {
  title?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  CTAText?: Maybe<Scalars['String']>;
  routing?: Maybe<FreeformHighlightModuleRouting>;
  link?: Maybe<Scalars['String']>;
  referenceId?: Maybe<Scalars['String']>;
  referenceType?: Maybe<FreeformReferenceType>;
};

export type UpdateModularPageInput = {
  title?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  modules?: Maybe<UpdateModuleInput>;
};

export type UpdateModularPageWhereInput = {
  id: Scalars['ID'];
};

export type UpdateModuleInput = {
  continueWatchingModules?: Maybe<UpsertContinueWatchingModuleCustomInput>;
  continueListeningModules?: Maybe<UpsertContinueListeningModuleCustomInput>;
  freeformHighlightModules?: Maybe<UpsertFreeformHighlightModuleCustomInput>;
  freeformHeadlinerModules?: Maybe<UpsertFreeformHeadlinerModuleCustomInput>;
  episodeCarouselModules?: Maybe<UpsertEpisodeCarouselModuleCustomInput>;
  freeformCarouselModules?: Maybe<UpsertFreeformCarouselModuleCustomInput>;
  podcastEpisodeCarouselModules?: Maybe<UpsertPodcastEpisodeCarouselModuleCustomInput>;
  showCarouselModules?: Maybe<UpsertShowCarouselModuleCustomInput>;
  podcastCarouselModules?: Maybe<UpsertPodcastCarouselModuleCustomInput>;
  clipCarouselModules?: Maybe<UpsertClipCarouselModuleCustomInput>;
  videoCarouselModules?: Maybe<UpsertVideoCarouselModuleCustomInput>;
  premiereModules?: Maybe<UpsertPremiereModuleCustomInput>;
};

export type UpdatePodcastCarouselModuleCustom = {
  where: ModuleUniqueInputWhere;
  data: UpdatePodcastCarouselModuleCustomData;
};

export type UpdatePodcastCarouselModuleCustomData = {
  title?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  orientation?: Maybe<ShowCarouselImageOrientation>;
  isPublished?: Maybe<Scalars['Boolean']>;
  carouselPodcasts?: Maybe<UpsertCarouselPodcastInput>;
};

export type UpdatePodcastEpisodeCarouselModuleCustom = {
  where: ModuleUniqueInputWhere;
  data: UpdatePodcastEpisodeCarouselModuleCustomData;
};

export type UpdatePodcastEpisodeCarouselModuleCustomData = {
  title?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  carouselPodcastEpisodes?: Maybe<UpsertCarouselPodcastEpisodeInput>;
};

export type UpdatePremiereModuleCustom = {
  where: ModuleUniqueInputWhere;
  data: UpdatePremiereModuleCustomData;
};

export type UpdatePremiereModuleCustomData = {
  order?: Maybe<Scalars['Int']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  referenceId?: Maybe<Scalars['String']>;
  type: PremiereModuleType;
  premiereImage?: Maybe<Scalars['String']>;
};

export type UpdateShowCarouselModuleCustom = {
  where: ModuleUniqueInputWhere;
  data: UpdateShowCarouselModuleCustomData;
};

export type UpdateShowCarouselModuleCustomData = {
  title?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  orientation?: Maybe<ShowCarouselImageOrientation>;
  isPublished?: Maybe<Scalars['Boolean']>;
  carouselShows?: Maybe<UpsertCarouselShowInput>;
};

export type UpdateVideoCarouselModuleCustom = {
  where: ModuleUniqueInputWhere;
  data: UpdateVideoCarouselModuleCustomData;
};

export type UpdateVideoCarouselModuleCustomData = {
  title?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  carouselVideos?: Maybe<UpsertCarouselVideoInput>;
};

export type UpsertCarouselClipInput = {
  create?: Maybe<Array<Maybe<ClipCarouselCustomInput>>>;
  update?: Maybe<Array<Maybe<UpdateCarouselClipInput>>>;
};

export type UpsertCarouselEpisodeInput = {
  create?: Maybe<Array<Maybe<EpisodeCarouselCustomInput>>>;
  update?: Maybe<Array<Maybe<UpdateCarouselEpisodeInput>>>;
};

export type UpsertCarouselPodcastEpisodeInput = {
  create?: Maybe<Array<Maybe<PodcastEpisodeCarouselCustomInput>>>;
  update?: Maybe<Array<Maybe<UpdateCarouselPodcastEpisodeInput>>>;
};

export type UpsertCarouselPodcastInput = {
  create?: Maybe<Array<Maybe<PodcastCarouselCustomInput>>>;
  update?: Maybe<Array<Maybe<UpdateCarouselPodcastInput>>>;
};

export type UpsertCarouselShowInput = {
  create?: Maybe<Array<Maybe<ShowCarouselCustomInput>>>;
  update?: Maybe<Array<Maybe<UpdateCarouselShowInput>>>;
};

export type UpsertCarouselVideoInput = {
  create?: Maybe<Array<Maybe<VideoCarouselCustomInput>>>;
  update?: Maybe<Array<Maybe<UpdateCarouselVideoInput>>>;
};

export type UpsertClipCarouselModuleCustomInput = {
  update?: Maybe<Array<Maybe<UpdateClipCarouselModuleCustom>>>;
  create?: Maybe<Array<Maybe<ClipCarouselModuleCustomInput>>>;
  delete?: Maybe<Array<Scalars['ID']>>;
};

export type UpsertContinueListeningModuleCustomInput = {
  update?: Maybe<Array<Maybe<UpdateContinueListeningModuleCustom>>>;
  create?: Maybe<Array<Maybe<ContinueListeningModuleCustomInput>>>;
  delete?: Maybe<Array<Scalars['ID']>>;
};

export type UpsertContinueWatchingModuleCustomInput = {
  update?: Maybe<Array<Maybe<UpdateContinueWatchingModuleCustom>>>;
  create?: Maybe<Array<Maybe<ContinueWatchingModuleCustomInput>>>;
  delete?: Maybe<Array<Scalars['ID']>>;
};

export type UpsertEpisodeCarouselModuleCustomInput = {
  update?: Maybe<Array<Maybe<UpdateEpisodeCarouselModuleCustom>>>;
  create?: Maybe<Array<Maybe<EpisodeCarouselModuleCustomInput>>>;
  delete?: Maybe<Array<Scalars['ID']>>;
};

export type UpsertFreeformHeadlinerModuleCustomInput = {
  update?: Maybe<Array<Maybe<UpdateFreeformHeadlinerModuleCustom>>>;
  create?: Maybe<Array<Maybe<FreeformHeadlinerModuleCustomInput>>>;
};

export type UpsertFreeformHighlightModuleCustomInput = {
  update?: Maybe<Array<Maybe<UpdateFreeformHighlightModuleCustom>>>;
  create?: Maybe<Array<Maybe<FreeformHighlightModuleCustomInput>>>;
};

export type UpsertPodcastCarouselModuleCustomInput = {
  update?: Maybe<Array<Maybe<UpdatePodcastCarouselModuleCustom>>>;
  create?: Maybe<Array<Maybe<PodcastCarouselModuleCustomInput>>>;
  delete?: Maybe<Array<Scalars['ID']>>;
};

export type UpsertPodcastEpisodeCarouselModuleCustomInput = {
  update?: Maybe<Array<Maybe<UpdatePodcastEpisodeCarouselModuleCustom>>>;
  create?: Maybe<Array<Maybe<PodcastEpisodeCarouselModuleCustomInput>>>;
  delete?: Maybe<Array<Scalars['ID']>>;
};

export type UpsertPremiereModuleCustomInput = {
  update?: Maybe<Array<Maybe<UpdatePremiereModuleCustom>>>;
  create?: Maybe<Array<Maybe<PremiereModuleCustomInput>>>;
  delete?: Maybe<Array<Maybe<DeletePremiereModuleCustomInput>>>;
};

export type UpsertShowCarouselModuleCustomInput = {
  update?: Maybe<Array<Maybe<UpdateShowCarouselModuleCustom>>>;
  create?: Maybe<Array<Maybe<ShowCarouselModuleCustomInput>>>;
  delete?: Maybe<Array<Scalars['ID']>>;
};

export type UpsertVideoCarouselModuleCustomInput = {
  update?: Maybe<Array<Maybe<UpdateVideoCarouselModuleCustom>>>;
  create?: Maybe<Array<Maybe<VideoCarouselModuleCustomInput>>>;
  delete?: Maybe<Array<Scalars['ID']>>;
};

export type UserDetails = {
  __typename?: 'userDetails';
  id?: Maybe<Scalars['ID']>;
  profileImage?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
};

export type GetClipSlugsQueryVariables = Exact<{
  first: Scalars['Int'];
}>;


export type GetClipSlugsQuery = (
  { __typename?: 'Query' }
  & { clips: Array<Maybe<(
    { __typename?: 'Clip' }
    & Pick<Clip, 'id' | 'slug'>
  )>> }
);

export type GetClipBySlugQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type GetClipBySlugQuery = (
  { __typename?: 'Query' }
  & { clip?: Maybe<(
    { __typename?: 'Clip' }
    & Pick<Clip, 'id' | 'name' | 'slug' | 'description' | 'image' | 'thumbnail' | 'duration' | 'clipAccess' | 'createdAt' | 'updatedAt' | 'videoURL'>
    & { show?: Maybe<(
      { __typename?: 'Show' }
      & Pick<Show, 'id' | 'name' | 'slug'>
    )>, video?: Maybe<(
      { __typename?: 'Video' }
      & Pick<Video, 'id' | 'status' | 'name' | 'slug'>
    )>, createdBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName'>
    )>, captions?: Maybe<Array<(
      { __typename?: 'Caption' }
      & Pick<Caption, 'id'>
    )>> }
  )> }
);

export type GetSeasonsQueryVariables = Exact<{
  where?: Maybe<SeasonWhereInput>;
}>;


export type GetSeasonsQuery = (
  { __typename?: 'Query' }
  & { seasons: Array<Maybe<(
    { __typename?: 'Season' }
    & Pick<Season, 'id' | 'name' | 'slug' | 'description' | 'image' | 'status'>
  )>> }
);

export type GetSeasonEpisodesQueryVariables = Exact<{
  where: GetSeasonEpisodesInput;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
}>;


export type GetSeasonEpisodesQuery = (
  { __typename?: 'Query' }
  & { getSeasonEpisodes?: Maybe<Array<Maybe<(
    { __typename?: 'SeasonEpisode' }
    & Pick<SeasonEpisode, 'id'>
    & { episode: (
      { __typename?: 'Episode' }
      & Pick<Episode, 'id' | 'title' | 'slug' | 'image' | 'description' | 'updatedAt' | 'scheduleAt' | 'createdAt' | 'discussionId' | 'isLive' | 'status'>
    ) }
  )>>> }
);

export type GetEpisodeSlugsQueryVariables = Exact<{
  first: Scalars['Int'];
}>;


export type GetEpisodeSlugsQuery = (
  { __typename?: 'Query' }
  & { episodes: Array<Maybe<(
    { __typename?: 'Episode' }
    & Pick<Episode, 'id' | 'slug'>
  )>> }
);

export type GetEpisodesQueryVariables = Exact<{
  first: Scalars['Int'];
}>;


export type GetEpisodesQuery = (
  { __typename?: 'Query' }
  & { episodes: Array<Maybe<(
    { __typename?: 'Episode' }
    & Pick<Episode, 'id' | 'image' | 'title' | 'slug' | 'isLive' | 'status' | 'scheduleAt' | 'createdAt' | 'description'>
    & { show: (
      { __typename?: 'Show' }
      & Pick<Show, 'id' | 'name' | 'slug' | 'belongsTo'>
    ), segments?: Maybe<Array<(
      { __typename?: 'EpisodeSegment' }
      & Pick<EpisodeSegment, 'id' | 'image' | 'title' | 'liveChatAccess' | 'audio' | 'video' | 'duration' | 'watchTime' | 'listenTime' | 'description' | 'videoAccess' | 'audioAccess' | 'muxAssetId' | 'muxPlaybackId'>
    )>> }
  )>> }
);

export type GetEpisodesByShowQueryVariables = Exact<{
  first: Scalars['Int'];
  showSlug?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  episodeSlugNot?: Maybe<Scalars['String']>;
}>;


export type GetEpisodesByShowQuery = (
  { __typename?: 'Query' }
  & { episodes: Array<Maybe<(
    { __typename?: 'Episode' }
    & Pick<Episode, 'id' | 'image' | 'title' | 'slug' | 'status' | 'scheduleAt' | 'createdAt' | 'description'>
    & { show: (
      { __typename?: 'Show' }
      & Pick<Show, 'id' | 'name' | 'slug' | 'belongsTo'>
    ), segments?: Maybe<Array<(
      { __typename?: 'EpisodeSegment' }
      & Pick<EpisodeSegment, 'id' | 'image' | 'title' | 'liveChatAccess' | 'audio' | 'video' | 'duration' | 'watchTime' | 'description' | 'videoAccess' | 'muxAssetId' | 'muxPlaybackId'>
    )>> }
  )>> }
);

export type GetFeaturedEpisodesQueryVariables = Exact<{
  first: Scalars['Int'];
}>;


export type GetFeaturedEpisodesQuery = (
  { __typename?: 'Query' }
  & { episodes: Array<Maybe<(
    { __typename?: 'Episode' }
    & Pick<Episode, 'id' | 'title' | 'slug' | 'featuredBannerImage'>
  )>> }
);

export type GetEpisodeBySlugQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type GetEpisodeBySlugQuery = (
  { __typename?: 'Query' }
  & { episode?: Maybe<(
    { __typename?: 'Episode' }
    & Pick<Episode, 'id' | 'title' | 'status' | 'slug' | 'isLive' | 'description' | 'createdAt' | 'scheduleAt' | 'updatedAt' | 'image' | 'allowedCountryNames' | 'allowedContinents' | 'rating' | 'discussionId'>
    & { show: (
      { __typename?: 'Show' }
      & Pick<Show, 'id' | 'name' | 'slug' | 'belongsTo'>
    ), segments?: Maybe<Array<(
      { __typename?: 'EpisodeSegment' }
      & Pick<EpisodeSegment, 'id' | 'image' | 'title' | 'liveChatAccess' | 'audio' | 'video' | 'duration' | 'watchTime' | 'description' | 'videoAccess' | 'muxAssetId' | 'muxPlaybackId'>
      & { captions?: Maybe<Array<(
        { __typename?: 'Caption' }
        & Pick<Caption, 'id'>
      )>> }
    )>>, createdBy: (
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName'>
    ) }
  )> }
);

export type GetEpisodeSegmentsQueryVariables = Exact<{
  episodeId: Scalars['ID'];
}>;


export type GetEpisodeSegmentsQuery = (
  { __typename?: 'Query' }
  & { episodeSegments: Array<Maybe<(
    { __typename?: 'EpisodeSegment' }
    & Pick<EpisodeSegment, 'id' | 'title' | 'muxAssetId' | 'muxPlaybackId' | 'video' | 'videoState' | 'videoAccess' | 'audio' | 'audioState' | 'audioAccess'>
  )>> }
);

export type GetLiveEpisodesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLiveEpisodesQuery = (
  { __typename?: 'Query' }
  & { getLiveEpisodes: Array<Maybe<(
    { __typename?: 'Episode' }
    & Pick<Episode, 'id' | 'title' | 'slug' | 'image' | 'updatedAt' | 'scheduleAt' | 'createdAt' | 'isLive' | 'status'>
    & { show: (
      { __typename?: 'Show' }
      & Pick<Show, 'id' | 'name' | 'slug' | 'belongsTo'>
    ) }
  )>> }
);

export type GetModularPageSlugsQueryVariables = Exact<{
  first: Scalars['Int'];
}>;


export type GetModularPageSlugsQuery = (
  { __typename?: 'Query' }
  & { modulePages: Array<Maybe<(
    { __typename?: 'ModulePage' }
    & Pick<ModulePage, 'id' | 'slug'>
  )>> }
);

export type GetModularPageQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type GetModularPageQuery = (
  { __typename?: 'Query' }
  & { getModularPage?: Maybe<(
    { __typename?: 'ModularPageRes' }
    & Pick<ModularPageRes, 'id' | 'slug' | 'title'>
    & { modules?: Maybe<Array<Maybe<(
      { __typename: 'EpisodeCarousel' }
      & Pick<EpisodeCarousel, 'id' | 'title'>
      & { episodes?: Maybe<Array<Maybe<(
        { __typename?: 'Episode' }
        & Pick<Episode, 'id' | 'title' | 'slug' | 'image' | 'status' | 'description' | 'scheduleAt' | 'createdAt'>
        & { show: (
          { __typename?: 'Show' }
          & Pick<Show, 'id' | 'name' | 'slug'>
        ) }
      )>>> }
    ) | (
      { __typename: 'PodcastEpisodeCarousel' }
      & Pick<PodcastEpisodeCarousel, 'id' | 'title'>
      & { podcastEpisodes?: Maybe<Array<Maybe<(
        { __typename?: 'PodcastEpisode' }
        & PodcastEpisodeFragment
      )>>> }
    ) | (
      { __typename: 'ShowCarousel' }
      & Pick<ShowCarousel, 'id' | 'title' | 'orientation'>
      & { shows?: Maybe<Array<Maybe<(
        { __typename?: 'Show' }
        & Pick<Show, 'id' | 'name' | 'slug' | 'image' | 'portraitImage'>
      )>>> }
    ) | (
      { __typename: 'PodcastCarousel' }
      & Pick<PodcastCarousel, 'id' | 'title'>
      & { podcasts?: Maybe<Array<Maybe<(
        { __typename?: 'Podcast' }
        & PodcastFragment
      )>>> }
    ) | (
      { __typename: 'VideoCarousel' }
      & Pick<VideoCarousel, 'id' | 'title'>
      & { videos?: Maybe<Array<Maybe<(
        { __typename?: 'Video' }
        & Pick<Video, 'id' | 'slug' | 'name' | 'image'>
      )>>> }
    ) | (
      { __typename: 'ClipCarousel' }
      & Pick<ClipCarousel, 'id' | 'title'>
      & { clips?: Maybe<Array<Maybe<(
        { __typename?: 'Clip' }
        & Pick<Clip, 'id' | 'name' | 'slug' | 'image'>
      )>>> }
    ) | { __typename: 'FreeformCarousel' } | (
      { __typename: 'EpisodePremiere' }
      & Pick<EpisodePremiere, 'id' | 'premiereImage'>
      & { episode?: Maybe<(
        { __typename?: 'Episode' }
        & Pick<Episode, 'id' | 'title' | 'slug' | 'image' | 'description' | 'status' | 'scheduleAt' | 'createdAt'>
        & { show: (
          { __typename?: 'Show' }
          & Pick<Show, 'id' | 'name' | 'slug' | 'logoImage' | 'belongsTo'>
        ) }
      )> }
    ) | (
      { __typename: 'PodcastEpisodePremiere' }
      & Pick<PodcastEpisodePremiere, 'id' | 'premiereImage'>
      & { podcastEpisode?: Maybe<(
        { __typename?: 'PodcastEpisode' }
        & PodcastEpisodeFragment
      )> }
    ) | (
      { __typename: 'ShowPremiere' }
      & Pick<ShowPremiere, 'id' | 'premiereImage'>
      & { show?: Maybe<(
        { __typename?: 'Show' }
        & Pick<Show, 'id' | 'name' | 'slug' | 'backgroundImage' | 'description' | 'image' | 'logoImage' | 'belongsTo'>
      )> }
    ) | (
      { __typename: 'PodcastPremiere' }
      & Pick<PodcastPremiere, 'id' | 'premiereImage'>
      & { podcast?: Maybe<(
        { __typename?: 'Podcast' }
        & PodcastFragment
      )> }
    ) | (
      { __typename: 'VideoPremiere' }
      & Pick<VideoPremiere, 'id' | 'premiereImage'>
      & { video?: Maybe<(
        { __typename?: 'Video' }
        & Pick<Video, 'id' | 'name' | 'slug' | 'description' | 'image' | 'logoImage'>
      )> }
    ) | (
      { __typename: 'Headliner' }
      & Pick<Headliner, 'id' | 'title' | 'description' | 'referenceId' | 'referenceType' | 'referenceSlug' | 'routing' | 'CTAText' | 'link'>
    ) | (
      { __typename: 'Highlight' }
      & Pick<Highlight, 'id' | 'image' | 'title' | 'description' | 'referenceId' | 'referenceType' | 'referenceSlug' | 'CTAText' | 'link'>
    ) | (
      { __typename: 'ContinueWatching' }
      & Pick<ContinueWatching, 'id' | 'title'>
    ) | (
      { __typename: 'ContinueListening' }
      & Pick<ContinueListening, 'id' | 'title'>
    )>>> }
  )> }
);

export type GetContinueWatchingModuleCustomQueryVariables = Exact<{
  moduleId: Scalars['ID'];
}>;


export type GetContinueWatchingModuleCustomQuery = (
  { __typename?: 'Query' }
  & { getContinueWatchingModule?: Maybe<(
    { __typename?: 'ContinueWatching' }
    & Pick<ContinueWatching, 'id' | 'order' | 'title'>
    & { items?: Maybe<Array<Maybe<(
      { __typename: 'ContinueWatchingEpisode' }
      & Pick<ContinueWatchingEpisode, 'id' | 'title' | 'slug' | 'image' | 'watchTime' | 'duration'>
    ) | (
      { __typename: 'Video' }
      & Pick<Video, 'id' | 'name' | 'slug' | 'image' | 'watchTime' | 'duration'>
    ) | { __typename: 'Clip' }>>> }
  )> }
);

export type GetContinueListeningModuleQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetContinueListeningModuleQuery = (
  { __typename?: 'Query' }
  & { getContinueListeningModule?: Maybe<(
    { __typename?: 'ContinueListening' }
    & Pick<ContinueListening, 'id'>
    & { items?: Maybe<Array<Maybe<(
      { __typename: 'ContinueListeningPodcastEpisode' }
      & Pick<ContinueListeningPodcastEpisode, 'id' | 'title' | 'description' | 'slug' | 'thumbnail' | 'listenTime' | 'duration'>
      & { publishDate: ContinueListeningPodcastEpisode['createdAt'] }
      & { podcast?: Maybe<(
        { __typename?: 'Podcast' }
        & Pick<Podcast, 'slug' | 'coverImage'>
      )> }
    )>>> }
  )> }
);

export type GetParselyTopPostsAuthorsTagsQueryVariables = Exact<{
  author?: Maybe<Scalars['String']>;
  tag?: Maybe<Scalars['String']>;
  sort?: Maybe<Scalars['String']>;
  limit: Scalars['Int'];
}>;


export type GetParselyTopPostsAuthorsTagsQuery = (
  { __typename?: 'Query' }
  & { posts: Array<Maybe<(
    { __typename?: 'ParselyPost' }
    & Pick<ParselyPost, 'title' | 'url' | 'image' | 'author' | 'date'>
  )>> }
);

export type GetParselyTopTopicsQueryVariables = Exact<{
  limit: Scalars['Int'];
}>;


export type GetParselyTopTopicsQuery = (
  { __typename?: 'Query' }
  & { topics: Array<Maybe<(
    { __typename?: 'ParselyTopic' }
    & Pick<ParselyTopic, 'name' | 'slug'>
  )>> }
);

export type ResPodcastFragment = (
  { __typename?: 'getPodcastRes' }
  & Pick<GetPodcastRes, 'id' | 'name' | 'description' | 'slug' | 'weight' | 'belongsTo' | 'thirdPartyShowId' | 'coverImage' | 'logoImage' | 'backgroundImage'>
  & { seasons?: Maybe<Array<Maybe<(
    { __typename?: 'seasonDetails' }
    & Pick<SeasonDetails, 'id' | 'name'>
  )>>>, author?: Maybe<(
    { __typename?: 'userDetails' }
    & Pick<UserDetails, 'id' | 'profileImage' | 'firstName' | 'lastName'>
  )>, createdBy?: Maybe<(
    { __typename?: 'userDetails' }
    & Pick<UserDetails, 'id' | 'profileImage' | 'firstName' | 'lastName'>
  )> }
);

export type PodcastFragment = (
  { __typename?: 'Podcast' }
  & Pick<Podcast, 'id' | 'name' | 'description' | 'slug' | 'weight' | 'belongsTo' | 'thirdPartyShowId' | 'coverImage' | 'logoImage' | 'backgroundImage'>
  & { seasons?: Maybe<Array<(
    { __typename?: 'Season' }
    & Pick<Season, 'id' | 'name'>
  )>>, author: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'profileImage' | 'firstName' | 'lastName'>
  ), createdBy: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'profileImage' | 'firstName' | 'lastName'>
  ) }
);

export type ResPodcastEpisodeFragment = (
  { __typename?: 'getPodcastEpisodeRes' }
  & Pick<GetPodcastEpisodeRes, 'id' | 'title' | 'description' | 'slug' | 'episodeNumber' | 'thumbnail' | 'weight' | 'rating' | 'duration' | 'audioState' | 'audioMuxPlaybackId' | 'durationWithAds' | 'audioWithAdsMuxPlaybackId' | 'publishDate' | 'createdAt' | 'scheduleAt'>
  & { podcast?: Maybe<(
    { __typename?: 'getPodcastRes' }
    & Pick<GetPodcastRes, 'id' | 'name' | 'slug' | 'description' | 'belongsTo' | 'logoImage' | 'coverImage' | 'backgroundImage'>
    & { author?: Maybe<(
      { __typename?: 'userDetails' }
      & Pick<UserDetails, 'id' | 'firstName' | 'lastName'>
    )> }
  )>, season?: Maybe<(
    { __typename?: 'seasonDetails' }
    & Pick<SeasonDetails, 'id' | 'name' | 'slug'>
  )> }
);

export type PodcastEpisodeFragment = (
  { __typename?: 'PodcastEpisode' }
  & Pick<PodcastEpisode, 'id' | 'title' | 'description' | 'slug' | 'episodeNumber' | 'thumbnail' | 'weight' | 'rating' | 'duration' | 'audioState' | 'audioMuxPlaybackId' | 'durationWithAds' | 'audioWithAdsMuxPlaybackId' | 'publishDate' | 'createdAt' | 'scheduleAt'>
  & { podcast: (
    { __typename?: 'Podcast' }
    & Pick<Podcast, 'id' | 'name' | 'slug' | 'description' | 'belongsTo' | 'logoImage' | 'coverImage' | 'backgroundImage'>
    & { author: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName'>
    ) }
  ), season?: Maybe<(
    { __typename?: 'Season' }
    & Pick<Season, 'id' | 'name' | 'slug'>
  )> }
);

export type GetPodcastsQueryVariables = Exact<{
  where?: Maybe<PodcastWhereInput>;
  orderBy?: Maybe<PodcastOrderBy>;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
}>;


export type GetPodcastsQuery = (
  { __typename?: 'Query' }
  & { listPodcast?: Maybe<Array<Maybe<(
    { __typename?: 'getPodcastRes' }
    & ResPodcastFragment
  )>>> }
);

export type GetPodcastQueryVariables = Exact<{
  where: PodcastWhereUniqueInput;
}>;


export type GetPodcastQuery = (
  { __typename?: 'Query' }
  & { getPodcast?: Maybe<(
    { __typename?: 'getPodcastRes' }
    & ResPodcastFragment
  )> }
);

export type GetPodcastEpisodesQueryVariables = Exact<{
  where?: Maybe<PodcastEpisodeWhereInput>;
  orderBy?: Maybe<PodcastEpisodeOrderBy>;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
}>;


export type GetPodcastEpisodesQuery = (
  { __typename?: 'Query' }
  & { listPodcastEpisode?: Maybe<Array<Maybe<(
    { __typename?: 'getPodcastEpisodeRes' }
    & ResPodcastEpisodeFragment
  )>>> }
);

export type GetPodcastEpisodeQueryVariables = Exact<{
  where: PodcastEpisodeWhereUniqueInput;
}>;


export type GetPodcastEpisodeQuery = (
  { __typename?: 'Query' }
  & { getPodcastEpisode?: Maybe<(
    { __typename?: 'getPodcastEpisodeRes' }
    & ResPodcastEpisodeFragment
  )> }
);

export type GetPodcastEpisodeAudioQueryVariables = Exact<{
  where: PodcastEpisodeWhereUniqueInput;
}>;


export type GetPodcastEpisodeAudioQuery = (
  { __typename?: 'Query' }
  & { getPodcastEpisode?: Maybe<(
    { __typename?: 'getPodcastEpisodeRes' }
    & Pick<GetPodcastEpisodeRes, 'listenTime' | 'audio'>
  )> }
);

export type GetShowsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetShowsQuery = (
  { __typename?: 'Query' }
  & { shows: Array<Maybe<(
    { __typename?: 'Show' }
    & Pick<Show, 'id' | 'name' | 'slug' | 'description' | 'image' | 'logoImage' | 'hostImage' | 'backgroundImage' | 'portraitImage' | 'weight'>
    & { author: (
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName'>
    ), episodes?: Maybe<Array<(
      { __typename?: 'Episode' }
      & Pick<Episode, 'id' | 'image' | 'title' | 'slug'>
      & { show: (
        { __typename?: 'Show' }
        & Pick<Show, 'id' | 'name' | 'slug' | 'belongsTo'>
      ), segments?: Maybe<Array<(
        { __typename?: 'EpisodeSegment' }
        & Pick<EpisodeSegment, 'id' | 'title'>
      )>> }
    )>> }
  )>> }
);

export type GetShowTitlesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetShowTitlesQuery = (
  { __typename?: 'Query' }
  & { shows: Array<Maybe<(
    { __typename?: 'Show' }
    & Pick<Show, 'id' | 'name' | 'slug' | 'weight'>
  )>> }
);

export type GetShowSlugsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetShowSlugsQuery = (
  { __typename?: 'Query' }
  & { shows: Array<Maybe<(
    { __typename?: 'Show' }
    & Pick<Show, 'id' | 'slug'>
  )>> }
);

export type GetPragerUShowsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPragerUShowsQuery = (
  { __typename?: 'Query' }
  & { shows: Array<Maybe<(
    { __typename?: 'Show' }
    & Pick<Show, 'id' | 'name' | 'slug' | 'description' | 'image' | 'logoImage' | 'hostImage' | 'backgroundImage' | 'portraitImage' | 'weight'>
    & { author: (
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName'>
    ), episodes?: Maybe<Array<(
      { __typename?: 'Episode' }
      & Pick<Episode, 'id' | 'image' | 'title' | 'slug'>
      & { show: (
        { __typename?: 'Show' }
        & Pick<Show, 'id' | 'name' | 'slug' | 'belongsTo'>
      ), segments?: Maybe<Array<(
        { __typename?: 'EpisodeSegment' }
        & Pick<EpisodeSegment, 'id' | 'title'>
      )>> }
    )>> }
  )>> }
);

export type GetShowBySlugQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type GetShowBySlugQuery = (
  { __typename?: 'Query' }
  & { show?: Maybe<(
    { __typename?: 'Show' }
    & Pick<Show, 'id' | 'slug' | 'belongsTo' | 'name' | 'description' | 'image' | 'logoImage' | 'backgroundImage' | 'hostImage' | 'createdAt' | 'updatedAt'>
    & { author: (
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName'>
    ), episodes?: Maybe<Array<(
      { __typename?: 'Episode' }
      & Pick<Episode, 'id' | 'image' | 'title' | 'slug' | 'status' | 'createdAt' | 'scheduleAt' | 'description'>
      & { show: (
        { __typename?: 'Show' }
        & Pick<Show, 'id' | 'name' | 'slug' | 'belongsTo'>
      ), segments?: Maybe<Array<(
        { __typename?: 'EpisodeSegment' }
        & Pick<EpisodeSegment, 'id' | 'title' | 'muxAssetId' | 'muxPlaybackId' | 'audio'>
      )>> }
    )>>, seasons?: Maybe<Array<(
      { __typename?: 'Season' }
      & Pick<Season, 'id' | 'name' | 'slug' | 'orderBy'>
    )>> }
  )> }
);

export type VideoTeaserFragmentFragment = (
  { __typename?: 'Video' }
  & Pick<Video, 'id' | 'name' | 'slug' | 'description' | 'image' | 'scheduleAt' | 'thumbnail' | 'duration' | 'createdAt' | 'updatedAt' | 'watchTime'>
);

export type GetVideoSlugsQueryVariables = Exact<{
  first: Scalars['Int'];
}>;


export type GetVideoSlugsQuery = (
  { __typename?: 'Query' }
  & { videos: Array<Maybe<(
    { __typename?: 'Video' }
    & Pick<Video, 'id' | 'slug'>
  )>> }
);

export type GetVideosQueryVariables = Exact<{
  first: Scalars['Int'];
}>;


export type GetVideosQuery = (
  { __typename?: 'Query' }
  & { videos: Array<Maybe<(
    { __typename?: 'Video' }
    & VideoTeaserFragmentFragment
  )>> }
);

export type GetVideoBySlugQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type GetVideoBySlugQuery = (
  { __typename?: 'Query' }
  & { video?: Maybe<(
    { __typename?: 'Video' }
    & Pick<Video, 'id' | 'name' | 'slug' | 'status' | 'description' | 'metadata' | 'image' | 'scheduleAt' | 'allowedContinents' | 'allowedCountryNames' | 'rating' | 'thumbnail' | 'videoURL' | 'logoImage' | 'duration' | 'createdAt' | 'updatedAt' | 'watchTime' | 'liveChatAccess' | 'videoAccess'>
    & { clips?: Maybe<Array<(
      { __typename?: 'Clip' }
      & Pick<Clip, 'id' | 'name' | 'slug' | 'image' | 'thumbnail' | 'duration' | 'clipAccess' | 'createdAt' | 'updatedAt'>
      & { show?: Maybe<(
        { __typename?: 'Show' }
        & Pick<Show, 'id' | 'name' | 'slug'>
      )>, video?: Maybe<(
        { __typename?: 'Video' }
        & Pick<Video, 'id' | 'name' | 'slug'>
      )> }
    )>>, createdBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName'>
    )>, captions?: Maybe<Array<(
      { __typename?: 'Caption' }
      & Pick<Caption, 'id'>
    )>> }
  )> }
);

export const ResPodcastFragmentDoc = gql`
    fragment ResPodcast on getPodcastRes {
  id
  name
  description
  slug
  weight
  belongsTo
  thirdPartyShowId
  coverImage
  logoImage
  backgroundImage
  seasons {
    id
    name
  }
  author {
    id
    profileImage
    firstName
    lastName
  }
  createdBy {
    id
    profileImage
    firstName
    lastName
  }
}
    `;
export const PodcastFragmentDoc = gql`
    fragment Podcast on Podcast {
  id
  name
  description
  slug
  weight
  belongsTo
  thirdPartyShowId
  coverImage
  logoImage
  backgroundImage
  seasons {
    id
    name
  }
  author {
    id
    profileImage
    firstName
    lastName
  }
  createdBy {
    id
    profileImage
    firstName
    lastName
  }
}
    `;
export const ResPodcastEpisodeFragmentDoc = gql`
    fragment ResPodcastEpisode on getPodcastEpisodeRes {
  id
  title
  description
  slug
  episodeNumber
  thumbnail
  weight
  thumbnail
  rating
  duration
  audioState
  audioMuxPlaybackId
  durationWithAds
  audioWithAdsMuxPlaybackId
  publishDate
  createdAt
  scheduleAt
  podcast {
    id
    name
    slug
    description
    belongsTo
    logoImage
    coverImage
    backgroundImage
    author {
      id
      firstName
      lastName
    }
  }
  season {
    id
    name
    slug
  }
}
    `;
export const PodcastEpisodeFragmentDoc = gql`
    fragment PodcastEpisode on PodcastEpisode {
  id
  title
  description
  slug
  episodeNumber
  thumbnail
  weight
  thumbnail
  rating
  duration
  audioState
  audioMuxPlaybackId
  durationWithAds
  audioWithAdsMuxPlaybackId
  publishDate
  createdAt
  scheduleAt
  podcast {
    id
    name
    slug
    description
    belongsTo
    logoImage
    coverImage
    backgroundImage
    author {
      id
      firstName
      lastName
    }
  }
  season {
    id
    name
    slug
  }
}
    `;
export const VideoTeaserFragmentFragmentDoc = gql`
    fragment VideoTeaserFragment on Video {
  id
  name
  slug
  description
  image
  scheduleAt
  thumbnail
  duration
  createdAt
  updatedAt
  watchTime
}
    `;
export const GetClipSlugsDocument = gql`
    query getClipSlugs($first: Int!) {
  clips(where: {status: PUBLISHED}, orderBy: createdAt_DESC, first: $first) {
    id
    slug
  }
}
    `;
export const GetClipBySlugDocument = gql`
    query getClipBySlug($slug: String!) {
  clip(where: {slug: $slug}) {
    id
    name
    slug
    description
    image
    show {
      id
      name
      slug
    }
    video {
      id
      status
      name
      slug
    }
    thumbnail
    duration
    clipAccess
    createdBy {
      firstName
      lastName
    }
    createdAt
    updatedAt
    videoURL
    captions {
      id
    }
  }
}
    `;
export const GetSeasonsDocument = gql`
    query getSeasons($where: SeasonWhereInput) {
  seasons(where: $where, orderBy: weight_ASC) {
    id
    name
    slug
    description
    image
    status
  }
}
    `;
export const GetSeasonEpisodesDocument = gql`
    query getSeasonEpisodes($where: getSeasonEpisodesInput!, $first: Int, $skip: Int) {
  getSeasonEpisodes(where: $where, first: $first, skip: $skip) {
    id
    episode {
      id
      title
      slug
      image
      description
      updatedAt
      scheduleAt
      createdAt
      discussionId
      isLive
      status
    }
  }
}
    `;
export const GetEpisodeSlugsDocument = gql`
    query getEpisodeSlugs($first: Int!) {
  episodes(
    where: {status_not_in: [DRAFT, UNPUBLISHED]}
    orderBy: createdAt_DESC
    first: $first
  ) {
    id
    slug
  }
}
    `;
export const GetEpisodesDocument = gql`
    query getEpisodes($first: Int!) {
  episodes(
    where: {status_not_in: [DRAFT, UNPUBLISHED]}
    orderBy: createdAt_DESC
    first: $first
  ) {
    id
    image
    title
    slug
    isLive
    status
    scheduleAt
    createdAt
    description
    show {
      id
      name
      slug
      belongsTo
    }
    segments {
      id
      image
      title
      liveChatAccess
      audio
      video
      duration
      watchTime
      listenTime
      description
      videoAccess
      audioAccess
      muxAssetId
      muxPlaybackId
    }
  }
}
    `;
export const GetEpisodesByShowDocument = gql`
    query getEpisodesByShow($first: Int!, $showSlug: String, $skip: Int, $episodeSlugNot: String) {
  episodes(
    where: {slug_not: $episodeSlugNot, show: {slug: $showSlug}, status_not_in: [DRAFT, UNPUBLISHED]}
    orderBy: createdAt_DESC
    first: $first
    skip: $skip
  ) {
    id
    image
    title
    slug
    status
    scheduleAt
    createdAt
    description
    show {
      id
      name
      slug
      belongsTo
    }
    segments {
      id
      image
      title
      liveChatAccess
      audio
      video
      duration
      watchTime
      description
      videoAccess
      muxAssetId
      muxPlaybackId
    }
  }
}
    `;
export const GetFeaturedEpisodesDocument = gql`
    query getFeaturedEpisodes($first: Int!) {
  episodes(
    where: {status_not_in: [DRAFT, UNPUBLISHED], isFeatured: true}
    orderBy: featureBannerWeight_DESC
    first: $first
  ) {
    id
    title
    slug
    featuredBannerImage
  }
}
    `;
export const GetEpisodeBySlugDocument = gql`
    query getEpisodeBySlug($slug: String!) {
  episode(where: {slug: $slug}) {
    id
    title
    status
    slug
    isLive
    description
    createdAt
    scheduleAt
    updatedAt
    image
    allowedCountryNames
    allowedContinents
    rating
    show {
      id
      name
      slug
      belongsTo
    }
    segments {
      id
      image
      title
      liveChatAccess
      audio
      video
      duration
      watchTime
      description
      videoAccess
      muxAssetId
      muxPlaybackId
      captions {
        id
      }
    }
    createdBy {
      firstName
      lastName
    }
    discussionId
  }
}
    `;
export const GetEpisodeSegmentsDocument = gql`
    query getEpisodeSegments($episodeId: ID!) {
  episodeSegments(where: {episode: {id: $episodeId}}) {
    id
    title
    muxAssetId
    muxPlaybackId
    video
    videoState
    videoAccess
    audio
    audioState
    audioAccess
  }
}
    `;
export const GetLiveEpisodesDocument = gql`
    query getLiveEpisodes {
  getLiveEpisodes {
    id
    title
    slug
    image
    updatedAt
    scheduleAt
    createdAt
    isLive
    status
    show {
      id
      name
      slug
      belongsTo
    }
  }
}
    `;
export const GetModularPageSlugsDocument = gql`
    query getModularPageSlugs($first: Int!) {
  modulePages(where: {isPublished: true}, first: $first) {
    id
    slug
  }
}
    `;
export const GetModularPageDocument = gql`
    query getModularPage($slug: String!) {
  getModularPage(where: {slug: $slug}) {
    id
    slug
    title
    modules {
      __typename
      ... on PodcastPremiere {
        id
        premiereImage
        podcast {
          ...Podcast
        }
      }
      ... on PodcastCarousel {
        id
        title
        podcasts {
          ...Podcast
        }
      }
      ... on PodcastEpisodeCarousel {
        id
        title
        podcastEpisodes {
          ...PodcastEpisode
        }
      }
      ... on PodcastEpisodePremiere {
        id
        premiereImage
        podcastEpisode {
          ...PodcastEpisode
        }
      }
      ... on ClipCarousel {
        id
        title
        clips {
          id
          name
          slug
          image
        }
      }
      ... on VideoCarousel {
        id
        title
        videos {
          id
          slug
          name
          image
        }
      }
      ... on ShowCarousel {
        id
        title
        shows {
          id
          name
          slug
          image
          portraitImage
        }
        orientation
      }
      ... on EpisodeCarousel {
        id
        title
        episodes {
          id
          title
          slug
          image
          status
          description
          scheduleAt
          createdAt
          show {
            id
            name
            slug
          }
        }
      }
      ... on ShowPremiere {
        id
        show {
          id
          name
          slug
          backgroundImage
          description
          image
          logoImage
          belongsTo
          description
        }
        premiereImage
      }
      ... on EpisodePremiere {
        id
        episode {
          id
          title
          slug
          image
          description
          status
          scheduleAt
          createdAt
          show {
            id
            name
            slug
            logoImage
            belongsTo
          }
        }
        premiereImage
      }
      ... on VideoPremiere {
        id
        video {
          id
          name
          slug
          description
          image
          logoImage
        }
        premiereImage
      }
      ... on Headliner {
        id
        title
        description
        referenceId
        referenceType
        referenceSlug
        routing
        CTAText
        link
      }
      ... on Highlight {
        id
        image
        title
        description
        referenceId
        referenceType
        referenceSlug
        CTAText
        link
      }
      ... on ContinueWatching {
        id
        title
      }
      ... on ContinueListening {
        id
        title
      }
    }
  }
}
    ${PodcastFragmentDoc}
${PodcastEpisodeFragmentDoc}`;
export const GetContinueWatchingModuleCustomDocument = gql`
    query getContinueWatchingModuleCustom($moduleId: ID!) {
  getContinueWatchingModule(where: {id: $moduleId}) {
    id
    order
    title
    items {
      __typename
      ... on Video {
        id
        name
        slug
        image
        watchTime
        duration
      }
      ... on ContinueWatchingEpisode {
        id
        title
        slug
        image
        watchTime
        duration
      }
    }
  }
}
    `;
export const GetContinueListeningModuleDocument = gql`
    query getContinueListeningModule($id: ID!) {
  getContinueListeningModule(where: {id: $id}) {
    id
    items {
      __typename
      ... on ContinueListeningPodcastEpisode {
        id
        title
        description
        publishDate: createdAt
        slug
        thumbnail
        listenTime
        duration
        podcast {
          slug
          coverImage
        }
      }
    }
  }
}
    `;
export const GetParselyTopPostsAuthorsTagsDocument = gql`
    query getParselyTopPostsAuthorsTags($author: String, $tag: String, $sort: String, $limit: Int!) {
  posts: getParselyTopPosts(
    where: {section: "News", tag: $tag, author: $author, sort: $sort, limit: $limit}
  ) {
    title
    url
    image
    author
    date
  }
}
    `;
export const GetParselyTopTopicsDocument = gql`
    query getParselyTopTopics($limit: Int!) {
  topics: getParselyTopTopics(where: {limit: $limit}) {
    name
    slug
  }
}
    `;
export const GetPodcastsDocument = gql`
    query getPodcasts($where: PodcastWhereInput, $orderBy: PodcastOrderBy, $skip: Int, $first: Int) {
  listPodcast(where: $where, orderBy: $orderBy, skip: $skip, first: $first) {
    ...ResPodcast
  }
}
    ${ResPodcastFragmentDoc}`;
export const GetPodcastDocument = gql`
    query getPodcast($where: PodcastWhereUniqueInput!) {
  getPodcast(where: $where) {
    ...ResPodcast
  }
}
    ${ResPodcastFragmentDoc}`;
export const GetPodcastEpisodesDocument = gql`
    query getPodcastEpisodes($where: PodcastEpisodeWhereInput, $orderBy: PodcastEpisodeOrderBy, $skip: Int, $first: Int) {
  listPodcastEpisode(where: $where, orderBy: $orderBy, skip: $skip, first: $first) {
    ...ResPodcastEpisode
  }
}
    ${ResPodcastEpisodeFragmentDoc}`;
export const GetPodcastEpisodeDocument = gql`
    query getPodcastEpisode($where: PodcastEpisodeWhereUniqueInput!) {
  getPodcastEpisode(where: $where) {
    ...ResPodcastEpisode
  }
}
    ${ResPodcastEpisodeFragmentDoc}`;
export const GetPodcastEpisodeAudioDocument = gql`
    query getPodcastEpisodeAudio($where: PodcastEpisodeWhereUniqueInput!) {
  getPodcastEpisode(where: $where) {
    listenTime
    audio
  }
}
    `;
export const GetShowsDocument = gql`
    query getShows {
  shows(orderBy: weight_DESC) {
    id
    name
    slug
    description
    image
    logoImage
    hostImage
    backgroundImage
    portraitImage
    weight
    author {
      firstName
      lastName
    }
    episodes(
      where: {status_not_in: [DRAFT, UNPUBLISHED]}
      first: 1
      orderBy: createdAt_DESC
    ) {
      id
      image
      title
      slug
      show {
        id
        name
        slug
        belongsTo
      }
      segments {
        id
        title
      }
    }
  }
}
    `;
export const GetShowTitlesDocument = gql`
    query getShowTitles {
  shows(orderBy: weight_DESC) {
    id
    name
    slug
    weight
  }
}
    `;
export const GetShowSlugsDocument = gql`
    query getShowSlugs {
  shows(orderBy: weight_DESC) {
    id
    slug
  }
}
    `;
export const GetPragerUShowsDocument = gql`
    query getPragerUShows {
  shows(orderBy: weight_DESC, where: {belongsTo: "prageru"}) {
    id
    name
    slug
    description
    image
    logoImage
    hostImage
    backgroundImage
    portraitImage
    weight
    author {
      firstName
      lastName
    }
    episodes(
      where: {status_not_in: [DRAFT, UNPUBLISHED]}
      first: 1
      orderBy: createdAt_DESC
    ) {
      id
      image
      title
      slug
      show {
        id
        name
        slug
        belongsTo
      }
      segments {
        id
        title
      }
    }
  }
}
    `;
export const GetShowBySlugDocument = gql`
    query getShowBySlug($slug: String!) {
  show(where: {slug: $slug}) {
    id
    slug
    belongsTo
    name
    description
    image
    logoImage
    backgroundImage
    hostImage
    createdAt
    updatedAt
    author {
      firstName
      lastName
    }
    episodes(
      where: {status_not_in: [DRAFT, UNPUBLISHED]}
      first: 1
      orderBy: createdAt_DESC
    ) {
      id
      image
      title
      slug
      status
      createdAt
      scheduleAt
      description
      show {
        id
        name
        slug
        belongsTo
      }
      segments {
        id
        title
        muxAssetId
        muxPlaybackId
        audio
      }
    }
    seasons(orderBy: weight_DESC) {
      id
      name
      slug
      orderBy
    }
  }
}
    `;
export const GetVideoSlugsDocument = gql`
    query getVideoSlugs($first: Int!) {
  videos(
    where: {status_not_in: [DRAFT, UNPUBLISHED]}
    orderBy: createdAt_DESC
    first: $first
  ) {
    id
    slug
  }
}
    `;
export const GetVideosDocument = gql`
    query getVideos($first: Int!) {
  videos(
    where: {status_not_in: [DRAFT, UNPUBLISHED]}
    orderBy: createdAt_DESC
    first: $first
  ) {
    ...VideoTeaserFragment
  }
}
    ${VideoTeaserFragmentFragmentDoc}`;
export const GetVideoBySlugDocument = gql`
    query getVideoBySlug($slug: String!) {
  video(where: {slug: $slug}) {
    id
    name
    slug
    status
    description
    metadata
    image
    clips {
      id
      name
      slug
      image
      show {
        id
        name
        slug
      }
      video {
        id
        name
        slug
      }
      thumbnail
      duration
      clipAccess
      createdAt
      updatedAt
    }
    scheduleAt
    allowedContinents
    allowedCountryNames
    rating
    thumbnail
    videoURL
    logoImage
    duration
    createdBy {
      firstName
      lastName
    }
    createdAt
    updatedAt
    watchTime
    liveChatAccess
    videoAccess
    captions {
      id
    }
  }
}
    `;

export type SdkFunctionWrapper = <T>(action: () => Promise<T>) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = sdkFunction => sdkFunction();
export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    getClipSlugs(variables: GetClipSlugsQueryVariables): Promise<GetClipSlugsQuery> {
      return withWrapper(() => client.request<GetClipSlugsQuery>(print(GetClipSlugsDocument), variables));
    },
    getClipBySlug(variables: GetClipBySlugQueryVariables): Promise<GetClipBySlugQuery> {
      return withWrapper(() => client.request<GetClipBySlugQuery>(print(GetClipBySlugDocument), variables));
    },
    getSeasons(variables?: GetSeasonsQueryVariables): Promise<GetSeasonsQuery> {
      return withWrapper(() => client.request<GetSeasonsQuery>(print(GetSeasonsDocument), variables));
    },
    getSeasonEpisodes(variables: GetSeasonEpisodesQueryVariables): Promise<GetSeasonEpisodesQuery> {
      return withWrapper(() => client.request<GetSeasonEpisodesQuery>(print(GetSeasonEpisodesDocument), variables));
    },
    getEpisodeSlugs(variables: GetEpisodeSlugsQueryVariables): Promise<GetEpisodeSlugsQuery> {
      return withWrapper(() => client.request<GetEpisodeSlugsQuery>(print(GetEpisodeSlugsDocument), variables));
    },
    getEpisodes(variables: GetEpisodesQueryVariables): Promise<GetEpisodesQuery> {
      return withWrapper(() => client.request<GetEpisodesQuery>(print(GetEpisodesDocument), variables));
    },
    getEpisodesByShow(variables: GetEpisodesByShowQueryVariables): Promise<GetEpisodesByShowQuery> {
      return withWrapper(() => client.request<GetEpisodesByShowQuery>(print(GetEpisodesByShowDocument), variables));
    },
    getFeaturedEpisodes(variables: GetFeaturedEpisodesQueryVariables): Promise<GetFeaturedEpisodesQuery> {
      return withWrapper(() => client.request<GetFeaturedEpisodesQuery>(print(GetFeaturedEpisodesDocument), variables));
    },
    getEpisodeBySlug(variables: GetEpisodeBySlugQueryVariables): Promise<GetEpisodeBySlugQuery> {
      return withWrapper(() => client.request<GetEpisodeBySlugQuery>(print(GetEpisodeBySlugDocument), variables));
    },
    getEpisodeSegments(variables: GetEpisodeSegmentsQueryVariables): Promise<GetEpisodeSegmentsQuery> {
      return withWrapper(() => client.request<GetEpisodeSegmentsQuery>(print(GetEpisodeSegmentsDocument), variables));
    },
    getLiveEpisodes(variables?: GetLiveEpisodesQueryVariables): Promise<GetLiveEpisodesQuery> {
      return withWrapper(() => client.request<GetLiveEpisodesQuery>(print(GetLiveEpisodesDocument), variables));
    },
    getModularPageSlugs(variables: GetModularPageSlugsQueryVariables): Promise<GetModularPageSlugsQuery> {
      return withWrapper(() => client.request<GetModularPageSlugsQuery>(print(GetModularPageSlugsDocument), variables));
    },
    getModularPage(variables: GetModularPageQueryVariables): Promise<GetModularPageQuery> {
      return withWrapper(() => client.request<GetModularPageQuery>(print(GetModularPageDocument), variables));
    },
    getContinueWatchingModuleCustom(variables: GetContinueWatchingModuleCustomQueryVariables): Promise<GetContinueWatchingModuleCustomQuery> {
      return withWrapper(() => client.request<GetContinueWatchingModuleCustomQuery>(print(GetContinueWatchingModuleCustomDocument), variables));
    },
    getContinueListeningModule(variables: GetContinueListeningModuleQueryVariables): Promise<GetContinueListeningModuleQuery> {
      return withWrapper(() => client.request<GetContinueListeningModuleQuery>(print(GetContinueListeningModuleDocument), variables));
    },
    getParselyTopPostsAuthorsTags(variables: GetParselyTopPostsAuthorsTagsQueryVariables): Promise<GetParselyTopPostsAuthorsTagsQuery> {
      return withWrapper(() => client.request<GetParselyTopPostsAuthorsTagsQuery>(print(GetParselyTopPostsAuthorsTagsDocument), variables));
    },
    getParselyTopTopics(variables: GetParselyTopTopicsQueryVariables): Promise<GetParselyTopTopicsQuery> {
      return withWrapper(() => client.request<GetParselyTopTopicsQuery>(print(GetParselyTopTopicsDocument), variables));
    },
    getPodcasts(variables?: GetPodcastsQueryVariables): Promise<GetPodcastsQuery> {
      return withWrapper(() => client.request<GetPodcastsQuery>(print(GetPodcastsDocument), variables));
    },
    getPodcast(variables: GetPodcastQueryVariables): Promise<GetPodcastQuery> {
      return withWrapper(() => client.request<GetPodcastQuery>(print(GetPodcastDocument), variables));
    },
    getPodcastEpisodes(variables?: GetPodcastEpisodesQueryVariables): Promise<GetPodcastEpisodesQuery> {
      return withWrapper(() => client.request<GetPodcastEpisodesQuery>(print(GetPodcastEpisodesDocument), variables));
    },
    getPodcastEpisode(variables: GetPodcastEpisodeQueryVariables): Promise<GetPodcastEpisodeQuery> {
      return withWrapper(() => client.request<GetPodcastEpisodeQuery>(print(GetPodcastEpisodeDocument), variables));
    },
    getPodcastEpisodeAudio(variables: GetPodcastEpisodeAudioQueryVariables): Promise<GetPodcastEpisodeAudioQuery> {
      return withWrapper(() => client.request<GetPodcastEpisodeAudioQuery>(print(GetPodcastEpisodeAudioDocument), variables));
    },
    getShows(variables?: GetShowsQueryVariables): Promise<GetShowsQuery> {
      return withWrapper(() => client.request<GetShowsQuery>(print(GetShowsDocument), variables));
    },
    getShowTitles(variables?: GetShowTitlesQueryVariables): Promise<GetShowTitlesQuery> {
      return withWrapper(() => client.request<GetShowTitlesQuery>(print(GetShowTitlesDocument), variables));
    },
    getShowSlugs(variables?: GetShowSlugsQueryVariables): Promise<GetShowSlugsQuery> {
      return withWrapper(() => client.request<GetShowSlugsQuery>(print(GetShowSlugsDocument), variables));
    },
    getPragerUShows(variables?: GetPragerUShowsQueryVariables): Promise<GetPragerUShowsQuery> {
      return withWrapper(() => client.request<GetPragerUShowsQuery>(print(GetPragerUShowsDocument), variables));
    },
    getShowBySlug(variables: GetShowBySlugQueryVariables): Promise<GetShowBySlugQuery> {
      return withWrapper(() => client.request<GetShowBySlugQuery>(print(GetShowBySlugDocument), variables));
    },
    getVideoSlugs(variables: GetVideoSlugsQueryVariables): Promise<GetVideoSlugsQuery> {
      return withWrapper(() => client.request<GetVideoSlugsQuery>(print(GetVideoSlugsDocument), variables));
    },
    getVideos(variables: GetVideosQueryVariables): Promise<GetVideosQuery> {
      return withWrapper(() => client.request<GetVideosQuery>(print(GetVideosDocument), variables));
    },
    getVideoBySlug(variables: GetVideoBySlugQueryVariables): Promise<GetVideoBySlugQuery> {
      return withWrapper(() => client.request<GetVideoBySlugQuery>(print(GetVideoBySlugDocument), variables));
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;
import { parseJwt } from './parsejwt';

export function tokenShouldRefreshed(token: string): boolean {
  if (!token || token === 'undefined' || token === 'null') {
    return true;
  }
  const val = parseJwt(token);
  const now = Math.ceil(Date.now() / 1000);

  return val.exp <= now;
}

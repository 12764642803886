import { css } from '@emotion/core';

export const font = {
  beaufort: css`
    font-family: 'beaufortproregular';
  `,
  beaufortLight: css`
    font-family: 'beaufort_prolight';
  `,
  beaufortMedium: css`
    font-family: 'beaufort_promedium';
  `,
  beaufortBold: css`
    font-family: 'beaufortprobold';
  `,
  beaufortHeavy: css`
    font-family: 'beaufort_proheavy';
  `,
  lato: css`
    font-family: 'Lato', sans-serif;
  `,
};

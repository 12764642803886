export function continentKeyToName(continentKey: string) {
  let continentName = '';
  switch (continentKey) {
    case 'NA':
      continentName = 'North America';
      break;
    case 'AS':
      continentName = 'Asia';
      break;
    case 'AF':
      continentName = 'Africa';
      break;
    case 'OC':
      continentName = 'Oceania';
      break;
    case 'AN':
      continentName = 'Antarctica';
      break;
    case 'EU':
      continentName = 'Europe';
      break;
    case 'SA':
      continentName = 'South America';
      break;
  }
  return continentName;
}

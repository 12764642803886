import { useCallback, useMemo } from 'react';

import { useRouter } from 'next/router';
import useSWR, { useSWRConfig, unstable_serialize, MutatorOptions } from 'swr';

import { useAuth0 } from '@/context/auth';
import { GetEpisodeBySlugQuery } from '@/graphql/app';
import { appApi } from '@/request/middleware';
import { AppApi } from '@/request/types';

import { EpisodeQueryKeys } from '../types';

export const useEpisode = (fallbackData?: GetEpisodeBySlugQuery, staticSlug?: string) => {
  const { mutate } = useSWRConfig();
  const { isAuthenticated } = useAuth0();
  const router = useRouter();

  const key = useMemo(
    () => (isAuthenticated ? [EpisodeQueryKeys.EpisodeBySlug, staticSlug || router.query.slug] : undefined),
    [isAuthenticated, router.query.slug, staticSlug],
  );

  const result = useSWR(key ?? null, (_, slug: string, appApi: AppApi) => appApi.getEpisodeBySlug({ slug }), {
    fallbackData,
    revalidateOnFocus: false,
    revalidateOnMount: true,
    use: [appApi],
  });

  const customBoundMutate = useCallback(
    (data: typeof result['data'], opts?: boolean | MutatorOptions) => mutate(unstable_serialize(key), data, opts),
    [key, mutate],
  );

  return { ...result, mutate: customBoundMutate };
};

import React, { FC } from 'react';

import { Divider, TabsContainer, TabsRoot } from './Tabs.styles';

type TabAlignment = 'left' | 'center' | 'right';

export interface TabsProps {
  alignment?: TabAlignment;
  divider?: boolean;
}

export const Tabs: FC<TabsProps> = ({ alignment, divider = true, children }) => {
  return (
    <TabsRoot>
      <TabsContainer alignment={alignment}>{children}</TabsContainer>
      {divider && <Divider />}
    </TabsRoot>
  );
};

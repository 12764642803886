import { Middleware, SWRHook } from 'swr';

import { useAuth0 } from '@/context/auth';

export const user: Middleware = (useSWRHook: SWRHook) => {
  return (key, fetcher, config) => {
    if (!fetcher) {
      throw new Error('Fetcher must be provided for `userMiddleware` to function.');
    }

    const { user } = useAuth0();

    return useSWRHook(Array.isArray(key) ? [...key, user] : [key, user], fetcher, config);
  };
};

import styled from '@emotion/styled';

import { TabsProps } from './Tabs';

function mapAlignmentToJustifyContent(alignment?: TabsProps['alignment']) {
  if (alignment === 'left') return 'flex-start';
  if (alignment === 'right') return 'flex-end';

  return alignment;
}

export const TabsRoot = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: stretch;
`;

export const TabsContainer = styled.div<TabsProps>`
  flex: 1;
  display: flex;
  width: 100%;
  align-items: stretch;
  justify-content: ${({ alignment }) => mapAlignmentToJustifyContent(alignment)};
`;

export const Divider = styled.div`
  flex-shrink: 0;
  width: 100%;
  height: 1px;
  background: linear-gradient(to right, #808080, rgba(0, 0, 0, 0));
  background-color: transparent;
`;

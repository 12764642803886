import * as React from 'react';

import { Global, css } from '@emotion/core';
import ReactModal from 'react-modal';

import { Icon } from '@/components/design-system';
import { breakpoints } from '@/utils/styles';

const globalStyles = css`
  .overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9999;
    background: rgba(5, 5, 5, 0.95);
  }
`;

const modalStyles = css`
  position: absolute;
  display: grid;
  gap: 8px;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  max-height: calc(100vh - 48px);
  padding: 0;
  color: #fff;
  transform: translate(-50%, -50%);
  opacity: 0;
  border: none;
  transition: all 400ms ease;
  overflow-y: auto;

  @media (min-width: ${breakpoints.tablet}px) {
    min-width: auto;
  }

  &:focus {
    outline: none;
  }

  &.ReactModal__Content--after-open {
    transform: translate(-50%, -50%);
    opacity: 1;
    transition-delay: 400ms;
  }

  &.ReactModal__Content--before-close {
    transform: translate(-50%, -25%);
    opacity: 0;
    transition-delay: 0s;
  }
`;

type ModalProps = {
  showModal: boolean;
  onRequestClose: () => void;
  gutter?: boolean;
  size?: 'default' | 'large';
};

export const Modal: React.FC<ModalProps> = ({
  showModal,
  gutter = true,
  size = 'default',
  onRequestClose,
  children,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const handleCloseModal = () => {
    if (onRequestClose) {
      onRequestClose();
    }
    setIsOpen(false);
  };

  React.useEffect(() => {
    ReactModal.setAppElement('#__next');
  }, []);

  React.useEffect(() => {
    setIsOpen(showModal);
  }, [setIsOpen, showModal]);

  return (
    <>
      <Global styles={globalStyles} />
      <ReactModal
        closeTimeoutMS={800}
        contentLabel='Modal'
        css={modalStyles}
        isOpen={isOpen}
        onRequestClose={handleCloseModal}
        overlayClassName='overlay'
      >
        <div
          css={(theme) => ({
            marginBottom: 32,
            backgroundColor: theme.colors.gray90,
            borderRadius: 6,
            padding: gutter ? 32 : 0,
            width: '90vw',
            [theme.mq.tablet]: {
              padding: gutter ? 56 : 0,
              maxWidth: size === 'default' ? 640 : 900,
            },
          })}
        >
          {children}
        </div>
        <button
          css={{
            padding: '0',
            appearance: 'none',
            border: '0',
            backgroundColor: 'transparent',
            gridRow: '1',
            justifySelf: 'end',

            ':focus': {
              outline: '0',
            },
          }}
          onClick={handleCloseModal}
        >
          <Icon css={{ color: '#CCCCCC' }} name='CLOSE' size={24} />
        </button>
      </ReactModal>
    </>
  );
};

import * as React from 'react';

import { spin } from '@/components/design-system/utils/animations';

type SpinnerProps = {
  size?: number;
  strokeWidth?: number;
  title?: string;
  duration?: number;
  variant?: 'default' | 'simple';
  color?: string;
};

export const Spinner: React.FC<SpinnerProps> = ({
  size = 56,
  strokeWidth = 4,
  title = 'Loading...',
  duration = 500,
  variant = 'default',
  color,
  ...props
}) => {
  const r = 16 - strokeWidth;
  const C = 2 * r * Math.PI;
  const offset = C - (1 / 4) * C;

  return (
    <svg
      css={(theme) => ({
        color: color ?? theme.colors.primary,
        overflow: 'visible',
        ...(variant === 'default' && {
          background: theme.colors.white,
          borderRadius: '50%',
          padding: 8,
          boxShadow: '0px 4px 8px rgba(45, 64, 83, 0.08)',
        }),
      })}
      fill='none'
      height={size}
      role='img'
      stroke='currentcolor'
      strokeWidth={strokeWidth}
      viewBox='0 0 32 32'
      width={size}
      {...props}
    >
      <title>{title}</title>
      <circle cx={16} cy={16} opacity={1 / 8} r={r} />
      <circle
        css={{
          transformOrigin: '50% 50%',
          animationName: spin.toString(),
          animationTimingFunction: 'linear',
          animationDuration: duration + 'ms',
          animationIterationCount: 'infinite',
        }}
        cx={16}
        cy={16}
        r={r}
        strokeDasharray={C}
        strokeDashoffset={offset}
      />
    </svg>
  );
};

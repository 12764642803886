import * as React from 'react';

import { css } from '@emotion/core';
import styled from '@emotion/styled';

import { spin } from '@/components/design-system/utils/animations';
import { color } from '@/utils/styles';

const loaderDimensions = css`
  height: 56px;
  width: 56px;
  max-width: 100%;
  max-height: 100%;
`;

export const layoutStyles = {
  inline: css`
    height: 56px;
    width: 56px;
    position: relative;
    max-width: 100%;
    max-height: 100%;
  `,
  fullscreen: css`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    justify-content: center;
    z-index: 99;
  `,
  overlay: css`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    justify-content: center;
    z-index: 9999;
  `,
};

export const backgroundStyles = {
  light: css`
    background: #fff;
    path {
      fill: ${color.gray60};
    }
  `,
  dark: css`
    background: #000;
    path {
      fill: ${color.gray30};
    }
  `,
};

export const logoStyles = {
  light: css`
    path {
      fill: ${color.gray30};
    }
  `,
  dark: css`
    path {
      fill: ${color.gray60};
    }
  `,
};

export const Lettermark = styled('svg')`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const Spinner = styled('div')`
  border-radius: 50%;
  ${loaderDimensions};
  color: red;
  position: relative;
  border-left: 3px solid ${(props) => props.color};
  transform: translateZ(0);
  animation: ${spin} 0.75s infinite linear;
  border-top: 2px solid transparent;
  border-right: 2px solid transparent;
  border-bottom: 2px solid transparent;

  &:after {
    border-radius: 50%;
    ${loaderDimensions};
  }
`;
type LoaderProps = {
  format?: 'inline' | 'fullscreen' | 'overlay';
  mode?: 'light' | 'dark';
  color?: string;
  hideLettermark?: boolean;
};

export const Loader: React.FC<LoaderProps & React.HTMLProps<HTMLDivElement>> = ({
  format = 'inline',
  mode = 'dark',
  hideLettermark = false,
  color = '#B33828',
  ...rest
}) => {
  const styles = [layoutStyles[format], logoStyles[mode]];
  if (format === 'fullscreen' || format === 'overlay') {
    styles.push(backgroundStyles[mode]);
  }

  return (
    <div css={styles} style={{ margin: '0 auto ' }} {...rest}>
      {!hideLettermark && (
        <Lettermark fill='none' height='12' viewBox='0 0 26 12' width='26' xmlns='http://www.w3.org/2000/svg'>
          <path
            clipRule='evenodd'
            d='M11.6679 0.0895556V0H10.1444H8.62061V0.0895556C8.70083 0.136444 8.77616 0.259333 8.91683 0.624444C10.6195 1.72689 11.5868 3.55 11.5868 5.70689V5.72222C11.5868 6.42644 11.4902 7.09067 11.3042 7.71133L12.6837 11.8656H13.0391C16.2726 5.88067 16.6957 4.97 17.0004 4.194H17.0173C17.3219 4.95511 17.7284 5.85067 20.8939 11.8656H21.2495L23.6195 4.62689C24.8722 0.790889 25.0586 0.223778 25.2615 0.0895556V0H23.8566H22.4344V0.0895556C22.6548 0.194 22.4177 0.776222 21.5037 3.77622C21.1313 4.97 20.9279 5.71644 20.691 6.61178H20.6571C20.3184 5.85067 19.9122 5.01511 19.2859 3.71622C18.4226 2.23867 17.7624 1.04467 17.2544 0.119111H16.9157C16.4079 1.05956 15.6802 2.29822 14.9353 3.67156C14.2244 5.08956 13.8517 5.86556 13.5133 6.67156H13.4624C13.2255 5.79089 13.0053 5.04489 12.5817 3.68667C11.6679 0.790889 11.4479 0.208667 11.6679 0.0895556Z'
            fill='#CCCCCC'
            fillRule='evenodd'
          />
          <path
            clipRule='evenodd'
            d='M2.78356 2.06289C3.09111 2.03289 3.46667 2.00267 3.85956 2.00267C5.00378 2.00267 5.99422 2.27356 6.67756 2.72556C7.66822 3.40311 8.23178 4.51756 8.23178 5.782V5.79733C8.23178 7.288 7.566 8.35711 6.59244 9.04978C5.858 9.57689 4.85 9.83289 3.82511 9.83289C3.51822 9.83289 3.14222 9.818 2.86911 9.78756C2.81778 9.78756 2.78356 9.75756 2.78356 9.69711V2.06289ZM0 11.6998V11.7753C0.392667 11.8053 0.905111 11.8204 1.36622 11.8356C1.91244 11.8507 2.68089 11.8656 3.39844 11.8656C5.294 11.8656 6.934 11.5042 8.16356 10.7064C9.88867 9.60689 10.9131 7.92022 10.9131 5.72222V5.70689C10.9131 3.87 10.0936 1.98756 8.14644 0.948667C7.01911 0.331111 5.55 0 3.63778 0C2.95467 0 2.32244 0.0148889 1.60511 0.0451111C0.905111 0.0602222 0.375556 0.105333 0.0171111 0.120444V0.271111C0.238889 0.376444 0.238889 0.888444 0.238889 4.96911V6.62556C0.238889 11.0073 0.238889 11.5644 0 11.6998Z'
            fill='#CCCCCC'
            fillRule='evenodd'
          />
        </Lettermark>
      )}
      <Spinner color={color} />
    </div>
  );
};

/*
 * NOTE: use a six-character hex code for all colors to allow alpha channel
 * adjustment without adding extra vars and/or a color manipulation lib.
 *
 * Example:
 *    // use the brand color at 25% opacity
 *    border-color: ${colors.brand}40;
 */
export const color = {
  red: '#B33828',
  blue: '#0077D9',
  green: '#0F992A',
  gray99: '#111111',
  gray98: '#050505',
  gray95: 'rgba(20,20,21,0.91)',
  gray92: '#141414',
  gray90: '#1A1A1A',
  gray85: '#262626',
  gray80: '#333333',
  gray60: '#666666',
  gray50: '#808080',
  gray40: '#999999',
  gray30: '#B3B3B3',
  gray20: '#CCCCCC',
  gray10: '#E6E6E6',
  gray5: '#F2F2F2',
  white: '#ffffff',
  black: '#000000',
};

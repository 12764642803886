import * as React from 'react';

import Portal from '@reach/portal';
import cn from 'classnames';
import { useClickAway } from 'react-use';

import { Heading, Icon } from '@/components/design-system';

import s from './Drawer.module.css';

interface IDrawer {
  open?: boolean;
  onClose?: () => void;
  position: 'top' | 'left' | 'right' | 'bottom';
  bg: 'light' | 'dark';
  title?: string | null;
}

export const Drawer: React.FC<IDrawer> = ({ open, onClose, position, bg, children, title }) => {
  const ref = React.useRef<HTMLDivElement | null>(null);

  // useLockBodyScroll(open);
  useClickAway(ref, () => (onClose ? onClose() : false));

  const handleKey = React.useCallback(
    (e: KeyboardEvent) => {
      if (onClose && e.key === 'Escape') {
        return onClose();
      }
    },
    [onClose],
  );

  React.useEffect(() => {
    if (ref.current && open) {
      window.addEventListener('keydown', handleKey);
    }
    return () => {
      window.removeEventListener('keydown', handleKey);
    };
  }, [open, handleKey]);

  return (
    <Portal>
      {open && (
        <div
          className={cn(s.root, {
            [s.root__top]: position === 'top',
            [s.root__right]: position === 'right',
            [s.root__bottom]: position === 'bottom',
            [s.root__left]: position === 'left',
            [s.root__light]: bg === 'light',
            [s.root__dark]: bg === 'dark',
            [s.root__hasTitle]: !!title,
          })}
        >
          <div className={s.drawer} ref={ref}>
            <div className={s.header}>
              {title && <Heading variant='400'>{title}</Heading>}
              {onClose && (
                <button className={s.close} onClick={onClose}>
                  <Icon name='CLOSE' size={20} />
                </button>
              )}
            </div>
            <div className={s.content}>{children}</div>
          </div>
        </div>
      )}
    </Portal>
  );
};

import * as React from 'react';

import { Spinner } from './Spinner';

type ButtonProps = {
  variant?: 'primary' | 'secondary' | 'tertiary' | 'minimal' | 'naked';
  size?: 'large' | 'small';
  loading?: boolean;
  progressPercentage?: number | null;
  onClick?: (event: React.MouseEvent<Element, MouseEvent>) => void;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset' | undefined;
  className?: string;
};

export const Button: React.FC<ButtonProps> = ({
  variant = 'primary',
  size = 'large',
  loading = false,
  progressPercentage,
  className = '',
  children,
  type = 'button',
  ...props
}) => {
  return (
    <button
      className={className}
      css={(theme) => ({
        appearance: 'button',
        border: 0,
        outline: 0,
        transition: 'padding-left 200ms linear',
        cursor: 'pointer',
        position: 'relative',
        display: 'inline-block',
        textAlign: 'center',
        fontWeight: '700',

        ...(variant !== 'naked' && {
          borderRadius: theme.radii.default,
          fontFamily: 'Libre Franklin',

          ...theme.buttonSizes[size],
        }),

        ...theme.buttons[variant],

        ...(!loading && {
          '&:disabled': {
            backgroundImage: 'none',
            backgroundColor: '#B9BDC6',
            borderColor: '#B9BDC6',
            color: theme.colors.white,
            cursor: 'not-allowed',
          },
        }),

        ...(loading && {
          '&, &:disabled': {
            paddingLeft: 50,
            backgroundImage: 'none',
            color: theme.colors.white,
            cursor: 'not-allowed',
          },
        }),
      })}
      type={type}
      {...props}
    >
      {loading && (
        <Spinner
          css={(theme) => ({
            color: theme.colors.white,
            marginRight: 16,
            position: 'absolute',
            left: 16,
            top: '50%',
            transform: 'translateY(-50%)',
          })}
          size={24}
          variant='simple'
        />
      )}
      {children}

      {!!progressPercentage && (
        <div
          css={{
            backgroundColor: variant === 'primary' ? 'rgba(255,255,255,.25)' : 'rgba(217, 0, 0, 0.25);',
            position: 'absolute',
            bottom: -1,
            left: -1,
            right: 0,
            borderRadius: '0 0 .25rem .25rem',
            overflow: 'hidden',
          }}
        >
          <div
            css={(theme) => ({
              width: progressPercentage + '%',
              height: '.25rem',
              background: variant === 'primary' ? theme.colors.white : theme.colors.red,
              borderRadius: '0 .25rem .25rem 0',
            })}
          />
        </div>
      )}
    </button>
  );
};

import React, { createContext, useContext, useState, ReactNode } from 'react';

type LightModeToggleContextType = {
  isLightModeActive: boolean;
  setIsLightModeActive: (value: boolean) => void;
};

const LightModeToggleContext = createContext<LightModeToggleContextType | undefined>(undefined);

export const LightModeToggleProvider = ({ children }: { children: ReactNode }) => {
  const [isLightModeActive, setIsLightModeActive] = useState(false);

  return (
    <LightModeToggleContext.Provider value={{ isLightModeActive, setIsLightModeActive }}>
      {children}
    </LightModeToggleContext.Provider>
  );
};

export const useLightModeToggle = () => {
  const context = useContext(LightModeToggleContext);
  if (!context) {
    throw new Error('useLightModeToggle must be used within a LightModeToggleProvider');
  }
  return context;
};

import React from 'react';

interface IProgressCircle {
  size: number;
  stroke: number;
  percent: number;
  className?: string;
}

export const ProgressCircle = React.memo<IProgressCircle>(({ size, stroke, percent, className }) => {
  const radius = size / 2 - stroke / 2;
  const circumference = 2 * Math.PI * radius;

  return (
    <svg
      className={className}
      height={size}
      style={{ transform: 'rotate(-90deg)' }}
      viewBox={`0 0 ${size} ${size}`}
      width={size}
    >
      <circle
        cx={size / 2}
        cy={size / 2}
        r={radius}
        style={{
          fill: 'transparent',
          stroke: 'currentColor',
          strokeWidth: stroke,
          strokeDasharray: circumference,
          strokeDashoffset: circumference * (1 - percent / 100),
          transition: 'all 1s ease',
        }}
      />
    </svg>
  );
});

ProgressCircle.displayName = 'ProgressCircle';

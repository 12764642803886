import styled from '@emotion/styled';

type Props = {
  theme: any;
  variant?: 'default' | 'light';
  spacing?: 'tight' | 'normal';
  lettermark?: boolean;
};

export const Hr = styled('hr')(({ theme, ...props }: Props) => ({
  height: 1,
  border: 'none',
  overflow: 'visible',
  position: 'relative',
  backgroundColor: props.variant === 'light' ? theme.colors.gray10 : theme.colors.gray85,
  margin: props.spacing === 'tight' ? '24px 0' : '40px 0',

  ...(props.lettermark && {
    '::before': {
      content: "''",
      position: 'absolute',
      left: '50%',
      top: -6,
      transform: 'translateX(-50%)',
      height: 12,
      width: 64,
      padding: 0,
      background: `url(./images/lettermark.svg) center / contain no-repeat`,
      backgroundColor: props.variant === 'light' ? theme.colors.white : theme.colors.gray90,
    },
    [theme.mq.tablet]: {
      margin: props.spacing === 'tight' ? '24px 0' : '56px 0',
    },
  }),
}));

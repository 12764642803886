/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';

import styled from '@emotion/styled';

export const CreditCard = styled('div')<any>(({ theme }) => ({
  ...theme.shadows.default,

  margin: '0 auto',
  maxWidth: '360px',
  padding: '24px',
  borderRadius: '8px',
  background: 'linear-gradient(119.74deg, #404040 0%, #1b1b1b 99.97%)',
  color: '#fff',

  [theme.mq.phablet]: {
    maxWidth: '480px',
    padding: '40px',
  },
}));

export const CreditCardTop = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',

  svg: {
    width: 'auto',
    maxWidth: '120px',
    height: '32px',
  },
});

type CreditCardLogoProps = {
  card_type?: string | null;
};

export const CreditCardLogo: React.FC<CreditCardLogoProps> = ({ card_type }) => {
  const iconName = card_type?.replace(' ', '-').toLowerCase();

  const didMatchLogo =
    iconName === 'american-express' ||
    iconName === 'diners-club' ||
    iconName === 'jcb' ||
    iconName === 'mastercard' ||
    iconName === 'visa';

  return didMatchLogo ? (
    <img alt={`${card_type} credit card`} src={`/svg/credit-cards/${iconName}.svg`} />
  ) : (
    <div>{card_type}</div>
  );
};

export const CreditCardNumbers = styled('div')<any>(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  margin: '24px 0',

  fontSize: '18px',
  textTransform: 'uppercase',
  letterSpacing: '2px',
  fontWeight: 300,
  color: theme.colors.gray30,
}));

export const CreditCardBottom = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
});

export const CreditCardLabel = styled('div')({
  marginBottom: '4px',
  fontSize: '10px',
  letterSpacing: '2px',
  textTransform: 'uppercase',
  fontWeight: 700,
  whiteSpace: 'nowrap',
});

export const CreditCardValue = styled('div')<any>(({ theme }) => ({
  fontSize: '14px',
  letterSpacing: '2px',
  textTransform: 'uppercase',
  color: theme.colors.gray30,
  textShadow: '0px 1px 0px #000000',
}));

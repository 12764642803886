import { APOLLO_CLIENT_NAME } from '@/lib/constants';
import { GraphQLClient } from 'graphql-request';

export * from './generated';
import { getSdk } from './generated';

const appApiURL = `https://${process.env.NEXT_PUBLIC_DW_APP_API_HOST}/app/graphql`;

const appClient = new GraphQLClient(appApiURL, {
  headers: { 'apollographql-client-name': APOLLO_CLIENT_NAME  },
});

export const appApi = getSdk(appClient);

export const getAppApiWithToken = (token: string) => {
  const appApiWithToken = new GraphQLClient(appApiURL, {
    headers: { Authorization: `Bearer ${token}`, 'apollographql-client-name': APOLLO_CLIENT_NAME  },
  });

  return getSdk(appApiWithToken);
};

import { keyframes } from '@emotion/core';

export const fadeIn = keyframes({
  from: {
    opacity: 0,
  },

  to: {
    opacity: 1,
  },
});

export const fadeOut = keyframes({
  from: {
    opacity: 1,
  },

  to: {
    opacity: 0,
  },
});

export const fadeDown = keyframes({
  from: {
    opacity: 0,
    transform: 'translateY(-4rem)',
  },

  to: {
    opacity: 1,
    transform: 'translateY(0)',
  },
});

export const slideUp = keyframes({
  from: {
    transform: 'translateY(100%)',
  },

  to: {
    transform: 'translateY(0)',
  },
});

export const slideDown = keyframes({
  from: {
    transform: 'translateY(0)',
  },

  to: {
    transform: 'translateY(100%)',
  },
});

export const spin = keyframes({
  from: {
    transform: 'rotate(0deg)',
  },
  to: {
    transform: 'rotate(360deg)',
  },
});

import { css } from '@emotion/core';

export const scrollable = css`
  -webkit-overflow-scrolling: touch;
  overflow-x: scroll;
  display: flex;
  flex-direction: row;

  scrollbar-width: none;
  -ms-overflow-style: none;

  &&::-webkit-scrollbar {
    display: none;
  }
`;

export default scrollable;

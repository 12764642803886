import * as React from 'react';

import styles from './index.module.css';

interface ISectionLabelProps {
  section?: string;
  title?: string;
}

export const SectionLabelV2: React.FC<ISectionLabelProps> = ({ section, title }) => {
  return (
    <div className={styles.sectionLabel}>
      {section && <span>{section}</span>}
      {title && title}
    </div>
  );
};

import { GraphQLClient } from 'graphql-request';
import { print } from 'graphql';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Json: any;
  DateTime: any;
  Long: any;
};

export type Query = {
  __typename?: 'Query';
  activityLog?: Maybe<ActivityLog>;
  activityLogs: Array<Maybe<ActivityLog>>;
  activityLogsConnection: ActivityLogConnection;
  archiveMessage?: Maybe<ArchiveMessage>;
  archiveMessages: Array<Maybe<ArchiveMessage>>;
  archiveMessagesConnection: ArchiveMessageConnection;
  badge?: Maybe<Badge>;
  badges: Array<Maybe<Badge>>;
  badgesConnection: BadgeConnection;
  comment?: Maybe<Comment>;
  comments: Array<Maybe<Comment>>;
  commentsConnection: CommentConnection;
  configuration?: Maybe<Configuration>;
  configurations: Array<Maybe<Configuration>>;
  configurationsConnection: ConfigurationConnection;
  discussion?: Maybe<Discussion>;
  discussions: Array<Maybe<Discussion>>;
  discussionsConnection: DiscussionConnection;
  emailChangeRequest?: Maybe<EmailChangeRequest>;
  emailChangeRequests: Array<Maybe<EmailChangeRequest>>;
  emailChangeRequestsConnection: EmailChangeRequestConnection;
  message?: Maybe<Message>;
  messages: Array<Maybe<Message>>;
  messagesConnection: MessageConnection;
  notification?: Maybe<Notification>;
  notifications: Array<Maybe<Notification>>;
  notificationsConnection: NotificationConnection;
  notificationUser?: Maybe<NotificationUser>;
  notificationUsers: Array<Maybe<NotificationUser>>;
  notificationUsersConnection: NotificationUserConnection;
  oneSignalPerson?: Maybe<OneSignalPerson>;
  oneSignalPersons: Array<Maybe<OneSignalPerson>>;
  oneSignalPersonsConnection: OneSignalPersonConnection;
  order?: Maybe<Order>;
  orders: Array<Maybe<Order>>;
  ordersConnection: OrderConnection;
  permission?: Maybe<Permission>;
  permissions: Array<Maybe<Permission>>;
  permissionsConnection: PermissionConnection;
  person?: Maybe<Person>;
  persons: Array<Maybe<Person>>;
  personsConnection: PersonConnection;
  personPermission?: Maybe<PersonPermission>;
  personPermissions: Array<Maybe<PersonPermission>>;
  personPermissionsConnection: PersonPermissionConnection;
  post?: Maybe<Post>;
  posts: Array<Maybe<Post>>;
  postsConnection: PostConnection;
  postVideo?: Maybe<PostVideo>;
  postVideos: Array<Maybe<PostVideo>>;
  postVideosConnection: PostVideoConnection;
  recurlyError?: Maybe<RecurlyError>;
  recurlyErrors: Array<Maybe<RecurlyError>>;
  recurlyErrorsConnection: RecurlyErrorConnection;
  recurlyWebhook?: Maybe<RecurlyWebhook>;
  recurlyWebhooks: Array<Maybe<RecurlyWebhook>>;
  recurlyWebhooksConnection: RecurlyWebhookConnection;
  setting?: Maybe<Setting>;
  settings: Array<Maybe<Setting>>;
  settingsConnection: SettingConnection;
  shopifyWebhook?: Maybe<ShopifyWebhook>;
  shopifyWebhooks: Array<Maybe<ShopifyWebhook>>;
  shopifyWebhooksConnection: ShopifyWebhookConnection;
  template?: Maybe<Template>;
  templates: Array<Maybe<Template>>;
  templatesConnection: TemplateConnection;
  templatePermission?: Maybe<TemplatePermission>;
  templatePermissions: Array<Maybe<TemplatePermission>>;
  templatePermissionsConnection: TemplatePermissionConnection;
  userBadge?: Maybe<UserBadge>;
  userBadges: Array<Maybe<UserBadge>>;
  userBadgesConnection: UserBadgeConnection;
  userSetting?: Maybe<UserSetting>;
  userSettings: Array<Maybe<UserSetting>>;
  userSettingsConnection: UserSettingConnection;
  watchTime?: Maybe<WatchTime>;
  watchTimes: Array<Maybe<WatchTime>>;
  watchTimesConnection: WatchTimeConnection;
  node?: Maybe<Node>;
  hasFreeArticleAccess?: Maybe<Scalars['Boolean']>;
  getBadgeSignedPutUrl: SignedUrlType;
  getUserBadges?: Maybe<UserBadges>;
  commentThread?: Maybe<Array<Maybe<Comment>>>;
  noteworthyComments?: Maybe<Array<Maybe<Comment>>>;
  getCommentImageSignedPutUrl: SignedUrlType;
  listUserName?: Maybe<Array<Maybe<ListUserNameRes>>>;
  discussionThread?: Maybe<DiscussionData>;
  getLiveDiscussions?: Maybe<Array<Maybe<Discussion>>>;
  commentModeration?: Maybe<ModerationComment>;
  listPostModeration?: Maybe<Array<Maybe<Post>>>;
  getPostModeration?: Maybe<Post>;
  commentThreadModeration?: Maybe<Array<Maybe<ModerationComment>>>;
  listCommentsModeration?: Maybe<Array<Maybe<ModerationComment>>>;
  listCommentPersonModeration?: Maybe<Array<Maybe<CommentPerson>>>;
  hasNewNotification?: Maybe<Scalars['Boolean']>;
  getCurrentPersonPermission?: Maybe<Array<Maybe<PermissionCustom>>>;
  getPersonPermission?: Maybe<GetPersonPermissionRes>;
  currentPerson?: Maybe<RecurlyPerson>;
  getPersonProfileSignedPutUrl: SignedUrlType;
  syncRecurlyPlan?: Maybe<Scalars['Boolean']>;
  isUserNameAvailable?: Maybe<Scalars['Boolean']>;
  getPersonDetails?: Maybe<PersonDetails>;
  getPostImageSignedPutUrl: SignedUrlType;
  getPostVideoUploadSignedUrl: UploadUrlType;
  getPostLiveStreamUrl: PostVideo;
  listPost?: Maybe<Array<Maybe<Post>>>;
  listAuthor?: Maybe<Array<Maybe<AuthorRes>>>;
  getRecurlyAccount?: Maybe<Array<Maybe<RecurlyAccount>>>;
  getRecurlyAccounts?: Maybe<Array<Maybe<RecurlyAccount>>>;
  getRecurlyShippingAddress?: Maybe<Array<Maybe<RecurlyShippingAddress>>>;
  getRecurlySubscription?: Maybe<Array<Maybe<RecurlySubscription>>>;
  getRecurlySubscriptions?: Maybe<Array<Maybe<RecurlySubscription>>>;
  getRecurlyAllSubscriptions?: Maybe<Array<Maybe<RecurlySubscription>>>;
  getRecurlyInvoice?: Maybe<Array<Maybe<RecurlyInvoice>>>;
  getRecurlyInvoices?: Maybe<Array<Maybe<RecurlyInvoice>>>;
  getRecurlyAllInvoices?: Maybe<Array<Maybe<RecurlyInvoice>>>;
  getRecurlyBillingInfo?: Maybe<Array<Maybe<RecurlyBillingInfo>>>;
  getRecurlyPlan?: Maybe<Array<Maybe<RecurlyPlan>>>;
  getRecurlyPlans?: Maybe<Array<Maybe<RecurlyPlan>>>;
  getRecurlyCoupon?: Maybe<Array<Maybe<RecurlyCoupon>>>;
  getRecurlyCoupons?: Maybe<Array<Maybe<RecurlyCoupon>>>;
  getRecurlyGiftCard: Array<Maybe<RecurlyGiftCard>>;
  getShopifyMultipassUrl?: Maybe<GetShopifyMultipassUrlRes>;
  getShopifyAccessToken?: Maybe<GetShopifyAccessTokenRes>;
  getSettings?: Maybe<Array<Maybe<SettingCustom>>>;
  getWatchTime?: Maybe<WatchTime>;
  getRecentEntity?: Maybe<Array<Maybe<GetRecentEntityRes>>>;
};


export type QueryActivityLogArgs = {
  where: ActivityLogWhereUniqueInput;
};


export type QueryActivityLogsArgs = {
  where?: Maybe<ActivityLogWhereInput>;
  orderBy?: Maybe<ActivityLogOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryActivityLogsConnectionArgs = {
  where?: Maybe<ActivityLogWhereInput>;
  orderBy?: Maybe<ActivityLogOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryArchiveMessageArgs = {
  where: ArchiveMessageWhereUniqueInput;
};


export type QueryArchiveMessagesArgs = {
  where?: Maybe<ArchiveMessageWhereInput>;
  orderBy?: Maybe<ArchiveMessageOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryArchiveMessagesConnectionArgs = {
  where?: Maybe<ArchiveMessageWhereInput>;
  orderBy?: Maybe<ArchiveMessageOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryBadgeArgs = {
  where: BadgeWhereUniqueInput;
};


export type QueryBadgesArgs = {
  where?: Maybe<BadgeWhereInput>;
  orderBy?: Maybe<BadgeOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryBadgesConnectionArgs = {
  where?: Maybe<BadgeWhereInput>;
  orderBy?: Maybe<BadgeOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryCommentArgs = {
  where: CommentWhereUniqueInput;
};


export type QueryCommentsArgs = {
  where?: Maybe<CommentWhereInput>;
  orderBy?: Maybe<CommentOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryCommentsConnectionArgs = {
  where?: Maybe<CommentWhereInput>;
  orderBy?: Maybe<CommentOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryConfigurationArgs = {
  where: ConfigurationWhereUniqueInput;
};


export type QueryConfigurationsArgs = {
  where?: Maybe<ConfigurationWhereInput>;
  orderBy?: Maybe<ConfigurationOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryConfigurationsConnectionArgs = {
  where?: Maybe<ConfigurationWhereInput>;
  orderBy?: Maybe<ConfigurationOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDiscussionArgs = {
  where: DiscussionWhereUniqueInput;
};


export type QueryDiscussionsArgs = {
  where?: Maybe<DiscussionWhereInput>;
  orderBy?: Maybe<DiscussionOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDiscussionsConnectionArgs = {
  where?: Maybe<DiscussionWhereInput>;
  orderBy?: Maybe<DiscussionOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryEmailChangeRequestArgs = {
  where: EmailChangeRequestWhereUniqueInput;
};


export type QueryEmailChangeRequestsArgs = {
  where?: Maybe<EmailChangeRequestWhereInput>;
  orderBy?: Maybe<EmailChangeRequestOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryEmailChangeRequestsConnectionArgs = {
  where?: Maybe<EmailChangeRequestWhereInput>;
  orderBy?: Maybe<EmailChangeRequestOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryMessageArgs = {
  where: MessageWhereUniqueInput;
};


export type QueryMessagesArgs = {
  where?: Maybe<MessageWhereInput>;
  orderBy?: Maybe<MessageOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryMessagesConnectionArgs = {
  where?: Maybe<MessageWhereInput>;
  orderBy?: Maybe<MessageOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryNotificationArgs = {
  where: NotificationWhereUniqueInput;
};


export type QueryNotificationsArgs = {
  where?: Maybe<NotificationWhereInput>;
  orderBy?: Maybe<NotificationOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryNotificationsConnectionArgs = {
  where?: Maybe<NotificationWhereInput>;
  orderBy?: Maybe<NotificationOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryNotificationUserArgs = {
  where: NotificationUserWhereUniqueInput;
};


export type QueryNotificationUsersArgs = {
  where?: Maybe<NotificationUserWhereInput>;
  orderBy?: Maybe<NotificationUserOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryNotificationUsersConnectionArgs = {
  where?: Maybe<NotificationUserWhereInput>;
  orderBy?: Maybe<NotificationUserOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryOneSignalPersonArgs = {
  where: OneSignalPersonWhereUniqueInput;
};


export type QueryOneSignalPersonsArgs = {
  where?: Maybe<OneSignalPersonWhereInput>;
  orderBy?: Maybe<OneSignalPersonOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryOneSignalPersonsConnectionArgs = {
  where?: Maybe<OneSignalPersonWhereInput>;
  orderBy?: Maybe<OneSignalPersonOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryOrderArgs = {
  where: OrderWhereUniqueInput;
};


export type QueryOrdersArgs = {
  where?: Maybe<OrderWhereInput>;
  orderBy?: Maybe<OrderOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryOrdersConnectionArgs = {
  where?: Maybe<OrderWhereInput>;
  orderBy?: Maybe<OrderOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryPermissionArgs = {
  where: PermissionWhereUniqueInput;
};


export type QueryPermissionsArgs = {
  where?: Maybe<PermissionWhereInput>;
  orderBy?: Maybe<PermissionOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryPermissionsConnectionArgs = {
  where?: Maybe<PermissionWhereInput>;
  orderBy?: Maybe<PermissionOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryPersonArgs = {
  where: PersonWhereUniqueInput;
};


export type QueryPersonsArgs = {
  where?: Maybe<PersonWhereInput>;
  orderBy?: Maybe<PersonOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryPersonsConnectionArgs = {
  where?: Maybe<PersonWhereInput>;
  orderBy?: Maybe<PersonOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryPersonPermissionArgs = {
  where: PersonPermissionWhereUniqueInput;
};


export type QueryPersonPermissionsArgs = {
  where?: Maybe<PersonPermissionWhereInput>;
  orderBy?: Maybe<PersonPermissionOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryPersonPermissionsConnectionArgs = {
  where?: Maybe<PersonPermissionWhereInput>;
  orderBy?: Maybe<PersonPermissionOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryPostArgs = {
  where: PostWhereUniqueInput;
};


export type QueryPostsArgs = {
  where?: Maybe<PostWhereInput>;
  orderBy?: Maybe<PostOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryPostsConnectionArgs = {
  where?: Maybe<PostWhereInput>;
  orderBy?: Maybe<PostOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryPostVideoArgs = {
  where: PostVideoWhereUniqueInput;
};


export type QueryPostVideosArgs = {
  where?: Maybe<PostVideoWhereInput>;
  orderBy?: Maybe<PostVideoOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryPostVideosConnectionArgs = {
  where?: Maybe<PostVideoWhereInput>;
  orderBy?: Maybe<PostVideoOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryRecurlyErrorArgs = {
  where: RecurlyErrorWhereUniqueInput;
};


export type QueryRecurlyErrorsArgs = {
  where?: Maybe<RecurlyErrorWhereInput>;
  orderBy?: Maybe<RecurlyErrorOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryRecurlyErrorsConnectionArgs = {
  where?: Maybe<RecurlyErrorWhereInput>;
  orderBy?: Maybe<RecurlyErrorOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryRecurlyWebhookArgs = {
  where: RecurlyWebhookWhereUniqueInput;
};


export type QueryRecurlyWebhooksArgs = {
  where?: Maybe<RecurlyWebhookWhereInput>;
  orderBy?: Maybe<RecurlyWebhookOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryRecurlyWebhooksConnectionArgs = {
  where?: Maybe<RecurlyWebhookWhereInput>;
  orderBy?: Maybe<RecurlyWebhookOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QuerySettingArgs = {
  where: SettingWhereUniqueInput;
};


export type QuerySettingsArgs = {
  where?: Maybe<SettingWhereInput>;
  orderBy?: Maybe<SettingOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QuerySettingsConnectionArgs = {
  where?: Maybe<SettingWhereInput>;
  orderBy?: Maybe<SettingOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryShopifyWebhookArgs = {
  where: ShopifyWebhookWhereUniqueInput;
};


export type QueryShopifyWebhooksArgs = {
  where?: Maybe<ShopifyWebhookWhereInput>;
  orderBy?: Maybe<ShopifyWebhookOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryShopifyWebhooksConnectionArgs = {
  where?: Maybe<ShopifyWebhookWhereInput>;
  orderBy?: Maybe<ShopifyWebhookOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryTemplateArgs = {
  where: TemplateWhereUniqueInput;
};


export type QueryTemplatesArgs = {
  where?: Maybe<TemplateWhereInput>;
  orderBy?: Maybe<TemplateOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryTemplatesConnectionArgs = {
  where?: Maybe<TemplateWhereInput>;
  orderBy?: Maybe<TemplateOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryTemplatePermissionArgs = {
  where: TemplatePermissionWhereUniqueInput;
};


export type QueryTemplatePermissionsArgs = {
  where?: Maybe<TemplatePermissionWhereInput>;
  orderBy?: Maybe<TemplatePermissionOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryTemplatePermissionsConnectionArgs = {
  where?: Maybe<TemplatePermissionWhereInput>;
  orderBy?: Maybe<TemplatePermissionOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryUserBadgeArgs = {
  where: UserBadgeWhereUniqueInput;
};


export type QueryUserBadgesArgs = {
  where?: Maybe<UserBadgeWhereInput>;
  orderBy?: Maybe<UserBadgeOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryUserBadgesConnectionArgs = {
  where?: Maybe<UserBadgeWhereInput>;
  orderBy?: Maybe<UserBadgeOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryUserSettingArgs = {
  where: UserSettingWhereUniqueInput;
};


export type QueryUserSettingsArgs = {
  where?: Maybe<UserSettingWhereInput>;
  orderBy?: Maybe<UserSettingOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryUserSettingsConnectionArgs = {
  where?: Maybe<UserSettingWhereInput>;
  orderBy?: Maybe<UserSettingOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryWatchTimeArgs = {
  where: WatchTimeWhereUniqueInput;
};


export type QueryWatchTimesArgs = {
  where?: Maybe<WatchTimeWhereInput>;
  orderBy?: Maybe<WatchTimeOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryWatchTimesConnectionArgs = {
  where?: Maybe<WatchTimeWhereInput>;
  orderBy?: Maybe<WatchTimeOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryNodeArgs = {
  id: Scalars['ID'];
};


export type QueryGetBadgeSignedPutUrlArgs = {
  data: SignedUrlDataInput;
};


export type QueryGetUserBadgesArgs = {
  orderBy?: Maybe<BadgeOrderEnum>;
};


export type QueryCommentThreadArgs = {
  where: CommentThreadWhereInput;
  orderBy?: Maybe<ListCommentOrder>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


export type QueryNoteworthyCommentsArgs = {
  where?: Maybe<NoteworthyCommentsInput>;
};


export type QueryGetCommentImageSignedPutUrlArgs = {
  data: CommentImageSignedPutUrlDataInput;
};


export type QueryListUserNameArgs = {
  where: ListUserNameWhereInput;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
};


export type QueryDiscussionThreadArgs = {
  where: CustomDiscussionWhereUniqueInput;
  orderBy?: Maybe<CustomDiscussionThreadOrderByInput>;
};


export type QueryGetLiveDiscussionsArgs = {
  where?: Maybe<LiveDiscussionWhereInput>;
};


export type QueryCommentModerationArgs = {
  where: CommentWhereUniqueInput;
};


export type QueryListPostModerationArgs = {
  where?: Maybe<ListPostModerationWhereInput>;
  orderBy?: Maybe<ListPostModerationOrder>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryGetPostModerationArgs = {
  where: PostWhereUniqueInput;
};


export type QueryCommentThreadModerationArgs = {
  where: CommentThreadModerationWhereInput;
  orderBy?: Maybe<CommentThreadModerationOrderBy>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


export type QueryListCommentsModerationArgs = {
  where?: Maybe<ListCommentsModerationWhereInput>;
  orderBy?: Maybe<ListCommentsModerationOrderBy>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


export type QueryListCommentPersonModerationArgs = {
  where?: Maybe<ListCommentPersonWhereInput>;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
};


export type QueryGetCurrentPersonPermissionArgs = {
  where?: Maybe<GetPersonPermissionWhereInput>;
};


export type QueryGetPersonPermissionArgs = {
  where: GetPersonPermissionWhereInput;
};


export type QueryGetPersonProfileSignedPutUrlArgs = {
  data?: Maybe<SignedUrlDataInput>;
};


export type QuerySyncRecurlyPlanArgs = {
  where: SyncRecurlyIdInput;
};


export type QueryIsUserNameAvailableArgs = {
  username: Scalars['String'];
};


export type QueryGetPersonDetailsArgs = {
  orderBy?: Maybe<BadgeOrderEnum>;
};


export type QueryGetPostImageSignedPutUrlArgs = {
  data: PostImageSignedPutUrlDataInput;
};


export type QueryGetPostVideoUploadSignedUrlArgs = {
  data?: Maybe<PostVideoUploadSignedUrlDataInput>;
};


export type QueryListPostArgs = {
  where?: Maybe<PostWhereInput>;
  orderBy?: Maybe<ListPostOrder>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryListAuthorArgs = {
  where?: Maybe<ListAuthorWhereInput>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryGetRecurlyAccountArgs = {
  where?: Maybe<RecurlyIdInput>;
};


export type QueryGetRecurlyAccountsArgs = {
  where?: Maybe<RecurlyLimitInput>;
};


export type QueryGetRecurlyShippingAddressArgs = {
  where?: Maybe<RecurlyAccountCodeInput>;
};


export type QueryGetRecurlySubscriptionArgs = {
  where: RecurlyIdInput;
};


export type QueryGetRecurlySubscriptionsArgs = {
  where?: Maybe<RecurlySubscriptionsInput>;
};


export type QueryGetRecurlyAllSubscriptionsArgs = {
  where?: Maybe<RecurlyLimitInput>;
};


export type QueryGetRecurlyInvoiceArgs = {
  where: RecurlyIdInput;
};


export type QueryGetRecurlyInvoicesArgs = {
  where?: Maybe<RecurlyInvoicesInput>;
};


export type QueryGetRecurlyAllInvoicesArgs = {
  where?: Maybe<RecurlyLimitInput>;
};


export type QueryGetRecurlyBillingInfoArgs = {
  where?: Maybe<RecurlyIdInput>;
};


export type QueryGetRecurlyPlanArgs = {
  where: RecurlyIdInput;
};


export type QueryGetRecurlyPlansArgs = {
  where?: Maybe<RecurlyLimitInput>;
};


export type QueryGetRecurlyCouponArgs = {
  where: RecurlyIdInput;
};


export type QueryGetRecurlyCouponsArgs = {
  where?: Maybe<RecurlyLimitInput>;
};


export type QueryGetRecurlyGiftCardArgs = {
  where: RecurlyIdInput;
};


export type QueryGetShopifyMultipassUrlArgs = {
  returnTo: Scalars['String'];
};


export type QueryGetShopifyAccessTokenArgs = {
  returnTo: Scalars['String'];
};


export type QueryGetSettingsArgs = {
  where?: Maybe<GetSettingWhereInput>;
};


export type QueryGetWatchTimeArgs = {
  where: GetWatchTimeInput;
};


export type QueryGetRecentEntityArgs = {
  where?: Maybe<GetRecentEntityWhereInput>;
  limit?: Maybe<Scalars['Int']>;
};

export type ActivityLogWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type ActivityLog = {
  __typename?: 'ActivityLog';
  id: Scalars['ID'];
  referenceId?: Maybe<Scalars['String']>;
  referenceType?: Maybe<ReferenceType>;
  action?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  clientDetails?: Maybe<Scalars['Json']>;
  updatedBy?: Maybe<Person>;
  beforeObj?: Maybe<Scalars['Json']>;
  afterObj?: Maybe<Scalars['Json']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export enum ReferenceType {
  Discussion = 'DISCUSSION',
  Person = 'PERSON',
  Badge = 'BADGE',
  Comment = 'COMMENT',
  Post = 'POST'
}


export type Person = {
  __typename?: 'Person';
  id: Scalars['ID'];
  email: Scalars['String'];
  userName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  profileImage?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  authZeroID?: Maybe<Scalars['String']>;
  recurlyId?: Maybe<Scalars['String']>;
  recurlyPlan?: Maybe<Plans>;
  promoPlan?: Maybe<Plans>;
  promoPlanExpiration?: Maybe<Scalars['DateTime']>;
  permissionTemplate?: Maybe<Template>;
  role: Role;
  badgeId?: Maybe<Scalars['ID']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isBanned?: Maybe<Scalars['Boolean']>;
  lastActiveOn?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  details?: Maybe<ModerationPersonDetails>;
  settings?: Maybe<Array<Maybe<SettingCustom>>>;
};

export enum Plans {
  Free = 'FREE',
  Reader = 'READER',
  Insider = 'INSIDER',
  InsiderPlus = 'INSIDER_PLUS',
  AllAccess = 'ALL_ACCESS'
}


export type Template = {
  __typename?: 'Template';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  permissions?: Maybe<Array<TemplatePermission>>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  message?: Maybe<Scalars['String']>;
};


export type TemplatePermissionsArgs = {
  where?: Maybe<TemplatePermissionWhereInput>;
  orderBy?: Maybe<TemplatePermissionOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type TemplatePermissionWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  permission?: Maybe<PermissionWhereInput>;
  template?: Maybe<TemplateWhereInput>;
  canRead?: Maybe<Scalars['Boolean']>;
  canRead_not?: Maybe<Scalars['Boolean']>;
  canMutate?: Maybe<Scalars['Boolean']>;
  canMutate_not?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<TemplatePermissionWhereInput>>;
  OR?: Maybe<Array<TemplatePermissionWhereInput>>;
  NOT?: Maybe<Array<TemplatePermissionWhereInput>>;
};

export type PermissionWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  key?: Maybe<Scalars['String']>;
  key_not?: Maybe<Scalars['String']>;
  key_in?: Maybe<Array<Scalars['String']>>;
  key_not_in?: Maybe<Array<Scalars['String']>>;
  key_lt?: Maybe<Scalars['String']>;
  key_lte?: Maybe<Scalars['String']>;
  key_gt?: Maybe<Scalars['String']>;
  key_gte?: Maybe<Scalars['String']>;
  key_contains?: Maybe<Scalars['String']>;
  key_not_contains?: Maybe<Scalars['String']>;
  key_starts_with?: Maybe<Scalars['String']>;
  key_not_starts_with?: Maybe<Scalars['String']>;
  key_ends_with?: Maybe<Scalars['String']>;
  key_not_ends_with?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Scalars['String']>>;
  name_not_in?: Maybe<Array<Scalars['String']>>;
  name_lt?: Maybe<Scalars['String']>;
  name_lte?: Maybe<Scalars['String']>;
  name_gt?: Maybe<Scalars['String']>;
  name_gte?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<PermissionWhereInput>>;
  OR?: Maybe<Array<PermissionWhereInput>>;
  NOT?: Maybe<Array<PermissionWhereInput>>;
};

export type TemplateWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Scalars['String']>>;
  name_not_in?: Maybe<Array<Scalars['String']>>;
  name_lt?: Maybe<Scalars['String']>;
  name_lte?: Maybe<Scalars['String']>;
  name_gt?: Maybe<Scalars['String']>;
  name_gte?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  permissions_every?: Maybe<TemplatePermissionWhereInput>;
  permissions_some?: Maybe<TemplatePermissionWhereInput>;
  permissions_none?: Maybe<TemplatePermissionWhereInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<TemplateWhereInput>>;
  OR?: Maybe<Array<TemplateWhereInput>>;
  NOT?: Maybe<Array<TemplateWhereInput>>;
};

export enum TemplatePermissionOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CanReadAsc = 'canRead_ASC',
  CanReadDesc = 'canRead_DESC',
  CanMutateAsc = 'canMutate_ASC',
  CanMutateDesc = 'canMutate_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type TemplatePermission = {
  __typename?: 'TemplatePermission';
  id: Scalars['ID'];
  permission?: Maybe<Permission>;
  template: Template;
  canRead?: Maybe<Scalars['Boolean']>;
  canMutate?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type Permission = {
  __typename?: 'Permission';
  id: Scalars['ID'];
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export enum Role {
  User = 'USER',
  Admin = 'ADMIN',
  Author = 'AUTHOR',
  GuestAuthor = 'GUEST_AUTHOR',
  Moderator = 'MODERATOR'
}

export type ModerationPersonDetails = {
  __typename?: 'ModerationPersonDetails';
  commentCount?: Maybe<Scalars['Int']>;
  likeCount?: Maybe<Scalars['Int']>;
  flagCount?: Maybe<Scalars['Int']>;
  hiddenCommentCount?: Maybe<Scalars['Int']>;
  starCount?: Maybe<Scalars['Int']>;
  postCount?: Maybe<Scalars['Int']>;
  lastPostedOn?: Maybe<Scalars['DateTime']>;
};

export type SettingCustom = {
  __typename?: 'SettingCustom';
  id?: Maybe<Scalars['ID']>;
  key?: Maybe<Scalars['String']>;
  type?: Maybe<SettingType>;
  title?: Maybe<Scalars['String']>;
  group?: Maybe<GroupType>;
  value?: Maybe<Scalars['String']>;
  defaultValue?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export enum SettingType {
  Boolean = 'BOOLEAN',
  String = 'STRING'
}

export enum GroupType {
  Notification = 'NOTIFICATION'
}

export type ActivityLogWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  referenceId?: Maybe<Scalars['String']>;
  referenceId_not?: Maybe<Scalars['String']>;
  referenceId_in?: Maybe<Array<Scalars['String']>>;
  referenceId_not_in?: Maybe<Array<Scalars['String']>>;
  referenceId_lt?: Maybe<Scalars['String']>;
  referenceId_lte?: Maybe<Scalars['String']>;
  referenceId_gt?: Maybe<Scalars['String']>;
  referenceId_gte?: Maybe<Scalars['String']>;
  referenceId_contains?: Maybe<Scalars['String']>;
  referenceId_not_contains?: Maybe<Scalars['String']>;
  referenceId_starts_with?: Maybe<Scalars['String']>;
  referenceId_not_starts_with?: Maybe<Scalars['String']>;
  referenceId_ends_with?: Maybe<Scalars['String']>;
  referenceId_not_ends_with?: Maybe<Scalars['String']>;
  referenceType?: Maybe<ReferenceType>;
  referenceType_not?: Maybe<ReferenceType>;
  referenceType_in?: Maybe<Array<ReferenceType>>;
  referenceType_not_in?: Maybe<Array<ReferenceType>>;
  action?: Maybe<Scalars['String']>;
  action_not?: Maybe<Scalars['String']>;
  action_in?: Maybe<Array<Scalars['String']>>;
  action_not_in?: Maybe<Array<Scalars['String']>>;
  action_lt?: Maybe<Scalars['String']>;
  action_lte?: Maybe<Scalars['String']>;
  action_gt?: Maybe<Scalars['String']>;
  action_gte?: Maybe<Scalars['String']>;
  action_contains?: Maybe<Scalars['String']>;
  action_not_contains?: Maybe<Scalars['String']>;
  action_starts_with?: Maybe<Scalars['String']>;
  action_not_starts_with?: Maybe<Scalars['String']>;
  action_ends_with?: Maybe<Scalars['String']>;
  action_not_ends_with?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Scalars['String']>>;
  title_not_in?: Maybe<Array<Scalars['String']>>;
  title_lt?: Maybe<Scalars['String']>;
  title_lte?: Maybe<Scalars['String']>;
  title_gt?: Maybe<Scalars['String']>;
  title_gte?: Maybe<Scalars['String']>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  title_starts_with?: Maybe<Scalars['String']>;
  title_not_starts_with?: Maybe<Scalars['String']>;
  title_ends_with?: Maybe<Scalars['String']>;
  title_not_ends_with?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  content_not?: Maybe<Scalars['String']>;
  content_in?: Maybe<Array<Scalars['String']>>;
  content_not_in?: Maybe<Array<Scalars['String']>>;
  content_lt?: Maybe<Scalars['String']>;
  content_lte?: Maybe<Scalars['String']>;
  content_gt?: Maybe<Scalars['String']>;
  content_gte?: Maybe<Scalars['String']>;
  content_contains?: Maybe<Scalars['String']>;
  content_not_contains?: Maybe<Scalars['String']>;
  content_starts_with?: Maybe<Scalars['String']>;
  content_not_starts_with?: Maybe<Scalars['String']>;
  content_ends_with?: Maybe<Scalars['String']>;
  content_not_ends_with?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  type_not?: Maybe<Scalars['String']>;
  type_in?: Maybe<Array<Scalars['String']>>;
  type_not_in?: Maybe<Array<Scalars['String']>>;
  type_lt?: Maybe<Scalars['String']>;
  type_lte?: Maybe<Scalars['String']>;
  type_gt?: Maybe<Scalars['String']>;
  type_gte?: Maybe<Scalars['String']>;
  type_contains?: Maybe<Scalars['String']>;
  type_not_contains?: Maybe<Scalars['String']>;
  type_starts_with?: Maybe<Scalars['String']>;
  type_not_starts_with?: Maybe<Scalars['String']>;
  type_ends_with?: Maybe<Scalars['String']>;
  type_not_ends_with?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<PersonWhereInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<ActivityLogWhereInput>>;
  OR?: Maybe<Array<ActivityLogWhereInput>>;
  NOT?: Maybe<Array<ActivityLogWhereInput>>;
};

export type PersonWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  email?: Maybe<Scalars['String']>;
  email_not?: Maybe<Scalars['String']>;
  email_in?: Maybe<Array<Scalars['String']>>;
  email_not_in?: Maybe<Array<Scalars['String']>>;
  email_lt?: Maybe<Scalars['String']>;
  email_lte?: Maybe<Scalars['String']>;
  email_gt?: Maybe<Scalars['String']>;
  email_gte?: Maybe<Scalars['String']>;
  email_contains?: Maybe<Scalars['String']>;
  email_not_contains?: Maybe<Scalars['String']>;
  email_starts_with?: Maybe<Scalars['String']>;
  email_not_starts_with?: Maybe<Scalars['String']>;
  email_ends_with?: Maybe<Scalars['String']>;
  email_not_ends_with?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
  userName_not?: Maybe<Scalars['String']>;
  userName_in?: Maybe<Array<Scalars['String']>>;
  userName_not_in?: Maybe<Array<Scalars['String']>>;
  userName_lt?: Maybe<Scalars['String']>;
  userName_lte?: Maybe<Scalars['String']>;
  userName_gt?: Maybe<Scalars['String']>;
  userName_gte?: Maybe<Scalars['String']>;
  userName_contains?: Maybe<Scalars['String']>;
  userName_not_contains?: Maybe<Scalars['String']>;
  userName_starts_with?: Maybe<Scalars['String']>;
  userName_not_starts_with?: Maybe<Scalars['String']>;
  userName_ends_with?: Maybe<Scalars['String']>;
  userName_not_ends_with?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  firstName_not?: Maybe<Scalars['String']>;
  firstName_in?: Maybe<Array<Scalars['String']>>;
  firstName_not_in?: Maybe<Array<Scalars['String']>>;
  firstName_lt?: Maybe<Scalars['String']>;
  firstName_lte?: Maybe<Scalars['String']>;
  firstName_gt?: Maybe<Scalars['String']>;
  firstName_gte?: Maybe<Scalars['String']>;
  firstName_contains?: Maybe<Scalars['String']>;
  firstName_not_contains?: Maybe<Scalars['String']>;
  firstName_starts_with?: Maybe<Scalars['String']>;
  firstName_not_starts_with?: Maybe<Scalars['String']>;
  firstName_ends_with?: Maybe<Scalars['String']>;
  firstName_not_ends_with?: Maybe<Scalars['String']>;
  profileImage?: Maybe<Scalars['String']>;
  profileImage_not?: Maybe<Scalars['String']>;
  profileImage_in?: Maybe<Array<Scalars['String']>>;
  profileImage_not_in?: Maybe<Array<Scalars['String']>>;
  profileImage_lt?: Maybe<Scalars['String']>;
  profileImage_lte?: Maybe<Scalars['String']>;
  profileImage_gt?: Maybe<Scalars['String']>;
  profileImage_gte?: Maybe<Scalars['String']>;
  profileImage_contains?: Maybe<Scalars['String']>;
  profileImage_not_contains?: Maybe<Scalars['String']>;
  profileImage_starts_with?: Maybe<Scalars['String']>;
  profileImage_not_starts_with?: Maybe<Scalars['String']>;
  profileImage_ends_with?: Maybe<Scalars['String']>;
  profileImage_not_ends_with?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  lastName_not?: Maybe<Scalars['String']>;
  lastName_in?: Maybe<Array<Scalars['String']>>;
  lastName_not_in?: Maybe<Array<Scalars['String']>>;
  lastName_lt?: Maybe<Scalars['String']>;
  lastName_lte?: Maybe<Scalars['String']>;
  lastName_gt?: Maybe<Scalars['String']>;
  lastName_gte?: Maybe<Scalars['String']>;
  lastName_contains?: Maybe<Scalars['String']>;
  lastName_not_contains?: Maybe<Scalars['String']>;
  lastName_starts_with?: Maybe<Scalars['String']>;
  lastName_not_starts_with?: Maybe<Scalars['String']>;
  lastName_ends_with?: Maybe<Scalars['String']>;
  lastName_not_ends_with?: Maybe<Scalars['String']>;
  authZeroID?: Maybe<Scalars['String']>;
  authZeroID_not?: Maybe<Scalars['String']>;
  authZeroID_in?: Maybe<Array<Scalars['String']>>;
  authZeroID_not_in?: Maybe<Array<Scalars['String']>>;
  authZeroID_lt?: Maybe<Scalars['String']>;
  authZeroID_lte?: Maybe<Scalars['String']>;
  authZeroID_gt?: Maybe<Scalars['String']>;
  authZeroID_gte?: Maybe<Scalars['String']>;
  authZeroID_contains?: Maybe<Scalars['String']>;
  authZeroID_not_contains?: Maybe<Scalars['String']>;
  authZeroID_starts_with?: Maybe<Scalars['String']>;
  authZeroID_not_starts_with?: Maybe<Scalars['String']>;
  authZeroID_ends_with?: Maybe<Scalars['String']>;
  authZeroID_not_ends_with?: Maybe<Scalars['String']>;
  recurlyId?: Maybe<Scalars['String']>;
  recurlyId_not?: Maybe<Scalars['String']>;
  recurlyId_in?: Maybe<Array<Scalars['String']>>;
  recurlyId_not_in?: Maybe<Array<Scalars['String']>>;
  recurlyId_lt?: Maybe<Scalars['String']>;
  recurlyId_lte?: Maybe<Scalars['String']>;
  recurlyId_gt?: Maybe<Scalars['String']>;
  recurlyId_gte?: Maybe<Scalars['String']>;
  recurlyId_contains?: Maybe<Scalars['String']>;
  recurlyId_not_contains?: Maybe<Scalars['String']>;
  recurlyId_starts_with?: Maybe<Scalars['String']>;
  recurlyId_not_starts_with?: Maybe<Scalars['String']>;
  recurlyId_ends_with?: Maybe<Scalars['String']>;
  recurlyId_not_ends_with?: Maybe<Scalars['String']>;
  recurlyPlan?: Maybe<Plans>;
  recurlyPlan_not?: Maybe<Plans>;
  recurlyPlan_in?: Maybe<Array<Plans>>;
  recurlyPlan_not_in?: Maybe<Array<Plans>>;
  promoPlan?: Maybe<Plans>;
  promoPlan_not?: Maybe<Plans>;
  promoPlan_in?: Maybe<Array<Plans>>;
  promoPlan_not_in?: Maybe<Array<Plans>>;
  promoPlanExpiration?: Maybe<Scalars['DateTime']>;
  promoPlanExpiration_not?: Maybe<Scalars['DateTime']>;
  promoPlanExpiration_in?: Maybe<Array<Scalars['DateTime']>>;
  promoPlanExpiration_not_in?: Maybe<Array<Scalars['DateTime']>>;
  promoPlanExpiration_lt?: Maybe<Scalars['DateTime']>;
  promoPlanExpiration_lte?: Maybe<Scalars['DateTime']>;
  promoPlanExpiration_gt?: Maybe<Scalars['DateTime']>;
  promoPlanExpiration_gte?: Maybe<Scalars['DateTime']>;
  permissionTemplate?: Maybe<TemplateWhereInput>;
  role?: Maybe<Role>;
  role_not?: Maybe<Role>;
  role_in?: Maybe<Array<Role>>;
  role_not_in?: Maybe<Array<Role>>;
  badgeId?: Maybe<Scalars['ID']>;
  badgeId_not?: Maybe<Scalars['ID']>;
  badgeId_in?: Maybe<Array<Scalars['ID']>>;
  badgeId_not_in?: Maybe<Array<Scalars['ID']>>;
  badgeId_lt?: Maybe<Scalars['ID']>;
  badgeId_lte?: Maybe<Scalars['ID']>;
  badgeId_gt?: Maybe<Scalars['ID']>;
  badgeId_gte?: Maybe<Scalars['ID']>;
  badgeId_contains?: Maybe<Scalars['ID']>;
  badgeId_not_contains?: Maybe<Scalars['ID']>;
  badgeId_starts_with?: Maybe<Scalars['ID']>;
  badgeId_not_starts_with?: Maybe<Scalars['ID']>;
  badgeId_ends_with?: Maybe<Scalars['ID']>;
  badgeId_not_ends_with?: Maybe<Scalars['ID']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isActive_not?: Maybe<Scalars['Boolean']>;
  isBanned?: Maybe<Scalars['Boolean']>;
  isBanned_not?: Maybe<Scalars['Boolean']>;
  lastActiveOn?: Maybe<Scalars['DateTime']>;
  lastActiveOn_not?: Maybe<Scalars['DateTime']>;
  lastActiveOn_in?: Maybe<Array<Scalars['DateTime']>>;
  lastActiveOn_not_in?: Maybe<Array<Scalars['DateTime']>>;
  lastActiveOn_lt?: Maybe<Scalars['DateTime']>;
  lastActiveOn_lte?: Maybe<Scalars['DateTime']>;
  lastActiveOn_gt?: Maybe<Scalars['DateTime']>;
  lastActiveOn_gte?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<PersonWhereInput>>;
  OR?: Maybe<Array<PersonWhereInput>>;
  NOT?: Maybe<Array<PersonWhereInput>>;
};

export enum ActivityLogOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  ReferenceIdAsc = 'referenceId_ASC',
  ReferenceIdDesc = 'referenceId_DESC',
  ReferenceTypeAsc = 'referenceType_ASC',
  ReferenceTypeDesc = 'referenceType_DESC',
  ActionAsc = 'action_ASC',
  ActionDesc = 'action_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  ContentAsc = 'content_ASC',
  ContentDesc = 'content_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  ClientDetailsAsc = 'clientDetails_ASC',
  ClientDetailsDesc = 'clientDetails_DESC',
  BeforeObjAsc = 'beforeObj_ASC',
  BeforeObjDesc = 'beforeObj_DESC',
  AfterObjAsc = 'afterObj_ASC',
  AfterObjDesc = 'afterObj_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type ActivityLogConnection = {
  __typename?: 'ActivityLogConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<ActivityLogEdge>>;
  aggregate: AggregateActivityLog;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
  endCursor?: Maybe<Scalars['String']>;
};

export type ActivityLogEdge = {
  __typename?: 'ActivityLogEdge';
  node: ActivityLog;
  cursor: Scalars['String'];
};

export type AggregateActivityLog = {
  __typename?: 'AggregateActivityLog';
  count: Scalars['Int'];
};

export type ArchiveMessageWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type ArchiveMessage = {
  __typename?: 'ArchiveMessage';
  id: Scalars['ID'];
  message: Scalars['String'];
  likes?: Maybe<Array<Person>>;
  discussion: Discussion;
  depth: Scalars['Int'];
  parent?: Maybe<ArchiveMessage>;
  isBlocked?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  flags?: Maybe<Array<Person>>;
  createdBy?: Maybe<Person>;
  userType?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};


export type ArchiveMessageLikesArgs = {
  where?: Maybe<PersonWhereInput>;
  orderBy?: Maybe<PersonOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type ArchiveMessageFlagsArgs = {
  where?: Maybe<PersonWhereInput>;
  orderBy?: Maybe<PersonOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export enum PersonOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
  UserNameAsc = 'userName_ASC',
  UserNameDesc = 'userName_DESC',
  FirstNameAsc = 'firstName_ASC',
  FirstNameDesc = 'firstName_DESC',
  ProfileImageAsc = 'profileImage_ASC',
  ProfileImageDesc = 'profileImage_DESC',
  LastNameAsc = 'lastName_ASC',
  LastNameDesc = 'lastName_DESC',
  AuthZeroIdAsc = 'authZeroID_ASC',
  AuthZeroIdDesc = 'authZeroID_DESC',
  RecurlyIdAsc = 'recurlyId_ASC',
  RecurlyIdDesc = 'recurlyId_DESC',
  RecurlyPlanAsc = 'recurlyPlan_ASC',
  RecurlyPlanDesc = 'recurlyPlan_DESC',
  PromoPlanAsc = 'promoPlan_ASC',
  PromoPlanDesc = 'promoPlan_DESC',
  PromoPlanExpirationAsc = 'promoPlanExpiration_ASC',
  PromoPlanExpirationDesc = 'promoPlanExpiration_DESC',
  RoleAsc = 'role_ASC',
  RoleDesc = 'role_DESC',
  BadgeIdAsc = 'badgeId_ASC',
  BadgeIdDesc = 'badgeId_DESC',
  IsActiveAsc = 'isActive_ASC',
  IsActiveDesc = 'isActive_DESC',
  IsBannedAsc = 'isBanned_ASC',
  IsBannedDesc = 'isBanned_DESC',
  LastActiveOnAsc = 'lastActiveOn_ASC',
  LastActiveOnDesc = 'lastActiveOn_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type Discussion = {
  __typename?: 'Discussion';
  id: Scalars['ID'];
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  isLive?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Discussion_Type>;
  isArchived?: Maybe<Scalars['Boolean']>;
  archiveStatus?: Maybe<Archive_Progress>;
  status?: Maybe<Scalars['String']>;
  scheduleAt?: Maybe<Scalars['DateTime']>;
  createdBy: Person;
  owner: Person;
  messages?: Maybe<Array<Message>>;
  archiveMessages?: Maybe<Array<ArchiveMessage>>;
  startTime?: Maybe<Scalars['DateTime']>;
  endTime?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  messagesCount?: Maybe<Scalars['Int']>;
};


export type DiscussionMessagesArgs = {
  where?: Maybe<MessageWhereInput>;
  orderBy?: Maybe<MessageOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DiscussionArchiveMessagesArgs = {
  where?: Maybe<ArchiveMessageWhereInput>;
  orderBy?: Maybe<ArchiveMessageOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export enum Discussion_Type {
  Episode = 'EPISODE',
  Discussion = 'DISCUSSION',
  Post = 'POST',
  Video = 'VIDEO'
}

export enum Archive_Progress {
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  Succeed = 'SUCCEED',
  Partial = 'PARTIAL'
}

export type MessageWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  message?: Maybe<Scalars['String']>;
  message_not?: Maybe<Scalars['String']>;
  message_in?: Maybe<Array<Scalars['String']>>;
  message_not_in?: Maybe<Array<Scalars['String']>>;
  message_lt?: Maybe<Scalars['String']>;
  message_lte?: Maybe<Scalars['String']>;
  message_gt?: Maybe<Scalars['String']>;
  message_gte?: Maybe<Scalars['String']>;
  message_contains?: Maybe<Scalars['String']>;
  message_not_contains?: Maybe<Scalars['String']>;
  message_starts_with?: Maybe<Scalars['String']>;
  message_not_starts_with?: Maybe<Scalars['String']>;
  message_ends_with?: Maybe<Scalars['String']>;
  message_not_ends_with?: Maybe<Scalars['String']>;
  likes_every?: Maybe<PersonWhereInput>;
  likes_some?: Maybe<PersonWhereInput>;
  likes_none?: Maybe<PersonWhereInput>;
  discussion?: Maybe<DiscussionWhereInput>;
  depth?: Maybe<Scalars['Int']>;
  depth_not?: Maybe<Scalars['Int']>;
  depth_in?: Maybe<Array<Scalars['Int']>>;
  depth_not_in?: Maybe<Array<Scalars['Int']>>;
  depth_lt?: Maybe<Scalars['Int']>;
  depth_lte?: Maybe<Scalars['Int']>;
  depth_gt?: Maybe<Scalars['Int']>;
  depth_gte?: Maybe<Scalars['Int']>;
  parent?: Maybe<MessageWhereInput>;
  isBlocked?: Maybe<Scalars['Boolean']>;
  isBlocked_not?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isDeleted_not?: Maybe<Scalars['Boolean']>;
  flags_every?: Maybe<PersonWhereInput>;
  flags_some?: Maybe<PersonWhereInput>;
  flags_none?: Maybe<PersonWhereInput>;
  createdBy?: Maybe<PersonWhereInput>;
  userType?: Maybe<Scalars['String']>;
  userType_not?: Maybe<Scalars['String']>;
  userType_in?: Maybe<Array<Scalars['String']>>;
  userType_not_in?: Maybe<Array<Scalars['String']>>;
  userType_lt?: Maybe<Scalars['String']>;
  userType_lte?: Maybe<Scalars['String']>;
  userType_gt?: Maybe<Scalars['String']>;
  userType_gte?: Maybe<Scalars['String']>;
  userType_contains?: Maybe<Scalars['String']>;
  userType_not_contains?: Maybe<Scalars['String']>;
  userType_starts_with?: Maybe<Scalars['String']>;
  userType_not_starts_with?: Maybe<Scalars['String']>;
  userType_ends_with?: Maybe<Scalars['String']>;
  userType_not_ends_with?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<MessageWhereInput>>;
  OR?: Maybe<Array<MessageWhereInput>>;
  NOT?: Maybe<Array<MessageWhereInput>>;
};

export type DiscussionWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Scalars['String']>>;
  title_not_in?: Maybe<Array<Scalars['String']>>;
  title_lt?: Maybe<Scalars['String']>;
  title_lte?: Maybe<Scalars['String']>;
  title_gt?: Maybe<Scalars['String']>;
  title_gte?: Maybe<Scalars['String']>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  title_starts_with?: Maybe<Scalars['String']>;
  title_not_starts_with?: Maybe<Scalars['String']>;
  title_ends_with?: Maybe<Scalars['String']>;
  title_not_ends_with?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  description_not?: Maybe<Scalars['String']>;
  description_in?: Maybe<Array<Scalars['String']>>;
  description_not_in?: Maybe<Array<Scalars['String']>>;
  description_lt?: Maybe<Scalars['String']>;
  description_lte?: Maybe<Scalars['String']>;
  description_gt?: Maybe<Scalars['String']>;
  description_gte?: Maybe<Scalars['String']>;
  description_contains?: Maybe<Scalars['String']>;
  description_not_contains?: Maybe<Scalars['String']>;
  description_starts_with?: Maybe<Scalars['String']>;
  description_not_starts_with?: Maybe<Scalars['String']>;
  description_ends_with?: Maybe<Scalars['String']>;
  description_not_ends_with?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  slug_not?: Maybe<Scalars['String']>;
  slug_in?: Maybe<Array<Scalars['String']>>;
  slug_not_in?: Maybe<Array<Scalars['String']>>;
  slug_lt?: Maybe<Scalars['String']>;
  slug_lte?: Maybe<Scalars['String']>;
  slug_gt?: Maybe<Scalars['String']>;
  slug_gte?: Maybe<Scalars['String']>;
  slug_contains?: Maybe<Scalars['String']>;
  slug_not_contains?: Maybe<Scalars['String']>;
  slug_starts_with?: Maybe<Scalars['String']>;
  slug_not_starts_with?: Maybe<Scalars['String']>;
  slug_ends_with?: Maybe<Scalars['String']>;
  slug_not_ends_with?: Maybe<Scalars['String']>;
  isLive?: Maybe<Scalars['Boolean']>;
  isLive_not?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Discussion_Type>;
  type_not?: Maybe<Discussion_Type>;
  type_in?: Maybe<Array<Discussion_Type>>;
  type_not_in?: Maybe<Array<Discussion_Type>>;
  isArchived?: Maybe<Scalars['Boolean']>;
  isArchived_not?: Maybe<Scalars['Boolean']>;
  archiveStatus?: Maybe<Archive_Progress>;
  archiveStatus_not?: Maybe<Archive_Progress>;
  archiveStatus_in?: Maybe<Array<Archive_Progress>>;
  archiveStatus_not_in?: Maybe<Array<Archive_Progress>>;
  status?: Maybe<Scalars['String']>;
  status_not?: Maybe<Scalars['String']>;
  status_in?: Maybe<Array<Scalars['String']>>;
  status_not_in?: Maybe<Array<Scalars['String']>>;
  status_lt?: Maybe<Scalars['String']>;
  status_lte?: Maybe<Scalars['String']>;
  status_gt?: Maybe<Scalars['String']>;
  status_gte?: Maybe<Scalars['String']>;
  status_contains?: Maybe<Scalars['String']>;
  status_not_contains?: Maybe<Scalars['String']>;
  status_starts_with?: Maybe<Scalars['String']>;
  status_not_starts_with?: Maybe<Scalars['String']>;
  status_ends_with?: Maybe<Scalars['String']>;
  status_not_ends_with?: Maybe<Scalars['String']>;
  scheduleAt?: Maybe<Scalars['DateTime']>;
  scheduleAt_not?: Maybe<Scalars['DateTime']>;
  scheduleAt_in?: Maybe<Array<Scalars['DateTime']>>;
  scheduleAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  scheduleAt_lt?: Maybe<Scalars['DateTime']>;
  scheduleAt_lte?: Maybe<Scalars['DateTime']>;
  scheduleAt_gt?: Maybe<Scalars['DateTime']>;
  scheduleAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<PersonWhereInput>;
  owner?: Maybe<PersonWhereInput>;
  messages_every?: Maybe<MessageWhereInput>;
  messages_some?: Maybe<MessageWhereInput>;
  messages_none?: Maybe<MessageWhereInput>;
  archiveMessages_every?: Maybe<ArchiveMessageWhereInput>;
  archiveMessages_some?: Maybe<ArchiveMessageWhereInput>;
  archiveMessages_none?: Maybe<ArchiveMessageWhereInput>;
  startTime?: Maybe<Scalars['DateTime']>;
  startTime_not?: Maybe<Scalars['DateTime']>;
  startTime_in?: Maybe<Array<Scalars['DateTime']>>;
  startTime_not_in?: Maybe<Array<Scalars['DateTime']>>;
  startTime_lt?: Maybe<Scalars['DateTime']>;
  startTime_lte?: Maybe<Scalars['DateTime']>;
  startTime_gt?: Maybe<Scalars['DateTime']>;
  startTime_gte?: Maybe<Scalars['DateTime']>;
  endTime?: Maybe<Scalars['DateTime']>;
  endTime_not?: Maybe<Scalars['DateTime']>;
  endTime_in?: Maybe<Array<Scalars['DateTime']>>;
  endTime_not_in?: Maybe<Array<Scalars['DateTime']>>;
  endTime_lt?: Maybe<Scalars['DateTime']>;
  endTime_lte?: Maybe<Scalars['DateTime']>;
  endTime_gt?: Maybe<Scalars['DateTime']>;
  endTime_gte?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<DiscussionWhereInput>>;
  OR?: Maybe<Array<DiscussionWhereInput>>;
  NOT?: Maybe<Array<DiscussionWhereInput>>;
};

export type ArchiveMessageWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  message?: Maybe<Scalars['String']>;
  message_not?: Maybe<Scalars['String']>;
  message_in?: Maybe<Array<Scalars['String']>>;
  message_not_in?: Maybe<Array<Scalars['String']>>;
  message_lt?: Maybe<Scalars['String']>;
  message_lte?: Maybe<Scalars['String']>;
  message_gt?: Maybe<Scalars['String']>;
  message_gte?: Maybe<Scalars['String']>;
  message_contains?: Maybe<Scalars['String']>;
  message_not_contains?: Maybe<Scalars['String']>;
  message_starts_with?: Maybe<Scalars['String']>;
  message_not_starts_with?: Maybe<Scalars['String']>;
  message_ends_with?: Maybe<Scalars['String']>;
  message_not_ends_with?: Maybe<Scalars['String']>;
  likes_every?: Maybe<PersonWhereInput>;
  likes_some?: Maybe<PersonWhereInput>;
  likes_none?: Maybe<PersonWhereInput>;
  discussion?: Maybe<DiscussionWhereInput>;
  depth?: Maybe<Scalars['Int']>;
  depth_not?: Maybe<Scalars['Int']>;
  depth_in?: Maybe<Array<Scalars['Int']>>;
  depth_not_in?: Maybe<Array<Scalars['Int']>>;
  depth_lt?: Maybe<Scalars['Int']>;
  depth_lte?: Maybe<Scalars['Int']>;
  depth_gt?: Maybe<Scalars['Int']>;
  depth_gte?: Maybe<Scalars['Int']>;
  parent?: Maybe<ArchiveMessageWhereInput>;
  isBlocked?: Maybe<Scalars['Boolean']>;
  isBlocked_not?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isDeleted_not?: Maybe<Scalars['Boolean']>;
  flags_every?: Maybe<PersonWhereInput>;
  flags_some?: Maybe<PersonWhereInput>;
  flags_none?: Maybe<PersonWhereInput>;
  createdBy?: Maybe<PersonWhereInput>;
  userType?: Maybe<Scalars['String']>;
  userType_not?: Maybe<Scalars['String']>;
  userType_in?: Maybe<Array<Scalars['String']>>;
  userType_not_in?: Maybe<Array<Scalars['String']>>;
  userType_lt?: Maybe<Scalars['String']>;
  userType_lte?: Maybe<Scalars['String']>;
  userType_gt?: Maybe<Scalars['String']>;
  userType_gte?: Maybe<Scalars['String']>;
  userType_contains?: Maybe<Scalars['String']>;
  userType_not_contains?: Maybe<Scalars['String']>;
  userType_starts_with?: Maybe<Scalars['String']>;
  userType_not_starts_with?: Maybe<Scalars['String']>;
  userType_ends_with?: Maybe<Scalars['String']>;
  userType_not_ends_with?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<ArchiveMessageWhereInput>>;
  OR?: Maybe<Array<ArchiveMessageWhereInput>>;
  NOT?: Maybe<Array<ArchiveMessageWhereInput>>;
};

export enum MessageOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  MessageAsc = 'message_ASC',
  MessageDesc = 'message_DESC',
  DepthAsc = 'depth_ASC',
  DepthDesc = 'depth_DESC',
  IsBlockedAsc = 'isBlocked_ASC',
  IsBlockedDesc = 'isBlocked_DESC',
  IsDeletedAsc = 'isDeleted_ASC',
  IsDeletedDesc = 'isDeleted_DESC',
  UserTypeAsc = 'userType_ASC',
  UserTypeDesc = 'userType_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type Message = {
  __typename?: 'Message';
  id: Scalars['ID'];
  message: Scalars['String'];
  likes?: Maybe<Array<Person>>;
  discussion: Discussion;
  depth: Scalars['Int'];
  parent?: Maybe<Message>;
  isBlocked?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  flags?: Maybe<Array<Person>>;
  createdBy?: Maybe<Person>;
  userType?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};


export type MessageLikesArgs = {
  where?: Maybe<PersonWhereInput>;
  orderBy?: Maybe<PersonOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type MessageFlagsArgs = {
  where?: Maybe<PersonWhereInput>;
  orderBy?: Maybe<PersonOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export enum ArchiveMessageOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  MessageAsc = 'message_ASC',
  MessageDesc = 'message_DESC',
  DepthAsc = 'depth_ASC',
  DepthDesc = 'depth_DESC',
  IsBlockedAsc = 'isBlocked_ASC',
  IsBlockedDesc = 'isBlocked_DESC',
  IsDeletedAsc = 'isDeleted_ASC',
  IsDeletedDesc = 'isDeleted_DESC',
  UserTypeAsc = 'userType_ASC',
  UserTypeDesc = 'userType_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type ArchiveMessageConnection = {
  __typename?: 'ArchiveMessageConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<ArchiveMessageEdge>>;
  aggregate: AggregateArchiveMessage;
};

export type ArchiveMessageEdge = {
  __typename?: 'ArchiveMessageEdge';
  node: ArchiveMessage;
  cursor: Scalars['String'];
};

export type AggregateArchiveMessage = {
  __typename?: 'AggregateArchiveMessage';
  count: Scalars['Int'];
};

export type BadgeWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
  slug?: Maybe<Scalars['String']>;
};

export type Badge = {
  __typename?: 'Badge';
  id: Scalars['ID'];
  name: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type: BadgeType;
  weight?: Maybe<Scalars['Int']>;
  defaultPlaceHolderImage?: Maybe<Scalars['String']>;
  isCustom?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  canAssignUsingScript?: Maybe<Scalars['Boolean']>;
  shopifySkuList: Array<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export enum BadgeType {
  Generic = 'GENERIC',
  Subscription = 'SUBSCRIPTION',
  Default = 'DEFAULT',
  Restricted = 'RESTRICTED',
  ShopifyRewarded = 'SHOPIFY_REWARDED'
}

export type BadgeWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Scalars['String']>>;
  name_not_in?: Maybe<Array<Scalars['String']>>;
  name_lt?: Maybe<Scalars['String']>;
  name_lte?: Maybe<Scalars['String']>;
  name_gt?: Maybe<Scalars['String']>;
  name_gte?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  slug_not?: Maybe<Scalars['String']>;
  slug_in?: Maybe<Array<Scalars['String']>>;
  slug_not_in?: Maybe<Array<Scalars['String']>>;
  slug_lt?: Maybe<Scalars['String']>;
  slug_lte?: Maybe<Scalars['String']>;
  slug_gt?: Maybe<Scalars['String']>;
  slug_gte?: Maybe<Scalars['String']>;
  slug_contains?: Maybe<Scalars['String']>;
  slug_not_contains?: Maybe<Scalars['String']>;
  slug_starts_with?: Maybe<Scalars['String']>;
  slug_not_starts_with?: Maybe<Scalars['String']>;
  slug_ends_with?: Maybe<Scalars['String']>;
  slug_not_ends_with?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  image_not?: Maybe<Scalars['String']>;
  image_in?: Maybe<Array<Scalars['String']>>;
  image_not_in?: Maybe<Array<Scalars['String']>>;
  image_lt?: Maybe<Scalars['String']>;
  image_lte?: Maybe<Scalars['String']>;
  image_gt?: Maybe<Scalars['String']>;
  image_gte?: Maybe<Scalars['String']>;
  image_contains?: Maybe<Scalars['String']>;
  image_not_contains?: Maybe<Scalars['String']>;
  image_starts_with?: Maybe<Scalars['String']>;
  image_not_starts_with?: Maybe<Scalars['String']>;
  image_ends_with?: Maybe<Scalars['String']>;
  image_not_ends_with?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  description_not?: Maybe<Scalars['String']>;
  description_in?: Maybe<Array<Scalars['String']>>;
  description_not_in?: Maybe<Array<Scalars['String']>>;
  description_lt?: Maybe<Scalars['String']>;
  description_lte?: Maybe<Scalars['String']>;
  description_gt?: Maybe<Scalars['String']>;
  description_gte?: Maybe<Scalars['String']>;
  description_contains?: Maybe<Scalars['String']>;
  description_not_contains?: Maybe<Scalars['String']>;
  description_starts_with?: Maybe<Scalars['String']>;
  description_not_starts_with?: Maybe<Scalars['String']>;
  description_ends_with?: Maybe<Scalars['String']>;
  description_not_ends_with?: Maybe<Scalars['String']>;
  type?: Maybe<BadgeType>;
  type_not?: Maybe<BadgeType>;
  type_in?: Maybe<Array<BadgeType>>;
  type_not_in?: Maybe<Array<BadgeType>>;
  weight?: Maybe<Scalars['Int']>;
  weight_not?: Maybe<Scalars['Int']>;
  weight_in?: Maybe<Array<Scalars['Int']>>;
  weight_not_in?: Maybe<Array<Scalars['Int']>>;
  weight_lt?: Maybe<Scalars['Int']>;
  weight_lte?: Maybe<Scalars['Int']>;
  weight_gt?: Maybe<Scalars['Int']>;
  weight_gte?: Maybe<Scalars['Int']>;
  defaultPlaceHolderImage?: Maybe<Scalars['String']>;
  defaultPlaceHolderImage_not?: Maybe<Scalars['String']>;
  defaultPlaceHolderImage_in?: Maybe<Array<Scalars['String']>>;
  defaultPlaceHolderImage_not_in?: Maybe<Array<Scalars['String']>>;
  defaultPlaceHolderImage_lt?: Maybe<Scalars['String']>;
  defaultPlaceHolderImage_lte?: Maybe<Scalars['String']>;
  defaultPlaceHolderImage_gt?: Maybe<Scalars['String']>;
  defaultPlaceHolderImage_gte?: Maybe<Scalars['String']>;
  defaultPlaceHolderImage_contains?: Maybe<Scalars['String']>;
  defaultPlaceHolderImage_not_contains?: Maybe<Scalars['String']>;
  defaultPlaceHolderImage_starts_with?: Maybe<Scalars['String']>;
  defaultPlaceHolderImage_not_starts_with?: Maybe<Scalars['String']>;
  defaultPlaceHolderImage_ends_with?: Maybe<Scalars['String']>;
  defaultPlaceHolderImage_not_ends_with?: Maybe<Scalars['String']>;
  isCustom?: Maybe<Scalars['Boolean']>;
  isCustom_not?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isActive_not?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isHidden_not?: Maybe<Scalars['Boolean']>;
  canAssignUsingScript?: Maybe<Scalars['Boolean']>;
  canAssignUsingScript_not?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<BadgeWhereInput>>;
  OR?: Maybe<Array<BadgeWhereInput>>;
  NOT?: Maybe<Array<BadgeWhereInput>>;
};

export enum BadgeOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  ImageAsc = 'image_ASC',
  ImageDesc = 'image_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  WeightAsc = 'weight_ASC',
  WeightDesc = 'weight_DESC',
  DefaultPlaceHolderImageAsc = 'defaultPlaceHolderImage_ASC',
  DefaultPlaceHolderImageDesc = 'defaultPlaceHolderImage_DESC',
  IsCustomAsc = 'isCustom_ASC',
  IsCustomDesc = 'isCustom_DESC',
  IsActiveAsc = 'isActive_ASC',
  IsActiveDesc = 'isActive_DESC',
  IsHiddenAsc = 'isHidden_ASC',
  IsHiddenDesc = 'isHidden_DESC',
  CanAssignUsingScriptAsc = 'canAssignUsingScript_ASC',
  CanAssignUsingScriptDesc = 'canAssignUsingScript_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type BadgeConnection = {
  __typename?: 'BadgeConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<BadgeEdge>>;
  aggregate: AggregateBadge;
};

export type BadgeEdge = {
  __typename?: 'BadgeEdge';
  node: Badge;
  cursor: Scalars['String'];
};

export type AggregateBadge = {
  __typename?: 'AggregateBadge';
  count: Scalars['Int'];
};

export type CommentWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type Comment = {
  __typename?: 'Comment';
  id: Scalars['ID'];
  comment?: Maybe<Scalars['String']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isHighlighted?: Maybe<Scalars['Boolean']>;
  isAccepted?: Maybe<Scalars['Boolean']>;
  deletedBy?: Maybe<Person>;
  likes?: Maybe<Array<Person>>;
  likeCount?: Maybe<Scalars['Int']>;
  starCount?: Maybe<Scalars['Int']>;
  flagCount?: Maybe<Scalars['Int']>;
  flags?: Maybe<Array<Person>>;
  stars?: Maybe<Array<Person>>;
  mentions?: Maybe<Array<Person>>;
  post: Post;
  parent?: Maybe<Comment>;
  createdBy?: Maybe<Person>;
  moderationResponse?: Maybe<Scalars['Json']>;
  moderationScore?: Maybe<Scalars['Float']>;
  moderationLabel?: Maybe<Scalars['String']>;
  moderationError?: Maybe<Scalars['Json']>;
  mediaModerationResponse?: Maybe<Scalars['Json']>;
  mediaModerationError?: Maybe<Scalars['Json']>;
  mediaType?: Maybe<CommentMediaType>;
  mediaDimensions?: Maybe<Scalars['Json']>;
  mediaURL?: Maybe<Scalars['String']>;
  giphyId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  isStared?: Maybe<Scalars['Boolean']>;
  isLiked?: Maybe<Scalars['Boolean']>;
  isFlaged?: Maybe<Scalars['Boolean']>;
  replyCount?: Maybe<Scalars['Int']>;
  replies?: Maybe<Array<Maybe<ReplyComment>>>;
};


export type CommentLikesArgs = {
  where?: Maybe<PersonWhereInput>;
  orderBy?: Maybe<PersonOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type CommentFlagsArgs = {
  where?: Maybe<PersonWhereInput>;
  orderBy?: Maybe<PersonOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type CommentStarsArgs = {
  where?: Maybe<PersonWhereInput>;
  orderBy?: Maybe<PersonOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type CommentMentionsArgs = {
  where?: Maybe<PersonWhereInput>;
  orderBy?: Maybe<PersonOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type CommentRepliesArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  orderBy?: Maybe<ListCommentOrder>;
};

export type Post = {
  __typename?: 'Post';
  id: Scalars['ID'];
  isLive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  referenceType?: Maybe<PostReferenceType>;
  referenceId?: Maybe<Scalars['String']>;
  referenceSlug?: Maybe<Scalars['String']>;
  likesCount?: Maybe<Scalars['Int']>;
  commentsCount?: Maybe<Scalars['Int']>;
  viewsCount?: Maybe<Scalars['Int']>;
  playsCount?: Maybe<Scalars['Int']>;
  text?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  liveStreamEndedAt?: Maybe<Scalars['DateTime']>;
  retentionPeriod?: Maybe<Scalars['Int']>;
  video?: Maybe<PostVideo>;
  mediaDimensions?: Maybe<Scalars['Json']>;
  likes?: Maybe<Array<Person>>;
  comments?: Maybe<Array<Comment>>;
  createdBy?: Maybe<Person>;
  updatedBy?: Maybe<Person>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  isLiked?: Maybe<Scalars['Boolean']>;
  referenceItem?: Maybe<ReferenceItemType>;
  type?: Maybe<PostReferenceType>;
  topComment?: Maybe<Comment>;
  moderationScoreAggregate?: Maybe<Array<Maybe<ModerationScoreAggregate>>>;
  totalFlagCount?: Maybe<Scalars['Int']>;
};


export type PostLikesArgs = {
  where?: Maybe<PersonWhereInput>;
  orderBy?: Maybe<PersonOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type PostCommentsArgs = {
  where?: Maybe<CommentWhereInput>;
  orderBy?: Maybe<CommentOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type PostModerationScoreAggregateArgs = {
  withoutHiddenComment?: Maybe<Scalars['Boolean']>;
  withoutDeletedComment?: Maybe<Scalars['Boolean']>;
};

export enum PostReferenceType {
  Video = 'VIDEO',
  Episode = 'EPISODE',
  Post = 'POST',
  NewsArticle = 'NEWS_ARTICLE'
}

export type PostVideo = {
  __typename?: 'PostVideo';
  id: Scalars['ID'];
  muxAssetId?: Maybe<Scalars['String']>;
  streamKey?: Maybe<Scalars['String']>;
  uploadId?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  videoGIF?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  post?: Maybe<Post>;
  videoState?: Maybe<Scalars['String']>;
  muxPlaybackId?: Maybe<Scalars['String']>;
  playbackPolicy?: Maybe<Scalars['String']>;
  videoURL?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type CommentWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  comment?: Maybe<Scalars['String']>;
  comment_not?: Maybe<Scalars['String']>;
  comment_in?: Maybe<Array<Scalars['String']>>;
  comment_not_in?: Maybe<Array<Scalars['String']>>;
  comment_lt?: Maybe<Scalars['String']>;
  comment_lte?: Maybe<Scalars['String']>;
  comment_gt?: Maybe<Scalars['String']>;
  comment_gte?: Maybe<Scalars['String']>;
  comment_contains?: Maybe<Scalars['String']>;
  comment_not_contains?: Maybe<Scalars['String']>;
  comment_starts_with?: Maybe<Scalars['String']>;
  comment_not_starts_with?: Maybe<Scalars['String']>;
  comment_ends_with?: Maybe<Scalars['String']>;
  comment_not_ends_with?: Maybe<Scalars['String']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isHidden_not?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isDeleted_not?: Maybe<Scalars['Boolean']>;
  isHighlighted?: Maybe<Scalars['Boolean']>;
  isHighlighted_not?: Maybe<Scalars['Boolean']>;
  isAccepted?: Maybe<Scalars['Boolean']>;
  isAccepted_not?: Maybe<Scalars['Boolean']>;
  deletedBy?: Maybe<PersonWhereInput>;
  likes_every?: Maybe<PersonWhereInput>;
  likes_some?: Maybe<PersonWhereInput>;
  likes_none?: Maybe<PersonWhereInput>;
  likeCount?: Maybe<Scalars['Int']>;
  likeCount_not?: Maybe<Scalars['Int']>;
  likeCount_in?: Maybe<Array<Scalars['Int']>>;
  likeCount_not_in?: Maybe<Array<Scalars['Int']>>;
  likeCount_lt?: Maybe<Scalars['Int']>;
  likeCount_lte?: Maybe<Scalars['Int']>;
  likeCount_gt?: Maybe<Scalars['Int']>;
  likeCount_gte?: Maybe<Scalars['Int']>;
  starCount?: Maybe<Scalars['Int']>;
  starCount_not?: Maybe<Scalars['Int']>;
  starCount_in?: Maybe<Array<Scalars['Int']>>;
  starCount_not_in?: Maybe<Array<Scalars['Int']>>;
  starCount_lt?: Maybe<Scalars['Int']>;
  starCount_lte?: Maybe<Scalars['Int']>;
  starCount_gt?: Maybe<Scalars['Int']>;
  starCount_gte?: Maybe<Scalars['Int']>;
  flagCount?: Maybe<Scalars['Int']>;
  flagCount_not?: Maybe<Scalars['Int']>;
  flagCount_in?: Maybe<Array<Scalars['Int']>>;
  flagCount_not_in?: Maybe<Array<Scalars['Int']>>;
  flagCount_lt?: Maybe<Scalars['Int']>;
  flagCount_lte?: Maybe<Scalars['Int']>;
  flagCount_gt?: Maybe<Scalars['Int']>;
  flagCount_gte?: Maybe<Scalars['Int']>;
  flags_every?: Maybe<PersonWhereInput>;
  flags_some?: Maybe<PersonWhereInput>;
  flags_none?: Maybe<PersonWhereInput>;
  stars_every?: Maybe<PersonWhereInput>;
  stars_some?: Maybe<PersonWhereInput>;
  stars_none?: Maybe<PersonWhereInput>;
  mentions_every?: Maybe<PersonWhereInput>;
  mentions_some?: Maybe<PersonWhereInput>;
  mentions_none?: Maybe<PersonWhereInput>;
  post?: Maybe<PostWhereInput>;
  parent?: Maybe<CommentWhereInput>;
  createdBy?: Maybe<PersonWhereInput>;
  moderationScore?: Maybe<Scalars['Float']>;
  moderationScore_not?: Maybe<Scalars['Float']>;
  moderationScore_in?: Maybe<Array<Scalars['Float']>>;
  moderationScore_not_in?: Maybe<Array<Scalars['Float']>>;
  moderationScore_lt?: Maybe<Scalars['Float']>;
  moderationScore_lte?: Maybe<Scalars['Float']>;
  moderationScore_gt?: Maybe<Scalars['Float']>;
  moderationScore_gte?: Maybe<Scalars['Float']>;
  moderationLabel?: Maybe<Scalars['String']>;
  moderationLabel_not?: Maybe<Scalars['String']>;
  moderationLabel_in?: Maybe<Array<Scalars['String']>>;
  moderationLabel_not_in?: Maybe<Array<Scalars['String']>>;
  moderationLabel_lt?: Maybe<Scalars['String']>;
  moderationLabel_lte?: Maybe<Scalars['String']>;
  moderationLabel_gt?: Maybe<Scalars['String']>;
  moderationLabel_gte?: Maybe<Scalars['String']>;
  moderationLabel_contains?: Maybe<Scalars['String']>;
  moderationLabel_not_contains?: Maybe<Scalars['String']>;
  moderationLabel_starts_with?: Maybe<Scalars['String']>;
  moderationLabel_not_starts_with?: Maybe<Scalars['String']>;
  moderationLabel_ends_with?: Maybe<Scalars['String']>;
  moderationLabel_not_ends_with?: Maybe<Scalars['String']>;
  mediaType?: Maybe<CommentMediaType>;
  mediaType_not?: Maybe<CommentMediaType>;
  mediaType_in?: Maybe<Array<CommentMediaType>>;
  mediaType_not_in?: Maybe<Array<CommentMediaType>>;
  mediaURL?: Maybe<Scalars['String']>;
  mediaURL_not?: Maybe<Scalars['String']>;
  mediaURL_in?: Maybe<Array<Scalars['String']>>;
  mediaURL_not_in?: Maybe<Array<Scalars['String']>>;
  mediaURL_lt?: Maybe<Scalars['String']>;
  mediaURL_lte?: Maybe<Scalars['String']>;
  mediaURL_gt?: Maybe<Scalars['String']>;
  mediaURL_gte?: Maybe<Scalars['String']>;
  mediaURL_contains?: Maybe<Scalars['String']>;
  mediaURL_not_contains?: Maybe<Scalars['String']>;
  mediaURL_starts_with?: Maybe<Scalars['String']>;
  mediaURL_not_starts_with?: Maybe<Scalars['String']>;
  mediaURL_ends_with?: Maybe<Scalars['String']>;
  mediaURL_not_ends_with?: Maybe<Scalars['String']>;
  giphyId?: Maybe<Scalars['String']>;
  giphyId_not?: Maybe<Scalars['String']>;
  giphyId_in?: Maybe<Array<Scalars['String']>>;
  giphyId_not_in?: Maybe<Array<Scalars['String']>>;
  giphyId_lt?: Maybe<Scalars['String']>;
  giphyId_lte?: Maybe<Scalars['String']>;
  giphyId_gt?: Maybe<Scalars['String']>;
  giphyId_gte?: Maybe<Scalars['String']>;
  giphyId_contains?: Maybe<Scalars['String']>;
  giphyId_not_contains?: Maybe<Scalars['String']>;
  giphyId_starts_with?: Maybe<Scalars['String']>;
  giphyId_not_starts_with?: Maybe<Scalars['String']>;
  giphyId_ends_with?: Maybe<Scalars['String']>;
  giphyId_not_ends_with?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<CommentWhereInput>>;
  OR?: Maybe<Array<CommentWhereInput>>;
  NOT?: Maybe<Array<CommentWhereInput>>;
};

export type PostWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  isLive?: Maybe<Scalars['Boolean']>;
  isLive_not?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isDeleted_not?: Maybe<Scalars['Boolean']>;
  referenceType?: Maybe<PostReferenceType>;
  referenceType_not?: Maybe<PostReferenceType>;
  referenceType_in?: Maybe<Array<PostReferenceType>>;
  referenceType_not_in?: Maybe<Array<PostReferenceType>>;
  referenceId?: Maybe<Scalars['String']>;
  referenceId_not?: Maybe<Scalars['String']>;
  referenceId_in?: Maybe<Array<Scalars['String']>>;
  referenceId_not_in?: Maybe<Array<Scalars['String']>>;
  referenceId_lt?: Maybe<Scalars['String']>;
  referenceId_lte?: Maybe<Scalars['String']>;
  referenceId_gt?: Maybe<Scalars['String']>;
  referenceId_gte?: Maybe<Scalars['String']>;
  referenceId_contains?: Maybe<Scalars['String']>;
  referenceId_not_contains?: Maybe<Scalars['String']>;
  referenceId_starts_with?: Maybe<Scalars['String']>;
  referenceId_not_starts_with?: Maybe<Scalars['String']>;
  referenceId_ends_with?: Maybe<Scalars['String']>;
  referenceId_not_ends_with?: Maybe<Scalars['String']>;
  referenceSlug?: Maybe<Scalars['String']>;
  referenceSlug_not?: Maybe<Scalars['String']>;
  referenceSlug_in?: Maybe<Array<Scalars['String']>>;
  referenceSlug_not_in?: Maybe<Array<Scalars['String']>>;
  referenceSlug_lt?: Maybe<Scalars['String']>;
  referenceSlug_lte?: Maybe<Scalars['String']>;
  referenceSlug_gt?: Maybe<Scalars['String']>;
  referenceSlug_gte?: Maybe<Scalars['String']>;
  referenceSlug_contains?: Maybe<Scalars['String']>;
  referenceSlug_not_contains?: Maybe<Scalars['String']>;
  referenceSlug_starts_with?: Maybe<Scalars['String']>;
  referenceSlug_not_starts_with?: Maybe<Scalars['String']>;
  referenceSlug_ends_with?: Maybe<Scalars['String']>;
  referenceSlug_not_ends_with?: Maybe<Scalars['String']>;
  likesCount?: Maybe<Scalars['Int']>;
  likesCount_not?: Maybe<Scalars['Int']>;
  likesCount_in?: Maybe<Array<Scalars['Int']>>;
  likesCount_not_in?: Maybe<Array<Scalars['Int']>>;
  likesCount_lt?: Maybe<Scalars['Int']>;
  likesCount_lte?: Maybe<Scalars['Int']>;
  likesCount_gt?: Maybe<Scalars['Int']>;
  likesCount_gte?: Maybe<Scalars['Int']>;
  commentsCount?: Maybe<Scalars['Int']>;
  commentsCount_not?: Maybe<Scalars['Int']>;
  commentsCount_in?: Maybe<Array<Scalars['Int']>>;
  commentsCount_not_in?: Maybe<Array<Scalars['Int']>>;
  commentsCount_lt?: Maybe<Scalars['Int']>;
  commentsCount_lte?: Maybe<Scalars['Int']>;
  commentsCount_gt?: Maybe<Scalars['Int']>;
  commentsCount_gte?: Maybe<Scalars['Int']>;
  viewsCount?: Maybe<Scalars['Int']>;
  viewsCount_not?: Maybe<Scalars['Int']>;
  viewsCount_in?: Maybe<Array<Scalars['Int']>>;
  viewsCount_not_in?: Maybe<Array<Scalars['Int']>>;
  viewsCount_lt?: Maybe<Scalars['Int']>;
  viewsCount_lte?: Maybe<Scalars['Int']>;
  viewsCount_gt?: Maybe<Scalars['Int']>;
  viewsCount_gte?: Maybe<Scalars['Int']>;
  playsCount?: Maybe<Scalars['Int']>;
  playsCount_not?: Maybe<Scalars['Int']>;
  playsCount_in?: Maybe<Array<Scalars['Int']>>;
  playsCount_not_in?: Maybe<Array<Scalars['Int']>>;
  playsCount_lt?: Maybe<Scalars['Int']>;
  playsCount_lte?: Maybe<Scalars['Int']>;
  playsCount_gt?: Maybe<Scalars['Int']>;
  playsCount_gte?: Maybe<Scalars['Int']>;
  text?: Maybe<Scalars['String']>;
  text_not?: Maybe<Scalars['String']>;
  text_in?: Maybe<Array<Scalars['String']>>;
  text_not_in?: Maybe<Array<Scalars['String']>>;
  text_lt?: Maybe<Scalars['String']>;
  text_lte?: Maybe<Scalars['String']>;
  text_gt?: Maybe<Scalars['String']>;
  text_gte?: Maybe<Scalars['String']>;
  text_contains?: Maybe<Scalars['String']>;
  text_not_contains?: Maybe<Scalars['String']>;
  text_starts_with?: Maybe<Scalars['String']>;
  text_not_starts_with?: Maybe<Scalars['String']>;
  text_ends_with?: Maybe<Scalars['String']>;
  text_not_ends_with?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  image_not?: Maybe<Scalars['String']>;
  image_in?: Maybe<Array<Scalars['String']>>;
  image_not_in?: Maybe<Array<Scalars['String']>>;
  image_lt?: Maybe<Scalars['String']>;
  image_lte?: Maybe<Scalars['String']>;
  image_gt?: Maybe<Scalars['String']>;
  image_gte?: Maybe<Scalars['String']>;
  image_contains?: Maybe<Scalars['String']>;
  image_not_contains?: Maybe<Scalars['String']>;
  image_starts_with?: Maybe<Scalars['String']>;
  image_not_starts_with?: Maybe<Scalars['String']>;
  image_ends_with?: Maybe<Scalars['String']>;
  image_not_ends_with?: Maybe<Scalars['String']>;
  liveStreamEndedAt?: Maybe<Scalars['DateTime']>;
  liveStreamEndedAt_not?: Maybe<Scalars['DateTime']>;
  liveStreamEndedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  liveStreamEndedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  liveStreamEndedAt_lt?: Maybe<Scalars['DateTime']>;
  liveStreamEndedAt_lte?: Maybe<Scalars['DateTime']>;
  liveStreamEndedAt_gt?: Maybe<Scalars['DateTime']>;
  liveStreamEndedAt_gte?: Maybe<Scalars['DateTime']>;
  retentionPeriod?: Maybe<Scalars['Int']>;
  retentionPeriod_not?: Maybe<Scalars['Int']>;
  retentionPeriod_in?: Maybe<Array<Scalars['Int']>>;
  retentionPeriod_not_in?: Maybe<Array<Scalars['Int']>>;
  retentionPeriod_lt?: Maybe<Scalars['Int']>;
  retentionPeriod_lte?: Maybe<Scalars['Int']>;
  retentionPeriod_gt?: Maybe<Scalars['Int']>;
  retentionPeriod_gte?: Maybe<Scalars['Int']>;
  video?: Maybe<PostVideoWhereInput>;
  likes_every?: Maybe<PersonWhereInput>;
  likes_some?: Maybe<PersonWhereInput>;
  likes_none?: Maybe<PersonWhereInput>;
  comments_every?: Maybe<CommentWhereInput>;
  comments_some?: Maybe<CommentWhereInput>;
  comments_none?: Maybe<CommentWhereInput>;
  createdBy?: Maybe<PersonWhereInput>;
  updatedBy?: Maybe<PersonWhereInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<PostWhereInput>>;
  OR?: Maybe<Array<PostWhereInput>>;
  NOT?: Maybe<Array<PostWhereInput>>;
};

export type PostVideoWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  muxAssetId?: Maybe<Scalars['String']>;
  muxAssetId_not?: Maybe<Scalars['String']>;
  muxAssetId_in?: Maybe<Array<Scalars['String']>>;
  muxAssetId_not_in?: Maybe<Array<Scalars['String']>>;
  muxAssetId_lt?: Maybe<Scalars['String']>;
  muxAssetId_lte?: Maybe<Scalars['String']>;
  muxAssetId_gt?: Maybe<Scalars['String']>;
  muxAssetId_gte?: Maybe<Scalars['String']>;
  muxAssetId_contains?: Maybe<Scalars['String']>;
  muxAssetId_not_contains?: Maybe<Scalars['String']>;
  muxAssetId_starts_with?: Maybe<Scalars['String']>;
  muxAssetId_not_starts_with?: Maybe<Scalars['String']>;
  muxAssetId_ends_with?: Maybe<Scalars['String']>;
  muxAssetId_not_ends_with?: Maybe<Scalars['String']>;
  streamKey?: Maybe<Scalars['String']>;
  streamKey_not?: Maybe<Scalars['String']>;
  streamKey_in?: Maybe<Array<Scalars['String']>>;
  streamKey_not_in?: Maybe<Array<Scalars['String']>>;
  streamKey_lt?: Maybe<Scalars['String']>;
  streamKey_lte?: Maybe<Scalars['String']>;
  streamKey_gt?: Maybe<Scalars['String']>;
  streamKey_gte?: Maybe<Scalars['String']>;
  streamKey_contains?: Maybe<Scalars['String']>;
  streamKey_not_contains?: Maybe<Scalars['String']>;
  streamKey_starts_with?: Maybe<Scalars['String']>;
  streamKey_not_starts_with?: Maybe<Scalars['String']>;
  streamKey_ends_with?: Maybe<Scalars['String']>;
  streamKey_not_ends_with?: Maybe<Scalars['String']>;
  uploadId?: Maybe<Scalars['String']>;
  uploadId_not?: Maybe<Scalars['String']>;
  uploadId_in?: Maybe<Array<Scalars['String']>>;
  uploadId_not_in?: Maybe<Array<Scalars['String']>>;
  uploadId_lt?: Maybe<Scalars['String']>;
  uploadId_lte?: Maybe<Scalars['String']>;
  uploadId_gt?: Maybe<Scalars['String']>;
  uploadId_gte?: Maybe<Scalars['String']>;
  uploadId_contains?: Maybe<Scalars['String']>;
  uploadId_not_contains?: Maybe<Scalars['String']>;
  uploadId_starts_with?: Maybe<Scalars['String']>;
  uploadId_not_starts_with?: Maybe<Scalars['String']>;
  uploadId_ends_with?: Maybe<Scalars['String']>;
  uploadId_not_ends_with?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  thumbnail_not?: Maybe<Scalars['String']>;
  thumbnail_in?: Maybe<Array<Scalars['String']>>;
  thumbnail_not_in?: Maybe<Array<Scalars['String']>>;
  thumbnail_lt?: Maybe<Scalars['String']>;
  thumbnail_lte?: Maybe<Scalars['String']>;
  thumbnail_gt?: Maybe<Scalars['String']>;
  thumbnail_gte?: Maybe<Scalars['String']>;
  thumbnail_contains?: Maybe<Scalars['String']>;
  thumbnail_not_contains?: Maybe<Scalars['String']>;
  thumbnail_starts_with?: Maybe<Scalars['String']>;
  thumbnail_not_starts_with?: Maybe<Scalars['String']>;
  thumbnail_ends_with?: Maybe<Scalars['String']>;
  thumbnail_not_ends_with?: Maybe<Scalars['String']>;
  videoGIF?: Maybe<Scalars['String']>;
  videoGIF_not?: Maybe<Scalars['String']>;
  videoGIF_in?: Maybe<Array<Scalars['String']>>;
  videoGIF_not_in?: Maybe<Array<Scalars['String']>>;
  videoGIF_lt?: Maybe<Scalars['String']>;
  videoGIF_lte?: Maybe<Scalars['String']>;
  videoGIF_gt?: Maybe<Scalars['String']>;
  videoGIF_gte?: Maybe<Scalars['String']>;
  videoGIF_contains?: Maybe<Scalars['String']>;
  videoGIF_not_contains?: Maybe<Scalars['String']>;
  videoGIF_starts_with?: Maybe<Scalars['String']>;
  videoGIF_not_starts_with?: Maybe<Scalars['String']>;
  videoGIF_ends_with?: Maybe<Scalars['String']>;
  videoGIF_not_ends_with?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  status_not?: Maybe<Scalars['String']>;
  status_in?: Maybe<Array<Scalars['String']>>;
  status_not_in?: Maybe<Array<Scalars['String']>>;
  status_lt?: Maybe<Scalars['String']>;
  status_lte?: Maybe<Scalars['String']>;
  status_gt?: Maybe<Scalars['String']>;
  status_gte?: Maybe<Scalars['String']>;
  status_contains?: Maybe<Scalars['String']>;
  status_not_contains?: Maybe<Scalars['String']>;
  status_starts_with?: Maybe<Scalars['String']>;
  status_not_starts_with?: Maybe<Scalars['String']>;
  status_ends_with?: Maybe<Scalars['String']>;
  status_not_ends_with?: Maybe<Scalars['String']>;
  post?: Maybe<PostWhereInput>;
  videoState?: Maybe<Scalars['String']>;
  videoState_not?: Maybe<Scalars['String']>;
  videoState_in?: Maybe<Array<Scalars['String']>>;
  videoState_not_in?: Maybe<Array<Scalars['String']>>;
  videoState_lt?: Maybe<Scalars['String']>;
  videoState_lte?: Maybe<Scalars['String']>;
  videoState_gt?: Maybe<Scalars['String']>;
  videoState_gte?: Maybe<Scalars['String']>;
  videoState_contains?: Maybe<Scalars['String']>;
  videoState_not_contains?: Maybe<Scalars['String']>;
  videoState_starts_with?: Maybe<Scalars['String']>;
  videoState_not_starts_with?: Maybe<Scalars['String']>;
  videoState_ends_with?: Maybe<Scalars['String']>;
  videoState_not_ends_with?: Maybe<Scalars['String']>;
  muxPlaybackId?: Maybe<Scalars['String']>;
  muxPlaybackId_not?: Maybe<Scalars['String']>;
  muxPlaybackId_in?: Maybe<Array<Scalars['String']>>;
  muxPlaybackId_not_in?: Maybe<Array<Scalars['String']>>;
  muxPlaybackId_lt?: Maybe<Scalars['String']>;
  muxPlaybackId_lte?: Maybe<Scalars['String']>;
  muxPlaybackId_gt?: Maybe<Scalars['String']>;
  muxPlaybackId_gte?: Maybe<Scalars['String']>;
  muxPlaybackId_contains?: Maybe<Scalars['String']>;
  muxPlaybackId_not_contains?: Maybe<Scalars['String']>;
  muxPlaybackId_starts_with?: Maybe<Scalars['String']>;
  muxPlaybackId_not_starts_with?: Maybe<Scalars['String']>;
  muxPlaybackId_ends_with?: Maybe<Scalars['String']>;
  muxPlaybackId_not_ends_with?: Maybe<Scalars['String']>;
  playbackPolicy?: Maybe<Scalars['String']>;
  playbackPolicy_not?: Maybe<Scalars['String']>;
  playbackPolicy_in?: Maybe<Array<Scalars['String']>>;
  playbackPolicy_not_in?: Maybe<Array<Scalars['String']>>;
  playbackPolicy_lt?: Maybe<Scalars['String']>;
  playbackPolicy_lte?: Maybe<Scalars['String']>;
  playbackPolicy_gt?: Maybe<Scalars['String']>;
  playbackPolicy_gte?: Maybe<Scalars['String']>;
  playbackPolicy_contains?: Maybe<Scalars['String']>;
  playbackPolicy_not_contains?: Maybe<Scalars['String']>;
  playbackPolicy_starts_with?: Maybe<Scalars['String']>;
  playbackPolicy_not_starts_with?: Maybe<Scalars['String']>;
  playbackPolicy_ends_with?: Maybe<Scalars['String']>;
  playbackPolicy_not_ends_with?: Maybe<Scalars['String']>;
  videoURL?: Maybe<Scalars['String']>;
  videoURL_not?: Maybe<Scalars['String']>;
  videoURL_in?: Maybe<Array<Scalars['String']>>;
  videoURL_not_in?: Maybe<Array<Scalars['String']>>;
  videoURL_lt?: Maybe<Scalars['String']>;
  videoURL_lte?: Maybe<Scalars['String']>;
  videoURL_gt?: Maybe<Scalars['String']>;
  videoURL_gte?: Maybe<Scalars['String']>;
  videoURL_contains?: Maybe<Scalars['String']>;
  videoURL_not_contains?: Maybe<Scalars['String']>;
  videoURL_starts_with?: Maybe<Scalars['String']>;
  videoURL_not_starts_with?: Maybe<Scalars['String']>;
  videoURL_ends_with?: Maybe<Scalars['String']>;
  videoURL_not_ends_with?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
  duration_not?: Maybe<Scalars['Float']>;
  duration_in?: Maybe<Array<Scalars['Float']>>;
  duration_not_in?: Maybe<Array<Scalars['Float']>>;
  duration_lt?: Maybe<Scalars['Float']>;
  duration_lte?: Maybe<Scalars['Float']>;
  duration_gt?: Maybe<Scalars['Float']>;
  duration_gte?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<PostVideoWhereInput>>;
  OR?: Maybe<Array<PostVideoWhereInput>>;
  NOT?: Maybe<Array<PostVideoWhereInput>>;
};

export enum CommentMediaType {
  Image = 'IMAGE',
  Gif = 'GIF'
}

export enum CommentOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CommentAsc = 'comment_ASC',
  CommentDesc = 'comment_DESC',
  IsHiddenAsc = 'isHidden_ASC',
  IsHiddenDesc = 'isHidden_DESC',
  IsDeletedAsc = 'isDeleted_ASC',
  IsDeletedDesc = 'isDeleted_DESC',
  IsHighlightedAsc = 'isHighlighted_ASC',
  IsHighlightedDesc = 'isHighlighted_DESC',
  IsAcceptedAsc = 'isAccepted_ASC',
  IsAcceptedDesc = 'isAccepted_DESC',
  LikeCountAsc = 'likeCount_ASC',
  LikeCountDesc = 'likeCount_DESC',
  StarCountAsc = 'starCount_ASC',
  StarCountDesc = 'starCount_DESC',
  FlagCountAsc = 'flagCount_ASC',
  FlagCountDesc = 'flagCount_DESC',
  ModerationResponseAsc = 'moderationResponse_ASC',
  ModerationResponseDesc = 'moderationResponse_DESC',
  ModerationScoreAsc = 'moderationScore_ASC',
  ModerationScoreDesc = 'moderationScore_DESC',
  ModerationLabelAsc = 'moderationLabel_ASC',
  ModerationLabelDesc = 'moderationLabel_DESC',
  ModerationErrorAsc = 'moderationError_ASC',
  ModerationErrorDesc = 'moderationError_DESC',
  MediaModerationResponseAsc = 'mediaModerationResponse_ASC',
  MediaModerationResponseDesc = 'mediaModerationResponse_DESC',
  MediaModerationErrorAsc = 'mediaModerationError_ASC',
  MediaModerationErrorDesc = 'mediaModerationError_DESC',
  MediaTypeAsc = 'mediaType_ASC',
  MediaTypeDesc = 'mediaType_DESC',
  MediaDimensionsAsc = 'mediaDimensions_ASC',
  MediaDimensionsDesc = 'mediaDimensions_DESC',
  MediaUrlAsc = 'mediaURL_ASC',
  MediaUrlDesc = 'mediaURL_DESC',
  GiphyIdAsc = 'giphyId_ASC',
  GiphyIdDesc = 'giphyId_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type ReferenceItemType = Post;

export type ModerationScoreAggregate = {
  __typename?: 'ModerationScoreAggregate';
  bucket?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
};

export enum ListCommentOrder {
  Recent = 'RECENT',
  MostLiked = 'MOST_LIKED',
  MostStared = 'MOST_STARED'
}

export type ReplyComment = {
  __typename?: 'ReplyComment';
  id: Scalars['ID'];
  comment: Scalars['String'];
  isHidden?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isStared?: Maybe<Scalars['Boolean']>;
  isLiked?: Maybe<Scalars['Boolean']>;
  deletedBy?: Maybe<Person>;
  likeCount?: Maybe<Scalars['Int']>;
  starCount?: Maybe<Scalars['Int']>;
  flagCount?: Maybe<Scalars['Int']>;
  replyCount?: Maybe<Scalars['Int']>;
  flags?: Maybe<Array<Person>>;
  stars?: Maybe<Array<Person>>;
  mentions?: Maybe<Array<Person>>;
  post: Post;
  parent?: Maybe<ReplyParentComment>;
  giphyId?: Maybe<Scalars['String']>;
  mediaURL?: Maybe<Scalars['String']>;
  mediaDimensions?: Maybe<Scalars['Json']>;
  mediaType?: Maybe<CommentMediaType>;
  createdBy?: Maybe<Person>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type ReplyCommentFlagsArgs = {
  where?: Maybe<PersonWhereInput>;
  orderBy?: Maybe<PersonOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type ReplyCommentStarsArgs = {
  where?: Maybe<PersonWhereInput>;
  orderBy?: Maybe<PersonOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type ReplyCommentMentionsArgs = {
  where?: Maybe<PersonWhereInput>;
  orderBy?: Maybe<PersonOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type ReplyParentComment = {
  __typename?: 'ReplyParentComment';
  id: Scalars['ID'];
};

export type CommentConnection = {
  __typename?: 'CommentConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<CommentEdge>>;
  aggregate: AggregateComment;
};

export type CommentEdge = {
  __typename?: 'CommentEdge';
  node: Comment;
  cursor: Scalars['String'];
};

export type AggregateComment = {
  __typename?: 'AggregateComment';
  count: Scalars['Int'];
};

export type ConfigurationWhereUniqueInput = {
  id?: Maybe<Scalars['Int']>;
  key?: Maybe<Scalars['String']>;
};

export type Configuration = {
  __typename?: 'Configuration';
  id: Scalars['Int'];
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  type?: Maybe<ConfigurationType>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export enum ConfigurationType {
  Duration = 'DURATION',
  Int = 'INT',
  Float = 'FLOAT',
  String = 'STRING'
}

export type ConfigurationWhereInput = {
  id?: Maybe<Scalars['Int']>;
  id_not?: Maybe<Scalars['Int']>;
  id_in?: Maybe<Array<Scalars['Int']>>;
  id_not_in?: Maybe<Array<Scalars['Int']>>;
  id_lt?: Maybe<Scalars['Int']>;
  id_lte?: Maybe<Scalars['Int']>;
  id_gt?: Maybe<Scalars['Int']>;
  id_gte?: Maybe<Scalars['Int']>;
  key?: Maybe<Scalars['String']>;
  key_not?: Maybe<Scalars['String']>;
  key_in?: Maybe<Array<Scalars['String']>>;
  key_not_in?: Maybe<Array<Scalars['String']>>;
  key_lt?: Maybe<Scalars['String']>;
  key_lte?: Maybe<Scalars['String']>;
  key_gt?: Maybe<Scalars['String']>;
  key_gte?: Maybe<Scalars['String']>;
  key_contains?: Maybe<Scalars['String']>;
  key_not_contains?: Maybe<Scalars['String']>;
  key_starts_with?: Maybe<Scalars['String']>;
  key_not_starts_with?: Maybe<Scalars['String']>;
  key_ends_with?: Maybe<Scalars['String']>;
  key_not_ends_with?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  value_not?: Maybe<Scalars['String']>;
  value_in?: Maybe<Array<Scalars['String']>>;
  value_not_in?: Maybe<Array<Scalars['String']>>;
  value_lt?: Maybe<Scalars['String']>;
  value_lte?: Maybe<Scalars['String']>;
  value_gt?: Maybe<Scalars['String']>;
  value_gte?: Maybe<Scalars['String']>;
  value_contains?: Maybe<Scalars['String']>;
  value_not_contains?: Maybe<Scalars['String']>;
  value_starts_with?: Maybe<Scalars['String']>;
  value_not_starts_with?: Maybe<Scalars['String']>;
  value_ends_with?: Maybe<Scalars['String']>;
  value_not_ends_with?: Maybe<Scalars['String']>;
  type?: Maybe<ConfigurationType>;
  type_not?: Maybe<ConfigurationType>;
  type_in?: Maybe<Array<ConfigurationType>>;
  type_not_in?: Maybe<Array<ConfigurationType>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<ConfigurationWhereInput>>;
  OR?: Maybe<Array<ConfigurationWhereInput>>;
  NOT?: Maybe<Array<ConfigurationWhereInput>>;
};

export enum ConfigurationOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  ValueAsc = 'value_ASC',
  ValueDesc = 'value_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type ConfigurationConnection = {
  __typename?: 'ConfigurationConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<ConfigurationEdge>>;
  aggregate: AggregateConfiguration;
};

export type ConfigurationEdge = {
  __typename?: 'ConfigurationEdge';
  node: Configuration;
  cursor: Scalars['String'];
};

export type AggregateConfiguration = {
  __typename?: 'AggregateConfiguration';
  count: Scalars['Int'];
};

export type DiscussionWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
  slug?: Maybe<Scalars['String']>;
};

export enum DiscussionOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  IsLiveAsc = 'isLive_ASC',
  IsLiveDesc = 'isLive_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  IsArchivedAsc = 'isArchived_ASC',
  IsArchivedDesc = 'isArchived_DESC',
  ArchiveStatusAsc = 'archiveStatus_ASC',
  ArchiveStatusDesc = 'archiveStatus_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  ScheduleAtAsc = 'scheduleAt_ASC',
  ScheduleAtDesc = 'scheduleAt_DESC',
  StartTimeAsc = 'startTime_ASC',
  StartTimeDesc = 'startTime_DESC',
  EndTimeAsc = 'endTime_ASC',
  EndTimeDesc = 'endTime_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type DiscussionConnection = {
  __typename?: 'DiscussionConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<DiscussionEdge>>;
  aggregate: AggregateDiscussion;
};

export type DiscussionEdge = {
  __typename?: 'DiscussionEdge';
  node: Discussion;
  cursor: Scalars['String'];
};

export type AggregateDiscussion = {
  __typename?: 'AggregateDiscussion';
  count: Scalars['Int'];
};

export type EmailChangeRequestWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type EmailChangeRequest = {
  __typename?: 'EmailChangeRequest';
  id: Scalars['ID'];
  currentEmail?: Maybe<Scalars['String']>;
  newEmail?: Maybe<Scalars['String']>;
  updateEmailInAuth0?: Maybe<Scalars['Boolean']>;
  updateEmailInRecurly?: Maybe<Scalars['Boolean']>;
  updateEmailInDatabase?: Maybe<Scalars['Boolean']>;
  updatedEmailInShopify?: Maybe<Scalars['Boolean']>;
  error?: Maybe<Scalars['Json']>;
  state?: Maybe<Scalars['Json']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type EmailChangeRequestWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  currentEmail?: Maybe<Scalars['String']>;
  currentEmail_not?: Maybe<Scalars['String']>;
  currentEmail_in?: Maybe<Array<Scalars['String']>>;
  currentEmail_not_in?: Maybe<Array<Scalars['String']>>;
  currentEmail_lt?: Maybe<Scalars['String']>;
  currentEmail_lte?: Maybe<Scalars['String']>;
  currentEmail_gt?: Maybe<Scalars['String']>;
  currentEmail_gte?: Maybe<Scalars['String']>;
  currentEmail_contains?: Maybe<Scalars['String']>;
  currentEmail_not_contains?: Maybe<Scalars['String']>;
  currentEmail_starts_with?: Maybe<Scalars['String']>;
  currentEmail_not_starts_with?: Maybe<Scalars['String']>;
  currentEmail_ends_with?: Maybe<Scalars['String']>;
  currentEmail_not_ends_with?: Maybe<Scalars['String']>;
  newEmail?: Maybe<Scalars['String']>;
  newEmail_not?: Maybe<Scalars['String']>;
  newEmail_in?: Maybe<Array<Scalars['String']>>;
  newEmail_not_in?: Maybe<Array<Scalars['String']>>;
  newEmail_lt?: Maybe<Scalars['String']>;
  newEmail_lte?: Maybe<Scalars['String']>;
  newEmail_gt?: Maybe<Scalars['String']>;
  newEmail_gte?: Maybe<Scalars['String']>;
  newEmail_contains?: Maybe<Scalars['String']>;
  newEmail_not_contains?: Maybe<Scalars['String']>;
  newEmail_starts_with?: Maybe<Scalars['String']>;
  newEmail_not_starts_with?: Maybe<Scalars['String']>;
  newEmail_ends_with?: Maybe<Scalars['String']>;
  newEmail_not_ends_with?: Maybe<Scalars['String']>;
  updateEmailInAuth0?: Maybe<Scalars['Boolean']>;
  updateEmailInAuth0_not?: Maybe<Scalars['Boolean']>;
  updateEmailInRecurly?: Maybe<Scalars['Boolean']>;
  updateEmailInRecurly_not?: Maybe<Scalars['Boolean']>;
  updateEmailInDatabase?: Maybe<Scalars['Boolean']>;
  updateEmailInDatabase_not?: Maybe<Scalars['Boolean']>;
  updatedEmailInShopify?: Maybe<Scalars['Boolean']>;
  updatedEmailInShopify_not?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<EmailChangeRequestWhereInput>>;
  OR?: Maybe<Array<EmailChangeRequestWhereInput>>;
  NOT?: Maybe<Array<EmailChangeRequestWhereInput>>;
};

export enum EmailChangeRequestOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CurrentEmailAsc = 'currentEmail_ASC',
  CurrentEmailDesc = 'currentEmail_DESC',
  NewEmailAsc = 'newEmail_ASC',
  NewEmailDesc = 'newEmail_DESC',
  UpdateEmailInAuth0Asc = 'updateEmailInAuth0_ASC',
  UpdateEmailInAuth0Desc = 'updateEmailInAuth0_DESC',
  UpdateEmailInRecurlyAsc = 'updateEmailInRecurly_ASC',
  UpdateEmailInRecurlyDesc = 'updateEmailInRecurly_DESC',
  UpdateEmailInDatabaseAsc = 'updateEmailInDatabase_ASC',
  UpdateEmailInDatabaseDesc = 'updateEmailInDatabase_DESC',
  UpdatedEmailInShopifyAsc = 'updatedEmailInShopify_ASC',
  UpdatedEmailInShopifyDesc = 'updatedEmailInShopify_DESC',
  ErrorAsc = 'error_ASC',
  ErrorDesc = 'error_DESC',
  StateAsc = 'state_ASC',
  StateDesc = 'state_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type EmailChangeRequestConnection = {
  __typename?: 'EmailChangeRequestConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<EmailChangeRequestEdge>>;
  aggregate: AggregateEmailChangeRequest;
};

export type EmailChangeRequestEdge = {
  __typename?: 'EmailChangeRequestEdge';
  node: EmailChangeRequest;
  cursor: Scalars['String'];
};

export type AggregateEmailChangeRequest = {
  __typename?: 'AggregateEmailChangeRequest';
  count: Scalars['Int'];
};

export type MessageWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type MessageConnection = {
  __typename?: 'MessageConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<MessageEdge>>;
  aggregate: AggregateMessage;
};

export type MessageEdge = {
  __typename?: 'MessageEdge';
  node: Message;
  cursor: Scalars['String'];
};

export type AggregateMessage = {
  __typename?: 'AggregateMessage';
  count: Scalars['Int'];
};

export type NotificationWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type Notification = {
  __typename?: 'Notification';
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  sender?: Maybe<Person>;
  receiver?: Maybe<Person>;
  type?: Maybe<Notification_Type>;
  referenceType?: Maybe<Notification_Ref_Type>;
  referenceData?: Maybe<Scalars['Json']>;
  referenceId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  hasRead?: Maybe<Scalars['Boolean']>;
};

export enum Notification_Type {
  StarComment = 'STAR_COMMENT',
  LikeComment = 'LIKE_COMMENT',
  AuthorLive = 'AUTHOR_LIVE',
  Broadcast = 'BROADCAST',
  ReplyOnComment = 'REPLY_ON_COMMENT'
}

export enum Notification_Ref_Type {
  Comment = 'COMMENT',
  Post = 'POST'
}

export type NotificationWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Scalars['String']>>;
  title_not_in?: Maybe<Array<Scalars['String']>>;
  title_lt?: Maybe<Scalars['String']>;
  title_lte?: Maybe<Scalars['String']>;
  title_gt?: Maybe<Scalars['String']>;
  title_gte?: Maybe<Scalars['String']>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  title_starts_with?: Maybe<Scalars['String']>;
  title_not_starts_with?: Maybe<Scalars['String']>;
  title_ends_with?: Maybe<Scalars['String']>;
  title_not_ends_with?: Maybe<Scalars['String']>;
  sender?: Maybe<PersonWhereInput>;
  receiver?: Maybe<PersonWhereInput>;
  type?: Maybe<Notification_Type>;
  type_not?: Maybe<Notification_Type>;
  type_in?: Maybe<Array<Notification_Type>>;
  type_not_in?: Maybe<Array<Notification_Type>>;
  referenceType?: Maybe<Notification_Ref_Type>;
  referenceType_not?: Maybe<Notification_Ref_Type>;
  referenceType_in?: Maybe<Array<Notification_Ref_Type>>;
  referenceType_not_in?: Maybe<Array<Notification_Ref_Type>>;
  referenceId?: Maybe<Scalars['String']>;
  referenceId_not?: Maybe<Scalars['String']>;
  referenceId_in?: Maybe<Array<Scalars['String']>>;
  referenceId_not_in?: Maybe<Array<Scalars['String']>>;
  referenceId_lt?: Maybe<Scalars['String']>;
  referenceId_lte?: Maybe<Scalars['String']>;
  referenceId_gt?: Maybe<Scalars['String']>;
  referenceId_gte?: Maybe<Scalars['String']>;
  referenceId_contains?: Maybe<Scalars['String']>;
  referenceId_not_contains?: Maybe<Scalars['String']>;
  referenceId_starts_with?: Maybe<Scalars['String']>;
  referenceId_not_starts_with?: Maybe<Scalars['String']>;
  referenceId_ends_with?: Maybe<Scalars['String']>;
  referenceId_not_ends_with?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<NotificationWhereInput>>;
  OR?: Maybe<Array<NotificationWhereInput>>;
  NOT?: Maybe<Array<NotificationWhereInput>>;
};

export enum NotificationOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  ReferenceTypeAsc = 'referenceType_ASC',
  ReferenceTypeDesc = 'referenceType_DESC',
  ReferenceDataAsc = 'referenceData_ASC',
  ReferenceDataDesc = 'referenceData_DESC',
  ReferenceIdAsc = 'referenceId_ASC',
  ReferenceIdDesc = 'referenceId_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type NotificationConnection = {
  __typename?: 'NotificationConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<NotificationEdge>>;
  aggregate: AggregateNotification;
};

export type NotificationEdge = {
  __typename?: 'NotificationEdge';
  node: Notification;
  cursor: Scalars['String'];
};

export type AggregateNotification = {
  __typename?: 'AggregateNotification';
  count: Scalars['Int'];
};

export type NotificationUserWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type NotificationUser = {
  __typename?: 'NotificationUser';
  id: Scalars['ID'];
  person?: Maybe<Person>;
  notification?: Maybe<Notification>;
  hasRead?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type NotificationUserWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  person?: Maybe<PersonWhereInput>;
  notification?: Maybe<NotificationWhereInput>;
  hasRead?: Maybe<Scalars['Boolean']>;
  hasRead_not?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<NotificationUserWhereInput>>;
  OR?: Maybe<Array<NotificationUserWhereInput>>;
  NOT?: Maybe<Array<NotificationUserWhereInput>>;
};

export enum NotificationUserOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  HasReadAsc = 'hasRead_ASC',
  HasReadDesc = 'hasRead_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type NotificationUserConnection = {
  __typename?: 'NotificationUserConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<NotificationUserEdge>>;
  aggregate: AggregateNotificationUser;
};

export type NotificationUserEdge = {
  __typename?: 'NotificationUserEdge';
  node: NotificationUser;
  cursor: Scalars['String'];
};

export type AggregateNotificationUser = {
  __typename?: 'AggregateNotificationUser';
  count: Scalars['Int'];
};

export type OneSignalPersonWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
  playerID?: Maybe<Scalars['String']>;
};

export type OneSignalPerson = {
  __typename?: 'OneSignalPerson';
  id: Scalars['ID'];
  person: Person;
  playerID: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type OneSignalPersonWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  person?: Maybe<PersonWhereInput>;
  playerID?: Maybe<Scalars['String']>;
  playerID_not?: Maybe<Scalars['String']>;
  playerID_in?: Maybe<Array<Scalars['String']>>;
  playerID_not_in?: Maybe<Array<Scalars['String']>>;
  playerID_lt?: Maybe<Scalars['String']>;
  playerID_lte?: Maybe<Scalars['String']>;
  playerID_gt?: Maybe<Scalars['String']>;
  playerID_gte?: Maybe<Scalars['String']>;
  playerID_contains?: Maybe<Scalars['String']>;
  playerID_not_contains?: Maybe<Scalars['String']>;
  playerID_starts_with?: Maybe<Scalars['String']>;
  playerID_not_starts_with?: Maybe<Scalars['String']>;
  playerID_ends_with?: Maybe<Scalars['String']>;
  playerID_not_ends_with?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<OneSignalPersonWhereInput>>;
  OR?: Maybe<Array<OneSignalPersonWhereInput>>;
  NOT?: Maybe<Array<OneSignalPersonWhereInput>>;
};

export enum OneSignalPersonOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PlayerIdAsc = 'playerID_ASC',
  PlayerIdDesc = 'playerID_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type OneSignalPersonConnection = {
  __typename?: 'OneSignalPersonConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<OneSignalPersonEdge>>;
  aggregate: AggregateOneSignalPerson;
};

export type OneSignalPersonEdge = {
  __typename?: 'OneSignalPersonEdge';
  node: OneSignalPerson;
  cursor: Scalars['String'];
};

export type AggregateOneSignalPerson = {
  __typename?: 'AggregateOneSignalPerson';
  count: Scalars['Int'];
};

export type OrderWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type Order = {
  __typename?: 'Order';
  id: Scalars['ID'];
  shipHeroId?: Maybe<Scalars['String']>;
  shopifyOrderId?: Maybe<Scalars['String']>;
  person: Person;
  recurlyAccountCode?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<Scalars['String']>;
  cancelStatus?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['Json']>;
  metadata?: Maybe<Scalars['Json']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type OrderWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  shipHeroId?: Maybe<Scalars['String']>;
  shipHeroId_not?: Maybe<Scalars['String']>;
  shipHeroId_in?: Maybe<Array<Scalars['String']>>;
  shipHeroId_not_in?: Maybe<Array<Scalars['String']>>;
  shipHeroId_lt?: Maybe<Scalars['String']>;
  shipHeroId_lte?: Maybe<Scalars['String']>;
  shipHeroId_gt?: Maybe<Scalars['String']>;
  shipHeroId_gte?: Maybe<Scalars['String']>;
  shipHeroId_contains?: Maybe<Scalars['String']>;
  shipHeroId_not_contains?: Maybe<Scalars['String']>;
  shipHeroId_starts_with?: Maybe<Scalars['String']>;
  shipHeroId_not_starts_with?: Maybe<Scalars['String']>;
  shipHeroId_ends_with?: Maybe<Scalars['String']>;
  shipHeroId_not_ends_with?: Maybe<Scalars['String']>;
  shopifyOrderId?: Maybe<Scalars['String']>;
  shopifyOrderId_not?: Maybe<Scalars['String']>;
  shopifyOrderId_in?: Maybe<Array<Scalars['String']>>;
  shopifyOrderId_not_in?: Maybe<Array<Scalars['String']>>;
  shopifyOrderId_lt?: Maybe<Scalars['String']>;
  shopifyOrderId_lte?: Maybe<Scalars['String']>;
  shopifyOrderId_gt?: Maybe<Scalars['String']>;
  shopifyOrderId_gte?: Maybe<Scalars['String']>;
  shopifyOrderId_contains?: Maybe<Scalars['String']>;
  shopifyOrderId_not_contains?: Maybe<Scalars['String']>;
  shopifyOrderId_starts_with?: Maybe<Scalars['String']>;
  shopifyOrderId_not_starts_with?: Maybe<Scalars['String']>;
  shopifyOrderId_ends_with?: Maybe<Scalars['String']>;
  shopifyOrderId_not_ends_with?: Maybe<Scalars['String']>;
  person?: Maybe<PersonWhereInput>;
  recurlyAccountCode?: Maybe<Scalars['String']>;
  recurlyAccountCode_not?: Maybe<Scalars['String']>;
  recurlyAccountCode_in?: Maybe<Array<Scalars['String']>>;
  recurlyAccountCode_not_in?: Maybe<Array<Scalars['String']>>;
  recurlyAccountCode_lt?: Maybe<Scalars['String']>;
  recurlyAccountCode_lte?: Maybe<Scalars['String']>;
  recurlyAccountCode_gt?: Maybe<Scalars['String']>;
  recurlyAccountCode_gte?: Maybe<Scalars['String']>;
  recurlyAccountCode_contains?: Maybe<Scalars['String']>;
  recurlyAccountCode_not_contains?: Maybe<Scalars['String']>;
  recurlyAccountCode_starts_with?: Maybe<Scalars['String']>;
  recurlyAccountCode_not_starts_with?: Maybe<Scalars['String']>;
  recurlyAccountCode_ends_with?: Maybe<Scalars['String']>;
  recurlyAccountCode_not_ends_with?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionId_not?: Maybe<Scalars['String']>;
  subscriptionId_in?: Maybe<Array<Scalars['String']>>;
  subscriptionId_not_in?: Maybe<Array<Scalars['String']>>;
  subscriptionId_lt?: Maybe<Scalars['String']>;
  subscriptionId_lte?: Maybe<Scalars['String']>;
  subscriptionId_gt?: Maybe<Scalars['String']>;
  subscriptionId_gte?: Maybe<Scalars['String']>;
  subscriptionId_contains?: Maybe<Scalars['String']>;
  subscriptionId_not_contains?: Maybe<Scalars['String']>;
  subscriptionId_starts_with?: Maybe<Scalars['String']>;
  subscriptionId_not_starts_with?: Maybe<Scalars['String']>;
  subscriptionId_ends_with?: Maybe<Scalars['String']>;
  subscriptionId_not_ends_with?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<Scalars['String']>;
  paymentStatus_not?: Maybe<Scalars['String']>;
  paymentStatus_in?: Maybe<Array<Scalars['String']>>;
  paymentStatus_not_in?: Maybe<Array<Scalars['String']>>;
  paymentStatus_lt?: Maybe<Scalars['String']>;
  paymentStatus_lte?: Maybe<Scalars['String']>;
  paymentStatus_gt?: Maybe<Scalars['String']>;
  paymentStatus_gte?: Maybe<Scalars['String']>;
  paymentStatus_contains?: Maybe<Scalars['String']>;
  paymentStatus_not_contains?: Maybe<Scalars['String']>;
  paymentStatus_starts_with?: Maybe<Scalars['String']>;
  paymentStatus_not_starts_with?: Maybe<Scalars['String']>;
  paymentStatus_ends_with?: Maybe<Scalars['String']>;
  paymentStatus_not_ends_with?: Maybe<Scalars['String']>;
  cancelStatus?: Maybe<Scalars['String']>;
  cancelStatus_not?: Maybe<Scalars['String']>;
  cancelStatus_in?: Maybe<Array<Scalars['String']>>;
  cancelStatus_not_in?: Maybe<Array<Scalars['String']>>;
  cancelStatus_lt?: Maybe<Scalars['String']>;
  cancelStatus_lte?: Maybe<Scalars['String']>;
  cancelStatus_gt?: Maybe<Scalars['String']>;
  cancelStatus_gte?: Maybe<Scalars['String']>;
  cancelStatus_contains?: Maybe<Scalars['String']>;
  cancelStatus_not_contains?: Maybe<Scalars['String']>;
  cancelStatus_starts_with?: Maybe<Scalars['String']>;
  cancelStatus_not_starts_with?: Maybe<Scalars['String']>;
  cancelStatus_ends_with?: Maybe<Scalars['String']>;
  cancelStatus_not_ends_with?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<OrderWhereInput>>;
  OR?: Maybe<Array<OrderWhereInput>>;
  NOT?: Maybe<Array<OrderWhereInput>>;
};

export enum OrderOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  ShipHeroIdAsc = 'shipHeroId_ASC',
  ShipHeroIdDesc = 'shipHeroId_DESC',
  ShopifyOrderIdAsc = 'shopifyOrderId_ASC',
  ShopifyOrderIdDesc = 'shopifyOrderId_DESC',
  RecurlyAccountCodeAsc = 'recurlyAccountCode_ASC',
  RecurlyAccountCodeDesc = 'recurlyAccountCode_DESC',
  SubscriptionIdAsc = 'subscriptionId_ASC',
  SubscriptionIdDesc = 'subscriptionId_DESC',
  PaymentStatusAsc = 'paymentStatus_ASC',
  PaymentStatusDesc = 'paymentStatus_DESC',
  CancelStatusAsc = 'cancelStatus_ASC',
  CancelStatusDesc = 'cancelStatus_DESC',
  ErrorAsc = 'error_ASC',
  ErrorDesc = 'error_DESC',
  MetadataAsc = 'metadata_ASC',
  MetadataDesc = 'metadata_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type OrderConnection = {
  __typename?: 'OrderConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<OrderEdge>>;
  aggregate: AggregateOrder;
};

export type OrderEdge = {
  __typename?: 'OrderEdge';
  node: Order;
  cursor: Scalars['String'];
};

export type AggregateOrder = {
  __typename?: 'AggregateOrder';
  count: Scalars['Int'];
};

export type PermissionWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
  key?: Maybe<Scalars['String']>;
};

export enum PermissionOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type PermissionConnection = {
  __typename?: 'PermissionConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<PermissionEdge>>;
  aggregate: AggregatePermission;
};

export type PermissionEdge = {
  __typename?: 'PermissionEdge';
  node: Permission;
  cursor: Scalars['String'];
};

export type AggregatePermission = {
  __typename?: 'AggregatePermission';
  count: Scalars['Int'];
};

export type PersonWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
  email?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
  authZeroID?: Maybe<Scalars['String']>;
};

export type PersonConnection = {
  __typename?: 'PersonConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<PersonEdge>>;
  aggregate: AggregatePerson;
};

export type PersonEdge = {
  __typename?: 'PersonEdge';
  node: Person;
  cursor: Scalars['String'];
};

export type AggregatePerson = {
  __typename?: 'AggregatePerson';
  count: Scalars['Int'];
};

export type PersonPermissionWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type PersonPermission = {
  __typename?: 'PersonPermission';
  id: Scalars['ID'];
  permission: Permission;
  person: Person;
  canRead?: Maybe<Scalars['Boolean']>;
  canMutate?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type PersonPermissionWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  permission?: Maybe<PermissionWhereInput>;
  person?: Maybe<PersonWhereInput>;
  canRead?: Maybe<Scalars['Boolean']>;
  canRead_not?: Maybe<Scalars['Boolean']>;
  canMutate?: Maybe<Scalars['Boolean']>;
  canMutate_not?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<PersonPermissionWhereInput>>;
  OR?: Maybe<Array<PersonPermissionWhereInput>>;
  NOT?: Maybe<Array<PersonPermissionWhereInput>>;
};

export enum PersonPermissionOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CanReadAsc = 'canRead_ASC',
  CanReadDesc = 'canRead_DESC',
  CanMutateAsc = 'canMutate_ASC',
  CanMutateDesc = 'canMutate_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type PersonPermissionConnection = {
  __typename?: 'PersonPermissionConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<PersonPermissionEdge>>;
  aggregate: AggregatePersonPermission;
};

export type PersonPermissionEdge = {
  __typename?: 'PersonPermissionEdge';
  node: PersonPermission;
  cursor: Scalars['String'];
};

export type AggregatePersonPermission = {
  __typename?: 'AggregatePersonPermission';
  count: Scalars['Int'];
};

export type PostWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export enum PostOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  IsLiveAsc = 'isLive_ASC',
  IsLiveDesc = 'isLive_DESC',
  IsDeletedAsc = 'isDeleted_ASC',
  IsDeletedDesc = 'isDeleted_DESC',
  ReferenceTypeAsc = 'referenceType_ASC',
  ReferenceTypeDesc = 'referenceType_DESC',
  ReferenceIdAsc = 'referenceId_ASC',
  ReferenceIdDesc = 'referenceId_DESC',
  ReferenceSlugAsc = 'referenceSlug_ASC',
  ReferenceSlugDesc = 'referenceSlug_DESC',
  LikesCountAsc = 'likesCount_ASC',
  LikesCountDesc = 'likesCount_DESC',
  CommentsCountAsc = 'commentsCount_ASC',
  CommentsCountDesc = 'commentsCount_DESC',
  ViewsCountAsc = 'viewsCount_ASC',
  ViewsCountDesc = 'viewsCount_DESC',
  PlaysCountAsc = 'playsCount_ASC',
  PlaysCountDesc = 'playsCount_DESC',
  TextAsc = 'text_ASC',
  TextDesc = 'text_DESC',
  ImageAsc = 'image_ASC',
  ImageDesc = 'image_DESC',
  LiveStreamEndedAtAsc = 'liveStreamEndedAt_ASC',
  LiveStreamEndedAtDesc = 'liveStreamEndedAt_DESC',
  RetentionPeriodAsc = 'retentionPeriod_ASC',
  RetentionPeriodDesc = 'retentionPeriod_DESC',
  MediaDimensionsAsc = 'mediaDimensions_ASC',
  MediaDimensionsDesc = 'mediaDimensions_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type PostConnection = {
  __typename?: 'PostConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<PostEdge>>;
  aggregate: AggregatePost;
};

export type PostEdge = {
  __typename?: 'PostEdge';
  node: Post;
  cursor: Scalars['String'];
};

export type AggregatePost = {
  __typename?: 'AggregatePost';
  count: Scalars['Int'];
};

export type PostVideoWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export enum PostVideoOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  MuxAssetIdAsc = 'muxAssetId_ASC',
  MuxAssetIdDesc = 'muxAssetId_DESC',
  StreamKeyAsc = 'streamKey_ASC',
  StreamKeyDesc = 'streamKey_DESC',
  UploadIdAsc = 'uploadId_ASC',
  UploadIdDesc = 'uploadId_DESC',
  ThumbnailAsc = 'thumbnail_ASC',
  ThumbnailDesc = 'thumbnail_DESC',
  VideoGifAsc = 'videoGIF_ASC',
  VideoGifDesc = 'videoGIF_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  VideoStateAsc = 'videoState_ASC',
  VideoStateDesc = 'videoState_DESC',
  MuxPlaybackIdAsc = 'muxPlaybackId_ASC',
  MuxPlaybackIdDesc = 'muxPlaybackId_DESC',
  PlaybackPolicyAsc = 'playbackPolicy_ASC',
  PlaybackPolicyDesc = 'playbackPolicy_DESC',
  VideoUrlAsc = 'videoURL_ASC',
  VideoUrlDesc = 'videoURL_DESC',
  DurationAsc = 'duration_ASC',
  DurationDesc = 'duration_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type PostVideoConnection = {
  __typename?: 'PostVideoConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<PostVideoEdge>>;
  aggregate: AggregatePostVideo;
};

export type PostVideoEdge = {
  __typename?: 'PostVideoEdge';
  node: PostVideo;
  cursor: Scalars['String'];
};

export type AggregatePostVideo = {
  __typename?: 'AggregatePostVideo';
  count: Scalars['Int'];
};

export type RecurlyErrorWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type RecurlyError = {
  __typename?: 'RecurlyError';
  id: Scalars['ID'];
  person?: Maybe<Person>;
  recurlyId?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type RecurlyErrorWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  person?: Maybe<PersonWhereInput>;
  recurlyId?: Maybe<Scalars['String']>;
  recurlyId_not?: Maybe<Scalars['String']>;
  recurlyId_in?: Maybe<Array<Scalars['String']>>;
  recurlyId_not_in?: Maybe<Array<Scalars['String']>>;
  recurlyId_lt?: Maybe<Scalars['String']>;
  recurlyId_lte?: Maybe<Scalars['String']>;
  recurlyId_gt?: Maybe<Scalars['String']>;
  recurlyId_gte?: Maybe<Scalars['String']>;
  recurlyId_contains?: Maybe<Scalars['String']>;
  recurlyId_not_contains?: Maybe<Scalars['String']>;
  recurlyId_starts_with?: Maybe<Scalars['String']>;
  recurlyId_not_starts_with?: Maybe<Scalars['String']>;
  recurlyId_ends_with?: Maybe<Scalars['String']>;
  recurlyId_not_ends_with?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  error_not?: Maybe<Scalars['String']>;
  error_in?: Maybe<Array<Scalars['String']>>;
  error_not_in?: Maybe<Array<Scalars['String']>>;
  error_lt?: Maybe<Scalars['String']>;
  error_lte?: Maybe<Scalars['String']>;
  error_gt?: Maybe<Scalars['String']>;
  error_gte?: Maybe<Scalars['String']>;
  error_contains?: Maybe<Scalars['String']>;
  error_not_contains?: Maybe<Scalars['String']>;
  error_starts_with?: Maybe<Scalars['String']>;
  error_not_starts_with?: Maybe<Scalars['String']>;
  error_ends_with?: Maybe<Scalars['String']>;
  error_not_ends_with?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<RecurlyErrorWhereInput>>;
  OR?: Maybe<Array<RecurlyErrorWhereInput>>;
  NOT?: Maybe<Array<RecurlyErrorWhereInput>>;
};

export enum RecurlyErrorOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  RecurlyIdAsc = 'recurlyId_ASC',
  RecurlyIdDesc = 'recurlyId_DESC',
  ErrorAsc = 'error_ASC',
  ErrorDesc = 'error_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type RecurlyErrorConnection = {
  __typename?: 'RecurlyErrorConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<RecurlyErrorEdge>>;
  aggregate: AggregateRecurlyError;
};

export type RecurlyErrorEdge = {
  __typename?: 'RecurlyErrorEdge';
  node: RecurlyError;
  cursor: Scalars['String'];
};

export type AggregateRecurlyError = {
  __typename?: 'AggregateRecurlyError';
  count: Scalars['Int'];
};

export type RecurlyWebhookWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type RecurlyWebhook = {
  __typename?: 'RecurlyWebhook';
  id: Scalars['ID'];
  accountCode?: Maybe<Scalars['String']>;
  webhookAction?: Maybe<Scalars['String']>;
  webhookPayload?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type RecurlyWebhookWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  accountCode?: Maybe<Scalars['String']>;
  accountCode_not?: Maybe<Scalars['String']>;
  accountCode_in?: Maybe<Array<Scalars['String']>>;
  accountCode_not_in?: Maybe<Array<Scalars['String']>>;
  accountCode_lt?: Maybe<Scalars['String']>;
  accountCode_lte?: Maybe<Scalars['String']>;
  accountCode_gt?: Maybe<Scalars['String']>;
  accountCode_gte?: Maybe<Scalars['String']>;
  accountCode_contains?: Maybe<Scalars['String']>;
  accountCode_not_contains?: Maybe<Scalars['String']>;
  accountCode_starts_with?: Maybe<Scalars['String']>;
  accountCode_not_starts_with?: Maybe<Scalars['String']>;
  accountCode_ends_with?: Maybe<Scalars['String']>;
  accountCode_not_ends_with?: Maybe<Scalars['String']>;
  webhookAction?: Maybe<Scalars['String']>;
  webhookAction_not?: Maybe<Scalars['String']>;
  webhookAction_in?: Maybe<Array<Scalars['String']>>;
  webhookAction_not_in?: Maybe<Array<Scalars['String']>>;
  webhookAction_lt?: Maybe<Scalars['String']>;
  webhookAction_lte?: Maybe<Scalars['String']>;
  webhookAction_gt?: Maybe<Scalars['String']>;
  webhookAction_gte?: Maybe<Scalars['String']>;
  webhookAction_contains?: Maybe<Scalars['String']>;
  webhookAction_not_contains?: Maybe<Scalars['String']>;
  webhookAction_starts_with?: Maybe<Scalars['String']>;
  webhookAction_not_starts_with?: Maybe<Scalars['String']>;
  webhookAction_ends_with?: Maybe<Scalars['String']>;
  webhookAction_not_ends_with?: Maybe<Scalars['String']>;
  webhookPayload?: Maybe<Scalars['String']>;
  webhookPayload_not?: Maybe<Scalars['String']>;
  webhookPayload_in?: Maybe<Array<Scalars['String']>>;
  webhookPayload_not_in?: Maybe<Array<Scalars['String']>>;
  webhookPayload_lt?: Maybe<Scalars['String']>;
  webhookPayload_lte?: Maybe<Scalars['String']>;
  webhookPayload_gt?: Maybe<Scalars['String']>;
  webhookPayload_gte?: Maybe<Scalars['String']>;
  webhookPayload_contains?: Maybe<Scalars['String']>;
  webhookPayload_not_contains?: Maybe<Scalars['String']>;
  webhookPayload_starts_with?: Maybe<Scalars['String']>;
  webhookPayload_not_starts_with?: Maybe<Scalars['String']>;
  webhookPayload_ends_with?: Maybe<Scalars['String']>;
  webhookPayload_not_ends_with?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<RecurlyWebhookWhereInput>>;
  OR?: Maybe<Array<RecurlyWebhookWhereInput>>;
  NOT?: Maybe<Array<RecurlyWebhookWhereInput>>;
};

export enum RecurlyWebhookOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  AccountCodeAsc = 'accountCode_ASC',
  AccountCodeDesc = 'accountCode_DESC',
  WebhookActionAsc = 'webhookAction_ASC',
  WebhookActionDesc = 'webhookAction_DESC',
  WebhookPayloadAsc = 'webhookPayload_ASC',
  WebhookPayloadDesc = 'webhookPayload_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type RecurlyWebhookConnection = {
  __typename?: 'RecurlyWebhookConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<RecurlyWebhookEdge>>;
  aggregate: AggregateRecurlyWebhook;
};

export type RecurlyWebhookEdge = {
  __typename?: 'RecurlyWebhookEdge';
  node: RecurlyWebhook;
  cursor: Scalars['String'];
};

export type AggregateRecurlyWebhook = {
  __typename?: 'AggregateRecurlyWebhook';
  count: Scalars['Int'];
};

export type SettingWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
  key?: Maybe<Scalars['String']>;
};

export type Setting = {
  __typename?: 'Setting';
  id: Scalars['ID'];
  key?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<SettingType>;
  group?: Maybe<GroupType>;
  isHidden?: Maybe<Scalars['Boolean']>;
  defaultValue?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type SettingWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  key?: Maybe<Scalars['String']>;
  key_not?: Maybe<Scalars['String']>;
  key_in?: Maybe<Array<Scalars['String']>>;
  key_not_in?: Maybe<Array<Scalars['String']>>;
  key_lt?: Maybe<Scalars['String']>;
  key_lte?: Maybe<Scalars['String']>;
  key_gt?: Maybe<Scalars['String']>;
  key_gte?: Maybe<Scalars['String']>;
  key_contains?: Maybe<Scalars['String']>;
  key_not_contains?: Maybe<Scalars['String']>;
  key_starts_with?: Maybe<Scalars['String']>;
  key_not_starts_with?: Maybe<Scalars['String']>;
  key_ends_with?: Maybe<Scalars['String']>;
  key_not_ends_with?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Scalars['String']>>;
  title_not_in?: Maybe<Array<Scalars['String']>>;
  title_lt?: Maybe<Scalars['String']>;
  title_lte?: Maybe<Scalars['String']>;
  title_gt?: Maybe<Scalars['String']>;
  title_gte?: Maybe<Scalars['String']>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  title_starts_with?: Maybe<Scalars['String']>;
  title_not_starts_with?: Maybe<Scalars['String']>;
  title_ends_with?: Maybe<Scalars['String']>;
  title_not_ends_with?: Maybe<Scalars['String']>;
  type?: Maybe<SettingType>;
  type_not?: Maybe<SettingType>;
  type_in?: Maybe<Array<SettingType>>;
  type_not_in?: Maybe<Array<SettingType>>;
  group?: Maybe<GroupType>;
  group_not?: Maybe<GroupType>;
  group_in?: Maybe<Array<GroupType>>;
  group_not_in?: Maybe<Array<GroupType>>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isHidden_not?: Maybe<Scalars['Boolean']>;
  defaultValue?: Maybe<Scalars['String']>;
  defaultValue_not?: Maybe<Scalars['String']>;
  defaultValue_in?: Maybe<Array<Scalars['String']>>;
  defaultValue_not_in?: Maybe<Array<Scalars['String']>>;
  defaultValue_lt?: Maybe<Scalars['String']>;
  defaultValue_lte?: Maybe<Scalars['String']>;
  defaultValue_gt?: Maybe<Scalars['String']>;
  defaultValue_gte?: Maybe<Scalars['String']>;
  defaultValue_contains?: Maybe<Scalars['String']>;
  defaultValue_not_contains?: Maybe<Scalars['String']>;
  defaultValue_starts_with?: Maybe<Scalars['String']>;
  defaultValue_not_starts_with?: Maybe<Scalars['String']>;
  defaultValue_ends_with?: Maybe<Scalars['String']>;
  defaultValue_not_ends_with?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  order_not?: Maybe<Scalars['Int']>;
  order_in?: Maybe<Array<Scalars['Int']>>;
  order_not_in?: Maybe<Array<Scalars['Int']>>;
  order_lt?: Maybe<Scalars['Int']>;
  order_lte?: Maybe<Scalars['Int']>;
  order_gt?: Maybe<Scalars['Int']>;
  order_gte?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<SettingWhereInput>>;
  OR?: Maybe<Array<SettingWhereInput>>;
  NOT?: Maybe<Array<SettingWhereInput>>;
};

export enum SettingOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  GroupAsc = 'group_ASC',
  GroupDesc = 'group_DESC',
  IsHiddenAsc = 'isHidden_ASC',
  IsHiddenDesc = 'isHidden_DESC',
  DefaultValueAsc = 'defaultValue_ASC',
  DefaultValueDesc = 'defaultValue_DESC',
  OrderAsc = 'order_ASC',
  OrderDesc = 'order_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type SettingConnection = {
  __typename?: 'SettingConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<SettingEdge>>;
  aggregate: AggregateSetting;
};

export type SettingEdge = {
  __typename?: 'SettingEdge';
  node: Setting;
  cursor: Scalars['String'];
};

export type AggregateSetting = {
  __typename?: 'AggregateSetting';
  count: Scalars['Int'];
};

export type ShopifyWebhookWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type ShopifyWebhook = {
  __typename?: 'ShopifyWebhook';
  id: Scalars['ID'];
  topic?: Maybe<Scalars['String']>;
  payload?: Maybe<Scalars['Json']>;
  error?: Maybe<Scalars['Json']>;
  orderId?: Maybe<Scalars['String']>;
  result?: Maybe<ResultType>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export enum ResultType {
  Success = 'SUCCESS',
  Failed = 'FAILED'
}

export type ShopifyWebhookWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  topic?: Maybe<Scalars['String']>;
  topic_not?: Maybe<Scalars['String']>;
  topic_in?: Maybe<Array<Scalars['String']>>;
  topic_not_in?: Maybe<Array<Scalars['String']>>;
  topic_lt?: Maybe<Scalars['String']>;
  topic_lte?: Maybe<Scalars['String']>;
  topic_gt?: Maybe<Scalars['String']>;
  topic_gte?: Maybe<Scalars['String']>;
  topic_contains?: Maybe<Scalars['String']>;
  topic_not_contains?: Maybe<Scalars['String']>;
  topic_starts_with?: Maybe<Scalars['String']>;
  topic_not_starts_with?: Maybe<Scalars['String']>;
  topic_ends_with?: Maybe<Scalars['String']>;
  topic_not_ends_with?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['String']>;
  orderId_not?: Maybe<Scalars['String']>;
  orderId_in?: Maybe<Array<Scalars['String']>>;
  orderId_not_in?: Maybe<Array<Scalars['String']>>;
  orderId_lt?: Maybe<Scalars['String']>;
  orderId_lte?: Maybe<Scalars['String']>;
  orderId_gt?: Maybe<Scalars['String']>;
  orderId_gte?: Maybe<Scalars['String']>;
  orderId_contains?: Maybe<Scalars['String']>;
  orderId_not_contains?: Maybe<Scalars['String']>;
  orderId_starts_with?: Maybe<Scalars['String']>;
  orderId_not_starts_with?: Maybe<Scalars['String']>;
  orderId_ends_with?: Maybe<Scalars['String']>;
  orderId_not_ends_with?: Maybe<Scalars['String']>;
  result?: Maybe<ResultType>;
  result_not?: Maybe<ResultType>;
  result_in?: Maybe<Array<ResultType>>;
  result_not_in?: Maybe<Array<ResultType>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<ShopifyWebhookWhereInput>>;
  OR?: Maybe<Array<ShopifyWebhookWhereInput>>;
  NOT?: Maybe<Array<ShopifyWebhookWhereInput>>;
};

export enum ShopifyWebhookOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TopicAsc = 'topic_ASC',
  TopicDesc = 'topic_DESC',
  PayloadAsc = 'payload_ASC',
  PayloadDesc = 'payload_DESC',
  ErrorAsc = 'error_ASC',
  ErrorDesc = 'error_DESC',
  OrderIdAsc = 'orderId_ASC',
  OrderIdDesc = 'orderId_DESC',
  ResultAsc = 'result_ASC',
  ResultDesc = 'result_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type ShopifyWebhookConnection = {
  __typename?: 'ShopifyWebhookConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<ShopifyWebhookEdge>>;
  aggregate: AggregateShopifyWebhook;
};

export type ShopifyWebhookEdge = {
  __typename?: 'ShopifyWebhookEdge';
  node: ShopifyWebhook;
  cursor: Scalars['String'];
};

export type AggregateShopifyWebhook = {
  __typename?: 'AggregateShopifyWebhook';
  count: Scalars['Int'];
};

export type TemplateWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export enum TemplateOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type TemplateConnection = {
  __typename?: 'TemplateConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<TemplateEdge>>;
  aggregate: AggregateTemplate;
};

export type TemplateEdge = {
  __typename?: 'TemplateEdge';
  node: Template;
  cursor: Scalars['String'];
};

export type AggregateTemplate = {
  __typename?: 'AggregateTemplate';
  count: Scalars['Int'];
};

export type TemplatePermissionWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type TemplatePermissionConnection = {
  __typename?: 'TemplatePermissionConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<TemplatePermissionEdge>>;
  aggregate: AggregateTemplatePermission;
};

export type TemplatePermissionEdge = {
  __typename?: 'TemplatePermissionEdge';
  node: TemplatePermission;
  cursor: Scalars['String'];
};

export type AggregateTemplatePermission = {
  __typename?: 'AggregateTemplatePermission';
  count: Scalars['Int'];
};

export type UserBadgeWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type UserBadge = {
  __typename?: 'UserBadge';
  id: Scalars['ID'];
  person: Person;
  badge: Badge;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  isProfileImage?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type UserBadgeWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  person?: Maybe<PersonWhereInput>;
  badge?: Maybe<BadgeWhereInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  isProfileImage?: Maybe<Scalars['Boolean']>;
  isProfileImage_not?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isDeleted_not?: Maybe<Scalars['Boolean']>;
  AND?: Maybe<Array<UserBadgeWhereInput>>;
  OR?: Maybe<Array<UserBadgeWhereInput>>;
  NOT?: Maybe<Array<UserBadgeWhereInput>>;
};

export enum UserBadgeOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  IsProfileImageAsc = 'isProfileImage_ASC',
  IsProfileImageDesc = 'isProfileImage_DESC',
  IsDeletedAsc = 'isDeleted_ASC',
  IsDeletedDesc = 'isDeleted_DESC'
}

export type UserBadgeConnection = {
  __typename?: 'UserBadgeConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<UserBadgeEdge>>;
  aggregate: AggregateUserBadge;
};

export type UserBadgeEdge = {
  __typename?: 'UserBadgeEdge';
  node: UserBadge;
  cursor: Scalars['String'];
};

export type AggregateUserBadge = {
  __typename?: 'AggregateUserBadge';
  count: Scalars['Int'];
};

export type UserSettingWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type UserSetting = {
  __typename?: 'UserSetting';
  id: Scalars['ID'];
  person?: Maybe<Person>;
  setting?: Maybe<Setting>;
  value?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type UserSettingWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  person?: Maybe<PersonWhereInput>;
  setting?: Maybe<SettingWhereInput>;
  value?: Maybe<Scalars['String']>;
  value_not?: Maybe<Scalars['String']>;
  value_in?: Maybe<Array<Scalars['String']>>;
  value_not_in?: Maybe<Array<Scalars['String']>>;
  value_lt?: Maybe<Scalars['String']>;
  value_lte?: Maybe<Scalars['String']>;
  value_gt?: Maybe<Scalars['String']>;
  value_gte?: Maybe<Scalars['String']>;
  value_contains?: Maybe<Scalars['String']>;
  value_not_contains?: Maybe<Scalars['String']>;
  value_starts_with?: Maybe<Scalars['String']>;
  value_not_starts_with?: Maybe<Scalars['String']>;
  value_ends_with?: Maybe<Scalars['String']>;
  value_not_ends_with?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<UserSettingWhereInput>>;
  OR?: Maybe<Array<UserSettingWhereInput>>;
  NOT?: Maybe<Array<UserSettingWhereInput>>;
};

export enum UserSettingOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  ValueAsc = 'value_ASC',
  ValueDesc = 'value_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type UserSettingConnection = {
  __typename?: 'UserSettingConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<UserSettingEdge>>;
  aggregate: AggregateUserSetting;
};

export type UserSettingEdge = {
  __typename?: 'UserSettingEdge';
  node: UserSetting;
  cursor: Scalars['String'];
};

export type AggregateUserSetting = {
  __typename?: 'AggregateUserSetting';
  count: Scalars['Int'];
};

export type WatchTimeWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type WatchTime = {
  __typename?: 'WatchTime';
  id: Scalars['ID'];
  person: Person;
  entityId?: Maybe<Scalars['String']>;
  watchTime?: Maybe<Scalars['Float']>;
  entityFormat: WatchEntityFormat;
  entityType: WatchEntityType;
  personEntityId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export enum WatchEntityFormat {
  Audio = 'AUDIO',
  Video = 'VIDEO'
}

export enum WatchEntityType {
  Video = 'VIDEO',
  Clip = 'CLIP',
  EpisodeSegment = 'EPISODE_SEGMENT',
  PodcastEpisode = 'PODCAST_EPISODE'
}

export type WatchTimeWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  person?: Maybe<PersonWhereInput>;
  entityId?: Maybe<Scalars['String']>;
  entityId_not?: Maybe<Scalars['String']>;
  entityId_in?: Maybe<Array<Scalars['String']>>;
  entityId_not_in?: Maybe<Array<Scalars['String']>>;
  entityId_lt?: Maybe<Scalars['String']>;
  entityId_lte?: Maybe<Scalars['String']>;
  entityId_gt?: Maybe<Scalars['String']>;
  entityId_gte?: Maybe<Scalars['String']>;
  entityId_contains?: Maybe<Scalars['String']>;
  entityId_not_contains?: Maybe<Scalars['String']>;
  entityId_starts_with?: Maybe<Scalars['String']>;
  entityId_not_starts_with?: Maybe<Scalars['String']>;
  entityId_ends_with?: Maybe<Scalars['String']>;
  entityId_not_ends_with?: Maybe<Scalars['String']>;
  watchTime?: Maybe<Scalars['Float']>;
  watchTime_not?: Maybe<Scalars['Float']>;
  watchTime_in?: Maybe<Array<Scalars['Float']>>;
  watchTime_not_in?: Maybe<Array<Scalars['Float']>>;
  watchTime_lt?: Maybe<Scalars['Float']>;
  watchTime_lte?: Maybe<Scalars['Float']>;
  watchTime_gt?: Maybe<Scalars['Float']>;
  watchTime_gte?: Maybe<Scalars['Float']>;
  entityFormat?: Maybe<WatchEntityFormat>;
  entityFormat_not?: Maybe<WatchEntityFormat>;
  entityFormat_in?: Maybe<Array<WatchEntityFormat>>;
  entityFormat_not_in?: Maybe<Array<WatchEntityFormat>>;
  entityType?: Maybe<WatchEntityType>;
  entityType_not?: Maybe<WatchEntityType>;
  entityType_in?: Maybe<Array<WatchEntityType>>;
  entityType_not_in?: Maybe<Array<WatchEntityType>>;
  personEntityId?: Maybe<Scalars['String']>;
  personEntityId_not?: Maybe<Scalars['String']>;
  personEntityId_in?: Maybe<Array<Scalars['String']>>;
  personEntityId_not_in?: Maybe<Array<Scalars['String']>>;
  personEntityId_lt?: Maybe<Scalars['String']>;
  personEntityId_lte?: Maybe<Scalars['String']>;
  personEntityId_gt?: Maybe<Scalars['String']>;
  personEntityId_gte?: Maybe<Scalars['String']>;
  personEntityId_contains?: Maybe<Scalars['String']>;
  personEntityId_not_contains?: Maybe<Scalars['String']>;
  personEntityId_starts_with?: Maybe<Scalars['String']>;
  personEntityId_not_starts_with?: Maybe<Scalars['String']>;
  personEntityId_ends_with?: Maybe<Scalars['String']>;
  personEntityId_not_ends_with?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<WatchTimeWhereInput>>;
  OR?: Maybe<Array<WatchTimeWhereInput>>;
  NOT?: Maybe<Array<WatchTimeWhereInput>>;
};

export enum WatchTimeOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  EntityIdAsc = 'entityId_ASC',
  EntityIdDesc = 'entityId_DESC',
  WatchTimeAsc = 'watchTime_ASC',
  WatchTimeDesc = 'watchTime_DESC',
  EntityFormatAsc = 'entityFormat_ASC',
  EntityFormatDesc = 'entityFormat_DESC',
  EntityTypeAsc = 'entityType_ASC',
  EntityTypeDesc = 'entityType_DESC',
  PersonEntityIdAsc = 'personEntityId_ASC',
  PersonEntityIdDesc = 'personEntityId_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type WatchTimeConnection = {
  __typename?: 'WatchTimeConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<WatchTimeEdge>>;
  aggregate: AggregateWatchTime;
};

export type WatchTimeEdge = {
  __typename?: 'WatchTimeEdge';
  node: WatchTime;
  cursor: Scalars['String'];
};

export type AggregateWatchTime = {
  __typename?: 'AggregateWatchTime';
  count: Scalars['Int'];
};

export type Node = {
  id: Scalars['ID'];
};

export type SignedUrlDataInput = {
  fileName: Scalars['String'];
  contentType: Scalars['String'];
};

export type SignedUrlType = {
  __typename?: 'SignedUrlType';
  fileName: Scalars['String'];
  signedUrl: Scalars['String'];
  getUrl: Scalars['String'];
};

export enum BadgeOrderEnum {
  WeightDesc = 'weight_DESC',
  WeightAsc = 'weight_ASC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC'
}

export type UserBadges = {
  __typename?: 'UserBadges';
  badges?: Maybe<Array<Maybe<BadgeCustom>>>;
};

export type BadgeCustom = {
  __typename?: 'BadgeCustom';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  defaultPlaceHolderImage?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<BadgeType>;
  weight?: Maybe<Scalars['Int']>;
  isCustom?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isProfileImage?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type CommentThreadWhereInput = {
  post: PostWhereUniqueInput;
  parent?: Maybe<CommentWhereUniqueInput>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
};

export type NoteworthyCommentsInput = {
  id?: Maybe<Scalars['ID']>;
};

export type CommentImageSignedPutUrlDataInput = {
  fileName: Scalars['String'];
};

export type ListUserNameWhereInput = {
  post: PostWhereUniqueInput;
  userName?: Maybe<Scalars['String']>;
};

export type ListUserNameRes = {
  __typename?: 'listUserNameRes';
  userName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};

export type CustomDiscussionWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export enum CustomDiscussionThreadOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC'
}

export type DiscussionData = {
  __typename?: 'DiscussionData';
  id: Scalars['ID'];
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  isLive?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Discussion_Type>;
  scheduleAt?: Maybe<Scalars['DateTime']>;
  createdBy: Person;
  owner: Person;
  messages?: Maybe<Array<CustomMessage>>;
  messagesCount?: Maybe<Scalars['Int']>;
  startTime?: Maybe<Scalars['DateTime']>;
  endTime?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};


export type DiscussionDataMessagesArgs = {
  where?: Maybe<MessageWhereInput>;
  orderBy?: Maybe<MessageOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};

export type CustomMessage = {
  __typename?: 'CustomMessage';
  id: Scalars['ID'];
  message: Scalars['String'];
  likesCount?: Maybe<Scalars['Int']>;
  likes?: Maybe<Array<Person>>;
  discussion: Discussion;
  depth: Scalars['Int'];
  replies?: Maybe<Array<Maybe<CustomMessage>>>;
  parent?: Maybe<Message>;
  isBlocked?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  flags?: Maybe<Array<Person>>;
  flagsCount?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<Person>;
  userType?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};


export type CustomMessageLikesArgs = {
  where?: Maybe<PersonWhereInput>;
  orderBy?: Maybe<PersonOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type CustomMessageRepliesArgs = {
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
};


export type CustomMessageFlagsArgs = {
  where?: Maybe<PersonWhereInput>;
  orderBy?: Maybe<PersonOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type LiveDiscussionWhereInput = {
  orderBy?: Maybe<DiscussionOrderByInput>;
};

export type ModerationComment = {
  __typename?: 'ModerationComment';
  id?: Maybe<Scalars['ID']>;
  comment?: Maybe<Scalars['String']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isHighlighted?: Maybe<Scalars['Boolean']>;
  isAccepted?: Maybe<Scalars['Boolean']>;
  replyCount?: Maybe<Scalars['Int']>;
  deletedBy?: Maybe<Person>;
  likes?: Maybe<Array<Person>>;
  likeCount?: Maybe<Scalars['Int']>;
  starCount?: Maybe<Scalars['Int']>;
  flagCount?: Maybe<Scalars['Int']>;
  flags?: Maybe<Array<Person>>;
  stars?: Maybe<Array<Person>>;
  mentions?: Maybe<Array<Person>>;
  replies?: Maybe<Array<Maybe<ModerationComment>>>;
  post?: Maybe<Post>;
  parent?: Maybe<Comment>;
  createdBy?: Maybe<Person>;
  moderationResponse?: Maybe<Scalars['Json']>;
  moderationScore?: Maybe<Scalars['Float']>;
  moderationLabel?: Maybe<Scalars['String']>;
  moderationError?: Maybe<Scalars['Json']>;
  mediaModerationResponse?: Maybe<Scalars['Json']>;
  mediaModerationError?: Maybe<Scalars['Json']>;
  mediaType?: Maybe<CommentMediaType>;
  mediaURL?: Maybe<Scalars['String']>;
  mediaDimensions?: Maybe<Scalars['Json']>;
  giphyId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  activityLogs?: Maybe<Array<Maybe<ActivityLog>>>;
};


export type ModerationCommentLikesArgs = {
  where?: Maybe<PersonWhereInput>;
  orderBy?: Maybe<PersonOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type ModerationCommentFlagsArgs = {
  where?: Maybe<PersonWhereInput>;
  orderBy?: Maybe<PersonOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type ModerationCommentStarsArgs = {
  where?: Maybe<PersonWhereInput>;
  orderBy?: Maybe<PersonOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type ModerationCommentMentionsArgs = {
  where?: Maybe<PersonWhereInput>;
  orderBy?: Maybe<PersonOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type ModerationCommentRepliesArgs = {
  where?: Maybe<CommentThreadModerationWhereInput>;
  orderBy?: Maybe<CommentThreadModerationOrderBy>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


export type ModerationCommentActivityLogsArgs = {
  orderBy?: Maybe<ActivityLogOrderByInput>;
};

export type CommentThreadModerationWhereInput = {
  author?: Maybe<PersonWhereUniqueInput>;
  post: PostWhereUniqueInput;
  parent?: Maybe<CommentWhereUniqueInput>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isFlagged?: Maybe<Scalars['Boolean']>;
  searchTerm?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
};

export enum CommentThreadModerationOrderBy {
  Recent = 'RECENT',
  MostFlagged = 'MOST_FLAGGED'
}

export type ListPostModerationWhereInput = {
  id?: Maybe<Scalars['ID']>;
  author?: Maybe<PersonWhereUniqueInput>;
  searchTerm?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  isLive?: Maybe<Scalars['Boolean']>;
};

export enum ListPostModerationOrder {
  LikesCountAsc = 'likesCount_ASC',
  LikesCountDesc = 'likesCount_DESC',
  CommentsCountAsc = 'commentsCount_ASC',
  CommentsCountDesc = 'commentsCount_DESC',
  ViewsCountAsc = 'viewsCount_ASC',
  ViewsCountDesc = 'viewsCount_DESC',
  PlaysCountAsc = 'playsCount_ASC',
  PlaysCountDesc = 'playsCount_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type ListCommentsModerationWhereInput = {
  id?: Maybe<Scalars['ID']>;
  searchTerm?: Maybe<Scalars['String']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isHighlighted?: Maybe<Scalars['Boolean']>;
  isAccepted?: Maybe<Scalars['Boolean']>;
  isLive?: Maybe<Scalars['Boolean']>;
  author?: Maybe<PersonWhereUniqueInput>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
};

export enum ListCommentsModerationOrderBy {
  FlagCountDesc = 'flagCount_DESC',
  ModerationScoreDesc = 'moderationScore_DESC',
  CreatedAtDesc = 'createdAt_DESC',
  CreatedAtAsc = 'createdAt_ASC'
}

export type ListCommentPersonWhereInput = {
  isBanned?: Maybe<Scalars['Boolean']>;
  personId?: Maybe<Scalars['ID']>;
};

export type CommentPerson = {
  __typename?: 'CommentPerson';
  personDetails?: Maybe<Person>;
  commentCount?: Maybe<Scalars['Int']>;
  likeCount?: Maybe<Scalars['Int']>;
  flagCount?: Maybe<Scalars['Int']>;
  moderationScoreAverage?: Maybe<Scalars['Float']>;
  moderationDetails?: Maybe<Array<Maybe<ModerationLabel>>>;
};

export type ModerationLabel = {
  __typename?: 'ModerationLabel';
  label?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
};

export type GetPersonPermissionWhereInput = {
  personId?: Maybe<Scalars['ID']>;
};

export type PermissionCustom = {
  __typename?: 'PermissionCustom';
  id?: Maybe<Scalars['ID']>;
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  canRead?: Maybe<Scalars['Boolean']>;
  canMutate?: Maybe<Scalars['Boolean']>;
};

export type GetPersonPermissionRes = {
  __typename?: 'GetPersonPermissionRes';
  permissionTemplate?: Maybe<Template>;
  permissions?: Maybe<Array<Maybe<PermissionCustom>>>;
};

export type RecurlyPerson = {
  __typename?: 'RecurlyPerson';
  id: Scalars['ID'];
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  profileImage?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  effectivePlan?: Maybe<Scalars['String']>;
  authZeroID?: Maybe<Scalars['String']>;
  recurlyId?: Maybe<Scalars['String']>;
  recurlyPlan?: Maybe<Plans>;
  promoPlan?: Maybe<Plans>;
  promoPlanExpiration?: Maybe<Scalars['DateTime']>;
  role: Role;
  userName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  settings?: Maybe<Array<Maybe<SettingCustom>>>;
};

export type SyncRecurlyIdInput = {
  recurlyId: Scalars['String'];
};

export type PersonDetails = {
  __typename?: 'PersonDetails';
  person?: Maybe<RecurlyPerson>;
  badges?: Maybe<Array<Maybe<BadgeCustom>>>;
};

export type PostImageSignedPutUrlDataInput = {
  fileName: Scalars['String'];
};

export type PostVideoUploadSignedUrlDataInput = {
  corsOrigin?: Maybe<Scalars['String']>;
};

export type UploadUrlType = {
  __typename?: 'UploadUrlType';
  signedUrl?: Maybe<Scalars['String']>;
  postVideoID?: Maybe<Scalars['ID']>;
};

export enum ListPostOrder {
  Recent = 'RECENT',
  MostLiked = 'MOST_LIKED',
  MostCommented = 'MOST_COMMENTED'
}

export type ListAuthorWhereInput = {
  id?: Maybe<Scalars['ID']>;
};

export type AuthorRes = {
  __typename?: 'AuthorRes';
  lastActivityAt?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Person>;
};

export type RecurlyIdInput = {
  id?: Maybe<Scalars['String']>;
};

export type RecurlyAccount = {
  __typename?: 'RecurlyAccount';
  id?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  account_code?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  subscription?: Maybe<RecurlyIdUrlType>;
  invoice?: Maybe<RecurlyIdUrlType>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  has_live_subscription?: Maybe<Scalars['String']>;
  has_active_subscription?: Maybe<Scalars['String']>;
  has_future_subscription?: Maybe<Scalars['String']>;
  has_canceled_subscription?: Maybe<Scalars['String']>;
  has_past_due_invoice?: Maybe<Scalars['String']>;
  has_paused_subscription?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
  closed_at?: Maybe<Scalars['String']>;
};

export type RecurlyIdUrlType = {
  __typename?: 'RecurlyIdUrlType';
  id?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type RecurlyLimitInput = {
  limit?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['String']>;
};

export type RecurlyAccountCodeInput = {
  account_code?: Maybe<Scalars['String']>;
};

export type RecurlyShippingAddress = {
  __typename?: 'RecurlyShippingAddress';
  id?: Maybe<Scalars['String']>;
  account_code?: Maybe<Scalars['String']>;
  subscription?: Maybe<RecurlyIdUrlType>;
  nickname?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
};

export type RecurlySubscription = {
  __typename?: 'RecurlySubscription';
  id?: Maybe<Scalars['String']>;
  user?: Maybe<RecurlyIdUrlType>;
  invoice?: Maybe<RecurlyIdUrlType>;
  plan_name?: Maybe<Scalars['String']>;
  plan_code?: Maybe<Scalars['String']>;
  pending_subscription?: Maybe<RecurlyPendingSubscription>;
  auto_renew?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  renewal_billing_cycles?: Maybe<Scalars['String']>;
  unit_amount_in_cents?: Maybe<Scalars['Int']>;
  currency?: Maybe<Scalars['String']>;
  activated_at?: Maybe<Scalars['String']>;
  canceled_at?: Maybe<Scalars['String']>;
  expires_at?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
  current_period_started_at?: Maybe<Scalars['String']>;
  current_period_ends_at?: Maybe<Scalars['String']>;
  trial_started_at?: Maybe<Scalars['String']>;
  trial_ends_at?: Maybe<Scalars['String']>;
};

export type RecurlyPendingSubscription = {
  __typename?: 'RecurlyPendingSubscription';
  plan_name?: Maybe<Scalars['String']>;
  plan_code?: Maybe<Scalars['String']>;
  unit_amount_in_cents?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['String']>;
};

export type RecurlySubscriptionsInput = {
  account_code?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
};

export type RecurlyInvoice = {
  __typename?: 'RecurlyInvoice';
  id?: Maybe<Scalars['String']>;
  user?: Maybe<RecurlyIdUrlType>;
  state?: Maybe<Scalars['String']>;
  due_on?: Maybe<Scalars['String']>;
  customer_notes?: Maybe<Scalars['String']>;
  subtotal_before_discount_in_cents?: Maybe<Scalars['Int']>;
  subtotal_in_cents?: Maybe<Scalars['Int']>;
  discount_in_cents?: Maybe<Scalars['Int']>;
  tax_in_cents?: Maybe<Scalars['Int']>;
  total_in_cents?: Maybe<Scalars['Int']>;
  currency?: Maybe<Scalars['String']>;
  vat_number?: Maybe<Scalars['String']>;
  line_items?: Maybe<Array<Maybe<RecurlyLineItems>>>;
  address?: Maybe<RecurlyAddress>;
  created_at?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
};

export type RecurlyLineItems = {
  __typename?: 'RecurlyLineItems';
  id?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  unit_amount_in_cents?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['String']>;
  discount_in_cents?: Maybe<Scalars['Int']>;
  tax_in_cents?: Maybe<Scalars['Int']>;
  total_in_cents?: Maybe<Scalars['Int']>;
  currency?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['String']>;
};

export type RecurlyAddress = {
  __typename?: 'RecurlyAddress';
  name_on_account?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type RecurlyInvoicesInput = {
  account_code?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
};

export type RecurlyBillingInfo = {
  __typename?: 'RecurlyBillingInfo';
  id?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  vat_number?: Maybe<Scalars['String']>;
  card_type?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['String']>;
  month?: Maybe<Scalars['String']>;
  first_six?: Maybe<Scalars['String']>;
  last_four?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
};

export type RecurlyPlan = {
  __typename?: 'RecurlyPlan';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  auto_renew?: Maybe<Scalars['String']>;
  revenue_schedule_type?: Maybe<Scalars['String']>;
  trial_requires_billing_info?: Maybe<Scalars['String']>;
  unit_amount_in_cents?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
};

export type RecurlyCoupon = {
  __typename?: 'RecurlyCoupon';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  redemptions?: Maybe<Scalars['String']>;
  coupon_code?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  discount_type?: Maybe<Scalars['String']>;
  discount_percent?: Maybe<Scalars['String']>;
  single_use?: Maybe<Scalars['String']>;
  applies_to_all_plans?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['String']>;
  applies_to_non_plan_charges?: Maybe<Scalars['String']>;
  redemption_resource?: Maybe<Scalars['String']>;
  max_redemptions_per_account?: Maybe<Scalars['String']>;
  coupon_type?: Maybe<Scalars['String']>;
};

export type RecurlyGiftCard = {
  __typename?: 'RecurlyGiftCard';
  id?: Maybe<Scalars['String']>;
  gifter_account?: Maybe<RecurlyIdUrlType>;
  invoice?: Maybe<RecurlyIdUrlType>;
  recipient_account?: Maybe<RecurlyIdUrlType>;
  redemption_code?: Maybe<Scalars['String']>;
  balance_in_cents?: Maybe<Scalars['Int']>;
  currency?: Maybe<Scalars['String']>;
  product_code?: Maybe<Scalars['String']>;
  unit_amount_in_cents?: Maybe<Scalars['Int']>;
  delivery?: Maybe<RecurlyDeliveryType>;
  created_at?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
  delivered_at?: Maybe<Scalars['String']>;
  redeemed_at?: Maybe<Scalars['String']>;
};

export type RecurlyDeliveryType = {
  __typename?: 'RecurlyDeliveryType';
  method?: Maybe<Scalars['String']>;
  deliver_at?: Maybe<Scalars['String']>;
  email_address?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  gifter_name?: Maybe<Scalars['String']>;
  personal_message?: Maybe<Scalars['String']>;
};

export type GetShopifyMultipassUrlRes = {
  __typename?: 'getShopifyMultipassUrlRes';
  url?: Maybe<Scalars['String']>;
};

export type GetShopifyAccessTokenRes = {
  __typename?: 'getShopifyAccessTokenRes';
  multipassToken?: Maybe<Scalars['String']>;
  customerAccessToken?: Maybe<CustomerAccessToken>;
  customerUserErrors?: Maybe<CustomerUserErrors>;
};

export type CustomerAccessToken = {
  __typename?: 'CustomerAccessToken';
  accessToken?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
};

export type CustomerUserErrors = {
  __typename?: 'CustomerUserErrors';
  code?: Maybe<CustomerErrorCode>;
  field?: Maybe<Array<Maybe<Scalars['String']>>>;
  message?: Maybe<Scalars['String']>;
};

export enum CustomerErrorCode {
  AlreadyEnabled = 'ALREADY_ENABLED',
  BadDomain = 'BAD_DOMAIN',
  Blank = 'BLANK',
  ContainsHtmlTags = 'CONTAINS_HTML_TAGS',
  ContainsUrl = 'CONTAINS_URL',
  CustomerDisabled = 'CUSTOMER_DISABLED',
  Invalid = 'INVALID',
  InvalidMultipassRequest = 'INVALID_MULTIPASS_REQUEST',
  NotFound = 'NOT_FOUND',
  PasswordStartsOrEndsWithWhitespace = 'PASSWORD_STARTS_OR_ENDS_WITH_WHITESPACE',
  Taken = 'TAKEN',
  TokenInvalid = 'TOKEN_INVALID',
  TooLong = 'TOO_LONG',
  TooShort = 'TOO_SHORT',
  UnidentifiedCustomer = 'UNIDENTIFIED_CUSTOMER'
}

export type GetSettingWhereInput = {
  id?: Maybe<Scalars['ID']>;
};

export type GetWatchTimeInput = {
  entityId: Scalars['ID'];
  entityFormat: WatchEntityFormat;
};

export type GetRecentEntityWhereInput = {
  entityFormat?: Maybe<WatchEntityFormat>;
  entityType?: Maybe<WatchEntityType>;
};

export type GetRecentEntityRes = {
  __typename?: 'getRecentEntityRes';
  entityId?: Maybe<Scalars['ID']>;
  entityFormat?: Maybe<WatchEntityFormat>;
  entityType?: Maybe<WatchEntityType>;
  watchTime?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createActivityLog: ActivityLog;
  updateActivityLog?: Maybe<ActivityLog>;
  updateManyActivityLogs: BatchPayload;
  upsertActivityLog: ActivityLog;
  deleteActivityLog?: Maybe<ActivityLog>;
  deleteManyActivityLogs: BatchPayload;
  createArchiveMessage: ArchiveMessage;
  updateArchiveMessage?: Maybe<ArchiveMessage>;
  updateManyArchiveMessages: BatchPayload;
  upsertArchiveMessage: ArchiveMessage;
  deleteArchiveMessage?: Maybe<ArchiveMessage>;
  deleteManyArchiveMessages: BatchPayload;
  createBadge: Badge;
  updateBadge?: Maybe<Badge>;
  updateManyBadges: BatchPayload;
  upsertBadge: Badge;
  deleteBadge?: Maybe<Badge>;
  deleteManyBadges: BatchPayload;
  createComment: Comment;
  updateComment?: Maybe<Comment>;
  updateManyComments: BatchPayload;
  upsertComment: Comment;
  deleteComment?: Maybe<Comment>;
  deleteManyComments: BatchPayload;
  createConfiguration: Configuration;
  updateConfiguration?: Maybe<Configuration>;
  updateManyConfigurations: BatchPayload;
  upsertConfiguration: Configuration;
  deleteConfiguration?: Maybe<Configuration>;
  deleteManyConfigurations: BatchPayload;
  createDiscussion: Discussion;
  updateDiscussion?: Maybe<Discussion>;
  updateManyDiscussions: BatchPayload;
  upsertDiscussion: Discussion;
  deleteDiscussion?: Maybe<Discussion>;
  deleteManyDiscussions: BatchPayload;
  createEmailChangeRequest: EmailChangeRequest;
  updateEmailChangeRequest?: Maybe<EmailChangeRequest>;
  updateManyEmailChangeRequests: BatchPayload;
  upsertEmailChangeRequest: EmailChangeRequest;
  deleteEmailChangeRequest?: Maybe<EmailChangeRequest>;
  deleteManyEmailChangeRequests: BatchPayload;
  createMessage: Message;
  updateMessage?: Maybe<Message>;
  updateManyMessages: BatchPayload;
  upsertMessage: Message;
  deleteMessage?: Maybe<Message>;
  deleteManyMessages: BatchPayload;
  createNotification: Notification;
  updateNotification?: Maybe<Notification>;
  updateManyNotifications: BatchPayload;
  upsertNotification: Notification;
  deleteNotification?: Maybe<Notification>;
  deleteManyNotifications: BatchPayload;
  createNotificationUser: NotificationUser;
  updateNotificationUser?: Maybe<NotificationUser>;
  updateManyNotificationUsers: BatchPayload;
  upsertNotificationUser: NotificationUser;
  deleteNotificationUser?: Maybe<NotificationUser>;
  deleteManyNotificationUsers: BatchPayload;
  createOneSignalPerson: OneSignalPerson;
  updateOneSignalPerson?: Maybe<OneSignalPerson>;
  updateManyOneSignalPersons: BatchPayload;
  upsertOneSignalPerson: OneSignalPerson;
  deleteOneSignalPerson?: Maybe<OneSignalPerson>;
  deleteManyOneSignalPersons: BatchPayload;
  createOrder: Order;
  updateOrder?: Maybe<Order>;
  updateManyOrders: BatchPayload;
  upsertOrder: Order;
  deleteOrder?: Maybe<Order>;
  deleteManyOrders: BatchPayload;
  createPermission: Permission;
  updatePermission?: Maybe<Permission>;
  updateManyPermissions: BatchPayload;
  upsertPermission: Permission;
  deletePermission?: Maybe<Permission>;
  deleteManyPermissions: BatchPayload;
  createPerson: Person;
  updatePerson?: Maybe<Person>;
  updateManyPersons: BatchPayload;
  upsertPerson: Person;
  deletePerson?: Maybe<Person>;
  deleteManyPersons: BatchPayload;
  createPersonPermission: PersonPermission;
  updatePersonPermission?: Maybe<PersonPermission>;
  updateManyPersonPermissions: BatchPayload;
  upsertPersonPermission: PersonPermission;
  deletePersonPermission?: Maybe<PersonPermission>;
  deleteManyPersonPermissions: BatchPayload;
  createPost: Post;
  updatePost?: Maybe<Post>;
  updateManyPosts: BatchPayload;
  upsertPost: Post;
  deletePost?: Maybe<Post>;
  deleteManyPosts: BatchPayload;
  createPostVideo: PostVideo;
  updatePostVideo?: Maybe<PostVideo>;
  updateManyPostVideos: BatchPayload;
  upsertPostVideo: PostVideo;
  deletePostVideo?: Maybe<PostVideo>;
  deleteManyPostVideos: BatchPayload;
  createRecurlyError: RecurlyError;
  updateRecurlyError?: Maybe<RecurlyError>;
  updateManyRecurlyErrors: BatchPayload;
  upsertRecurlyError: RecurlyError;
  deleteRecurlyError?: Maybe<RecurlyError>;
  deleteManyRecurlyErrors: BatchPayload;
  createRecurlyWebhook: RecurlyWebhook;
  updateRecurlyWebhook?: Maybe<RecurlyWebhook>;
  updateManyRecurlyWebhooks: BatchPayload;
  upsertRecurlyWebhook: RecurlyWebhook;
  deleteRecurlyWebhook?: Maybe<RecurlyWebhook>;
  deleteManyRecurlyWebhooks: BatchPayload;
  createSetting: Setting;
  updateSetting?: Maybe<Setting>;
  updateManySettings: BatchPayload;
  upsertSetting: Setting;
  deleteSetting?: Maybe<Setting>;
  deleteManySettings: BatchPayload;
  createShopifyWebhook: ShopifyWebhook;
  updateShopifyWebhook?: Maybe<ShopifyWebhook>;
  updateManyShopifyWebhooks: BatchPayload;
  upsertShopifyWebhook: ShopifyWebhook;
  deleteShopifyWebhook?: Maybe<ShopifyWebhook>;
  deleteManyShopifyWebhooks: BatchPayload;
  createTemplate: Template;
  updateTemplate?: Maybe<Template>;
  updateManyTemplates: BatchPayload;
  upsertTemplate: Template;
  deleteTemplate?: Maybe<Template>;
  deleteManyTemplates: BatchPayload;
  createTemplatePermission: TemplatePermission;
  updateTemplatePermission?: Maybe<TemplatePermission>;
  updateManyTemplatePermissions: BatchPayload;
  upsertTemplatePermission: TemplatePermission;
  deleteTemplatePermission?: Maybe<TemplatePermission>;
  deleteManyTemplatePermissions: BatchPayload;
  createUserBadge: UserBadge;
  updateUserBadge?: Maybe<UserBadge>;
  updateManyUserBadges: BatchPayload;
  upsertUserBadge: UserBadge;
  deleteUserBadge?: Maybe<UserBadge>;
  deleteManyUserBadges: BatchPayload;
  createUserSetting: UserSetting;
  updateUserSetting?: Maybe<UserSetting>;
  updateManyUserSettings: BatchPayload;
  upsertUserSetting: UserSetting;
  deleteUserSetting?: Maybe<UserSetting>;
  deleteManyUserSettings: BatchPayload;
  createWatchTime: WatchTime;
  updateWatchTime?: Maybe<WatchTime>;
  updateManyWatchTimes: BatchPayload;
  upsertWatchTime: WatchTime;
  deleteWatchTime?: Maybe<WatchTime>;
  deleteManyWatchTimes: BatchPayload;
  archiveDiscussion?: Maybe<ArchiveDiscussionRes>;
  purgeBadgeImage?: Maybe<PurgeBadgeImageRes>;
  likeComment?: Maybe<Comment>;
  starComment?: Maybe<Comment>;
  flagComment?: Maybe<Comment>;
  updateCommentCustom?: Maybe<Comment>;
  createPostChat?: Maybe<Discussion>;
  deletePostChat?: Maybe<Discussion>;
  updatePostChat?: Maybe<Discussion>;
  updateEmail?: Maybe<UpdateEmailRes>;
  updateCommentModeration?: Maybe<ModerationComment>;
  banUser?: Maybe<BanUserRes>;
  unbanUser?: Maybe<BanUserRes>;
  hideAllCommentsByUser?: Maybe<BanUserRes>;
  createPermissionTemplate?: Maybe<Template>;
  updatePermissionTemplate?: Maybe<Template>;
  assignPermissions?: Maybe<AssignPermissionsRes>;
  updatePromoSubscription?: Maybe<RecurlyPerson>;
  updatePersonDetails?: Maybe<PersonDetails>;
  resetPassword?: Maybe<ResetPasswordRes>;
  updateUserName?: Maybe<UpdateUserNameRes>;
  likePost?: Maybe<Post>;
  endPostLiveStream?: Maybe<Scalars['Boolean']>;
  createAnimationEvent?: Maybe<Scalars['Boolean']>;
  increaseViewCount?: Maybe<Scalars['Boolean']>;
  increasePlayCount?: Maybe<Scalars['Boolean']>;
  createRecurlyAccount: Array<Maybe<RecurlyAccount>>;
  createRecurlyShippingAddress: Array<Maybe<RecurlyShippingAddress>>;
  updateRecurlyShippingAddress: Array<Maybe<RecurlyShippingAddress>>;
  deleteRecurlyShippingAddress: Array<Maybe<RecurlyRemovePosts>>;
  createRecurlySubscription: Array<Maybe<RecurlySubscription>>;
  previewRecurlySubscription: Array<Maybe<RecurlySubscription>>;
  createRecurlyGiftCard: Array<Maybe<RecurlyGiftCard>>;
  redeemRecurlyGiftCardOnAccount: Array<Maybe<RecurlyGiftCard>>;
  updateRecurlySubscription: Array<Maybe<RecurlySubscription>>;
  cancelRecurlySubscription: Array<Maybe<RecurlySubscription>>;
  reactivateRecurlySubscription: Array<Maybe<RecurlySubscription>>;
  createRecurlyBillingInfo: Array<Maybe<RecurlyBillingInfo>>;
  updateRecurlyBillingInfo: Array<Maybe<RecurlyBillingInfo>>;
  deleteRecurlyBillingInfo: Array<Maybe<RecurlyRemovePosts>>;
  deleteRecurlyPlan: Array<Maybe<RecurlyRemovePosts>>;
  createUserBadgeCustom?: Maybe<UserBadge>;
  verifyAndAwardBadge?: Maybe<Badge>;
  upsertSettingCustom?: Maybe<Array<Maybe<SettingCustom>>>;
  upsertWatchTimeCustom: WatchTime;
};


export type MutationCreateActivityLogArgs = {
  data: ActivityLogCreateInput;
};


export type MutationUpdateActivityLogArgs = {
  data: ActivityLogUpdateInput;
  where: ActivityLogWhereUniqueInput;
};


export type MutationUpdateManyActivityLogsArgs = {
  data: ActivityLogUpdateManyMutationInput;
  where?: Maybe<ActivityLogWhereInput>;
};


export type MutationUpsertActivityLogArgs = {
  where: ActivityLogWhereUniqueInput;
  create: ActivityLogCreateInput;
  update: ActivityLogUpdateInput;
};


export type MutationDeleteActivityLogArgs = {
  where: ActivityLogWhereUniqueInput;
};


export type MutationDeleteManyActivityLogsArgs = {
  where?: Maybe<ActivityLogWhereInput>;
};


export type MutationCreateArchiveMessageArgs = {
  data: ArchiveMessageCreateInput;
};


export type MutationUpdateArchiveMessageArgs = {
  data: ArchiveMessageUpdateInput;
  where: ArchiveMessageWhereUniqueInput;
};


export type MutationUpdateManyArchiveMessagesArgs = {
  data: ArchiveMessageUpdateManyMutationInput;
  where?: Maybe<ArchiveMessageWhereInput>;
};


export type MutationUpsertArchiveMessageArgs = {
  where: ArchiveMessageWhereUniqueInput;
  create: ArchiveMessageCreateInput;
  update: ArchiveMessageUpdateInput;
};


export type MutationDeleteArchiveMessageArgs = {
  where: ArchiveMessageWhereUniqueInput;
};


export type MutationDeleteManyArchiveMessagesArgs = {
  where?: Maybe<ArchiveMessageWhereInput>;
};


export type MutationCreateBadgeArgs = {
  data: BadgeCreateInput;
};


export type MutationUpdateBadgeArgs = {
  data: BadgeUpdateInput;
  where: BadgeWhereUniqueInput;
};


export type MutationUpdateManyBadgesArgs = {
  data: BadgeUpdateManyMutationInput;
  where?: Maybe<BadgeWhereInput>;
};


export type MutationUpsertBadgeArgs = {
  where: BadgeWhereUniqueInput;
  create: BadgeCreateInput;
  update: BadgeUpdateInput;
};


export type MutationDeleteBadgeArgs = {
  where: BadgeWhereUniqueInput;
};


export type MutationDeleteManyBadgesArgs = {
  where?: Maybe<BadgeWhereInput>;
};


export type MutationCreateCommentArgs = {
  data: CommentCreateInput;
};


export type MutationUpdateCommentArgs = {
  data: CommentUpdateInput;
  where: CommentWhereUniqueInput;
};


export type MutationUpdateManyCommentsArgs = {
  data: CommentUpdateManyMutationInput;
  where?: Maybe<CommentWhereInput>;
};


export type MutationUpsertCommentArgs = {
  where: CommentWhereUniqueInput;
  create: CommentCreateInput;
  update: CommentUpdateInput;
};


export type MutationDeleteCommentArgs = {
  where: CommentWhereUniqueInput;
};


export type MutationDeleteManyCommentsArgs = {
  where?: Maybe<CommentWhereInput>;
};


export type MutationCreateConfigurationArgs = {
  data: ConfigurationCreateInput;
};


export type MutationUpdateConfigurationArgs = {
  data: ConfigurationUpdateInput;
  where: ConfigurationWhereUniqueInput;
};


export type MutationUpdateManyConfigurationsArgs = {
  data: ConfigurationUpdateManyMutationInput;
  where?: Maybe<ConfigurationWhereInput>;
};


export type MutationUpsertConfigurationArgs = {
  where: ConfigurationWhereUniqueInput;
  create: ConfigurationCreateInput;
  update: ConfigurationUpdateInput;
};


export type MutationDeleteConfigurationArgs = {
  where: ConfigurationWhereUniqueInput;
};


export type MutationDeleteManyConfigurationsArgs = {
  where?: Maybe<ConfigurationWhereInput>;
};


export type MutationCreateDiscussionArgs = {
  data: DiscussionCreateInput;
};


export type MutationUpdateDiscussionArgs = {
  data: DiscussionUpdateInput;
  where: DiscussionWhereUniqueInput;
};


export type MutationUpdateManyDiscussionsArgs = {
  data: DiscussionUpdateManyMutationInput;
  where?: Maybe<DiscussionWhereInput>;
};


export type MutationUpsertDiscussionArgs = {
  where: DiscussionWhereUniqueInput;
  create: DiscussionCreateInput;
  update: DiscussionUpdateInput;
};


export type MutationDeleteDiscussionArgs = {
  where: DiscussionWhereUniqueInput;
};


export type MutationDeleteManyDiscussionsArgs = {
  where?: Maybe<DiscussionWhereInput>;
};


export type MutationCreateEmailChangeRequestArgs = {
  data: EmailChangeRequestCreateInput;
};


export type MutationUpdateEmailChangeRequestArgs = {
  data: EmailChangeRequestUpdateInput;
  where: EmailChangeRequestWhereUniqueInput;
};


export type MutationUpdateManyEmailChangeRequestsArgs = {
  data: EmailChangeRequestUpdateManyMutationInput;
  where?: Maybe<EmailChangeRequestWhereInput>;
};


export type MutationUpsertEmailChangeRequestArgs = {
  where: EmailChangeRequestWhereUniqueInput;
  create: EmailChangeRequestCreateInput;
  update: EmailChangeRequestUpdateInput;
};


export type MutationDeleteEmailChangeRequestArgs = {
  where: EmailChangeRequestWhereUniqueInput;
};


export type MutationDeleteManyEmailChangeRequestsArgs = {
  where?: Maybe<EmailChangeRequestWhereInput>;
};


export type MutationCreateMessageArgs = {
  data: MessageCreateInput;
};


export type MutationUpdateMessageArgs = {
  data: MessageUpdateInput;
  where: MessageWhereUniqueInput;
};


export type MutationUpdateManyMessagesArgs = {
  data: MessageUpdateManyMutationInput;
  where?: Maybe<MessageWhereInput>;
};


export type MutationUpsertMessageArgs = {
  where: MessageWhereUniqueInput;
  create: MessageCreateInput;
  update: MessageUpdateInput;
};


export type MutationDeleteMessageArgs = {
  where: MessageWhereUniqueInput;
};


export type MutationDeleteManyMessagesArgs = {
  where?: Maybe<MessageWhereInput>;
};


export type MutationCreateNotificationArgs = {
  data: NotificationCreateInput;
};


export type MutationUpdateNotificationArgs = {
  data: NotificationUpdateInput;
  where: NotificationWhereUniqueInput;
};


export type MutationUpdateManyNotificationsArgs = {
  data: NotificationUpdateManyMutationInput;
  where?: Maybe<NotificationWhereInput>;
};


export type MutationUpsertNotificationArgs = {
  where: NotificationWhereUniqueInput;
  create: NotificationCreateInput;
  update: NotificationUpdateInput;
};


export type MutationDeleteNotificationArgs = {
  where: NotificationWhereUniqueInput;
};


export type MutationDeleteManyNotificationsArgs = {
  where?: Maybe<NotificationWhereInput>;
};


export type MutationCreateNotificationUserArgs = {
  data: NotificationUserCreateInput;
};


export type MutationUpdateNotificationUserArgs = {
  data: NotificationUserUpdateInput;
  where: NotificationUserWhereUniqueInput;
};


export type MutationUpdateManyNotificationUsersArgs = {
  data: NotificationUserUpdateManyMutationInput;
  where?: Maybe<NotificationUserWhereInput>;
};


export type MutationUpsertNotificationUserArgs = {
  where: NotificationUserWhereUniqueInput;
  create: NotificationUserCreateInput;
  update: NotificationUserUpdateInput;
};


export type MutationDeleteNotificationUserArgs = {
  where: NotificationUserWhereUniqueInput;
};


export type MutationDeleteManyNotificationUsersArgs = {
  where?: Maybe<NotificationUserWhereInput>;
};


export type MutationCreateOneSignalPersonArgs = {
  data: OneSignalPersonCreateInput;
};


export type MutationUpdateOneSignalPersonArgs = {
  data: OneSignalPersonUpdateInput;
  where: OneSignalPersonWhereUniqueInput;
};


export type MutationUpdateManyOneSignalPersonsArgs = {
  data: OneSignalPersonUpdateManyMutationInput;
  where?: Maybe<OneSignalPersonWhereInput>;
};


export type MutationUpsertOneSignalPersonArgs = {
  where: OneSignalPersonWhereUniqueInput;
  create: OneSignalPersonCreateInput;
  update: OneSignalPersonUpdateInput;
};


export type MutationDeleteOneSignalPersonArgs = {
  where: OneSignalPersonWhereUniqueInput;
};


export type MutationDeleteManyOneSignalPersonsArgs = {
  where?: Maybe<OneSignalPersonWhereInput>;
};


export type MutationCreateOrderArgs = {
  data: OrderCreateInput;
};


export type MutationUpdateOrderArgs = {
  data: OrderUpdateInput;
  where: OrderWhereUniqueInput;
};


export type MutationUpdateManyOrdersArgs = {
  data: OrderUpdateManyMutationInput;
  where?: Maybe<OrderWhereInput>;
};


export type MutationUpsertOrderArgs = {
  where: OrderWhereUniqueInput;
  create: OrderCreateInput;
  update: OrderUpdateInput;
};


export type MutationDeleteOrderArgs = {
  where: OrderWhereUniqueInput;
};


export type MutationDeleteManyOrdersArgs = {
  where?: Maybe<OrderWhereInput>;
};


export type MutationCreatePermissionArgs = {
  data: PermissionCreateInput;
};


export type MutationUpdatePermissionArgs = {
  data: PermissionUpdateInput;
  where: PermissionWhereUniqueInput;
};


export type MutationUpdateManyPermissionsArgs = {
  data: PermissionUpdateManyMutationInput;
  where?: Maybe<PermissionWhereInput>;
};


export type MutationUpsertPermissionArgs = {
  where: PermissionWhereUniqueInput;
  create: PermissionCreateInput;
  update: PermissionUpdateInput;
};


export type MutationDeletePermissionArgs = {
  where: PermissionWhereUniqueInput;
};


export type MutationDeleteManyPermissionsArgs = {
  where?: Maybe<PermissionWhereInput>;
};


export type MutationCreatePersonArgs = {
  data: PersonCreateInput;
};


export type MutationUpdatePersonArgs = {
  data: PersonUpdateInput;
  where: PersonWhereUniqueInput;
};


export type MutationUpdateManyPersonsArgs = {
  data: PersonUpdateManyMutationInput;
  where?: Maybe<PersonWhereInput>;
};


export type MutationUpsertPersonArgs = {
  where: PersonWhereUniqueInput;
  create: PersonCreateInput;
  update: PersonUpdateInput;
};


export type MutationDeletePersonArgs = {
  where: PersonWhereUniqueInput;
};


export type MutationDeleteManyPersonsArgs = {
  where?: Maybe<PersonWhereInput>;
};


export type MutationCreatePersonPermissionArgs = {
  data: PersonPermissionCreateInput;
};


export type MutationUpdatePersonPermissionArgs = {
  data: PersonPermissionUpdateInput;
  where: PersonPermissionWhereUniqueInput;
};


export type MutationUpdateManyPersonPermissionsArgs = {
  data: PersonPermissionUpdateManyMutationInput;
  where?: Maybe<PersonPermissionWhereInput>;
};


export type MutationUpsertPersonPermissionArgs = {
  where: PersonPermissionWhereUniqueInput;
  create: PersonPermissionCreateInput;
  update: PersonPermissionUpdateInput;
};


export type MutationDeletePersonPermissionArgs = {
  where: PersonPermissionWhereUniqueInput;
};


export type MutationDeleteManyPersonPermissionsArgs = {
  where?: Maybe<PersonPermissionWhereInput>;
};


export type MutationCreatePostArgs = {
  data: PostCreateInput;
};


export type MutationUpdatePostArgs = {
  data: PostUpdateInput;
  where: PostWhereUniqueInput;
};


export type MutationUpdateManyPostsArgs = {
  data: PostUpdateManyMutationInput;
  where?: Maybe<PostWhereInput>;
};


export type MutationUpsertPostArgs = {
  where: PostWhereUniqueInput;
  create: PostCreateInput;
  update: PostUpdateInput;
};


export type MutationDeletePostArgs = {
  where: PostWhereUniqueInput;
};


export type MutationDeleteManyPostsArgs = {
  where?: Maybe<PostWhereInput>;
};


export type MutationCreatePostVideoArgs = {
  data: PostVideoCreateInput;
};


export type MutationUpdatePostVideoArgs = {
  data: PostVideoUpdateInput;
  where: PostVideoWhereUniqueInput;
};


export type MutationUpdateManyPostVideosArgs = {
  data: PostVideoUpdateManyMutationInput;
  where?: Maybe<PostVideoWhereInput>;
};


export type MutationUpsertPostVideoArgs = {
  where: PostVideoWhereUniqueInput;
  create: PostVideoCreateInput;
  update: PostVideoUpdateInput;
};


export type MutationDeletePostVideoArgs = {
  where: PostVideoWhereUniqueInput;
};


export type MutationDeleteManyPostVideosArgs = {
  where?: Maybe<PostVideoWhereInput>;
};


export type MutationCreateRecurlyErrorArgs = {
  data: RecurlyErrorCreateInput;
};


export type MutationUpdateRecurlyErrorArgs = {
  data: RecurlyErrorUpdateInput;
  where: RecurlyErrorWhereUniqueInput;
};


export type MutationUpdateManyRecurlyErrorsArgs = {
  data: RecurlyErrorUpdateManyMutationInput;
  where?: Maybe<RecurlyErrorWhereInput>;
};


export type MutationUpsertRecurlyErrorArgs = {
  where: RecurlyErrorWhereUniqueInput;
  create: RecurlyErrorCreateInput;
  update: RecurlyErrorUpdateInput;
};


export type MutationDeleteRecurlyErrorArgs = {
  where: RecurlyErrorWhereUniqueInput;
};


export type MutationDeleteManyRecurlyErrorsArgs = {
  where?: Maybe<RecurlyErrorWhereInput>;
};


export type MutationCreateRecurlyWebhookArgs = {
  data: RecurlyWebhookCreateInput;
};


export type MutationUpdateRecurlyWebhookArgs = {
  data: RecurlyWebhookUpdateInput;
  where: RecurlyWebhookWhereUniqueInput;
};


export type MutationUpdateManyRecurlyWebhooksArgs = {
  data: RecurlyWebhookUpdateManyMutationInput;
  where?: Maybe<RecurlyWebhookWhereInput>;
};


export type MutationUpsertRecurlyWebhookArgs = {
  where: RecurlyWebhookWhereUniqueInput;
  create: RecurlyWebhookCreateInput;
  update: RecurlyWebhookUpdateInput;
};


export type MutationDeleteRecurlyWebhookArgs = {
  where: RecurlyWebhookWhereUniqueInput;
};


export type MutationDeleteManyRecurlyWebhooksArgs = {
  where?: Maybe<RecurlyWebhookWhereInput>;
};


export type MutationCreateSettingArgs = {
  data: SettingCreateInput;
};


export type MutationUpdateSettingArgs = {
  data: SettingUpdateInput;
  where: SettingWhereUniqueInput;
};


export type MutationUpdateManySettingsArgs = {
  data: SettingUpdateManyMutationInput;
  where?: Maybe<SettingWhereInput>;
};


export type MutationUpsertSettingArgs = {
  where: SettingWhereUniqueInput;
  create: SettingCreateInput;
  update: SettingUpdateInput;
};


export type MutationDeleteSettingArgs = {
  where: SettingWhereUniqueInput;
};


export type MutationDeleteManySettingsArgs = {
  where?: Maybe<SettingWhereInput>;
};


export type MutationCreateShopifyWebhookArgs = {
  data: ShopifyWebhookCreateInput;
};


export type MutationUpdateShopifyWebhookArgs = {
  data: ShopifyWebhookUpdateInput;
  where: ShopifyWebhookWhereUniqueInput;
};


export type MutationUpdateManyShopifyWebhooksArgs = {
  data: ShopifyWebhookUpdateManyMutationInput;
  where?: Maybe<ShopifyWebhookWhereInput>;
};


export type MutationUpsertShopifyWebhookArgs = {
  where: ShopifyWebhookWhereUniqueInput;
  create: ShopifyWebhookCreateInput;
  update: ShopifyWebhookUpdateInput;
};


export type MutationDeleteShopifyWebhookArgs = {
  where: ShopifyWebhookWhereUniqueInput;
};


export type MutationDeleteManyShopifyWebhooksArgs = {
  where?: Maybe<ShopifyWebhookWhereInput>;
};


export type MutationCreateTemplateArgs = {
  data: TemplateCreateInput;
};


export type MutationUpdateTemplateArgs = {
  data: TemplateUpdateInput;
  where: TemplateWhereUniqueInput;
};


export type MutationUpdateManyTemplatesArgs = {
  data: TemplateUpdateManyMutationInput;
  where?: Maybe<TemplateWhereInput>;
};


export type MutationUpsertTemplateArgs = {
  where: TemplateWhereUniqueInput;
  create: TemplateCreateInput;
  update: TemplateUpdateInput;
};


export type MutationDeleteTemplateArgs = {
  where: TemplateWhereUniqueInput;
};


export type MutationDeleteManyTemplatesArgs = {
  where?: Maybe<TemplateWhereInput>;
};


export type MutationCreateTemplatePermissionArgs = {
  data: TemplatePermissionCreateInput;
};


export type MutationUpdateTemplatePermissionArgs = {
  data: TemplatePermissionUpdateInput;
  where: TemplatePermissionWhereUniqueInput;
};


export type MutationUpdateManyTemplatePermissionsArgs = {
  data: TemplatePermissionUpdateManyMutationInput;
  where?: Maybe<TemplatePermissionWhereInput>;
};


export type MutationUpsertTemplatePermissionArgs = {
  where: TemplatePermissionWhereUniqueInput;
  create: TemplatePermissionCreateInput;
  update: TemplatePermissionUpdateInput;
};


export type MutationDeleteTemplatePermissionArgs = {
  where: TemplatePermissionWhereUniqueInput;
};


export type MutationDeleteManyTemplatePermissionsArgs = {
  where?: Maybe<TemplatePermissionWhereInput>;
};


export type MutationCreateUserBadgeArgs = {
  data: UserBadgeCreateInput;
};


export type MutationUpdateUserBadgeArgs = {
  data: UserBadgeUpdateInput;
  where: UserBadgeWhereUniqueInput;
};


export type MutationUpdateManyUserBadgesArgs = {
  data: UserBadgeUpdateManyMutationInput;
  where?: Maybe<UserBadgeWhereInput>;
};


export type MutationUpsertUserBadgeArgs = {
  where: UserBadgeWhereUniqueInput;
  create: UserBadgeCreateInput;
  update: UserBadgeUpdateInput;
};


export type MutationDeleteUserBadgeArgs = {
  where: UserBadgeWhereUniqueInput;
};


export type MutationDeleteManyUserBadgesArgs = {
  where?: Maybe<UserBadgeWhereInput>;
};


export type MutationCreateUserSettingArgs = {
  data: UserSettingCreateInput;
};


export type MutationUpdateUserSettingArgs = {
  data: UserSettingUpdateInput;
  where: UserSettingWhereUniqueInput;
};


export type MutationUpdateManyUserSettingsArgs = {
  data: UserSettingUpdateManyMutationInput;
  where?: Maybe<UserSettingWhereInput>;
};


export type MutationUpsertUserSettingArgs = {
  where: UserSettingWhereUniqueInput;
  create: UserSettingCreateInput;
  update: UserSettingUpdateInput;
};


export type MutationDeleteUserSettingArgs = {
  where: UserSettingWhereUniqueInput;
};


export type MutationDeleteManyUserSettingsArgs = {
  where?: Maybe<UserSettingWhereInput>;
};


export type MutationCreateWatchTimeArgs = {
  data: WatchTimeCreateInput;
};


export type MutationUpdateWatchTimeArgs = {
  data: WatchTimeUpdateInput;
  where: WatchTimeWhereUniqueInput;
};


export type MutationUpdateManyWatchTimesArgs = {
  data: WatchTimeUpdateManyMutationInput;
  where?: Maybe<WatchTimeWhereInput>;
};


export type MutationUpsertWatchTimeArgs = {
  where: WatchTimeWhereUniqueInput;
  create: WatchTimeCreateInput;
  update: WatchTimeUpdateInput;
};


export type MutationDeleteWatchTimeArgs = {
  where: WatchTimeWhereUniqueInput;
};


export type MutationDeleteManyWatchTimesArgs = {
  where?: Maybe<WatchTimeWhereInput>;
};


export type MutationArchiveDiscussionArgs = {
  where: ArchiveDiscussionWhereInput;
};


export type MutationPurgeBadgeImageArgs = {
  imageURL: Scalars['String'];
};


export type MutationLikeCommentArgs = {
  where: CommentWhereUniqueInput;
  data: LikeCommentDataInput;
};


export type MutationStarCommentArgs = {
  where: CommentWhereUniqueInput;
  data: StarCommentDataInput;
};


export type MutationFlagCommentArgs = {
  where: CommentWhereUniqueInput;
  data: FlagCommentDataInput;
};


export type MutationUpdateCommentCustomArgs = {
  where: CommentWhereUniqueInput;
  data: UpdateCommentCustomDataInput;
};


export type MutationCreatePostChatArgs = {
  data: PostDataInput;
};


export type MutationDeletePostChatArgs = {
  where: DiscussionWhereUniqueInput;
};


export type MutationUpdatePostChatArgs = {
  data: PostDataInput;
  where: DiscussionWhereUniqueInput;
};


export type MutationUpdateEmailArgs = {
  currentEmail: Scalars['String'];
  newEmail: Scalars['String'];
};


export type MutationUpdateCommentModerationArgs = {
  where: CommentWhereUniqueInput;
  data: UpdateCommentModerationDataInput;
};


export type MutationBanUserArgs = {
  where?: Maybe<PersonWhereUniqueInput>;
};


export type MutationUnbanUserArgs = {
  where?: Maybe<PersonWhereUniqueInput>;
};


export type MutationHideAllCommentsByUserArgs = {
  where: HideAllCommentsByUserWhereInput;
};


export type MutationCreatePermissionTemplateArgs = {
  data?: Maybe<CreatePermissionTemplateDataInput>;
};


export type MutationUpdatePermissionTemplateArgs = {
  data?: Maybe<UpdatePermissionTemplateDataInput>;
  where?: Maybe<UpdatePermissionTemplateWhereInput>;
};


export type MutationAssignPermissionsArgs = {
  data: AssignPermissionsInputData;
  where: AssignPermissionsWhereInput;
};


export type MutationUpdatePromoSubscriptionArgs = {
  data: PromoDataInput;
  where: PromoWhereUniqueInput;
};


export type MutationUpdatePersonDetailsArgs = {
  data: UpdatePersonDetailsInput;
};


export type MutationResetPasswordArgs = {
  where: ResetPasswordWhereInput;
};


export type MutationUpdateUserNameArgs = {
  personID: Scalars['ID'];
  username: Scalars['String'];
};


export type MutationLikePostArgs = {
  where: PostWhereUniqueInput;
  data: LikePostDataInput;
};


export type MutationEndPostLiveStreamArgs = {
  where: PostWhereUniqueInput;
};


export type MutationCreateAnimationEventArgs = {
  data: Scalars['Json'];
  where: PostWhereUniqueInput;
  socketId?: Maybe<Scalars['String']>;
};


export type MutationIncreaseViewCountArgs = {
  where: PostWhereUniqueInput;
};


export type MutationIncreasePlayCountArgs = {
  where: PostWhereUniqueInput;
};


export type MutationCreateRecurlyAccountArgs = {
  data: RecurlyCreateAccountInput;
};


export type MutationCreateRecurlyShippingAddressArgs = {
  data?: Maybe<RecurlyAccountShippingAddressInput>;
};


export type MutationUpdateRecurlyShippingAddressArgs = {
  where: RecurlyIdInput;
  data?: Maybe<RecurlyAccountShippingAddressInput>;
};


export type MutationDeleteRecurlyShippingAddressArgs = {
  data?: Maybe<RecurlyIdInput>;
};


export type MutationCreateRecurlySubscriptionArgs = {
  data: RecurlyCreateSubscriptionsInput;
};


export type MutationPreviewRecurlySubscriptionArgs = {
  data: RecurlyCreateSubscriptionsInput;
};


export type MutationCreateRecurlyGiftCardArgs = {
  data: RecurlyCreateGiftCardInput;
};


export type MutationRedeemRecurlyGiftCardOnAccountArgs = {
  where: RecurlyRedeemGiftCardOnAccountInput;
};


export type MutationUpdateRecurlySubscriptionArgs = {
  where: RecurlyIdInput;
  data: RecurlyUpdateSubscriptionsInput;
};


export type MutationCancelRecurlySubscriptionArgs = {
  where: RecurlyIdInput;
};


export type MutationReactivateRecurlySubscriptionArgs = {
  where: RecurlyIdInput;
};


export type MutationCreateRecurlyBillingInfoArgs = {
  data: RecurlyBillingInfoInput;
  where?: Maybe<RecurlyIdInput>;
};


export type MutationUpdateRecurlyBillingInfoArgs = {
  data: RecurlyBillingInfoInput;
  where?: Maybe<RecurlyIdInput>;
};


export type MutationDeleteRecurlyBillingInfoArgs = {
  where?: Maybe<RecurlyIdInput>;
};


export type MutationDeleteRecurlyPlanArgs = {
  where: RecurlyIdInput;
};


export type MutationCreateUserBadgeCustomArgs = {
  data: CreateUserBadgeCustomWhereInput;
};


export type MutationVerifyAndAwardBadgeArgs = {
  badgeSlug?: Maybe<Scalars['String']>;
};


export type MutationUpsertSettingCustomArgs = {
  data: Array<UpsertSettingCustomDataInput>;
};


export type MutationUpsertWatchTimeCustomArgs = {
  data: WatchTimeUpsertInput;
  where: UpsertWatchTimeCustomWhere;
};

export type ActivityLogCreateInput = {
  id?: Maybe<Scalars['ID']>;
  referenceId?: Maybe<Scalars['String']>;
  referenceType?: Maybe<ReferenceType>;
  action?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  clientDetails?: Maybe<Scalars['Json']>;
  updatedBy?: Maybe<PersonCreateOneInput>;
  beforeObj?: Maybe<Scalars['Json']>;
  afterObj?: Maybe<Scalars['Json']>;
};

export type PersonCreateOneInput = {
  create?: Maybe<PersonCreateInput>;
  connect?: Maybe<PersonWhereUniqueInput>;
};

export type PersonCreateInput = {
  id?: Maybe<Scalars['ID']>;
  email: Scalars['String'];
  userName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  profileImage?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  authZeroID?: Maybe<Scalars['String']>;
  recurlyId?: Maybe<Scalars['String']>;
  recurlyPlan?: Maybe<Plans>;
  promoPlan?: Maybe<Plans>;
  promoPlanExpiration?: Maybe<Scalars['DateTime']>;
  permissionTemplate?: Maybe<TemplateCreateOneInput>;
  role?: Maybe<Role>;
  badgeId?: Maybe<Scalars['ID']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isBanned?: Maybe<Scalars['Boolean']>;
  lastActiveOn?: Maybe<Scalars['DateTime']>;
};

export type TemplateCreateOneInput = {
  create?: Maybe<TemplateCreateInput>;
  connect?: Maybe<TemplateWhereUniqueInput>;
};

export type TemplateCreateInput = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  permissions?: Maybe<TemplatePermissionCreateManyWithoutTemplateInput>;
};

export type TemplatePermissionCreateManyWithoutTemplateInput = {
  create?: Maybe<Array<TemplatePermissionCreateWithoutTemplateInput>>;
  connect?: Maybe<Array<TemplatePermissionWhereUniqueInput>>;
};

export type TemplatePermissionCreateWithoutTemplateInput = {
  id?: Maybe<Scalars['ID']>;
  permission?: Maybe<PermissionCreateOneInput>;
  canRead?: Maybe<Scalars['Boolean']>;
  canMutate?: Maybe<Scalars['Boolean']>;
};

export type PermissionCreateOneInput = {
  create?: Maybe<PermissionCreateInput>;
  connect?: Maybe<PermissionWhereUniqueInput>;
};

export type PermissionCreateInput = {
  id?: Maybe<Scalars['ID']>;
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type ActivityLogUpdateInput = {
  referenceId?: Maybe<Scalars['String']>;
  referenceType?: Maybe<ReferenceType>;
  action?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  clientDetails?: Maybe<Scalars['Json']>;
  updatedBy?: Maybe<PersonUpdateOneInput>;
  beforeObj?: Maybe<Scalars['Json']>;
  afterObj?: Maybe<Scalars['Json']>;
};

export type PersonUpdateOneInput = {
  create?: Maybe<PersonCreateInput>;
  update?: Maybe<PersonUpdateDataInput>;
  upsert?: Maybe<PersonUpsertNestedInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<PersonWhereUniqueInput>;
};

export type PersonUpdateDataInput = {
  email?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  profileImage?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  authZeroID?: Maybe<Scalars['String']>;
  recurlyId?: Maybe<Scalars['String']>;
  recurlyPlan?: Maybe<Plans>;
  promoPlan?: Maybe<Plans>;
  promoPlanExpiration?: Maybe<Scalars['DateTime']>;
  permissionTemplate?: Maybe<TemplateUpdateOneInput>;
  role?: Maybe<Role>;
  badgeId?: Maybe<Scalars['ID']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isBanned?: Maybe<Scalars['Boolean']>;
  lastActiveOn?: Maybe<Scalars['DateTime']>;
};

export type TemplateUpdateOneInput = {
  create?: Maybe<TemplateCreateInput>;
  update?: Maybe<TemplateUpdateDataInput>;
  upsert?: Maybe<TemplateUpsertNestedInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<TemplateWhereUniqueInput>;
};

export type TemplateUpdateDataInput = {
  name?: Maybe<Scalars['String']>;
  permissions?: Maybe<TemplatePermissionUpdateManyWithoutTemplateInput>;
};

export type TemplatePermissionUpdateManyWithoutTemplateInput = {
  create?: Maybe<Array<TemplatePermissionCreateWithoutTemplateInput>>;
  delete?: Maybe<Array<TemplatePermissionWhereUniqueInput>>;
  connect?: Maybe<Array<TemplatePermissionWhereUniqueInput>>;
  set?: Maybe<Array<TemplatePermissionWhereUniqueInput>>;
  disconnect?: Maybe<Array<TemplatePermissionWhereUniqueInput>>;
  update?: Maybe<Array<TemplatePermissionUpdateWithWhereUniqueWithoutTemplateInput>>;
  upsert?: Maybe<Array<TemplatePermissionUpsertWithWhereUniqueWithoutTemplateInput>>;
  deleteMany?: Maybe<Array<TemplatePermissionScalarWhereInput>>;
  updateMany?: Maybe<Array<TemplatePermissionUpdateManyWithWhereNestedInput>>;
};

export type TemplatePermissionUpdateWithWhereUniqueWithoutTemplateInput = {
  where: TemplatePermissionWhereUniqueInput;
  data: TemplatePermissionUpdateWithoutTemplateDataInput;
};

export type TemplatePermissionUpdateWithoutTemplateDataInput = {
  permission?: Maybe<PermissionUpdateOneInput>;
  canRead?: Maybe<Scalars['Boolean']>;
  canMutate?: Maybe<Scalars['Boolean']>;
};

export type PermissionUpdateOneInput = {
  create?: Maybe<PermissionCreateInput>;
  update?: Maybe<PermissionUpdateDataInput>;
  upsert?: Maybe<PermissionUpsertNestedInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<PermissionWhereUniqueInput>;
};

export type PermissionUpdateDataInput = {
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type PermissionUpsertNestedInput = {
  update: PermissionUpdateDataInput;
  create: PermissionCreateInput;
};

export type TemplatePermissionUpsertWithWhereUniqueWithoutTemplateInput = {
  where: TemplatePermissionWhereUniqueInput;
  update: TemplatePermissionUpdateWithoutTemplateDataInput;
  create: TemplatePermissionCreateWithoutTemplateInput;
};

export type TemplatePermissionScalarWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  canRead?: Maybe<Scalars['Boolean']>;
  canRead_not?: Maybe<Scalars['Boolean']>;
  canMutate?: Maybe<Scalars['Boolean']>;
  canMutate_not?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<TemplatePermissionScalarWhereInput>>;
  OR?: Maybe<Array<TemplatePermissionScalarWhereInput>>;
  NOT?: Maybe<Array<TemplatePermissionScalarWhereInput>>;
};

export type TemplatePermissionUpdateManyWithWhereNestedInput = {
  where: TemplatePermissionScalarWhereInput;
  data: TemplatePermissionUpdateManyDataInput;
};

export type TemplatePermissionUpdateManyDataInput = {
  canRead?: Maybe<Scalars['Boolean']>;
  canMutate?: Maybe<Scalars['Boolean']>;
};

export type TemplateUpsertNestedInput = {
  update: TemplateUpdateDataInput;
  create: TemplateCreateInput;
};

export type PersonUpsertNestedInput = {
  update: PersonUpdateDataInput;
  create: PersonCreateInput;
};

export type ActivityLogUpdateManyMutationInput = {
  referenceId?: Maybe<Scalars['String']>;
  referenceType?: Maybe<ReferenceType>;
  action?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  clientDetails?: Maybe<Scalars['Json']>;
  beforeObj?: Maybe<Scalars['Json']>;
  afterObj?: Maybe<Scalars['Json']>;
};

export type BatchPayload = {
  __typename?: 'BatchPayload';
  count: Scalars['Long'];
};


export type ArchiveMessageCreateInput = {
  id?: Maybe<Scalars['ID']>;
  message: Scalars['String'];
  likes?: Maybe<PersonCreateManyInput>;
  discussion: DiscussionCreateOneWithoutArchiveMessagesInput;
  depth?: Maybe<Scalars['Int']>;
  parent?: Maybe<ArchiveMessageCreateOneWithoutParentInput>;
  isBlocked?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  flags?: Maybe<PersonCreateManyInput>;
  createdBy?: Maybe<PersonCreateOneInput>;
  userType?: Maybe<Scalars['String']>;
};

export type PersonCreateManyInput = {
  create?: Maybe<Array<PersonCreateInput>>;
  connect?: Maybe<Array<PersonWhereUniqueInput>>;
};

export type DiscussionCreateOneWithoutArchiveMessagesInput = {
  create?: Maybe<DiscussionCreateWithoutArchiveMessagesInput>;
  connect?: Maybe<DiscussionWhereUniqueInput>;
};

export type DiscussionCreateWithoutArchiveMessagesInput = {
  id?: Maybe<Scalars['ID']>;
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  isLive?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Discussion_Type>;
  isArchived?: Maybe<Scalars['Boolean']>;
  archiveStatus?: Maybe<Archive_Progress>;
  status?: Maybe<Scalars['String']>;
  scheduleAt?: Maybe<Scalars['DateTime']>;
  createdBy: PersonCreateOneInput;
  owner: PersonCreateOneInput;
  messages?: Maybe<MessageCreateManyWithoutDiscussionInput>;
  startTime?: Maybe<Scalars['DateTime']>;
  endTime?: Maybe<Scalars['DateTime']>;
};

export type MessageCreateManyWithoutDiscussionInput = {
  create?: Maybe<Array<MessageCreateWithoutDiscussionInput>>;
  connect?: Maybe<Array<MessageWhereUniqueInput>>;
};

export type MessageCreateWithoutDiscussionInput = {
  id?: Maybe<Scalars['ID']>;
  message: Scalars['String'];
  likes?: Maybe<PersonCreateManyInput>;
  depth?: Maybe<Scalars['Int']>;
  parent?: Maybe<MessageCreateOneWithoutParentInput>;
  isBlocked?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  flags?: Maybe<PersonCreateManyInput>;
  createdBy?: Maybe<PersonCreateOneInput>;
  userType?: Maybe<Scalars['String']>;
};

export type MessageCreateOneWithoutParentInput = {
  create?: Maybe<MessageCreateWithoutParentInput>;
  connect?: Maybe<MessageWhereUniqueInput>;
};

export type MessageCreateWithoutParentInput = {
  id?: Maybe<Scalars['ID']>;
  message: Scalars['String'];
  likes?: Maybe<PersonCreateManyInput>;
  discussion: DiscussionCreateOneWithoutMessagesInput;
  depth?: Maybe<Scalars['Int']>;
  isBlocked?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  flags?: Maybe<PersonCreateManyInput>;
  createdBy?: Maybe<PersonCreateOneInput>;
  userType?: Maybe<Scalars['String']>;
};

export type DiscussionCreateOneWithoutMessagesInput = {
  create?: Maybe<DiscussionCreateWithoutMessagesInput>;
  connect?: Maybe<DiscussionWhereUniqueInput>;
};

export type DiscussionCreateWithoutMessagesInput = {
  id?: Maybe<Scalars['ID']>;
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  isLive?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Discussion_Type>;
  isArchived?: Maybe<Scalars['Boolean']>;
  archiveStatus?: Maybe<Archive_Progress>;
  status?: Maybe<Scalars['String']>;
  scheduleAt?: Maybe<Scalars['DateTime']>;
  createdBy: PersonCreateOneInput;
  owner: PersonCreateOneInput;
  archiveMessages?: Maybe<ArchiveMessageCreateManyWithoutDiscussionInput>;
  startTime?: Maybe<Scalars['DateTime']>;
  endTime?: Maybe<Scalars['DateTime']>;
};

export type ArchiveMessageCreateManyWithoutDiscussionInput = {
  create?: Maybe<Array<ArchiveMessageCreateWithoutDiscussionInput>>;
  connect?: Maybe<Array<ArchiveMessageWhereUniqueInput>>;
};

export type ArchiveMessageCreateWithoutDiscussionInput = {
  id?: Maybe<Scalars['ID']>;
  message: Scalars['String'];
  likes?: Maybe<PersonCreateManyInput>;
  depth?: Maybe<Scalars['Int']>;
  parent?: Maybe<ArchiveMessageCreateOneWithoutParentInput>;
  isBlocked?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  flags?: Maybe<PersonCreateManyInput>;
  createdBy?: Maybe<PersonCreateOneInput>;
  userType?: Maybe<Scalars['String']>;
};

export type ArchiveMessageCreateOneWithoutParentInput = {
  create?: Maybe<ArchiveMessageCreateWithoutParentInput>;
  connect?: Maybe<ArchiveMessageWhereUniqueInput>;
};

export type ArchiveMessageCreateWithoutParentInput = {
  id?: Maybe<Scalars['ID']>;
  message: Scalars['String'];
  likes?: Maybe<PersonCreateManyInput>;
  discussion: DiscussionCreateOneWithoutArchiveMessagesInput;
  depth?: Maybe<Scalars['Int']>;
  isBlocked?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  flags?: Maybe<PersonCreateManyInput>;
  createdBy?: Maybe<PersonCreateOneInput>;
  userType?: Maybe<Scalars['String']>;
};

export type ArchiveMessageUpdateInput = {
  message?: Maybe<Scalars['String']>;
  likes?: Maybe<PersonUpdateManyInput>;
  discussion?: Maybe<DiscussionUpdateOneRequiredWithoutArchiveMessagesInput>;
  depth?: Maybe<Scalars['Int']>;
  parent?: Maybe<ArchiveMessageUpdateOneWithoutParentInput>;
  isBlocked?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  flags?: Maybe<PersonUpdateManyInput>;
  createdBy?: Maybe<PersonUpdateOneInput>;
  userType?: Maybe<Scalars['String']>;
};

export type PersonUpdateManyInput = {
  create?: Maybe<Array<PersonCreateInput>>;
  update?: Maybe<Array<PersonUpdateWithWhereUniqueNestedInput>>;
  upsert?: Maybe<Array<PersonUpsertWithWhereUniqueNestedInput>>;
  delete?: Maybe<Array<PersonWhereUniqueInput>>;
  connect?: Maybe<Array<PersonWhereUniqueInput>>;
  set?: Maybe<Array<PersonWhereUniqueInput>>;
  disconnect?: Maybe<Array<PersonWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PersonScalarWhereInput>>;
  updateMany?: Maybe<Array<PersonUpdateManyWithWhereNestedInput>>;
};

export type PersonUpdateWithWhereUniqueNestedInput = {
  where: PersonWhereUniqueInput;
  data: PersonUpdateDataInput;
};

export type PersonUpsertWithWhereUniqueNestedInput = {
  where: PersonWhereUniqueInput;
  update: PersonUpdateDataInput;
  create: PersonCreateInput;
};

export type PersonScalarWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  email?: Maybe<Scalars['String']>;
  email_not?: Maybe<Scalars['String']>;
  email_in?: Maybe<Array<Scalars['String']>>;
  email_not_in?: Maybe<Array<Scalars['String']>>;
  email_lt?: Maybe<Scalars['String']>;
  email_lte?: Maybe<Scalars['String']>;
  email_gt?: Maybe<Scalars['String']>;
  email_gte?: Maybe<Scalars['String']>;
  email_contains?: Maybe<Scalars['String']>;
  email_not_contains?: Maybe<Scalars['String']>;
  email_starts_with?: Maybe<Scalars['String']>;
  email_not_starts_with?: Maybe<Scalars['String']>;
  email_ends_with?: Maybe<Scalars['String']>;
  email_not_ends_with?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
  userName_not?: Maybe<Scalars['String']>;
  userName_in?: Maybe<Array<Scalars['String']>>;
  userName_not_in?: Maybe<Array<Scalars['String']>>;
  userName_lt?: Maybe<Scalars['String']>;
  userName_lte?: Maybe<Scalars['String']>;
  userName_gt?: Maybe<Scalars['String']>;
  userName_gte?: Maybe<Scalars['String']>;
  userName_contains?: Maybe<Scalars['String']>;
  userName_not_contains?: Maybe<Scalars['String']>;
  userName_starts_with?: Maybe<Scalars['String']>;
  userName_not_starts_with?: Maybe<Scalars['String']>;
  userName_ends_with?: Maybe<Scalars['String']>;
  userName_not_ends_with?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  firstName_not?: Maybe<Scalars['String']>;
  firstName_in?: Maybe<Array<Scalars['String']>>;
  firstName_not_in?: Maybe<Array<Scalars['String']>>;
  firstName_lt?: Maybe<Scalars['String']>;
  firstName_lte?: Maybe<Scalars['String']>;
  firstName_gt?: Maybe<Scalars['String']>;
  firstName_gte?: Maybe<Scalars['String']>;
  firstName_contains?: Maybe<Scalars['String']>;
  firstName_not_contains?: Maybe<Scalars['String']>;
  firstName_starts_with?: Maybe<Scalars['String']>;
  firstName_not_starts_with?: Maybe<Scalars['String']>;
  firstName_ends_with?: Maybe<Scalars['String']>;
  firstName_not_ends_with?: Maybe<Scalars['String']>;
  profileImage?: Maybe<Scalars['String']>;
  profileImage_not?: Maybe<Scalars['String']>;
  profileImage_in?: Maybe<Array<Scalars['String']>>;
  profileImage_not_in?: Maybe<Array<Scalars['String']>>;
  profileImage_lt?: Maybe<Scalars['String']>;
  profileImage_lte?: Maybe<Scalars['String']>;
  profileImage_gt?: Maybe<Scalars['String']>;
  profileImage_gte?: Maybe<Scalars['String']>;
  profileImage_contains?: Maybe<Scalars['String']>;
  profileImage_not_contains?: Maybe<Scalars['String']>;
  profileImage_starts_with?: Maybe<Scalars['String']>;
  profileImage_not_starts_with?: Maybe<Scalars['String']>;
  profileImage_ends_with?: Maybe<Scalars['String']>;
  profileImage_not_ends_with?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  lastName_not?: Maybe<Scalars['String']>;
  lastName_in?: Maybe<Array<Scalars['String']>>;
  lastName_not_in?: Maybe<Array<Scalars['String']>>;
  lastName_lt?: Maybe<Scalars['String']>;
  lastName_lte?: Maybe<Scalars['String']>;
  lastName_gt?: Maybe<Scalars['String']>;
  lastName_gte?: Maybe<Scalars['String']>;
  lastName_contains?: Maybe<Scalars['String']>;
  lastName_not_contains?: Maybe<Scalars['String']>;
  lastName_starts_with?: Maybe<Scalars['String']>;
  lastName_not_starts_with?: Maybe<Scalars['String']>;
  lastName_ends_with?: Maybe<Scalars['String']>;
  lastName_not_ends_with?: Maybe<Scalars['String']>;
  authZeroID?: Maybe<Scalars['String']>;
  authZeroID_not?: Maybe<Scalars['String']>;
  authZeroID_in?: Maybe<Array<Scalars['String']>>;
  authZeroID_not_in?: Maybe<Array<Scalars['String']>>;
  authZeroID_lt?: Maybe<Scalars['String']>;
  authZeroID_lte?: Maybe<Scalars['String']>;
  authZeroID_gt?: Maybe<Scalars['String']>;
  authZeroID_gte?: Maybe<Scalars['String']>;
  authZeroID_contains?: Maybe<Scalars['String']>;
  authZeroID_not_contains?: Maybe<Scalars['String']>;
  authZeroID_starts_with?: Maybe<Scalars['String']>;
  authZeroID_not_starts_with?: Maybe<Scalars['String']>;
  authZeroID_ends_with?: Maybe<Scalars['String']>;
  authZeroID_not_ends_with?: Maybe<Scalars['String']>;
  recurlyId?: Maybe<Scalars['String']>;
  recurlyId_not?: Maybe<Scalars['String']>;
  recurlyId_in?: Maybe<Array<Scalars['String']>>;
  recurlyId_not_in?: Maybe<Array<Scalars['String']>>;
  recurlyId_lt?: Maybe<Scalars['String']>;
  recurlyId_lte?: Maybe<Scalars['String']>;
  recurlyId_gt?: Maybe<Scalars['String']>;
  recurlyId_gte?: Maybe<Scalars['String']>;
  recurlyId_contains?: Maybe<Scalars['String']>;
  recurlyId_not_contains?: Maybe<Scalars['String']>;
  recurlyId_starts_with?: Maybe<Scalars['String']>;
  recurlyId_not_starts_with?: Maybe<Scalars['String']>;
  recurlyId_ends_with?: Maybe<Scalars['String']>;
  recurlyId_not_ends_with?: Maybe<Scalars['String']>;
  recurlyPlan?: Maybe<Plans>;
  recurlyPlan_not?: Maybe<Plans>;
  recurlyPlan_in?: Maybe<Array<Plans>>;
  recurlyPlan_not_in?: Maybe<Array<Plans>>;
  promoPlan?: Maybe<Plans>;
  promoPlan_not?: Maybe<Plans>;
  promoPlan_in?: Maybe<Array<Plans>>;
  promoPlan_not_in?: Maybe<Array<Plans>>;
  promoPlanExpiration?: Maybe<Scalars['DateTime']>;
  promoPlanExpiration_not?: Maybe<Scalars['DateTime']>;
  promoPlanExpiration_in?: Maybe<Array<Scalars['DateTime']>>;
  promoPlanExpiration_not_in?: Maybe<Array<Scalars['DateTime']>>;
  promoPlanExpiration_lt?: Maybe<Scalars['DateTime']>;
  promoPlanExpiration_lte?: Maybe<Scalars['DateTime']>;
  promoPlanExpiration_gt?: Maybe<Scalars['DateTime']>;
  promoPlanExpiration_gte?: Maybe<Scalars['DateTime']>;
  role?: Maybe<Role>;
  role_not?: Maybe<Role>;
  role_in?: Maybe<Array<Role>>;
  role_not_in?: Maybe<Array<Role>>;
  badgeId?: Maybe<Scalars['ID']>;
  badgeId_not?: Maybe<Scalars['ID']>;
  badgeId_in?: Maybe<Array<Scalars['ID']>>;
  badgeId_not_in?: Maybe<Array<Scalars['ID']>>;
  badgeId_lt?: Maybe<Scalars['ID']>;
  badgeId_lte?: Maybe<Scalars['ID']>;
  badgeId_gt?: Maybe<Scalars['ID']>;
  badgeId_gte?: Maybe<Scalars['ID']>;
  badgeId_contains?: Maybe<Scalars['ID']>;
  badgeId_not_contains?: Maybe<Scalars['ID']>;
  badgeId_starts_with?: Maybe<Scalars['ID']>;
  badgeId_not_starts_with?: Maybe<Scalars['ID']>;
  badgeId_ends_with?: Maybe<Scalars['ID']>;
  badgeId_not_ends_with?: Maybe<Scalars['ID']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isActive_not?: Maybe<Scalars['Boolean']>;
  isBanned?: Maybe<Scalars['Boolean']>;
  isBanned_not?: Maybe<Scalars['Boolean']>;
  lastActiveOn?: Maybe<Scalars['DateTime']>;
  lastActiveOn_not?: Maybe<Scalars['DateTime']>;
  lastActiveOn_in?: Maybe<Array<Scalars['DateTime']>>;
  lastActiveOn_not_in?: Maybe<Array<Scalars['DateTime']>>;
  lastActiveOn_lt?: Maybe<Scalars['DateTime']>;
  lastActiveOn_lte?: Maybe<Scalars['DateTime']>;
  lastActiveOn_gt?: Maybe<Scalars['DateTime']>;
  lastActiveOn_gte?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<PersonScalarWhereInput>>;
  OR?: Maybe<Array<PersonScalarWhereInput>>;
  NOT?: Maybe<Array<PersonScalarWhereInput>>;
};

export type PersonUpdateManyWithWhereNestedInput = {
  where: PersonScalarWhereInput;
  data: PersonUpdateManyDataInput;
};

export type PersonUpdateManyDataInput = {
  email?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  profileImage?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  authZeroID?: Maybe<Scalars['String']>;
  recurlyId?: Maybe<Scalars['String']>;
  recurlyPlan?: Maybe<Plans>;
  promoPlan?: Maybe<Plans>;
  promoPlanExpiration?: Maybe<Scalars['DateTime']>;
  role?: Maybe<Role>;
  badgeId?: Maybe<Scalars['ID']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isBanned?: Maybe<Scalars['Boolean']>;
  lastActiveOn?: Maybe<Scalars['DateTime']>;
};

export type DiscussionUpdateOneRequiredWithoutArchiveMessagesInput = {
  create?: Maybe<DiscussionCreateWithoutArchiveMessagesInput>;
  update?: Maybe<DiscussionUpdateWithoutArchiveMessagesDataInput>;
  upsert?: Maybe<DiscussionUpsertWithoutArchiveMessagesInput>;
  connect?: Maybe<DiscussionWhereUniqueInput>;
};

export type DiscussionUpdateWithoutArchiveMessagesDataInput = {
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  isLive?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Discussion_Type>;
  isArchived?: Maybe<Scalars['Boolean']>;
  archiveStatus?: Maybe<Archive_Progress>;
  status?: Maybe<Scalars['String']>;
  scheduleAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<PersonUpdateOneRequiredInput>;
  owner?: Maybe<PersonUpdateOneRequiredInput>;
  messages?: Maybe<MessageUpdateManyWithoutDiscussionInput>;
  startTime?: Maybe<Scalars['DateTime']>;
  endTime?: Maybe<Scalars['DateTime']>;
};

export type PersonUpdateOneRequiredInput = {
  create?: Maybe<PersonCreateInput>;
  update?: Maybe<PersonUpdateDataInput>;
  upsert?: Maybe<PersonUpsertNestedInput>;
  connect?: Maybe<PersonWhereUniqueInput>;
};

export type MessageUpdateManyWithoutDiscussionInput = {
  create?: Maybe<Array<MessageCreateWithoutDiscussionInput>>;
  delete?: Maybe<Array<MessageWhereUniqueInput>>;
  connect?: Maybe<Array<MessageWhereUniqueInput>>;
  set?: Maybe<Array<MessageWhereUniqueInput>>;
  disconnect?: Maybe<Array<MessageWhereUniqueInput>>;
  update?: Maybe<Array<MessageUpdateWithWhereUniqueWithoutDiscussionInput>>;
  upsert?: Maybe<Array<MessageUpsertWithWhereUniqueWithoutDiscussionInput>>;
  deleteMany?: Maybe<Array<MessageScalarWhereInput>>;
  updateMany?: Maybe<Array<MessageUpdateManyWithWhereNestedInput>>;
};

export type MessageUpdateWithWhereUniqueWithoutDiscussionInput = {
  where: MessageWhereUniqueInput;
  data: MessageUpdateWithoutDiscussionDataInput;
};

export type MessageUpdateWithoutDiscussionDataInput = {
  message?: Maybe<Scalars['String']>;
  likes?: Maybe<PersonUpdateManyInput>;
  depth?: Maybe<Scalars['Int']>;
  parent?: Maybe<MessageUpdateOneWithoutParentInput>;
  isBlocked?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  flags?: Maybe<PersonUpdateManyInput>;
  createdBy?: Maybe<PersonUpdateOneInput>;
  userType?: Maybe<Scalars['String']>;
};

export type MessageUpdateOneWithoutParentInput = {
  create?: Maybe<MessageCreateWithoutParentInput>;
  update?: Maybe<MessageUpdateWithoutParentDataInput>;
  upsert?: Maybe<MessageUpsertWithoutParentInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<MessageWhereUniqueInput>;
};

export type MessageUpdateWithoutParentDataInput = {
  message?: Maybe<Scalars['String']>;
  likes?: Maybe<PersonUpdateManyInput>;
  discussion?: Maybe<DiscussionUpdateOneRequiredWithoutMessagesInput>;
  depth?: Maybe<Scalars['Int']>;
  isBlocked?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  flags?: Maybe<PersonUpdateManyInput>;
  createdBy?: Maybe<PersonUpdateOneInput>;
  userType?: Maybe<Scalars['String']>;
};

export type DiscussionUpdateOneRequiredWithoutMessagesInput = {
  create?: Maybe<DiscussionCreateWithoutMessagesInput>;
  update?: Maybe<DiscussionUpdateWithoutMessagesDataInput>;
  upsert?: Maybe<DiscussionUpsertWithoutMessagesInput>;
  connect?: Maybe<DiscussionWhereUniqueInput>;
};

export type DiscussionUpdateWithoutMessagesDataInput = {
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  isLive?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Discussion_Type>;
  isArchived?: Maybe<Scalars['Boolean']>;
  archiveStatus?: Maybe<Archive_Progress>;
  status?: Maybe<Scalars['String']>;
  scheduleAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<PersonUpdateOneRequiredInput>;
  owner?: Maybe<PersonUpdateOneRequiredInput>;
  archiveMessages?: Maybe<ArchiveMessageUpdateManyWithoutDiscussionInput>;
  startTime?: Maybe<Scalars['DateTime']>;
  endTime?: Maybe<Scalars['DateTime']>;
};

export type ArchiveMessageUpdateManyWithoutDiscussionInput = {
  create?: Maybe<Array<ArchiveMessageCreateWithoutDiscussionInput>>;
  delete?: Maybe<Array<ArchiveMessageWhereUniqueInput>>;
  connect?: Maybe<Array<ArchiveMessageWhereUniqueInput>>;
  set?: Maybe<Array<ArchiveMessageWhereUniqueInput>>;
  disconnect?: Maybe<Array<ArchiveMessageWhereUniqueInput>>;
  update?: Maybe<Array<ArchiveMessageUpdateWithWhereUniqueWithoutDiscussionInput>>;
  upsert?: Maybe<Array<ArchiveMessageUpsertWithWhereUniqueWithoutDiscussionInput>>;
  deleteMany?: Maybe<Array<ArchiveMessageScalarWhereInput>>;
  updateMany?: Maybe<Array<ArchiveMessageUpdateManyWithWhereNestedInput>>;
};

export type ArchiveMessageUpdateWithWhereUniqueWithoutDiscussionInput = {
  where: ArchiveMessageWhereUniqueInput;
  data: ArchiveMessageUpdateWithoutDiscussionDataInput;
};

export type ArchiveMessageUpdateWithoutDiscussionDataInput = {
  message?: Maybe<Scalars['String']>;
  likes?: Maybe<PersonUpdateManyInput>;
  depth?: Maybe<Scalars['Int']>;
  parent?: Maybe<ArchiveMessageUpdateOneWithoutParentInput>;
  isBlocked?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  flags?: Maybe<PersonUpdateManyInput>;
  createdBy?: Maybe<PersonUpdateOneInput>;
  userType?: Maybe<Scalars['String']>;
};

export type ArchiveMessageUpdateOneWithoutParentInput = {
  create?: Maybe<ArchiveMessageCreateWithoutParentInput>;
  update?: Maybe<ArchiveMessageUpdateWithoutParentDataInput>;
  upsert?: Maybe<ArchiveMessageUpsertWithoutParentInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ArchiveMessageWhereUniqueInput>;
};

export type ArchiveMessageUpdateWithoutParentDataInput = {
  message?: Maybe<Scalars['String']>;
  likes?: Maybe<PersonUpdateManyInput>;
  discussion?: Maybe<DiscussionUpdateOneRequiredWithoutArchiveMessagesInput>;
  depth?: Maybe<Scalars['Int']>;
  isBlocked?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  flags?: Maybe<PersonUpdateManyInput>;
  createdBy?: Maybe<PersonUpdateOneInput>;
  userType?: Maybe<Scalars['String']>;
};

export type ArchiveMessageUpsertWithoutParentInput = {
  update: ArchiveMessageUpdateWithoutParentDataInput;
  create: ArchiveMessageCreateWithoutParentInput;
};

export type ArchiveMessageUpsertWithWhereUniqueWithoutDiscussionInput = {
  where: ArchiveMessageWhereUniqueInput;
  update: ArchiveMessageUpdateWithoutDiscussionDataInput;
  create: ArchiveMessageCreateWithoutDiscussionInput;
};

export type ArchiveMessageScalarWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  message?: Maybe<Scalars['String']>;
  message_not?: Maybe<Scalars['String']>;
  message_in?: Maybe<Array<Scalars['String']>>;
  message_not_in?: Maybe<Array<Scalars['String']>>;
  message_lt?: Maybe<Scalars['String']>;
  message_lte?: Maybe<Scalars['String']>;
  message_gt?: Maybe<Scalars['String']>;
  message_gte?: Maybe<Scalars['String']>;
  message_contains?: Maybe<Scalars['String']>;
  message_not_contains?: Maybe<Scalars['String']>;
  message_starts_with?: Maybe<Scalars['String']>;
  message_not_starts_with?: Maybe<Scalars['String']>;
  message_ends_with?: Maybe<Scalars['String']>;
  message_not_ends_with?: Maybe<Scalars['String']>;
  depth?: Maybe<Scalars['Int']>;
  depth_not?: Maybe<Scalars['Int']>;
  depth_in?: Maybe<Array<Scalars['Int']>>;
  depth_not_in?: Maybe<Array<Scalars['Int']>>;
  depth_lt?: Maybe<Scalars['Int']>;
  depth_lte?: Maybe<Scalars['Int']>;
  depth_gt?: Maybe<Scalars['Int']>;
  depth_gte?: Maybe<Scalars['Int']>;
  isBlocked?: Maybe<Scalars['Boolean']>;
  isBlocked_not?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isDeleted_not?: Maybe<Scalars['Boolean']>;
  userType?: Maybe<Scalars['String']>;
  userType_not?: Maybe<Scalars['String']>;
  userType_in?: Maybe<Array<Scalars['String']>>;
  userType_not_in?: Maybe<Array<Scalars['String']>>;
  userType_lt?: Maybe<Scalars['String']>;
  userType_lte?: Maybe<Scalars['String']>;
  userType_gt?: Maybe<Scalars['String']>;
  userType_gte?: Maybe<Scalars['String']>;
  userType_contains?: Maybe<Scalars['String']>;
  userType_not_contains?: Maybe<Scalars['String']>;
  userType_starts_with?: Maybe<Scalars['String']>;
  userType_not_starts_with?: Maybe<Scalars['String']>;
  userType_ends_with?: Maybe<Scalars['String']>;
  userType_not_ends_with?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<ArchiveMessageScalarWhereInput>>;
  OR?: Maybe<Array<ArchiveMessageScalarWhereInput>>;
  NOT?: Maybe<Array<ArchiveMessageScalarWhereInput>>;
};

export type ArchiveMessageUpdateManyWithWhereNestedInput = {
  where: ArchiveMessageScalarWhereInput;
  data: ArchiveMessageUpdateManyDataInput;
};

export type ArchiveMessageUpdateManyDataInput = {
  message?: Maybe<Scalars['String']>;
  depth?: Maybe<Scalars['Int']>;
  isBlocked?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  userType?: Maybe<Scalars['String']>;
};

export type DiscussionUpsertWithoutMessagesInput = {
  update: DiscussionUpdateWithoutMessagesDataInput;
  create: DiscussionCreateWithoutMessagesInput;
};

export type MessageUpsertWithoutParentInput = {
  update: MessageUpdateWithoutParentDataInput;
  create: MessageCreateWithoutParentInput;
};

export type MessageUpsertWithWhereUniqueWithoutDiscussionInput = {
  where: MessageWhereUniqueInput;
  update: MessageUpdateWithoutDiscussionDataInput;
  create: MessageCreateWithoutDiscussionInput;
};

export type MessageScalarWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  message?: Maybe<Scalars['String']>;
  message_not?: Maybe<Scalars['String']>;
  message_in?: Maybe<Array<Scalars['String']>>;
  message_not_in?: Maybe<Array<Scalars['String']>>;
  message_lt?: Maybe<Scalars['String']>;
  message_lte?: Maybe<Scalars['String']>;
  message_gt?: Maybe<Scalars['String']>;
  message_gte?: Maybe<Scalars['String']>;
  message_contains?: Maybe<Scalars['String']>;
  message_not_contains?: Maybe<Scalars['String']>;
  message_starts_with?: Maybe<Scalars['String']>;
  message_not_starts_with?: Maybe<Scalars['String']>;
  message_ends_with?: Maybe<Scalars['String']>;
  message_not_ends_with?: Maybe<Scalars['String']>;
  depth?: Maybe<Scalars['Int']>;
  depth_not?: Maybe<Scalars['Int']>;
  depth_in?: Maybe<Array<Scalars['Int']>>;
  depth_not_in?: Maybe<Array<Scalars['Int']>>;
  depth_lt?: Maybe<Scalars['Int']>;
  depth_lte?: Maybe<Scalars['Int']>;
  depth_gt?: Maybe<Scalars['Int']>;
  depth_gte?: Maybe<Scalars['Int']>;
  isBlocked?: Maybe<Scalars['Boolean']>;
  isBlocked_not?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isDeleted_not?: Maybe<Scalars['Boolean']>;
  userType?: Maybe<Scalars['String']>;
  userType_not?: Maybe<Scalars['String']>;
  userType_in?: Maybe<Array<Scalars['String']>>;
  userType_not_in?: Maybe<Array<Scalars['String']>>;
  userType_lt?: Maybe<Scalars['String']>;
  userType_lte?: Maybe<Scalars['String']>;
  userType_gt?: Maybe<Scalars['String']>;
  userType_gte?: Maybe<Scalars['String']>;
  userType_contains?: Maybe<Scalars['String']>;
  userType_not_contains?: Maybe<Scalars['String']>;
  userType_starts_with?: Maybe<Scalars['String']>;
  userType_not_starts_with?: Maybe<Scalars['String']>;
  userType_ends_with?: Maybe<Scalars['String']>;
  userType_not_ends_with?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<MessageScalarWhereInput>>;
  OR?: Maybe<Array<MessageScalarWhereInput>>;
  NOT?: Maybe<Array<MessageScalarWhereInput>>;
};

export type MessageUpdateManyWithWhereNestedInput = {
  where: MessageScalarWhereInput;
  data: MessageUpdateManyDataInput;
};

export type MessageUpdateManyDataInput = {
  message?: Maybe<Scalars['String']>;
  depth?: Maybe<Scalars['Int']>;
  isBlocked?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  userType?: Maybe<Scalars['String']>;
};

export type DiscussionUpsertWithoutArchiveMessagesInput = {
  update: DiscussionUpdateWithoutArchiveMessagesDataInput;
  create: DiscussionCreateWithoutArchiveMessagesInput;
};

export type ArchiveMessageUpdateManyMutationInput = {
  message?: Maybe<Scalars['String']>;
  depth?: Maybe<Scalars['Int']>;
  isBlocked?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  userType?: Maybe<Scalars['String']>;
};

export type BadgeCreateInput = {
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type: BadgeType;
  weight?: Maybe<Scalars['Int']>;
  defaultPlaceHolderImage?: Maybe<Scalars['String']>;
  isCustom?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  canAssignUsingScript?: Maybe<Scalars['Boolean']>;
  shopifySkuList?: Maybe<BadgeCreateshopifySkuListInput>;
};

export type BadgeCreateshopifySkuListInput = {
  set?: Maybe<Array<Scalars['String']>>;
};

export type BadgeUpdateInput = {
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<BadgeType>;
  weight?: Maybe<Scalars['Int']>;
  defaultPlaceHolderImage?: Maybe<Scalars['String']>;
  isCustom?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  canAssignUsingScript?: Maybe<Scalars['Boolean']>;
  shopifySkuList?: Maybe<BadgeUpdateshopifySkuListInput>;
};

export type BadgeUpdateshopifySkuListInput = {
  set?: Maybe<Array<Scalars['String']>>;
};

export type BadgeUpdateManyMutationInput = {
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<BadgeType>;
  weight?: Maybe<Scalars['Int']>;
  defaultPlaceHolderImage?: Maybe<Scalars['String']>;
  isCustom?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  canAssignUsingScript?: Maybe<Scalars['Boolean']>;
  shopifySkuList?: Maybe<BadgeUpdateshopifySkuListInput>;
};

export type CommentCreateInput = {
  id?: Maybe<Scalars['ID']>;
  comment?: Maybe<Scalars['String']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isHighlighted?: Maybe<Scalars['Boolean']>;
  isAccepted?: Maybe<Scalars['Boolean']>;
  deletedBy?: Maybe<PersonCreateOneInput>;
  likes?: Maybe<PersonCreateManyInput>;
  likeCount?: Maybe<Scalars['Int']>;
  starCount?: Maybe<Scalars['Int']>;
  flagCount?: Maybe<Scalars['Int']>;
  flags?: Maybe<PersonCreateManyInput>;
  stars?: Maybe<PersonCreateManyInput>;
  mentions?: Maybe<PersonCreateManyInput>;
  post: PostCreateOneWithoutCommentsInput;
  parent?: Maybe<CommentCreateOneWithoutParentInput>;
  createdBy?: Maybe<PersonCreateOneInput>;
  moderationResponse?: Maybe<Scalars['Json']>;
  moderationScore?: Maybe<Scalars['Float']>;
  moderationLabel?: Maybe<Scalars['String']>;
  moderationError?: Maybe<Scalars['Json']>;
  mediaModerationResponse?: Maybe<Scalars['Json']>;
  mediaModerationError?: Maybe<Scalars['Json']>;
  mediaType?: Maybe<CommentMediaType>;
  mediaDimensions?: Maybe<Scalars['Json']>;
  mediaURL?: Maybe<Scalars['String']>;
  giphyId?: Maybe<Scalars['String']>;
};

export type PostCreateOneWithoutCommentsInput = {
  create?: Maybe<PostCreateWithoutCommentsInput>;
  connect?: Maybe<PostWhereUniqueInput>;
};

export type PostCreateWithoutCommentsInput = {
  id?: Maybe<Scalars['ID']>;
  isLive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  referenceType?: Maybe<PostReferenceType>;
  referenceId?: Maybe<Scalars['String']>;
  referenceSlug?: Maybe<Scalars['String']>;
  likesCount?: Maybe<Scalars['Int']>;
  commentsCount?: Maybe<Scalars['Int']>;
  viewsCount?: Maybe<Scalars['Int']>;
  playsCount?: Maybe<Scalars['Int']>;
  text?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  liveStreamEndedAt?: Maybe<Scalars['DateTime']>;
  retentionPeriod?: Maybe<Scalars['Int']>;
  video?: Maybe<PostVideoCreateOneWithoutPostInput>;
  mediaDimensions?: Maybe<Scalars['Json']>;
  likes?: Maybe<PersonCreateManyInput>;
  createdBy?: Maybe<PersonCreateOneInput>;
  updatedBy?: Maybe<PersonCreateOneInput>;
};

export type PostVideoCreateOneWithoutPostInput = {
  create?: Maybe<PostVideoCreateWithoutPostInput>;
  connect?: Maybe<PostVideoWhereUniqueInput>;
};

export type PostVideoCreateWithoutPostInput = {
  id?: Maybe<Scalars['ID']>;
  muxAssetId?: Maybe<Scalars['String']>;
  streamKey?: Maybe<Scalars['String']>;
  uploadId?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  videoGIF?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  videoState?: Maybe<Scalars['String']>;
  muxPlaybackId?: Maybe<Scalars['String']>;
  playbackPolicy?: Maybe<Scalars['String']>;
  videoURL?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
};

export type CommentCreateOneWithoutParentInput = {
  create?: Maybe<CommentCreateWithoutParentInput>;
  connect?: Maybe<CommentWhereUniqueInput>;
};

export type CommentCreateWithoutParentInput = {
  id?: Maybe<Scalars['ID']>;
  comment?: Maybe<Scalars['String']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isHighlighted?: Maybe<Scalars['Boolean']>;
  isAccepted?: Maybe<Scalars['Boolean']>;
  deletedBy?: Maybe<PersonCreateOneInput>;
  likes?: Maybe<PersonCreateManyInput>;
  likeCount?: Maybe<Scalars['Int']>;
  starCount?: Maybe<Scalars['Int']>;
  flagCount?: Maybe<Scalars['Int']>;
  flags?: Maybe<PersonCreateManyInput>;
  stars?: Maybe<PersonCreateManyInput>;
  mentions?: Maybe<PersonCreateManyInput>;
  post: PostCreateOneWithoutCommentsInput;
  createdBy?: Maybe<PersonCreateOneInput>;
  moderationResponse?: Maybe<Scalars['Json']>;
  moderationScore?: Maybe<Scalars['Float']>;
  moderationLabel?: Maybe<Scalars['String']>;
  moderationError?: Maybe<Scalars['Json']>;
  mediaModerationResponse?: Maybe<Scalars['Json']>;
  mediaModerationError?: Maybe<Scalars['Json']>;
  mediaType?: Maybe<CommentMediaType>;
  mediaDimensions?: Maybe<Scalars['Json']>;
  mediaURL?: Maybe<Scalars['String']>;
  giphyId?: Maybe<Scalars['String']>;
};

export type CommentUpdateInput = {
  comment?: Maybe<Scalars['String']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isHighlighted?: Maybe<Scalars['Boolean']>;
  isAccepted?: Maybe<Scalars['Boolean']>;
  deletedBy?: Maybe<PersonUpdateOneInput>;
  likes?: Maybe<PersonUpdateManyInput>;
  likeCount?: Maybe<Scalars['Int']>;
  starCount?: Maybe<Scalars['Int']>;
  flagCount?: Maybe<Scalars['Int']>;
  flags?: Maybe<PersonUpdateManyInput>;
  stars?: Maybe<PersonUpdateManyInput>;
  mentions?: Maybe<PersonUpdateManyInput>;
  post?: Maybe<PostUpdateOneRequiredWithoutCommentsInput>;
  parent?: Maybe<CommentUpdateOneWithoutParentInput>;
  createdBy?: Maybe<PersonUpdateOneInput>;
  moderationResponse?: Maybe<Scalars['Json']>;
  moderationScore?: Maybe<Scalars['Float']>;
  moderationLabel?: Maybe<Scalars['String']>;
  moderationError?: Maybe<Scalars['Json']>;
  mediaModerationResponse?: Maybe<Scalars['Json']>;
  mediaModerationError?: Maybe<Scalars['Json']>;
  mediaType?: Maybe<CommentMediaType>;
  mediaDimensions?: Maybe<Scalars['Json']>;
  mediaURL?: Maybe<Scalars['String']>;
  giphyId?: Maybe<Scalars['String']>;
};

export type PostUpdateOneRequiredWithoutCommentsInput = {
  create?: Maybe<PostCreateWithoutCommentsInput>;
  update?: Maybe<PostUpdateWithoutCommentsDataInput>;
  upsert?: Maybe<PostUpsertWithoutCommentsInput>;
  connect?: Maybe<PostWhereUniqueInput>;
};

export type PostUpdateWithoutCommentsDataInput = {
  isLive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  referenceType?: Maybe<PostReferenceType>;
  referenceId?: Maybe<Scalars['String']>;
  referenceSlug?: Maybe<Scalars['String']>;
  likesCount?: Maybe<Scalars['Int']>;
  commentsCount?: Maybe<Scalars['Int']>;
  viewsCount?: Maybe<Scalars['Int']>;
  playsCount?: Maybe<Scalars['Int']>;
  text?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  liveStreamEndedAt?: Maybe<Scalars['DateTime']>;
  retentionPeriod?: Maybe<Scalars['Int']>;
  video?: Maybe<PostVideoUpdateOneWithoutPostInput>;
  mediaDimensions?: Maybe<Scalars['Json']>;
  likes?: Maybe<PersonUpdateManyInput>;
  createdBy?: Maybe<PersonUpdateOneInput>;
  updatedBy?: Maybe<PersonUpdateOneInput>;
};

export type PostVideoUpdateOneWithoutPostInput = {
  create?: Maybe<PostVideoCreateWithoutPostInput>;
  update?: Maybe<PostVideoUpdateWithoutPostDataInput>;
  upsert?: Maybe<PostVideoUpsertWithoutPostInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<PostVideoWhereUniqueInput>;
};

export type PostVideoUpdateWithoutPostDataInput = {
  muxAssetId?: Maybe<Scalars['String']>;
  streamKey?: Maybe<Scalars['String']>;
  uploadId?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  videoGIF?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  videoState?: Maybe<Scalars['String']>;
  muxPlaybackId?: Maybe<Scalars['String']>;
  playbackPolicy?: Maybe<Scalars['String']>;
  videoURL?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
};

export type PostVideoUpsertWithoutPostInput = {
  update: PostVideoUpdateWithoutPostDataInput;
  create: PostVideoCreateWithoutPostInput;
};

export type PostUpsertWithoutCommentsInput = {
  update: PostUpdateWithoutCommentsDataInput;
  create: PostCreateWithoutCommentsInput;
};

export type CommentUpdateOneWithoutParentInput = {
  create?: Maybe<CommentCreateWithoutParentInput>;
  update?: Maybe<CommentUpdateWithoutParentDataInput>;
  upsert?: Maybe<CommentUpsertWithoutParentInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<CommentWhereUniqueInput>;
};

export type CommentUpdateWithoutParentDataInput = {
  comment?: Maybe<Scalars['String']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isHighlighted?: Maybe<Scalars['Boolean']>;
  isAccepted?: Maybe<Scalars['Boolean']>;
  deletedBy?: Maybe<PersonUpdateOneInput>;
  likes?: Maybe<PersonUpdateManyInput>;
  likeCount?: Maybe<Scalars['Int']>;
  starCount?: Maybe<Scalars['Int']>;
  flagCount?: Maybe<Scalars['Int']>;
  flags?: Maybe<PersonUpdateManyInput>;
  stars?: Maybe<PersonUpdateManyInput>;
  mentions?: Maybe<PersonUpdateManyInput>;
  post?: Maybe<PostUpdateOneRequiredWithoutCommentsInput>;
  createdBy?: Maybe<PersonUpdateOneInput>;
  moderationResponse?: Maybe<Scalars['Json']>;
  moderationScore?: Maybe<Scalars['Float']>;
  moderationLabel?: Maybe<Scalars['String']>;
  moderationError?: Maybe<Scalars['Json']>;
  mediaModerationResponse?: Maybe<Scalars['Json']>;
  mediaModerationError?: Maybe<Scalars['Json']>;
  mediaType?: Maybe<CommentMediaType>;
  mediaDimensions?: Maybe<Scalars['Json']>;
  mediaURL?: Maybe<Scalars['String']>;
  giphyId?: Maybe<Scalars['String']>;
};

export type CommentUpsertWithoutParentInput = {
  update: CommentUpdateWithoutParentDataInput;
  create: CommentCreateWithoutParentInput;
};

export type CommentUpdateManyMutationInput = {
  comment?: Maybe<Scalars['String']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isHighlighted?: Maybe<Scalars['Boolean']>;
  isAccepted?: Maybe<Scalars['Boolean']>;
  likeCount?: Maybe<Scalars['Int']>;
  starCount?: Maybe<Scalars['Int']>;
  flagCount?: Maybe<Scalars['Int']>;
  moderationResponse?: Maybe<Scalars['Json']>;
  moderationScore?: Maybe<Scalars['Float']>;
  moderationLabel?: Maybe<Scalars['String']>;
  moderationError?: Maybe<Scalars['Json']>;
  mediaModerationResponse?: Maybe<Scalars['Json']>;
  mediaModerationError?: Maybe<Scalars['Json']>;
  mediaType?: Maybe<CommentMediaType>;
  mediaDimensions?: Maybe<Scalars['Json']>;
  mediaURL?: Maybe<Scalars['String']>;
  giphyId?: Maybe<Scalars['String']>;
};

export type ConfigurationCreateInput = {
  id?: Maybe<Scalars['Int']>;
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  type?: Maybe<ConfigurationType>;
};

export type ConfigurationUpdateInput = {
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  type?: Maybe<ConfigurationType>;
};

export type ConfigurationUpdateManyMutationInput = {
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  type?: Maybe<ConfigurationType>;
};

export type DiscussionCreateInput = {
  id?: Maybe<Scalars['ID']>;
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  isLive?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Discussion_Type>;
  isArchived?: Maybe<Scalars['Boolean']>;
  archiveStatus?: Maybe<Archive_Progress>;
  status?: Maybe<Scalars['String']>;
  scheduleAt?: Maybe<Scalars['DateTime']>;
  createdBy: PersonCreateOneInput;
  owner: PersonCreateOneInput;
  messages?: Maybe<MessageCreateManyWithoutDiscussionInput>;
  archiveMessages?: Maybe<ArchiveMessageCreateManyWithoutDiscussionInput>;
  startTime?: Maybe<Scalars['DateTime']>;
  endTime?: Maybe<Scalars['DateTime']>;
};

export type DiscussionUpdateInput = {
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  isLive?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Discussion_Type>;
  isArchived?: Maybe<Scalars['Boolean']>;
  archiveStatus?: Maybe<Archive_Progress>;
  status?: Maybe<Scalars['String']>;
  scheduleAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<PersonUpdateOneRequiredInput>;
  owner?: Maybe<PersonUpdateOneRequiredInput>;
  messages?: Maybe<MessageUpdateManyWithoutDiscussionInput>;
  archiveMessages?: Maybe<ArchiveMessageUpdateManyWithoutDiscussionInput>;
  startTime?: Maybe<Scalars['DateTime']>;
  endTime?: Maybe<Scalars['DateTime']>;
};

export type DiscussionUpdateManyMutationInput = {
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  isLive?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Discussion_Type>;
  isArchived?: Maybe<Scalars['Boolean']>;
  archiveStatus?: Maybe<Archive_Progress>;
  status?: Maybe<Scalars['String']>;
  scheduleAt?: Maybe<Scalars['DateTime']>;
  startTime?: Maybe<Scalars['DateTime']>;
  endTime?: Maybe<Scalars['DateTime']>;
};

export type EmailChangeRequestCreateInput = {
  id?: Maybe<Scalars['ID']>;
  currentEmail?: Maybe<Scalars['String']>;
  newEmail?: Maybe<Scalars['String']>;
  updateEmailInAuth0?: Maybe<Scalars['Boolean']>;
  updateEmailInRecurly?: Maybe<Scalars['Boolean']>;
  updateEmailInDatabase?: Maybe<Scalars['Boolean']>;
  updatedEmailInShopify?: Maybe<Scalars['Boolean']>;
  error?: Maybe<Scalars['Json']>;
  state?: Maybe<Scalars['Json']>;
};

export type EmailChangeRequestUpdateInput = {
  currentEmail?: Maybe<Scalars['String']>;
  newEmail?: Maybe<Scalars['String']>;
  updateEmailInAuth0?: Maybe<Scalars['Boolean']>;
  updateEmailInRecurly?: Maybe<Scalars['Boolean']>;
  updateEmailInDatabase?: Maybe<Scalars['Boolean']>;
  updatedEmailInShopify?: Maybe<Scalars['Boolean']>;
  error?: Maybe<Scalars['Json']>;
  state?: Maybe<Scalars['Json']>;
};

export type EmailChangeRequestUpdateManyMutationInput = {
  currentEmail?: Maybe<Scalars['String']>;
  newEmail?: Maybe<Scalars['String']>;
  updateEmailInAuth0?: Maybe<Scalars['Boolean']>;
  updateEmailInRecurly?: Maybe<Scalars['Boolean']>;
  updateEmailInDatabase?: Maybe<Scalars['Boolean']>;
  updatedEmailInShopify?: Maybe<Scalars['Boolean']>;
  error?: Maybe<Scalars['Json']>;
  state?: Maybe<Scalars['Json']>;
};

export type MessageCreateInput = {
  id?: Maybe<Scalars['ID']>;
  message: Scalars['String'];
  likes?: Maybe<PersonCreateManyInput>;
  discussion: DiscussionCreateOneWithoutMessagesInput;
  depth?: Maybe<Scalars['Int']>;
  parent?: Maybe<MessageCreateOneWithoutParentInput>;
  isBlocked?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  flags?: Maybe<PersonCreateManyInput>;
  createdBy?: Maybe<PersonCreateOneInput>;
  userType?: Maybe<Scalars['String']>;
};

export type MessageUpdateInput = {
  message?: Maybe<Scalars['String']>;
  likes?: Maybe<PersonUpdateManyInput>;
  discussion?: Maybe<DiscussionUpdateOneRequiredWithoutMessagesInput>;
  depth?: Maybe<Scalars['Int']>;
  parent?: Maybe<MessageUpdateOneWithoutParentInput>;
  isBlocked?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  flags?: Maybe<PersonUpdateManyInput>;
  createdBy?: Maybe<PersonUpdateOneInput>;
  userType?: Maybe<Scalars['String']>;
};

export type MessageUpdateManyMutationInput = {
  message?: Maybe<Scalars['String']>;
  depth?: Maybe<Scalars['Int']>;
  isBlocked?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  userType?: Maybe<Scalars['String']>;
};

export type NotificationCreateInput = {
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  sender?: Maybe<PersonCreateOneInput>;
  receiver?: Maybe<PersonCreateOneInput>;
  type?: Maybe<Notification_Type>;
  referenceType?: Maybe<Notification_Ref_Type>;
  referenceData?: Maybe<Scalars['Json']>;
  referenceId?: Maybe<Scalars['String']>;
};

export type NotificationUpdateInput = {
  title?: Maybe<Scalars['String']>;
  sender?: Maybe<PersonUpdateOneInput>;
  receiver?: Maybe<PersonUpdateOneInput>;
  type?: Maybe<Notification_Type>;
  referenceType?: Maybe<Notification_Ref_Type>;
  referenceData?: Maybe<Scalars['Json']>;
  referenceId?: Maybe<Scalars['String']>;
};

export type NotificationUpdateManyMutationInput = {
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Notification_Type>;
  referenceType?: Maybe<Notification_Ref_Type>;
  referenceData?: Maybe<Scalars['Json']>;
  referenceId?: Maybe<Scalars['String']>;
};

export type NotificationUserCreateInput = {
  id?: Maybe<Scalars['ID']>;
  person?: Maybe<PersonCreateOneInput>;
  notification?: Maybe<NotificationCreateOneInput>;
  hasRead?: Maybe<Scalars['Boolean']>;
};

export type NotificationCreateOneInput = {
  create?: Maybe<NotificationCreateInput>;
  connect?: Maybe<NotificationWhereUniqueInput>;
};

export type NotificationUserUpdateInput = {
  person?: Maybe<PersonUpdateOneInput>;
  notification?: Maybe<NotificationUpdateOneInput>;
  hasRead?: Maybe<Scalars['Boolean']>;
};

export type NotificationUpdateOneInput = {
  create?: Maybe<NotificationCreateInput>;
  update?: Maybe<NotificationUpdateDataInput>;
  upsert?: Maybe<NotificationUpsertNestedInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<NotificationWhereUniqueInput>;
};

export type NotificationUpdateDataInput = {
  title?: Maybe<Scalars['String']>;
  sender?: Maybe<PersonUpdateOneInput>;
  receiver?: Maybe<PersonUpdateOneInput>;
  type?: Maybe<Notification_Type>;
  referenceType?: Maybe<Notification_Ref_Type>;
  referenceData?: Maybe<Scalars['Json']>;
  referenceId?: Maybe<Scalars['String']>;
};

export type NotificationUpsertNestedInput = {
  update: NotificationUpdateDataInput;
  create: NotificationCreateInput;
};

export type NotificationUserUpdateManyMutationInput = {
  hasRead?: Maybe<Scalars['Boolean']>;
};

export type OneSignalPersonCreateInput = {
  id?: Maybe<Scalars['ID']>;
  person: PersonCreateOneInput;
  playerID: Scalars['String'];
};

export type OneSignalPersonUpdateInput = {
  person?: Maybe<PersonUpdateOneRequiredInput>;
  playerID?: Maybe<Scalars['String']>;
};

export type OneSignalPersonUpdateManyMutationInput = {
  playerID?: Maybe<Scalars['String']>;
};

export type OrderCreateInput = {
  id?: Maybe<Scalars['ID']>;
  shipHeroId?: Maybe<Scalars['String']>;
  shopifyOrderId?: Maybe<Scalars['String']>;
  person: PersonCreateOneInput;
  recurlyAccountCode?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<Scalars['String']>;
  cancelStatus?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['Json']>;
  metadata?: Maybe<Scalars['Json']>;
};

export type OrderUpdateInput = {
  shipHeroId?: Maybe<Scalars['String']>;
  shopifyOrderId?: Maybe<Scalars['String']>;
  person?: Maybe<PersonUpdateOneRequiredInput>;
  recurlyAccountCode?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<Scalars['String']>;
  cancelStatus?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['Json']>;
  metadata?: Maybe<Scalars['Json']>;
};

export type OrderUpdateManyMutationInput = {
  shipHeroId?: Maybe<Scalars['String']>;
  shopifyOrderId?: Maybe<Scalars['String']>;
  recurlyAccountCode?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<Scalars['String']>;
  cancelStatus?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['Json']>;
  metadata?: Maybe<Scalars['Json']>;
};

export type PermissionUpdateInput = {
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type PermissionUpdateManyMutationInput = {
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type PersonUpdateInput = {
  email?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  profileImage?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  authZeroID?: Maybe<Scalars['String']>;
  recurlyId?: Maybe<Scalars['String']>;
  recurlyPlan?: Maybe<Plans>;
  promoPlan?: Maybe<Plans>;
  promoPlanExpiration?: Maybe<Scalars['DateTime']>;
  permissionTemplate?: Maybe<TemplateUpdateOneInput>;
  role?: Maybe<Role>;
  badgeId?: Maybe<Scalars['ID']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isBanned?: Maybe<Scalars['Boolean']>;
  lastActiveOn?: Maybe<Scalars['DateTime']>;
};

export type PersonUpdateManyMutationInput = {
  email?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  profileImage?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  authZeroID?: Maybe<Scalars['String']>;
  recurlyId?: Maybe<Scalars['String']>;
  recurlyPlan?: Maybe<Plans>;
  promoPlan?: Maybe<Plans>;
  promoPlanExpiration?: Maybe<Scalars['DateTime']>;
  role?: Maybe<Role>;
  badgeId?: Maybe<Scalars['ID']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isBanned?: Maybe<Scalars['Boolean']>;
  lastActiveOn?: Maybe<Scalars['DateTime']>;
};

export type PersonPermissionCreateInput = {
  id?: Maybe<Scalars['ID']>;
  permission: PermissionCreateOneInput;
  person: PersonCreateOneInput;
  canRead?: Maybe<Scalars['Boolean']>;
  canMutate?: Maybe<Scalars['Boolean']>;
};

export type PersonPermissionUpdateInput = {
  permission?: Maybe<PermissionUpdateOneRequiredInput>;
  person?: Maybe<PersonUpdateOneRequiredInput>;
  canRead?: Maybe<Scalars['Boolean']>;
  canMutate?: Maybe<Scalars['Boolean']>;
};

export type PermissionUpdateOneRequiredInput = {
  create?: Maybe<PermissionCreateInput>;
  update?: Maybe<PermissionUpdateDataInput>;
  upsert?: Maybe<PermissionUpsertNestedInput>;
  connect?: Maybe<PermissionWhereUniqueInput>;
};

export type PersonPermissionUpdateManyMutationInput = {
  canRead?: Maybe<Scalars['Boolean']>;
  canMutate?: Maybe<Scalars['Boolean']>;
};

export type PostCreateInput = {
  id?: Maybe<Scalars['ID']>;
  isLive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  referenceType?: Maybe<PostReferenceType>;
  referenceId?: Maybe<Scalars['String']>;
  referenceSlug?: Maybe<Scalars['String']>;
  likesCount?: Maybe<Scalars['Int']>;
  commentsCount?: Maybe<Scalars['Int']>;
  viewsCount?: Maybe<Scalars['Int']>;
  playsCount?: Maybe<Scalars['Int']>;
  text?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  liveStreamEndedAt?: Maybe<Scalars['DateTime']>;
  retentionPeriod?: Maybe<Scalars['Int']>;
  video?: Maybe<PostVideoCreateOneWithoutPostInput>;
  mediaDimensions?: Maybe<Scalars['Json']>;
  likes?: Maybe<PersonCreateManyInput>;
  comments?: Maybe<CommentCreateManyWithoutPostInput>;
  createdBy?: Maybe<PersonCreateOneInput>;
  updatedBy?: Maybe<PersonCreateOneInput>;
};

export type CommentCreateManyWithoutPostInput = {
  create?: Maybe<Array<CommentCreateWithoutPostInput>>;
  connect?: Maybe<Array<CommentWhereUniqueInput>>;
};

export type CommentCreateWithoutPostInput = {
  id?: Maybe<Scalars['ID']>;
  comment?: Maybe<Scalars['String']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isHighlighted?: Maybe<Scalars['Boolean']>;
  isAccepted?: Maybe<Scalars['Boolean']>;
  deletedBy?: Maybe<PersonCreateOneInput>;
  likes?: Maybe<PersonCreateManyInput>;
  likeCount?: Maybe<Scalars['Int']>;
  starCount?: Maybe<Scalars['Int']>;
  flagCount?: Maybe<Scalars['Int']>;
  flags?: Maybe<PersonCreateManyInput>;
  stars?: Maybe<PersonCreateManyInput>;
  mentions?: Maybe<PersonCreateManyInput>;
  parent?: Maybe<CommentCreateOneWithoutParentInput>;
  createdBy?: Maybe<PersonCreateOneInput>;
  moderationResponse?: Maybe<Scalars['Json']>;
  moderationScore?: Maybe<Scalars['Float']>;
  moderationLabel?: Maybe<Scalars['String']>;
  moderationError?: Maybe<Scalars['Json']>;
  mediaModerationResponse?: Maybe<Scalars['Json']>;
  mediaModerationError?: Maybe<Scalars['Json']>;
  mediaType?: Maybe<CommentMediaType>;
  mediaDimensions?: Maybe<Scalars['Json']>;
  mediaURL?: Maybe<Scalars['String']>;
  giphyId?: Maybe<Scalars['String']>;
};

export type PostUpdateInput = {
  isLive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  referenceType?: Maybe<PostReferenceType>;
  referenceId?: Maybe<Scalars['String']>;
  referenceSlug?: Maybe<Scalars['String']>;
  likesCount?: Maybe<Scalars['Int']>;
  commentsCount?: Maybe<Scalars['Int']>;
  viewsCount?: Maybe<Scalars['Int']>;
  playsCount?: Maybe<Scalars['Int']>;
  text?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  liveStreamEndedAt?: Maybe<Scalars['DateTime']>;
  retentionPeriod?: Maybe<Scalars['Int']>;
  video?: Maybe<PostVideoUpdateOneWithoutPostInput>;
  mediaDimensions?: Maybe<Scalars['Json']>;
  likes?: Maybe<PersonUpdateManyInput>;
  comments?: Maybe<CommentUpdateManyWithoutPostInput>;
  createdBy?: Maybe<PersonUpdateOneInput>;
  updatedBy?: Maybe<PersonUpdateOneInput>;
};

export type CommentUpdateManyWithoutPostInput = {
  create?: Maybe<Array<CommentCreateWithoutPostInput>>;
  delete?: Maybe<Array<CommentWhereUniqueInput>>;
  connect?: Maybe<Array<CommentWhereUniqueInput>>;
  set?: Maybe<Array<CommentWhereUniqueInput>>;
  disconnect?: Maybe<Array<CommentWhereUniqueInput>>;
  update?: Maybe<Array<CommentUpdateWithWhereUniqueWithoutPostInput>>;
  upsert?: Maybe<Array<CommentUpsertWithWhereUniqueWithoutPostInput>>;
  deleteMany?: Maybe<Array<CommentScalarWhereInput>>;
  updateMany?: Maybe<Array<CommentUpdateManyWithWhereNestedInput>>;
};

export type CommentUpdateWithWhereUniqueWithoutPostInput = {
  where: CommentWhereUniqueInput;
  data: CommentUpdateWithoutPostDataInput;
};

export type CommentUpdateWithoutPostDataInput = {
  comment?: Maybe<Scalars['String']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isHighlighted?: Maybe<Scalars['Boolean']>;
  isAccepted?: Maybe<Scalars['Boolean']>;
  deletedBy?: Maybe<PersonUpdateOneInput>;
  likes?: Maybe<PersonUpdateManyInput>;
  likeCount?: Maybe<Scalars['Int']>;
  starCount?: Maybe<Scalars['Int']>;
  flagCount?: Maybe<Scalars['Int']>;
  flags?: Maybe<PersonUpdateManyInput>;
  stars?: Maybe<PersonUpdateManyInput>;
  mentions?: Maybe<PersonUpdateManyInput>;
  parent?: Maybe<CommentUpdateOneWithoutParentInput>;
  createdBy?: Maybe<PersonUpdateOneInput>;
  moderationResponse?: Maybe<Scalars['Json']>;
  moderationScore?: Maybe<Scalars['Float']>;
  moderationLabel?: Maybe<Scalars['String']>;
  moderationError?: Maybe<Scalars['Json']>;
  mediaModerationResponse?: Maybe<Scalars['Json']>;
  mediaModerationError?: Maybe<Scalars['Json']>;
  mediaType?: Maybe<CommentMediaType>;
  mediaDimensions?: Maybe<Scalars['Json']>;
  mediaURL?: Maybe<Scalars['String']>;
  giphyId?: Maybe<Scalars['String']>;
};

export type CommentUpsertWithWhereUniqueWithoutPostInput = {
  where: CommentWhereUniqueInput;
  update: CommentUpdateWithoutPostDataInput;
  create: CommentCreateWithoutPostInput;
};

export type CommentScalarWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  comment?: Maybe<Scalars['String']>;
  comment_not?: Maybe<Scalars['String']>;
  comment_in?: Maybe<Array<Scalars['String']>>;
  comment_not_in?: Maybe<Array<Scalars['String']>>;
  comment_lt?: Maybe<Scalars['String']>;
  comment_lte?: Maybe<Scalars['String']>;
  comment_gt?: Maybe<Scalars['String']>;
  comment_gte?: Maybe<Scalars['String']>;
  comment_contains?: Maybe<Scalars['String']>;
  comment_not_contains?: Maybe<Scalars['String']>;
  comment_starts_with?: Maybe<Scalars['String']>;
  comment_not_starts_with?: Maybe<Scalars['String']>;
  comment_ends_with?: Maybe<Scalars['String']>;
  comment_not_ends_with?: Maybe<Scalars['String']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isHidden_not?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isDeleted_not?: Maybe<Scalars['Boolean']>;
  isHighlighted?: Maybe<Scalars['Boolean']>;
  isHighlighted_not?: Maybe<Scalars['Boolean']>;
  isAccepted?: Maybe<Scalars['Boolean']>;
  isAccepted_not?: Maybe<Scalars['Boolean']>;
  likeCount?: Maybe<Scalars['Int']>;
  likeCount_not?: Maybe<Scalars['Int']>;
  likeCount_in?: Maybe<Array<Scalars['Int']>>;
  likeCount_not_in?: Maybe<Array<Scalars['Int']>>;
  likeCount_lt?: Maybe<Scalars['Int']>;
  likeCount_lte?: Maybe<Scalars['Int']>;
  likeCount_gt?: Maybe<Scalars['Int']>;
  likeCount_gte?: Maybe<Scalars['Int']>;
  starCount?: Maybe<Scalars['Int']>;
  starCount_not?: Maybe<Scalars['Int']>;
  starCount_in?: Maybe<Array<Scalars['Int']>>;
  starCount_not_in?: Maybe<Array<Scalars['Int']>>;
  starCount_lt?: Maybe<Scalars['Int']>;
  starCount_lte?: Maybe<Scalars['Int']>;
  starCount_gt?: Maybe<Scalars['Int']>;
  starCount_gte?: Maybe<Scalars['Int']>;
  flagCount?: Maybe<Scalars['Int']>;
  flagCount_not?: Maybe<Scalars['Int']>;
  flagCount_in?: Maybe<Array<Scalars['Int']>>;
  flagCount_not_in?: Maybe<Array<Scalars['Int']>>;
  flagCount_lt?: Maybe<Scalars['Int']>;
  flagCount_lte?: Maybe<Scalars['Int']>;
  flagCount_gt?: Maybe<Scalars['Int']>;
  flagCount_gte?: Maybe<Scalars['Int']>;
  moderationScore?: Maybe<Scalars['Float']>;
  moderationScore_not?: Maybe<Scalars['Float']>;
  moderationScore_in?: Maybe<Array<Scalars['Float']>>;
  moderationScore_not_in?: Maybe<Array<Scalars['Float']>>;
  moderationScore_lt?: Maybe<Scalars['Float']>;
  moderationScore_lte?: Maybe<Scalars['Float']>;
  moderationScore_gt?: Maybe<Scalars['Float']>;
  moderationScore_gte?: Maybe<Scalars['Float']>;
  moderationLabel?: Maybe<Scalars['String']>;
  moderationLabel_not?: Maybe<Scalars['String']>;
  moderationLabel_in?: Maybe<Array<Scalars['String']>>;
  moderationLabel_not_in?: Maybe<Array<Scalars['String']>>;
  moderationLabel_lt?: Maybe<Scalars['String']>;
  moderationLabel_lte?: Maybe<Scalars['String']>;
  moderationLabel_gt?: Maybe<Scalars['String']>;
  moderationLabel_gte?: Maybe<Scalars['String']>;
  moderationLabel_contains?: Maybe<Scalars['String']>;
  moderationLabel_not_contains?: Maybe<Scalars['String']>;
  moderationLabel_starts_with?: Maybe<Scalars['String']>;
  moderationLabel_not_starts_with?: Maybe<Scalars['String']>;
  moderationLabel_ends_with?: Maybe<Scalars['String']>;
  moderationLabel_not_ends_with?: Maybe<Scalars['String']>;
  mediaType?: Maybe<CommentMediaType>;
  mediaType_not?: Maybe<CommentMediaType>;
  mediaType_in?: Maybe<Array<CommentMediaType>>;
  mediaType_not_in?: Maybe<Array<CommentMediaType>>;
  mediaURL?: Maybe<Scalars['String']>;
  mediaURL_not?: Maybe<Scalars['String']>;
  mediaURL_in?: Maybe<Array<Scalars['String']>>;
  mediaURL_not_in?: Maybe<Array<Scalars['String']>>;
  mediaURL_lt?: Maybe<Scalars['String']>;
  mediaURL_lte?: Maybe<Scalars['String']>;
  mediaURL_gt?: Maybe<Scalars['String']>;
  mediaURL_gte?: Maybe<Scalars['String']>;
  mediaURL_contains?: Maybe<Scalars['String']>;
  mediaURL_not_contains?: Maybe<Scalars['String']>;
  mediaURL_starts_with?: Maybe<Scalars['String']>;
  mediaURL_not_starts_with?: Maybe<Scalars['String']>;
  mediaURL_ends_with?: Maybe<Scalars['String']>;
  mediaURL_not_ends_with?: Maybe<Scalars['String']>;
  giphyId?: Maybe<Scalars['String']>;
  giphyId_not?: Maybe<Scalars['String']>;
  giphyId_in?: Maybe<Array<Scalars['String']>>;
  giphyId_not_in?: Maybe<Array<Scalars['String']>>;
  giphyId_lt?: Maybe<Scalars['String']>;
  giphyId_lte?: Maybe<Scalars['String']>;
  giphyId_gt?: Maybe<Scalars['String']>;
  giphyId_gte?: Maybe<Scalars['String']>;
  giphyId_contains?: Maybe<Scalars['String']>;
  giphyId_not_contains?: Maybe<Scalars['String']>;
  giphyId_starts_with?: Maybe<Scalars['String']>;
  giphyId_not_starts_with?: Maybe<Scalars['String']>;
  giphyId_ends_with?: Maybe<Scalars['String']>;
  giphyId_not_ends_with?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<CommentScalarWhereInput>>;
  OR?: Maybe<Array<CommentScalarWhereInput>>;
  NOT?: Maybe<Array<CommentScalarWhereInput>>;
};

export type CommentUpdateManyWithWhereNestedInput = {
  where: CommentScalarWhereInput;
  data: CommentUpdateManyDataInput;
};

export type CommentUpdateManyDataInput = {
  comment?: Maybe<Scalars['String']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isHighlighted?: Maybe<Scalars['Boolean']>;
  isAccepted?: Maybe<Scalars['Boolean']>;
  likeCount?: Maybe<Scalars['Int']>;
  starCount?: Maybe<Scalars['Int']>;
  flagCount?: Maybe<Scalars['Int']>;
  moderationResponse?: Maybe<Scalars['Json']>;
  moderationScore?: Maybe<Scalars['Float']>;
  moderationLabel?: Maybe<Scalars['String']>;
  moderationError?: Maybe<Scalars['Json']>;
  mediaModerationResponse?: Maybe<Scalars['Json']>;
  mediaModerationError?: Maybe<Scalars['Json']>;
  mediaType?: Maybe<CommentMediaType>;
  mediaDimensions?: Maybe<Scalars['Json']>;
  mediaURL?: Maybe<Scalars['String']>;
  giphyId?: Maybe<Scalars['String']>;
};

export type PostUpdateManyMutationInput = {
  isLive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  referenceType?: Maybe<PostReferenceType>;
  referenceId?: Maybe<Scalars['String']>;
  referenceSlug?: Maybe<Scalars['String']>;
  likesCount?: Maybe<Scalars['Int']>;
  commentsCount?: Maybe<Scalars['Int']>;
  viewsCount?: Maybe<Scalars['Int']>;
  playsCount?: Maybe<Scalars['Int']>;
  text?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  liveStreamEndedAt?: Maybe<Scalars['DateTime']>;
  retentionPeriod?: Maybe<Scalars['Int']>;
  mediaDimensions?: Maybe<Scalars['Json']>;
};

export type PostVideoCreateInput = {
  id?: Maybe<Scalars['ID']>;
  muxAssetId?: Maybe<Scalars['String']>;
  streamKey?: Maybe<Scalars['String']>;
  uploadId?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  videoGIF?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  post?: Maybe<PostCreateOneWithoutVideoInput>;
  videoState?: Maybe<Scalars['String']>;
  muxPlaybackId?: Maybe<Scalars['String']>;
  playbackPolicy?: Maybe<Scalars['String']>;
  videoURL?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
};

export type PostCreateOneWithoutVideoInput = {
  create?: Maybe<PostCreateWithoutVideoInput>;
  connect?: Maybe<PostWhereUniqueInput>;
};

export type PostCreateWithoutVideoInput = {
  id?: Maybe<Scalars['ID']>;
  isLive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  referenceType?: Maybe<PostReferenceType>;
  referenceId?: Maybe<Scalars['String']>;
  referenceSlug?: Maybe<Scalars['String']>;
  likesCount?: Maybe<Scalars['Int']>;
  commentsCount?: Maybe<Scalars['Int']>;
  viewsCount?: Maybe<Scalars['Int']>;
  playsCount?: Maybe<Scalars['Int']>;
  text?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  liveStreamEndedAt?: Maybe<Scalars['DateTime']>;
  retentionPeriod?: Maybe<Scalars['Int']>;
  mediaDimensions?: Maybe<Scalars['Json']>;
  likes?: Maybe<PersonCreateManyInput>;
  comments?: Maybe<CommentCreateManyWithoutPostInput>;
  createdBy?: Maybe<PersonCreateOneInput>;
  updatedBy?: Maybe<PersonCreateOneInput>;
};

export type PostVideoUpdateInput = {
  muxAssetId?: Maybe<Scalars['String']>;
  streamKey?: Maybe<Scalars['String']>;
  uploadId?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  videoGIF?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  post?: Maybe<PostUpdateOneWithoutVideoInput>;
  videoState?: Maybe<Scalars['String']>;
  muxPlaybackId?: Maybe<Scalars['String']>;
  playbackPolicy?: Maybe<Scalars['String']>;
  videoURL?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
};

export type PostUpdateOneWithoutVideoInput = {
  create?: Maybe<PostCreateWithoutVideoInput>;
  update?: Maybe<PostUpdateWithoutVideoDataInput>;
  upsert?: Maybe<PostUpsertWithoutVideoInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<PostWhereUniqueInput>;
};

export type PostUpdateWithoutVideoDataInput = {
  isLive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  referenceType?: Maybe<PostReferenceType>;
  referenceId?: Maybe<Scalars['String']>;
  referenceSlug?: Maybe<Scalars['String']>;
  likesCount?: Maybe<Scalars['Int']>;
  commentsCount?: Maybe<Scalars['Int']>;
  viewsCount?: Maybe<Scalars['Int']>;
  playsCount?: Maybe<Scalars['Int']>;
  text?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  liveStreamEndedAt?: Maybe<Scalars['DateTime']>;
  retentionPeriod?: Maybe<Scalars['Int']>;
  mediaDimensions?: Maybe<Scalars['Json']>;
  likes?: Maybe<PersonUpdateManyInput>;
  comments?: Maybe<CommentUpdateManyWithoutPostInput>;
  createdBy?: Maybe<PersonUpdateOneInput>;
  updatedBy?: Maybe<PersonUpdateOneInput>;
};

export type PostUpsertWithoutVideoInput = {
  update: PostUpdateWithoutVideoDataInput;
  create: PostCreateWithoutVideoInput;
};

export type PostVideoUpdateManyMutationInput = {
  muxAssetId?: Maybe<Scalars['String']>;
  streamKey?: Maybe<Scalars['String']>;
  uploadId?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  videoGIF?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  videoState?: Maybe<Scalars['String']>;
  muxPlaybackId?: Maybe<Scalars['String']>;
  playbackPolicy?: Maybe<Scalars['String']>;
  videoURL?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
};

export type RecurlyErrorCreateInput = {
  id?: Maybe<Scalars['ID']>;
  person?: Maybe<PersonCreateOneInput>;
  recurlyId?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
};

export type RecurlyErrorUpdateInput = {
  person?: Maybe<PersonUpdateOneInput>;
  recurlyId?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
};

export type RecurlyErrorUpdateManyMutationInput = {
  recurlyId?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
};

export type RecurlyWebhookCreateInput = {
  id?: Maybe<Scalars['ID']>;
  accountCode?: Maybe<Scalars['String']>;
  webhookAction?: Maybe<Scalars['String']>;
  webhookPayload?: Maybe<Scalars['String']>;
};

export type RecurlyWebhookUpdateInput = {
  accountCode?: Maybe<Scalars['String']>;
  webhookAction?: Maybe<Scalars['String']>;
  webhookPayload?: Maybe<Scalars['String']>;
};

export type RecurlyWebhookUpdateManyMutationInput = {
  accountCode?: Maybe<Scalars['String']>;
  webhookAction?: Maybe<Scalars['String']>;
  webhookPayload?: Maybe<Scalars['String']>;
};

export type SettingCreateInput = {
  id?: Maybe<Scalars['ID']>;
  key?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<SettingType>;
  group?: Maybe<GroupType>;
  isHidden?: Maybe<Scalars['Boolean']>;
  defaultValue?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

export type SettingUpdateInput = {
  key?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<SettingType>;
  group?: Maybe<GroupType>;
  isHidden?: Maybe<Scalars['Boolean']>;
  defaultValue?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

export type SettingUpdateManyMutationInput = {
  key?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<SettingType>;
  group?: Maybe<GroupType>;
  isHidden?: Maybe<Scalars['Boolean']>;
  defaultValue?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

export type ShopifyWebhookCreateInput = {
  id?: Maybe<Scalars['ID']>;
  topic?: Maybe<Scalars['String']>;
  payload?: Maybe<Scalars['Json']>;
  error?: Maybe<Scalars['Json']>;
  orderId?: Maybe<Scalars['String']>;
  result?: Maybe<ResultType>;
};

export type ShopifyWebhookUpdateInput = {
  topic?: Maybe<Scalars['String']>;
  payload?: Maybe<Scalars['Json']>;
  error?: Maybe<Scalars['Json']>;
  orderId?: Maybe<Scalars['String']>;
  result?: Maybe<ResultType>;
};

export type ShopifyWebhookUpdateManyMutationInput = {
  topic?: Maybe<Scalars['String']>;
  payload?: Maybe<Scalars['Json']>;
  error?: Maybe<Scalars['Json']>;
  orderId?: Maybe<Scalars['String']>;
  result?: Maybe<ResultType>;
};

export type TemplateUpdateInput = {
  name?: Maybe<Scalars['String']>;
  permissions?: Maybe<TemplatePermissionUpdateManyWithoutTemplateInput>;
};

export type TemplateUpdateManyMutationInput = {
  name?: Maybe<Scalars['String']>;
};

export type TemplatePermissionCreateInput = {
  id?: Maybe<Scalars['ID']>;
  permission?: Maybe<PermissionCreateOneInput>;
  template: TemplateCreateOneWithoutPermissionsInput;
  canRead?: Maybe<Scalars['Boolean']>;
  canMutate?: Maybe<Scalars['Boolean']>;
};

export type TemplateCreateOneWithoutPermissionsInput = {
  create?: Maybe<TemplateCreateWithoutPermissionsInput>;
  connect?: Maybe<TemplateWhereUniqueInput>;
};

export type TemplateCreateWithoutPermissionsInput = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type TemplatePermissionUpdateInput = {
  permission?: Maybe<PermissionUpdateOneInput>;
  template?: Maybe<TemplateUpdateOneRequiredWithoutPermissionsInput>;
  canRead?: Maybe<Scalars['Boolean']>;
  canMutate?: Maybe<Scalars['Boolean']>;
  create?: Maybe<Array<Maybe<TemplatePermissionCreateCustomInput>>>;
  update?: Maybe<Array<Maybe<TemplatePermissionUpdateCustomInput>>>;
};

export type TemplateUpdateOneRequiredWithoutPermissionsInput = {
  create?: Maybe<TemplateCreateWithoutPermissionsInput>;
  update?: Maybe<TemplateUpdateWithoutPermissionsDataInput>;
  upsert?: Maybe<TemplateUpsertWithoutPermissionsInput>;
  connect?: Maybe<TemplateWhereUniqueInput>;
};

export type TemplateUpdateWithoutPermissionsDataInput = {
  name?: Maybe<Scalars['String']>;
};

export type TemplateUpsertWithoutPermissionsInput = {
  update: TemplateUpdateWithoutPermissionsDataInput;
  create: TemplateCreateWithoutPermissionsInput;
};

export type TemplatePermissionCreateCustomInput = {
  permissionId: Scalars['ID'];
  canRead?: Maybe<Scalars['Boolean']>;
  canMutate?: Maybe<Scalars['Boolean']>;
};

export type TemplatePermissionUpdateCustomInput = {
  templatePermissionId: Scalars['ID'];
  canRead?: Maybe<Scalars['Boolean']>;
  canMutate?: Maybe<Scalars['Boolean']>;
};

export type TemplatePermissionUpdateManyMutationInput = {
  canRead?: Maybe<Scalars['Boolean']>;
  canMutate?: Maybe<Scalars['Boolean']>;
};

export type UserBadgeCreateInput = {
  id?: Maybe<Scalars['ID']>;
  person: PersonCreateOneInput;
  badge: BadgeCreateOneInput;
  isProfileImage?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type BadgeCreateOneInput = {
  create?: Maybe<BadgeCreateInput>;
  connect?: Maybe<BadgeWhereUniqueInput>;
};

export type UserBadgeUpdateInput = {
  person?: Maybe<PersonUpdateOneRequiredInput>;
  badge?: Maybe<BadgeUpdateOneRequiredInput>;
  isProfileImage?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type BadgeUpdateOneRequiredInput = {
  create?: Maybe<BadgeCreateInput>;
  update?: Maybe<BadgeUpdateDataInput>;
  upsert?: Maybe<BadgeUpsertNestedInput>;
  connect?: Maybe<BadgeWhereUniqueInput>;
};

export type BadgeUpdateDataInput = {
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<BadgeType>;
  weight?: Maybe<Scalars['Int']>;
  defaultPlaceHolderImage?: Maybe<Scalars['String']>;
  isCustom?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  canAssignUsingScript?: Maybe<Scalars['Boolean']>;
  shopifySkuList?: Maybe<BadgeUpdateshopifySkuListInput>;
};

export type BadgeUpsertNestedInput = {
  update: BadgeUpdateDataInput;
  create: BadgeCreateInput;
};

export type UserBadgeUpdateManyMutationInput = {
  isProfileImage?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type UserSettingCreateInput = {
  id?: Maybe<Scalars['ID']>;
  person?: Maybe<PersonCreateOneInput>;
  setting?: Maybe<SettingCreateOneInput>;
  value?: Maybe<Scalars['String']>;
};

export type SettingCreateOneInput = {
  create?: Maybe<SettingCreateInput>;
  connect?: Maybe<SettingWhereUniqueInput>;
};

export type UserSettingUpdateInput = {
  person?: Maybe<PersonUpdateOneInput>;
  setting?: Maybe<SettingUpdateOneInput>;
  value?: Maybe<Scalars['String']>;
};

export type SettingUpdateOneInput = {
  create?: Maybe<SettingCreateInput>;
  update?: Maybe<SettingUpdateDataInput>;
  upsert?: Maybe<SettingUpsertNestedInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<SettingWhereUniqueInput>;
};

export type SettingUpdateDataInput = {
  key?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<SettingType>;
  group?: Maybe<GroupType>;
  isHidden?: Maybe<Scalars['Boolean']>;
  defaultValue?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

export type SettingUpsertNestedInput = {
  update: SettingUpdateDataInput;
  create: SettingCreateInput;
};

export type UserSettingUpdateManyMutationInput = {
  value?: Maybe<Scalars['String']>;
};

export type WatchTimeCreateInput = {
  id?: Maybe<Scalars['ID']>;
  person: PersonCreateOneInput;
  entityId?: Maybe<Scalars['String']>;
  watchTime?: Maybe<Scalars['Float']>;
  entityFormat?: Maybe<WatchEntityFormat>;
  entityType: WatchEntityType;
  personEntityId?: Maybe<Scalars['String']>;
};

export type WatchTimeUpdateInput = {
  person?: Maybe<PersonUpdateOneRequiredInput>;
  entityId?: Maybe<Scalars['String']>;
  watchTime?: Maybe<Scalars['Float']>;
  entityFormat?: Maybe<WatchEntityFormat>;
  entityType?: Maybe<WatchEntityType>;
  personEntityId?: Maybe<Scalars['String']>;
};

export type WatchTimeUpdateManyMutationInput = {
  entityId?: Maybe<Scalars['String']>;
  watchTime?: Maybe<Scalars['Float']>;
  entityFormat?: Maybe<WatchEntityFormat>;
  entityType?: Maybe<WatchEntityType>;
  personEntityId?: Maybe<Scalars['String']>;
};

export type ArchiveDiscussionWhereInput = {
  id: Scalars['ID'];
};

export type ArchiveDiscussionRes = {
  __typename?: 'archiveDiscussionRes';
  message?: Maybe<Scalars['String']>;
};

export type PurgeBadgeImageRes = {
  __typename?: 'purgeBadgeImageRes';
  purgeId?: Maybe<Scalars['String']>;
};

export type LikeCommentDataInput = {
  action?: Maybe<LikeCommentAction>;
};

export enum LikeCommentAction {
  Like = 'LIKE',
  Dislike = 'DISLIKE'
}

export type StarCommentDataInput = {
  action?: Maybe<StarCommentAction>;
};

export enum StarCommentAction {
  Star = 'STAR',
  Unstar = 'UNSTAR'
}

export type FlagCommentDataInput = {
  action?: Maybe<FlagCommentAction>;
};

export enum FlagCommentAction {
  Flag = 'FLAG',
  Unflag = 'UNFLAG'
}

export type UpdateCommentCustomDataInput = {
  comment?: Maybe<Scalars['String']>;
  mediaType?: Maybe<CommentMediaType>;
  mediaURL?: Maybe<Scalars['String']>;
  giphyId?: Maybe<Scalars['String']>;
  mediaDimensions?: Maybe<Scalars['Json']>;
};

export type PostDataInput = {
  title: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  scheduleAt?: Maybe<Scalars['DateTime']>;
  startTime?: Maybe<Scalars['DateTime']>;
  endTime?: Maybe<Scalars['DateTime']>;
};

export type UpdateEmailRes = {
  __typename?: 'updateEmailRes';
  message?: Maybe<Scalars['String']>;
};

export type UpdateCommentModerationDataInput = {
  isHidden?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isHighlighted?: Maybe<Scalars['Boolean']>;
  isAccepted?: Maybe<Scalars['Boolean']>;
};

export type BanUserRes = {
  __typename?: 'banUserRes';
  message?: Maybe<Scalars['String']>;
};

export type HideAllCommentsByUserWhereInput = {
  user: PersonWhereUniqueInput;
  post: PostWhereUniqueInput;
};

export type CreatePermissionTemplateDataInput = {
  name: Scalars['String'];
  permissions: Array<TemplatePermissionCreateCustomInput>;
};

export type UpdatePermissionTemplateDataInput = {
  name?: Maybe<Scalars['String']>;
  permissions?: Maybe<TemplatePermissionUpdateInput>;
};

export type UpdatePermissionTemplateWhereInput = {
  id: Scalars['ID'];
};

export type AssignPermissionsInputData = {
  templateId: Scalars['ID'];
  permissions?: Maybe<Array<Maybe<TemplatePermissionCreateCustomInput>>>;
};

export type AssignPermissionsWhereInput = {
  personId: Scalars['ID'];
};

export type AssignPermissionsRes = {
  __typename?: 'assignPermissionsRes';
  message?: Maybe<Scalars['String']>;
};

export type PromoDataInput = {
  promoPlan?: Maybe<Plans>;
  promoPlanExpiration?: Maybe<Scalars['DateTime']>;
};

export type PromoWhereUniqueInput = {
  recurlyId?: Maybe<Scalars['String']>;
};

export type UpdatePersonDetailsInput = {
  username?: Maybe<Scalars['String']>;
  badgeId?: Maybe<Scalars['ID']>;
};

export type ResetPasswordWhereInput = {
  auth0_id: Scalars['ID'];
};

export type ResetPasswordRes = {
  __typename?: 'resetPasswordRes';
  message?: Maybe<Scalars['String']>;
};

export type UpdateUserNameRes = {
  __typename?: 'updateUserNameRes';
  message?: Maybe<Scalars['String']>;
};

export type LikePostDataInput = {
  action?: Maybe<LikePostAction>;
};

export enum LikePostAction {
  Like = 'LIKE',
  Dislike = 'DISLIKE'
}

export type RecurlyCreateAccountInput = {
  account_code: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
};

export type RecurlyAccountShippingAddressInput = {
  nickname?: Maybe<Scalars['String']>;
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  address1: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  state: Scalars['String'];
  zip: Scalars['String'];
  country: Scalars['String'];
};

export type RecurlyRemovePosts = {
  __typename?: 'RecurlyRemovePosts';
  id?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export type RecurlyCreateSubscriptionsInput = {
  plan_code: Scalars['String'];
  currency: Scalars['String'];
  account: RecurlySubscriptionsAccountInput;
  unit_amount_in_cents: Scalars['Int'];
  coupon_code?: Maybe<Scalars['String']>;
  gift_card?: Maybe<RecurlyGiftCardInput>;
  renewal_billing_cycles?: Maybe<Scalars['Int']>;
  auto_renew?: Maybe<Scalars['Boolean']>;
  shipping_address?: Maybe<RecurlyShippingAddressInput>;
};

export type RecurlySubscriptionsAccountInput = {
  account_code: Scalars['String'];
  billing_info?: Maybe<RecurlyBillingInfoInput>;
};

export type RecurlyBillingInfoInput = {
  token_id?: Maybe<Scalars['String']>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type RecurlyGiftCardInput = {
  redemption_code?: Maybe<Scalars['String']>;
};

export type RecurlyShippingAddressInput = {
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  address1: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  state: Scalars['String'];
  zip: Scalars['String'];
  country: Scalars['String'];
};

export type RecurlyCreateGiftCardInput = {
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  product_code: Scalars['String'];
  unit_amount_in_cents: Scalars['Int'];
  currency: Scalars['String'];
  delivery: RecurlyDeliveryInput;
  gifter_account: RecurlySubscriptionsAccountInput;
};

export type RecurlyDeliveryInput = {
  method?: Maybe<Scalars['String']>;
  deliver_at?: Maybe<Scalars['String']>;
  email_address?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  gifter_name?: Maybe<Scalars['String']>;
  personal_message?: Maybe<Scalars['String']>;
};

export type RecurlyRedeemGiftCardOnAccountInput = {
  redemption_code: Scalars['String'];
  recipient_account: RecurlyAccountCodeInput;
};

export type RecurlyUpdateSubscriptionsInput = {
  timeframe?: Maybe<Scalars['String']>;
  plan_code?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  unit_amount_in_cents?: Maybe<Scalars['Int']>;
  auto_renew?: Maybe<Scalars['Boolean']>;
  coupon_code?: Maybe<Scalars['String']>;
};

export type CreateUserBadgeCustomWhereInput = {
  personId: Scalars['ID'];
  badgeSlug: Scalars['String'];
  isProfileImage: Scalars['Boolean'];
};

export type UpsertSettingCustomDataInput = {
  settingId: Scalars['ID'];
  value: Scalars['String'];
};

export type WatchTimeUpsertInput = {
  watchTime: Scalars['Float'];
  entityType: WatchEntityType;
};

export type UpsertWatchTimeCustomWhere = {
  entityId: Scalars['ID'];
  entityFormat: WatchEntityFormat;
};

export type Subscription = {
  __typename?: 'Subscription';
  activityLog?: Maybe<ActivityLogSubscriptionPayload>;
  archiveMessage?: Maybe<ArchiveMessageSubscriptionPayload>;
  badge?: Maybe<BadgeSubscriptionPayload>;
  comment?: Maybe<CommentSubscriptionPayload>;
  configuration?: Maybe<ConfigurationSubscriptionPayload>;
  discussion?: Maybe<DiscussionSubscriptionPayload>;
  emailChangeRequest?: Maybe<EmailChangeRequestSubscriptionPayload>;
  message?: Maybe<MessageSubscriptionPayload>;
  notification?: Maybe<NotificationSubscriptionPayload>;
  notificationUser?: Maybe<NotificationUserSubscriptionPayload>;
  oneSignalPerson?: Maybe<OneSignalPersonSubscriptionPayload>;
  order?: Maybe<OrderSubscriptionPayload>;
  permission?: Maybe<PermissionSubscriptionPayload>;
  person?: Maybe<PersonSubscriptionPayload>;
  personPermission?: Maybe<PersonPermissionSubscriptionPayload>;
  post?: Maybe<PostSubscriptionPayload>;
  postVideo?: Maybe<PostVideoSubscriptionPayload>;
  recurlyError?: Maybe<RecurlyErrorSubscriptionPayload>;
  recurlyWebhook?: Maybe<RecurlyWebhookSubscriptionPayload>;
  setting?: Maybe<SettingSubscriptionPayload>;
  shopifyWebhook?: Maybe<ShopifyWebhookSubscriptionPayload>;
  template?: Maybe<TemplateSubscriptionPayload>;
  templatePermission?: Maybe<TemplatePermissionSubscriptionPayload>;
  userBadge?: Maybe<UserBadgeSubscriptionPayload>;
  userSetting?: Maybe<UserSettingSubscriptionPayload>;
  watchTime?: Maybe<WatchTimeSubscriptionPayload>;
  animationEvent?: Maybe<Scalars['Json']>;
};


export type SubscriptionActivityLogArgs = {
  where?: Maybe<ActivityLogSubscriptionWhereInput>;
};


export type SubscriptionArchiveMessageArgs = {
  where?: Maybe<ArchiveMessageSubscriptionWhereInput>;
};


export type SubscriptionBadgeArgs = {
  where?: Maybe<BadgeSubscriptionWhereInput>;
};


export type SubscriptionCommentArgs = {
  where?: Maybe<CommentSubscriptionWhereInput>;
};


export type SubscriptionConfigurationArgs = {
  where?: Maybe<ConfigurationSubscriptionWhereInput>;
};


export type SubscriptionDiscussionArgs = {
  where?: Maybe<DiscussionSubscriptionWhereInput>;
};


export type SubscriptionEmailChangeRequestArgs = {
  where?: Maybe<EmailChangeRequestSubscriptionWhereInput>;
};


export type SubscriptionMessageArgs = {
  where?: Maybe<MessageSubscriptionWhereInput>;
};


export type SubscriptionNotificationArgs = {
  where?: Maybe<NotificationSubscriptionWhereInput>;
};


export type SubscriptionNotificationUserArgs = {
  where?: Maybe<NotificationUserSubscriptionWhereInput>;
};


export type SubscriptionOneSignalPersonArgs = {
  where?: Maybe<OneSignalPersonSubscriptionWhereInput>;
};


export type SubscriptionOrderArgs = {
  where?: Maybe<OrderSubscriptionWhereInput>;
};


export type SubscriptionPermissionArgs = {
  where?: Maybe<PermissionSubscriptionWhereInput>;
};


export type SubscriptionPersonArgs = {
  where?: Maybe<PersonSubscriptionWhereInput>;
};


export type SubscriptionPersonPermissionArgs = {
  where?: Maybe<PersonPermissionSubscriptionWhereInput>;
};


export type SubscriptionPostArgs = {
  where?: Maybe<PostSubscriptionWhereInput>;
};


export type SubscriptionPostVideoArgs = {
  where?: Maybe<PostVideoSubscriptionWhereInput>;
};


export type SubscriptionRecurlyErrorArgs = {
  where?: Maybe<RecurlyErrorSubscriptionWhereInput>;
};


export type SubscriptionRecurlyWebhookArgs = {
  where?: Maybe<RecurlyWebhookSubscriptionWhereInput>;
};


export type SubscriptionSettingArgs = {
  where?: Maybe<SettingSubscriptionWhereInput>;
};


export type SubscriptionShopifyWebhookArgs = {
  where?: Maybe<ShopifyWebhookSubscriptionWhereInput>;
};


export type SubscriptionTemplateArgs = {
  where?: Maybe<TemplateSubscriptionWhereInput>;
};


export type SubscriptionTemplatePermissionArgs = {
  where?: Maybe<TemplatePermissionSubscriptionWhereInput>;
};


export type SubscriptionUserBadgeArgs = {
  where?: Maybe<UserBadgeSubscriptionWhereInput>;
};


export type SubscriptionUserSettingArgs = {
  where?: Maybe<UserSettingSubscriptionWhereInput>;
};


export type SubscriptionWatchTimeArgs = {
  where?: Maybe<WatchTimeSubscriptionWhereInput>;
};


export type SubscriptionAnimationEventArgs = {
  where: AnimationEventSubscriptionWhereInput;
};

export type ActivityLogSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<ActivityLogWhereInput>;
  AND?: Maybe<Array<ActivityLogSubscriptionWhereInput>>;
  OR?: Maybe<Array<ActivityLogSubscriptionWhereInput>>;
  NOT?: Maybe<Array<ActivityLogSubscriptionWhereInput>>;
};

export enum MutationType {
  Created = 'CREATED',
  Updated = 'UPDATED',
  Deleted = 'DELETED'
}

export type ActivityLogSubscriptionPayload = {
  __typename?: 'ActivityLogSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<ActivityLog>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<ActivityLogPreviousValues>;
};

export type ActivityLogPreviousValues = {
  __typename?: 'ActivityLogPreviousValues';
  id: Scalars['ID'];
  referenceId?: Maybe<Scalars['String']>;
  referenceType?: Maybe<ReferenceType>;
  action?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  clientDetails?: Maybe<Scalars['Json']>;
  beforeObj?: Maybe<Scalars['Json']>;
  afterObj?: Maybe<Scalars['Json']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type ArchiveMessageSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<ArchiveMessageWhereInput>;
  AND?: Maybe<Array<ArchiveMessageSubscriptionWhereInput>>;
  OR?: Maybe<Array<ArchiveMessageSubscriptionWhereInput>>;
  NOT?: Maybe<Array<ArchiveMessageSubscriptionWhereInput>>;
};

export type ArchiveMessageSubscriptionPayload = {
  __typename?: 'ArchiveMessageSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<ArchiveMessage>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<ArchiveMessagePreviousValues>;
};

export type ArchiveMessagePreviousValues = {
  __typename?: 'ArchiveMessagePreviousValues';
  id: Scalars['ID'];
  message: Scalars['String'];
  depth: Scalars['Int'];
  isBlocked?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  userType?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type BadgeSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<BadgeWhereInput>;
  AND?: Maybe<Array<BadgeSubscriptionWhereInput>>;
  OR?: Maybe<Array<BadgeSubscriptionWhereInput>>;
  NOT?: Maybe<Array<BadgeSubscriptionWhereInput>>;
};

export type BadgeSubscriptionPayload = {
  __typename?: 'BadgeSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<Badge>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<BadgePreviousValues>;
};

export type BadgePreviousValues = {
  __typename?: 'BadgePreviousValues';
  id: Scalars['ID'];
  name: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type: BadgeType;
  weight?: Maybe<Scalars['Int']>;
  defaultPlaceHolderImage?: Maybe<Scalars['String']>;
  isCustom?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  canAssignUsingScript?: Maybe<Scalars['Boolean']>;
  shopifySkuList: Array<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type CommentSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<CommentWhereInput>;
  AND?: Maybe<Array<CommentSubscriptionWhereInput>>;
  OR?: Maybe<Array<CommentSubscriptionWhereInput>>;
  NOT?: Maybe<Array<CommentSubscriptionWhereInput>>;
};

export type CommentSubscriptionPayload = {
  __typename?: 'CommentSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<Comment>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<CommentPreviousValues>;
};

export type CommentPreviousValues = {
  __typename?: 'CommentPreviousValues';
  id: Scalars['ID'];
  comment?: Maybe<Scalars['String']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isHighlighted?: Maybe<Scalars['Boolean']>;
  isAccepted?: Maybe<Scalars['Boolean']>;
  likeCount?: Maybe<Scalars['Int']>;
  starCount?: Maybe<Scalars['Int']>;
  flagCount?: Maybe<Scalars['Int']>;
  moderationResponse?: Maybe<Scalars['Json']>;
  moderationScore?: Maybe<Scalars['Float']>;
  moderationLabel?: Maybe<Scalars['String']>;
  moderationError?: Maybe<Scalars['Json']>;
  mediaModerationResponse?: Maybe<Scalars['Json']>;
  mediaModerationError?: Maybe<Scalars['Json']>;
  mediaType?: Maybe<CommentMediaType>;
  mediaDimensions?: Maybe<Scalars['Json']>;
  mediaURL?: Maybe<Scalars['String']>;
  giphyId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type ConfigurationSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<ConfigurationWhereInput>;
  AND?: Maybe<Array<ConfigurationSubscriptionWhereInput>>;
  OR?: Maybe<Array<ConfigurationSubscriptionWhereInput>>;
  NOT?: Maybe<Array<ConfigurationSubscriptionWhereInput>>;
};

export type ConfigurationSubscriptionPayload = {
  __typename?: 'ConfigurationSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<Configuration>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<ConfigurationPreviousValues>;
};

export type ConfigurationPreviousValues = {
  __typename?: 'ConfigurationPreviousValues';
  id: Scalars['Int'];
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  type?: Maybe<ConfigurationType>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type DiscussionSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<DiscussionWhereInput>;
  AND?: Maybe<Array<DiscussionSubscriptionWhereInput>>;
  OR?: Maybe<Array<DiscussionSubscriptionWhereInput>>;
  NOT?: Maybe<Array<DiscussionSubscriptionWhereInput>>;
};

export type DiscussionSubscriptionPayload = {
  __typename?: 'DiscussionSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<Discussion>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<DiscussionPreviousValues>;
};

export type DiscussionPreviousValues = {
  __typename?: 'DiscussionPreviousValues';
  id: Scalars['ID'];
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  isLive?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Discussion_Type>;
  isArchived?: Maybe<Scalars['Boolean']>;
  archiveStatus?: Maybe<Archive_Progress>;
  status?: Maybe<Scalars['String']>;
  scheduleAt?: Maybe<Scalars['DateTime']>;
  startTime?: Maybe<Scalars['DateTime']>;
  endTime?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type EmailChangeRequestSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<EmailChangeRequestWhereInput>;
  AND?: Maybe<Array<EmailChangeRequestSubscriptionWhereInput>>;
  OR?: Maybe<Array<EmailChangeRequestSubscriptionWhereInput>>;
  NOT?: Maybe<Array<EmailChangeRequestSubscriptionWhereInput>>;
};

export type EmailChangeRequestSubscriptionPayload = {
  __typename?: 'EmailChangeRequestSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<EmailChangeRequest>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<EmailChangeRequestPreviousValues>;
};

export type EmailChangeRequestPreviousValues = {
  __typename?: 'EmailChangeRequestPreviousValues';
  id: Scalars['ID'];
  currentEmail?: Maybe<Scalars['String']>;
  newEmail?: Maybe<Scalars['String']>;
  updateEmailInAuth0?: Maybe<Scalars['Boolean']>;
  updateEmailInRecurly?: Maybe<Scalars['Boolean']>;
  updateEmailInDatabase?: Maybe<Scalars['Boolean']>;
  updatedEmailInShopify?: Maybe<Scalars['Boolean']>;
  error?: Maybe<Scalars['Json']>;
  state?: Maybe<Scalars['Json']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type MessageSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<MessageWhereInput>;
  AND?: Maybe<Array<MessageSubscriptionWhereInput>>;
  OR?: Maybe<Array<MessageSubscriptionWhereInput>>;
  NOT?: Maybe<Array<MessageSubscriptionWhereInput>>;
};

export type MessageSubscriptionPayload = {
  __typename?: 'MessageSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<Message>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<MessagePreviousValues>;
};

export type MessagePreviousValues = {
  __typename?: 'MessagePreviousValues';
  id: Scalars['ID'];
  message: Scalars['String'];
  depth: Scalars['Int'];
  isBlocked?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  userType?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type NotificationSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<NotificationWhereInput>;
  AND?: Maybe<Array<NotificationSubscriptionWhereInput>>;
  OR?: Maybe<Array<NotificationSubscriptionWhereInput>>;
  NOT?: Maybe<Array<NotificationSubscriptionWhereInput>>;
};

export type NotificationSubscriptionPayload = {
  __typename?: 'NotificationSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<Notification>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<NotificationPreviousValues>;
};

export type NotificationPreviousValues = {
  __typename?: 'NotificationPreviousValues';
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Notification_Type>;
  referenceType?: Maybe<Notification_Ref_Type>;
  referenceData?: Maybe<Scalars['Json']>;
  referenceId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type NotificationUserSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<NotificationUserWhereInput>;
  AND?: Maybe<Array<NotificationUserSubscriptionWhereInput>>;
  OR?: Maybe<Array<NotificationUserSubscriptionWhereInput>>;
  NOT?: Maybe<Array<NotificationUserSubscriptionWhereInput>>;
};

export type NotificationUserSubscriptionPayload = {
  __typename?: 'NotificationUserSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<NotificationUser>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<NotificationUserPreviousValues>;
};

export type NotificationUserPreviousValues = {
  __typename?: 'NotificationUserPreviousValues';
  id: Scalars['ID'];
  hasRead?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type OneSignalPersonSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<OneSignalPersonWhereInput>;
  AND?: Maybe<Array<OneSignalPersonSubscriptionWhereInput>>;
  OR?: Maybe<Array<OneSignalPersonSubscriptionWhereInput>>;
  NOT?: Maybe<Array<OneSignalPersonSubscriptionWhereInput>>;
};

export type OneSignalPersonSubscriptionPayload = {
  __typename?: 'OneSignalPersonSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<OneSignalPerson>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<OneSignalPersonPreviousValues>;
};

export type OneSignalPersonPreviousValues = {
  __typename?: 'OneSignalPersonPreviousValues';
  id: Scalars['ID'];
  playerID: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type OrderSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<OrderWhereInput>;
  AND?: Maybe<Array<OrderSubscriptionWhereInput>>;
  OR?: Maybe<Array<OrderSubscriptionWhereInput>>;
  NOT?: Maybe<Array<OrderSubscriptionWhereInput>>;
};

export type OrderSubscriptionPayload = {
  __typename?: 'OrderSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<Order>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<OrderPreviousValues>;
};

export type OrderPreviousValues = {
  __typename?: 'OrderPreviousValues';
  id: Scalars['ID'];
  shipHeroId?: Maybe<Scalars['String']>;
  shopifyOrderId?: Maybe<Scalars['String']>;
  recurlyAccountCode?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<Scalars['String']>;
  cancelStatus?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['Json']>;
  metadata?: Maybe<Scalars['Json']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type PermissionSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<PermissionWhereInput>;
  AND?: Maybe<Array<PermissionSubscriptionWhereInput>>;
  OR?: Maybe<Array<PermissionSubscriptionWhereInput>>;
  NOT?: Maybe<Array<PermissionSubscriptionWhereInput>>;
};

export type PermissionSubscriptionPayload = {
  __typename?: 'PermissionSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<Permission>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<PermissionPreviousValues>;
};

export type PermissionPreviousValues = {
  __typename?: 'PermissionPreviousValues';
  id: Scalars['ID'];
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type PersonSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<PersonWhereInput>;
  AND?: Maybe<Array<PersonSubscriptionWhereInput>>;
  OR?: Maybe<Array<PersonSubscriptionWhereInput>>;
  NOT?: Maybe<Array<PersonSubscriptionWhereInput>>;
};

export type PersonSubscriptionPayload = {
  __typename?: 'PersonSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<Person>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<PersonPreviousValues>;
};

export type PersonPreviousValues = {
  __typename?: 'PersonPreviousValues';
  id: Scalars['ID'];
  email: Scalars['String'];
  userName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  profileImage?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  authZeroID?: Maybe<Scalars['String']>;
  recurlyId?: Maybe<Scalars['String']>;
  recurlyPlan?: Maybe<Plans>;
  promoPlan?: Maybe<Plans>;
  promoPlanExpiration?: Maybe<Scalars['DateTime']>;
  role: Role;
  badgeId?: Maybe<Scalars['ID']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isBanned?: Maybe<Scalars['Boolean']>;
  lastActiveOn?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type PersonPermissionSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<PersonPermissionWhereInput>;
  AND?: Maybe<Array<PersonPermissionSubscriptionWhereInput>>;
  OR?: Maybe<Array<PersonPermissionSubscriptionWhereInput>>;
  NOT?: Maybe<Array<PersonPermissionSubscriptionWhereInput>>;
};

export type PersonPermissionSubscriptionPayload = {
  __typename?: 'PersonPermissionSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<PersonPermission>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<PersonPermissionPreviousValues>;
};

export type PersonPermissionPreviousValues = {
  __typename?: 'PersonPermissionPreviousValues';
  id: Scalars['ID'];
  canRead?: Maybe<Scalars['Boolean']>;
  canMutate?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type PostSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<PostWhereInput>;
  AND?: Maybe<Array<PostSubscriptionWhereInput>>;
  OR?: Maybe<Array<PostSubscriptionWhereInput>>;
  NOT?: Maybe<Array<PostSubscriptionWhereInput>>;
};

export type PostSubscriptionPayload = {
  __typename?: 'PostSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<Post>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<PostPreviousValues>;
};

export type PostPreviousValues = {
  __typename?: 'PostPreviousValues';
  id: Scalars['ID'];
  isLive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  referenceType?: Maybe<PostReferenceType>;
  referenceId?: Maybe<Scalars['String']>;
  referenceSlug?: Maybe<Scalars['String']>;
  likesCount?: Maybe<Scalars['Int']>;
  commentsCount?: Maybe<Scalars['Int']>;
  viewsCount?: Maybe<Scalars['Int']>;
  playsCount?: Maybe<Scalars['Int']>;
  text?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  liveStreamEndedAt?: Maybe<Scalars['DateTime']>;
  retentionPeriod?: Maybe<Scalars['Int']>;
  mediaDimensions?: Maybe<Scalars['Json']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type PostVideoSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<PostVideoWhereInput>;
  AND?: Maybe<Array<PostVideoSubscriptionWhereInput>>;
  OR?: Maybe<Array<PostVideoSubscriptionWhereInput>>;
  NOT?: Maybe<Array<PostVideoSubscriptionWhereInput>>;
};

export type PostVideoSubscriptionPayload = {
  __typename?: 'PostVideoSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<PostVideo>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<PostVideoPreviousValues>;
};

export type PostVideoPreviousValues = {
  __typename?: 'PostVideoPreviousValues';
  id: Scalars['ID'];
  muxAssetId?: Maybe<Scalars['String']>;
  streamKey?: Maybe<Scalars['String']>;
  uploadId?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  videoGIF?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  videoState?: Maybe<Scalars['String']>;
  muxPlaybackId?: Maybe<Scalars['String']>;
  playbackPolicy?: Maybe<Scalars['String']>;
  videoURL?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type RecurlyErrorSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<RecurlyErrorWhereInput>;
  AND?: Maybe<Array<RecurlyErrorSubscriptionWhereInput>>;
  OR?: Maybe<Array<RecurlyErrorSubscriptionWhereInput>>;
  NOT?: Maybe<Array<RecurlyErrorSubscriptionWhereInput>>;
};

export type RecurlyErrorSubscriptionPayload = {
  __typename?: 'RecurlyErrorSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<RecurlyError>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<RecurlyErrorPreviousValues>;
};

export type RecurlyErrorPreviousValues = {
  __typename?: 'RecurlyErrorPreviousValues';
  id: Scalars['ID'];
  recurlyId?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type RecurlyWebhookSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<RecurlyWebhookWhereInput>;
  AND?: Maybe<Array<RecurlyWebhookSubscriptionWhereInput>>;
  OR?: Maybe<Array<RecurlyWebhookSubscriptionWhereInput>>;
  NOT?: Maybe<Array<RecurlyWebhookSubscriptionWhereInput>>;
};

export type RecurlyWebhookSubscriptionPayload = {
  __typename?: 'RecurlyWebhookSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<RecurlyWebhook>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<RecurlyWebhookPreviousValues>;
};

export type RecurlyWebhookPreviousValues = {
  __typename?: 'RecurlyWebhookPreviousValues';
  id: Scalars['ID'];
  accountCode?: Maybe<Scalars['String']>;
  webhookAction?: Maybe<Scalars['String']>;
  webhookPayload?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type SettingSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<SettingWhereInput>;
  AND?: Maybe<Array<SettingSubscriptionWhereInput>>;
  OR?: Maybe<Array<SettingSubscriptionWhereInput>>;
  NOT?: Maybe<Array<SettingSubscriptionWhereInput>>;
};

export type SettingSubscriptionPayload = {
  __typename?: 'SettingSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<Setting>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<SettingPreviousValues>;
};

export type SettingPreviousValues = {
  __typename?: 'SettingPreviousValues';
  id: Scalars['ID'];
  key?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<SettingType>;
  group?: Maybe<GroupType>;
  isHidden?: Maybe<Scalars['Boolean']>;
  defaultValue?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type ShopifyWebhookSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<ShopifyWebhookWhereInput>;
  AND?: Maybe<Array<ShopifyWebhookSubscriptionWhereInput>>;
  OR?: Maybe<Array<ShopifyWebhookSubscriptionWhereInput>>;
  NOT?: Maybe<Array<ShopifyWebhookSubscriptionWhereInput>>;
};

export type ShopifyWebhookSubscriptionPayload = {
  __typename?: 'ShopifyWebhookSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<ShopifyWebhook>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<ShopifyWebhookPreviousValues>;
};

export type ShopifyWebhookPreviousValues = {
  __typename?: 'ShopifyWebhookPreviousValues';
  id: Scalars['ID'];
  topic?: Maybe<Scalars['String']>;
  payload?: Maybe<Scalars['Json']>;
  error?: Maybe<Scalars['Json']>;
  orderId?: Maybe<Scalars['String']>;
  result?: Maybe<ResultType>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type TemplateSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<TemplateWhereInput>;
  AND?: Maybe<Array<TemplateSubscriptionWhereInput>>;
  OR?: Maybe<Array<TemplateSubscriptionWhereInput>>;
  NOT?: Maybe<Array<TemplateSubscriptionWhereInput>>;
};

export type TemplateSubscriptionPayload = {
  __typename?: 'TemplateSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<Template>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<TemplatePreviousValues>;
};

export type TemplatePreviousValues = {
  __typename?: 'TemplatePreviousValues';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type TemplatePermissionSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<TemplatePermissionWhereInput>;
  AND?: Maybe<Array<TemplatePermissionSubscriptionWhereInput>>;
  OR?: Maybe<Array<TemplatePermissionSubscriptionWhereInput>>;
  NOT?: Maybe<Array<TemplatePermissionSubscriptionWhereInput>>;
};

export type TemplatePermissionSubscriptionPayload = {
  __typename?: 'TemplatePermissionSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<TemplatePermission>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<TemplatePermissionPreviousValues>;
};

export type TemplatePermissionPreviousValues = {
  __typename?: 'TemplatePermissionPreviousValues';
  id: Scalars['ID'];
  canRead?: Maybe<Scalars['Boolean']>;
  canMutate?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type UserBadgeSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<UserBadgeWhereInput>;
  AND?: Maybe<Array<UserBadgeSubscriptionWhereInput>>;
  OR?: Maybe<Array<UserBadgeSubscriptionWhereInput>>;
  NOT?: Maybe<Array<UserBadgeSubscriptionWhereInput>>;
};

export type UserBadgeSubscriptionPayload = {
  __typename?: 'UserBadgeSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<UserBadge>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<UserBadgePreviousValues>;
};

export type UserBadgePreviousValues = {
  __typename?: 'UserBadgePreviousValues';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  isProfileImage?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type UserSettingSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<UserSettingWhereInput>;
  AND?: Maybe<Array<UserSettingSubscriptionWhereInput>>;
  OR?: Maybe<Array<UserSettingSubscriptionWhereInput>>;
  NOT?: Maybe<Array<UserSettingSubscriptionWhereInput>>;
};

export type UserSettingSubscriptionPayload = {
  __typename?: 'UserSettingSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<UserSetting>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<UserSettingPreviousValues>;
};

export type UserSettingPreviousValues = {
  __typename?: 'UserSettingPreviousValues';
  id: Scalars['ID'];
  value?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type WatchTimeSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<WatchTimeWhereInput>;
  AND?: Maybe<Array<WatchTimeSubscriptionWhereInput>>;
  OR?: Maybe<Array<WatchTimeSubscriptionWhereInput>>;
  NOT?: Maybe<Array<WatchTimeSubscriptionWhereInput>>;
};

export type WatchTimeSubscriptionPayload = {
  __typename?: 'WatchTimeSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<WatchTime>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<WatchTimePreviousValues>;
};

export type WatchTimePreviousValues = {
  __typename?: 'WatchTimePreviousValues';
  id: Scalars['ID'];
  entityId?: Maybe<Scalars['String']>;
  watchTime?: Maybe<Scalars['Float']>;
  entityFormat: WatchEntityFormat;
  entityType: WatchEntityType;
  personEntityId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type AnimationEventSubscriptionWhereInput = {
  id: Scalars['ID'];
};

export type CommentReplyThreadModerationWhereInput = {
  author?: Maybe<PersonWhereUniqueInput>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isFlagged?: Maybe<Scalars['Boolean']>;
};

export type UpdatePostCustom = {
  likes?: Maybe<PersonUpdateManyInput>;
};

export type RecurlyPlanInput = {
  id: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  auto_renew: Scalars['String'];
  revenue_schedule_type: Scalars['String'];
  trial_requires_billing_info: Scalars['String'];
  unit_amount_in_cents: Scalars['Int'];
};

export type UpsertWatchTimeCustomInput = {
  watchTime: Scalars['String'];
  entityFormat: WatchEntityFormat;
  entityType: WatchEntityType;
};

export type GetDiscussionSlugsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
}>;


export type GetDiscussionSlugsQuery = (
  { __typename?: 'Query' }
  & { discussions: Array<Maybe<(
    { __typename?: 'Discussion' }
    & Pick<Discussion, 'id' | 'slug'>
  )>> }
);

export type GetDiscussionsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  status?: Maybe<Array<Scalars['String']>>;
}>;


export type GetDiscussionsQuery = (
  { __typename?: 'Query' }
  & { discussions: Array<Maybe<(
    { __typename?: 'Discussion' }
    & Pick<Discussion, 'id' | 'title' | 'slug' | 'status' | 'startTime' | 'endTime' | 'scheduleAt' | 'isLive'>
    & { owner: (
      { __typename?: 'Person' }
      & Pick<Person, 'firstName' | 'lastName' | 'profileImage'>
    ) }
  )>> }
);

export type GetDiscussionsWithPaginationQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  status?: Maybe<Array<Scalars['String']>>;
}>;


export type GetDiscussionsWithPaginationQuery = (
  { __typename?: 'Query' }
  & { discussionsConnection: (
    { __typename?: 'DiscussionConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'DiscussionEdge' }
      & { node: (
        { __typename?: 'Discussion' }
        & Pick<Discussion, 'id' | 'title' | 'slug' | 'status' | 'startTime' | 'endTime' | 'createdAt' | 'updatedAt' | 'scheduleAt' | 'isLive'>
        & { createdBy: (
          { __typename?: 'Person' }
          & Pick<Person, 'id' | 'firstName' | 'lastName'>
        ), owner: (
          { __typename?: 'Person' }
          & Pick<Person, 'firstName' | 'lastName' | 'profileImage'>
        ) }
      ) }
    )>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'endCursor'>
    ) }
  ) }
);

export type GetDiscussionBySlugQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type GetDiscussionBySlugQuery = (
  { __typename?: 'Query' }
  & { discussion?: Maybe<(
    { __typename?: 'Discussion' }
    & Pick<Discussion, 'id' | 'title' | 'status' | 'description' | 'isLive' | 'createdAt' | 'updatedAt' | 'scheduleAt' | 'startTime' | 'endTime' | 'messagesCount'>
    & { createdBy: (
      { __typename?: 'Person' }
      & Pick<Person, 'id' | 'firstName' | 'lastName'>
    ), owner: (
      { __typename?: 'Person' }
      & Pick<Person, 'firstName' | 'lastName' | 'profileImage'>
    ) }
  )> }
);

export type GetDiscussionThreadQueryVariables = Exact<{
  id: Scalars['ID'];
  after?: Maybe<Scalars['String']>;
}>;


export type GetDiscussionThreadQuery = (
  { __typename?: 'Query' }
  & { discussionThread?: Maybe<(
    { __typename?: 'DiscussionData' }
    & Pick<DiscussionData, 'id' | 'title' | 'description' | 'status' | 'isLive' | 'scheduleAt' | 'startTime' | 'endTime' | 'createdAt' | 'updatedAt' | 'messagesCount'>
    & { createdBy: (
      { __typename?: 'Person' }
      & Pick<Person, 'id' | 'firstName' | 'lastName'>
    ), messages?: Maybe<Array<(
      { __typename?: 'CustomMessage' }
      & Pick<CustomMessage, 'id' | 'message' | 'isBlocked' | 'createdAt' | 'flagsCount' | 'likesCount'>
      & { createdBy?: Maybe<(
        { __typename?: 'Person' }
        & Pick<Person, 'firstName' | 'lastName' | 'role' | 'profileImage' | 'userName'>
      )>, flags?: Maybe<Array<(
        { __typename?: 'Person' }
        & Pick<Person, 'id'>
      )>>, likes?: Maybe<Array<(
        { __typename?: 'Person' }
        & Pick<Person, 'id'>
      )>>, replies?: Maybe<Array<Maybe<(
        { __typename?: 'CustomMessage' }
        & Pick<CustomMessage, 'id' | 'message' | 'flagsCount' | 'likesCount' | 'isBlocked' | 'createdAt'>
        & { createdBy?: Maybe<(
          { __typename?: 'Person' }
          & Pick<Person, 'firstName' | 'lastName' | 'role' | 'profileImage' | 'userName'>
        )>, likes?: Maybe<Array<(
          { __typename?: 'Person' }
          & Pick<Person, 'id'>
        )>>, flags?: Maybe<Array<(
          { __typename?: 'Person' }
          & Pick<Person, 'id'>
        )>> }
      )>>> }
    )>> }
  )> }
);

export type CreateMessageMutationVariables = Exact<{
  discussionId: Scalars['ID'];
  message: Scalars['String'];
  personId: Scalars['ID'];
}>;


export type CreateMessageMutation = (
  { __typename?: 'Mutation' }
  & { createMessage: (
    { __typename?: 'Message' }
    & Pick<Message, 'id'>
  ) }
);

export type CreateReplyMessageMutationVariables = Exact<{
  discussionId: Scalars['ID'];
  message: Scalars['String'];
  personId: Scalars['ID'];
  parentId: Scalars['ID'];
}>;


export type CreateReplyMessageMutation = (
  { __typename?: 'Mutation' }
  & { createMessage: (
    { __typename?: 'Message' }
    & Pick<Message, 'id'>
  ) }
);

export type UpdateMessageLikeMutationVariables = Exact<{
  id: Scalars['ID'];
  likes: PersonUpdateManyInput;
}>;


export type UpdateMessageLikeMutation = (
  { __typename?: 'Mutation' }
  & { updateMessage?: Maybe<(
    { __typename?: 'Message' }
    & Pick<Message, 'id'>
  )> }
);

export type UpdateFlagMessageMutationVariables = Exact<{
  id: Scalars['ID'];
  flags: PersonUpdateManyInput;
}>;


export type UpdateFlagMessageMutation = (
  { __typename?: 'Mutation' }
  & { updateMessage?: Maybe<(
    { __typename?: 'Message' }
    & Pick<Message, 'id'>
  )> }
);

export type RemoveFlagMessageMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RemoveFlagMessageMutation = (
  { __typename?: 'Mutation' }
  & { updateMessage?: Maybe<(
    { __typename?: 'Message' }
    & Pick<Message, 'id'>
  )> }
);

export type CurrentPersonQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentPersonQuery = (
  { __typename?: 'Query' }
  & { currentPerson?: Maybe<(
    { __typename?: 'RecurlyPerson' }
    & Pick<RecurlyPerson, 'id' | 'email' | 'userName' | 'firstName' | 'lastName' | 'authZeroID' | 'recurlyId' | 'recurlyPlan' | 'promoPlan' | 'promoPlanExpiration' | 'effectivePlan' | 'profileImage'>
  )> }
);

export type GetUserBadgesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserBadgesQuery = (
  { __typename?: 'Query' }
  & { getUserBadges?: Maybe<(
    { __typename?: 'UserBadges' }
    & { badges?: Maybe<Array<Maybe<(
      { __typename?: 'BadgeCustom' }
      & Pick<BadgeCustom, 'id' | 'name' | 'slug' | 'image' | 'description' | 'type' | 'weight' | 'isCustom' | 'isActive' | 'isHidden' | 'createdAt' | 'updatedAt' | 'isProfileImage' | 'isDeleted'>
    )>>> }
  )> }
);

export type IsUserNameAvailableQueryVariables = Exact<{
  username: Scalars['String'];
}>;


export type IsUserNameAvailableQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'isUserNameAvailable'>
);

export type UpdatePersonDetailsMutationVariables = Exact<{
  data: UpdatePersonDetailsInput;
}>;


export type UpdatePersonDetailsMutation = (
  { __typename?: 'Mutation' }
  & { updatePersonDetails?: Maybe<(
    { __typename?: 'PersonDetails' }
    & { person?: Maybe<(
      { __typename?: 'RecurlyPerson' }
      & Pick<RecurlyPerson, 'id'>
    )>, badges?: Maybe<Array<Maybe<(
      { __typename?: 'BadgeCustom' }
      & Pick<BadgeCustom, 'id' | 'name' | 'slug' | 'image' | 'description' | 'type' | 'weight' | 'isCustom' | 'isActive' | 'isHidden'>
    )>>> }
  )> }
);

export type VerifyAndAwardBadgeMutationVariables = Exact<{ [key: string]: never; }>;


export type VerifyAndAwardBadgeMutation = (
  { __typename?: 'Mutation' }
  & { verifyAndAwardBadge?: Maybe<(
    { __typename?: 'Badge' }
    & Pick<Badge, 'id' | 'image' | 'slug' | 'description' | 'name' | 'type' | 'createdAt' | 'updatedAt'>
  )> }
);

export type CreateRecurlyGiftCardMutationVariables = Exact<{
  unit_amount_in_cents: Scalars['Int'];
  email_address: Scalars['String'];
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  gifter_name: Scalars['String'];
  personal_message?: Maybe<Scalars['String']>;
  account_code: Scalars['String'];
  token_id: Scalars['String'];
}>;


export type CreateRecurlyGiftCardMutation = (
  { __typename?: 'Mutation' }
  & { createRecurlyGiftCard: Array<Maybe<(
    { __typename?: 'RecurlyGiftCard' }
    & Pick<RecurlyGiftCard, 'id' | 'balance_in_cents' | 'unit_amount_in_cents' | 'delivered_at' | 'redeemed_at'>
    & { invoice?: Maybe<(
      { __typename?: 'RecurlyIdUrlType' }
      & Pick<RecurlyIdUrlType, 'id'>
    )>, delivery?: Maybe<(
      { __typename?: 'RecurlyDeliveryType' }
      & Pick<RecurlyDeliveryType, 'method' | 'deliver_at' | 'email_address' | 'first_name' | 'last_name' | 'gifter_name' | 'personal_message'>
    )> }
  )>> }
);

export type GetRecurlyPlanQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetRecurlyPlanQuery = (
  { __typename?: 'Query' }
  & { getRecurlyPlan?: Maybe<Array<Maybe<(
    { __typename?: 'RecurlyPlan' }
    & Pick<RecurlyPlan, 'id' | 'name' | 'auto_renew' | 'revenue_schedule_type' | 'trial_requires_billing_info' | 'unit_amount_in_cents' | 'created_at' | 'updated_at'>
  )>>> }
);

export type GetRecurlySubscriptionsByAccountQueryVariables = Exact<{
  account_code: Scalars['String'];
}>;


export type GetRecurlySubscriptionsByAccountQuery = (
  { __typename?: 'Query' }
  & { getRecurlySubscriptions?: Maybe<Array<Maybe<(
    { __typename?: 'RecurlySubscription' }
    & Pick<RecurlySubscription, 'id' | 'plan_name' | 'plan_code' | 'state' | 'auto_renew' | 'renewal_billing_cycles' | 'unit_amount_in_cents' | 'currency' | 'activated_at' | 'canceled_at' | 'expires_at' | 'updated_at' | 'current_period_started_at' | 'current_period_ends_at' | 'trial_started_at' | 'trial_ends_at'>
    & { pending_subscription?: Maybe<(
      { __typename?: 'RecurlyPendingSubscription' }
      & Pick<RecurlyPendingSubscription, 'plan_name' | 'plan_code'>
    )> }
  )>>> }
);

export type GetRecurlySubscriptionQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetRecurlySubscriptionQuery = (
  { __typename?: 'Query' }
  & { getRecurlySubscription?: Maybe<Array<Maybe<(
    { __typename?: 'RecurlySubscription' }
    & Pick<RecurlySubscription, 'id' | 'plan_name' | 'plan_code' | 'state' | 'auto_renew' | 'renewal_billing_cycles' | 'unit_amount_in_cents' | 'currency' | 'activated_at' | 'canceled_at' | 'expires_at' | 'updated_at' | 'current_period_started_at' | 'current_period_ends_at' | 'trial_started_at' | 'trial_ends_at'>
    & { pending_subscription?: Maybe<(
      { __typename?: 'RecurlyPendingSubscription' }
      & Pick<RecurlyPendingSubscription, 'plan_name' | 'plan_code'>
    )> }
  )>>> }
);

export type CreateRecurlySubscriptionMutationVariables = Exact<{
  plan_code: Scalars['String'];
  account_code: Scalars['String'];
  token_id: Scalars['String'];
  unit_amount_in_cents: Scalars['Int'];
  coupon_code?: Maybe<Scalars['String']>;
  shipping_address?: Maybe<RecurlyShippingAddressInput>;
}>;


export type CreateRecurlySubscriptionMutation = (
  { __typename?: 'Mutation' }
  & { createRecurlySubscription: Array<Maybe<(
    { __typename?: 'RecurlySubscription' }
    & Pick<RecurlySubscription, 'id' | 'plan_name' | 'plan_code' | 'state' | 'auto_renew' | 'renewal_billing_cycles' | 'unit_amount_in_cents' | 'currency' | 'activated_at' | 'canceled_at' | 'expires_at' | 'updated_at' | 'current_period_started_at' | 'current_period_ends_at' | 'trial_started_at' | 'trial_ends_at'>
    & { invoice?: Maybe<(
      { __typename?: 'RecurlyIdUrlType' }
      & Pick<RecurlyIdUrlType, 'id'>
    )> }
  )>> }
);

export type CreateRecurlySubscriptionFromGiftCardMutationVariables = Exact<{
  plan_code: Scalars['String'];
  account_code: Scalars['String'];
  token_id: Scalars['String'];
  unit_amount_in_cents: Scalars['Int'];
  redemption_code?: Maybe<Scalars['String']>;
  shipping_address?: Maybe<RecurlyShippingAddressInput>;
}>;


export type CreateRecurlySubscriptionFromGiftCardMutation = (
  { __typename?: 'Mutation' }
  & { createRecurlySubscription: Array<Maybe<(
    { __typename?: 'RecurlySubscription' }
    & Pick<RecurlySubscription, 'id' | 'plan_name' | 'plan_code' | 'state' | 'auto_renew' | 'renewal_billing_cycles' | 'unit_amount_in_cents' | 'currency' | 'activated_at' | 'canceled_at' | 'expires_at' | 'updated_at' | 'current_period_started_at' | 'current_period_ends_at' | 'trial_started_at' | 'trial_ends_at'>
    & { invoice?: Maybe<(
      { __typename?: 'RecurlyIdUrlType' }
      & Pick<RecurlyIdUrlType, 'id'>
    )> }
  )>> }
);

export type CreateRecurlySubscriptionFromGiftCardAndNoBillingInfoMutationVariables = Exact<{
  plan_code: Scalars['String'];
  account_code: Scalars['String'];
  unit_amount_in_cents: Scalars['Int'];
  redemption_code?: Maybe<Scalars['String']>;
  shipping_address?: Maybe<RecurlyShippingAddressInput>;
}>;


export type CreateRecurlySubscriptionFromGiftCardAndNoBillingInfoMutation = (
  { __typename?: 'Mutation' }
  & { createRecurlySubscription: Array<Maybe<(
    { __typename?: 'RecurlySubscription' }
    & Pick<RecurlySubscription, 'id' | 'plan_name' | 'plan_code' | 'state' | 'auto_renew' | 'renewal_billing_cycles' | 'unit_amount_in_cents' | 'currency' | 'activated_at' | 'canceled_at' | 'expires_at' | 'updated_at' | 'current_period_started_at' | 'current_period_ends_at' | 'trial_started_at' | 'trial_ends_at'>
    & { invoice?: Maybe<(
      { __typename?: 'RecurlyIdUrlType' }
      & Pick<RecurlyIdUrlType, 'id'>
    )> }
  )>> }
);

export type PreviewSubscriptionMutationVariables = Exact<{
  plan_code: Scalars['String'];
  redemption_code: Scalars['String'];
  unit_amount_in_cents: Scalars['Int'];
  account_code: Scalars['String'];
}>;


export type PreviewSubscriptionMutation = (
  { __typename?: 'Mutation' }
  & { previewRecurlySubscription: Array<Maybe<(
    { __typename?: 'RecurlySubscription' }
    & Pick<RecurlySubscription, 'id'>
  )>> }
);

export type UpdateRecurlySubscriptionMutationVariables = Exact<{
  id?: Maybe<Scalars['String']>;
  timeframe?: Maybe<Scalars['String']>;
  plan_code?: Maybe<Scalars['String']>;
  coupon_code?: Maybe<Scalars['String']>;
}>;


export type UpdateRecurlySubscriptionMutation = (
  { __typename?: 'Mutation' }
  & { updateRecurlySubscription: Array<Maybe<(
    { __typename?: 'RecurlySubscription' }
    & Pick<RecurlySubscription, 'id' | 'plan_name' | 'plan_code' | 'state' | 'auto_renew' | 'renewal_billing_cycles' | 'unit_amount_in_cents' | 'currency' | 'activated_at' | 'canceled_at' | 'expires_at' | 'updated_at' | 'current_period_started_at' | 'current_period_ends_at' | 'trial_started_at' | 'trial_ends_at'>
  )>> }
);

export type CancelRecurlySubscriptionMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type CancelRecurlySubscriptionMutation = (
  { __typename?: 'Mutation' }
  & { cancelRecurlySubscription: Array<Maybe<(
    { __typename?: 'RecurlySubscription' }
    & Pick<RecurlySubscription, 'id' | 'plan_name' | 'plan_code' | 'state' | 'auto_renew' | 'renewal_billing_cycles' | 'unit_amount_in_cents' | 'currency' | 'activated_at' | 'canceled_at' | 'expires_at' | 'updated_at' | 'current_period_started_at' | 'current_period_ends_at' | 'trial_started_at' | 'trial_ends_at'>
  )>> }
);

export type ReactivateRecurlySubscriptionMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type ReactivateRecurlySubscriptionMutation = (
  { __typename?: 'Mutation' }
  & { reactivateRecurlySubscription: Array<Maybe<(
    { __typename?: 'RecurlySubscription' }
    & Pick<RecurlySubscription, 'id' | 'plan_name' | 'plan_code' | 'state' | 'auto_renew' | 'renewal_billing_cycles' | 'unit_amount_in_cents' | 'currency' | 'activated_at' | 'canceled_at' | 'expires_at' | 'updated_at' | 'current_period_started_at' | 'current_period_ends_at' | 'trial_started_at' | 'trial_ends_at'>
  )>> }
);

export type GetRecurlyBillingInfoQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetRecurlyBillingInfoQuery = (
  { __typename?: 'Query' }
  & { getRecurlyBillingInfo?: Maybe<Array<Maybe<(
    { __typename?: 'RecurlyBillingInfo' }
    & Pick<RecurlyBillingInfo, 'id' | 'first_name' | 'last_name' | 'address1' | 'address2' | 'city' | 'state' | 'zip' | 'country' | 'phone' | 'card_type' | 'year' | 'month' | 'first_six' | 'last_four' | 'updated_at'>
  )>>> }
);

export type CreateRecurlyBillingInfoMutationVariables = Exact<{
  id: Scalars['String'];
  tokenId: Scalars['String'];
}>;


export type CreateRecurlyBillingInfoMutation = (
  { __typename?: 'Mutation' }
  & { createRecurlyBillingInfo: Array<Maybe<(
    { __typename?: 'RecurlyBillingInfo' }
    & Pick<RecurlyBillingInfo, 'id' | 'first_name' | 'last_name' | 'address1' | 'address2' | 'city' | 'state' | 'zip' | 'country' | 'phone' | 'card_type' | 'year' | 'month' | 'first_six' | 'last_four' | 'updated_at'>
  )>> }
);

export type UpdateRecurlyBillingInfoMutationVariables = Exact<{
  id: Scalars['String'];
  tokenId: Scalars['String'];
}>;


export type UpdateRecurlyBillingInfoMutation = (
  { __typename?: 'Mutation' }
  & { updateRecurlyBillingInfo: Array<Maybe<(
    { __typename?: 'RecurlyBillingInfo' }
    & Pick<RecurlyBillingInfo, 'id' | 'first_name' | 'last_name' | 'address1' | 'address2' | 'city' | 'state' | 'zip' | 'country' | 'phone' | 'card_type' | 'year' | 'month' | 'first_six' | 'last_four' | 'updated_at'>
  )>> }
);

export type CreateRecurlyShippingAddressMutationVariables = Exact<{
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  address1: Scalars['String'];
  address2: Scalars['String'];
  city: Scalars['String'];
  state: Scalars['String'];
  zip: Scalars['String'];
  country: Scalars['String'];
}>;


export type CreateRecurlyShippingAddressMutation = (
  { __typename?: 'Mutation' }
  & { createRecurlyShippingAddress: Array<Maybe<(
    { __typename?: 'RecurlyShippingAddress' }
    & Pick<RecurlyShippingAddress, 'id'>
  )>> }
);

export type GetRecurlyInvoicesByAccountQueryVariables = Exact<{
  account_code: Scalars['String'];
}>;


export type GetRecurlyInvoicesByAccountQuery = (
  { __typename?: 'Query' }
  & { getRecurlyInvoices?: Maybe<Array<Maybe<(
    { __typename?: 'RecurlyInvoice' }
    & Pick<RecurlyInvoice, 'id' | 'created_at' | 'total_in_cents' | 'subtotal_in_cents' | 'subtotal_before_discount_in_cents' | 'discount_in_cents'>
    & { line_items?: Maybe<Array<Maybe<(
      { __typename?: 'RecurlyLineItems' }
      & Pick<RecurlyLineItems, 'id' | 'quantity' | 'description' | 'total_in_cents'>
    )>>> }
  )>>> }
);

export type GetRecurlyInvoiceByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetRecurlyInvoiceByIdQuery = (
  { __typename?: 'Query' }
  & { getRecurlyInvoice?: Maybe<Array<Maybe<(
    { __typename?: 'RecurlyInvoice' }
    & Pick<RecurlyInvoice, 'id' | 'created_at' | 'total_in_cents'>
    & { line_items?: Maybe<Array<Maybe<(
      { __typename?: 'RecurlyLineItems' }
      & Pick<RecurlyLineItems, 'id' | 'quantity' | 'description' | 'total_in_cents'>
    )>>> }
  )>>> }
);

export type GetShopifyAccessTokenQueryVariables = Exact<{
  returnTo: Scalars['String'];
}>;


export type GetShopifyAccessTokenQuery = (
  { __typename?: 'Query' }
  & { getShopifyAccessToken?: Maybe<(
    { __typename?: 'getShopifyAccessTokenRes' }
    & Pick<GetShopifyAccessTokenRes, 'multipassToken'>
    & { customerAccessToken?: Maybe<(
      { __typename?: 'CustomerAccessToken' }
      & Pick<CustomerAccessToken, 'accessToken' | 'expiresAt'>
    )>, customerUserErrors?: Maybe<(
      { __typename?: 'CustomerUserErrors' }
      & Pick<CustomerUserErrors, 'code' | 'field' | 'message'>
    )> }
  )> }
);

export type GetShopifyMultipassUrlQueryVariables = Exact<{
  returnTo: Scalars['String'];
}>;


export type GetShopifyMultipassUrlQuery = (
  { __typename?: 'Query' }
  & { getShopifyMultipassUrl?: Maybe<(
    { __typename?: 'getShopifyMultipassUrlRes' }
    & Pick<GetShopifyMultipassUrlRes, 'url'>
  )> }
);

export type UpdateEpisodeSegmentWatchTimeMutationVariables = Exact<{
  segmentId: Scalars['ID'];
  time: Scalars['Float'];
}>;


export type UpdateEpisodeSegmentWatchTimeMutation = (
  { __typename?: 'Mutation' }
  & { upsertWatchTimeCustom: (
    { __typename?: 'WatchTime' }
    & Pick<WatchTime, 'id' | 'watchTime' | 'entityId'>
    & { person: (
      { __typename?: 'Person' }
      & Pick<Person, 'id'>
    ) }
  ) }
);

export type UpdateEpisodeSegmentListenTimeMutationVariables = Exact<{
  segmentId: Scalars['ID'];
  time: Scalars['Float'];
}>;


export type UpdateEpisodeSegmentListenTimeMutation = (
  { __typename?: 'Mutation' }
  & { upsertWatchTimeCustom: (
    { __typename?: 'WatchTime' }
    & Pick<WatchTime, 'id' | 'watchTime' | 'entityId'>
    & { person: (
      { __typename?: 'Person' }
      & Pick<Person, 'id'>
    ) }
  ) }
);

export type UpdateVideoWatchTimeMutationVariables = Exact<{
  videoId: Scalars['ID'];
  time: Scalars['Float'];
}>;


export type UpdateVideoWatchTimeMutation = (
  { __typename?: 'Mutation' }
  & { upsertWatchTimeCustom: (
    { __typename?: 'WatchTime' }
    & Pick<WatchTime, 'id' | 'watchTime' | 'entityId'>
    & { person: (
      { __typename?: 'Person' }
      & Pick<Person, 'id'>
    ) }
  ) }
);

export type UpdatePodcastListenTimeMutationVariables = Exact<{
  id: Scalars['ID'];
  time: Scalars['Float'];
}>;


export type UpdatePodcastListenTimeMutation = (
  { __typename?: 'Mutation' }
  & { upsertWatchTimeCustom: (
    { __typename?: 'WatchTime' }
    & Pick<WatchTime, 'id' | 'watchTime' | 'entityId'>
    & { person: (
      { __typename?: 'Person' }
      & Pick<Person, 'id'>
    ) }
  ) }
);


export const GetDiscussionSlugsDocument = gql`
    query getDiscussionSlugs($first: Int) {
  discussions(first: $first, orderBy: scheduleAt_DESC) {
    id
    slug
  }
}
    `;
export const GetDiscussionsDocument = gql`
    query getDiscussions($first: Int, $skip: Int, $status: [String!]) {
  discussions(
    first: $first
    skip: $skip
    orderBy: scheduleAt_DESC
    where: {type: DISCUSSION, status_in: $status}
  ) {
    id
    title
    slug
    status
    startTime
    endTime
    scheduleAt
    isLive
    owner {
      firstName
      lastName
      profileImage
    }
  }
}
    `;
export const GetDiscussionsWithPaginationDocument = gql`
    query getDiscussionsWithPagination($first: Int, $after: String, $status: [String!]) {
  discussionsConnection(
    where: {type: DISCUSSION, status_in: $status}
    first: $first
    after: $after
    orderBy: scheduleAt_DESC
  ) {
    edges {
      node {
        id
        title
        slug
        status
        startTime
        endTime
        createdAt
        createdBy {
          id
          firstName
          lastName
        }
        updatedAt
        scheduleAt
        isLive
        owner {
          firstName
          lastName
          profileImage
        }
      }
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}
    `;
export const GetDiscussionBySlugDocument = gql`
    query getDiscussionBySlug($slug: String!) {
  discussion(where: {slug: $slug}) {
    id
    title
    status
    description
    isLive
    createdAt
    createdBy {
      id
      firstName
      lastName
    }
    updatedAt
    scheduleAt
    startTime
    endTime
    owner {
      firstName
      lastName
      profileImage
    }
    messagesCount
  }
}
    `;
export const GetDiscussionThreadDocument = gql`
    query getDiscussionThread($id: ID!, $after: String) {
  discussionThread(where: {id: $id}) {
    id
    title
    description
    status
    isLive
    scheduleAt
    startTime
    endTime
    createdAt
    createdBy {
      id
      firstName
      lastName
    }
    updatedAt
    messagesCount
    messages(first: 10, after: $after) {
      id
      message
      isBlocked
      createdAt
      createdBy {
        firstName
        lastName
        role
        profileImage
        userName
      }
      flags {
        id
      }
      likes {
        id
      }
      flagsCount
      likesCount
      replies {
        id
        message
        createdBy {
          firstName
          lastName
          role
          profileImage
          userName
        }
        flagsCount
        likesCount
        likes {
          id
        }
        flags {
          id
        }
        isBlocked
        createdAt
      }
    }
  }
}
    `;
export const CreateMessageDocument = gql`
    mutation createMessage($discussionId: ID!, $message: String!, $personId: ID!) {
  createMessage(
    data: {message: $message, createdBy: {connect: {id: $personId}}, discussion: {connect: {id: $discussionId}}}
  ) {
    id
  }
}
    `;
export const CreateReplyMessageDocument = gql`
    mutation createReplyMessage($discussionId: ID!, $message: String!, $personId: ID!, $parentId: ID!) {
  createMessage(
    data: {createdBy: {connect: {id: $personId}}, parent: {connect: {id: $parentId}}, message: $message, discussion: {connect: {id: $discussionId}}}
  ) {
    id
  }
}
    `;
export const UpdateMessageLikeDocument = gql`
    mutation updateMessageLike($id: ID!, $likes: PersonUpdateManyInput!) {
  updateMessage(data: {likes: $likes}, where: {id: $id}) {
    id
  }
}
    `;
export const UpdateFlagMessageDocument = gql`
    mutation updateFlagMessage($id: ID!, $flags: PersonUpdateManyInput!) {
  updateMessage(data: {flags: $flags}, where: {id: $id}) {
    id
  }
}
    `;
export const RemoveFlagMessageDocument = gql`
    mutation removeFlagMessage($id: ID!) {
  updateMessage(data: {flags: {set: []}}, where: {id: $id}) {
    id
  }
}
    `;
export const CurrentPersonDocument = gql`
    query currentPerson {
  currentPerson {
    id
    email
    userName
    firstName
    lastName
    authZeroID
    recurlyId
    recurlyPlan
    promoPlan
    promoPlanExpiration
    promoPlan
    promoPlanExpiration
    effectivePlan
    profileImage
  }
}
    `;
export const GetUserBadgesDocument = gql`
    query getUserBadges {
  getUserBadges(orderBy: createdAt_DESC) {
    badges {
      id
      name
      slug
      image
      description
      type
      weight
      isCustom
      isActive
      isHidden
      createdAt
      updatedAt
      isProfileImage
      isDeleted
    }
  }
}
    `;
export const IsUserNameAvailableDocument = gql`
    query isUserNameAvailable($username: String!) {
  isUserNameAvailable(username: $username)
}
    `;
export const UpdatePersonDetailsDocument = gql`
    mutation updatePersonDetails($data: updatePersonDetailsInput!) {
  updatePersonDetails(data: $data) {
    person {
      id
    }
    badges {
      id
      name
      slug
      image
      description
      type
      weight
      isCustom
      isActive
      isHidden
    }
  }
}
    `;
export const VerifyAndAwardBadgeDocument = gql`
    mutation verifyAndAwardBadge {
  verifyAndAwardBadge {
    id
    image
    slug
    description
    name
    type
    createdAt
    updatedAt
  }
}
    `;
export const CreateRecurlyGiftCardDocument = gql`
    mutation createRecurlyGiftCard($unit_amount_in_cents: Int!, $email_address: String!, $first_name: String!, $last_name: String!, $gifter_name: String!, $personal_message: String, $account_code: String!, $token_id: String!) {
  createRecurlyGiftCard(
    data: {product_code: "gift_card", unit_amount_in_cents: $unit_amount_in_cents, currency: "USD", delivery: {method: "email", email_address: $email_address, first_name: $first_name, last_name: $last_name, gifter_name: $gifter_name, personal_message: $personal_message}, gifter_account: {account_code: $account_code, billing_info: {token_id: $token_id}}}
  ) {
    id
    invoice {
      id
    }
    balance_in_cents
    unit_amount_in_cents
    delivery {
      method
      deliver_at
      email_address
      first_name
      last_name
      gifter_name
      personal_message
    }
    delivered_at
    redeemed_at
  }
}
    `;
export const GetRecurlyPlanDocument = gql`
    query getRecurlyPlan($id: String!) {
  getRecurlyPlan(where: {id: $id}) {
    id
    name
    auto_renew
    revenue_schedule_type
    trial_requires_billing_info
    unit_amount_in_cents
    created_at
    updated_at
  }
}
    `;
export const GetRecurlySubscriptionsByAccountDocument = gql`
    query getRecurlySubscriptionsByAccount($account_code: String!) {
  getRecurlySubscriptions(where: {account_code: $account_code}) {
    id
    plan_name
    plan_code
    state
    auto_renew
    renewal_billing_cycles
    unit_amount_in_cents
    currency
    activated_at
    canceled_at
    expires_at
    updated_at
    current_period_started_at
    current_period_ends_at
    trial_started_at
    trial_ends_at
    pending_subscription {
      plan_name
      plan_code
    }
  }
}
    `;
export const GetRecurlySubscriptionDocument = gql`
    query getRecurlySubscription($id: String!) {
  getRecurlySubscription(where: {id: $id}) {
    id
    plan_name
    plan_code
    state
    auto_renew
    renewal_billing_cycles
    unit_amount_in_cents
    currency
    activated_at
    canceled_at
    expires_at
    updated_at
    current_period_started_at
    current_period_ends_at
    trial_started_at
    trial_ends_at
    pending_subscription {
      plan_name
      plan_code
    }
  }
}
    `;
export const CreateRecurlySubscriptionDocument = gql`
    mutation createRecurlySubscription($plan_code: String!, $account_code: String!, $token_id: String!, $unit_amount_in_cents: Int!, $coupon_code: String, $shipping_address: RecurlyShippingAddressInput) {
  createRecurlySubscription(
    data: {plan_code: $plan_code, currency: "USD", account: {account_code: $account_code, billing_info: {token_id: $token_id}}, unit_amount_in_cents: $unit_amount_in_cents, coupon_code: $coupon_code, shipping_address: $shipping_address}
  ) {
    id
    invoice {
      id
    }
    plan_name
    plan_code
    state
    auto_renew
    renewal_billing_cycles
    unit_amount_in_cents
    currency
    activated_at
    canceled_at
    expires_at
    updated_at
    current_period_started_at
    current_period_ends_at
    trial_started_at
    trial_ends_at
  }
}
    `;
export const CreateRecurlySubscriptionFromGiftCardDocument = gql`
    mutation createRecurlySubscriptionFromGiftCard($plan_code: String!, $account_code: String!, $token_id: String!, $unit_amount_in_cents: Int!, $redemption_code: String, $shipping_address: RecurlyShippingAddressInput) {
  createRecurlySubscription(
    data: {plan_code: $plan_code, currency: "USD", account: {account_code: $account_code, billing_info: {token_id: $token_id}}, unit_amount_in_cents: $unit_amount_in_cents, gift_card: {redemption_code: $redemption_code}, shipping_address: $shipping_address}
  ) {
    id
    invoice {
      id
    }
    plan_name
    plan_code
    state
    auto_renew
    renewal_billing_cycles
    unit_amount_in_cents
    currency
    activated_at
    canceled_at
    expires_at
    updated_at
    current_period_started_at
    current_period_ends_at
    trial_started_at
    trial_ends_at
  }
}
    `;
export const CreateRecurlySubscriptionFromGiftCardAndNoBillingInfoDocument = gql`
    mutation createRecurlySubscriptionFromGiftCardAndNoBillingInfo($plan_code: String!, $account_code: String!, $unit_amount_in_cents: Int!, $redemption_code: String, $shipping_address: RecurlyShippingAddressInput) {
  createRecurlySubscription(
    data: {plan_code: $plan_code, currency: "USD", account: {account_code: $account_code}, unit_amount_in_cents: $unit_amount_in_cents, gift_card: {redemption_code: $redemption_code}, auto_renew: false, shipping_address: $shipping_address}
  ) {
    id
    invoice {
      id
    }
    plan_name
    plan_code
    state
    auto_renew
    renewal_billing_cycles
    unit_amount_in_cents
    currency
    activated_at
    canceled_at
    expires_at
    updated_at
    current_period_started_at
    current_period_ends_at
    trial_started_at
    trial_ends_at
  }
}
    `;
export const PreviewSubscriptionDocument = gql`
    mutation previewSubscription($plan_code: String!, $redemption_code: String!, $unit_amount_in_cents: Int!, $account_code: String!) {
  previewRecurlySubscription(
    data: {plan_code: $plan_code, gift_card: {redemption_code: $redemption_code}, currency: "USD", unit_amount_in_cents: $unit_amount_in_cents, account: {account_code: $account_code}}
  ) {
    id
  }
}
    `;
export const UpdateRecurlySubscriptionDocument = gql`
    mutation updateRecurlySubscription($id: String, $timeframe: String, $plan_code: String, $coupon_code: String) {
  updateRecurlySubscription(
    where: {id: $id}
    data: {timeframe: $timeframe, plan_code: $plan_code, coupon_code: $coupon_code}
  ) {
    id
    plan_name
    plan_code
    state
    auto_renew
    renewal_billing_cycles
    unit_amount_in_cents
    currency
    activated_at
    canceled_at
    expires_at
    updated_at
    current_period_started_at
    current_period_ends_at
    trial_started_at
    trial_ends_at
  }
}
    `;
export const CancelRecurlySubscriptionDocument = gql`
    mutation cancelRecurlySubscription($id: String!) {
  cancelRecurlySubscription(where: {id: $id}) {
    id
    plan_name
    plan_code
    state
    auto_renew
    renewal_billing_cycles
    unit_amount_in_cents
    currency
    activated_at
    canceled_at
    expires_at
    updated_at
    current_period_started_at
    current_period_ends_at
    trial_started_at
    trial_ends_at
  }
}
    `;
export const ReactivateRecurlySubscriptionDocument = gql`
    mutation reactivateRecurlySubscription($id: String!) {
  reactivateRecurlySubscription(where: {id: $id}) {
    id
    plan_name
    plan_code
    state
    auto_renew
    renewal_billing_cycles
    unit_amount_in_cents
    currency
    activated_at
    canceled_at
    expires_at
    updated_at
    current_period_started_at
    current_period_ends_at
    trial_started_at
    trial_ends_at
  }
}
    `;
export const GetRecurlyBillingInfoDocument = gql`
    query getRecurlyBillingInfo($id: String!) {
  getRecurlyBillingInfo(where: {id: $id}) {
    id
    first_name
    last_name
    address1
    address2
    city
    state
    zip
    country
    phone
    card_type
    year
    month
    first_six
    last_four
    updated_at
  }
}
    `;
export const CreateRecurlyBillingInfoDocument = gql`
    mutation createRecurlyBillingInfo($id: String!, $tokenId: String!) {
  createRecurlyBillingInfo(data: {token_id: $tokenId}, where: {id: $id}) {
    id
    first_name
    last_name
    address1
    address2
    city
    state
    zip
    country
    phone
    card_type
    year
    month
    first_six
    last_four
    updated_at
  }
}
    `;
export const UpdateRecurlyBillingInfoDocument = gql`
    mutation updateRecurlyBillingInfo($id: String!, $tokenId: String!) {
  updateRecurlyBillingInfo(data: {token_id: $tokenId}, where: {id: $id}) {
    id
    first_name
    last_name
    address1
    address2
    city
    state
    zip
    country
    phone
    card_type
    year
    month
    first_six
    last_four
    updated_at
  }
}
    `;
export const CreateRecurlyShippingAddressDocument = gql`
    mutation createRecurlyShippingAddress($first_name: String!, $last_name: String!, $address1: String!, $address2: String!, $city: String!, $state: String!, $zip: String!, $country: String!) {
  createRecurlyShippingAddress(
    data: {first_name: $first_name, last_name: $last_name, address1: $address1, address2: $address2, city: $city, state: $state, zip: $zip, country: $country}
  ) {
    id
  }
}
    `;
export const GetRecurlyInvoicesByAccountDocument = gql`
    query getRecurlyInvoicesByAccount($account_code: String!) {
  getRecurlyInvoices(
    where: {account_code: $account_code, limit: 10, state: "paid"}
  ) {
    id
    created_at
    total_in_cents
    subtotal_in_cents
    subtotal_before_discount_in_cents
    discount_in_cents
    line_items {
      id
      quantity
      description
      total_in_cents
    }
  }
}
    `;
export const GetRecurlyInvoiceByIdDocument = gql`
    query getRecurlyInvoiceById($id: String!) {
  getRecurlyInvoice(where: {id: $id}) {
    id
    created_at
    total_in_cents
    line_items {
      id
      quantity
      description
      total_in_cents
    }
  }
}
    `;
export const GetShopifyAccessTokenDocument = gql`
    query getShopifyAccessToken($returnTo: String!) {
  getShopifyAccessToken(returnTo: $returnTo) {
    multipassToken
    customerAccessToken {
      accessToken
      expiresAt
    }
    customerUserErrors {
      code
      field
      message
    }
  }
}
    `;
export const GetShopifyMultipassUrlDocument = gql`
    query getShopifyMultipassUrl($returnTo: String!) {
  getShopifyMultipassUrl(returnTo: $returnTo) {
    url
  }
}
    `;
export const UpdateEpisodeSegmentWatchTimeDocument = gql`
    mutation updateEpisodeSegmentWatchTime($segmentId: ID!, $time: Float!) {
  upsertWatchTimeCustom(
    data: {watchTime: $time, entityType: EPISODE_SEGMENT}
    where: {entityId: $segmentId, entityFormat: VIDEO}
  ) {
    id
    watchTime
    entityId
    person {
      id
    }
  }
}
    `;
export const UpdateEpisodeSegmentListenTimeDocument = gql`
    mutation updateEpisodeSegmentListenTime($segmentId: ID!, $time: Float!) {
  upsertWatchTimeCustom(
    data: {watchTime: $time, entityType: EPISODE_SEGMENT}
    where: {entityId: $segmentId, entityFormat: AUDIO}
  ) {
    id
    watchTime
    entityId
    person {
      id
    }
  }
}
    `;
export const UpdateVideoWatchTimeDocument = gql`
    mutation updateVideoWatchTime($videoId: ID!, $time: Float!) {
  upsertWatchTimeCustom(
    data: {watchTime: $time, entityType: VIDEO}
    where: {entityId: $videoId, entityFormat: VIDEO}
  ) {
    id
    watchTime
    entityId
    person {
      id
    }
  }
}
    `;
export const UpdatePodcastListenTimeDocument = gql`
    mutation updatePodcastListenTime($id: ID!, $time: Float!) {
  upsertWatchTimeCustom(
    data: {watchTime: $time, entityType: PODCAST_EPISODE}
    where: {entityId: $id, entityFormat: AUDIO}
  ) {
    id
    watchTime
    entityId
    person {
      id
    }
  }
}
    `;

export type SdkFunctionWrapper = <T>(action: () => Promise<T>) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = sdkFunction => sdkFunction();
export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    getDiscussionSlugs(variables?: GetDiscussionSlugsQueryVariables): Promise<GetDiscussionSlugsQuery> {
      return withWrapper(() => client.request<GetDiscussionSlugsQuery>(print(GetDiscussionSlugsDocument), variables));
    },
    getDiscussions(variables?: GetDiscussionsQueryVariables): Promise<GetDiscussionsQuery> {
      return withWrapper(() => client.request<GetDiscussionsQuery>(print(GetDiscussionsDocument), variables));
    },
    getDiscussionsWithPagination(variables?: GetDiscussionsWithPaginationQueryVariables): Promise<GetDiscussionsWithPaginationQuery> {
      return withWrapper(() => client.request<GetDiscussionsWithPaginationQuery>(print(GetDiscussionsWithPaginationDocument), variables));
    },
    getDiscussionBySlug(variables: GetDiscussionBySlugQueryVariables): Promise<GetDiscussionBySlugQuery> {
      return withWrapper(() => client.request<GetDiscussionBySlugQuery>(print(GetDiscussionBySlugDocument), variables));
    },
    getDiscussionThread(variables: GetDiscussionThreadQueryVariables): Promise<GetDiscussionThreadQuery> {
      return withWrapper(() => client.request<GetDiscussionThreadQuery>(print(GetDiscussionThreadDocument), variables));
    },
    createMessage(variables: CreateMessageMutationVariables): Promise<CreateMessageMutation> {
      return withWrapper(() => client.request<CreateMessageMutation>(print(CreateMessageDocument), variables));
    },
    createReplyMessage(variables: CreateReplyMessageMutationVariables): Promise<CreateReplyMessageMutation> {
      return withWrapper(() => client.request<CreateReplyMessageMutation>(print(CreateReplyMessageDocument), variables));
    },
    updateMessageLike(variables: UpdateMessageLikeMutationVariables): Promise<UpdateMessageLikeMutation> {
      return withWrapper(() => client.request<UpdateMessageLikeMutation>(print(UpdateMessageLikeDocument), variables));
    },
    updateFlagMessage(variables: UpdateFlagMessageMutationVariables): Promise<UpdateFlagMessageMutation> {
      return withWrapper(() => client.request<UpdateFlagMessageMutation>(print(UpdateFlagMessageDocument), variables));
    },
    removeFlagMessage(variables: RemoveFlagMessageMutationVariables): Promise<RemoveFlagMessageMutation> {
      return withWrapper(() => client.request<RemoveFlagMessageMutation>(print(RemoveFlagMessageDocument), variables));
    },
    currentPerson(variables?: CurrentPersonQueryVariables): Promise<CurrentPersonQuery> {
      return withWrapper(() => client.request<CurrentPersonQuery>(print(CurrentPersonDocument), variables));
    },
    getUserBadges(variables?: GetUserBadgesQueryVariables): Promise<GetUserBadgesQuery> {
      return withWrapper(() => client.request<GetUserBadgesQuery>(print(GetUserBadgesDocument), variables));
    },
    isUserNameAvailable(variables: IsUserNameAvailableQueryVariables): Promise<IsUserNameAvailableQuery> {
      return withWrapper(() => client.request<IsUserNameAvailableQuery>(print(IsUserNameAvailableDocument), variables));
    },
    updatePersonDetails(variables: UpdatePersonDetailsMutationVariables): Promise<UpdatePersonDetailsMutation> {
      return withWrapper(() => client.request<UpdatePersonDetailsMutation>(print(UpdatePersonDetailsDocument), variables));
    },
    verifyAndAwardBadge(variables?: VerifyAndAwardBadgeMutationVariables): Promise<VerifyAndAwardBadgeMutation> {
      return withWrapper(() => client.request<VerifyAndAwardBadgeMutation>(print(VerifyAndAwardBadgeDocument), variables));
    },
    createRecurlyGiftCard(variables: CreateRecurlyGiftCardMutationVariables): Promise<CreateRecurlyGiftCardMutation> {
      return withWrapper(() => client.request<CreateRecurlyGiftCardMutation>(print(CreateRecurlyGiftCardDocument), variables));
    },
    getRecurlyPlan(variables: GetRecurlyPlanQueryVariables): Promise<GetRecurlyPlanQuery> {
      return withWrapper(() => client.request<GetRecurlyPlanQuery>(print(GetRecurlyPlanDocument), variables));
    },
    getRecurlySubscriptionsByAccount(variables: GetRecurlySubscriptionsByAccountQueryVariables): Promise<GetRecurlySubscriptionsByAccountQuery> {
      return withWrapper(() => client.request<GetRecurlySubscriptionsByAccountQuery>(print(GetRecurlySubscriptionsByAccountDocument), variables));
    },
    getRecurlySubscription(variables: GetRecurlySubscriptionQueryVariables): Promise<GetRecurlySubscriptionQuery> {
      return withWrapper(() => client.request<GetRecurlySubscriptionQuery>(print(GetRecurlySubscriptionDocument), variables));
    },
    createRecurlySubscription(variables: CreateRecurlySubscriptionMutationVariables): Promise<CreateRecurlySubscriptionMutation> {
      return withWrapper(() => client.request<CreateRecurlySubscriptionMutation>(print(CreateRecurlySubscriptionDocument), variables));
    },
    createRecurlySubscriptionFromGiftCard(variables: CreateRecurlySubscriptionFromGiftCardMutationVariables): Promise<CreateRecurlySubscriptionFromGiftCardMutation> {
      return withWrapper(() => client.request<CreateRecurlySubscriptionFromGiftCardMutation>(print(CreateRecurlySubscriptionFromGiftCardDocument), variables));
    },
    createRecurlySubscriptionFromGiftCardAndNoBillingInfo(variables: CreateRecurlySubscriptionFromGiftCardAndNoBillingInfoMutationVariables): Promise<CreateRecurlySubscriptionFromGiftCardAndNoBillingInfoMutation> {
      return withWrapper(() => client.request<CreateRecurlySubscriptionFromGiftCardAndNoBillingInfoMutation>(print(CreateRecurlySubscriptionFromGiftCardAndNoBillingInfoDocument), variables));
    },
    previewSubscription(variables: PreviewSubscriptionMutationVariables): Promise<PreviewSubscriptionMutation> {
      return withWrapper(() => client.request<PreviewSubscriptionMutation>(print(PreviewSubscriptionDocument), variables));
    },
    updateRecurlySubscription(variables?: UpdateRecurlySubscriptionMutationVariables): Promise<UpdateRecurlySubscriptionMutation> {
      return withWrapper(() => client.request<UpdateRecurlySubscriptionMutation>(print(UpdateRecurlySubscriptionDocument), variables));
    },
    cancelRecurlySubscription(variables: CancelRecurlySubscriptionMutationVariables): Promise<CancelRecurlySubscriptionMutation> {
      return withWrapper(() => client.request<CancelRecurlySubscriptionMutation>(print(CancelRecurlySubscriptionDocument), variables));
    },
    reactivateRecurlySubscription(variables: ReactivateRecurlySubscriptionMutationVariables): Promise<ReactivateRecurlySubscriptionMutation> {
      return withWrapper(() => client.request<ReactivateRecurlySubscriptionMutation>(print(ReactivateRecurlySubscriptionDocument), variables));
    },
    getRecurlyBillingInfo(variables: GetRecurlyBillingInfoQueryVariables): Promise<GetRecurlyBillingInfoQuery> {
      return withWrapper(() => client.request<GetRecurlyBillingInfoQuery>(print(GetRecurlyBillingInfoDocument), variables));
    },
    createRecurlyBillingInfo(variables: CreateRecurlyBillingInfoMutationVariables): Promise<CreateRecurlyBillingInfoMutation> {
      return withWrapper(() => client.request<CreateRecurlyBillingInfoMutation>(print(CreateRecurlyBillingInfoDocument), variables));
    },
    updateRecurlyBillingInfo(variables: UpdateRecurlyBillingInfoMutationVariables): Promise<UpdateRecurlyBillingInfoMutation> {
      return withWrapper(() => client.request<UpdateRecurlyBillingInfoMutation>(print(UpdateRecurlyBillingInfoDocument), variables));
    },
    createRecurlyShippingAddress(variables: CreateRecurlyShippingAddressMutationVariables): Promise<CreateRecurlyShippingAddressMutation> {
      return withWrapper(() => client.request<CreateRecurlyShippingAddressMutation>(print(CreateRecurlyShippingAddressDocument), variables));
    },
    getRecurlyInvoicesByAccount(variables: GetRecurlyInvoicesByAccountQueryVariables): Promise<GetRecurlyInvoicesByAccountQuery> {
      return withWrapper(() => client.request<GetRecurlyInvoicesByAccountQuery>(print(GetRecurlyInvoicesByAccountDocument), variables));
    },
    getRecurlyInvoiceById(variables: GetRecurlyInvoiceByIdQueryVariables): Promise<GetRecurlyInvoiceByIdQuery> {
      return withWrapper(() => client.request<GetRecurlyInvoiceByIdQuery>(print(GetRecurlyInvoiceByIdDocument), variables));
    },
    getShopifyAccessToken(variables: GetShopifyAccessTokenQueryVariables): Promise<GetShopifyAccessTokenQuery> {
      return withWrapper(() => client.request<GetShopifyAccessTokenQuery>(print(GetShopifyAccessTokenDocument), variables));
    },
    getShopifyMultipassUrl(variables: GetShopifyMultipassUrlQueryVariables): Promise<GetShopifyMultipassUrlQuery> {
      return withWrapper(() => client.request<GetShopifyMultipassUrlQuery>(print(GetShopifyMultipassUrlDocument), variables));
    },
    updateEpisodeSegmentWatchTime(variables: UpdateEpisodeSegmentWatchTimeMutationVariables): Promise<UpdateEpisodeSegmentWatchTimeMutation> {
      return withWrapper(() => client.request<UpdateEpisodeSegmentWatchTimeMutation>(print(UpdateEpisodeSegmentWatchTimeDocument), variables));
    },
    updateEpisodeSegmentListenTime(variables: UpdateEpisodeSegmentListenTimeMutationVariables): Promise<UpdateEpisodeSegmentListenTimeMutation> {
      return withWrapper(() => client.request<UpdateEpisodeSegmentListenTimeMutation>(print(UpdateEpisodeSegmentListenTimeDocument), variables));
    },
    updateVideoWatchTime(variables: UpdateVideoWatchTimeMutationVariables): Promise<UpdateVideoWatchTimeMutation> {
      return withWrapper(() => client.request<UpdateVideoWatchTimeMutation>(print(UpdateVideoWatchTimeDocument), variables));
    },
    updatePodcastListenTime(variables: UpdatePodcastListenTimeMutationVariables): Promise<UpdatePodcastListenTimeMutation> {
      return withWrapper(() => client.request<UpdatePodcastListenTimeMutation>(print(UpdatePodcastListenTimeDocument), variables));
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;
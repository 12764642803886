import React from 'react';

import style from './index.module.css';

type ButtonProps = {
  loading: boolean;
  cta: string;
};

export const SubmitButton: React.FC<ButtonProps> = ({ loading, cta, children }) => {
  return (
    <button className={loading ? `${style.submitButton} ${style.loading}` : style.submitButton}>
      {cta}
      {children}
      <div className={style.loaderContainer}>
        <div className={style.loader} />
      </div>
    </button>
  );
};

/* eslint-disable import/no-unresolved */
/* eslint-disable import/order */
import * as React from 'react';

import { css } from '@emotion/core';
import styled from '@emotion/styled';

import { label, input, textarea, select, button, breakpoints, color } from '@/utils/styles';
import { selectBlack } from '@/utils/styles/form';

export const RecurlyInput = styled('div')`
  .recurly-hosted-field {
    display: flex;
    align-items: center;
    margin: 0;
    height: 64px;
    border: 0;
    border-radius: 8px;
    background-color: #f5f5f5;
    color: #fff;
  }

  .recurly-element {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    border: 0;
    background-color: #f5f5f5;
  }
  .recurly-element-number {
    font-family: 'Libre Franklin';
    margin: 0;
    padding: 0;
    width: 100%;
    height: 64px;
    border: 0;
    background-color: #f5f5f5;
    border-radius: 8px;
    padding: 22px;
    box-sizing: border-box;
  }
  .recurly-element-month {
    font-family: 'Libre Franklin';
    margin: 0;
    padding: 0;
    width: 100%;
    max-width: 60px;
    height: 64px;
    border: 0;
    background-color: #f5f5f5;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    padding: 22px 0 22px 22px;
    box-sizing: border-box;
  }
  .recurly-element-year {
    font-family: 'Libre Franklin';
    margin: 0;
    padding: 0;
    width: 100%;
    height: 64px;
    border: 0;
    background-color: #f5f5f5;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    padding: 22px 22px 22px 0;
    box-sizing: border-box;
  }
  .recurly-element-cvv {
    font-family: 'Libre Franklin';
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 27px */
    letter-spacing: -0.36px;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 64px;
    border: 0;
    background-color: #f5f5f5;
    border-radius: 8px;
    padding: 22px;
    box-sizing: border-box;
  }
`;
export const RecurlyInputOld = styled('div')`
  .recurly-hosted-field {
    display: flex;
    align-items: center;
    margin: 0;
    height: 56px;
    border: 0;
    border-radius: 6px;
    background-color: ${color.gray80};
    color: #fff;
  }

  .recurly-element {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    border: 0;
    background-color: ${color.gray80};
  }
`;

export const Fieldset = styled('fieldset')`
  border: none;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 0 0 8px;
  padding: 0;
  font-family: 'Libre Franklin';
`;

export const FormItems = styled('ol')`
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0;
  padding: 0;
`;

type FormItemProps = {
  col?: number;
};

export const FormItem = styled('li')<FormItemProps>`
  width: 100%;
  margin-bottom: 24px;

  ${({ col }) =>
    col &&
    css`
      @media (min-width: ${breakpoints.phablet}px) {
        width: ${100 / col - 2}%;
      }
    `};
`;

type LabelProps = {
  lightMode?: boolean;
  as?: React.ElementType;
};

export const Label = styled('label')<LabelProps>`
  ${(props) => (props.lightMode ? label.default.light : label.default.dark)};
`;

export const InlineLabel = styled('label')<LabelProps>`
  ${(props) => (props.lightMode ? label.inline.light : label.inline.dark)};
`;

export const Input = styled('input')`
  ${input.default};
`;
export const Textarea = styled('textarea')`
  ${textarea.default};
`;
export const Select = styled('select')`
  ${select.default};
`;
export const SelectBlack = styled('select')`
  ${selectBlack.default};
`;

export const Radio = styled('div')`
  :not(:last-of-type) {
    margin-bottom: 24px;
  }

  label {
    padding: 0 0 0 40px;
    display: block;
  }

  input {
    position: absolute;
    opacity: 0;
  }

  input + label {
    position: relative;
    cursor: pointer;
  }

  /* Box. */
  input + label:before {
    content: '';
    margin-right: 16px;
    display: inline-block;
    width: 24px;
    height: 24px;
    border-radius: 24px;
    background-color: ${color.gray80};
    position: absolute;
    left: 0;
    top: 1px;
    flex-shrink: 0;
    align-self: flex-start;
  }

  /* Box checked */
  input:checked + label:before {
    background: #fff;
  }
  /* Checkmark */
  input:checked + label:after {
    content: '';
    position: absolute;
    left: 7px;
    top: 13px;
    background: ${color.gray95};
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 ${color.gray95}, 4px 0 0 ${color.gray95}, 4px -2px 0 ${color.gray95}, 4px -4px 0 ${color.gray95},
      4px -6px 0 ${color.gray95}, 4px -8px 0 ${color.gray95};
    transform: rotate(45deg);
  }
`;

type BaseSubmitProps = {
  buttonText: string;
  isSubmitting: boolean;
  status?: any;
  disabled?: boolean;
  css?: any;
  width?: string;
};

export const Submit: React.FC<BaseSubmitProps> = ({ buttonText, isSubmitting, status, css, width, ...rest }) => {
  return (
    <button
      css={[button.primary.red.large, css]}
      type='submit'
      {...rest}
      disabled={isSubmitting || (status && status.success)}
      style={{ overflow: 'hidden', width: width === 'auto' ? 'auto' : '100%' }}
    >
      <div style={{ minHeight: `1em` }}>
        {isSubmitting && 'Processing...'}
        {!isSubmitting && !status && buttonText}
        <div
          style={{
            position: 'relative',
            top: !isSubmitting && status && status.success ? '0' : '4em',
            transition: `max-height 200ms ease 0s, top 200ms ease 200ms`,
            maxHeight: !isSubmitting && status && status.success ? '2em' : '1px',
          }}
        >
          {status && status.msg && <>{status.msg}</>}
        </div>
      </div>
    </button>
  );
};

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/order */
import * as React from 'react';

import styled from '@emotion/styled';
import { useRouter } from 'next/router';
import { useMedia } from 'react-use';

import Link from '@/components/shared/Link';
import { breakpoints, visuallyHidden } from '@/utils/styles';

import { Container, ExternalLink, Icon } from '../design-system';

const currentYear = new Date().getFullYear();

const footerLinkStyles = {
  a: {
    display: 'block',
    marginBottom: 16,
    color: '#fff',
    fontSize: 15,
  },
};

const FollowLink = styled(ExternalLink)<any>(() => ({
  border: '1px solid',
  color: 'rgba(255, 255, 255, 0.88)',
  borderRadius: '100%',
  background: ' rgba(11, 11, 17, 0.11)',
  borderColor: 'rgba(255, 255, 255, 0.28)',
  height: 40,
  width: 40,
  margin: '0 8px',
  overflow: 'hidden',
  textAlign: 'center',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const Footer = React.memo(() => {
  const router = useRouter();
  const isDesktopViewport = useMedia(`(min-width: ${breakpoints.desktop}px)`);
  const isMobileNewsPage = !isDesktopViewport && router.pathname === '/news/[slug]';

  return (
    <div
      css={(theme) => ({
        backgroundColor: 'rgba(39, 39, 39, 1)',
        borderTop: '0.5px solid rgba(102, 102, 102, 1)',
        color: theme.colors.white,
        paddingBottom: isMobileNewsPage ? '5rem' : 0,
        textAlign: 'center',
        position: 'relative',
      })}
    >
      {router.asPath != '/watch' && (
        <Container
          css={(theme) => ({
            paddingTop: '32px',
            paddingBottom: '32px',

            [theme.mq.tablet]: {
              display: 'flex',
              textAlign: 'left',
              justifyContent: 'space-between',
            },
          })}
          data-label='global-footer-nav'
        >
          <div className='version2-footer-links' css={footerLinkStyles}>
            <Link href='/about'>About</Link>
            <Link href='/authors'>Authors</Link>
            <a href='mailto:advertisewithus@dwventures.com'>Advertise With Us</a>
            <a href='https://get.dailywire.com/speakers/'>Book our Speakers</a>
            {/* <!-- OptanonCookieSettingsButtonStart --> */}
            <button
              className='ot-sdk-show-settings'
              id='ot-sdk-btn'
              style={{
                backgroundColor: 'transparent',
                border: 'none',
                color: '#fff',
                fontFamily: 'Libre Franklin',
                fontSize: '15px',
                margin: '0 0 16px 0',
                padding: 0,
              }}
            >
              Do Not Sell or Share My Personal Information
            </button>
            {/* <!-- OptanonCookieSettingsButtonEnd --> */}
            <Link href='/legal'>Legal</Link>
          </div>
          <div css={footerLinkStyles}>
            <ExternalLink href='https://dailywire.zendesk.com/hc/en-us'>Support</ExternalLink>
            <Link href='/standards-policies'>Standards &amp; Policies</Link>
            <Link href='/shipping-returns-policy'>Shipping &amp; Returns</Link>
            <ExternalLink href='https://dailywire.multiscreensite.com/'>Careers</ExternalLink>
            <ExternalLink href='https://tips.dailywire.com/hc/en-us/requests/new'>Submit a Tip</ExternalLink>
            <ExternalLink href='mailto:press@dailywire.com'>Media &amp; Press Inquiries</ExternalLink>
          </div>
          <div
            css={(theme) => ({
              order: -1,
              marginTop: '32px',
              [theme.mq.mobile]: {
                order: 5,
                margin: 0,
              },
            })}
          >
            <FollowLink href='https://www.facebook.com/DailyWire'>
              <span css={visuallyHidden}>Facebook</span>
              <Icon name='FACEBOOK' size={21} style={{ position: 'relative', top: '1px' }} />
            </FollowLink>
            <FollowLink href='https://twitter.com/realDailyWire' style={{ position: 'relative', top: '-3.5px' }}>
              <span css={visuallyHidden}>X</span>
              <svg fill='none' height='14' viewBox='0 0 15 14' width='15' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M11.8131 0H14.1131L9.08813 5.74375L15 13.5581H10.3712L6.74625 8.81813L2.5975 13.5581H0.29625L5.67125 7.41437L0 0.000625029H4.74625L8.02312 4.33313L11.8131 0ZM11.0063 12.1819H12.2806L4.05375 1.30437H2.68625L11.0063 12.1819Z'
                  fill='white'
                  fillOpacity='0.88'
                />
              </svg>
            </FollowLink>
            <FollowLink href='https://www.instagram.com/realdailywire/'>
              <span css={visuallyHidden}>Instagram</span>
              <Icon
                name='INSTAGRAM'
                size={21}
                style={{
                  position: 'relative',
                  left: '0.25px',
                  top: '0.5px',
                  width: '20px',
                }}
              />
            </FollowLink>
            <FollowLink href='https://www.youtube.com/channel/UCaeO5vkdj5xOQHp4UmIN6dw'>
              <span css={visuallyHidden}>YouTube</span>
              <Icon name='YOUTUBE' size={21} style={{ position: 'relative', left: '0.75px', width: '19px' }} />
            </FollowLink>
            <FollowLink href='/rss.xml'>
              <span css={visuallyHidden}>RSS</span>
              <Icon name='RSS' size={21} style={{ position: 'relative', left: '1px' }} />
            </FollowLink>
          </div>
        </Container>
      )}
      <div
        css={(theme) => ({
          width: '100%',
          padding: '16px 24px',
          color: theme.colors.white,
          backgroundColor: 'rgba(39, 39, 39, 1)',
          borderTop: '0.5px solid rgba(102,102,102,1)',
          fontSize: 12,
          textAlign: 'start',
          display: 'flex',
          alignItems: 'center',
          gap: 20,
          [theme.mq.tablet]: {
            alignItems: 'flex-end',
          },
        })}
        data-label='global-footer'
      >
        <div
          css={() => ({
            width: '100%',
            maxWidth: 1400,
            margin: '0 auto',
            display: 'flex',
            alignItems: 'center',
            gap: 20,
          })}
        >
          <div
            css={() => ({
              flex: 1,
            })}
          >
            <span>&copy; Copyright {currentYear}, The Daily Wire LLC </span>
            <span>
              <span className='hide-mobile'>&nbsp;|&nbsp;</span>
              <ExternalLink href='/terms'>Terms</ExternalLink>
              &nbsp;|&nbsp;
              <ExternalLink href='/privacy'>Privacy</ExternalLink>
            </span>
          </div>
          <a href='https://iabtechlab.com/compliance-programs/compliant-companies/#' rel='noreferrer' target='_blank'>
            <img
              alt='Podcast compliance badge'
              height='73'
              src='https://dailywireplus-v2.imgix.net/images/dailywire.com/iabBadge.png'
              style={{
                borderRadius: 4,
                display: 'block',
              }}
              title='Podcast compliance badge'
              width='85'
            />
          </a>
        </div>
      </div>
    </div>
  );
});

Footer.displayName = 'Footer';

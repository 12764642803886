import styled from '@emotion/styled';

type PillProps = {
  as?: React.ElementType;
  theme: any;
  mode: 'light' | 'dark';
  active: boolean;
};

export const Pill = styled('div')(({ theme, mode, active }: PillProps) => ({
  display: 'inline-block',
  padding: '8px 24px',
  fontSize: 12,
  fontFamily: 'Libre Franklin',
  lineHeight: 1.3,
  borderRadius: theme.radii.pill,
  border: `1px solid ${theme.colors.gray20}`,
  color:
    mode === 'light'
      ? active
        ? theme.colors.white
        : theme.colors.gray95
      : active
      ? theme.colors.gray95
      : theme.colors.white,
  appearance: 'none',
  background: active ? (mode === 'light' ? theme.colors.gray95 : theme.colors.white) : 'transparent',
}));

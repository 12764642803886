import * as React from 'react';

import styles from './ElectionDay.module.css';

const ElectionFooter = (): JSX.Element => {
  return (
    <div style={{ display: 'flex', width: '100%', backgroundColor: '#FFF' }}>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: '#FFF',
          maxWidth: '1550px',
          margin: 'auto',
        }}
      >
        <div className={styles.footerContainer}>
          <div className={styles.flexContainer}>
            <div className={styles.logoContainer}>
              <img alt='DailyWire Logo' className={styles.logo} src='/images/version2/LogoDark.svg' />
            </div>

            <div className={styles.divider}>
              <svg fill='none' height='2' viewBox='0 0 600 2' width='600' xmlns='http://www.w3.org/2000/svg'>
                <path d='M0 1.33691H600' stroke='#E3E3E3' />
              </svg>
            </div>

            <div className={styles.linksGrid}>
              <div className={styles.linkContainer}>
                <p className={styles.linkHeader}>Company Information</p>
                <div className={styles.columnFlex}>
                  <a href='/about' rel='noopener noreferrer'>
                    About
                  </a>
                  <a href='/standards-policies' rel='noopener noreferrer'>
                    Standards & Policies
                  </a>
                  <a href='/legal' rel='noopener noreferrer'>
                    Legal
                  </a>

                  <button
                    className='ot-sdk-show-settings'
                    id='ot-sdk-btn'
                    style={{
                      border: 'none',
                      fontSize: '14px',
                      color: '#000',
                      width: 'auto',
                      cursor: 'pointer',
                      padding: '0',
                      lineHeight: '1',
                      backgroundColor: 'transparent',
                      fontFamily: 'Libre Franklin, sans-serif',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    Do Not Sell or Share My Personal Information
                  </button>
                </div>
              </div>

              <div className={styles.divider}>
                <svg fill='none' height='2' viewBox='0 0 600 2' width='600' xmlns='http://www.w3.org/2000/svg'>
                  <path d='M0 1.33691H600' stroke='#E3E3E3' />
                </svg>
              </div>

              <div className={styles.linkContainer}>
                <p className={styles.linkHeader}>Careers and Opportunities</p>
                <div className={styles.columnFlex}>
                  <a href='https://dailywire.multiscreensite.com/' rel='noopener noreferrer'>
                    Career
                  </a>
                  <a href='https://advertise.dailywire.com/' rel='noopener noreferrer'>
                    Advertise with Us
                  </a>
                  <a href='https://get.dailywire.com/speakers/' rel='noopener noreferrer'>
                    Book our Speakers
                  </a>
                </div>
              </div>

              <div className={styles.divider}>
                <svg fill='none' height='2' viewBox='0 0 600 2' width='600' xmlns='http://www.w3.org/2000/svg'>
                  <path d='M0 1.33691H600' stroke='#E3E3E3' />
                </svg>
              </div>

              <div className={styles.linkContainer}>
                <p className={styles.linkHeader}>Support and Feedback</p>
                <div className={styles.columnFlex}>
                  <a href='https://support.dailywire.com/hc/en-us' rel='noopener noreferrer'>
                    Support
                  </a>
                  <a href='/shipping-returns-policy' rel='noopener noreferrer'>
                    Shipping & Returns
                  </a>
                  <a href='https://tips.dailywire.com/hc/en-us/requests/news' rel='noopener noreferrer'>
                    Submit a Tip
                  </a>
                  <a href='mailto:press@dailywire.com' rel='noopener noreferrer'>
                    Media & Press Inquiries
                  </a>
                </div>
              </div>

              <div className={styles.divider}>
                <svg fill='none' height='2' viewBox='0 0 600 2' width='600' xmlns='http://www.w3.org/2000/svg'>
                  <path d='M0 1.33691H600' stroke='#E3E3E3' />
                </svg>
              </div>

              <div className={styles.linkContainer}>
                <p className={styles.linkHeader}>Socials</p>
                <div className={styles.columnFlex}>
                  <a
                    href='https://www.youtube.com/channel/UCaeO5vkdj5xOQHp4UmIN6dw'
                    rel='noopener noreferrer'
                    target='_blank'
                  >
                    Youtube
                  </a>
                  <a href='https://www.facebook.com/DailyWire' rel='noopener noreferrer' target='_blank'>
                    Facebook
                  </a>
                  <a href='https://x.com/realDailyWire' rel='noopener noreferrer' target='_blank'>
                    X
                  </a>
                  <a href='https://www.instagram.com/realdailywire' rel='noopener noreferrer' target='_blank'>
                    Instagram
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.divider}>
            <svg fill='none' height='2' viewBox='0 0 600 2' width='600' xmlns='http://www.w3.org/2000/svg'>
              <path d='M0 1.33691H600' stroke='#E3E3E3' />
            </svg>
          </div>

          <div className={styles.termsAndPrivacy}>
            <div className={styles.copyright}>
              <p>
                © Copyright 2024 The Daily Wire LLC |{' '}
                <a className='underline' href='/terms' rel='noopener noreferrer'>
                  Terms
                </a>{' '}
                |{' '}
                <a className='underline' href='/privacy' rel='noopener noreferrer'>
                  Privacy
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ElectionFooter;

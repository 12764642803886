import * as React from 'react';

import styled from '@emotion/styled';

import { Button, Icon } from '@/components/design-system';

const Message = styled<any>('div')`
  position: relative;
  overflow: hidden;
  margin: 24px 0 0;
  padding: 1rem;
  border-radius: 6px;
  font-size: 14px;
  line-height: 1.5;
  color: #fff;
  display: flex;
  background-color: ${({ status }) => (status === 'error' ? '#A32929' : '#26734C')};
  align-items: center;
  a {
    color: #fff;
    text-decoration: underline;
  }
`;

const MessageText = styled('div')`
  button {
    font-size: 14px;
    margin: 8px 4px 0 0;
    appearance: none;
    border: 0;
    background: transparent;
    padding: 0;
    text-decoration: underline;
    color: #fff;
  }

  button:focus {
    outline: none;
  }
`;

interface ISystemMessageProps {
  status?: 'success' | 'error';
  allowClose?: boolean;
}

export const SystemMessage: React.FC<ISystemMessageProps> = ({
  status = 'success',
  children,
  allowClose = true,
  ...rest
}) => {
  const [close, setClose] = React.useState(false);

  if (close) {
    return <div />;
  }

  return (
    <Message status={status} {...rest}>
      <Icon
        css={{ marginRight: '.5rem', flexShrink: 0 }}
        name={status === 'error' ? 'ALERT' : 'CHECK_CIRCLE'}
        size={16}
      />
      <MessageText> {children}</MessageText>
      {allowClose && (
        <Button css={{ marginLeft: 'auto' }} onClick={() => setClose(true)} type='button' variant='naked'>
          <Icon css={{ color: '#fff' }} name='CLOSE' size={16} />
        </Button>
      )}
    </Message>
  );
};

export const centsToDollars = (cents: number) => {
  const negative = Math.sign(cents) === -1;
  const dollars = negative ? Math.abs(cents) / 100 : cents / 100;
  const showCents = dollars % 1 !== 0;

  return `${negative ? '-' : ''} ${dollars.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: showCents ? 2 : 0,
  })}`;
};

import { css } from '@emotion/core';

import { breakpoints } from './breakpoints';

const containDefaults = css`
  margin-right: auto;
  margin-left: auto;
  width: 100%;

  @media (min-width: ${breakpoints.mobile}px) {
    max-width: 560px;
  }
`;

export const contain = {
  sm: css`
    ${containDefaults};
    max-width: 688px;
    padding-left: 24px;
    padding-right: 24px;
  `,
  md: css`
    ${containDefaults};
    padding-left: 24px;
    padding-right: 24px;

    @media (min-width: ${breakpoints.mobile}px) {
      max-width: 560px;
    }
    @media (min-width: ${breakpoints.tablet}px) {
      max-width: 824px;
      padding-left: 48px;
      padding-right: 48px;
    }
  `,
  lg: css`
    ${containDefaults};
    padding-left: 24px;
    padding-right: 24px;
    @media (min-width: ${breakpoints.mobile}px) {
      max-width: 560px;
    }
    @media (min-width: ${breakpoints.tablet}px) {
      max-width: 824px;
      padding-left: 48px;
      padding-right: 48px;
    }
    @media (min-width: ${breakpoints.desktop}px) {
      max-width: 1296px;
    }
  `,
};

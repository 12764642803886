/* eslint-disable import/no-unresolved */
/* eslint-disable import/order */

import { useEffect, useState, useCallback } from 'react';

import { useAuth0 } from '@/context/auth';

import cookie from 'js-cookie';

import { tokenShouldRefreshed } from '@/utils/helpers';

import { clearUserData } from '@/lib/auth0/clearUserData';

export function useToken() {
  const [token, setToken] = useState<string | null>(
    typeof localStorage !== 'undefined' ? localStorage?.getItem('access_token') : null,
  );
  const { getTokenSilently, isAuthenticated, loginWithRedirect } = useAuth0();

  const getToken = useCallback(
    async function () {
      if (!isAuthenticated) return null;
      if (token && !tokenShouldRefreshed(token)) return token;
      let accessToken = localStorage.getItem('access_token');
      if (!accessToken || tokenShouldRefreshed(accessToken)) {
        try {
          accessToken = await getTokenSilently();
          if (!accessToken) {
            throw new Error('Token is empty');
          }
        } catch (error) {
          console.error('Error while getting token', error);
          setToken(null);
          clearUserData();
          loginWithRedirect({ appState: { targetUrl: window.location.pathname } });
          return null;
        }
        localStorage.setItem('access_token', accessToken);
        cookie.set('accessToken', token, { expires: 30 });
      }
      setToken(accessToken);
      return accessToken;
    },
    [getTokenSilently, token, isAuthenticated, loginWithRedirect],
  );

  useEffect(() => {
    if (token) return;
    getToken();
  }, [token, getToken]);

  return { token, getToken };
}

/* OWASP Special Characters: https://www.owasp.org/index.php/Password_special_characters */
const specialCharacters = [
  ' ',
  '!',
  '"',
  '#',
  '\\$',
  '%',
  '&',
  "'",
  '\\(',
  '\\)',
  '\\*',
  '\\+',
  ',',
  '-',
  '\\.',
  '/',
  ':',
  ';',
  '<',
  '=',
  '>',
  '\\?',
  '@',
  '\\[',
  '\\\\',
  '\\]',
  '\\^',
  '_',
  '`',
  '{',
  '\\|',
  '}',
  '~',
].join('|');

const specialCharactersRegexp = new RegExp(specialCharacters);

type PasswordRequirements = {
  valid: boolean;
  lowercase: boolean;
  uppercase: boolean;
  numbers: boolean;
  special: boolean;
};

export type PassportReport = {
  valid: boolean;
  length: boolean;
  requirements: PasswordRequirements;
};

export const validatePassword = (password: string): PassportReport => {
  const output = {
    valid: false,
    length: false,
    requirements: {
      lowercase: false,
      uppercase: false,
      numbers: false,
      special: false,
    },
  };

  if (password.length >= 8) {
    output.length = true;
  }

  let numberOfElements = 0;

  // Lowecase letters
  if (/.*[a-z].*/.test(password)) {
    output.requirements.lowercase = true;
    numberOfElements++;
  }

  if (/.*[A-Z].*/.test(password)) {
    output.requirements.uppercase = true;
    numberOfElements++;
  }

  if (/.*[0-9].*/.test(password)) {
    output.requirements.numbers = true;
    numberOfElements++;
  }

  if (specialCharactersRegexp.test(password)) {
    output.requirements.special = true;
    numberOfElements++;
  }

  if (numberOfElements >= 4) {
    output.requirements.valid = true;
  }

  if (output.length && output.requirements.valid) {
    output.valid = true;
  }

  return output;
};

import * as React from 'react';

import { Heading } from '@/components/design-system';

interface ISectionLabelProps {
  section?: string;
  title?: string;
  image?: string;
  variant?: 'light' | 'dark';
}

export const SectionLabel: React.FC<ISectionLabelProps> = ({ section, title, image, variant = 'light' }) => {
  return (
    <div
      css={(theme) => ({
        display: 'flex',
        alignItems: 'center',
        lineHeight: '1',
        '::before': {
          content: `''`,
          border: `6px solid ${theme.colors.red}`,
          borderRightColor: 'transparent',
          borderBottomColor: 'transparent',
          marginRight: 8,
          color: theme.colors.red,
        },
      })}
    >
      {image && (
        <img
          alt={title}
          css={{
            width: 32,
            borderRadius: '50%',
            verticalAlign: 'middle',
            marginRight: 8,
          }}
          src={image}
        />
      )}
      <Heading
        as='span'
        css={(theme) => ({
          color: variant === 'dark' ? theme.colors.white : theme.colors.gray95,
          fontFamily: 'Libre Franklin',
        })}
        variant='200'
      >
        {section && (
          <span css={(theme) => ({ color: theme.colors.red, marginRight: 8, fontFamily: 'Libre Franklin' })}>
            {section}
          </span>
        )}
        {title && title}
      </Heading>
    </div>
  );
};

import { css } from '@emotion/core';

import { color } from './color';
import { shadow } from './shadows';

export const table = css`
  color: #fff;
  width: 100%;
  background-color: ${color.gray85};
  border-spacing: 0;
  border-radius: 6px;
  ${shadow.default};
  text-align: left;
  margin-bottom: 1rem;

  th {
    padding: 16px 32px;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 700;
    background-color: ${color.gray95};
    white-space: nowrap;
    border-bottom: 1px solid ${color.red};
  }

  th:first-of-type {
    border-radius: 6px 0 0 0;
  }

  th:last-of-type {
    border-radius: 0 6px 0 0;
  }

  td {
    padding: 16px 32px;
    font-size: 14px;
    vertical-align: top;
    /* min-width: */
    white-space: nowrap;
  }

  tr:not(:last-of-type) td {
    border-bottom: 1px solid ${color.gray80};
  }
`;

/* eslint-disable import/no-unresolved */
/* eslint-disable import/order */
import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import cn from 'classnames';

import { getABTestAnalyticData } from '@/lib/abTesting';
import { getUtmCookieData } from '@/lib/utm_cookie';

interface AllPageBannerProps {
  altText: string;
  alwaysTimer: boolean;
  email: string;
  endDate: string;
  mobileTitleSuffix: string;
  removeDate: string;
  startDate: string;
  subscription: any;
  titleSuffix: string;
}

const DAYS_OF_WEEK = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const CST_HOUR_OFFSET = 5;

const AllPageBanner: React.FC<AllPageBannerProps> = function ({
  altText,
  alwaysTimer = false,
  email = null,
  endDate,
  mobileTitleSuffix,
  removeDate,
  startDate,
  subscription,
  titleSuffix,
}) {
  const [countDown, setCountDown] = useState({ d: '00', h: '00', m: '00', s: '00' });
  const router = useRouter();
  const withTimer = alwaysTimer;
  const destination = '/videos/am-i-racist';

  useEffect(
    function () {
      let intervalId = null;

      if (withTimer) {
        const end = new Date(`${endDate}+0000`);

        end.setHours(end.getHours() + CST_HOUR_OFFSET);

        const endTs = end.getTime();

        setCountDown(calculateTime(endTs));

        intervalId = setInterval(function () {
          setCountDown(calculateTime(endTs));
          if (!isTodayInRangeCST(startDate, removeDate)) {
            clearInterval(intervalId);
          }
        }, 1000);
      }

      return function () {
        clearInterval(intervalId);
      };
    },
    [endDate, removeDate, startDate, withTimer],
  );

  function sendCtaEvent() {
    const abTestData = getABTestAnalyticData();
    const utmCookieData = getUtmCookieData();

    window.__DW_Next_Bridge?.Analytics.logEvent('Landing Page CTA Click', {
      ...abTestData,
      ...utmCookieData,
      destination,
      email: email,
      label: 'Am I Racist?',
      location: 'Universal CTA Banner',
      path: window.location.pathname,
      subscriptionUuid: subscription?.id || null,
    });
  }

  const offset = 0;
  const { d, h, m, s } = countDown;
  const timeOver = d === '00' && h === '00' && m === '00' && s === '00';

  if (!isTodayInRangeCST(startDate, removeDate, offset)) {
    return null;
  }

  function adjustImages() {
    const isToday = isTodayInRangeCST('2024-10-27T11:01:00', '2024-12-30T00:01:00', offset);
    const isTomorrow = isTodayInRangeCST('2024-10-27T00:01:00', '2024-10-28T00:01:00', offset);
    const isFuture = isTodayInRangeCST('2024-09-11T00:01:00', '2024-10-27T00:01:00', offset);

    if (isToday) {
      return 'isToday';
    } else if (isTomorrow) {
      return 'isTomorrow';
    } else if (isFuture) {
      return 'isFuture';
    } else {
      return '';
    }
  }
  const title = isTodayInRangeCST(startDate, endDate, offset) ? (
    <>
      <span className='title-mobile'>
        {getTitlePrefix(endDate)} <span className='text-normal'>AT {mobileTitleSuffix}</span>
      </span>
      <span className='title-desktop'>
        {getTitlePrefix(endDate)} <span className='text-normal'>AT {titleSuffix}</span>
      </span>
    </>
  ) : (
    <>
      <span className='title-mobile'>{altText}</span>
      <span className='title-desktop'>{altText}</span>
    </>
  );

  return (
    <div
      className={cn('all-page-banner ap-v5', adjustImages(), {
        'with-timer': withTimer,
      })}
      onClick={() => {
        sendCtaEvent();
        router.push(destination);
      }}
    >
      <div className='banner-left'>
        {/* <div className='graphic-birchum' /> */}
        <div className='text-content'>
          {/* <div className='logo'>
            <img alt='Mr Birchum' src='/images/ap-banner/birchum_banner_logo.png' />
          </div> */}
          {/* <h2>{title}</h2> */}
          {withTimer && !timeOver && (
            <div className='timer-mobile'>
              {d !== '00' && (
                <>
                  <div className='unit'>{d}</div>
                  <div className='divider'>:</div>
                </>
              )}
              <div className='unit'>{h}</div>
              <div className='divider'>:</div>
              <div className='unit'>{m}</div>
              <div className='divider'>:</div>
              <div className='unit'>{s}</div>
            </div>
          )}
          {timeOver && (
            <div className='timer-mobile'>
              <div className='time time-over'>Watch Now</div>
            </div>
          )}
        </div>
      </div>
      <div className='banner-right'>
        {withTimer && !timeOver && (
          <div className='timer'>
            {d !== '00' && (
              <div className='time-unit days'>
                <div className='time'>{d}</div>
                <div className='unit'>Day{d !== '01' && 's'}</div>
              </div>
            )}
            <div className='time-unit hours'>
              <div className='time'>{h}</div>
              <div className='unit'>Hour{h !== '01' && 's'}</div>
            </div>
            <div className='time-unit minutes'>
              <div className='time'>{m}</div>
              <div className='unit'>Min{m !== '01' && 's'}</div>
            </div>
            {d === '00' && (
              <div className='time-unit seconds'>
                <div className='time'>{s}</div>
                <div className='unit'>Second{s !== '01' && 's'}</div>
              </div>
            )}
          </div>
        )}
        {timeOver && (
          <div className='timer'>
            <div className='time time-over'>Watch Now</div>
          </div>
        )}
        <div className='text-content-right'>
          <div className='mobile-cta-link'>
            <svg fill='none' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'>
              <g clipPath='url(#clip0_1236_55526)'>
                <path d='M5 12H19' stroke='black' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' />
                <path d='M15 16L19 12' stroke='black' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' />
                <path d='M15 8L19 12' stroke='black' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' />
              </g>
              <defs>
                <clipPath id='clip0_1236_55526'>
                  <rect fill='black' height='24' width='24' />
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};

// eslint-disable-next-line
function DigitalNumber({ digit, size = 'md' }) {
  return (
    <div className={`digital-number ${size}`}>
      {digitToArray(digit).map(function (r) {
        return (
          <div className='dn-row'>
            {r.map(function (c) {
              let cls = 'dn-col';
              if (c) {
                cls += ' active';
              }
              return <div className={cls} />;
            })}
          </div>
        );
      })}
    </div>
  );
}

function calculateTime(endTs) {
  const now = new Date();

  const nowTs = now.getTime();
  const remaining = endTs - nowTs;

  const days = Math.floor(remaining / (1000 * 60 * 60 * 24));
  const hours = Math.floor((remaining / (1000 * 60 * 60)) % 24);
  const minutes = Math.floor((remaining / (1000 * 60)) % 60);
  const seconds = Math.floor((remaining / 1000) % 60);

  return {
    d: pad(days),
    h: pad(hours),
    m: pad(minutes),
    s: pad(seconds),
  };
}

function getHourAdjust(date) {
  const offset = date.getTimezoneOffset() / 60;

  return offset - CST_HOUR_OFFSET;
}

function getTitlePrefix(endDate: string): string {
  const end = new Date(endDate);
  const endMonth = end.getMonth();
  const endDom = end.getDate();

  const now = new Date();
  const nowMonth = now.getMonth();
  const nowDom = now.getDate();

  const domDiff = endDom - nowDom;

  let prefix = '';

  if (endMonth !== nowMonth || domDiff > 1) {
    const dow = DAYS_OF_WEEK[end.getDay()];
    // prefix = `${dow} ${endMonth + 1}/${endDom}`;
    prefix = `${dow}`;
  } else if (domDiff === 1) {
    prefix = 'Tomorrow';
  } else {
    prefix = 'Tonight';
  }

  return `Premieres ${prefix}`;
}

function isDateTodayCST(dateStr) {
  const date = parseDateStr(dateStr);

  if (!date) {
    return false;
  }

  const now = setToCST(new Date());

  if (now.getFullYear() !== date.year || now.getMonth() !== date.month || now.getDate() !== date.day) {
    return false;
  }

  return true;
}

function isTodayInRangeCST(startDateStr, endDateStr, offset = 0) {
  if (typeof localStorage !== 'undefined' && localStorage.getItem('dev-discount-banner')) {
    return true;
  }

  const today = setToCST(new Date());

  const todayStr = `${today.getFullYear()}-${pad(today.getMonth() + 1)}-${pad(today.getDate())}T${pad(
    today.getHours() - offset,
  )}:${pad(today.getMinutes())}:${pad(today.getSeconds())}`;

  return todayStr > startDateStr && todayStr < endDateStr;
}

function pad(number) {
  if (number < 10) {
    return `0${number < 0 ? '0' : number}`;
  }

  return `${number}`;
}

function parseDateStr(dateStr) {
  const dateArr = dateStr.split('-');

  if (dateArr.length !== 3) {
    return null;
  }

  return {
    year: Number(dateArr[0]),
    month: Number(dateArr[1]) - 1,
    day: Number(dateArr[2]),
  };
}

function setToCST(date) {
  const cstOffset = getHourAdjust(date);

  date.setHours(date.getHours() + cstOffset);

  return date;
}

function digitToArray(digit) {
  switch (digit) {
    case '0':
      return [
        [0, 1, 1, 1, 0],
        [1, 0, 0, 0, 1],
        [1, 0, 0, 1, 1],
        [1, 0, 1, 0, 1],
        [1, 1, 0, 0, 1],
        [1, 0, 0, 0, 1],
        [0, 1, 1, 1, 0],
      ];
    case '1':
      return [
        [0, 0, 1, 0, 0],
        [0, 1, 1, 0, 0],
        [0, 0, 1, 0, 0],
        [0, 0, 1, 0, 0],
        [0, 0, 1, 0, 0],
        [0, 0, 1, 0, 0],
        [0, 1, 1, 1, 0],
      ];
    case '2':
      return [
        [0, 1, 1, 1, 0],
        [1, 0, 0, 0, 1],
        [0, 0, 0, 0, 1],
        [0, 0, 1, 1, 0],
        [0, 1, 0, 0, 0],
        [1, 0, 0, 0, 0],
        [1, 1, 1, 1, 1],
      ];
    case '3':
      return [
        [0, 1, 1, 1, 0],
        [1, 0, 0, 0, 1],
        [0, 0, 0, 0, 1],
        [0, 1, 1, 1, 0],
        [0, 0, 0, 0, 1],
        [1, 0, 0, 0, 1],
        [0, 1, 1, 1, 0],
      ];
    case '4':
      return [
        [0, 0, 1, 1, 0],
        [0, 1, 0, 1, 0],
        [1, 0, 0, 1, 0],
        [1, 1, 1, 1, 1],
        [0, 0, 0, 1, 0],
        [0, 0, 0, 1, 0],
        [0, 0, 0, 1, 0],
      ];
    case '5':
      return [
        [1, 1, 1, 1, 1],
        [1, 0, 0, 0, 0],
        [1, 0, 0, 0, 0],
        [1, 1, 1, 1, 0],
        [0, 0, 0, 0, 1],
        [0, 0, 0, 0, 1],
        [1, 1, 1, 1, 0],
      ];
    case '6':
      return [
        [0, 1, 1, 1, 0],
        [1, 0, 0, 0, 1],
        [1, 0, 0, 0, 0],
        [1, 1, 1, 1, 0],
        [1, 0, 0, 0, 1],
        [1, 0, 0, 0, 1],
        [0, 1, 1, 1, 0],
      ];
    case '7':
      return [
        [1, 1, 1, 1, 1],
        [1, 0, 0, 0, 1],
        [0, 0, 0, 0, 1],
        [0, 0, 0, 1, 0],
        [0, 0, 1, 0, 0],
        [0, 0, 1, 0, 0],
        [0, 0, 1, 0, 0],
      ];
    case '8':
      return [
        [0, 1, 1, 1, 0],
        [1, 0, 0, 0, 1],
        [1, 0, 0, 0, 1],
        [0, 1, 1, 1, 0],
        [1, 0, 0, 0, 1],
        [1, 0, 0, 0, 1],
        [0, 1, 1, 1, 0],
      ];
    case '9':
      return [
        [0, 1, 1, 1, 0],
        [1, 0, 0, 0, 1],
        [1, 0, 0, 0, 1],
        [0, 1, 1, 1, 1],
        [0, 0, 0, 0, 1],
        [1, 0, 0, 0, 1],
        [0, 1, 1, 1, 0],
      ];
    default:
      return [
        [0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0],
      ];
  }
}

export default AllPageBanner;

import React, { Fragment } from 'react';

import styled from '@emotion/styled';
import ReactHtmlParser from 'react-html-parser';

import { BodyText } from '@/components/shared/BodyText';
import { moreLink } from '@/utils/styles';

const ReadMore = styled('button')`
  ${moreLink};
  line-height: 24px;
  position: absolute;
  right: 0;
  bottom: 8px;
  background: linear-gradient(270deg, #fff 60%, rgba(255, 255, 255, 0) 100%);
  width: 160px;
  text-align: right;
`;

interface IExpandableTextProps {
  content: string;
}

export const ExpandableText: React.FC<IExpandableTextProps> = ({ content }) => {
  const [expanded, setExpanded] = React.useState(false);

  if (!content) return null;
  const bodyComponents = ReactHtmlParser(content);

  return (
    <BodyText>
      <div>{bodyComponents[0]}</div>
      <div hidden={!expanded}>
        {bodyComponents.map((BodyComponent, index) => {
          if (!index) return;
          return <Fragment key={BodyComponent.key}>{BodyComponent}</Fragment>;
        })}
      </div>
      {!expanded && <ReadMore onClick={() => setExpanded(true)}>Read more</ReadMore>}
    </BodyText>
  );
};

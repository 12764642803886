/* eslint-disable import/no-unresolved */
/* eslint-disable import/order */
import { Middleware } from 'swr';

import { useAuth0 } from '@/context/auth';
import { useToken } from '@/hooks/useToken';
import { appApi as gqlAppApi, getAppApiWithToken } from '@/graphql/app';

export const appApi: Middleware = (useSWRHook) => {
  return (key, fetcher, config) => {
    if (!fetcher) throw new Error('Fetcher must be provided for `tokenizedAppApi` middleware to function.');

    const { isAuthenticated } = useAuth0();
    const { getToken } = useToken();

    const fetcherWithAppApi = async (...args) => {
      if (isAuthenticated) {
        const token = await getToken();
        const tokenizedAppApi = getAppApiWithToken(token as string);

        return fetcher(...args, tokenizedAppApi);
      }

      return fetcher(...args, gqlAppApi);
    };

    return useSWRHook(key, fetcherWithAppApi, config);
  };
};

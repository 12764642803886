/**
 * Formats a JavaScript Date object as MM/DD/YYYY.
 * @param {Date} date - The date to format.
 * @returns {string} - The formatted date string.
 */
export function formatDate(date: Date): string {
  if (!(date instanceof Date)) {
    throw new Error('Invalid date');
  }

  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
  const day = String(date.getDate()).padStart(2, '0');
  const year = date.getFullYear();

  return `${month}/${day}/${year}`;
}

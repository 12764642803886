import * as React from 'react';

import { useLayer, useHover, Arrow } from 'react-laag';

import s from './Tooltip.module.css';

interface ITooltip {
  className?: string;
  text: string;
}

export const Tooltip: React.FC<ITooltip> = ({ children, text }) => {
  // We use `useHover()` to determine whether we should show the tooltip.
  // Notice how we're configuring a small delay on enter / leave.
  const [isOver, hoverProps] = useHover({ delayEnter: 100, delayLeave: 300 });

  // Tell `useLayer()` how we would like to position our tooltip
  const { triggerProps, layerProps, arrowProps, renderLayer } = useLayer({
    isOpen: isOver,
    placement: 'top-center',
    triggerOffset: 12, // small gap between wrapped content and the tooltip
  });

  let trigger;

  // when children equals text (string | number), we need to wrap it in an
  // extra span-element in order to attach props
  if (isReactText(children)) {
    trigger = (
      <span className='tooltip-text-wrapper' {...triggerProps} {...hoverProps}>
        {children}
      </span>
    );
  }

  // In case of an react-element, we need to clone it in order to attach our own props
  if (React.isValidElement(children)) {
    trigger = React.cloneElement(children, {
      ...triggerProps,
      ...hoverProps,
    });
  }

  return (
    <>
      {trigger}
      {renderLayer(
        <>
          {isOver && (
            <div className={s.root} {...layerProps}>
              {text}
              <Arrow
                {...arrowProps}
                backgroundColor='rgba(0, 0, 0, 0.75)'
                borderColor='rgba(0, 0, 0, 0.75)'
                borderWidth={0}
                size={6}
              />
            </div>
          )}
        </>,
      )}
    </>
  );
};

function isReactText(children: React.ReactNode) {
  return ['string', 'number'].includes(typeof children);
}

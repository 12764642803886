export const cleanRecurlyErrorMessages = (message: string) => {
  if (!message) return null;

  return message
    .replace('GraphQL error: Error: ', '')
    .replace('subscription.account.base ', '')
    .replace('gift_card.base ', '')
    .replace('subscription.account.billing_info.credit_card_verification_value', 'CVV')
    .replace('gift_card.billing_info.credit_card_number ', 'Credit Card number ')
    .replace('subscription.account.billing_info.credit_card_number ', 'Credit Card number ');
};

import useSWRInfinite from 'swr/infinite';

import { appApi } from '@/graphql/app';

import { EpisodeQueryKeys } from '../types';

const PAGE_SIZE = 10;

export const useEpisodes = (seasonId?: string) => {
  const swrHook = useSWRInfinite(
    (index) => {
      if (!seasonId) return null;
      if (index === 0) return [EpisodeQueryKeys.Episodes, seasonId, PAGE_SIZE, null];
      return [EpisodeQueryKeys.Episodes, seasonId, PAGE_SIZE, PAGE_SIZE * index];
    },
    async (_, seasonId, first, skip) => {
      return await appApi.getSeasonEpisodes({
        where: { season: { id: seasonId } },
        first,
        skip,
      });
    },
    {
      revalidateOnMount: true,
      revalidateOnFocus: false,
    },
  );

  const episodes = swrHook.data?.flatMap((page) => page.getSeasonEpisodes);
  const isLoadingInitialData = !swrHook.data && !swrHook.error;
  const isLoadingMore =
    isLoadingInitialData || (swrHook.size > 0 && swrHook.data && typeof swrHook.data[swrHook.size - 1] === 'undefined');
  const isEmpty = swrHook.data?.[0]?.getSeasonEpisodes?.length === 0;
  const isReachingEnd =
    isEmpty || (swrHook.data && (swrHook.data[swrHook.data.length - 1]?.getSeasonEpisodes?.length ?? 0) < PAGE_SIZE);
  const isRefreshing = swrHook.isValidating && swrHook.data && swrHook.data.length === swrHook.size;

  return {
    ...swrHook,
    episodes,
    isLoadingInitialData,
    isLoadingMore,
    isReachingEnd,
    isRefreshing,
  };
};

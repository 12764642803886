import * as React from 'react';

import styled from '@emotion/styled';

import { Heading, Text } from '@/components/design-system';
import { contain, color, breakpoints } from '@/utils/styles';

const ImageWrapper = styled<any>('div')`
  max-width: ${(props) => (props.imageWidth ? props.imageWidth * 0.7 : 200)}px;
  margin: 0 auto 12px;

  @media (min-width: ${breakpoints.tablet}px) {
    max-width: ${(props) => (props.imageWidth ? props.imageWidth : 200)}px;
  }
`;

const Header = styled<any>('header')`
  text-align: center;
  margin: ${(props) => (props.prominent ? '0 0 32px' : '32px auto')};
  padding: ${(props) => (props.prominent ? `32px 0 ${props.overlappingContent ? '80px' : '40px'}` : '0')};
  background-color: ${(props) => (props.prominent ? color.gray95 : 'transparent')};

  @media (min-width: ${breakpoints.tablet}px) {
    margin: ${(props) => (props.prominent ? '0 0 48px' : '40px auto')};
    padding: ${(props) => (props.prominent ? `40px 0 ${props.overlappingContent ? '104px' : '48px'}` : '0')};
  }
`;

type HeaderProps = {
  image?: string;
  imageWidth?: number;
  kicker?: string;
  title: string;
  description?: string;
  prominent?: boolean;
  overlappingContent?: boolean;
};

export const PageHeader: React.FC<HeaderProps> = ({
  image,
  imageWidth,
  kicker,
  title,
  description,
  prominent = false,
  overlappingContent = false,
  ...rest
}) => {
  return (
    <Header overlappingContent={overlappingContent} prominent={prominent} {...rest}>
      <div css={contain.md}>
        {image && (
          <ImageWrapper imageWidth={imageWidth}>
            <img alt={title} src={image} />
          </ImageWrapper>
        )}
        {kicker && (
          <Heading css={(theme) => ({ color: theme.colors.gray40 })} variant='200'>
            {kicker}
          </Heading>
        )}
        <Heading css={(theme) => ({ color: theme.colors.white, margin: '4px 0' })}>{title}</Heading>
        {description && (
          <Text
            css={(theme) => ({
              color: theme.colors.gray40,
            })}
          >
            {description}
          </Text>
        )}
      </div>
    </Header>
  );
};

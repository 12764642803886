/* eslint-disable import/no-unresolved */
/* eslint-disable import/order */

import { css } from '@emotion/core';
import styled from '@emotion/styled';

import { breakpoints } from '@/utils/styles';

export const FourColumnGrid = styled<any>('div')`
  display: grid;
  grid-gap: 24px;
  padding: 0;

  ${(props) =>
    !props.mobileSingle
      ? css`
          grid-template-columns: repeat(2, 1fr);
        `
      : css`
          @media (min-width: ${breakpoints.tablet}px) {
            grid-template-columns: repeat(2, 1fr);
          }
        `}

  @media (min-width: ${breakpoints.desktop}px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

export const ThreeColumnGrid = styled('div')`
  display: grid;
  grid-gap: 16px;

  @media (min-width: ${breakpoints.desktop}px) {
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 32px;
  }
`;

export const SixtyFourtyGrid = styled('div')`
  display: grid;
  grid-gap: 32px;

  @media (min-width: ${breakpoints.desktop}px) {
    grid-template-columns: 1.1fr 0.9fr;
  }
`;

export const TwoColGrid = styled('div')`
  display: grid;
  grid-gap: 32px;
  align-items: flex-start;
  justify-content: center;

  @media (min-width: ${breakpoints.desktop}px) {
    grid-template-columns: 1fr 1fr;
  }
`;

/* eslint-disable import/no-unresolved */
/* eslint-disable import/order */
import { Middleware, SWRHook } from 'swr';

import { useAuth0 } from '@/context/auth';
import { getChatApiWithToken, chatApi as gqlChatApi } from '@/graphql/chat';
import { useToken } from '@/hooks/useToken';

export const chatApi: Middleware = (useSWRHook: SWRHook) => {
  return (key, fetcher, config) => {
    const { getToken } = useToken();
    if (!fetcher) throw new Error('Fetcher must be provided for `tokenizedChatApi` middleware to function.');

    const { isAuthenticated } = useAuth0();

    const fetcherWithChatApi = async (...args: any) => {
      if (isAuthenticated) {
        const token = await getToken();
        const tokenizedChatApi = getChatApiWithToken(token as string);
        return fetcher(...args, tokenizedChatApi);
      }

      return fetcher(...args, gqlChatApi);
    };

    return useSWRHook(key, fetcherWithChatApi, config);
  };
};

import * as React from 'react';

import cn from 'classnames';
import NextLink from 'next/link';

import { font } from '@/utils/styles';

type ButtonLinkProps = {
  variant?: 'primary' | 'secondary' | 'minimal' | 'naked';
  size?: 'large' | 'small';
  style?: React.CSSProperties;
  href: string;
  onClick?: (event: React.MouseEvent<Element, MouseEvent>) => void;
  className?: string;
};

export const ButtonLink: React.FC<ButtonLinkProps> = ({
  variant = 'primary',
  size = 'large',
  href,
  children,
  className = '',
  ...props
}) => {
  let isExternal = href.startsWith('http') || href.endsWith('.pdf');

  if (isExternal && href.startsWith('https://get.dailywire.com')) {
    isExternal = false;
  }

  const buttonLinkStyles = (theme: any) => ({
    ...theme.buttons[variant],
    ...theme.buttonSizes[size],

    display: 'inline-block',
    textAlign: 'center',
    borderRadius: theme.radii.default,
    fontFamily: 'Libre Franklin',
    textDecoration: 'none',
  });

  if (isExternal) {
    return (
      <a
        className={cn(className, 'external')}
        css={(theme) => buttonLinkStyles(theme)}
        href={href}
        rel='noopener noreferrer'
        target='_blank'
        {...props}
      >
        {children}
      </a>
    );
  }

  return (
    <NextLink href={href as string} passHref>
      <a className={className} css={(theme) => buttonLinkStyles(theme)} href={href} {...props}>
        {children}
      </a>
    </NextLink>
  );
};

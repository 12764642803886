import { AccessLevel, Subscriber } from 'types/subscriber';

export const subscriberCanAccessChat = (subscriber?: Subscriber, accessLevels: string[] = []): boolean => {
  if (!subscriber) return false;

  if (accessLevels.length === 0) return true;

  const effectivePlan = subscriber.effectivePlan as AccessLevel;
  return accessLevels.includes(effectivePlan);
};

import * as React from 'react';

import cn from 'classnames';

import s from './Container.module.css';

interface IContainer {
  variant?: 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
  className?: string;
  spacing?: 'none' | 'tight' | 'generous';
}

export const Container: React.FC<IContainer> = ({ children, className, variant = 'xl', spacing = 'generous' }) => {
  return (
    <div
      className={cn(className, s.container, {
        [s.sm]: variant === 'sm',
        [s.md]: variant === 'md',
        [s.lg]: variant === 'lg',
        [s.xl]: variant === 'xl',
        [s.xxl]: variant === 'xxl',
        [s.tight]: spacing === 'tight',
        [s.generous]: spacing === 'generous',
      })}
    >
      {children}
    </div>
  );
};

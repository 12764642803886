import * as React from 'react';

import cn from 'classnames';

import s from './Grid.module.css';

export interface IGrid {
  className?: string;
  layout?: 'A' | 'B' | 'C' | 'D' | 'E' | 'F' | 'G';
}

export const Grid: React.FC<IGrid> = ({ className, layout = 'A', children }) => {
  return (
    <div
      className={cn(
        s.root,
        {
          [s.layoutA]: layout === 'A',
          [s.layoutB]: layout === 'B',
          [s.layoutC]: layout === 'C',
          [s.layoutD]: layout === 'D',
          [s.layoutE]: layout === 'E',
          [s.layoutF]: layout === 'F',
          [s.layoutG]: layout === 'G',
        },
        className,
      )}
    >
      {children}
    </div>
  );
};

const processEnvAsInt = (env: string | undefined | null, defaultValue: number): number => {
  if (!env) {
    return defaultValue;
  }
  return parseInt(env);
};

const processEnvAsBoolean = (env: string | undefined | null, defaultValue: boolean): boolean => {
  if (!env) {
    return defaultValue;
  }
  return env == 'true';
};

export { processEnvAsInt, processEnvAsBoolean };

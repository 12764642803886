import { APOLLO_CLIENT_NAME } from '@/lib/constants';
import { GraphQLClient } from 'graphql-request';

export * from './generated';
import { getSdk } from './generated';

const chatApiURL = `https://${process.env.NEXT_PUBLIC_DW_CHAT_API_HOST}/discussion/graphql`;

const chatClient = new GraphQLClient(chatApiURL, {
  headers: { 'apollographql-client-name': APOLLO_CLIENT_NAME  },
});

export const chatApi = getSdk(chatClient);

export const getChatApiWithToken = (token: string) => {
  const chatApiWithToken = new GraphQLClient(chatApiURL, {
    headers: { Authorization: `Bearer ${token}`, 'apollographql-client-name': APOLLO_CLIENT_NAME },
  });

  return getSdk(chatApiWithToken);
};

/* eslint-disable import/no-unresolved */
/* eslint-disable import/order */

import { css } from '@emotion/core';

import { fadeIn, slideUp } from '@/components/design-system/utils/animations';

const baseTheme = {
  space: [0, 2, 4, 8, 16, 24, 32, 40],
  fontSizes: [12, 14, 16, 20, 24, 32, 40, 48],
  fonts: {
    primary: `"Lato", sans-serif`,
    //
    beaufort: 'beaufortproregular',
    beaufortLight: 'beaufort_prolight',
    beaufortMedium: 'beaufort_promedium',
    beaufortBold: 'beaufortprobold',
    beaufortHeavy: 'beaufort_proheavy',
    lato: "'Lato', sans-serif",
  },
  colors: {
    primary: '#0066CC',
    //
    success: '#008463',
    warning: '#F25F0D',
    error: '#B30000',
    white: '#FFFFFF',
    neutrals: [
      '#FCFCFD', // 99%
      '#DEE6ED', // 90%
      '#364D63', // 30%
    ],

    red: '#B33828',
    blue: '#0077D9',
    green: '#0F992A',
    gray99: '#111111',
    gray98: '#050505',
    gray95: 'rgba(20,20,21,0.91)',
    gray92: '#141414',
    gray90: '#1A1A1A',
    gray85: '#262626',
    gray80: '#333333',
    gray60: '#666666',
    gray50: '#808080',
    gray40: '#999999',
    gray30: '#B3B3B3',
    gray20: '#CCCCCC',
    gray10: '#E6E6E6',
    gray5: '#F2F2F2',
  },
  gradients: {
    primary: 'linear-gradient(180deg, #3879FA 0%, #105EF9 100%)',
    subtle: 'linear-gradient(179.63deg, #FFFFFF 1.25%, #FAFAFA 98.74%)',
  },

  shadows: {
    small: {
      boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.5)',
    },
    default: {
      boxShadow: '0px 16px 24px rgba(0, 0, 0, 0.15)',
    },
    large: {
      boxShadow: '0px 16px 48px rgba(0, 0, 0, 0.6)',
    },
  },

  breakpoints: [400, 550, 750, 1000, 1200],
  radii: {
    none: 0,
    default: 6,
    pill: 9999,
    circle: '50%',
  },
  widths: [588, 792, 996, 1200, 1400],
  maxWidth: 1224,
};

const mq = {
  mobile: `@media(min-width: ${baseTheme.breakpoints[0]}px)`,
  phablet: `@media(min-width: ${baseTheme.breakpoints[1]}px)`,
  tablet: `@media(min-width: ${baseTheme.breakpoints[2]}px)`,
  desktop: `@media(min-width: ${baseTheme.breakpoints[3]}px)`,
  hd: `@media(min-width: ${baseTheme.breakpoints[4]}px)`,
};

const variants = {
  forms: {
    label: {
      marginTop: 32,
      fontSize: 14,
      fontWeight: 600,
      marginBottom: 8,
      color: '#52667A',
    },
    input: {
      fontSize: 14,
      padding: '10px 16px',
      border: `1px solid #DEE6ED`,
      borderRadius: 6,
    },
    textarea: {
      fontSize: 14,
      padding: '10px 16px',
      border: `1px solid #DEE6ED`,
      borderRadius: 6,
      lineHeight: 1.75,
    },
    select: {
      fontSize: 14,
      padding: '10px 16px',
      border: `1px solid #DEE6ED`,
      borderRadius: 6,
    },
  },
  headers: {
    800: {
      fontSize: baseTheme.fontSizes[5],
      lineHeight: 1.15,
      fontWeight: 400,
      fontFamily: 'Libre Franklin',

      [mq.tablet]: {
        fontSize: baseTheme.fontSizes[7],
      },
    },
    700: {
      fontSize: baseTheme.fontSizes[4],
      lineHeight: 1.15,
      fontWeight: 400,
      fontFamily: 'Libre Franklin',

      [mq.tablet]: {
        fontSize: baseTheme.fontSizes[6],
      },
    },
    600: {
      fontSize: baseTheme.fontSizes[4],
      lineHeight: 1.15,
      fontWeight: 400,
      fontFamily: 'Libre Franklin',
      [mq.tablet]: {
        fontSize: baseTheme.fontSizes[5],
      },
    },
    // Temporary until we replace 600 with something else? (For modular page)
    550: {
      fontSize: baseTheme.fontSizes[3],
      lineHeight: 1.15,
      fontWeight: 400,
      fontFamily: 'Libre Franklin',
      [mq.tablet]: {
        fontSize: baseTheme.fontSizes[4],
      },
    },
    500: {
      fontSize: baseTheme.fontSizes[4],
      lineHeight: 1.25,
      fontWeight: 900,
    },
    400: {
      fontSize: baseTheme.fontSizes[2],
      lineHeight: 1.25,
      fontWeight: 900,

      [mq.tablet]: {
        fontSize: baseTheme.fontSizes[3],
      },
    },
    300: {
      fontSize: baseTheme.fontSizes[2],
      lineHeight: 1.25,
      fontWeight: 900,
    },
    200: {
      fontSize: baseTheme.fontSizes[1],
      lineHeight: 1.15,
      letterSpacing: 2,
      fontWeight: 400,
      textTransform: 'uppercase',
      fontFamily: 'Libre Franklin',
    },
    100: {
      fontSize: baseTheme.fontSizes[0],
      lineHeight: 1.15,
      letterSpacing: 2,
      fontWeight: 400,
      textTransform: 'uppercase',
      fontFamily: 'Libre Franklin',
    },
  },
  text: {
    700: {
      fontSize: baseTheme.fontSizes[6],
    },
    600: {
      fontSize: baseTheme.fontSizes[5],
    },
    500: {
      fontSize: baseTheme.fontSizes[4],
    },
    400: {
      fontSize: baseTheme.fontSizes[3],
    },
    300: {
      fontSize: baseTheme.fontSizes[2],
    },
    200: {
      fontSize: baseTheme.fontSizes[1],
    },
    100: {
      fontSize: baseTheme.fontSizes[0],
    },
    error: {
      color: baseTheme.colors.red,
    },
  },
  paragraph: {
    300: {
      fontSize: baseTheme.fontSizes[3],
    },
    200: {
      fontSize: baseTheme.fontSizes[2],
    },
    100: {
      fontSize: baseTheme.fontSizes[1],
    },
  },
  pills: {
    default: {
      color: baseTheme.colors.neutrals[2],
      backgroundColor: baseTheme.colors.neutrals[0],
      border: '1px solid',
    },
    error: {
      color: baseTheme.colors.white,
      backgroundColor: baseTheme.colors.error,
      border: '1px solid',
    },
    warning: {
      color: baseTheme.colors.white,
      backgroundColor: baseTheme.colors.warning,
      border: '1px solid',
    },
    success: {
      color: baseTheme.colors.white,
      backgroundColor: baseTheme.colors.success,
      border: '1px solid',
    },
  },
  buttons: {
    primary: {
      color: baseTheme.colors.white,
      backgroundColor: baseTheme.colors.red,
      border: `1px solid ${baseTheme.colors.red}`,
    },
    secondary: {
      color: baseTheme.colors.gray95,
      backgroundColor: baseTheme.colors.white,
      border: `1px solid ${baseTheme.colors.white}`,
    },
    tertiary: {
      color: baseTheme.colors.white,
      backgroundColor: baseTheme.colors.gray90,
      border: `1px solid ${baseTheme.colors.gray90}`,
    },
    minimal: {
      color: baseTheme.colors.white,
      backgroundColor: 'transparent',
    },
    naked: {
      color: baseTheme.colors.white,
      backgroundColor: 'transparent',
      fontFamily: 'Libre Franklin',
    },
  },
  buttonSizes: {
    small: {
      padding: '8px 16px',
      fontSize: 12,
      lineHeight: 1.3,
    },
    large: {
      padding: '16px 24px',
      fontSize: 16,
      lineHeight: 1,
    },
  },
  containers: {
    sm: {
      maxWidth: baseTheme.widths[0],
    },
    md: {
      maxWidth: baseTheme.widths[1],
      [mq.desktop]: {
        maxWidth: baseTheme.widths[1],
      },
    },
    lg: {
      maxWidth: baseTheme.widths[1],
      [mq.desktop]: {
        maxWidth: baseTheme.widths[2],
      },
    },
    xl: {
      maxWidth: baseTheme.widths[1],
      [mq.desktop]: {
        maxWidth: baseTheme.widths[3],
      },
    },
    xxl: {
      maxWidth: baseTheme.widths[1],
      [mq.desktop]: {
        maxWidth: baseTheme.widths[4],
      },
    },
  },

  grids: {
    '1-col': {
      gridTemplateColumns: 588,
      justifyContent: 'center',
    },
    '2-col': {
      gridTemplateColumns: 'repeat(2, 1fr)',
      gap: 24,
    },
    '3-col': {
      gridTemplateColumns: 'repeat(1, 1fr)',
      gap: 24,

      [mq.desktop]: {
        gridTemplateColumns: 'repeat(3, 1fr)',
      },
    },
    '4-col': {
      gridTemplateColumns: 'repeat(1, 1fr)',
      justifyContent: 'center',
      gap: 24,

      [mq.phablet]: {
        gridTemplateColumns: 'repeat(2, 1fr)',
      },

      [mq.desktop]: {
        gridTemplateColumns: 'repeat(4, 1fr)',
      },
    },
    '6-col': {
      gridTemplateColumns: 'repeat(6, 1fr)',
      justifyContent: 'center',
      gap: 24,
    },
    'sidebar-left': {
      gridTemplateColumns: '1fr',
      gap: 40,
      [mq.desktop]: {
        gridTemplateColumns: '1fr 3fr',
      },
    },
  },
};

const theme = {
  ...baseTheme,
  ...variants,
  mq,
};

const globalStyles = css`
  .Toastify__toast-container {
    color: #fff;
    max-width: 460px;
    width: 100%;
    overflow: hidden;
    padding: 16px;
    bottom: 0;
  }

  .Toastify__toast-body {
    max-width: 100%;
  }

  .Toastify__toast {
    position: relative;
    margin: 16px 0;
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0px 8px 16px rgb(0 0 0 / 20%);
    backdrop-filter: blur(8px);
    border-radius: 6px;
    padding: 24px 40px 24px 24px;
  }

  .Toastify__close-button--success svg,
  .Toastify__close-button--warning svg,
  .Toastify__close-button--error svg {
    color: rgba(255, 255, 255, 0.75);
  }

  .Toastify__toast--default {
    color: ${theme.colors.gray95};
  }

  .Toastify__toast--success {
    background-color: ${theme.colors.success};
  }

  .Toastify__toast--warning {
    background-color: ${theme.colors.warning};
  }

  .Toastify__toast--error {
    background-color: ${theme.colors.error};
  }

  .Toastify__close-button {
    color: ${theme.colors.gray80};
    opacity: 1;
    position: absolute;
    right: 16px;
    top: 16px;
  }
  .Toastify__progress-bar--default {
    background: ${baseTheme.colors.red};
  }

  /* Tostify Center overrides */

  .Toastify__toast-container--bottom-center .Toastify__close-button {
    top: 50%;
    color: #666666;
    transform: translateY(-50%);
  }

  /* React Modal */
  /* Ensureing underlying elements do not float above modal when open */
  .ReactModal__Body--open #__next {
    z-index: 0;
    position: relative;
  }
  /* // Locking body scroll when player modal is open */
  body.ReactModal__Body--open {
    overflow: hidden;
  }
  /* // Open Animation */
  .ReactModal__Overlay--after-open {
    animation: ${slideUp} 0.5s ease;
    @media (min-width: ${baseTheme.breakpoints[3]}px) {
      animation: ${fadeIn} 0.25s ease;
    }
  }
  /* // Close Animation */
  .ReactModal__Overlay--before-close {
    opacity: 0;
  }
  @supports (backdrop-filter: blur(40px)) {
    .ReactModal__Overlay {
      backdrop-filter: blur(40px);
      background: rgba(0, 0, 0, 0.4) !important;
    }
  }
`;

export type ThemeType = typeof theme;

export { theme, globalStyles };

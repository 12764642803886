/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { RecurlySubscription } from '@/graphql/chat';

export const subscriptionToIdentifyTraits = (subscription: RecurlySubscription | null) => {
  const traits: any = {};

  if (subscription) {
    const keys = Object.keys(subscription);

    const keysToIgnore = ['__typename', 'invoice', 'pending_subscription'];

    keys.forEach((key) => {
      if (!keysToIgnore.includes(key)) {
        // @ts-ignore
        traits[`subscription_${key}`] = subscription[key];
      }
    });

    traits.subscription_pending_subscription = subscription.pending_subscription
      ? subscription.pending_subscription.plan_code
      : null;
  }

  return traits;
};

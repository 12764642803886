// The total size of the cookie header.
//
// Node >14 defaults to a maximum of 16kb for any one header (HTTP 431 errors
// will be issued over that size) and Cloudfront issues HTTP 494 errors on
// requests totalling over 20,480 bytes. It seems reasonable, then, to limit the
// cookie header size to 16kb, because that avoids both limits.
// Reduced maxTotalSize to allow room for 3rd party cookies
const maxTotalSize = 10000;
const singleMax = 880;

// White list of cookie names in descending order of priority
const whitelist: Array<string> = [
  'DW_ORIGIN_SESSION',
  '_legacy_auth0.hDgwLR0K67GTe9IuVKATlbohhsAbD37H.is.authenticated',
  'auth0.hDgwLR0K67GTe9IuVKATlbohhsAbD37H.is.authenticated',
  'ajs_anonymous_id',
  'ajs_user_id',
  'cconsent',
  'muxData',
  '__gads',
  '__gpi',
  '_fbp',
  '_ga',
  '_ga_CZKR9ZYKJP',
  '_gcl_au',
  '_gcl_aw',
  '_landing_page',
  '_orig_referrer',
  '_shopify_y',
  '_uetsid',
  '_uetvid',
  '_y',
  'shopify_cartId',
  'shopify_checkoutUrl',
  'token_v2',
  'wisepops',
  'wisepops_props',
  'wisepops_session',
  'wisepops_visits',
].reverse();

// Black list of cookie names, each will always be removed regardless of size
const blackList: Array<string> = ['spotim_visitId'];
const alwaysKeepList: Array<string> = ['accessToken'];

export const LocalStorageKey = 'dw_cookie_scrubber_enabled';
export const ScrubbingEnabled = () => true;

// This function, if enabled,  evaluates the current list of available cookies
// for the domain, assembles a prioritised sub-list that will fit into the
// total size limit, and deletes all cookies not on the sub-list.
export default () => {
  // Only return an operative function if explicitly enabled
  if (!ScrubbingEnabled()) {
    return;
  }

  const cookies = document.cookie.split('; ').map((c) => ({
    name: c.split('=')[0],
    size: c.length,
  }));

  // Sort by ascending size and then descending whitelist priority
  cookies.sort((a, b) => a.size - b.size);
  cookies.sort((a, b) => whitelist.indexOf(b.name) - whitelist.indexOf(a.name));

  // Compile a list of explicitly included cookies
  let totalSize = 0;
  const included = [];

  for (let i = 0; i < cookies.length; i++) {
    if (alwaysKeepList.includes(cookies[i].name)) {
      included.push(cookies[i]);
      totalSize += cookies[i].size;
      continue;
    }

    if (blackList.includes(cookies[i].name)) {
      continue;
    }

    if (cookies[i].size > singleMax) {
      continue;
    }
    if (totalSize + cookies[i].size > maxTotalSize) {
      continue;
    }

    included.push(cookies[i]);
    totalSize += cookies[i].size;
  }

  // Delete cookies that are not explicitly included.
  cookies
    .filter((n) => !included.includes(n))
    .forEach((cookie) => {
      console.log(`[scrub] ${cookie.name}`);
      document.cookie = cookie.name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    });
};

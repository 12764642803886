/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/order */
import React, { forwardRef } from 'react';

import { ErrorMessage } from '@hookform/error-message';
import { CardNumberElement, CardCvvElement, CardMonthElement, CardYearElement } from '@recurly/react-recurly';

import {
  CustomInput,
  Fieldset,
  FormItem,
  FormItems,
  RecurlyInput,
  Text,
  SubmitButton,
} from '@/components/design-system';

import { CountrySelectBlack } from '@/components/design-system/CountrySelectBlack';

import { useAuth0 } from '@/context/auth';
import { getABTestAnalyticData } from '@/lib/abTesting';
import { getUtmCookieData } from '@/lib/utm_cookie';
import style from './index.module.css';
type FormProps = {
  children: React.ReactNode;
  submitHandler: (e: React.FormEvent<HTMLFormElement>) => void;
  checkFormValidity: () => void;
  handleInputError: (name: string, error: boolean) => void;
  inputErrors: string[];
  loading: boolean;
  creditCardError: string | null;
  setCreditCardError: (error: string | null) => void;
  recurlyElementErrors: string[];
};

export const RecurlyForm = forwardRef<HTMLFormElement, FormProps>(
  (
    {
      children,
      submitHandler,
      checkFormValidity,
      handleInputError,
      inputErrors,
      loading,
      creditCardError,
      setCreditCardError,
      recurlyElementErrors = [],
    },
    formRef,
  ) => {
    const { subscriber } = useAuth0();

    const abTestData = getABTestAnalyticData();
    const utmCookieData = getUtmCookieData();

    //refs

    const firstNameRef = React.useRef(null);
    const lastNameRef = React.useRef(null);
    const zipCodeRef = React.useRef(null);

    //send analytics page event

    React.useEffect(() => {
      const path = new URL(window.location.href).pathname;
      window.__DW_Next_Bridge?.Analytics.logEvent('page', {
        ...abTestData,
        ...utmCookieData,
        path: path,
      });
    }, [abTestData, utmCookieData]);

    return (
      <form
        onInput={() => {
          creditCardError && setCreditCardError(null);
        }}
        onSubmit={submitHandler}
        ref={formRef}
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '22px',
        }}
      >
        <Fieldset>
          <FormItems style={{ gap: '16px' }}>
            <div className={style.twoStack}>
              <CustomInput
                //@ts-ignore
                checkFormValidity={checkFormValidity}
                dataRecurly='first_name'
                errors={inputErrors}
                handleInputError={handleInputError}
                id='first-name'
                initialValue={subscriber?.firstName ?? ''}
                inputEventHandler={null}
                name='first_name'
                placeholder='First name'
                ref={firstNameRef}
                required={true}
                type='text'
              />
              <CustomInput
                //@ts-ignore
                checkFormValidity={checkFormValidity}
                dataRecurly='last_name'
                errors={inputErrors}
                handleInputError={handleInputError}
                id='last-name'
                initialValue={subscriber?.lastName ?? ''}
                inputEventHandler={null}
                name='last_name'
                placeholder='Last name'
                ref={lastNameRef}
                required={true}
                type='text'
              />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '8px',
              }}
            >
              <FormItem
                style={{
                  margin: '0',
                  border: recurlyElementErrors.includes('number') ? '1px solid red' : '1px solid transparent',
                  borderRadius: '8px',
                }}
              >
                <RecurlyInput>
                  <CardNumberElement
                    style={{
                      padding: '0',
                      fontFamily: 'Libre Franklin',
                      fontColor: '#140D0E',
                      fontSize: '18px',
                      placeholder: {
                        color: '#8F8F8F',
                        //@ts-ignore
                        fontSize: '18px',
                        content: '0000 0000 0000 0000',
                      },
                    }}
                  />
                </RecurlyInput>
              </FormItem>
              <div
                style={{
                  display: 'flex',
                  gap: '8px',
                  width: '100%',
                  marginBottom: '8px',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flex: '1',
                    border:
                      recurlyElementErrors.includes('month') || recurlyElementErrors.includes('year')
                        ? '1px solid red'
                        : '1px solid transparent',
                    borderRadius: '8px',
                  }}
                >
                  <FormItem
                    style={{
                      margin: '0',
                      maxWidth: '60px',
                      backgroundColor: '#F5F5F5',
                      borderTopLeftRadius: '8px',
                      borderBottomLeftRadius: '8px',
                    }}
                  >
                    <RecurlyInput>
                      <CardMonthElement
                        style={{
                          padding: '0',
                          fontFamily: 'Libre Franklin',
                          fontColor: '#140D0E',
                          fontSize: '18px',
                          textAlign: 'left',
                          placeholder: {
                            //@ts-ignore
                            fontSize: '18px',
                            color: '#8F8F8F',
                            content: 'MM',
                          },
                        }}
                      />
                    </RecurlyInput>
                  </FormItem>
                  <div
                    style={{
                      fontFamily: 'Libre Franklin',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      background: '#F5F5F5',
                      color: '#8F8F8F',
                      padding: '0 4px 0 0',
                      fontSize: '18px',
                      flex: '1',
                    }}
                  >
                    /
                  </div>
                  <FormItem style={{ margin: '0' }}>
                    <RecurlyInput>
                      <CardYearElement
                        style={{
                          padding: '0',
                          fontFamily: 'Libre Franklin',
                          fontColor: '#140D0E',
                          fontSize: '18px',
                          placeholder: {
                            //@ts-ignore
                            fontSize: '18px',
                            color: '#8F8F8F',
                            content: 'YYYY',
                          },
                        }}
                      />
                    </RecurlyInput>
                  </FormItem>
                </div>
                <div
                  style={{
                    flex: '1',
                    border: recurlyElementErrors.includes('cvv') ? '1px solid red' : '1px solid transparent',
                    borderRadius: '8px',
                  }}
                >
                  <FormItem style={{ margin: '0' }}>
                    <RecurlyInput>
                      <CardCvvElement
                        style={{
                          padding: '0',
                          fontFamily: 'Libre Franklin',
                          fontColor: '#140D0E',
                          fontSize: '18px',
                          placeholder: {
                            //@ts-ignore
                            fontSize: '18px',
                            color: '#8F8F8F',
                            content: 'CVV',
                          },
                        }}
                      />
                    </RecurlyInput>
                  </FormItem>
                </div>
              </div>
              <div className={style.twoStack}>
                <FormItem style={{ marginBottom: '0' }}>
                  <CustomInput
                    //@ts-ignore
                    checkFormValidity={checkFormValidity}
                    dataRecurly='postal_code'
                    errors={inputErrors}
                    handleInputError={handleInputError}
                    id='postal-code'
                    initialValue=''
                    inputEventHandler={null}
                    name='postal_code'
                    placeholder='Zip code'
                    ref={zipCodeRef}
                    required={true}
                    type='text'
                  />
                </FormItem>
                <FormItem style={{ marginBottom: '0' }}>
                  <CountrySelectBlack
                    data-recurly='country'
                    name='country'
                    style={{
                      fontFamily: 'Libre Franklin',
                      height: '64px',
                      borderRadius: '8px',
                      backgroundColor: '#F5F5F5',
                      color: '#140D0E',
                      fontSize: '18px',
                      fontWeight: '400',
                    }}
                  />
                  <ErrorMessage
                    as={<Text variant='error' />}
                    className='input-error'
                    errors={inputErrors}
                    name='country'
                  />
                </FormItem>
              </div>
              {creditCardError && (
                <div style={{ display: 'flex', alignItems: 'center', paddingTop: '20px' }}>
                  <p
                    style={{
                      color: '#EB1313',
                      fontFamily: 'Libre Franklin',
                      fontSize: '10px',
                      fontStyle: 'normal',
                      fontWeight: '700',
                      lineHeight: 'normal',
                      marginTop: '4px',
                    }}
                  >
                    {creditCardError ?? ''}
                  </p>
                  {/* <FormError error={creditCardError} /> */}
                </div>
              )}
            </div>
          </FormItems>
        </Fieldset>
        {children}
        <SubmitButton cta=' Agree & Redeem' loading={loading}>
          <svg fill='none' height='12' viewBox='0 0 10 12' width='10' xmlns='http://www.w3.org/2000/svg'>
            <g clipPath='url(#clip0_2910_2190)'>
              <path
                d='M2.92584 5.45455H7.07399V3.81818C7.07399 3.21591 6.87145 2.7017 6.46635 2.27557C6.06126 1.84943 5.57245 1.63636 4.99992 1.63636C4.42739 1.63636 3.93858 1.84943 3.53348 2.27557C3.12839 2.7017 2.92584 3.21591 2.92584 3.81818V5.45455ZM9.66659 6.27273V11.1818C9.66659 11.4091 9.59097 11.6023 9.43973 11.7614C9.2885 11.9205 9.10486 12 8.88881 12H1.11103C0.89498 12 0.711338 11.9205 0.560104 11.7614C0.408869 11.6023 0.333252 11.4091 0.333252 11.1818V6.27273C0.333252 6.04545 0.408869 5.85227 0.560104 5.69318C0.711338 5.53409 0.89498 5.45455 1.11103 5.45455H1.37029V3.81818C1.37029 2.77273 1.72677 1.875 2.43973 1.125C3.1527 0.375 4.00609 0 4.99992 0C5.99375 0 6.84714 0.375 7.5601 1.125C8.27307 1.875 8.62955 2.77273 8.62955 3.81818V5.45455H8.88881C9.10486 5.45455 9.2885 5.53409 9.43973 5.69318C9.59097 5.85227 9.66659 6.04545 9.66659 6.27273Z'
                fill='white'
              />
            </g>
            <defs>
              <clipPath id='clip0_2910_2190'>
                <rect fill='white' height='12' transform='translate(0.333252)' width='9.33333' />
              </clipPath>
            </defs>
          </svg>
        </SubmitButton>
      </form>
    );
  },
);

RecurlyForm.displayName = 'RecurlyForm';

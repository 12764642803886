import styled from '@emotion/styled';

type Props = {
  theme: any;
  variant?: string;
};

export const Grid = styled('div')(({ theme, variant = '1-col' }: Props) => ({
  display: 'grid',
  alignItems: 'start',
  ...theme.grids[variant],
}));

import styled from '@emotion/styled';

export const Card = styled('div')<any>(({ theme }) => ({
  background: theme.colors.gray85,
  color: theme.colors.white,
  borderRadius: 6,
  overflow: 'hidden',
}));

export const CardHeader = styled('div')<any>(({ theme }) => ({
  borderRadius: `6px 6px 0 0`,
  padding: 24,
  borderBottom: '1px solid',
  borderBottomColor: theme.colors.gray80,
  [theme.mq.tablet]: {
    padding: 32,
  },
}));

export const CardContent = styled('div')<any>(({ theme, ...props }) => ({
  textAlign: props.textAlign,
  padding: 24,
  [theme.mq.tablet]: {
    padding: 32,
  },
}));

export const CardFooter = styled('div')<any>(({ theme }) => ({
  borderRadius: `0px 0px 6px 6px`,
  padding: `16px 24px 32px`,

  [theme.mq.tablet]: {
    padding: `16px 32px 32px`,
  },
}));

import * as React from 'react';

import styled from '@emotion/styled';

type Props = {
  as?: React.ElementType;
  theme: any;
  gutter?: string | number;
  variant?: 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
};

export const Container = styled('div')(({ theme, ...props }: Props) => ({
  ...theme.containers[props.variant ? props.variant : 'xxl'],
  paddingLeft: props.gutter || '5%',
  paddingRight: props.gutter || '5%',
  marginLeft: 'auto',
  marginRight: 'auto',
  boxSizing: 'content-box',
}));

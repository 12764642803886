/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';

import { imgixLoader } from '@delicious-simplicity/next-image-imgix-loader';
import Image from 'next/image';
import NextLink from 'next/link';

import { Heading, Text } from '@/components/design-system';
import { Badge } from '@/graphql/chat';

interface IBadgeToastProps {
  badge: Badge;
}

const BadgeToast: React.FC<IBadgeToastProps> = ({ badge }) => {
  return (
    <NextLink href='/account/profile' passHref>
      <a css={(theme) => ({ color: theme.colors.red })}>
        <div
          css={(theme) => ({
            display: 'flex',
            alignItems: 'center',
            color: theme.colors.gray92,
          })}
        >
          <div>
            {badge.image && (
              <Image
                alt={badge.name}
                height={80}
                loader={(props) => imgixLoader(props, { fit: 'crop', ar: '1:1' })}
                src={badge.image}
                title={badge.name}
                width={80}
              />
            )}
          </div>
          <div css={{ marginLeft: 16 }}>
            <Heading variant='300'>Congratulations! You’ve unlocked the {badge.name} badge.</Heading>
            <Text variant='200'>Enable this badge under your account</Text>
          </div>
        </div>
      </a>
    </NextLink>
  );
};

export default BadgeToast;

import * as React from 'react';

// Conditionally wrap a Component in some more JSX
//
// Usage:
//   <ConditionalWrap
//     condition={shouldLink}
//     wrap={children => <TouchableOpacity onPress={this.onPress}>{children}</TouchableOpacity>}
//   >
//    <OtherComponent />
//  </ConditionalWrap>

interface IConditionalWrapProps {
  condition: boolean;
  //  TODO: more specific return type
  wrap: (children: React.ReactNode) => any;
  children: React.ReactNode;
}

export const ConditionalWrap: React.FC<IConditionalWrapProps> = ({ condition, wrap, children }) => {
  return condition ? wrap(children) : children;
};

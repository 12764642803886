import { FreeformReferenceType } from '@/graphql/app';

interface CmsLinkResolverConfig {
  link?: string | null;
  referenceSlug?: string | null;
  referenceType?: FreeformReferenceType | null;
}

export function cmsLinkResolver(config: CmsLinkResolverConfig) {
  if (!config.link && !config.referenceSlug && !config.referenceType) return null;

  if (config.link) return config.link;
  switch (config.referenceType) {
    case FreeformReferenceType.Clip: {
      return `/clips/${config.referenceSlug}`;
    }
    case FreeformReferenceType.Video: {
      return `/videos/${config.referenceSlug}`;
    }
    case FreeformReferenceType.Episode: {
      return `/episode/${config.referenceSlug}`;
    }
    case FreeformReferenceType.Show: {
      return `/show/${config.referenceSlug}`;
    }
    case FreeformReferenceType.ModulePage: {
      return `/${config.referenceSlug}`;
    }
    default: {
      return `/${config.referenceSlug}`;
    }
  }
}

import * as React from 'react';

import cn from 'classnames';

import s from './Text.module.css';

interface TextProps {
  variant?: Variant;
  spacing?: 'default' | 'none';
  className?: string;
  style?: React.CSSProperties;
}

type Variant =
  | 'pageHeading'
  | 'sectionHeading'
  | 'heading'
  | 'subHeading'
  | 'body'
  | 'bodySmall'
  | 'kicker'
  | 'description';

export const Text: React.FC<TextProps> = ({
  style,
  className = '',
  variant = 'body',
  spacing = 'default',
  children,
}) => {
  return (
    <div
      className={cn(
        {
          [s.pageHeading]: variant === 'pageHeading',
          [s.sectionHeading]: variant === 'sectionHeading',
          [s.heading]: variant === 'heading',
          [s.subHeading]: variant === 'subHeading',
          [s.body]: variant === 'body',
          [s.bodySmall]: variant === 'bodySmall',
          [s.kicker]: variant === 'kicker',
          [s.description]: variant === 'description',
          [s.noSpacing]: spacing === 'none',
        },
        className,
      )}
      style={style}
    >
      {children}
    </div>
  );
};

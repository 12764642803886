import styled from '@emotion/styled';

interface TabStyleProps {
  active?: boolean;
}

export const TabRoot = styled.a<TabStyleProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 12px;
  padding-right: 12px;
  min-width: 150px;
  background-color: ${(props) => (props.active ? 'rgba(0, 0, 0, 0.15)' : 'rgba(0, 0, 0, 0.)')};
`;

export const Text = styled.span<TabStyleProps>`
  font-size: 16px;
  color: ${(props) => (props.active ? 'white' : 'rgba(255, 255, 255, 0.6)')};
`;

export const Underline = styled.div<TabStyleProps>`
  display: block;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 3px;
  opacity: ${(props) => (props.active ? 1 : 0)};
  background-color: white;
  transition: 0.1s opacity;
`;

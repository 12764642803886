const AB_SESSION_ID_KEY = 'dw_sid';
const AB_TEST_DATA_LOCAL_STORAGE_KEY = 'ab_test_data';
// const DEFAULT_AB_TEST_NAME = 'initial ab test'; // 2024-02-22
// const DEFAULT_AB_TEST_NAME = 'home page slideshow'; // 2024-03-11
const DEFAULT_AB_TEST_NAME = 'paypal checkout'; // 2024-06-13

export enum TestGroups {
  A = 'A',
  B = 'B',
  Control = 'CONTROL',
}

export interface ABTestAnalyticData {
  abSessionId: string | null;
  abTestFeature: boolean | null;
  abTestGroup: TestGroups | null;
  abTestName: string | null;
}

export interface ABTestData {
  feature: boolean | null;
  name: string | null;
  group: TestGroups | null;
  sessionId?: string | null;
}

const AB_TEST_FEATURE_GROUP: TestGroups = TestGroups.B;

export function clearABTestData(testName: string = DEFAULT_AB_TEST_NAME): ABTestData {
  const blankTestData: ABTestData = {
    feature: null,
    group: null,
    name: null,
    sessionId: null,
  };

  if (typeof localStorage === 'undefined') return blankTestData;

  const keyName = justifyName(testName);

  const testDataString = localStorage.getItem(AB_TEST_DATA_LOCAL_STORAGE_KEY);

  if (!testDataString) return blankTestData;

  const allTestData = JSON.parse(testDataString);

  allTestData[keyName] = blankTestData;

  localStorage.setItem(AB_TEST_DATA_LOCAL_STORAGE_KEY, JSON.stringify(allTestData));

  return blankTestData;
}

export function getABTestAnalyticData(testName: string = DEFAULT_AB_TEST_NAME): ABTestAnalyticData {
  const testData = getABTestData(testName);

  if (testData) {
    return {
      abSessionId: testData.sessionId,
      abTestFeature: testData.feature,
      abTestGroup: testData.group,
      abTestName: testData.name,
    };
  }

  const abSessionId = getABSessionId();

  return {
    abSessionId,
    abTestFeature: false,
    abTestGroup: null,
    abTestName: null,
  };
}

export function getABTestData(testName: string = DEFAULT_AB_TEST_NAME): ABTestData {
  if (typeof localStorage === 'undefined') return null;

  const keyName = justifyName(testName);

  const testDataString = localStorage.getItem(AB_TEST_DATA_LOCAL_STORAGE_KEY);

  if (!testDataString) return null;

  const allTestData = JSON.parse(testDataString);
  const testData: ABTestData = allTestData[keyName] || null;

  let corruptTestData = false;
  for (const key in testData) {
    if (testData[key] === null) {
      corruptTestData = true;
      break;
    }
  }
  if (!testData || corruptTestData) return null;
  testData.sessionId = getABSessionId();

  return testData;
}

export function startABTest(testName: string = DEFAULT_AB_TEST_NAME): ABTestData {
  const existingTestData = getABTestData(testName);

  if (existingTestData) return existingTestData;
  const keyName = justifyName(testName);
  // const testDataString = localStorage.getItem(AB_TEST_DATA_LOCAL_STORAGE_KEY);

  // For now always reset all test
  // data when testName has changed
  const allTestData = {};
  // const allTestData = JSON.parse(testDataString) || {};

  const testData: ABTestData = {
    feature: false,
    group: TestGroups.Control,
    name: testName.toLowerCase(),
  };

  // let group = ;

  const rollResult = Math.floor(Math.random() * (100 + 1));

  if (rollResult < 10) {
    testData.group = TestGroups.A;
  } else if (rollResult < 20) {
    testData.group = TestGroups.B;
  }

  testData.feature = AB_TEST_FEATURE_GROUP === testData.group;

  allTestData[keyName] = testData;

  localStorage.setItem(AB_TEST_DATA_LOCAL_STORAGE_KEY, JSON.stringify(allTestData));

  return testData;
}

function getABSessionId(): string {
  if (typeof sessionStorage === 'undefined') return null;

  return sessionStorage.getItem(AB_SESSION_ID_KEY);
}

function justifyName(name: string): string {
  return name
    .toLowerCase()
    .trim()
    .replaceAll(/[^\w\s]/gi, '')
    .replaceAll(/ +|-/g, '_');
}

/* eslint-disable import/no-unresolved */
/* eslint-disable import/order */
import * as React from 'react';

import cn from 'classnames';

import { ButtonLink, Icon, SectionLabel } from '@/components/design-system';
import { debounce } from '@/utils/helpers';

import s from './Carousel.module.css';

type CarouselProps = {
  columns: number;
  image?: string;
  kicker?: string;
  mobileColumns?: number;
  tabletColumns?: number;
  hdColumns?: number;
  mode?: 'light' | 'dark';
  title?: string;
  offsetControls?: '1:1' | '16:9' | '2:3' | '4:3' | '2:5';
  moreLink?: string;
  mobileFullWidthSlide?: boolean;
  // NOTE: Temporary crutch, this component needs to be updated
  // to include this functionality by default.
  consistentSlideSize?: boolean;
};

export const Carousel: React.FC<CarouselProps> = ({
  children,
  image,
  kicker,
  mobileColumns,
  tabletColumns,
  columns,
  hdColumns,
  mode = 'light',
  title,
  offsetControls,
  moreLink = '',
  mobileFullWidthSlide = true,
  consistentSlideSize,
}) => {
  const [isStart, setIsStart] = React.useState(true);
  const [isEnd, setIsEnd] = React.useState(false);
  const ref = React.useRef<HTMLDivElement>(null);

  const handleScroll = React.useCallback(() => {
    if (ref.current) {
      setIsStart(ref.current.scrollLeft === 0);
      setIsEnd(ref.current.scrollLeft + ref.current.offsetWidth >= ref.current.scrollWidth);
    }
  }, []);

  const handlePrevious = React.useCallback(() => {
    if (ref.current) {
      const moveBy = Math.round(ref.current.scrollLeft / ref.current.offsetWidth) - 1;
      ref.current.scrollLeft = ref.current.offsetWidth * moveBy;
    }
  }, []);

  const handleNext = React.useCallback(() => {
    if (ref.current) {
      ref.current.scrollLeft += ref.current.offsetWidth + 20;
    }
  }, []);

  React.useEffect(() => {
    const listener = debounce(handleScroll, 1000);

    window.addEventListener('resize', listener);
    handleScroll();

    return () => window.removeEventListener('resize', listener);
  }, [handleScroll]);

  return (
    <section
      className={cn(s.root, 'relative', {
        [s.light]: mode === 'light',
        [s.dark]: mode === 'dark',
        [s.consistentSlideSize]: consistentSlideSize,
        [s.mobileFullWidthSlide]: mobileFullWidthSlide,
      })}
      style={
        {
          '--mobile-cols': mobileColumns,
          '--tablet-cols': tabletColumns,
          '--default-cols': columns,
          '--hd-cols': hdColumns,
        } as React.CSSProperties
      }
    >
      {(title || kicker) && (
        <header className={s.header}>
          <SectionLabel image={image} section={title} title={kicker} variant={mode} />

          {moreLink && (
            <ButtonLink css={{ color: '#1a1a1a', paddingRight: 0 }} href={moreLink} size='small' variant='minimal'>
              More <Icon name='CHEVRON_RIGHT' size={16} />
            </ButtonLink>
          )}
        </header>
      )}
      <div className='grid' style={{ overflow: 'hidden' }}>
        <div className={s.lens}>
          <div className={s.tray} onScroll={handleScroll} ref={ref}>
            {React.Children.map(children, (child) => {
              if (React.isValidElement(child)) {
                return {
                  ...child,
                  props: {
                    ...child.props,
                    className: cn(child.props.className, s.slide),
                  },
                };
              }
              return child;
            })}
          </div>
        </div>
        {(!isStart || !isEnd) && (
          <div
            className={cn(s.controls, {
              [s.controls__offset]: !!offsetControls,
              [s.controls__offset_16x9]: offsetControls === '16:9',
              [s.controls__offset_4x3]: offsetControls === '4:3',
              [s.controls__offset_2x3]: offsetControls === '2:3',
              [s.controls__offset_1x1]: offsetControls === '1:1',
              [s.controls__offset_2x5]: offsetControls === '2:5',
            })}
            style={{ zIndex: 20, width: '100%' }}
          >
            <button
              className={cn(s.btn, s.btn__prev, {
                invisible: isStart,
              })}
              disabled={isStart}
              onClick={handlePrevious}
            >
              <span className='sr-only'>Previous</span>
            </button>
            <button
              className={cn(s.btn, s.btn__next, {
                invisible: isEnd,
              })}
              disabled={isEnd}
              onClick={handleNext}
            >
              <span className='sr-only'>Next</span>
            </button>
          </div>
        )}
      </div>
    </section>
  );
};

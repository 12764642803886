import React, { FC } from 'react';

import Image from 'next/image';
import NextLink from 'next/link';

import { visuallyHidden } from '@/utils/styles';

export const Logo: FC = () => (
  <NextLink href='/'>
    <a>
      <span css={visuallyHidden}>The Daily Wire</span>
      <Image className='daily-wire-logo' height='23' src='/images/version2/Logo.svg' width='240' />
    </a>
  </NextLink>
);

import * as React from 'react';

import styled from '@emotion/styled';

type Props = {
  as?: React.ElementType;
  theme: any;
  variant?: string;
};

export const Heading = styled('h2')(({ theme, ...props }: Props) => ({
  ...theme.headers[props.variant ? props.variant : '700'],

  margin: 0,
  textDecoration: 'none',

  a: {
    color: 'inherit',
    textDecoration: 'none',
  },
}));

import { Subscriber } from 'types/subscriber';

import { isEmptyObject } from './isEmptyObject';

export const subscriberHasPaidPlan = (subscriber?: Subscriber) => {
  if (!subscriber) return false;
  if (isEmptyObject(subscriber) || !subscriber.effectivePlan || subscriber.effectivePlan === 'FREE') return false;

  return true;
};

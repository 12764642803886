import { css } from '@emotion/core';

import { color } from './color';

/* Links */
export const link = css`
  color: ${color.blue};
  border-bottom: 1px solid ${color.blue};
  text-decoration: none;
`;

export const moreLink = css`
  color: ${color.blue};
  font-weight: 700;
  font-size: 13px;
  border: 0;
  padding: 0;
  appearance: none;
  background: transparent;

  &:focus {
    outline: 0;
  }

  &::before {
    content: '+';
    margin-right: 8px;
  }
`;

import { css } from '@emotion/core';
import styled from '@emotion/styled';

import { color } from './color';
import { font } from './font';
import { shadow } from './shadows';

export const button = {
  primary: {
    red: {
      small: css``,
      large: css``,
    },
    white: {
      small: css``,
      large: css``,
    },
  },
  secondary: {
    white: {
      small: css``,
      large: css``,
    },
  },
  naked: css``,
};

// Base

const buttonBase = css`
  font-family: 'Libre Franklin';
  appearance: none;
  border-radius: 4px;
  cursor: pointer;
  line-height: 1;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border: 1px solid;
  text-transform: uppercase;
  z-index: 1;
  overflow: hidden;
  position: relative;
  transition: all 0.5s ease;

  &[type='submit'] {
    width: 100%;
  }

  :focus {
    outline: none;
  }

  :disabled {
    background-color: ${color.gray60};
    border: 1px solid ${color.gray60};
    color: ${color.gray30};
    cursor: not-allowed;
  }
`;

// Sizes

const buttonSmall = css`
  letter-spacing: 1px;
  padding: 10px 16px;
  font-size: 12px;
`;

const buttonLarge = css`
  padding: 16px 24px 14px;
  font-size: 15px;
  line-height: 1.25;
  letter-spacing: 1.5px;
`;

// Colors

const buttonRed = css`
  color: ${color.white};
  background-color: ${color.red};
  border-color: ${color.red};
`;

const buttonWhite = css`
  background-color: ${color.gray92};
  color: ${color.white};
  border-color: ${color.gray92};
`;

// Primary

const buttonPrimary = css`
  ${buttonBase};

  :hover {
    ${shadow.small};
  }
`;

// Secondary (outlined)

const buttonSecondary = css`
  ${buttonBase};
  border: 1px solid;
  background-color: transparent;
  color: ${color.white};
  border-color: ${color.white};

  &:hover {
    background-color: ${color.white};
    color: ${color.gray95};
  }
`;

button.primary = {
  red: {
    small: css`
      ${buttonPrimary};
      ${buttonRed};
      ${buttonSmall};
    `,
    large: css`
      ${buttonPrimary};
      ${buttonRed};
      ${buttonLarge};
    `,
  },
  white: {
    small: css`
      ${buttonPrimary};
      ${buttonWhite};
      ${buttonSmall};
    `,
    large: css`
      ${buttonPrimary};
      ${buttonWhite};
      ${buttonLarge};
    `,
  },
};

button.secondary = {
  white: {
    small: css`
      ${buttonSecondary};
      ${buttonSmall};
    `,
    large: css`
      ${buttonSecondary};
      ${buttonLarge};
    `,
  },
};

// Naked

button.naked = css`
  appearance: none;
  background-color: transparent;
  border: 0;
  &:focus {
    outline: 0;
  }
`;

//  don't love this in here. should move to components
export const LoadMoreButton = styled('button')<any>`
  appearance: none;
  display: block;
  border: 0;
  background-color: transparent;
  text-align: center;
  margin-top: 48px;
  margin-bottom: 16px;
  margin-left: auto;
  margin-right: auto;
  text-transform: uppercase;
  font-family: 'Libre Franklin';
  font-weight: 600;
  letter-spacing: 1.5px;
  line-height: 106%;
  font-size: 14px;
  color: ${({ mode }) => (mode === 'dark' ? '#666' : '#666')};
  &::after {
    position: relative;
    top: 0;
    content: '';
    display: block;
    margin: 8px auto 0;
    height: 20px;
    width: 20px;
    transition: 0.3s ease !important;
    background: url(${`/svg/chevron-down-gray-dark.svg`}) center / contain no-repeat;
  }
  &:hover::after {
    top: 8px;
  }
  &:focus {
    outline: 0;
  }
`;
